import { isEmpty } from 'lodash';

/* eslint-disable max-lines */
export const selectedCarrierMapping = {
  'Great American': 'GA',
  Coterie: 'COTERIE',
  Pie: 'PIE',
  CNA: 'CNA',
};

export const getRequestIdFromQuoteUrl = (quoteUrl = '') => {
  const query = quoteUrl.split('?')?.[1] || '';
  const searchParams = new URLSearchParams(query);
  return searchParams.get('requestId');
};

export const getCoterieProducerIdFromQuoteUrl = (
  selectedCarrier = [],
  availableCarriers = []
) => {
  const carrierQuoteURL = selectedCarrier?.[0]?.carrierQuoteURL;
  const query = carrierQuoteURL?.split('?')[1] || '';
  const searchParams = new URLSearchParams(query);
  let carrierAgentId = searchParams.get('carrierAgentId');

  if (!carrierAgentId && !isEmpty(selectedCarrier)) {
    for (const carrier of availableCarriers) {
      if (carrier.carrierName === 'Coterie') {
        const { carrierQuoteURL: otherCarrierQuoteURL } = carrier;
        const otherQuery = otherCarrierQuoteURL?.split('?')[1] || '';
        const otherSearchParams = new URLSearchParams(otherQuery);
        carrierAgentId = otherSearchParams.get('carrierAgentId');
      }
    }
    return carrierAgentId;
  } else {
    return null;
  }
};

export const getCoterieAgencyIdFromQuoteUrl = (
  selectedCarrier = [],
  availableCarriers = []
) => {
  const carrierQuoteURL = selectedCarrier?.[0]?.carrierQuoteURL;
  const query = carrierQuoteURL?.split('?')[1] || '';
  const searchParams = new URLSearchParams(query);
  let carrierAgencyId = searchParams.get('carrierAgencyId');

  if (!carrierAgencyId && !isEmpty(selectedCarrier)) {
    for (const carrier of availableCarriers) {
      if (carrier.carrierName === 'Coterie') {
        const { carrierQuoteURL: otherCarrierQuoteURL } = carrier;
        const otherQuery = otherCarrierQuoteURL?.split('?')[1] || '';
        const otherSearchParams = new URLSearchParams(otherQuery);
        carrierAgencyId = otherSearchParams.get('carrierAgencyId');
      }
    }
    return carrierAgencyId;
  } else {
    return null;
  }
};

export const coterieHashData = [
  {
    description: 'Porch construction, residential-type',
    coterieAkHash: '857c922976b2cabcc79f4f772ad0425d',
    coterieId: 10109,
  },
  {
    description: 'Handyman construction service, residential building',
    coterieAkHash: '8ea04835d08f45520a171945b5f4d31d',
    coterieId: 10109,
  },
  {
    description:
      'Foundation (e.g., brick, block, stone), building, contractors',
    coterieAkHash: '0fef2b746cdfcf98f78c482ac57d31e4',
    coterieId: 10447,
  },
  {
    description: 'Decorative steel and wrought iron work installation',
    coterieAkHash: '1c59e2688012c46b694302cf99bc12cc',
    coterieId: 10461,
  },
  {
    description: 'Metal furring contractors',
    coterieAkHash: '41f617fd3ec8d7b08e5a2a2139dfedc3',
    coterieId: 10117,
  },
  {
    description: 'Concrete form contractors',
    coterieAkHash: '73953af1db593b18f7c833d29eea9122',
    coterieId: 10157,
  },
  {
    description: 'Shutter installation',
    coterieAkHash: 'a58d2127e2c1be5ed2f2cfd2d53fa193',
    coterieId: 10643,
  },
  {
    description: 'Storefront, metal or metal frame, installation',
    coterieAkHash: 'ca6d4e991e1723d0560bb1b598794ca8',
    coterieId: 10117,
  },
  {
    description: 'Awning installation',
    coterieAkHash: 'f74f4fb45fa7ff1d65ac0ac91534ad5e',
    coterieId: 10117,
  },
  {
    description: 'Epoxy application contractors',
    coterieAkHash: 'fccb44bc98ee08416263e1e5b2896353',
    coterieId: 10283,
  },
  {
    description: 'Balcony, metal, installation',
    coterieAkHash: 'ff5329608b5464a1881291be87c2752a',
    coterieId: 10117,
  },
  {
    description: 'Electrical equipment and appliance installation',
    coterieAkHash: '315f63ba427df8f3ef85a957341bb4de',
    coterieId: 10238,
  },
  {
    description: 'Waterless fire suppression system installation and repair',
    coterieAkHash: '0091c4153a5df3f69b42e641dedd3330',
    coterieId: 10039,
  },
  {
    description:
      'Exhaust system (e.g., kitchens, industrial work areas) installation',
    coterieAkHash: '01f2c6983d5f8692c0431dba6d400bc7',
    coterieId: 10024,
  },
  {
    description: 'Household oil storage tank installation',
    coterieAkHash: '0dc7dc30a32276c9c639987c3e50d89b',
    coterieId: 10041,
  },
  {
    description: 'Heating boiler installation',
    coterieAkHash: '233afbfc677653d0b95ad805305ba2b3',
    coterieId: 10024,
  },
  {
    description: 'Plumbing fixture installation',
    coterieAkHash: '234fb0b1d6c390c681779418e65fb627',
    coterieId: 10537,
  },
  {
    description: 'Snow melting system (e.g., hot water, glycol) installation',
    coterieAkHash: '26a90c82a142174ac7591381eb28da3d',
    coterieId: 10039,
  },
  {
    description: 'Dry heating equipment installation',
    coterieAkHash: '27b78d260f44f6244c0c95784acd4ba6',
    coterieId: 10024,
  },
  {
    description: 'Water heater installation',
    coterieAkHash: '2baecf28b014036cd3c8c3edc6e52b08',
    coterieId: 10537,
  },
  {
    description: 'Ventilating contractors',
    coterieAkHash: '317903b746bc41fbc91c855b48ecdb17',
    coterieId: 10024,
  },
  {
    description: 'Dust collecting and bag house equipment installation',
    coterieAkHash: '35c7b8de2eb8278e49c00b70f5acfa09',
    coterieId: 10039,
  },
  {
    description: 'HVAC (heating, ventilation and air-conditioning) contractors',
    coterieAkHash: '3cdfc10ad6660692a3f77f6a4e3825b4',
    coterieId: 10024,
  },
  {
    description: 'Sprinkler system, building, installation',
    coterieAkHash: '3d5224e2c6581645166665ff914eb807',
    coterieId: 10039,
  },
  {
    description: 'Industrial process piping installation',
    coterieAkHash: '428d3bdc9e8c69e348332db0f820d47d',
    coterieId: 10024,
  },
  {
    description: 'Pumping system, water, installation',
    coterieAkHash: '43d46a2328977dd28f5d215d9b9e045b',
    coterieId: 10537,
  },
  {
    description: 'Fuel oil burner installation',
    coterieAkHash: '45fdf32fa68951bb374ff43c9bc1d1c6',
    coterieId: 10039,
  },
  {
    description: 'Heat pump installation',
    coterieAkHash: '481efa95c592655d32084cac1e1243da',
    coterieId: 10024,
  },
  {
    description: 'Sanitary ware installation',
    coterieAkHash: '51162da65398c11c55af4aa7352c776e',
    coterieId: 10537,
  },
  {
    description: 'Warehouse refrigeration system installation',
    coterieAkHash: '57a004df31ab05fdfa315b15edcbf032',
    coterieId: 10039,
  },
  {
    description: 'Radiant floor heating equipment installation',
    coterieAkHash: '5a0ae32389c1e06ef260eb98e26e86ac',
    coterieId: 10024,
  },
  {
    description: 'Heating equipment installation',
    coterieAkHash: '5d0387d862aee091d8eb1e9f78251216',
    coterieId: 10024,
  },
  {
    description: 'Sewer hookup and connection, building',
    coterieAkHash: '5f5d7fa04e6cd5e789a2711adff895ff',
    coterieId: 10537,
  },
  {
    description: 'Sheet metal duct work installation',
    coterieAkHash: '65af1a0d6be5a9122b707617b9b141b7',
    coterieId: 10024,
  },
  {
    description: 'Pipe fitting contractors',
    coterieAkHash: '66304c892ddaa6d1c42e5afafcf7d41f',
    coterieId: 10537,
  },
  {
    description: 'Central cooling equipment and piping installation',
    coterieAkHash: '67a10d85db085825ea64fbfabee10821',
    coterieId: 10024,
  },
  {
    description: 'Boiler, heating, installation',
    coterieAkHash: '67d0a7429a2675c6d49eb86e95c73332',
    coterieId: 10024,
  },
  {
    description: 'Water softener installation',
    coterieAkHash: '6cbad0f1819e671904a0073dd5577e5b',
    coterieId: 10537,
  },
  {
    description:
      'Refrigeration system (e.g., commercial, industrial, scientific) installation',
    coterieAkHash: '77f8f6d286f16251cccf1b77cb380b8f',
    coterieId: 10585,
  },
  {
    description: 'Furnace installation',
    coterieAkHash: '7bfdf44032351aa8e2b98909cf015d5d',
    coterieId: 10024,
  },
  {
    description: 'Steam fitting contractors',
    coterieAkHash: '7c8e6bdeae8e1e6a22c94d92a30cd3e3',
    coterieId: 10024,
  },
  {
    description:
      'Duct work (e.g., cooling, dust collection, exhaust, heating, ventilation) installation',
    coterieAkHash: '7eaa92614357c8a0385a205cc955ea5f',
    coterieId: 10024,
  },
  {
    description: 'Process piping installation',
    coterieAkHash: '7f2fa4ff6b784f7378cb8804ef41f0e9',
    coterieId: 10537,
  },
  {
    description: 'Water system balancing and testing contractors',
    coterieAkHash: '7fdd550197e2ce995c9292928dcd4aa1',
    coterieId: 10537,
  },
  {
    description: 'Fire extinguisher installation and repair',
    coterieAkHash: '81a0ecaf98fa09ddbad895246476ea5e',
    coterieId: 10039,
  },
  {
    description: 'Water meter installation',
    coterieAkHash: '846758cc042456e8dc2c3d2ffef770fc',
    coterieId: 10537,
  },
  {
    description: 'Lawn sprinkler system installation',
    coterieAkHash: '85ee7bdcb3090ad365d59454bf32b81f',
    coterieId: 10413,
  },
  {
    description: 'Oil burner installation',
    coterieAkHash: '8a446afcbc951cb3d9bf7af3ddb9066b',
    coterieId: 10039,
  },
  {
    description: 'Hot water tank installation',
    coterieAkHash: '8d13ad063841f890bdacfbdbc2b7afb4',
    coterieId: 10537,
  },
  {
    description: 'Fire sprinkler system installation',
    coterieAkHash: '91bad3cf9c436d59764fc0a94cedb29c',
    coterieId: 10537,
  },
  {
    description: 'Fire extinguisher installation',
    coterieAkHash: '963b85f564dde551e62945248101c6e7',
    coterieId: 10039,
  },
  {
    description: 'Hydronic heating system installation',
    coterieAkHash: '9ecc1865b7ec33d67ee9360928b0efd1',
    coterieId: 10024,
  },
  {
    description: 'Air vent installation',
    coterieAkHash: 'a36aa41456297b994dc163c7aa71cef8',
    coterieId: 10024,
  },
  {
    description: 'Plumbing and heating contractors',
    coterieAkHash: 'a3a68ce1f350381eacf5b92ed82077cf',
    coterieId: 10537,
  },
  {
    description: 'Heating and cooling duct work installation',
    coterieAkHash: 'a3eba02aa24cff99ca471cf70213e38c',
    coterieId: 10024,
  },
  {
    description: 'Hot water heating system installation',
    coterieAkHash: 'a508c92fb37cef717af8846196c77964',
    coterieId: 10537,
  },
  {
    description: 'Blower or fan, cooling and dry heating, installation',
    coterieAkHash: 'af1edc1ba5caefaa76d7b3948f50afe9',
    coterieId: 10024,
  },
  {
    description: 'Central air-conditioning equipment installation',
    coterieAkHash: 'b03c30416fbfda12b2a36d92b6c93c7c',
    coterieId: 10024,
  },
  {
    description: 'Furnace, forced air, installation',
    coterieAkHash: 'b2d79f97ce178f43ab3ceb5830c9bd9c',
    coterieId: 10024,
  },
  {
    description: 'Chimney liner installation',
    coterieAkHash: 'b2f6412c7baca60a94ce3367fe676b53',
    coterieId: 10039,
  },
  {
    description: 'Mechanical contractors',
    coterieAkHash: 'b3a601756c30f7759b3ce70e1aee5e23',
    coterieId: 10024,
  },
  {
    description:
      'Heating and ventilation system component (e.g., air registers, diffusers, filters, grilles, sound attenuators) installation',
    coterieAkHash: 'b74cf0cb3103b456b067a0fe38813f3d',
    coterieId: 10024,
  },
  {
    description: 'Furnace humidifier installation',
    coterieAkHash: 'b8cd6dece0b741e0caeddd761ccd71f1',
    coterieId: 10024,
  },
  {
    description: 'Plumbing contractors',
    coterieAkHash: 'b977fc92dc7b5436e7a79c5df4f7d9f9',
    coterieId: 10537,
  },
  {
    description: 'Boiler chipping, cleaning and scaling',
    coterieAkHash: 'b9d86fec16ab9fd52610b0531589f123',
    coterieId: 10024,
  },
  {
    description: 'Heating contractors',
    coterieAkHash: 'b9f1d354fe6f7219bb18f385a82a232e',
    coterieId: 10024,
  },
  {
    description: 'Commercial freezer installation',
    coterieAkHash: 'bbbd5faaf51ddbb145b97b56f03f85ad',
    coterieId: 10039,
  },
  {
    description: 'Commercial refrigeration system installation',
    coterieAkHash: 'bd12945a3bd3610da133ab68d58ac703',
    coterieId: 10039,
  },
  {
    description: 'Kitchen sink and hardware installation',
    coterieAkHash: 'c322faf94389b68476beb765b401a2c5',
    coterieId: 10039,
  },
  {
    description: 'Fireplace, natural gas, installation',
    coterieAkHash: 'c75cba95e17dad7e8f019ea653ae8b7c',
    coterieId: 10024,
  },
  {
    description: 'Drinking fountain installation',
    coterieAkHash: 'c9ba64c1a65c2d1ea2b9656c7beedd53',
    coterieId: 10039,
  },
  {
    description: 'Furnace conversion (i.e., from one fuel to another)',
    coterieAkHash: 'cea41f2d4ff423f37cd6b2e5ebbc214b',
    coterieId: 10024,
  },
  {
    description: 'Heating, ventilation and air-conditioning (HVAC) contractors',
    coterieAkHash: 'd164c970babf4d0449503f018e3e3faf',
    coterieId: 10024,
  },
  {
    description: 'Chilled water system installation',
    coterieAkHash: 'd723f23c3c752cfc31cbe426b3936277',
    coterieId: 10039,
  },
  {
    description: 'Central heating equipment and piping installation',
    coterieAkHash: 'd8cd9e8bfdd8de7529094220b8e779b9',
    coterieId: 10024,
  },
  {
    description: 'Drain, waste and vent system installation',
    coterieAkHash: 'de75f9ddce055b105a05dbb0b3841c62',
    coterieId: 10039,
  },
  {
    description: 'Bathroom plumbing fixture and sanitary ware installation',
    coterieAkHash: 'e5781c8c26e709ccb4df4c17edfd74b8',
    coterieId: 10537,
  },
  {
    description: 'Sump pump installation',
    coterieAkHash: 'e753c1951a3e6beeca34027b463142bc',
    coterieId: 10039,
  },
  {
    description: 'Air-conditioning system (except window) installation',
    coterieAkHash: 'ea760598820ae18be76b8e5b970217d4',
    coterieId: 10024,
  },
  {
    description: 'Plumbers',
    coterieAkHash: 'ec166bae97d3a4cfcccc7749a1b426cb',
    coterieId: 10537,
  },
  {
    description: 'Waterless fire suppression system installation',
    coterieAkHash: 'ef17aa647a0aa8e98b9cd4d61e9ab83f',
    coterieId: 10039,
  },
  {
    description: 'Cooling tower installation',
    coterieAkHash: 'f17db317cb4e60222942f5c8cb5e8ab7',
    coterieId: 10024,
  },
  {
    description: 'Scrubber, air purification, installation',
    coterieAkHash: 'f3089255d4eb010cc15dee1c43b37b62',
    coterieId: 10039,
  },
  {
    description: 'Solar heating equipment installation',
    coterieAkHash: 'faaeb9a12633650468360b504a13c7c5',
    coterieId: 10024,
  },
  {
    description: 'Warm air heating system installation',
    coterieAkHash: 'fc207bef399ddd85691cfd0a366fe582',
    coterieId: 10024,
  },
  {
    description: 'Air system balancing and testing',
    coterieAkHash: 'fedd228b25a212d1c2487d78efa99a81',
    coterieId: 10024,
  },
  {
    description: 'Boiler and pipe insulation installation',
    coterieAkHash: '02e4dc64965fc382d7e64fe13b68e5ed',
    coterieId: 10024,
  },
  {
    description: 'Bowling alley equipment installation',
    coterieAkHash: '0864481f8145698557cfef0c9949e873',
    coterieId: 10039,
  },
  {
    description: 'Power boiler, installation only',
    coterieAkHash: '18e00815c968706d8679c254fb3bb615',
    coterieId: 10024,
  },
  {
    description: 'Pipe covering',
    coterieAkHash: '215e9dfc1952f7a10401ab5fd2f75ee7',
    coterieId: 10024,
  },
  {
    description: 'Insulation, boiler, duct and pipe, installation',
    coterieAkHash: '303bd507d7f6be74de8de9a7a9ae12c8',
    coterieId: 10024,
  },
  {
    description: 'Pipe, duct and boiler insulation',
    coterieAkHash: '3125f499b6375645f9dcf541a6862e48',
    coterieId: 10024,
  },
  {
    description: 'Material handling equipment installation',
    coterieAkHash: '46ede21fe5258098fba13ce1b83c4c5a',
    coterieId: 10039,
  },
  {
    description: 'Moving sidewalk installation',
    coterieAkHash: '49b0a6846806889912ac55a4ae57fa0e',
    coterieId: 10039,
  },
  {
    description: 'Overhead door, commercial- or industrial-type, installation',
    coterieAkHash: '533e38491ee5f6a4a721bed184fa56c9',
    coterieId: 10214,
  },
  {
    description: 'Lightning rod and conductor installation',
    coterieAkHash: '5819f0804dc83f2838be455fa335376c',
    coterieId: 10039,
  },
  {
    description: 'Door, commercial- or industrial-type, installation',
    coterieAkHash: '5f0e0782bb376dc5e41fd0d6a71f7a05',
    coterieId: 10039,
  },
  {
    description: 'Water pipe insulating',
    coterieAkHash: '691f4bf5caaabfa81de80125df441cf0',
    coterieId: 10537,
  },
  {
    description: 'Church bell and tower clock installation',
    coterieAkHash: '6933d932a670fea9bb0afedce1cc010d',
    coterieId: 10039,
  },
  {
    description: 'Deodorization (i.e., air filtration) system installation',
    coterieAkHash: '69a05cb7c97e22fd585465ebb1d89d64',
    coterieId: 10039,
  },
  {
    description: 'ATMs (automatic teller machines) installation',
    coterieAkHash: '6d1cfcc368a966e08597df5789e0d222',
    coterieId: 10039,
  },
  {
    description: 'Automated and revolving door installation',
    coterieAkHash: '6f73a56365d82a9d0139c4ddf0b8275d',
    coterieId: 10039,
  },
  {
    description: 'Dumbwaiter installation',
    coterieAkHash: '7e86aa3528c090d054733e9943f12255',
    coterieId: 10039,
  },
  {
    description: 'Conveyor system installation',
    coterieAkHash: '83fb94bfedebef916214bba08e31b3a7',
    coterieId: 10039,
  },
  {
    description: 'Vending machine installation',
    coterieAkHash: '896f7abc2913df0e51f058d69daa03c3',
    coterieId: 10039,
  },
  {
    description: 'Automatic gate (e.g., garage, parking lot) installation',
    coterieAkHash: '8d2290917b1805c4426faae3553a458d',
    coterieId: 10039,
  },
  {
    description: 'Commercial-type door installation',
    coterieAkHash: '90e96d89d5778e78e9380354cc9b05b3',
    coterieId: 10039,
  },
  {
    description: 'Mechanical equipment insulation',
    coterieAkHash: '92ea867adf1b708e08cac57d86245329',
    coterieId: 10024,
  },
  {
    description: 'Pneumatic tube conveyor system installation',
    coterieAkHash: 'a08edd8dfe29f2f8c9fcffef960fdac1',
    coterieId: 10024,
  },
  {
    description: 'Satellite dish, household-type, installation',
    coterieAkHash: 'a4663b2a7502bb2f0a7d4578b970c7e1',
    coterieId: 10041,
  },
  {
    description: 'Rubber door installation',
    coterieAkHash: 'aa46f1f8bfaac274b7f91e78fe2f488f',
    coterieId: 10214,
  },
  {
    description: 'Power generating equipment installation',
    coterieAkHash: 'b22f62067ef227cfe4d739d0e62cad09',
    coterieId: 10039,
  },
  {
    description:
      'Lightning protection equipment (e.g., lightning rod) installation',
    coterieAkHash: 'b608eeff309b39e3a4a56d6912dcfb61',
    coterieId: 10039,
  },
  {
    description: 'Millwrights',
    coterieAkHash: 'b6c371b3ac81365ce1d8c5ab77b59c3b',
    coterieId: 10039,
  },
  {
    description: 'Garage door, commercial- or industrial-type, installation',
    coterieAkHash: 'b80a5ab66aa8d2e0e319ed9e89717467',
    coterieId: 10039,
  },
  {
    description: 'Boiler covering installation',
    coterieAkHash: 'bbc5829268c9edea134a245341657924',
    coterieId: 10024,
  },
  {
    description: 'Duct insulation installation',
    coterieAkHash: 'bd7da63846212ac9174d339e570271fc',
    coterieId: 10024,
  },
  {
    description: 'Hoisting and placement of large-scale apparatus',
    coterieAkHash: 'c059c0840cbce148d1e297ba14e7f609',
    coterieId: 10039,
  },
  {
    description: 'Vacuum cleaning system, built-in, installation',
    coterieAkHash: 'd4869d471696e3abb439fbf4caba555b',
    coterieId: 10039,
  },
  {
    description: 'Revolving door installation',
    coterieAkHash: 'd641343638041f9ff80359ae63d8cf7f',
    coterieId: 10214,
  },
  {
    description: 'Incinerators, building equipment type, installation',
    coterieAkHash: 'e0d0d184b6aa599fb4820718b278f558',
    coterieId: 10039,
  },
  {
    description: 'Dismantling large-scale machinery and equipment',
    coterieAkHash: 'e47de294f9e8b166d0de5ca355dba8e1',
    coterieId: 10039,
  },
  {
    description: 'Vault, safe and banking machine installation',
    coterieAkHash: 'e77e1687c9bb037b36697a81a62473b5',
    coterieId: 10039,
  },
  {
    description:
      'Commercial kitchen food preparation equipment (e.g., mixers, ovens, stoves) installation',
    coterieAkHash: 'e7cfe7193d15c77990ff566bf6875a42',
    coterieId: 10039,
  },
  {
    description: 'Antenna, household-type, installation',
    coterieAkHash: 'eeecfabd2394bf98b010b73169964f7f',
    coterieId: 10041,
  },
  {
    description: 'Rigging large-scale equipment',
    coterieAkHash: 'f1db9af23e5122971975a8bf8043019e',
    coterieId: 10039,
  },
  {
    description: 'Attic space insulating',
    coterieAkHash: '1612c51dd496f3f5e8e32fc31e439117',
    coterieId: 10041,
  },
  {
    description: 'House painting',
    coterieAkHash: '5cfa1bfa977b2c605a84031d9fe9670e',
    coterieId: 10501,
  },
  {
    description: 'Parquet flooring installation',
    coterieAkHash: '11c64d7e6de656cb30399e4b77e07f85',
    coterieId: 10283,
  },
  {
    description: 'Slate, interior, installation',
    coterieAkHash: '19b13a898159f583d6ac0df04c749877',
    coterieId: 10696,
  },
  {
    description: 'Finish carpentry',
    coterieAkHash: '000a012549fc1716c0185c83f28fc666',
    coterieId: 10112,
  },
  {
    description: 'Vibration isolation contractors',
    coterieAkHash: '2418679ef022581db95f4925cdf3456a',
    coterieId: 10039,
  },
  {
    description: 'Parking lot paving and sealing',
    coterieAkHash: '0333a66fe00feabcbbfcebd519fd157d',
    coterieId: 10220,
  },
  {
    description:
      'Concrete paving, residential and commercial driveway and parking area',
    coterieAkHash: '0d9f92516eaae756a2936295e6fd9792',
    coterieId: 10220,
  },
  {
    description:
      'Brick paver (e.g., driveways, patios, sidewalks) installation',
    coterieAkHash: '132d9856ee1179fad08260266de3ecf6',
    coterieId: 10220,
  },
  {
    description:
      'Blacktop work, residential and commercial driveway and parking area',
    coterieAkHash: '16d4ae64e08fc36dc67b44de066d4920',
    coterieId: 10220,
  },
  {
    description: 'Sidewalk construction, residential and commercial',
    coterieAkHash: '1efa6c58eb8924894cb1d511698c94df',
    coterieId: 10157,
  },
  {
    description: 'Flagpole installation',
    coterieAkHash: '22bf3692e49e9ffe8d9e2870830e9841',
    coterieId: 10406,
  },
  {
    description: 'Driveway paving or sealing',
    coterieAkHash: '25df6c2dfa3b2a13328e0a0ff219425e',
    coterieId: 10220,
  },
  {
    description: 'Paving, residential and commercial driveway and parking lot',
    coterieAkHash: '3aedf7979194960314c37e28b6fd4a77',
    coterieId: 10220,
  },
  {
    description: 'Chain link fence installation',
    coterieAkHash: '432f5f9159313261da0777bd357af5c6',
    coterieId: 10268,
  },
  {
    description:
      'Fence installation (except electronic containment fencing for pets)',
    coterieAkHash: '4bab74a4899ae4d85a5c535f7bf3965d',
    coterieId: 10268,
  },
  {
    description: 'Culvert, concrete, residential and commercial paved area',
    coterieAkHash: '56224563f6f4ac474398dbe6e72d5d7d',
    coterieId: 10157,
  },
  {
    description: 'Concrete patio construction',
    coterieAkHash: '5e11b5b9d9b9829307ca269e647de647',
    coterieId: 10110,
  },
  {
    description:
      'Fencing contractors (except electronic containment fencing for pets)',
    coterieAkHash: '62023ad2b5c0ca50bd94717a8ef0d9d9',
    coterieId: 10268,
  },
  {
    description: 'Brick driveway contractors',
    coterieAkHash: '620661528e7fb04ebfae4567b7ff0fa7',
    coterieId: 10220,
  },
  {
    description:
      'Street, interlocking brick (i.e., not mortared), installation',
    coterieAkHash: '6a88b380ec2fc114552055c5702c74c6',
    coterieId: 10220,
  },
  {
    description:
      'Asphalt coating and sealing, residential and commercial parking lot and driveway',
    coterieAkHash: '7a62c920948f759f07cfc552784bc003',
    coterieId: 10220,
  },
  {
    description:
      'Curb and gutter construction, residential and commercial driveway and parking area, concrete',
    coterieAkHash: '8377c2f79ed626c06b1a88d6ddd78c11',
    coterieId: 10220,
  },
  {
    description: 'Pole (e.g., telephone) removal',
    coterieAkHash: '8b0aa44633e0b3d7baf34450c6c39e0a',
    coterieId: 10406,
  },
  {
    description: 'Mail box units, outdoor, multiple box-type, erection',
    coterieAkHash: '8fd51a5861872027fe7436566cdae69c',
    coterieId: 10406,
  },
  {
    description:
      'Asphalting, residential and commercial driveway and parking area',
    coterieAkHash: '9786e6059aedc2983a3e6c44c458b378',
    coterieId: 10220,
  },
  {
    description: 'Interlocking brick and block installation',
    coterieAkHash: 'be6f844882eea68f5357df62bfddaebc',
    coterieId: 10110,
  },
  {
    description: 'Posthole digging',
    coterieAkHash: 'c9966355872592f11c4e3873346bd06f',
    coterieId: 10110,
  },
  {
    description: 'Concrete sawing and drilling (except demolition)',
    coterieAkHash: 'd7ed66fffca17a8d3583734925fcce19',
    coterieId: 10157,
  },
  {
    description: 'Patio construction',
    coterieAkHash: 'e47073b2673fc29697421fd86e764cef',
    coterieId: 10110,
  },
  {
    description: 'Sandblasting, building exterior',
    coterieAkHash: 'e7e090bcf1da05da81afffc359594d62',
    coterieId: 10110,
  },
  {
    description: 'Paver, brick (e.g., driveway, patio, sidewalk), installation',
    coterieAkHash: 'e83bdb03b52699037ccf76d1c646b593',
    coterieId: 10220,
  },
  {
    description: 'Artificial turf installation',
    coterieAkHash: '3730d421db74813217ed5116d05fd940',
    coterieId: 10406,
  },
  {
    description: 'Cable splicing (except electrical or fiber optic)',
    coterieAkHash: '4180f465c6a58c63bba9e65361143f11',
    coterieId: 10039,
  },
  {
    description:
      'Cleaning building interiors during and immediately after construction',
    coterieAkHash: '217da37e1e96d7d7423384c18f538af1',
    coterieId: 10588,
  },
  {
    description:
      'Cleaning new building interiors immediately after construction',
    coterieAkHash: 'e9c97954120c5f1992197aa03164db1a',
    coterieId: 10588,
  },
  {
    description: 'Playground equipment installation',
    coterieAkHash: 'c32edd47da46d9e4e476e87e532167d9',
    coterieId: 10406,
  },
  {
    description:
      'Safety net system, erecting and dismantling at construction site',
    coterieAkHash: '6edbeb5b1e2f70c6e81722adcf706ba5',
    coterieId: 10039,
  },
  {
    description: 'Turf, artificial, installation',
    coterieAkHash: '8c151865239bf9e856f78b613598e9b0',
    coterieId: 10406,
  },
  {
    description: 'Commercial printing (except screen, books)',
    coterieAkHash: '43540fc5c9620922544327d61866b11f',
    coterieId: 10555,
  },
  {
    description:
      'Printing, screen (except books, manifold business forms, grey goods)',
    coterieAkHash: '0deb3d1c2be4aa173f9ab1b13f614672',
    coterieId: 10555,
  },
  {
    description: 'Pamphlets printing without publishing',
    coterieAkHash: '1732e93f1c8d3987cb6e2d4a01e695dc',
    coterieId: 10555,
  },
  {
    description:
      'Postpress services (e.g., beveling, bronzing, folding, gluing, edging, foil stamping, gilding) on printed materials',
    coterieAkHash: '03830ad8cbd414af62f553c6e4ccd3b5',
    coterieId: 10555,
  },
  {
    description: 'Mirrors, automotive, merchant wholesalers',
    coterieAkHash: '0b0e9ba8d993ea657d4a1f6900b606e7',
    coterieId: 10063,
  },
  {
    description: 'Religious furniture merchant wholesalers',
    coterieAkHash: '05404fb96e28d4c1f657f76fe0d9bf5a',
    coterieId: 10034,
  },
  {
    description: 'Napkins (except paper) merchant wholesalers',
    coterieAkHash: '0191210071ab7b35ae25657a50f3fddd',
    coterieId: 10034,
  },
  {
    description: 'Kitchen cabinets, built-in, merchant wholesalers',
    coterieAkHash: '10227d65b444d5945fe67e14ffb2c10f',
    coterieId: 10320,
  },
  {
    description: 'Lumber (e.g., dressed, finished, rough) merchant wholesalers',
    coterieAkHash: '19ee967a7e0bde2f145ded55c68a7c67',
    coterieId: 10320,
  },
  {
    description: 'Molding (e.g., sheet metal, wood) merchant wholesalers',
    coterieAkHash: '21095f65a45cf3c1b2c5994ea50e258b',
    coterieId: 10320,
  },
  {
    description: 'Screens, window and door, merchant wholesalers',
    coterieAkHash: '217cb6d3742fbfb1f89ef309b2b7f99a',
    coterieId: 10320,
  },
  {
    description: 'Plywood merchant wholesalers',
    coterieAkHash: '21a9189a0b272716b1d16e9229435bb3',
    coterieId: 10320,
  },
  {
    description: 'Wood flooring merchant wholesalers',
    coterieAkHash: '36e4cab4b228499874ce313b4f553456',
    coterieId: 10320,
  },
  {
    description: 'Fiberboard merchant wholesalers',
    coterieAkHash: '3a260220dd1bead62fe02cf630c9c0fd',
    coterieId: 10320,
  },
  {
    description: 'Wood shingles merchant wholesalers',
    coterieAkHash: '40c237957f8db20b9c61577e895b9462',
    coterieId: 10320,
  },
  {
    description: 'Veneer, wood, merchant wholesalers',
    coterieAkHash: '50abc8a3f379c426d03e4460cbd4d415',
    coterieId: 10320,
  },
  {
    description: 'Drywall board merchant wholesalers',
    coterieAkHash: '64516136bf6c1643f82d6ae83de3d92e',
    coterieId: 10320,
  },
  {
    description: 'Doors and door frames merchant wholesalers',
    coterieAkHash: '68c09560173ecf61a663d5353151c758',
    coterieId: 10320,
  },
  {
    description: 'Railings, wood, merchant wholesalers',
    coterieAkHash: '7e8706424eb33ffb7524fb9e62a5f088',
    coterieId: 10320,
  },
  {
    description: 'Fencing, wood, merchant wholesalers',
    coterieAkHash: '87298915ed1b897bf00cdbbea00898b1',
    coterieId: 10320,
  },
  {
    description: 'Paneling merchant wholesalers',
    coterieAkHash: '8780bb1a41b986486f13313e01fd289d',
    coterieId: 10320,
  },
  {
    description: 'Wallboard merchant wholesalers',
    coterieAkHash: '87fcb5bd1c41c4cef59789daf8cb43c3',
    coterieId: 10320,
  },
  {
    description: 'Stairs, wood, merchant wholesalers',
    coterieAkHash: '8aa3c6b76d8944c9178ea8836a537fb1',
    coterieId: 10320,
  },
  {
    description:
      'Building board (e.g., fiber, flake, particle) merchant wholesalers',
    coterieAkHash: '8d2bc513a5eed39b8a522b1d9fdd127c',
    coterieId: 10320,
  },
  {
    description: 'Particleboard merchant wholesalers',
    coterieAkHash: '9d4da9be4a01f1ba32b3c8d802150fb4',
    coterieId: 10320,
  },
  {
    description: 'Millwork merchant wholesalers',
    coterieAkHash: 'a0cefdf273c497e4d9f97b936e7da0ff',
    coterieId: 10320,
  },
  {
    description: 'Laminates, wood, merchant wholesalers',
    coterieAkHash: 'a1fc9f2980dab03d9a2ded24c9d3c0b7',
    coterieId: 10281,
  },
  {
    description: 'Countertops (except granite) merchant wholesalers',
    coterieAkHash: 'ac3a9b8f5791f65cb5a75f39ce766849',
    coterieId: 10320,
  },
  {
    description: 'Wood siding merchant wholesalers',
    coterieAkHash: 'b0d464560a0fc9aefe8a2055ad3999ca',
    coterieId: 10320,
  },
  {
    description: 'Cabinets, kitchen, built-in, merchant wholesalers',
    coterieAkHash: 'ccada6195b60e091a63dc776764def54',
    coterieId: 10320,
  },
  {
    description: 'Wood fencing merchant wholesalers',
    coterieAkHash: 'db0e7886029eb17254cb20175e75974b',
    coterieId: 10320,
  },
  {
    description: 'Flooring, wood, merchant wholesalers',
    coterieAkHash: 'f0cda0df4bbba841abc3ec442f5e5f91',
    coterieId: 10281,
  },
  {
    description: 'Buildings, prefabricated wood, merchant wholesalers',
    coterieAkHash: 'ff8f06fe1e5af7576f7756dc5dc96204',
    coterieId: 10320,
  },
  {
    description: 'Siding, wood, merchant wholesalers',
    coterieAkHash: 'ffe80f83c6205c26b0d116ea5f571d9c',
    coterieId: 10320,
  },
  {
    description: 'Cement merchant wholesalers',
    coterieAkHash: '1c00ada754efad51ab9bfdcad0f83636',
    coterieId: 10320,
  },
  {
    description: 'Siding (except wood) merchant wholesalers',
    coterieAkHash: '062f8980a2024b88703eba2ac844dfc1',
    coterieId: 10320,
  },
  {
    description: 'Ceramic and clay roofing materials merchant wholesalers',
    coterieAkHash: '227631f1aea5dbb727ea57c62c8636c7',
    coterieId: 10320,
  },
  {
    description: 'Weather stripping merchant wholesalers',
    coterieAkHash: '3579d83803bbeccf08e2a104d4a36cc2',
    coterieId: 10320,
  },
  {
    description: 'Eavestroughing merchant wholesalers',
    coterieAkHash: '4776f9a9a30f084e986e18d113d9f513',
    coterieId: 10320,
  },
  {
    description: 'House wrapping insulation materials merchant wholesalers',
    coterieAkHash: '62ba523e58f1a5a53cf4dfcd1d8ac6b4',
    coterieId: 10320,
  },
  {
    description: 'Sheet metal roofing materials merchant wholesalers',
    coterieAkHash: '6ed9f72a820fd21964eb1b6b7af23ce8',
    coterieId: 10320,
  },
  {
    description: 'Shingles (except wood) merchant wholesalers',
    coterieAkHash: '8043c020cebb9211de081aa58d09c5ac',
    coterieId: 10320,
  },
  {
    description: 'Gutters and down spouts (except wood) merchant wholesalers',
    coterieAkHash: '958ad5fd199350e7980c2e037f6ab2c1',
    coterieId: 10320,
  },
  {
    description: 'Asphalt roofing shingles merchant wholesalers',
    coterieAkHash: '961a70018d3cb4183f2392ee05f3632d',
    coterieId: 10320,
  },
  {
    description: 'Thermal wrap, house, merchant wholesalers',
    coterieAkHash: 'a8cf927f2766c084575c3c8190f3d8e1',
    coterieId: 10320,
  },
  {
    description: 'Vinyl siding merchant wholesalers',
    coterieAkHash: 'ceabb57293cf4b4fb8f4a9d25bf59188',
    coterieId: 10320,
  },
  {
    description: 'Tarred felts merchant wholesalers',
    coterieAkHash: 'd6559b804b923ad8c226a58a3f4d2ff8',
    coterieId: 10320,
  },
  {
    description: 'Sound insulation merchant wholesalers',
    coterieAkHash: 'd6e2edca53c588305ce28f90c4e61e12',
    coterieId: 10320,
  },
  {
    description: 'Soffit, building (except wood), merchant wholesalers',
    coterieAkHash: 'e872f0337c0b13febe84279847c13dcd',
    coterieId: 10320,
  },
  {
    description: 'Asphalt felts and coatings merchant wholesalers',
    coterieAkHash: 'e904a220fcd9073620567d23f1bdf288',
    coterieId: 10320,
  },
  {
    description: 'Insulation materials (except wood) merchant wholesalers',
    coterieAkHash: 'ecf7ec7ac0e3223412e5cb28c908ff2e',
    coterieId: 10320,
  },
  {
    description: 'Bailey bridges merchant wholesalers',
    coterieAkHash: '0430f5a1914b11d71291b174c6bb0c72',
    coterieId: 10320,
  },
  {
    description: 'Fencing and fencing accessories, wire, merchant wholesalers',
    coterieAkHash: '093988d394f6643d29b3474b944ae686',
    coterieId: 10320,
  },
  {
    description: 'Ornamental ironwork merchant wholesalers',
    coterieAkHash: '0c630a9716be6ab319a258c7c7619992',
    coterieId: 10320,
  },
  {
    description: 'Wire fencing and fencing accessories merchant wholesalers',
    coterieAkHash: '137dd6108a2078371bd17504577bbf9b',
    coterieId: 10320,
  },
  {
    description:
      'Silt fence and other fabrics (e.g., for erosion control) merchant wholesalers',
    coterieAkHash: '1d783ec3f41d1b0e065ff856d10843d4',
    coterieId: 10320,
  },
  {
    description: 'Septic tanks (except concrete) merchant wholesalers',
    coterieAkHash: '4244db03e8e5fb5efd0b6f4577f4ea95',
    coterieId: 10320,
  },
  {
    description: 'Glass, plate, merchant wholesalers',
    coterieAkHash: '4386337e43f2a171af9257403de6803b',
    coterieId: 10320,
  },
  {
    description: 'Paper, building, merchant wholesalers',
    coterieAkHash: '44f84b628203c2c5423c18b9d349b1a8',
    coterieId: 10320,
  },
  {
    description: 'Gypsum building products merchant wholesalers',
    coterieAkHash: '621738f1739cce57a116252910974fee',
    coterieId: 10320,
  },
  {
    description: 'Flat glass merchant wholesalers',
    coterieAkHash: '62241ff0aa3eb6bc83120cd734af2f56',
    coterieId: 10320,
  },
  {
    description: 'Mastics, construction, merchant wholesalers',
    coterieAkHash: '70fba10adb39283c971de5f8752b4a4a',
    coterieId: 10320,
  },
  {
    description:
      'Fiberglass building materials (except insulation, roofing, siding) merchant wholesalers',
    coterieAkHash: '772d5968643e6847addff78f41c6f65e',
    coterieId: 10320,
  },
  {
    description: 'Iron and steel architectural shapes merchant wholesalers',
    coterieAkHash: '79b566cea6004c2ca0a05f59020774d7',
    coterieId: 10320,
  },
  {
    description: 'Gate and fence hardware merchant wholesalers',
    coterieAkHash: '7fca082ba43c2b70ea9d183ccf202342',
    coterieId: 10320,
  },
  {
    description: 'Architectural metalwork merchant wholesalers',
    coterieAkHash: '9c5d7e5dcdbab3d4fbb5800bb6210c3c',
    coterieId: 10320,
  },
  {
    description: 'Storage bins merchant wholesalers',
    coterieAkHash: 'a8cad6e4f32cd1f47ab7e64d71b88d5e',
    coterieId: 10320,
  },
  {
    description: 'Bins, storage, merchant wholesalers',
    coterieAkHash: 'ad8f1b9e6abed864f6acd6be732558b2',
    coterieId: 10320,
  },
  {
    description: 'Glass, block and brick, merchant wholesalers',
    coterieAkHash: 'cbd8b10c7923eecb025e4065866ebd11',
    coterieId: 10320,
  },
  {
    description: 'Building paper merchant wholesalers',
    coterieAkHash: 'cf60d9592160ccefc1dabf6168fcc1ec',
    coterieId: 10320,
  },
  {
    description: 'Awnings (except canvas) merchant wholesalers',
    coterieAkHash: 'd6ed798d8df08f91876d9c7d311d7d18',
    coterieId: 10320,
  },
  {
    description: 'Plate glass merchant wholesalers',
    coterieAkHash: 'd90db36e4aaa2d953f9a67cecca6c989',
    coterieId: 10320,
  },
  {
    description: 'Fencing (except wood) merchant wholesalers',
    coterieAkHash: 'e4d66db1598b7bde5791f4ea6a7775cd',
    coterieId: 10320,
  },
  {
    description: 'Ceiling tile merchant wholesalers',
    coterieAkHash: 'ee167ca5e8d54f0f4c28f26fafa9854d',
    coterieId: 10320,
  },
  {
    description: 'Photofinishing equipment merchant wholesalers',
    coterieAkHash: '07c30ecb7cacd014534769ff8d828883',
    coterieId: 10035,
  },
  {
    description: 'Security safes merchant wholesalers',
    coterieAkHash: '00264ad66e76a0b254c05bcde0df875e',
    coterieId: 10252,
  },
  {
    description: 'Computer printers merchant wholesalers',
    coterieAkHash: '035ce83c2ed5efb88ad7d0d60b5243d7',
    coterieId: 10252,
  },
  {
    description: 'Mannequins merchant wholesalers',
    coterieAkHash: '0b1ab6fab617fcc1a6e705d16d19e44c',
    coterieId: 10253,
  },
  {
    description: 'Hospital furniture merchant wholesalers',
    coterieAkHash: '015514a18873228efeb459cfec895683',
    coterieId: 10327,
  },
  {
    description: 'Optical goods (except cameras) merchant wholesalers',
    coterieAkHash: '09123442162825da4fc9e20a7e2be5b2',
    coterieId: 10327,
  },
  {
    description: 'Blackboards merchant wholesalers',
    coterieAkHash: '07e16f2e53d41c3b615bdef9679306e5',
    coterieId: 10252,
  },
  {
    description: 'Poles, metal, merchant wholesalers',
    coterieAkHash: '0bb66f9002b08f43f53e6355269f9455',
    coterieId: 10320,
  },
  {
    description: 'Copper and copper alloy primary forms merchant wholesalers',
    coterieAkHash: '1a4237fd425692f4bd30503cc0f6488c',
    coterieId: 10320,
  },
  {
    description: 'Nails merchant wholesalers',
    coterieAkHash: '387c0f789d92ee68636e8a2e9445dea8',
    coterieId: 10320,
  },
  {
    description: 'Metals, ferrous and nonferrous, merchant wholesalers',
    coterieAkHash: '387ed3ec0aa1077eafaeeae8fe39d8e5',
    coterieId: 10320,
  },
  {
    description: 'Posts, metal, merchant wholesalers',
    coterieAkHash: '415b67213b56a10b5f2563dc680b833a',
    coterieId: 10320,
  },
  {
    description:
      'Aluminum foil, plate, sheet, coil, and roll products merchant wholesalers',
    coterieAkHash: '48536007b78a3033bad2824eb39830b6',
    coterieId: 10320,
  },
  {
    description: 'Black plate merchant wholesalers',
    coterieAkHash: '55b9f32d1813734662da5ad9887e44f5',
    coterieId: 10320,
  },
  {
    description: 'Ferrous metals merchant wholesalers',
    coterieAkHash: '5c50c4b07f0ebb63ed9508e1dd2f07f6',
    coterieId: 10320,
  },
  {
    description: 'Grinding balls merchant wholesalers',
    coterieAkHash: '6329eb9a09c16ca8d153647356438c40',
    coterieId: 10320,
  },
  {
    description: 'Billets, metal, merchant wholesalers',
    coterieAkHash: '67e7114ae4a41b75d3011aa41a2c2e2f',
    coterieId: 10320,
  },
  {
    description: 'Steel wire cloth (screening) merchant wholesalers',
    coterieAkHash: '6ab513114decb801975c3efa45e8716a',
    coterieId: 10320,
  },
  {
    description: 'Wire screening merchant wholesalers',
    coterieAkHash: '73119010a38f27d8d1120fe43b859854',
    coterieId: 10320,
  },
  {
    description: 'Tin plate merchant wholesalers',
    coterieAkHash: '74de3bbaa65099883b74f000c994905e',
    coterieId: 10320,
  },
  {
    description: 'Strips, metal (except precious), merchant wholesalers',
    coterieAkHash: '758c95c45704d3d42bff134cbcffad0f',
    coterieId: 10320,
  },
  {
    description: 'Rails and accessories, metal, merchant wholesalers',
    coterieAkHash: '75c46096fafb88b51e0a41f7876f0d38',
    coterieId: 10320,
  },
  {
    description: 'Galvanized iron and steel products merchant wholesalers',
    coterieAkHash: '76e7cc36fcde8ef31e33896a159098dc',
    coterieId: 10320,
  },
  {
    description: 'Metals sales offices',
    coterieAkHash: '789adc592e7a84e7f197d930c6fd37f8',
    coterieId: 10320,
  },
  {
    description: 'Blooms, metal, merchant wholesalers',
    coterieAkHash: '7907817a55e6769d08ab15ae6cb1657c',
    coterieId: 10320,
  },
  {
    description: 'Storage tanks, metal, merchant wholesalers',
    coterieAkHash: '79e8db9485ff25acc1f4fce65dd1c699',
    coterieId: 10320,
  },
  {
    description: 'Wire and cable (except electrical) merchant wholesalers',
    coterieAkHash: '8345d5ee8c99d01b2c37311e6585918c',
    coterieId: 10320,
  },
  {
    description: 'Steel merchant wholesalers',
    coterieAkHash: '87ff8536c915cde5dcb2f7cb1a3308c8',
    coterieId: 10320,
  },
  {
    description: 'Metal pipe merchant wholesalers',
    coterieAkHash: '89b6fd3639a59b23f58c8262f7286eb5',
    coterieId: 10320,
  },
  {
    description:
      'Metal products (e.g., bars, ingots, plates, rods, shapes, sheets) merchant wholesalers',
    coterieAkHash: '8ac225c6dab788a9d2ebef7fcd9d17da',
    coterieId: 10320,
  },
  {
    description: 'Pipe, metal, merchant wholesalers',
    coterieAkHash: '8ea153740b40ab04ac22d1566d8ce039',
    coterieId: 10320,
  },
  {
    description: 'Ropes, wire (except insulated), merchant wholesalers',
    coterieAkHash: '94cd365edaf1945ffd87ec4d2cd14beb',
    coterieId: 10320,
  },
  {
    description: 'Bars, metal (except precious), merchant wholesalers',
    coterieAkHash: 'a2983a26dc9ef17fe6799c0bb71c727c',
    coterieId: 10320,
  },
  {
    description: 'Tanks, storage metal, merchant wholesalers',
    coterieAkHash: 'aaf8df0fe9664747aec9f216a7b0e563',
    coterieId: 10320,
  },
  {
    description: 'Castings, metal, merchant wholesalers',
    coterieAkHash: 'ae518372aa1e8b111da585bba796d3b0',
    coterieId: 10320,
  },
  {
    description: 'Carbon and alloy steel primary forms merchant wholesalers',
    coterieAkHash: 'aef4b7d33706fa5690d6d9788c9a0afd',
    coterieId: 10320,
  },
  {
    description:
      'Aluminum and aluminum alloy primary forms merchant wholesalers',
    coterieAkHash: 'b7a4799334b850d484e182e719ca0d79',
    coterieId: 10320,
  },
  {
    description: 'Pilings, metal, merchant wholesalers',
    coterieAkHash: 'b996993238fd84001c4d67ee5fc8a43d',
    coterieId: 10320,
  },
  {
    description: 'Noninsulated wire merchant wholesalers',
    coterieAkHash: 'b9efab151acfbcb3244fbb3e297479f0',
    coterieId: 10320,
  },
  {
    description: 'Terneplate merchant wholesalers',
    coterieAkHash: 'c394e8d98b55c1cf2e5aa12e642d7116',
    coterieId: 10320,
  },
  {
    description: 'Wire (except insulated) merchant wholesalers',
    coterieAkHash: 'c60cd6e385f02a0021a333ee3157fbfb',
    coterieId: 10320,
  },
  {
    description: 'Semi-finished metal products merchant wholesalers',
    coterieAkHash: 'c64fe532030a96202bb741fbb36f7982',
    coterieId: 10320,
  },
  {
    description: 'Cable, wire (except insulated), merchant wholesalers',
    coterieAkHash: 'c73676fd3ad6c752b3313e3b1829826b',
    coterieId: 10320,
  },
  {
    description: 'Tin and tin alloy primary forms merchant wholesalers',
    coterieAkHash: 'cd1fb23cd114ab0e3ee2b4ccf2aa4569',
    coterieId: 10320,
  },
  {
    description: 'Spikes, metal, merchant wholesalers',
    coterieAkHash: 'cfafadf3d44c32bc53c020aded4b3070',
    coterieId: 10320,
  },
  {
    description: 'Steel wool merchant wholesalers',
    coterieAkHash: 'd048ba0b12e42921922d37b96a4e3b1e',
    coterieId: 10320,
  },
  {
    description: 'Cast iron pipe merchant wholesalers',
    coterieAkHash: 'd221f057d011df03aaf68162664a77cd',
    coterieId: 10320,
  },
  {
    description: 'Bearing piles, metal, merchant wholesalers',
    coterieAkHash: 'd5d881ae932ead8403292150609c0c12',
    coterieId: 10320,
  },
  {
    description: 'Stainless steel merchant wholesalers',
    coterieAkHash: 'e362ac00b2b4b73e85e43768f670bbcf',
    coterieId: 10320,
  },
  {
    description: 'Rods, metal (except precious), merchant wholesalers',
    coterieAkHash: 'ef030fdc986db7de3daa66dacb0e4a97',
    coterieId: 10320,
  },
  {
    description: 'Tubing, metal, merchant wholesalers',
    coterieAkHash: 'f44d74d129fcf58fb76747155185f227',
    coterieId: 10320,
  },
  {
    description: 'Ferroalloys merchant wholesalers',
    coterieAkHash: 'fafa72f7a1d7f10c43ed305759ec34c7',
    coterieId: 10320,
  },
  {
    description: 'Reinforcement mesh and wire merchant wholesalers',
    coterieAkHash: 'fcf6c644c03de352762d041c48c86a74',
    coterieId: 10320,
  },
  {
    description: 'Concrete reinforcing bars merchant wholesalers',
    coterieAkHash: 'fdd8bb2a7ff4af5bdd15b50398433131',
    coterieId: 10320,
  },
  {
    description: 'Lugs and connectors, electrical, merchant wholesalers',
    coterieAkHash: '0180e6a834c74bb70f48dfd594840fbe',
    coterieId: 10320,
  },
  {
    description:
      'Household-type laundry equipment (e.g., dryers, washers) merchant wholesalers',
    coterieAkHash: '0347ea010813c15720cbe5b5f7ffbec8',
    coterieId: 10034,
  },
  {
    description: 'Electronic communications equipment merchant wholesalers',
    coterieAkHash: '023c68bb8e4f60f63d109df0dbcafda2',
    coterieId: 10320,
  },
  {
    description: 'Staples merchant wholesalers',
    coterieAkHash: '03db97b289e35589c7667eec2ad57cca',
    coterieId: 10320,
  },
  {
    description:
      'Fountains, drinking (except refrigerated), merchant wholesalers',
    coterieAkHash: '00810332e23c2a339e782ce20802365c',
    coterieId: 10541,
  },
  {
    description: 'Warm air heating equipment merchant wholesalers',
    coterieAkHash: '07c2220f4fc29f0fc3bf5e2d9ee0b16a',
    coterieId: 10329,
  },
  {
    description: 'Soda fountain fixtures, refrigerated, merchant wholesalers',
    coterieAkHash: '0b11fd12571bb339ad5a6e70e9e66560',
    coterieId: 10253,
  },
  {
    description: 'Mops, industrial, merchant wholesalers',
    coterieAkHash: '003ff148f7058c8984af65c0e5c2311b',
    coterieId: 10392,
  },
  {
    description: 'Funeral home supplies merchant wholesalers',
    coterieAkHash: '254aae570e94d0e65e2f0a733dbbb2da',
    coterieId: 10490,
  },
  {
    description: 'Beauty parlor equipment and supplies merchant wholesalers',
    coterieAkHash: '3d6c8fcfe2ddc7bb6a74110d6dee529d',
    coterieId: 10068,
  },
  {
    description: 'Hair preparations, professional, merchant wholesalers',
    coterieAkHash: '434816c50a511f54921c1a77ea48fe46',
    coterieId: 10068,
  },
  {
    description: 'Taxidermy supplies merchant wholesalers',
    coterieAkHash: '0f770570c179e99a879de42f862ac592',
    coterieId: 10490,
  },
  {
    description: 'Boot and shoe cut stock and findings merchant wholesalers',
    coterieAkHash: '11445836c883fcb3eee86ce82b2118dc',
    coterieId: 10253,
  },
  {
    description: 'Burial caskets merchant wholesalers',
    coterieAkHash: '616124c4341125b23b0b776e545a74f5',
    coterieId: 10490,
  },
  {
    description:
      'Service establishment equipment and supplies merchant wholesalers',
    coterieAkHash: '1c186c0a5edf2090bc3bf7d4663eeb9d',
    coterieId: 10490,
  },
  {
    description: 'Manicurists supplies merchant wholesalers',
    coterieAkHash: '6d08a2eb83b113a3892a7b177a0ebc0f',
    coterieId: 10068,
  },
  {
    description: 'Barber shop equipment and supplies merchant wholesalers',
    coterieAkHash: 'ac82c519ffb21988c7e5dfa26eb54ccf',
    coterieId: 10068,
  },
  {
    description: 'Coffins merchant wholesalers',
    coterieAkHash: 'cf0593453685d9e4ac632a2320fb5dcf',
    coterieId: 10490,
  },
  {
    description: 'Fare boxes, public transit vehicle, merchant wholesalers',
    coterieAkHash: '38aaf1dfe96eaa664f8c1c277b1558b9',
    coterieId: 10320,
  },
  {
    description:
      "Upholsterers' equipment and supplies (except fabrics) merchant wholesalers",
    coterieAkHash: '448959ef2c4b50d546183e285f353036',
    coterieId: 10253,
  },
  {
    description: 'Floor maintenance equipment merchant wholesalers',
    coterieAkHash: '46bc1137c02659279aca0f497aa61be0',
    coterieId: 10392,
  },
  {
    description: 'Carpet cleaning equipment and supplies merchant wholesalers',
    coterieAkHash: '58a1c3223dc391b6e2bbd520c35b3cf0',
    coterieId: 10392,
  },
  {
    description: 'Janitorial equipment and supplies merchant wholesalers',
    coterieAkHash: '654e13a2ae4217fc6520715b23526b6f',
    coterieId: 10392,
  },
  {
    description:
      'Chairs, hydraulic, beauty and barber shop, merchant wholesalers',
    coterieAkHash: '66fea09dc3ef5b9ff90c26dc7294ced6',
    coterieId: 10068,
  },
  {
    description: "Tailors' supplies merchant wholesalers",
    coterieAkHash: '6b760514ae0fe887a937b538d3e05aac',
    coterieId: 10253,
  },
  {
    description: 'Clothes dryers (except household-type) merchant wholesalers',
    coterieAkHash: '7e54369843e67a9d7d08532fec68b583',
    coterieId: 10320,
  },
  {
    description: "Undertakers' equipment and supplies merchant wholesalers",
    coterieAkHash: '8570a9e56b33c5911b962fd2713d8586',
    coterieId: 10490,
  },
  {
    description:
      'Laundry machinery, equipment, and supplies, commercial, merchant wholesalers',
    coterieAkHash: '8700a68b148b9fc2d883aa660520d30a',
    coterieId: 10320,
  },
  {
    description: 'Meters, parking, merchant wholesalers',
    coterieAkHash: '8e6d9461ca78c0b7e52a073ebdc63fdc',
    coterieId: 10320,
  },
  {
    description: 'Car wash equipment and supplies merchant wholesalers',
    coterieAkHash: '8efe6eef70a357f866b69679a8fecc81',
    coterieId: 10320,
  },
  {
    description: 'Firefighting equipment and supplies merchant wholesalers',
    coterieAkHash: 'a69140d2f3c61c6286a7d8a42fa9f67a',
    coterieId: 10320,
  },
  {
    description: 'Locksmith equipment and supplies merchant wholesalers',
    coterieAkHash: 'a784d36bf54895db7fa2ce2d6bed6ab3',
    coterieId: 10320,
  },
  {
    description: 'Patterns, shoe, merchant wholesalers',
    coterieAkHash: 'ae16b77c220fc14af82796e46e3796a7',
    coterieId: 10253,
  },
  {
    description: 'Water treatment equipment, municipal, merchant wholesalers',
    coterieAkHash: 'b98c6097d22e2802cb755c6006e7abfd',
    coterieId: 10541,
  },
  {
    description: 'Caskets, burial, merchant wholesalers',
    coterieAkHash: 'bdf96c0eb7f6ab8855ba43d97613206e',
    coterieId: 10490,
  },
  {
    description: 'Shoe repair materials merchant wholesalers',
    coterieAkHash: 'c0ddce9bd9004a75d56a7d7bdf32a5ba',
    coterieId: 10253,
  },
  {
    description: 'Brooms (except household-type) merchant wholesalers',
    coterieAkHash: 'cc1450e8ec8659e10300aaa5d8d0d877',
    coterieId: 10392,
  },
  {
    description:
      'Law enforcement equipment (except safety) merchant wholesalers',
    coterieAkHash: 'cec4e61c38dba222993976eee1132e64',
    coterieId: 10320,
  },
  {
    description: 'Sprinkler systems, fire, merchant wholesalers',
    coterieAkHash: 'e1eb4da181d5b034a81e44bfa0edc464',
    coterieId: 10320,
  },
  {
    description: 'Furriers equipment and supplies merchant wholesalers',
    coterieAkHash: 'e542f3663134cbc8ec8d8022f64573db',
    coterieId: 10253,
  },
  {
    description: 'Vacuum cleaning systems, commercial, merchant wholesalers',
    coterieAkHash: 'ee36b5a2c18bc230a768d9249c4284e1',
    coterieId: 10392,
  },
  {
    description: 'Padding, upholstery filling, merchant wholesalers',
    coterieAkHash: 'f925cef5626d49c96a1767a6cb3a7d0e',
    coterieId: 10253,
  },
  {
    description: 'Drycleaning equipment and supplies merchant wholesalers',
    coterieAkHash: 'f9946758c932f237680e9e86af0d1959',
    coterieId: 10320,
  },
  {
    description: 'Tennis equipment and supplies merchant wholesalers',
    coterieAkHash: '0af8fe8b38608b9b7f76f14a45ff8b37',
    coterieId: 10709,
  },
  {
    description: 'Marine supplies, pleasure, merchant wholesalers',
    coterieAkHash: '10f712825b64460040a5be524a0dea91',
    coterieId: 10709,
  },
  {
    description: 'Hot tubs merchant wholesalers',
    coterieAkHash: '2372cd8294c83781aa0e69bf8edd5f9d',
    coterieId: 10541,
  },
  {
    description: 'Pool (billiards) tables and supplies merchant wholesalers',
    coterieAkHash: '34f0c3a447429cbf89974b80a3b06183',
    coterieId: 10709,
  },
  {
    description: 'Casino supplies merchant wholesalers',
    coterieAkHash: '4d56559ca0861a76c01eb700314b5051',
    coterieId: 10709,
  },
  {
    description: 'Baseball equipment and supplies merchant wholesalers',
    coterieAkHash: '4e9bc9db94745e58f6135a6638010cf7',
    coterieId: 10709,
  },
  {
    description:
      'Fishing equipment and supplies (except commercial) merchant wholesalers',
    coterieAkHash: '6506d15598b6090576cdcb185b71a1a6',
    coterieId: 10709,
  },
  {
    description: 'Bait, artificial, merchant wholesalers',
    coterieAkHash: '6c6bead1a9913b9473071a272077ed01',
    coterieId: 10709,
  },
  {
    description: 'Bowling equipment and supplies merchant wholesalers',
    coterieAkHash: '818fed0620ccd71d15e4446ba4b689f1',
    coterieId: 10709,
  },
  {
    description: 'Fitness equipment and supplies merchant wholesalers',
    coterieAkHash: '966670c5da9484a4f709b093e47b6bc3',
    coterieId: 10709,
  },
  {
    description: 'Gymnasium equipment merchant wholesalers',
    coterieAkHash: 'cd43dea44fa07dcb3ac12e661681b47d',
    coterieId: 10709,
  },
  {
    description: 'Billiard equipment and supplies merchant wholesalers',
    coterieAkHash: 'd46149851ee4cda8ec1a49d43627240b',
    coterieId: 10709,
  },
  {
    description: 'Golf equipment and supplies merchant wholesalers',
    coterieAkHash: 'd9464c1024ec7865706c535329646713',
    coterieId: 10709,
  },
  {
    description: 'Athletic uniforms merchant wholesalers',
    coterieAkHash: 'e73142186e62ef7ef1f30ea2af03b4c7',
    coterieId: 10709,
  },
  {
    description: 'Hockey equipment and supplies merchant wholesalers',
    coterieAkHash: 'fb1f11bfbffa7f66d9865809a6dd6750',
    coterieId: 10709,
  },
  {
    description: 'Camping equipment and supplies merchant wholesalers',
    coterieAkHash: 'fb3af81e803e17bf5df13bee5b33cb2c',
    coterieId: 10709,
  },
  {
    description: 'Hobby craft kits merchant wholesalers',
    coterieAkHash: '041734f21f5a336fdbdddcefa4a8c890',
    coterieId: 10333,
  },
  {
    description: 'Trading cards merchant wholesalers',
    coterieAkHash: '08ab7a4bd85c4e59f4d9215915149a13',
    coterieId: 10709,
  },
  {
    description: 'Card games merchant wholesalers',
    coterieAkHash: '13b0dbc66b561caa5c8816dd58fa0baa',
    coterieId: 10709,
  },
  {
    description: "Hobbyists' supplies merchant wholesalers",
    coterieAkHash: '1c683aa9bb01f18d4dc2f8b0895e0263',
    coterieId: 10333,
  },
  {
    description: 'Video games merchant wholesalers',
    coterieAkHash: '1d4e4a0bc6f2b81a8fe5340320eb25cf',
    coterieId: 10709,
  },
  {
    description: 'Electronic games merchant wholesalers',
    coterieAkHash: '24fcd88a394f6701698a5baf15241219',
    coterieId: 10709,
  },
  {
    description: 'Toys (including electronic) merchant wholesalers',
    coterieAkHash: '4494b522bae6b85e8bbe601806ed2737',
    coterieId: 10709,
  },
  {
    description: 'Toy furniture merchant wholesalers',
    coterieAkHash: '45d445f91795ff8c8b4de2bfbed11644',
    coterieId: 10709,
  },
  {
    description: 'Puzzles merchant wholesalers',
    coterieAkHash: '465f1d3f7458ddf55d80918f4a4316d7',
    coterieId: 10709,
  },
  {
    description: 'Craft kits merchant wholesalers',
    coterieAkHash: '53b28c06d490611686782c0dabacc0d1',
    coterieId: 10333,
  },
  {
    description: 'Playing cards merchant wholesalers',
    coterieAkHash: '5b655722b66067dff5c3ed828f14478f',
    coterieId: 10709,
  },
  {
    description: "Vehicles, children's (except bicycles), merchant wholesalers",
    coterieAkHash: '7108851d2eafcd2f7025743c977a250f',
    coterieId: 10709,
  },
  {
    description: 'Model kits merchant wholesalers',
    coterieAkHash: '7355f36856c65fd60be257f7a180c5c9',
    coterieId: 10333,
  },
  {
    description: 'Games (except coin-operated) merchant wholesalers',
    coterieAkHash: '79f6aa17959db5f5151671fa323bcc66',
    coterieId: 10709,
  },
  {
    description: 'Board games merchant wholesalers',
    coterieAkHash: 'c4cd8983b9e979eaf8bef5587d81ed84',
    coterieId: 10709,
  },
  {
    description: 'Science kits and sets merchant wholesalers',
    coterieAkHash: 'd58f43c7cd00200af3bfa5b966781346',
    coterieId: 10709,
  },
  {
    description: 'Dolls merchant wholesalers',
    coterieAkHash: 'dc820d46e61031befb28d0b375fe9094',
    coterieId: 10709,
  },
  {
    description: 'Coloring books merchant wholesalers',
    coterieAkHash: 'dcc5f0608681fa457f129ca2bf5982b3',
    coterieId: 10333,
  },
  {
    description: 'Stamps, philatelist, merchant wholesalers',
    coterieAkHash: 'e209bcee90917f7dbd73cea037b5f0c5',
    coterieId: 10709,
  },
  {
    description: 'Gaming consoles merchant wholesalers',
    coterieAkHash: 'f176d43d300bcbebb4b09525848d6e83',
    coterieId: 10709,
  },
  {
    description: 'Costume jewelry merchant wholesalers',
    coterieAkHash: 'c92394552cd823d1141655974bfde19c',
    coterieId: 10394,
  },
  {
    description: 'Briefcases merchant wholesalers',
    coterieAkHash: '11dadbf0c8181c9054fce5ceeb6e4bbe',
    coterieId: 10709,
  },
  {
    description:
      'Musical recordings (e.g., compact discs, records, tapes) merchant wholesalers',
    coterieAkHash: '195f0d4dcb82beb6d66c8dced8ce5f1c',
    coterieId: 10035,
  },
  {
    description:
      'Safety devices (e.g., eye shields, face shields) merchant wholesalers',
    coterieAkHash: '24ee9eab72b053d2c284ab2bba2e2f20',
    coterieId: 10320,
  },
  {
    description:
      'Fire extinguisher sales combined with rental and/or service, merchant wholesalers',
    coterieAkHash: '44832164712677f75f8554199fb67992',
    coterieId: 10320,
  },
  {
    description: 'Musical instruments merchant wholesalers',
    coterieAkHash: '47a6afe571c302c12ac53862fd4757a1',
    coterieId: 10709,
  },
  {
    description: 'Signs (except electrical) merchant wholesalers',
    coterieAkHash: '5a63ab648644c1bdbd5551a782699b75',
    coterieId: 10320,
  },
  {
    description: 'Games, coin-operated, merchant wholesalers',
    coterieAkHash: '657e7592eb08747f291bc8360d7c9fff',
    coterieId: 10709,
  },
  {
    description: 'Logs merchant wholesalers',
    coterieAkHash: '6aa6aa411a36be9c4d9e9e067066087c',
    coterieId: 10320,
  },
  {
    description: 'Safety deposit boxes merchant wholesalers',
    coterieAkHash: '6ad0207d982b0f451b1b1100e44eac45',
    coterieId: 10320,
  },
  {
    description:
      'Wood products (e.g., chips, posts, shavings, ties) merchant wholesalers',
    coterieAkHash: '6fb6c5c852e3f4330dd4b47d90ffdde6',
    coterieId: 10320,
  },
  {
    description: 'Luggage merchant wholesalers',
    coterieAkHash: '6fcc6a6742301ef3cae6eea3d6964386',
    coterieId: 10709,
  },
  {
    description: 'Billboards merchant wholesalers',
    coterieAkHash: '720775b928d36b60e3c7b689d06f1862',
    coterieId: 10320,
  },
  {
    description: 'Extinguishers, fire, merchant wholesalers',
    coterieAkHash: '78f33ce4b499f8dc2455f31e1d77e2f2',
    coterieId: 10320,
  },
  {
    description: 'Fire extinguishers merchant wholesalers',
    coterieAkHash: '82f177e393cfc7d2096cb10e55a334b4',
    coterieId: 10320,
  },
  {
    description: 'Forest products (except lumber) merchant wholesalers',
    coterieAkHash: '8326cb7436b944b56d6347fef7b43c91',
    coterieId: 10320,
  },
  {
    description: 'Tombstones merchant wholesalers',
    coterieAkHash: '965ccce2df8a3e548ed1bfb5f44daf06',
    coterieId: 10320,
  },
  {
    description:
      'Musical instrument accessories and supplies merchant wholesalers',
    coterieAkHash: '9ce4f475862d3a15107b24595a2550cb',
    coterieId: 10709,
  },
  {
    description: 'Gas lighting fixtures merchant wholesalers',
    coterieAkHash: 'a25bcf2662dcf173dbac9a720890c6a9',
    coterieId: 10320,
  },
  {
    description:
      'Audio and video tapes and disks, prerecorded, merchant wholesalers',
    coterieAkHash: 'a7d023623d4634c7caa81bb748f28327',
    coterieId: 10035,
  },
  {
    description:
      'Digital video discs (DVDs), prerecorded, merchant wholesalers',
    coterieAkHash: 'ae36ac60a5ee3957c490424b5f19ec33',
    coterieId: 10035,
  },
  {
    description:
      'Timber and timber products (except lumber) merchant wholesalers',
    coterieAkHash: 'b628847355e5ec1e74110cd0d9ae0ec5',
    coterieId: 10320,
  },
  {
    description: 'Mailboxes merchant wholesalers',
    coterieAkHash: 'b7de879b4873c9be49cf5a0b99a43040',
    coterieId: 10320,
  },
  {
    description: 'Coin-operated game machines merchant wholesalers',
    coterieAkHash: 'b9cd642fb5714cc75f5a49a65cc75905',
    coterieId: 10709,
  },
  {
    description: 'Ordnance and accessories merchant wholesalers',
    coterieAkHash: 'c6811c8481ad510a3c7d4ad4d414fbae',
    coterieId: 10320,
  },
  {
    description: 'Compact discs (CDs), prerecorded, merchant wholesalers',
    coterieAkHash: 'caee3de22e0700bd0836ec7848485b0f',
    coterieId: 10035,
  },
  {
    description: 'General merchandise, durable goods, merchant wholesalers',
    coterieAkHash: 'd4042b003d30089be54b8c4bccd976a3',
    coterieId: 10034,
  },
  {
    description: 'Cassettes, prerecorded audio and video, merchant wholesalers',
    coterieAkHash: 'd563940fe8c1402231eb001267e1fbbb',
    coterieId: 10035,
  },
  {
    description: 'Video tapes, prerecorded, merchant wholesalers',
    coterieAkHash: 'd84d03852b9ec83ed8fbf1b2317866b2',
    coterieId: 10035,
  },
  {
    description: 'Monuments and grave markers merchant wholesalers',
    coterieAkHash: 'daac63136824e3079b55614386aa8246',
    coterieId: 10320,
  },
  {
    description: 'Phonograph records merchant wholesalers',
    coterieAkHash: 'de4a52d172239b9f75594f993ebe7ed2',
    coterieId: 10035,
  },
  {
    description: 'Instruments, musical, merchant wholesalers',
    coterieAkHash: 'dfc41314f3fbd9b47a98ff2d65390fa6',
    coterieId: 10709,
  },
  {
    description:
      'Prerecorded audio and video tapes and discs merchant wholesalers',
    coterieAkHash: 'e38662597a72d1933688ee5fff27ef73',
    coterieId: 10035,
  },
  {
    description: 'Suitcases merchant wholesalers',
    coterieAkHash: 'eb67ff017396e2b104b4407aa630de5a',
    coterieId: 10709,
  },
  {
    description: 'Lighting equipment, gas, merchant wholesalers',
    coterieAkHash: 'eed0836c14e72ba191d8fb269ec3f80b',
    coterieId: 10320,
  },
  {
    description: 'Roundwood merchant wholesalers',
    coterieAkHash: 'fc1c35d23c37b0292ec7081487394ca8',
    coterieId: 10320,
  },
  {
    description: 'Tapes, prerecorded, audio or video, merchant wholesalers',
    coterieAkHash: 'fd1e784beb4f4306548bc3d2d9dbb75c',
    coterieId: 10035,
  },
  {
    description: 'Printing paper, bulk, merchant wholesalers',
    coterieAkHash: '757288b912969ec5a4bf21724753b6f4',
    coterieId: 10661,
  },
  {
    description: 'Social stationery merchant wholesalers',
    coterieAkHash: '01028cc0a9ff10a73c2933a85dce83ea',
    coterieId: 10661,
  },
  {
    description: 'Personal sanitary paper products merchant wholesalers',
    coterieAkHash: '0d8faee9eae7edb5d4684ef7b8051271',
    coterieId: 10313,
  },
  {
    description: 'Perfumes merchant wholesalers',
    coterieAkHash: '09caf0de8f8f3e8a37c757b5913c8a26',
    coterieId: 10221,
  },
  {
    description: 'Beauty supplies merchant wholesalers',
    coterieAkHash: '12468101b5dfb4e040b068e8eefe4d92',
    coterieId: 10068,
  },
  {
    description: 'Medical sundries, rubber, merchant wholesalers',
    coterieAkHash: '1777dfcf1e772bdd05d1bf811241a430',
    coterieId: 10221,
  },
  {
    description: 'First-aid kits (except industrial) merchant wholesalers',
    coterieAkHash: '20390095ef1e070484124ee5f6110d52',
    coterieId: 10313,
  },
  {
    description: 'Razor blades merchant wholesalers',
    coterieAkHash: '267d53513304f06a3703b36741bb6d3e',
    coterieId: 10068,
  },
  {
    description: 'Rubber goods, medical, merchant wholesalers',
    coterieAkHash: '27dcfb293ff81a8ae7f5347fa1fcfaae',
    coterieId: 10313,
  },
  {
    description: 'Toothbrushes (except electric) merchant wholesalers',
    coterieAkHash: '34bc8b2247df942628271c503deaaf71',
    coterieId: 10313,
  },
  {
    description: 'Dental care preparations merchant wholesalers',
    coterieAkHash: '3722acae5801e0a2ae7853b791bf6792',
    coterieId: 10221,
  },
  {
    description: 'Skin care preparations merchant wholesalers',
    coterieAkHash: '3937eff6b763f54dd851b7cd553869e0',
    coterieId: 10068,
  },
  {
    description: 'Razors (except electric) merchant wholesalers',
    coterieAkHash: '3de48092e0767691a3ffd2780e35cc99',
    coterieId: 10068,
  },
  {
    description: 'Deodorants, personal, merchant wholesalers',
    coterieAkHash: '49592c00a7ebd85be0a47357982a1137',
    coterieId: 10313,
  },
  {
    description: 'Colognes merchant wholesalers',
    coterieAkHash: '50a18f48e82f685ed539d9991c851caf',
    coterieId: 10221,
  },
  {
    description: 'Beauty preparations merchant wholesalers',
    coterieAkHash: '5bfc4a1ffd4ebd967dabe8b2cb7a40da',
    coterieId: 10068,
  },
  {
    description: 'Antiseptics merchant wholesalers',
    coterieAkHash: '67d403e50540815095273a870689b017',
    coterieId: 10221,
  },
  {
    description: 'Toothpastes merchant wholesalers',
    coterieAkHash: '6bacaf3d2dd15cf22ff7bea767111428',
    coterieId: 10313,
  },
  {
    description: 'Hair preparations (except professional) merchant wholesalers',
    coterieAkHash: '723db7862b3cb55d5eaa4b2ef609ac7f',
    coterieId: 10068,
  },
  {
    description: 'First-aid supplies merchant wholesalers',
    coterieAkHash: '75c1ca6322767a2e5e2b09e674aff01c',
    coterieId: 10313,
  },
  {
    description: 'Dentifrices merchant wholesalers',
    coterieAkHash: '79fb5dd9212357f2a3dc2165958cc5e7',
    coterieId: 10221,
  },
  {
    description: 'Gauze merchant wholesalers',
    coterieAkHash: '82a8c572054ed03f2388b612f35cfcf6',
    coterieId: 10313,
  },
  {
    description: 'Salts, bath, merchant wholesalers',
    coterieAkHash: '8dfd857ff47e82aba57d0501018406c0',
    coterieId: 10313,
  },
  {
    description: 'Blades, razor, merchant wholesalers',
    coterieAkHash: '8f7d6ffb7e6b4c95ed63789bc4cf5a37',
    coterieId: 10068,
  },
  {
    description: 'Cosmetics merchant wholesalers',
    coterieAkHash: 'a4cf13f53ce05dc1b44788f062e1f477',
    coterieId: 10221,
  },
  {
    description: 'Shaving preparations merchant wholesalers',
    coterieAkHash: 'a999fb427978622dd27eb498a16f490f',
    coterieId: 10068,
  },
  {
    description: 'Toiletries merchant wholesalers',
    coterieAkHash: 'c0192c87f65ad87445dd4198081a5a04',
    coterieId: 10313,
  },
  {
    description: 'Toilet soaps merchant wholesalers',
    coterieAkHash: 'c03c7b46a82372858b0dfe312a95261f',
    coterieId: 10313,
  },
  {
    description: "Druggists' sundries merchant wholesalers",
    coterieAkHash: 'c0965512212075b8d3272c353f6ba627',
    coterieId: 10221,
  },
  {
    description: 'Hygiene products, oral, merchant wholesalers',
    coterieAkHash: 'c22cc578c25742ef351c5c7137850178',
    coterieId: 10313,
  },
  {
    description: 'Toilet preparations merchant wholesalers',
    coterieAkHash: 'c5cb465cb3def01a5ca69050b5ff3adf',
    coterieId: 10313,
  },
  {
    description: 'Biologicals and allied products merchant wholesalers',
    coterieAkHash: 'da6f7d689145bb9edf4e2eb2335b017f',
    coterieId: 10221,
  },
  {
    description: 'Hair care products merchant wholesalers',
    coterieAkHash: 'e01978db8758b4ceaa1712bd8e1090b1',
    coterieId: 10068,
  },
  {
    description: 'Bandages (except surgical) merchant wholesalers',
    coterieAkHash: 'ff759aa710bd937694465844f9394e09',
    coterieId: 10313,
  },
  {
    description: 'Upholstery fabrics merchant wholesalers',
    coterieAkHash: '1024a4eab63c62dea498aae2d36ca688',
    coterieId: 10253,
  },
  {
    description: "Work clothing, men's and boys', merchant wholesalers",
    coterieAkHash: '0893bef5d2d3e6cb08c3419fe98434cf',
    coterieId: 10317,
  },
  {
    description: 'Diapers (except paper) merchant wholesalers',
    coterieAkHash: '11e07a8357b0eefa919b91a541aa3b25',
    coterieId: 10141,
  },
  {
    description: 'Shoes merchant wholesalers',
    coterieAkHash: '07481507f8a3e937e27e0161abced99e',
    coterieId: 10141,
  },
  {
    description: 'Food, general-line, merchant wholesalers',
    coterieAkHash: 'a1700a5f1aa0ce307fcb0af5d44ae247',
    coterieId: 10313,
  },
  {
    description: 'Pretzels, frozen, merchant wholesalers',
    coterieAkHash: '11bc33815213d93b92c3de946d17d3e1',
    coterieId: 10313,
  },
  {
    description: 'Butter merchant wholesalers',
    coterieAkHash: '1c32448f16a2794606701d7b002409b3',
    coterieId: 10449,
  },
  {
    description: 'Frozen poultry (except packaged) merchant wholesalers',
    coterieAkHash: '0380ec6a0e4597ed5be20a106b4005ab',
    coterieId: 10550,
  },
  {
    description: 'Toppings, fountain, merchant wholesalers',
    coterieAkHash: '25e16b3cb1cf5cbff76d1390664ef5f5',
    coterieId: 10313,
  },
  {
    description: 'Frozen seafood (except packaged) merchant wholesalers',
    coterieAkHash: '1641808047cf2dd7dee043bda5708c36',
    coterieId: 10280,
  },
  {
    description: 'Frozen meats (except packaged) merchant wholesalers',
    coterieAkHash: '3c9ad0d1636402b7261f7adae89745df',
    coterieId: 10449,
  },
  {
    description: 'Produce, fresh, merchant wholesalers',
    coterieAkHash: '18cd33128d56826efebb5c54ce706480',
    coterieId: 10291,
  },
  {
    description: 'Pretzels (except frozen) merchant wholesalers',
    coterieAkHash: '06d99f1673cb7b5d548b85bc573a94cf',
    coterieId: 10313,
  },
  {
    description: 'Soybeans merchant wholesalers',
    coterieAkHash: '03ef7a12447279cb52330c4b7aab7461',
    coterieId: 10313,
  },
  {
    description: 'Flower bulbs merchant wholesalers',
    coterieAkHash: '285d511329954cb7c99e4184ce86fde8',
    coterieId: 10302,
  },
  {
    description: 'Harness equipment merchant wholesalers',
    coterieAkHash: '32f8fe3d467ca514991492ee7e66662e',
    coterieId: 10302,
  },
  {
    description: 'Agricultural limestone merchant wholesalers',
    coterieAkHash: '35e9345c9187975ef16b223e390afcf7',
    coterieId: 10302,
  },
  {
    description: 'Hay merchant wholesalers',
    coterieAkHash: '45f0545bf3975e743949e901fe09c9c0',
    coterieId: 10302,
  },
  {
    description: 'Phosphate rock, ground, merchant wholesalers',
    coterieAkHash: '5e49f89cfbbb677b83c5c6f146d57107',
    coterieId: 10302,
  },
  {
    description: 'Alfalfa merchant wholesalers',
    coterieAkHash: '5efc01929227dbfd42a0ed64661bead4',
    coterieId: 10302,
  },
  {
    description: 'Lime, agricultural, merchant wholesalers',
    coterieAkHash: '60a8b3dff85d04276957b750c2256d68',
    coterieId: 10302,
  },
  {
    description: 'Saddlery merchant wholesalers',
    coterieAkHash: '61a381b415b5d10a5299f27af07e106c',
    coterieId: 10302,
  },
  {
    description: 'Horticultural products merchant wholesalers',
    coterieAkHash: '6337479520ee10d93b41efe8166c4a73',
    coterieId: 10302,
  },
  {
    description: 'Plant bulbs merchant wholesalers',
    coterieAkHash: '75e20cc451c870f529d4f1578d87be0b',
    coterieId: 10302,
  },
  {
    description: 'Produce containers merchant wholesalers',
    coterieAkHash: '79a80b8f7efee653cfedea6dccc7ddd8',
    coterieId: 10302,
  },
  {
    description: 'Tack (e.g., harnesses, saddlery) merchant wholesalers',
    coterieAkHash: 'ade81cd6ec3d8eba356a32ee93ffee0c',
    coterieId: 10302,
  },
  {
    description: 'Farm supplies merchant wholesalers',
    coterieAkHash: 'ba44c6094ec03716547add723bc781bd',
    coterieId: 10302,
  },
  {
    description: 'Straw merchant wholesalers',
    coterieAkHash: 'd12993df72744e4d7bd28e13c29d0b9d',
    coterieId: 10302,
  },
  {
    description:
      'Garden supplies (e.g., fertilizers, pesticides) merchant wholesalers',
    coterieAkHash: 'de4de65b426e3bf88c6ee1ba7da35861',
    coterieId: 10302,
  },
  {
    description: 'Beekeeping supplies merchant wholesalers',
    coterieAkHash: 'fec3c2b9697924048e88432e57010e9e',
    coterieId: 10302,
  },
  {
    description: 'Brochures merchant wholesalers',
    coterieAkHash: '5dd4b3868972423fb9aa6b896fcdc3e9',
    coterieId: 10085,
  },
  {
    description: "Florist's supplies merchant wholesalers",
    coterieAkHash: '49a7b50a1d8a10d827fc4cd2b1894e9c',
    coterieId: 10286,
  },
  {
    description: 'Paint rollers merchant wholesalers',
    coterieAkHash: '4ccfe542a76506fc4d9112a0eb786a63',
    coterieId: 10320,
  },
  {
    description: 'Wallpaper merchant wholesalers',
    coterieAkHash: 'f8240f8b6e9127cf7b0c3bb41ad2a1b6',
    coterieId: 10320,
  },
  {
    description: 'Paintbrushes merchant wholesalers',
    coterieAkHash: 'f914043d748163b4b7cc9e8c180e3892',
    coterieId: 10320,
  },
  {
    description: 'Wigs merchant wholesalers',
    coterieAkHash: '45e464ae246ff532b2ddd04226fa6ba2',
    coterieId: 10068,
  },
  {
    description: 'Toupees merchant wholesalers',
    coterieAkHash: '52cf5d918ddd05dc9b8421b74a627d89',
    coterieId: 10068,
  },
  {
    description: 'Jewelry boxes merchant wholesalers',
    coterieAkHash: '18426ddf19536b2409ff253c94651b1f',
    coterieId: 10034,
  },
  {
    description: 'Rennets merchant wholesalers',
    coterieAkHash: '1ee0a3d4acd44d9fb5db710f8be54db9',
    coterieId: 10313,
  },
  {
    description: 'Piece goods, burlap and felt, merchant wholesalers',
    coterieAkHash: '254c41cb8f4620ea4d0e9f41d89b02ff',
    coterieId: 10259,
  },
  {
    description: 'Bags, textile, merchant wholesalers',
    coterieAkHash: '2be0983ebc5e1a6a8ec18440e63a985b',
    coterieId: 10259,
  },
  {
    description: 'Hairbrushes merchant wholesalers',
    coterieAkHash: 'df05c85f09fe8195b4e15542c034f67b',
    coterieId: 10068,
  },
  {
    description: 'Skins, dressed, merchant wholesalers',
    coterieAkHash: '2eb29ab344d40a19651d209e10d357c9',
    coterieId: 10259,
  },
  {
    description: 'General merchandise, nondurable goods, merchant wholesalers',
    coterieAkHash: '2f9a061adb2022d5793bbbd1ded6ac43',
    coterieId: 10313,
  },
  {
    description: 'Wood carvings merchant wholesalers',
    coterieAkHash: '2fa17d8a1d78b2fc3138e7b457d5e20f',
    coterieId: 10143,
  },
  {
    description: 'Ice (except dry ice) merchant wholesalers',
    coterieAkHash: '31ab6e3c8f060aa78e1a08f01386beaa',
    coterieId: 10313,
  },
  {
    description:
      'Hairpieces (e.g., toupees, wigs, wiglets) merchant wholesalers',
    coterieAkHash: 'f183e65b1509f1b7d1bb3f2bf385fbf3',
    coterieId: 10068,
  },
  {
    description: 'Yarns, industrial, merchant wholesalers',
    coterieAkHash: '3c48fc997b659e57ad1d582682df5f96',
    coterieId: 10259,
  },
  {
    description: 'Canvas products merchant wholesalers',
    coterieAkHash: '3e2dd549e1a5e4bcd076edbe3459f071',
    coterieId: 10333,
  },
  {
    description: 'Statuary (except religious) merchant wholesalers',
    coterieAkHash: '3e4f1db1f33d393bd7a71bf093e7dd1a',
    coterieId: 10143,
  },
  {
    description: 'Candles merchant wholesalers',
    coterieAkHash: '44477d4cfabb140d4f2f945fd63f7537',
    coterieId: 10333,
  },
  {
    description: 'Dressed furs and skins merchant wholesalers',
    coterieAkHash: '46d049e903c96c7c9b4da52d2be16f69',
    coterieId: 10259,
  },
  {
    description: 'Tents (except camping) merchant wholesalers',
    coterieAkHash: '516a9d0ec0aea9f9c90ad508ef98c566',
    coterieId: 10709,
  },
  {
    description: 'Sponges merchant wholesalers',
    coterieAkHash: '5747783104b13d90895c69a0652c086a',
    coterieId: 10392,
  },
  {
    description:
      'Leather goods (except belting, footwear, handbags, gloves, luggage) merchant wholesalers',
    coterieAkHash: '5f3e4bbbcb7a3221ea093f967dc6c607',
    coterieId: 10259,
  },
  {
    description: 'Art goods merchant wholesalers',
    coterieAkHash: '62739f8369641923ab7baf881e6fb48a',
    coterieId: 10333,
  },
  {
    description: 'Wood pulp merchant wholesalers',
    coterieAkHash: '63aa029b3309980649f722c975899cd9',
    coterieId: 10320,
  },
  {
    description: 'Curios merchant wholesalers',
    coterieAkHash: '6b5a065eca0efeaed6fd0aa4f960d2f7',
    coterieId: 10034,
  },
  {
    description: 'Sawdust merchant wholesalers',
    coterieAkHash: '6bba425cffe34f1872cdf3ef9fccf0e5',
    coterieId: 10320,
  },
  {
    description: 'Ship chandler merchant wholesalers',
    coterieAkHash: '709a56d2715a30ffb33ed3ff02ab2f78',
    coterieId: 10063,
  },
  {
    description: 'Handles (e.g., broom, mop, paint) merchant wholesalers',
    coterieAkHash: '7bbe58386eca8c59764a8791ea0ca8f2',
    coterieId: 10392,
  },
  {
    description: "Artists' supplies merchant wholesalers",
    coterieAkHash: '7dc5bba2790ce1de07ce59038d8ba80b',
    coterieId: 10333,
  },
  {
    description: 'Vegetable cake and meal merchant wholesalers',
    coterieAkHash: '8623b91942d99fcdd741be15caee41db',
    coterieId: 10313,
  },
  {
    description: 'Textile bags merchant wholesalers',
    coterieAkHash: '940912c119141a77b253ecdd95e2de97',
    coterieId: 10259,
  },
  {
    description: 'Clothes hangers merchant wholesalers',
    coterieAkHash: '9749322195c00458715bb7a0575f27a4',
    coterieId: 10034,
  },
  {
    description: 'Artificial Christmas trees merchant wholesalers',
    coterieAkHash: '9917aa583636ff27f93ad141593b587d',
    coterieId: 10333,
  },
  {
    description: 'Sheet music merchant wholesalers',
    coterieAkHash: '9ac0a26db666b01f70a702d45c02f6e6',
    coterieId: 10333,
  },
  {
    description: 'Awnings, canvas, merchant wholesalers',
    coterieAkHash: '9dca80b8fa092fe876172573f3f73df5',
    coterieId: 10034,
  },
  {
    description: 'Leather cut stock (except boot, shoe) merchant wholesalers',
    coterieAkHash: 'a89ac4ea3f24a782a091d0d8c523b898',
    coterieId: 10259,
  },
  {
    description: 'Novelties merchant wholesalers',
    coterieAkHash: 'bc33ba81070b6709addf6526c3941723',
    coterieId: 10143,
  },
  {
    description: 'Felt merchant wholesalers',
    coterieAkHash: 'beb56a5c237c4e8ffca930d60cfef8bc',
    coterieId: 10259,
  },
  {
    description: 'Burlap merchant wholesalers',
    coterieAkHash: 'c499926d77798eac959776045356df71',
    coterieId: 10259,
  },
  {
    description: "Paints, artist's, merchant wholesalers",
    coterieAkHash: 'd278a4123b28d09a18d08e884bdea7ac',
    coterieId: 10333,
  },
  {
    description: 'Christmas ornaments merchant wholesalers',
    coterieAkHash: 'd8af403e2b5d38cf9ee964e8e9f775e7',
    coterieId: 10333,
  },
  {
    description: 'Music, sheet, merchant wholesalers',
    coterieAkHash: 'd928f4b1ff20d038bf1565aeb9a8ce1f',
    coterieId: 10333,
  },
  {
    description: 'Backpacks, textile, merchant wholesalers',
    coterieAkHash: 'd9a198c6ad758bbd4e54fb27e9788adc',
    coterieId: 10259,
  },
  {
    description: 'Furs, dressed, merchant wholesalers',
    coterieAkHash: 'ddcdc986aa8957e0f56d45943b3b4ea7',
    coterieId: 10259,
  },
  {
    description: 'Baskets merchant wholesalers',
    coterieAkHash: 'e0da718d073fb6a284b5eec96a68814f',
    coterieId: 10333,
  },
  {
    description: 'Cotton (except raw) merchant wholesalers',
    coterieAkHash: 'ec1b5536f6c74bfe8fd74c0bac604aef',
    coterieId: 10259,
  },
  {
    description: 'Plant food merchant wholesalers',
    coterieAkHash: 'ef388ab344739a0e872bc75fe305010e',
    coterieId: 10302,
  },
  {
    description: "Brushes, artists', merchant wholesalers",
    coterieAkHash: 'ef401420f0fe029f5de2a18debcc77d0',
    coterieId: 10333,
  },
  {
    description: 'Pre-paid calling card distribution, merchant wholesalers',
    coterieAkHash: 'efba9d4266c19c531092eefd3fe298a6',
    coterieId: 10313,
  },
  {
    description: 'Chamois, leather, merchant wholesalers',
    coterieAkHash: 'f4cd8081c26bacbfac6d2c44f998a1f0',
    coterieId: 10259,
  },
  {
    description: 'Pottery, novelty, merchant wholesalers',
    coterieAkHash: 'fb24fc2782975f616546825b0170b013',
    coterieId: 10034,
  },
  {
    description:
      'Durable goods business to business electronic markets, wholesale trade',
    coterieAkHash: '086507ab4f7e829d4f3e66544824fa71',
    coterieId: 10428,
  },
  {
    description: 'Auto supply stores',
    coterieAkHash: '067da709092ae04ab981b0981f811fb3',
    coterieId: 10064,
  },
  {
    description: 'Stereo stores, automotive',
    coterieAkHash: '34eeef4910d784a76800f056ffb098e6',
    coterieId: 10064,
  },
  {
    description: 'Truck cap stores',
    coterieAkHash: '4a2effffc0086f01070e67b711d23025',
    coterieId: 10064,
  },
  {
    description: 'Automobile parts dealers',
    coterieAkHash: '61a5633cf8fe333ea785ec696744e7f9',
    coterieId: 10064,
  },
  {
    description: 'Parts and accessories dealers, automotive',
    coterieAkHash: '7ed4d351aee8b18c3098ab80b563632c',
    coterieId: 10064,
  },
  {
    description: 'Automotive parts and supply stores',
    coterieAkHash: 'c67062eeabdad77efb2ee00ab9a9f4c3',
    coterieId: 10064,
  },
  {
    description: 'Batteries, automotive, dealers',
    coterieAkHash: 'e3263b8557839af04724ae93fe18281c',
    coterieId: 10064,
  },
  {
    description: 'Automotive audio equipment stores',
    coterieAkHash: 'ff1ac91f8b05ac697ad8bd7ccc43b5b3',
    coterieId: 10064,
  },
  {
    description: 'Mattress stores (including waterbeds)',
    coterieAkHash: '1add7f14b9d3567527e791bba5d204a5',
    coterieId: 10295,
  },
  {
    description: 'Rug stores',
    coterieAkHash: '05bf10bcb7c35721727b8cb462532675',
    coterieId: 10284,
  },
  {
    description: 'Curtain and drapery stores, packaged',
    coterieAkHash: 'b628f982bd6115182ec979d04738b1d7',
    coterieId: 10036,
  },
  {
    description: 'Home furnishings stores',
    coterieAkHash: '201d8bbc7cb4964fefd7a8303111694d',
    coterieId: 10036,
  },
  {
    description: 'Vacuum cleaner stores, household-type',
    coterieAkHash: '011fa691f49417afbafb77eec412dd61',
    coterieId: 10036,
  },
  {
    description: 'Video game software stores',
    coterieAkHash: '069d0b3f9817d6857f081e672aab2844',
    coterieId: 10239,
  },
  {
    description: 'Home improvement centers',
    coterieAkHash: 'a570b9ed71711304f47f40ad6ad23f3e',
    coterieId: 10336,
  },
  {
    description: 'Wallpaper and wall coverings stores',
    coterieAkHash: '4bb3ecacf82918078c5e14803a1893e0',
    coterieId: 10499,
  },
  {
    description: 'Hardware stores',
    coterieAkHash: '242297c4716559e960c3aa5cf63988c1',
    coterieId: 10319,
  },
  {
    description: 'Lumber retailing yards',
    coterieAkHash: '1488c342eeb79d7581ab89adb91d4d99',
    coterieId: 10336,
  },
  {
    description: 'Door stores',
    coterieAkHash: '373313cafcc5f7396cc1e9267d1624e5',
    coterieId: 10336,
  },
  {
    description: 'Lighting fixture stores',
    coterieAkHash: '3f0721bb9ea0605f2b4f13e55305c9f0',
    coterieId: 10336,
  },
  {
    description: 'Glass stores',
    coterieAkHash: '4ab484749487600992c38c35a92d757a',
    coterieId: 10336,
  },
  {
    description: 'Tile stores, ceramic',
    coterieAkHash: '6cd497c6eab013c6baa2022c4dbe609c',
    coterieId: 10336,
  },
  {
    description: 'Hardwood flooring dealers',
    coterieAkHash: '83318f0007acd58a23f6b665042683a0',
    coterieId: 10336,
  },
  {
    description: 'Building materials supply dealers',
    coterieAkHash: '83c76a1a6215eefdf2858f3a3952a800',
    coterieId: 10336,
  },
  {
    description: 'Electrical supply stores',
    coterieAkHash: '86e67fec61f68589f3d929393a67384b',
    coterieId: 10336,
  },
  {
    description: 'Plumbing supply stores',
    coterieAkHash: '929575307ed3f02d05350dab18dac805',
    coterieId: 10336,
  },
  {
    description: 'Masonry (e.g., block, brick, stone) dealers',
    coterieAkHash: '94a99c809b5b3ed391396604ff345784',
    coterieId: 10336,
  },
  {
    description: 'Cabinet stores, kitchen (except custom), to be installed',
    coterieAkHash: 'abb7feb4d5674d45e202e443a7776a16',
    coterieId: 10336,
  },
  {
    description: 'Fencing dealers',
    coterieAkHash: 'c323b917190bee46af1eb3749729759c',
    coterieId: 10336,
  },
  {
    description: 'Floor covering stores, wood or ceramic tile only',
    coterieAkHash: 'cfae9b06df303f423ab01b64e089b1ee',
    coterieId: 10336,
  },
  {
    description: 'Siding dealers',
    coterieAkHash: 'd5ef557d972efca09fed5d91abbd6cb5',
    coterieId: 10336,
  },
  {
    description: 'Kitchen cabinet (except custom) stores',
    coterieAkHash: 'e2e0c59eb9c7b5d67b282a2f8a09e92b',
    coterieId: 10336,
  },
  {
    description: 'Ceramic tile stores',
    coterieAkHash: 'e3bcb23c1ed516ba5459b0e18381e705',
    coterieId: 10336,
  },
  {
    description: 'Ceiling fan stores',
    coterieAkHash: 'ef3c88e589f27075deaf2d5d2092aad3',
    coterieId: 10336,
  },
  {
    description: 'Garage door dealers',
    coterieAkHash: 'fef3a12de9ece51c724413f9a5f237cf',
    coterieId: 10336,
  },
  {
    description: 'Lawn power equipment stores',
    coterieAkHash: '13cdab5f2b922d5a8f46f2d627384f98',
    coterieId: 10551,
  },
  {
    description: 'Nurseries, retail, stock primarily grown off premises',
    coterieAkHash: '231ee549aabedcd394ba3054724421ad',
    coterieId: 10301,
  },
  {
    description: 'Convenience food stores',
    coterieAkHash: 'ba7269099932e1808df4f613037f6618',
    coterieId: 10165,
  },
  {
    description: 'Frozen meat stores',
    coterieAkHash: 'bdf374f85fa10c2cb374532f64d8b322',
    coterieId: 10289,
  },
  {
    description: 'Seafood markets',
    coterieAkHash: '4e70a46df431a58581747390058ae3f6',
    coterieId: 10288,
  },
  {
    description: 'Fruit stands, permanent',
    coterieAkHash: '01f4e42e90e3da3fdbe7e72dafc36ee4',
    coterieId: 10290,
  },
  {
    description:
      'Baked goods stores, retailing only (except immediate consumption)',
    coterieAkHash: 'f7a657e266ada4d80923ff8c442f0daf',
    coterieId: 10066,
  },
  {
    description: 'Nut (i.e., packaged) stores',
    coterieAkHash: 'aecfed0bcb8ba1c5b63da4c225119b25',
    coterieId: 10105,
  },
  {
    description: 'Coffee and tea (i.e., packaged) stores',
    coterieAkHash: '3ad82429a6bb9df1c142a0568f34e5c4',
    coterieId: 10177,
  },
  {
    description: 'Apothecaries',
    coterieAkHash: '027a3a28428992f90de15a8548b38940',
    coterieId: 10224,
  },
  {
    description: 'Beauty supply stores',
    coterieAkHash: '2506947d13512aac17c0ae0164878f14',
    coterieId: 10169,
  },
  {
    description: 'Sunglass stores',
    coterieAkHash: '5654f7f3e635cd5c84c8909ba48d7812',
    coterieId: 10492,
  },
  {
    description: 'Nutrition (i.e., food supplement) stores',
    coterieAkHash: '2a72349d4025021ddc018aabaee9d5ae',
    coterieId: 10326,
  },
  {
    description: 'Medical equipment and supplies stores',
    coterieAkHash: '2e915b91c36975860718328659ade24d',
    coterieId: 10328,
  },
  {
    description: "Clothing stores, men's and boys'",
    coterieAkHash: '2d61dd5445d773097f5e29ff5746fca8',
    coterieId: 10136,
  },
  {
    description: "Apparel stores, women's and girls' clothing",
    coterieAkHash: '06a682b67026b1b9e94ddfa74d9c1d4d',
    coterieId: 10132,
  },
  {
    description: "Clothing stores, children's and infants'",
    coterieAkHash: '1720c1ea5802f31d600e6d22c8d4e48d',
    coterieId: 10128,
  },
  {
    description: 'Unisex clothing stores',
    coterieAkHash: '227385aab6467e9c376535fc3c888a97',
    coterieId: 10129,
  },
  {
    description: 'Apparel accessory stores',
    coterieAkHash: '1d25cb1bbef7372c8c67a5eb2d256311',
    coterieId: 10130,
  },
  {
    description: 'Sports apparel stores (except uniforms)',
    coterieAkHash: '1c408f4dfb93f581e8cd5589aa84e92f',
    coterieId: 10659,
  },
  {
    description: 'Shoe stores, orthopedic',
    coterieAkHash: '037dd88d3c0719f571d54ae93ea1f520',
    coterieId: 10638,
  },
  {
    description: 'Jewelry stores, precious',
    coterieAkHash: '173c8cb41dd22aaf80f84cf9f4f31c2d',
    coterieId: 10396,
  },
  {
    description: 'Leather goods stores',
    coterieAkHash: '1d4e64b5c888a08fe0b2f9b26887aa5e',
    coterieId: 10135,
  },
  {
    description: 'Exercise equipment stores',
    coterieAkHash: '0671194714069c88e1b98c7f1ac072c7',
    coterieId: 10659,
  },
  {
    description: 'Sports gear stores (e.g., outdoors, scuba, skiing)',
    coterieAkHash: '0f6c28cb12e13f2ad8716b3e02bd2a8d',
    coterieId: 10659,
  },
  {
    description: 'Tackle shops (i.e., fishing)',
    coterieAkHash: '446fe0982b9ee19aa31750ca4267bea5',
    coterieId: 10659,
  },
  {
    description: 'Athletic equipment and supply stores (including uniforms)',
    coterieAkHash: '4c2ad310d1d655cc6265500a93613ed9',
    coterieId: 10659,
  },
  {
    description: 'Uniform stores, athletic',
    coterieAkHash: '53d442b563857e54eeea2cdb64eeffb1',
    coterieId: 10139,
  },
  {
    description:
      'Footwear (e.g., bowling, golf, spiked), specialty sports, stores',
    coterieAkHash: '5dff2ecc8c11e69dadc43aa1faa8901b',
    coterieId: 10139,
  },
  {
    description: 'Pro shops (e.g., golf, skiing, tennis)',
    coterieAkHash: '63081ea9545a57702ef822874ca06021',
    coterieId: 10659,
  },
  {
    description: 'Sporting goods stores',
    coterieAkHash: '67125ad3f6b323833a2f17c99fa04436',
    coterieId: 10659,
  },
  {
    description: 'Bicycle (except motorized) shops',
    coterieAkHash: '70d1aea9e69400149d95da04d9d66347',
    coterieId: 10073,
  },
  {
    description: 'Fishing supply stores (e.g., bait)',
    coterieAkHash: '7ab15ccfbf5c51d70ec3e809c996be34',
    coterieId: 10659,
  },
  {
    description: 'Golf pro shops',
    coterieAkHash: '7d539ee99800657e5890aa81b6d832d8',
    coterieId: 10659,
  },
  {
    description: 'Outdoor sporting equipment stores',
    coterieAkHash: 'a3c813a253232fc92c00521fe5cb7b3b',
    coterieId: 10659,
  },
  {
    description: 'Diving equipment stores',
    coterieAkHash: 'a7881cb8a973ad5243290a6538e57d6f',
    coterieId: 10659,
  },
  {
    description:
      'Shoe stores, specialty sports footwear (e.g., bowling, golf, spiked)',
    coterieAkHash: 'bfae33b87289643050e71864563eb101',
    coterieId: 10139,
  },
  {
    description: 'Saddlery stores',
    coterieAkHash: 'cb4cf89c99a8a50eacaedf843b8e9afa',
    coterieId: 10139,
  },
  {
    description: 'Tack (e.g., harnesses, saddlery) shops',
    coterieAkHash: 'e5553f2d9ab4d2b509aa13f81c846b42',
    coterieId: 10659,
  },
  {
    description: 'Bowling equipment and supply stores',
    coterieAkHash: 'f57026d3895e1798098d6f10fa8d881f',
    coterieId: 10659,
  },
  {
    description:
      'Pottery (unfinished pottery to be painted by customer on premises) stores',
    coterieAkHash: '8ae50ab022c08221b5fb6597a90bdadc',
    coterieId: 10332,
  },
  {
    description: 'Upholstery materials stores',
    coterieAkHash: '60641af86ee45879d7ab1b76c55f39ca',
    coterieId: 10260,
  },
  {
    description: 'Music stores (i.e., instrument)',
    coterieAkHash: '15d7dece791e74a081abbb76d997daef',
    coterieId: 10474,
  },
  {
    description: 'Book stores',
    coterieAkHash: '5f34f77d36c1c9fb201969dfecc84117',
    coterieId: 10092,
  },
  {
    description: 'News dealers',
    coterieAkHash: '059eabcefd28d4296faa4cd58c56eb45',
    coterieId: 10477,
  },
  {
    description: 'Department stores',
    coterieAkHash: 'c3fb1221af20f63fa368ee60e5d72568',
    coterieId: 10197,
  },
  {
    description: 'Supercenters',
    coterieAkHash: '5aa6eb31da2fa33b5fcaee9e219422f0',
    coterieId: 10671,
  },
  {
    description: 'Limited price variety stores',
    coterieAkHash: '0b6bc698792eade49cd9974b755aa306',
    coterieId: 10727,
  },
  {
    description: 'Flower shops, fresh',
    coterieAkHash: 'dc48a4db1b9cae93124d82158e0f863b',
    coterieId: 10287,
  },
  {
    description: 'Office supply stores',
    coterieAkHash: '9bd804476967a35c77da4a73051ed376',
    coterieId: 10660,
  },
  {
    description: 'Novelty shops',
    coterieAkHash: '0107b9f77c91dab3a0cac82327c804ea',
    coterieId: 10303,
  },
  {
    description:
      "Used rare collectors' items (e.g., autograph, coin, card, stamps) shops",
    coterieAkHash: '1aed502cfac0cb4e1cb980733a824103',
    coterieId: 10029,
  },
  {
    description: 'Consignment shops, used merchandise',
    coterieAkHash: '2773bcf7111248d2fcb8205e1461af41',
    coterieId: 10601,
  },
  {
    description: 'Bicycle (except motorized) shops, used',
    coterieAkHash: 'e9ba121d9a2a724393c63aa163f6c7cc',
    coterieId: 10073,
  },
  {
    description: 'Antique dealers (except motor vehicles)',
    coterieAkHash: '3ad77774fc8faf3abbf45bbcab13ca02',
    coterieId: 10029,
  },
  {
    description: 'Book stores, used',
    coterieAkHash: '44fdb4e4e83feacc60d9f2bf7cbdd525',
    coterieId: 10093,
  },
  {
    description: 'Apparel stores, used clothing',
    coterieAkHash: '6493777432631d9c12526b5a4924cac9',
    coterieId: 10601,
  },
  {
    description: 'Appliance stores, household-type, used',
    coterieAkHash: '7bfc1ef367745a4be0da3227a9e12bc9',
    coterieId: 10097,
  },
  {
    description: 'Record stores, used',
    coterieAkHash: '94f14b5f57244a055b660a5ae3eb581c',
    coterieId: 10471,
  },
  {
    description: 'Clothing stores, used',
    coterieAkHash: '9bc102b6b34988919a51f6f0516101da',
    coterieId: 10601,
  },
  {
    description: 'Architectural salvage dealers',
    coterieAkHash: 'a9866f1c5d79998048108080e19e6cd1',
    coterieId: 10097,
  },
  {
    description:
      'Music stores (e.g., cassette, instrument, record, tape), used',
    coterieAkHash: 'b085c96c36185afdfabc01f8e37d5ab8',
    coterieId: 10471,
  },
  {
    description: 'Secondhand merchandise stores',
    coterieAkHash: 'b2475ccba7cc3e9d2b6b9bc3b43c3145',
    coterieId: 10601,
  },
  {
    description: 'Rare manuscript stores',
    coterieAkHash: 'b35ed678e2fc29df638b63f9634b726a',
    coterieId: 10029,
  },
  {
    description: 'Antique shops',
    coterieAkHash: 'c21befed89ac44844f831b40f1984d86',
    coterieId: 10029,
  },
  {
    description: 'Sporting goods stores, used',
    coterieAkHash: 'f0855aa776c15e0bb79ef90a440b6cbd',
    coterieId: 10659,
  },
  {
    description: 'Furniture stores, used',
    coterieAkHash: 'fec31491d016e95400f3c48373e9a633',
    coterieId: 10601,
  },
  {
    description: 'Feed stores, pet',
    coterieAkHash: '806e13383cc4ca1a29b109fd0e2dd12b',
    coterieId: 10518,
  },
  {
    description: 'Art auctions',
    coterieAkHash: '487ba89c09bfaa0a743d4e2e11e07be7',
    coterieId: 10048,
  },
  {
    description: 'Trophy (including awards and plaques) shops',
    coterieAkHash: '3bf963e69559c1b3a19fc989bb0609d2',
    coterieId: 10723,
  },
  {
    description: 'Architectural supply stores',
    coterieAkHash: '3e1de2c593d37815fc01232900c16a3c',
    coterieId: 10334,
  },
  {
    description:
      "Collectors' items (e.g., autograph, card, coin, stamp) shops (except used rare items)",
    coterieAkHash: '59c9293251ed0e40dc0184688d3c56d0',
    coterieId: 10144,
  },
  {
    description: 'Flag and banner shops',
    coterieAkHash: '5f969a18881f571b333bd4d0a1043906',
    coterieId: 10260,
  },
  {
    description:
      'Cemetery memorial dealers (e.g., headstones, markers, vaults)',
    coterieAkHash: '68bc1983f70b8f1e8b1e8824e9fbec96',
    coterieId: 10591,
  },
  {
    description: 'Swimming pool supply stores',
    coterieAkHash: '6b8683aaf1d4caf6bc7392eb5bd73c07',
    coterieId: 10674,
  },
  {
    description: 'Monument (i.e., burial marker) dealers',
    coterieAkHash: '84dd6f85bbadc7343dddcf4b27bd0a39',
    coterieId: 10591,
  },
  {
    description: 'Candle shops',
    coterieAkHash: '889194ecbaf886855e1ea79e1319d81a',
    coterieId: 10303,
  },
  {
    description: 'Janitorial equipment and supplies stores',
    coterieAkHash: '9e4c95901fbb9b52fb7997e98dce3908',
    coterieId: 10393,
  },
  {
    description: 'Emergency preparedness supply stores',
    coterieAkHash: 'b9d63da3fe1868138ed18c91fc2240d1',
    coterieId: 10278,
  },
  {
    description: 'Cake decorating supply stores',
    coterieAkHash: 'cf4ed9754ef5021ca121c9c49887acce',
    coterieId: 10065,
  },
  {
    description: 'Closet organizer stores',
    coterieAkHash: 'cf89d33190f668871181fa0abc4f5bc0',
    coterieId: 10334,
  },
  {
    description: 'Batteries, except automotive, dealers',
    coterieAkHash: 'd1cbf956526a8be48fba2343663703bb',
    coterieId: 10239,
  },
  {
    description: 'Religious goods (except books) stores',
    coterieAkHash: 'd7ff1f685ac0a80993ccdc01c15196ed',
    coterieId: 10144,
  },
  {
    description: 'Art supply stores',
    coterieAkHash: 'e3f01db4c3ca586f8f11449a76d66bd9',
    coterieId: 10332,
  },
  {
    description: 'Flower shops, artificial or dried',
    coterieAkHash: 'e7367c38be3947ef946a6e94fe51d0f4',
    coterieId: 10287,
  },
  {
    description: 'Calendar shops',
    coterieAkHash: 'ea95ef98001de7ed984b957a0efb69a0',
    coterieId: 10092,
  },
  {
    description: 'Binocular stores',
    coterieAkHash: 'ef5ac1574d6dd1fede8ff745e32a36b8',
    coterieId: 10552,
  },
  {
    description: 'Auction houses (general merchandise)',
    coterieAkHash: 'fa77be4708cb4585326c5b16ecd78476',
    coterieId: 10115,
  },
  {
    description: 'Automatic merchandising machine operators',
    coterieAkHash: '5fd273b44509051ffcd41239ddde837d',
    coterieId: 10597,
  },
  {
    description: 'Frozen food and freezer meal plan providers, direct selling',
    coterieAkHash: '53359e33e00f9c0774f0f0fef8d4b4a4',
    coterieId: 10597,
  },
  {
    description: 'Bazaars (i.e., temporary stands)',
    coterieAkHash: '02ba373ec9c43774c1f2f6361fc3a37a',
    coterieId: 10597,
  },
  {
    description: 'In-home sales of merchandise, direct selling',
    coterieAkHash: '2058f6d8c09c221c68f9e0acc78b7764',
    coterieId: 10597,
  },
  {
    description: 'Locker meat provisioners, direct selling',
    coterieAkHash: '38245a1b514fe7ac019df5a9c071431f',
    coterieId: 10597,
  },
  {
    description: 'Bottled water providers, direct selling',
    coterieAkHash: '7522787580366d72fd7d50d8b45a1644',
    coterieId: 10597,
  },
  {
    description: 'Party plan merchandisers, direct selling',
    coterieAkHash: 'aeb609d2f48751c389602d88d8c15e8e',
    coterieId: 10597,
  },
  {
    description: 'Fruit stands, temporary',
    coterieAkHash: 'c070550e6d0bba5c953fc236ba85f25a',
    coterieId: 10597,
  },
  {
    description: 'Produce stands, temporary',
    coterieAkHash: 'c49612a2f7f92a96d535511e6f6ba15f',
    coterieId: 10597,
  },
  {
    description: 'Water softener service providers, direct selling',
    coterieAkHash: 'e29b36d4e9d3460bce3e868fb085402f',
    coterieId: 10597,
  },
  {
    description: 'Christmas trees, cut, direct selling',
    coterieAkHash: 'e7faaf10897ab4dc6f00aa191fc5683f',
    coterieId: 10597,
  },
  {
    description: 'Street vendors (except food)',
    coterieAkHash: 'e88bfa7afccdafaf082fb0812df05480',
    coterieId: 10597,
  },
  {
    description: 'Coffee-break supplies providers, direct selling',
    coterieAkHash: 'eeaf576e9ef35815bb1327248639b210',
    coterieId: 10597,
  },
  {
    description: 'Door-to-door retailing of merchandise, direct selling',
    coterieAkHash: 'f1115fc0c6b6caef4d02636226d3e229',
    coterieId: 10597,
  },
  {
    description: 'Publishers, newspaper, combined with printing',
    coterieAkHash: '2296bfdd30e3fdbb456db8a1c3736a7c',
    coterieId: 10430,
  },
  {
    description:
      'Religious magazine and periodical publishers and printing combined',
    coterieAkHash: '164c8ab08a9d11cb23377dc61ea76b8b',
    coterieId: 10430,
  },
  {
    description: 'School book publishers and printing combined',
    coterieAkHash: '03100d0afd80b348581b9d67438ff5c2',
    coterieId: 10430,
  },
  {
    description:
      'Catalog of collections publishers (except exclusive Internet publishing)',
    coterieAkHash: '08382fcc54d754ebc98d1ce94bb79775',
    coterieId: 10430,
  },
  {
    description: 'Greeting card publishers and printing combined',
    coterieAkHash: '034f57397094b23ed2c10f909a750a6c',
    coterieId: 10430,
  },
  {
    description: 'Poster publishers (except exclusive Internet publishing)',
    coterieAkHash: '0c93a2b74a0fe7837014a5138addd681',
    coterieId: 10430,
  },
  {
    description: 'Games, computer software, publishing',
    coterieAkHash: '062ac79188e64a0da2ed8dcc713b563b',
    coterieId: 10025,
  },
  {
    description: 'Motion picture production',
    coterieAkHash: '06c8c78c2eda6c6fd4d260e61819cb57',
    coterieId: 10734,
  },
  {
    description: 'Tape distribution for television',
    coterieAkHash: '041a55eb1bdfb7cea7ceddd9b5359a19',
    coterieId: 10098,
  },
  {
    description: 'Motion picture or video editing services',
    coterieAkHash: '0100c28a4a3cd616e805620c2c44c626',
    coterieId: 10734,
  },
  {
    description: 'Motion picture film laboratories',
    coterieAkHash: '015a63ba3f399afacb77d66286e16750',
    coterieId: 10734,
  },
  {
    description: 'Publishers, music',
    coterieAkHash: '1d1dce16178ddb65fcebf5dcc7fc0a7a',
    coterieId: 10430,
  },
  {
    description: 'Audio recording postproduction services',
    coterieAkHash: '0b11b51241da58ad7d642f08295f55ac',
    coterieId: 10061,
  },
  {
    description: 'Integrated record production and distribution',
    coterieAkHash: '0e8f5d6b27f3a9f420bbc2cc04fcc9d7',
    coterieId: 10061,
  },
  {
    description: 'Music program distribution, prerecorded',
    coterieAkHash: '331e3f1174d07b25c8b7b972ef8a2ce7',
    coterieId: 10061,
  },
  {
    description: 'Computer input preparation services',
    coterieAkHash: '0982529d51511db05f5b5ac85d55a084',
    coterieId: 10183,
  },
  {
    description: 'Music archives',
    coterieAkHash: '3d70692fe2d49a384e7f86c6207c7d25',
    coterieId: 10061,
  },
  {
    description: 'Motion picture film libraries, archives',
    coterieAkHash: '90422d4e4d7b11972e57264ff2ea6999',
    coterieId: 10734,
  },
  {
    description: 'Film archives',
    coterieAkHash: 'b24d34b134e32d42d7efe2ac5705efae',
    coterieId: 10734,
  },
  {
    description: 'Postcard publishers, exclusively on Internet',
    coterieAkHash: '0768730e63b21f938f9d65bd9bb666b0',
    coterieId: 10747,
  },
  {
    description: 'Publishers, Internet map',
    coterieAkHash: '0b11b1e284c020b41fc04d958095b2f4',
    coterieId: 10747,
  },
  {
    description: 'Art publishers, exclusively on Internet',
    coterieAkHash: '0fde6cd08b762b0b73b4a0e84b0e0d9f',
    coterieId: 10747,
  },
  {
    description: 'Encyclopedia publishers, exclusively on Internet',
    coterieAkHash: '1289defd8e0d20db90e70757d8dd0af1',
    coterieId: 10747,
  },
  {
    description:
      'Juvenile magazine and periodical publishers, exclusively on Internet',
    coterieAkHash: '1714b23dc4162cebf5b4478dcb106400',
    coterieId: 10747,
  },
  {
    description: 'Web search portals',
    coterieAkHash: '1a19f38979fc7cc460513af61bf639da',
    coterieId: 10747,
  },
  {
    description: 'Trade journal publishers, exclusively on Internet',
    coterieAkHash: '1b5918cd7f95d39909a603387a435a43',
    coterieId: 10747,
  },
  {
    description: 'Religious book publishers, exclusively on Internet',
    coterieAkHash: '1c4329b4bce6180425b24a9a65f95890',
    coterieId: 10747,
  },
  {
    description: 'Electronic directory publishers, exclusively on Internet',
    coterieAkHash: '1e6c95b44e1fdff8384f612a94998950',
    coterieId: 10747,
  },
  {
    description: 'Social networking sites, Internet',
    coterieAkHash: '24f450905bdc68823da0b2a08f217b0b',
    coterieId: 10747,
  },
  {
    description: 'Radio guide publishers, exclusively on Internet',
    coterieAkHash: '264383178479838debb379c608c99b08',
    coterieId: 10747,
  },
  {
    description:
      'Agricultural magazine and periodical publishers, exclusively on Internet',
    coterieAkHash: '27b9cb052c6b2751be7dc85eda6d9f21',
    coterieId: 10747,
  },
  {
    description: 'Periodical publishers, exclusively on Internet',
    coterieAkHash: '2e33aa1b0c36945e8bdbd3bde1c59c91',
    coterieId: 10747,
  },
  {
    description:
      'Religious magazine and periodical publishers, exclusively on Internet',
    coterieAkHash: '2e99ef0ff4244d20c2042ff848662133',
    coterieId: 10747,
  },
  {
    description: 'Publishers, Internet greeting card',
    coterieAkHash: '31e75278bf3865444cb3ccfd6ca44807',
    coterieId: 10747,
  },
  {
    description: 'Diary and time scheduler publishers, exclusively on Internet',
    coterieAkHash: '338635a18053d66e1a6b283304d5a7aa',
    coterieId: 10747,
  },
  {
    description: 'Street map and guide publishers, exclusively on Internet',
    coterieAkHash: '33c8233b7050067de7e743a8b63e80a5',
    coterieId: 10747,
  },
  {
    description: 'Technical manual publishers, exclusively on Internet',
    coterieAkHash: '369049f49498b94c4f3c6e8b49787d13',
    coterieId: 10747,
  },
  {
    description: 'Search portals, Internet',
    coterieAkHash: '3b357787fe590c5c3f6ba1dc52a3ddb8',
    coterieId: 10747,
  },
  {
    description: 'Internet magazine publishing',
    coterieAkHash: '3e9fe61480624e10601d2e3c39bbc724',
    coterieId: 10747,
  },
  {
    description: 'University press publishers, exclusively on Internet',
    coterieAkHash: '432a6df57b9075f6f6ff46d396c7f637',
    coterieId: 10747,
  },
  {
    description: "Children's coloring book publishers, exclusively on Internet",
    coterieAkHash: '4b58b444adc60136b3bc8fbf33d806d8',
    coterieId: 10747,
  },
  {
    description: 'Internet comic book publishing',
    coterieAkHash: '4e72cea69ca278cbd09060f98b18337b',
    coterieId: 10747,
  },
  {
    description: 'Travel guide book publishers, exclusively on Internet',
    coterieAkHash: '50dd0260ad78438aeaea93c35f9dd484',
    coterieId: 10747,
  },
  {
    description: 'Book publishers, exclusively on Internet',
    coterieAkHash: '53b09701af3e6ec53d34e97dc512e411',
    coterieId: 10747,
  },
  {
    description: 'Internet book publishers',
    coterieAkHash: '58263e78b3367f6eb0dac93adf7916c9',
    coterieId: 10747,
  },
  {
    description: 'Comic book publishers, exclusively on Internet',
    coterieAkHash: '582aa85ebb06066809dbf842be0adb03',
    coterieId: 10747,
  },
  {
    description: 'Greeting card publishers, exclusively on Internet',
    coterieAkHash: '58d57527369545ff82c34bb1e7ae60fc',
    coterieId: 10747,
  },
  {
    description: 'Atlas publishers, exclusively on Internet',
    coterieAkHash: '5982c064d3fcf9f4b9c15a6a1b31867c',
    coterieId: 10747,
  },
  {
    description: 'Nonfiction book publishers, exclusively on Internet',
    coterieAkHash: '5c35c7baad2121677c57a942aa44a5a6',
    coterieId: 10747,
  },
  {
    description: 'Internet newspaper publishing',
    coterieAkHash: '5c8f8185e7b5380ed6936033e5d318b6',
    coterieId: 10747,
  },
  {
    description: 'Dictionary publishers, exclusively on Internet',
    coterieAkHash: '5d2cd9524aa36f2b111cf7852055aa6e',
    coterieId: 10747,
  },
  {
    description: 'Advertising periodical publishers, exclusively on Internet',
    coterieAkHash: '5e2aa5c03cd9c2be6b33888584b8dc56',
    coterieId: 10747,
  },
  {
    description: 'Publishers, Internet racing form',
    coterieAkHash: '62883afb17eeef6e704a00588868695f',
    coterieId: 10747,
  },
  {
    description:
      'Trade magazine and periodical publishers, exclusively on Internet',
    coterieAkHash: '68e6c2f1794d57e540ebd630295890ca',
    coterieId: 10747,
  },
  {
    description: 'Magazine publishers, exclusively on Internet',
    coterieAkHash: '6b40c0b205d20be07995c5fdea8ef6b1',
    coterieId: 10747,
  },
  {
    description: 'Business directory publishers, exclusively on Internet',
    coterieAkHash: '7526cf9b399887d2219c51154f1a098e',
    coterieId: 10747,
  },
  {
    description: 'Internet broadcasting',
    coterieAkHash: '755577da6aca07cf02ba958a137f8c03',
    coterieId: 10747,
  },
  {
    description: 'Web communities',
    coterieAkHash: '75c3dbc950f1ce16592b42421b1ed8c4',
    coterieId: 10747,
  },
  {
    description:
      'Special interest portals (e.g., parents sharing information about child rearing, etc.), Internet',
    coterieAkHash: '7763adb5a246f7ea172a49b7178ca3b5',
    coterieId: 10747,
  },
  {
    description: 'Broadcasting exclusively on Internet, video',
    coterieAkHash: '77851c17dd20a00fc6ed65086edace9b',
    coterieId: 10747,
  },
  {
    description: 'Directory publishers, exclusively on Internet',
    coterieAkHash: '7898e100a1ad004716c75cadd7e3b675',
    coterieId: 10747,
  },
  {
    description: 'Television guide publishers, exclusively on Internet',
    coterieAkHash: '7a0e880715638c7fede315fa49bc8b07',
    coterieId: 10747,
  },
  {
    description: 'Newspaper publishing, exclusively on Internet',
    coterieAkHash: '7e3365f2ad2049470dd0688dd1cc1378',
    coterieId: 10747,
  },
  {
    description: 'Entertainment sites, Internet',
    coterieAkHash: '81861295a3671cadedec1f939e28d681',
    coterieId: 10747,
  },
  {
    description:
      'Scholastic magazine and periodical publishers, exclusively on Internet',
    coterieAkHash: '82fd552451909ce591d7f4ed52cb10da',
    coterieId: 10747,
  },
  {
    description:
      'Medical journal and periodical publishers, exclusively on Internet',
    coterieAkHash: '88aaf90d8d98af9e993cde128cdd847d',
    coterieId: 10747,
  },
  {
    description: 'Internet radio stations',
    coterieAkHash: '88f2c71c68ba6f307eed356ab7ec1661',
    coterieId: 10747,
  },
  {
    description: 'Racing form publishers, exclusively on Internet',
    coterieAkHash: '90200e257f6724ce678c58b9a9fd8027',
    coterieId: 10747,
  },
  {
    description: 'Racetrack program publishers, exclusively on Internet',
    coterieAkHash: '9ba5b6973596a7470eeaedbbadd8f4e9',
    coterieId: 10747,
  },
  {
    description: 'Internet periodical publishers',
    coterieAkHash: '9bda43502c3ca2f1e8335b7c94c2cf0f',
    coterieId: 10747,
  },
  {
    description: 'Map publishers, exclusively on Internet',
    coterieAkHash: 'a0d262ca80696a564393ef1ccb7c5250',
    coterieId: 10747,
  },
  {
    description: 'Calendar publishers, exclusively on Internet',
    coterieAkHash: 'a3010b9de92af8114badb05f665f038a',
    coterieId: 10747,
  },
  {
    description: 'Web broadcasting',
    coterieAkHash: 'a8974c092390689ac8cf9bc53a697fa9',
    coterieId: 10747,
  },
  {
    description:
      'Professional magazine and periodical publishers, exclusively on Internet',
    coterieAkHash: 'ab50212c752aa7165cb9b13a52809ecc',
    coterieId: 10747,
  },
  {
    description: 'Video broadcasting, exclusively on Internet',
    coterieAkHash: 'ad47a6b3ed573a540dc3c1f553e6673f',
    coterieId: 10747,
  },
  {
    description: 'Portals, web search',
    coterieAkHash: 'b15b75e1d72ca57099ebbad421c9ecfb',
    coterieId: 10747,
  },
  {
    description:
      'Yearbook (e.g., high school, college, university) publishers, exclusively on Internet',
    coterieAkHash: 'b160995f72ab18be198eb31bc4d76791',
    coterieId: 10747,
  },
  {
    description: 'Pamphlet publishers, exclusively on Internet',
    coterieAkHash: 'b2cdc413cb43d2f5f71f57088886a89f',
    coterieId: 10747,
  },
  {
    description: 'Almanac publishers, exclusively on Internet',
    coterieAkHash: 'be3dbbd9aa7a47da19d113efd7466c8f',
    coterieId: 10747,
  },
  {
    description: 'Discount coupon book publishers, exclusively on Internet',
    coterieAkHash: 'c200b0dc26311e3459b3a6849d22c02c',
    coterieId: 10747,
  },
  {
    description: 'Broadcasting exclusively on Internet, audio',
    coterieAkHash: 'c4ed4823565fb7e7eba3258ea5cdf406',
    coterieId: 10747,
  },
  {
    description: 'Radio schedule publishers, exclusively on Internet',
    coterieAkHash: 'ca03edf0a90036826bb8431e5d0be2b5',
    coterieId: 10747,
  },
  {
    description: 'Internet video broadcast sites',
    coterieAkHash: 'cb248b61302d3c5d39e757bc4ff5a92e',
    coterieId: 10747,
  },
  {
    description: 'Professional book publishers, exclusively on Internet',
    coterieAkHash: 'cf12eb12e5bee33c413ad97300a28b0c',
    coterieId: 10747,
  },
  {
    description:
      'Technical magazine and periodical publishers, exclusively on Internet',
    coterieAkHash: 'd07aab5836184dad083784a613217b48',
    coterieId: 10747,
  },
  {
    description: 'Internet news publishers',
    coterieAkHash: 'd19e365d26819a1004da20528e637fea',
    coterieId: 10747,
  },
  {
    description: 'Fiction book publishers, exclusively on Internet',
    coterieAkHash: 'd2e8edb61a4af1571ca3dcf4d0c8850a',
    coterieId: 10747,
  },
  {
    description: 'School textbook publishers, exclusively on Internet',
    coterieAkHash: 'd578e98a821af253ef0f63306e0243ce',
    coterieId: 10747,
  },
  {
    description:
      'Pattern and plan (e.g., clothing patterns) publishers, exclusively on Internet',
    coterieAkHash: 'd823b0597bff218b1ba050c4ed61eacf',
    coterieId: 10747,
  },
  {
    description: 'Scholarly journal publishers, exclusively on Internet',
    coterieAkHash: 'dd710c301a984e39dd842ca6deae0702',
    coterieId: 10747,
  },
  {
    description: 'Telephone directory publishers, exclusively on Internet',
    coterieAkHash: 'e0913b53a3033d62bd8830b24e4a452f',
    coterieId: 10747,
  },
  {
    description:
      'Financial magazine and periodical publishers, exclusively on Internet',
    coterieAkHash: 'f0500a2469aa5a46fd376c0dd3303522',
    coterieId: 10747,
  },
  {
    description: 'Internet newsletter publishing',
    coterieAkHash: 'f0fea8524814d3b1006edc5d917cbb86',
    coterieId: 10747,
  },
  {
    description: 'Guide, street map, publishers, exclusively on Internet',
    coterieAkHash: 'f6b45d82aff6a5d0982b97553544fc82',
    coterieId: 10747,
  },
  {
    description:
      'Scientific journal and periodical publishers, exclusively on Internet',
    coterieAkHash: 'f83dd39eeab7c8de9cd5961a95b00018',
    coterieId: 10747,
  },
  {
    description: 'Newsletter publishers, exclusively on Internet',
    coterieAkHash: 'f9b5ca6f938adab4be863731b248d687',
    coterieId: 10747,
  },
  {
    description: 'Catalog of collections publishers, exclusively on Internet',
    coterieAkHash: 'fcac99d798af44747db60d94e3ca24fb',
    coterieId: 10747,
  },
  {
    description: 'Poster publishers, exclusively on Internet',
    coterieAkHash: 'fd85d619f5719557833efc95849d9a90',
    coterieId: 10747,
  },
  {
    description: 'School book publishers, exclusively on Internet',
    coterieAkHash: 'fdcbd63f9e5a47e2779ce0f776a0f3ad',
    coterieId: 10747,
  },
  {
    description: 'Stock photo agencies',
    coterieAkHash: '2d019e9454c1a4dcfd16dab23c89e150',
    coterieId: 10747,
  },
  {
    description: 'Private equity fund managing',
    coterieAkHash: '1592d53e3912122f553d61fd47a7a845',
    coterieId: 10271,
  },
  {
    description:
      'Investment advice consulting services, customized, fees paid by client',
    coterieAkHash: '041c93b29b9edc9b5ecc3b694ae0ffe7',
    coterieId: 10271,
  },
  {
    description: 'Insurance brokerages',
    coterieAkHash: '74bb3cabbf5956cf0788681fb53ef065',
    coterieId: 10377,
  },
  {
    description: 'Insurance claims investigation services',
    coterieAkHash: '019e15f06616f1c2d796d167d09903ec',
    coterieId: 10127,
  },
  {
    description: 'Claims processing services, insurance, third party',
    coterieAkHash: '031499826555532462d94408e9c53ea5',
    coterieId: 10127,
  },
  {
    description: 'Insurance claims processing services, third party',
    coterieAkHash: '231bc20c36a9f385df4b2f9c44e9a601',
    coterieId: 10127,
  },
  {
    description: 'Insurance actuarial services',
    coterieAkHash: '05124a2321e023fb4c4e7568729a5e58',
    coterieId: 10667,
  },
  {
    description: 'Medical cost evaluation services',
    coterieAkHash: '3fa8ac4f6f6a607230a5699613d847c6',
    coterieId: 10667,
  },
  {
    description: 'Insurance underwriters laboratories and standards services',
    coterieAkHash: '539436e3c6598bac1f36c03c516b4502',
    coterieId: 10667,
  },
  {
    description: 'Actuarial services, insurance',
    coterieAkHash: '5f6dfb5f128bf40e5ef96124c3ec797a',
    coterieId: 10667,
  },
  {
    description: 'Ratemaking services, insurance',
    coterieAkHash: '6a7b62c1a8b7fed032ebabc482f67747',
    coterieId: 10667,
  },
  {
    description: 'Insurance advisory services',
    coterieAkHash: '9ae2674518fb63fb591c223755d95da9',
    coterieId: 10667,
  },
  {
    description: 'Insurance reporting services',
    coterieAkHash: 'a601d709e2da94f012d3e885598f5ecb',
    coterieId: 10667,
  },
  {
    description: 'Insurance rate making services',
    coterieAkHash: 'b381a0c9e3f7bd12220b2e8cc902df4d',
    coterieId: 10667,
  },
  {
    description: 'Insurance loss prevention services',
    coterieAkHash: 'd99c871e465ed5835fd2ff06567d2478',
    coterieId: 10374,
  },
  {
    description:
      'Insurance investigation services (except claims investigation)',
    coterieAkHash: 'e1f094e9b9b34f7b325484e100655f1a',
    coterieId: 10374,
  },
  {
    description: 'Insurance coverage consulting services',
    coterieAkHash: 'f88445ddbd324961e5e2f02fde7c1b81',
    coterieId: 10667,
  },
  {
    description: 'Real estate agencies',
    coterieAkHash: '00e9b24a4f191d43ea3d66da3aa3a469',
    coterieId: 10580,
  },
  {
    description: 'Managing residential condominiums',
    coterieAkHash: '3adff9a5b929aa4bfa0c9d7bc7200959',
    coterieId: 10569,
  },
  {
    description: "Managers' offices, commercial condominium",
    coterieAkHash: '0213c8f7a9996c2ba28a883cac74c43a',
    coterieId: 10569,
  },
  {
    description: 'Appraisal services, real estate',
    coterieAkHash: '3f27ecafd78abe7b23f1c5f989bf9cd3',
    coterieId: 10374,
  },
  {
    description:
      'Real estate asset management services (except property management)',
    coterieAkHash: '066d2b56ce2727c8ecbd94f8d6288101',
    coterieId: 10580,
  },
  {
    description: 'Video tape player rental',
    coterieAkHash: '77dead52f58ccab0c99b0f2dd3692bd5',
    coterieId: 10735,
  },
  {
    description: 'Video tape rental stores',
    coterieAkHash: '1dbbc57c6806471acf48e201527c8b20',
    coterieId: 10735,
  },
  {
    description: 'Family law offices',
    coterieAkHash: '02b31d0cf2ff5c3cd3308c24cdfaf4c9',
    coterieId: 10415,
  },
  {
    description: 'Offices of notaries',
    coterieAkHash: '09cad4f953d41e7340590de9558fb7de',
    coterieId: 10482,
  },
  {
    description: 'Title abstract companies, real estate',
    coterieAkHash: '152d815434275638fcd69d76ec283071',
    coterieId: 10699,
  },
  {
    description: "Notary publics' private practices",
    coterieAkHash: '78fd08ff762be604af5912e0dfa98c50',
    coterieId: 10508,
  },
  {
    description: "Public accountants' (CPAs) offices, certified",
    coterieAkHash: '07335ea031dd44a5b2946f6dd773580f',
    coterieId: 10006,
  },
  {
    description: 'Income tax return preparation services',
    coterieAkHash: '226dfc0d3ada96040fb258dc48b562ee',
    coterieId: 10679,
  },
  {
    description: "Accountants' (except CPAs) offices",
    coterieAkHash: '13e89317f82d99300d627dae2727925f',
    coterieId: 10010,
  },
  {
    description: 'Architectural (except landscape) design services',
    coterieAkHash: '445c922147a3ccf019a9c5a7634aba3a',
    coterieId: 10045,
  },
  {
    description: "Urban planners' offices",
    coterieAkHash: '03e8db316d5704eece267d8bbd9da240',
    coterieId: 10045,
  },
  {
    description: 'Heating engineering consulting services',
    coterieAkHash: '0ba6d50e9d31a924d007b34d578f7086',
    coterieId: 10235,
  },
  {
    description: "Draftsmen's offices",
    coterieAkHash: '6d5a4b7b30122163f055bb22c26cfa73',
    coterieId: 10215,
  },
  {
    description: 'Home inspection services',
    coterieAkHash: '0de117273f3ee0e5ecd21a82827a4b61',
    coterieId: 10095,
  },
  {
    description: 'Radioactive geophysical surveying services',
    coterieAkHash: '1e1b7891b5b7b129246f129461a835dd',
    coterieId: 10403,
  },
  {
    description: 'Geographic information system (GIS) base mapping services',
    coterieAkHash: '2f670fb2a5e68f24f4cf4dc10bfb3e0a',
    coterieId: 10403,
  },
  {
    description: 'Vibration testing laboratories or services',
    coterieAkHash: '096255dc49f9e7a21f5f71ac5aef3990',
    coterieId: 10586,
  },
  {
    description: 'Geotechnical testing laboratories or services',
    coterieAkHash: '0aa72d8fd9aa0f92e2425ec69187625c',
    coterieId: 10586,
  },
  {
    description: 'Soil testing laboratories or services',
    coterieAkHash: '177ee4d572603edbb3e9c407c2c1aa2c',
    coterieId: 10586,
  },
  {
    description: 'Mechanical testing laboratories or services',
    coterieAkHash: '1f1d0fb962f566114f032f2c961b32d2',
    coterieId: 10586,
  },
  {
    description: 'Acoustics testing laboratories or services',
    coterieAkHash: '27164c174cffbe2fda870f5434dc1ccc',
    coterieId: 10586,
  },
  {
    description: 'Industrial testing laboratories or services',
    coterieAkHash: '2c947de04559c55c112e1f68a5c7850e',
    coterieId: 10586,
  },
  {
    description: "Fire insurance underwriters' laboratories",
    coterieAkHash: '3019e06af594b994731c726029164302',
    coterieId: 10586,
  },
  {
    description: 'Product testing laboratories or services',
    coterieAkHash: '38cf7215d1b80f818e1b45f99e097d5e',
    coterieId: 10586,
  },
  {
    description: 'Electrical testing laboratories or services',
    coterieAkHash: '43a4734e22bd2752b4d7d6f602077e89',
    coterieId: 10586,
  },
  {
    description: 'Radiographic testing laboratories or services',
    coterieAkHash: '514198083060c948a2d4ae4ade7a96e6',
    coterieId: 10586,
  },
  {
    description: 'Nondestructive testing laboratories or services',
    coterieAkHash: '5d51738f13e7a86f4bb85ad1a058cd63',
    coterieId: 10586,
  },
  {
    description: 'Thermal testing laboratories or services',
    coterieAkHash: '5dd27fb504282e5015b4867a6b201ca5',
    coterieId: 10586,
  },
  {
    description:
      'Biological (except medical, veterinary) testing laboratories or services',
    coterieAkHash: '66fc5ae4cf807463226b2c818da9210f',
    coterieId: 10586,
  },
  {
    description: 'Seed testing laboratories or services',
    coterieAkHash: '6dcc198caf415687d6bf559dafcff8db',
    coterieId: 10586,
  },
  {
    description: 'Electronic testing laboratories or services',
    coterieAkHash: '73ecac33338ef11b11148e062a81c2f2',
    coterieId: 10586,
  },
  {
    description: 'Forensic (except medical) laboratories or services',
    coterieAkHash: '77202742cc5984a689d2cc4ca45d111e',
    coterieId: 10586,
  },
  {
    description: 'Laboratory testing (except medical, veterinary) services',
    coterieAkHash: '8090fc2589d0c73ed54166408f93a039',
    coterieId: 10586,
  },
  {
    description: 'Environmental testing laboratories or services',
    coterieAkHash: '880e98591b1d90bb7d97fe3064fee494',
    coterieId: 10586,
  },
  {
    description: 'Metallurgical testing laboratories or services',
    coterieAkHash: '8a79b3ab46ebf8d31b175f69f057906f',
    coterieId: 10586,
  },
  {
    description:
      'Pollution testing (except automotive emissions testing) services',
    coterieAkHash: '8bf7f13b65dfa30375fcac9e275451e6',
    coterieId: 10586,
  },
  {
    description: 'X-ray inspection services',
    coterieAkHash: 'a07d23f06d8cd2311b742a351b083b56',
    coterieId: 10586,
  },
  {
    description: 'Radiation testing laboratories or services',
    coterieAkHash: 'a335a25e85b06ff8849d402909c700b3',
    coterieId: 10586,
  },
  {
    description: 'Food testing laboratories or services',
    coterieAkHash: 'afbd5ce7aa8686d8e8889c5660d530fe',
    coterieId: 10586,
  },
  {
    description: 'Testing laboratories (except medical, veterinary)',
    coterieAkHash: 'b551f38542481e32322269c6fdcaafc9',
    coterieId: 10586,
  },
  {
    description: 'Radiographing welded joints on pipes and fittings',
    coterieAkHash: 'c7854b572fd9f3e338e8cf7ee1b96fde',
    coterieId: 10586,
  },
  {
    description: 'Assaying services',
    coterieAkHash: 'c8204a8bbb63187a72b13186d511a252',
    coterieId: 10586,
  },
  {
    description:
      'Film badge testing (i.e., radiation testing) laboratories or services',
    coterieAkHash: 'd4459dab3fa1669015df379e9ed56126',
    coterieId: 10586,
  },
  {
    description: 'Hydrostatic testing laboratories or services',
    coterieAkHash: 'def47fdfe1d2a7664add1c359c979a48',
    coterieId: 10586,
  },
  {
    description: 'Radiography inspection services',
    coterieAkHash: 'ea48cefe899a5911b8120ab939c61854',
    coterieId: 10586,
  },
  {
    description:
      'Calibration and certification testing laboratories or services',
    coterieAkHash: 'eaf3a39e266f1779fcacaac219729e24',
    coterieId: 10586,
  },
  {
    description:
      'Radiation dosimetry (i.e., radiation testing) laboratories or services',
    coterieAkHash: 'ed413290e27c9a9fdd03aabdc7ee9b12',
    coterieId: 10586,
  },
  {
    description: 'Interior design services',
    coterieAkHash: '2ff77d3a4a58907d97ea3d45d63c720b',
    coterieId: 10247,
  },
  {
    description: 'Tool industrial design services',
    coterieAkHash: '3c49084bd0686b9e38da38733fbebd29',
    coterieId: 10368,
  },
  {
    description: 'Silk screen design services',
    coterieAkHash: '1c586960e85cc0f24e84939549b67798',
    coterieId: 10308,
  },
  {
    description: 'Float design services',
    coterieAkHash: '0409738cfa5d4af6bb4f240f850246d0',
    coterieId: 10561,
  },
  {
    description: 'Computer software analysis and design services, custom',
    coterieAkHash: '094a792ef1f1c09efe32f36d29bdac9f',
    coterieId: 10391,
  },
  {
    description:
      'CAM (computer-aided manufacturing) systems integration design services',
    coterieAkHash: '19466700c849d0cddacac37cf246b93f',
    coterieId: 10160,
  },
  {
    description:
      "Data processing facilities (i.e., clients' facilities) management and operation services",
    coterieAkHash: '5ecc62b070014a429b1d0fb5b749a384',
    coterieId: 10391,
  },
  {
    description: 'Computer disaster recovery services',
    coterieAkHash: '03ea7cd8e3153dd40c984f566f94e7d2',
    coterieId: 10006,
  },
  {
    description: 'Site location consulting services',
    coterieAkHash: '15c42bf57a96b0f1e18ba117055e878f',
    coterieId: 10431,
  },
  {
    description: 'Human resource consulting services',
    coterieAkHash: 'ccbf3082a925dc1476512b3179ea166e',
    coterieId: 10271,
  },
  {
    description: 'Marketing consulting services',
    coterieAkHash: '63a147f793a36c9601bfd350f7229444',
    coterieId: 10560,
  },
  {
    description: 'Tariff rate consulting services',
    coterieAkHash: '1acfa82c45b2c41f6f55eb3ccf71d942',
    coterieId: 10431,
  },
  {
    description: 'Utilities management consulting services',
    coterieAkHash: '3f0a3b0d0e8d58047403c988ce895e78',
    coterieId: 10431,
  },
  {
    description: 'Environmental consulting services',
    coterieAkHash: '3e73849435d9ac428a4c940df6682404',
    coterieId: 10251,
  },
  {
    description: 'Hydrology consulting services',
    coterieAkHash: '09ec78a38524bc1eef4e2374ee8c8a08',
    coterieId: 10247,
  },
  {
    description: 'Safety consulting services',
    coterieAkHash: '1c2a9c16f64c2fbba4c4f2476fa44f8b',
    coterieId: 10247,
  },
  {
    description: 'Livestock breeding consulting services',
    coterieAkHash: '2d14b659def5157c3e59d7289a6316eb',
    coterieId: 10247,
  },
  {
    description: 'Agrology consulting services',
    coterieAkHash: '3100b690b7cf00337268e0dabd2d289d',
    coterieId: 10247,
  },
  {
    description: 'Entomology consulting services',
    coterieAkHash: '48cd070bb7408fe44f58785847262cf0',
    coterieId: 10247,
  },
  {
    description: 'Geochemical consulting services',
    coterieAkHash: '6480b5671b1fdb00ecd282a4d4ff2997',
    coterieId: 10247,
  },
  {
    description: 'Agronomy consulting services',
    coterieAkHash: '6773e41caa6a081bc15aea3a6503d0db',
    coterieId: 10247,
  },
  {
    description: 'Building envelope consulting services',
    coterieAkHash: '7cfc81fb9a02547e0b5b9c2e93774482',
    coterieId: 10247,
  },
  {
    description: 'Chemical consulting services',
    coterieAkHash: '90d057d6d1fef0a3ee09c24259336fc5',
    coterieId: 10247,
  },
  {
    description: 'Agricultural consulting services',
    coterieAkHash: 'b5461dde56172c29d84817268d566f78',
    coterieId: 10247,
  },
  {
    description: 'Economic consulting services',
    coterieAkHash: 'b570b39cb2e4cd229295388debc176bb',
    coterieId: 10247,
  },
  {
    description: 'Roof consulting services',
    coterieAkHash: 'b7a5b3b3f288be547c7ae729e7814e7c',
    coterieId: 10247,
  },
  {
    description: 'Physics consulting services',
    coterieAkHash: 'baa69e70c9d8414b62378ad27ef5caae',
    coterieId: 10247,
  },
  {
    description: 'Waterproof consulting services',
    coterieAkHash: 'c31901bc90db8b340c3b7ef22c53aa97',
    coterieId: 10247,
  },
  {
    description: 'Biological consulting services',
    coterieAkHash: 'cea0f365ca75ac7738aaf21819585601',
    coterieId: 10247,
  },
  {
    description: 'Horticultural consulting services',
    coterieAkHash: 'd18a205dbc3279762f41859a1a37cd43',
    coterieId: 10247,
  },
  {
    description: 'Dairy herd consulting services',
    coterieAkHash: 'dafad4f200deced6421fc0a0a426e654',
    coterieId: 10247,
  },
  {
    description: 'Motion picture consulting services',
    coterieAkHash: 'ea68510d47179279def5bb44ed7d151f',
    coterieId: 10247,
  },
  {
    description: 'Radio consulting services',
    coterieAkHash: 'f25a9d0c00f13ed0d56d89673ca57f00',
    coterieId: 10247,
  },
  {
    description: 'Energy consulting services',
    coterieAkHash: 'faf8e4fd1de02568a0f3498246616ac0',
    coterieId: 10247,
  },
  {
    description: 'Dental research and development laboratories or services',
    coterieAkHash: '0245d3bd905006491fba1d66749b6551',
    coterieId: 10586,
  },
  {
    description: 'Language research and development services',
    coterieAkHash: '0e52e5e9c453469ffd43a99573e4e29d',
    coterieId: 10586,
  },
  {
    description: 'Advertising agencies',
    coterieAkHash: '2c52ecc34506ac2c68dc72962662b27f',
    coterieId: 10017,
  },
  {
    description: 'Public relations consulting services',
    coterieAkHash: '985f0ded2698113679c1bacb42170361',
    coterieId: 10571,
  },
  {
    description: 'Public relations services',
    coterieAkHash: 'cdd25ed76c1fa769dd66576b5ee83f3e',
    coterieId: 10571,
  },
  {
    description: 'Public relations agencies',
    coterieAkHash: 'fade6cb379ee0a758ff2e7a4940e7315',
    coterieId: 10571,
  },
  {
    description: 'Media buying agencies',
    coterieAkHash: '105dd1a40c6ae65c1aad5ba5d0e22b02',
    coterieId: 10017,
  },
  {
    description:
      'Advertising media representatives (i.e., independent of media owners)',
    coterieAkHash: '2194581082c02ce6aba2c766dd7f710c',
    coterieId: 10017,
  },
  {
    description: 'Indoor display advertising services',
    coterieAkHash: '0ba7ebd299dd1ffc82e08cfcba6b7d61',
    coterieId: 10017,
  },
  {
    description:
      'Advertising material preparation services for mailing or other direct distribution',
    coterieAkHash: '6ab09758c79c6fcb9c81857f09a0ffd6',
    coterieId: 10429,
  },
  {
    description: 'Sample direct distribution services',
    coterieAkHash: '09d6f0dbaecda418f48a17a5321e8056',
    coterieId: 10017,
  },
  {
    description: 'Sign lettering and painting services',
    coterieAkHash: '5779acf4e2451590a40e7b6667a4342a',
    coterieId: 10645,
  },
  {
    description: 'Political opinion polling services',
    coterieAkHash: '10ae89814707478b070334f0a4577e75',
    coterieId: 10441,
  },
  {
    description:
      'Video taping services, special events (e.g., birthdays, weddings)',
    coterieAkHash: '0961e78d863c205ffeea19e3d6e72828',
    coterieId: 10521,
  },
  {
    description: 'Commercial photography services',
    coterieAkHash: '36b1e76a83a9c05c8c06520cc7879695',
    coterieId: 10521,
  },
  {
    description: 'Sign language services',
    coterieAkHash: '0099386b667c5d45bc90ff58ac3a01b1',
    coterieId: 10714,
  },
  {
    description: 'Livestock veterinary services',
    coterieAkHash: '0cec5f042064e9d57c6760aa25cbf077',
    coterieId: 10730,
  },
  {
    description: 'Appraisal (except real estate) services',
    coterieAkHash: '3a5a0000dec4ecd04b39001e5face2c0',
    coterieId: 10374,
  },
  {
    description: 'Managing offices of dentists',
    coterieAkHash: '2e6553cc5b43da7c4d46323938b54d62',
    coterieId: 10453,
  },
  {
    description: 'Radio transcription services',
    coterieAkHash: '09a8b6c49f622962d316f0210a93a420',
    coterieId: 10713,
  },
  {
    description: 'Telephone call forwarding services',
    coterieAkHash: '001852a993b8ca3c4ee43b7b514f544f',
    coterieId: 10028,
  },
  {
    description: 'Telephone call centers',
    coterieAkHash: '1960bf9eb64fd501b0b0d5b5950c2740',
    coterieId: 10685,
  },
  {
    description:
      'Mailbox rental services combined with one or more other office support services, private',
    coterieAkHash: '897dbe63037100fc32d1d667e23d4e9a',
    coterieId: 10167,
  },
  {
    description: 'Copy centers (except combined with printing services)',
    coterieAkHash: '33f65a2038bf325eddfd57bcd1c2a35d',
    coterieId: 10167,
  },
  {
    description: 'Closed captioning services, real-time (i.e., simultaneous)',
    coterieAkHash: '145cfc5770a5b13d541db23d38f39788',
    coterieId: 10061,
  },
  {
    description:
      'Fundraising campaign organization services on a contract or fee basis',
    coterieAkHash: '04f13fdf097de57d9e05008a03ae28ab',
    coterieId: 10429,
  },
  {
    description: 'Travel management services',
    coterieAkHash: '23fc963de7bd8c59188aecc29c5d3cb0',
    coterieId: 10718,
  },
  {
    description: 'Fingerprint services',
    coterieAkHash: '1097374c0ba1d46060b751ad160104cc',
    coterieId: 10199,
  },
  {
    description: 'Investigation services (except credit), private',
    coterieAkHash: 'b07906bcb3048297be28f8fc930be968',
    coterieId: 10199,
  },
  {
    description: 'Investigators, private',
    coterieAkHash: 'ec9a855de1263c8aca72a755eaf24a35',
    coterieId: 10199,
  },
  {
    description: 'Lock rekeying services',
    coterieAkHash: '0d3e1f4a965f0842d673ac72edc41680',
    coterieId: 10420,
  },
  {
    description: 'Aircraft janitorial services',
    coterieAkHash: '138f1e4c0544e23172f0ef64195916dd',
    coterieId: 10587,
  },
  {
    description: 'Fertilizing lawns',
    coterieAkHash: '11fc63554902f943de42ed35d7f87e65',
    coterieId: 10405,
  },
  {
    description: 'Spraying lawns',
    coterieAkHash: '231249701b844e507044538bc682718b',
    coterieId: 10405,
  },
  {
    description: 'Maintenance of plants and shrubs in buildings',
    coterieAkHash: '30895b0ef051b2163413397b67a6f454',
    coterieId: 10405,
  },
  {
    description: 'Landscape installation services',
    coterieAkHash: '3623c0f6c8d8c67aa25ff2a121ea17af',
    coterieId: 10405,
  },
  {
    description: 'Lawn spraying services',
    coterieAkHash: '380e8b723a7e875534572c6ed41e151e',
    coterieId: 10405,
  },
  {
    description: 'Turf (except artificial) installation services',
    coterieAkHash: '3a1882158e37c19ac861fcc13bf00209',
    coterieId: 10405,
  },
  {
    description: 'Lawn seeding services',
    coterieAkHash: '3e90a82b4e7ef3dcb7aca25c07ad23db',
    coterieId: 10405,
  },
  {
    description:
      'Hydroseeding services (e.g., decorative, erosion control purposes)',
    coterieAkHash: '43e14f84d564e58e5c37bce510b8032d',
    coterieId: 10405,
  },
  {
    description: 'Line slash (i.e., rights of way) maintenance services',
    coterieAkHash: '58cf8882dcfbbb57013170f8ad0419ce',
    coterieId: 10405,
  },
  {
    description: 'Pruning services, ornamental tree and shrub',
    coterieAkHash: '5b109168ee08b2fea1be4b773a4a23b8',
    coterieId: 10405,
  },
  {
    description: 'Tree pruning services',
    coterieAkHash: '5efbb81cb06e2b0b76e0599d3ce3172e',
    coterieId: 10405,
  },
  {
    description:
      'Seasonal property maintenance services (i.e., snow plowing in winter, landscaping during other seasons)',
    coterieAkHash: '615d3222ae6930201a8ac0bf45cd320c',
    coterieId: 10405,
  },
  {
    description: 'Landscaping services (except planning)',
    coterieAkHash: '6859f5fdb9cb4ed48c37e433ce06bcbb',
    coterieId: 10405,
  },
  {
    description: 'Tree surgery services',
    coterieAkHash: '686df3ea06ad2fffe2570a98f79a5637',
    coterieId: 10406,
  },
  {
    description: 'Interior landscaping services',
    coterieAkHash: '6955bdf4954c773b8f119525be4790de',
    coterieId: 10405,
  },
  {
    description: 'Lawn mulching services',
    coterieAkHash: '6f2017bd03c5cc957f5bf827f655f684',
    coterieId: 10405,
  },
  {
    description: 'Plant maintenance services',
    coterieAkHash: '79a4e5cafd52b6fc6681ffdcfc0f1c24',
    coterieId: 10405,
  },
  {
    description: 'Landscape care and maintenance services',
    coterieAkHash: '7a6bf299e8e74b3efd9f4c675d154db8',
    coterieId: 10405,
  },
  {
    description: 'Tree and brush trimming, overhead utility line',
    coterieAkHash: '852421d1371718841cbed9e52f6d4605',
    coterieId: 10405,
  },
  {
    description: 'Landscape contractors (except construction)',
    coterieAkHash: '94a0c0bdc8411c12c234ac72926e9bc7',
    coterieId: 10405,
  },
  {
    description: 'Mowing services (e.g., highway, lawn, road strip)',
    coterieAkHash: '98061af63ba02e12cb9a1537e8d87d0d',
    coterieId: 10405,
  },
  {
    description: 'Lawn maintenance services',
    coterieAkHash: 'a1c0fdb9fbe2d2786e9382652594c3de',
    coterieId: 10405,
  },
  {
    description: 'Sod laying services',
    coterieAkHash: 'a2e9f6433e9e14fcb7dc0fb71c36a583',
    coterieId: 10405,
  },
  {
    description:
      'Lawn care services (e.g., fertilizing, mowing, seeding, spraying)',
    coterieAkHash: 'a6247a2276867683229b7278f18fbb6c',
    coterieId: 10405,
  },
  {
    description:
      'Shrub services (e.g., bracing, planting, pruning, removal, spraying, surgery, trimming)',
    coterieAkHash: 'aaf841b18e7bc349cb1cb8dabe9cc92b',
    coterieId: 10405,
  },
  {
    description: 'Tropical plant maintenance services',
    coterieAkHash: 'b04a7f1d9cf2aa03522191a4cf12730d',
    coterieId: 10405,
  },
  {
    description: 'Weed control and fertilizing services (except crop)',
    coterieAkHash: 'b1a7846a7072b865eb8218311cefdbd6',
    coterieId: 10405,
  },
  {
    description: 'Cemetery plot care services',
    coterieAkHash: 'b473034cb5540b7e435131bc9f28274e',
    coterieId: 10405,
  },
  {
    description: 'Lawn fertilizing services',
    coterieAkHash: 'baff63976442ac363576a43af16be7ab',
    coterieId: 10405,
  },
  {
    description: 'Seeding lawns',
    coterieAkHash: 'c0d93f8d6ccf67bdf397c6bd855ab25d',
    coterieId: 10405,
  },
  {
    description: 'Lawn mowing services',
    coterieAkHash: 'cf2da137a8d53bd4900c242228e1dcb7',
    coterieId: 10405,
  },
  {
    description: 'Arborist services',
    coterieAkHash: 'd065aa3f6e4c84472de3ebbaf578b292',
    coterieId: 10405,
  },
  {
    description: 'Plant and shrub maintenance in buildings',
    coterieAkHash: 'd6400767f8d49fa416b867d4cfd3e230',
    coterieId: 10405,
  },
  {
    description: 'Garden maintenance services',
    coterieAkHash: 'f43c832f02c507250892308b01b881c0',
    coterieId: 10405,
  },
  {
    description:
      'Snow plowing services combined with landscaping services (i.e., seasonal property maintenance services)',
    coterieAkHash: 'f4be6a4f8b2a0da00f29e0407b0ab72b',
    coterieId: 10405,
  },
  {
    description: 'Ornamental tree and shrub services',
    coterieAkHash: 'f52658f82058fecae97d3daefbd7f8bd',
    coterieId: 10405,
  },
  {
    description: 'Cleaning rugs',
    coterieAkHash: '17c78f1adf7ec54acf3ad2febd40832c',
    coterieId: 10113,
  },
  {
    description: 'Ventilation duct cleaning services',
    coterieAkHash: '05c7d390c0ba71ada2a4a8c5a44dbbcd',
    coterieId: 10587,
  },
  {
    description: 'Steam cleaning building exteriors',
    coterieAkHash: '12f6cb358bc7005f86fdcf2f47c9cdb5',
    coterieId: 10587,
  },
  {
    description:
      'Lighting maintenance services (e.g., bulb and fuse replacement and cleaning)',
    coterieAkHash: '1a9c31e4f917f270449a25151d972610',
    coterieId: 10587,
  },
  {
    description:
      'Cleaning (e.g., power sweeping, washing) driveways and parking lots',
    coterieAkHash: '1ac94bdc31b3ec641bca8a97fbe69a23',
    coterieId: 10587,
  },
  {
    description: 'Duct cleaning services',
    coterieAkHash: '2b1a10da52810e0b35285f1d48a1b23d',
    coterieId: 10587,
  },
  {
    description: 'Swimming pool cleaning and maintenance services',
    coterieAkHash: '2d403a4d4d128f6abf97b3fd2203b2a4',
    coterieId: 10587,
  },
  {
    description:
      'Parking lot cleaning (e.g., power sweeping, washing) services',
    coterieAkHash: '30e5f0d3159872dacd485f70ef7161f0',
    coterieId: 10587,
  },
  {
    description:
      'Cleaning building exteriors (except sandblasting, window cleaning)',
    coterieAkHash: '31904c1203fdbd1196dbe718688f059d',
    coterieId: 10587,
  },
  {
    description: 'Chimney sweep (i.e., cleaning) services',
    coterieAkHash: '56a35a1411e3e3edb750e08a34593eb8',
    coterieId: 10587,
  },
  {
    description: 'Power washing building exteriors',
    coterieAkHash: '6308a2b6b6de2ed8af25815e27ac751d',
    coterieId: 10587,
  },
  {
    description: 'Gutter cleaning services',
    coterieAkHash: '635219fdbbcbe67a3335f37e46db4db8',
    coterieId: 10587,
  },
  {
    description: 'Pressure washing (e.g., buildings, decks, fences)',
    coterieAkHash: '6f14bcfd76de148147226d432bba8238',
    coterieId: 10587,
  },
  {
    description: 'Drain cleaning services',
    coterieAkHash: '7e3a090242bcc5cd328ca64329b00761',
    coterieId: 10587,
  },
  {
    description: 'Driveway cleaning (e.g., power sweeping, washing) services',
    coterieAkHash: 'c34ce800714b56678ce43be8e9ccb37c',
    coterieId: 10587,
  },
  {
    description: 'Cleaning swimming pools',
    coterieAkHash: 'e6a5301fc64ca19d4999d11bf6bc9378',
    coterieId: 10587,
  },
  {
    description:
      'Building exterior cleaning services (except sandblasting, window cleaning)',
    coterieAkHash: 'f73c0be35b01917e500fa3e7047e0ebd',
    coterieId: 10587,
  },
  {
    description: 'Textile folding and packaging services',
    coterieAkHash: '128e0c4ce32f732862b3128518a41c37',
    coterieId: 10426,
  },
  {
    description: 'Bartering services',
    coterieAkHash: '034a9c2aebca5150425b2b6ef109a1ef',
    coterieId: 10057,
  },
  {
    description: 'Inventory computing services',
    coterieAkHash: '0b4331debbcc3fcfb5d74c37afb02a28',
    coterieId: 10057,
  },
  {
    description:
      'Tape slitting (e.g., cutting plastic or leather into widths) for the trade',
    coterieAkHash: '10e2a42856985df2f5f7a8766fcbc377',
    coterieId: 10057,
  },
  {
    description: 'Auctioneers, independent',
    coterieAkHash: '13f8b9bfb94b0effbafa51dc9e68a722',
    coterieId: 10057,
  },
  {
    description: 'Lumber grading services',
    coterieAkHash: '15c1332beabeb382f1fd39b2845e321c',
    coterieId: 10057,
  },
  {
    description: 'Document shredding services',
    coterieAkHash: '25d9860bddab164f9a6e0b978260577f',
    coterieId: 10057,
  },
  {
    description: 'Inventory taking services',
    coterieAkHash: '2eaa550767ba0a33714fe9854d0828fc',
    coterieId: 10057,
  },
  {
    description: 'Textile cutting services',
    coterieAkHash: '33ffbe5a8c7eff0ae405cab008c4a3ca',
    coterieId: 10057,
  },
  {
    description: 'Cloth cutting, bolting, or winding for the trade',
    coterieAkHash: '34b22ea078153a73dcc75348c29d2582',
    coterieId: 10057,
  },
  {
    description: 'Meter reading services, contract',
    coterieAkHash: '3d6607cbaaed978e73b87fc6ae0f8936',
    coterieId: 10057,
  },
  {
    description: 'Museum cataloging services',
    coterieAkHash: '3f14b1b9e98e788e1d97e979d7f264ee',
    coterieId: 10057,
  },
  {
    description: 'Gas meter reading services, contract',
    coterieAkHash: '4e69081e3e02f426ced971e011f28aaf',
    coterieId: 10057,
  },
  {
    description: 'Coupon processing services',
    coterieAkHash: '5792ce4bc27f392fe04391c9691019c2',
    coterieId: 10057,
  },
  {
    description: 'Trading stamp redemption services',
    coterieAkHash: '75b9837ccc5703cb3311c2f6a8019f74',
    coterieId: 10057,
  },
  {
    description: 'Flagging (i.e., traffic control) services',
    coterieAkHash: '798355a6f74f99951a67794e879e688b',
    coterieId: 10057,
  },
  {
    description: 'License issuing services (except government), motor vehicle',
    coterieAkHash: '880a221e22adf07b9805c67d3d5d7346',
    coterieId: 10057,
  },
  {
    description: 'Locating underground utility lines prior to digging',
    coterieAkHash: '8e5aaa36f7b934064a807b98c5534fff',
    coterieId: 10057,
  },
  {
    description: 'Coupon redemption services (i.e., clearinghouse)',
    coterieAkHash: '9072fb4cd9dda1bb01cc498b46a1602a',
    coterieId: 10057,
  },
  {
    description: 'Trading stamp promotion and sale to stores',
    coterieAkHash: '9f1e0bfb96336a22f4ffca36eb2e734e',
    coterieId: 10057,
  },
  {
    description: 'Motor vehicle license issuing services, private franchise',
    coterieAkHash: 'a884755764b9da31417c14c6d9e97a36',
    coterieId: 10057,
  },
  {
    description: 'Water softening and conditioning services',
    coterieAkHash: 'ad844f1e94c3cc61f4d3496f783aae6d',
    coterieId: 10057,
  },
  {
    description: 'Float decorating services',
    coterieAkHash: 'bc81629ae7124e99c90eef8a6c692894',
    coterieId: 10057,
  },
  {
    description: 'Coin pick-up services, parking meter',
    coterieAkHash: 'bf16adaf3695c68eca4c110788f53b74',
    coterieId: 10057,
  },
  {
    description: 'Bottle exchanges',
    coterieAkHash: 'c4f120c876f5998c233f9f7146aa24cb',
    coterieId: 10057,
  },
  {
    description: 'Electrical meter reading services, contract',
    coterieAkHash: 'c7b4e2d9717b75e2447aefed23ca31c2',
    coterieId: 10057,
  },
  {
    description: 'Printing brokers',
    coterieAkHash: '06377ae217388bbc0c1d13d0e226419b',
    coterieId: 10057,
  },
  {
    description: 'Portable toilet pumping (i.e., cleaning) services',
    coterieAkHash: '51178bae821bec39b3c44ff63fcf3258',
    coterieId: 10610,
  },
  {
    description: 'Tank cleaning services, septic',
    coterieAkHash: '86fd3ea895a655685df5f1f6320137a3',
    coterieId: 10610,
  },
  {
    description: 'Septic tank pumping (i.e., cleaning) services',
    coterieAkHash: 'f3320f1424598c41027e9e6dbd2139ec',
    coterieId: 10610,
  },
  {
    description: 'Pumping (i.e., cleaning) cesspools and septic tanks',
    coterieAkHash: 'f689206626acd9e4455113150710b699',
    coterieId: 10610,
  },
  {
    description: 'Aerobic dance and exercise instruction',
    coterieAkHash: '429319a2f4287d54021c84e1e5e6cf2b',
    coterieId: 10517,
  },
  {
    description: 'Swimming instruction',
    coterieAkHash: 'ea43ebb5763806227e2a0cda3f9c581d',
    coterieId: 10517,
  },
  {
    description: 'Tennis instruction, camps, or schools',
    coterieAkHash: '4457d237bf9c8bb102b1a2bf8fc632da',
    coterieId: 10517,
  },
  {
    description: 'Yoga instruction, camps, or schools',
    coterieAkHash: 'dc8a2c208bfed26ce3cc102f929bf557',
    coterieId: 10234,
  },
  {
    description: 'Study abroad programs',
    coterieAkHash: '1579343988f08966b65a12f52f65832f',
    coterieId: 10234,
  },
  {
    description: "Surgeons' (except dental) offices (e.g., centers, clinics)",
    coterieAkHash: '00bad86ef01015eac4d177c614d7de9d',
    coterieId: 10453,
  },
  {
    description:
      "Pediatricians', mental health, offices (e.g., centers, clinics)",
    coterieAkHash: '0b27b4e707a42d1aa2d877a658bb8f29',
    coterieId: 10453,
  },
  {
    description:
      "Oral and maxillofacial surgeons' offices (e.g., centers, clinics)",
    coterieAkHash: '4793fcb8d87bbc220fe92b36124cb08b',
    coterieId: 10453,
  },
  {
    description:
      "DCs' (doctors of chiropractic) offices (e.g., centers, clinics)",
    coterieAkHash: '8c3bc8cf8de8e76f1b2300b8eb1d1b84',
    coterieId: 10122,
  },
  {
    description: 'Doctors of optometry (ODs) offices (e.g., centers, clinics)',
    coterieAkHash: '104f849eb2cc4e3c9c20d0010436191d',
    coterieId: 10453,
  },
  {
    description:
      "Psychoanalysts' (except MDs or DOs) offices (e.g., centers, clinics)",
    coterieAkHash: '0bc87dd22d88cef7f5a609a0320c65c9',
    coterieId: 10458,
  },
  {
    description: "Physiotherapists' offices (e.g., centers, clinics)",
    coterieAkHash: '021f63968c4b33e9abc42022e71c36c4',
    coterieId: 10453,
  },
  {
    description: 'Doctors of podiatry (DPs) offices (e.g., centers, clinics)',
    coterieAkHash: '145551104f492517be37a6d65fd51807',
    coterieId: 10453,
  },
  {
    description: "Registered nurses' (RNs) offices (e.g., centers, clinics)",
    coterieAkHash: '09ef99fccb69d16bb8927d192231a882',
    coterieId: 10453,
  },
  {
    description: 'Childbirth preparation classes',
    coterieAkHash: '445d74d391c7da3747360073e74c911c',
    coterieId: 10453,
  },
  {
    description: 'Counseling services, family planning',
    coterieAkHash: '6afcf1b8f21c775a8b444eb401dc446f',
    coterieId: 10445,
  },
  {
    description:
      'Mental health centers and clinics (except hospitals), outpatient',
    coterieAkHash: '11f5773eb5e3fb0927b9f0520d9bc746',
    coterieId: 10458,
  },
  {
    description:
      'Outpatient mental health centers and clinics (except hospitals)',
    coterieAkHash: '14cd6ec867bfff0f4f2337ab7622b428',
    coterieId: 10458,
  },
  {
    description: 'Renal dialysis centers and clinics',
    coterieAkHash: '4daa4db51819361e936fba566da9f286',
    coterieId: 10453,
  },
  {
    description:
      'Urgent medical care centers and clinics (except hospitals), freestanding',
    coterieAkHash: '2997c63c1d57130d2c523c5369564525',
    coterieId: 10453,
  },
  {
    description: 'Laser surgery centers, freestanding',
    coterieAkHash: '8ee0acde14fc0bcde855cfbe8a23d2a5',
    coterieId: 10453,
  },
  {
    description: 'Freestanding emergency medical centers and clinics',
    coterieAkHash: 'eedc773472ea51a4c60a61370c75fd61',
    coterieId: 10453,
  },
  {
    description: 'Pain therapy centers and clinics, outpatient',
    coterieAkHash: '7b447138e0e09aa6fe7af9d354581428',
    coterieId: 10453,
  },
  {
    description: 'Pathology laboratories, medical',
    coterieAkHash: '0000dc045c872f122d694ef600c394df',
    coterieId: 10586,
  },
  {
    description: 'Radiological laboratory services, medical',
    coterieAkHash: '06c0eb89c53a0c84af83c15258b0f81f',
    coterieId: 10586,
  },
  {
    description: 'Medical care management services',
    coterieAkHash: '00504aebe3b5c791d2860572d4092b81',
    coterieId: 10453,
  },
  {
    description: 'Dance theaters',
    coterieAkHash: '0d4d47bf932a655fb540422f1f4b915b',
    coterieId: 10740,
  },
  {
    description: 'Cameramen, independent (freelance)',
    coterieAkHash: '004ee9655c9becad36cde4166f3ab652',
    coterieId: 10737,
  },
  {
    description: 'Lecturers, independent',
    coterieAkHash: '010aab407f2ef030528748f8b2d091d7',
    coterieId: 10234,
  },
  {
    description: 'Recording technicians, independent',
    coterieAkHash: '03f72ce640921e3226ec2121a50dedfa',
    coterieId: 10059,
  },
  {
    description: 'Film actors, independent',
    coterieAkHash: '05221a0e9c899351bba6cb334a04b42e',
    coterieId: 10740,
  },
  {
    description: 'Journalists, independent (freelance)',
    coterieAkHash: '0d198d9f40b29d5641fc267e44988b51',
    coterieId: 10050,
  },
  {
    description: 'Dancers, independent',
    coterieAkHash: '0d5ab5834dc0842d50ebb5bef41f4549',
    coterieId: 10740,
  },
  {
    description: 'Radio commentators, independent',
    coterieAkHash: '102e0fda13f766a7180a3704531da3a1',
    coterieId: 10740,
  },
  {
    description: 'Ballet dancers, independent',
    coterieAkHash: '1097922c2225b426b936ce7d25fa32ee',
    coterieId: 10740,
  },
  {
    description: 'Television producers, independent',
    coterieAkHash: '10b233fbf215a8e3965df79edf098724',
    coterieId: 10734,
  },
  {
    description: 'Political cartoonists, independent',
    coterieAkHash: '125215256ded0bc63fcf2c2717884b94',
    coterieId: 10027,
  },
  {
    description: 'Cinematographers, independent',
    coterieAkHash: '14405f590697f342c0807454bff57751',
    coterieId: 10270,
  },
  {
    description: 'Comedians, independent',
    coterieAkHash: '15adf7e07b73ee6137ad732f61f3373b',
    coterieId: 10740,
  },
  {
    description: 'Choreographers, independent',
    coterieAkHash: '16bd7f3d8b71294023e943d2969182be',
    coterieId: 10740,
  },
  {
    description: 'Screenplay writers, independent',
    coterieAkHash: '188f06e4facbc30ad38e049f12132357',
    coterieId: 10171,
  },
  {
    description: 'Artists (except commercial, musical), independent',
    coterieAkHash: '18e4c46fac55a45990f59be3085105d7',
    coterieId: 10739,
  },
  {
    description: 'Disc jockeys, independent',
    coterieAkHash: '1b1b93353a3937e218202dc48c7b27da',
    coterieId: 10059,
  },
  {
    description: 'Art restorers, independent',
    coterieAkHash: '1bc8ab8cbde049d2ad31752610025899',
    coterieId: 10308,
  },
  {
    description: 'Special effect technicians, independent',
    coterieAkHash: '1ec38020a18347f7a8adf8a4ce9d1cbe',
    coterieId: 10653,
  },
  {
    description: 'Performing artists, independent',
    coterieAkHash: '24492cf468bf168dab0209755c525de6',
    coterieId: 10740,
  },
  {
    description: 'Scenery designers, independent theatrical',
    coterieAkHash: '2bddb2a70066ee814fd2f753de49ec0f',
    coterieId: 10270,
  },
  {
    description: 'Actors, independent',
    coterieAkHash: '318ac548c2515b7d2af215c857b02e9e',
    coterieId: 10740,
  },
  {
    description: 'Independent technical writers',
    coterieAkHash: '371b53a560ddb1d66644b237c42ee324',
    coterieId: 10684,
  },
  {
    description: 'Motivational speakers, independent',
    coterieAkHash: '384508bb12b225a67df1691edd0da6b0',
    coterieId: 10740,
  },
  {
    description: 'Producers, independent',
    coterieAkHash: '3b2a74edfb5b658a0a307bd924e5b1e7',
    coterieId: 10059,
  },
  {
    description: 'Cartoonists, independent',
    coterieAkHash: '3c86b6f928a40b4fa817206f9a9276e7',
    coterieId: 10027,
  },
  {
    description: 'Directors, independent motion picture',
    coterieAkHash: '404888654b0ae6b1043d2ff442f739d2',
    coterieId: 10270,
  },
  {
    description: 'Storytellers, independent',
    coterieAkHash: '40ec834dd0cb2f7e5b98ee1524b82005',
    coterieId: 10740,
  },
  {
    description: 'Sculptors, independent',
    coterieAkHash: '41184c32ba19e275674f0513045125f5',
    coterieId: 10308,
  },
  {
    description: 'Orchestra conductors, independent',
    coterieAkHash: '41bf2fa7f5505b9271f8c959c71e0a1e',
    coterieId: 10059,
  },
  {
    description: 'Performers (i.e., entertainers), independent',
    coterieAkHash: '4432045e99439e6a8ce7121fda4558ad',
    coterieId: 10740,
  },
  {
    description: 'Music arrangers, independent',
    coterieAkHash: '4645b6ce3081f0091261fe765a06468a',
    coterieId: 10468,
  },
  {
    description: 'Playwrights, independent',
    coterieAkHash: '5094304a452d4df3eb9f73c9a31589de',
    coterieId: 10171,
  },
  {
    description: 'Motion picture producers, independent',
    coterieAkHash: '63a8cb81db2a01f319033cb616fc1c69',
    coterieId: 10270,
  },
  {
    description: 'Artists (i.e., painters), independent',
    coterieAkHash: '6468852093b4d4f30745b2a3f2d414c2',
    coterieId: 10739,
  },
  {
    description: 'Entertainers, independent',
    coterieAkHash: '65c70310107186e0aa034a7cc91e9320',
    coterieId: 10740,
  },
  {
    description: 'Magicians, independent',
    coterieAkHash: '669639e5ec6748c17074302773be0210',
    coterieId: 10740,
  },
  {
    description: 'Jazz dancers, independent',
    coterieAkHash: '6e7c3f1a9659b16a2a762d187a19d174',
    coterieId: 10740,
  },
  {
    description: 'Photojournalists, independent (freelance)',
    coterieAkHash: '707c6eaa731b478e95228aef2a7b8cbb',
    coterieId: 10523,
  },
  {
    description: 'Record producers, independent',
    coterieAkHash: '7403ce346c042bb1f0cfd2fd743c3165',
    coterieId: 10059,
  },
  {
    description: 'Photographers, independent artistic',
    coterieAkHash: '74f3191805a55f64547660f88187f0e0',
    coterieId: 10523,
  },
  {
    description: 'Set designers, independent theatrical',
    coterieAkHash: '808d4abb318bb3c704c184bb1bb6de54',
    coterieId: 10270,
  },
  {
    description: 'Music directors, independent',
    coterieAkHash: '84c3d855107b06d92092419852c010cd',
    coterieId: 10468,
  },
  {
    description: 'Sports announcers, independent',
    coterieAkHash: '84cf51f2c49bc22c81108dffbc6bfcad',
    coterieId: 10740,
  },
  {
    description: 'Ghost writers, independent',
    coterieAkHash: '87ff3f471596a5a574016ba3b6c6842a',
    coterieId: 10171,
  },
  {
    description: 'Folk dancers, independent',
    coterieAkHash: '8ade11b98b6be27bd3db44327ff147dd',
    coterieId: 10740,
  },
  {
    description:
      'Directors (i.e., film, motion picture, music, theatrical), independent',
    coterieAkHash: '8d5908d66b2e3ac2ec2076f15a4c4e15',
    coterieId: 10270,
  },
  {
    description: 'Painters (i.e., artists), independent',
    coterieAkHash: '8e0ab7a7aae73f3db651cd910912115c',
    coterieId: 10308,
  },
  {
    description: 'Newspaper columnists, independent (freelance)',
    coterieAkHash: '8ec029a0d1c6c192beacac59ad4a4d83',
    coterieId: 10050,
  },
  {
    description: 'Directors, independent music',
    coterieAkHash: '91979cab9f28f19e67858a9aaa702b06',
    coterieId: 10059,
  },
  {
    description: 'Speakers, independent',
    coterieAkHash: '960df3f849e4757117d2214cadb35698',
    coterieId: 10740,
  },
  {
    description: 'Costume designers, independent theatrical',
    coterieAkHash: '9caa7f40e009b300fab2d4e95f9b6765',
    coterieId: 10308,
  },
  {
    description: 'Interpretive dancers, independent',
    coterieAkHash: 'a1d2e6e19add33b5481a5af2540876de',
    coterieId: 10740,
  },
  {
    description: 'Celebrity spokespersons, independent',
    coterieAkHash: 'a4b43a5d5e659ee5cd6d0c0c3282702d',
    coterieId: 10740,
  },
  {
    description: 'Standup comedians, independent',
    coterieAkHash: 'a70df974a909f75719def7d9e7c5e161',
    coterieId: 10740,
  },
  {
    description: 'Conductors, independent',
    coterieAkHash: 'a8da2349d2d04749712251bcbb46cfbd',
    coterieId: 10059,
  },
  {
    description:
      'Stage set (e.g., concert, motion picture, television) erecting and dismantling, independent',
    coterieAkHash: 'aca7ff5dd160e85204e1d23c8bf9391c',
    coterieId: 10270,
  },
  {
    description: 'Technical writers, independent',
    coterieAkHash: 'ad0d37bdf41be6e5e2b8998b2d5769a1',
    coterieId: 10684,
  },
  {
    description: 'Script writers, independent',
    coterieAkHash: 'be766fcda9b26e8958b9321a0d7df8ed',
    coterieId: 10171,
  },
  {
    description: 'Authors, independent',
    coterieAkHash: 'd3b067eaacfcab3d3f8e164528ec6a5d',
    coterieId: 10171,
  },
  {
    description: 'Wildlife artists, independent',
    coterieAkHash: 'd4b734636b3faf3ad89659874d845d5e',
    coterieId: 10308,
  },
  {
    description: 'News correspondents, independent (freelance)',
    coterieAkHash: 'd656c63a722bd32f9a35fcfa56948905',
    coterieId: 10740,
  },
  {
    description: 'Models, independent',
    coterieAkHash: 'd6810ce5a9bd227aea40e1dbb1561d95',
    coterieId: 10740,
  },
  {
    description: 'Lighting technicians, theatrical, independent',
    coterieAkHash: 'dc17bf49a27b676c58ced60e2f5287c3',
    coterieId: 10270,
  },
  {
    description: 'Film producers, independent',
    coterieAkHash: 'de34385a1e53564bb4bfa9907beeec17',
    coterieId: 10270,
  },
  {
    description: 'Motion picture directors, independent',
    coterieAkHash: 'df7060ed014c51ed76fa4679888c0775',
    coterieId: 10270,
  },
  {
    description: 'Ethnic dancers, independent',
    coterieAkHash: 'dfc06cdb040b62e8d02d22554e374a81',
    coterieId: 10740,
  },
  {
    description: 'Painting restorers, independent',
    coterieAkHash: 'e0b0b08a1bd98012882f8cdc67fe5321',
    coterieId: 10308,
  },
  {
    description: 'Poets, independent',
    coterieAkHash: 'e0b8ca7561c90769d200d38f8c09ab7c',
    coterieId: 10171,
  },
  {
    description: 'Writers of advertising copy, independent',
    coterieAkHash: 'e0c46bd17fddfa69deec1c6fa7fc1846',
    coterieId: 10554,
  },
  {
    description: 'Writers, independent (freelance)',
    coterieAkHash: 'e27c3afd4902be2f977c7a410f59f286',
    coterieId: 10171,
  },
  {
    description: 'Song writers, independent',
    coterieAkHash: 'e6380e05127b03e405fbb99eaead7a25',
    coterieId: 10473,
  },
  {
    description: 'Sketch artists, independent',
    coterieAkHash: 'ea0222b08e0f5ed04ac35c6579f5c578',
    coterieId: 10308,
  },
  {
    description: 'Conservators (i.e., art, artifact restorers), independent',
    coterieAkHash: 'ecf4bac30e66e2673cce0847eacb7ce2',
    coterieId: 10308,
  },
  {
    description: 'Reporters, independent (freelance)',
    coterieAkHash: 'f65f310f3972469bdf9a679137941909',
    coterieId: 10740,
  },
  {
    description: 'Composers, independent',
    coterieAkHash: 'fc73bf40d6b7f5a16bf21a19f113faad',
    coterieId: 10468,
  },
  {
    description: 'Exercise centers',
    coterieAkHash: '1a6984eb75eba2743518709b8f6696ef',
    coterieId: 10517,
  },
  {
    description: 'Body building studios, physical fitness',
    coterieAkHash: '2264c049293cbed05826b5656586e500',
    coterieId: 10517,
  },
  {
    description: 'Dance centers, aerobic',
    coterieAkHash: '2d960a67e7d648a30c9ab574d2f82941',
    coterieId: 10517,
  },
  {
    description: 'Squash club facilities',
    coterieAkHash: '2fb63c390894537b339cd6b3fcd403c4',
    coterieId: 10517,
  },
  {
    description: 'Gymnasiums',
    coterieAkHash: '38a9fdec6be4647a4efcf86e427b21d9',
    coterieId: 10517,
  },
  {
    description: 'Fitness spas without accommodations',
    coterieAkHash: '3bf3ec1e5867f75967877112b82599a3',
    coterieId: 10517,
  },
  {
    description: 'Racquetball club facilities',
    coterieAkHash: '40d4021b8e313e54b75f9597b3592965',
    coterieId: 10517,
  },
  {
    description: 'Health club facilities, physical fitness',
    coterieAkHash: '456b985493d2baa2131c0d63a774fdec',
    coterieId: 10517,
  },
  {
    description: 'Strength development centers',
    coterieAkHash: '4b7366d40faaf0ceae14d7afeac93f43',
    coterieId: 10517,
  },
  {
    description: 'Aerobic dance and exercise centers',
    coterieAkHash: '5bd61a2d3fe714db62f960702ec15f97',
    coterieId: 10517,
  },
  {
    description: 'Weight training centers',
    coterieAkHash: '62763ac3d0a8ea0e270b30a79742aeda',
    coterieId: 10517,
  },
  {
    description: 'Fitness centers',
    coterieAkHash: '645d9b04ba7f28c10bb46a802ceef7d7',
    coterieId: 10517,
  },
  {
    description: 'Fitness salons',
    coterieAkHash: '6563e6b87838f6b129a4849dbeb9f17f',
    coterieId: 10517,
  },
  {
    description: 'Athletic club facilities, physical fitness',
    coterieAkHash: '6ef535c489dd399b31494e5dee4fb530',
    coterieId: 10517,
  },
  {
    description: 'Health spas without accommodations, physical fitness',
    coterieAkHash: '7275a356ad1492f461310e8d7ffcb961',
    coterieId: 10517,
  },
  {
    description: 'Gyms, physical fitness',
    coterieAkHash: '78225200b528cc946e6b3f5f02b04b68',
    coterieId: 10517,
  },
  {
    description: 'Health studios, physical fitness',
    coterieAkHash: '78543f0d63d748deede63e2c6c46bc79',
    coterieId: 10517,
  },
  {
    description: 'Physical fitness studios',
    coterieAkHash: '96811230e7feec657c12dc32b6910a60',
    coterieId: 10517,
  },
  {
    description: 'Physical fitness facilities',
    coterieAkHash: '9a1013ef23b1853e77cccd2e5bace528',
    coterieId: 10517,
  },
  {
    description: 'Recreational sports club facilities',
    coterieAkHash: 'aca2030623821d0aacf19589bd6fa6c4',
    coterieId: 10517,
  },
  {
    description: 'Handball club facilities',
    coterieAkHash: 'd82d3a23861f8bfa5959f79fa28fe63b',
    coterieId: 10517,
  },
  {
    description: 'Sports club facilities, physical fitness',
    coterieAkHash: 'dfc4111dbd8f0478b1f7e8ce0c3cabed',
    coterieId: 10517,
  },
  {
    description: 'Physical fitness centers',
    coterieAkHash: 'ed5521eacfb113eda6c5ee587f82ce5f',
    coterieId: 10517,
  },
  {
    description: 'Tennis courts',
    coterieAkHash: 'fbfac313065dc87046d33ea0b1d29e80',
    coterieId: 10517,
  },
  {
    description: 'Bed and breakfast inns',
    coterieAkHash: 'a22e85a05972e14209739e63d69b3733',
    coterieId: 10080,
  },
  {
    description: 'Food service contractors, industrial',
    coterieAkHash: '071e2c478a40e03025d1bde1fe77d370',
    coterieId: 10676,
  },
  {
    description:
      'Cafeteria food services contractors (e.g., government office cafeterias, hospital cafeterias, school cafeterias)',
    coterieAkHash: '3ec2a489983360030d7e5a3aaabcd2a6',
    coterieId: 10103,
  },
  {
    description: 'Food service contractors, institutional',
    coterieAkHash: '7f86ce989326bcd40156c13d0c1efdc1',
    coterieId: 10676,
  },
  {
    description: 'Food service contractors, cafeteria',
    coterieAkHash: '803d86c52779f244819b8c62f406a8b8',
    coterieId: 10103,
  },
  {
    description:
      'Industrial caterers (i.e., providing food services on a contractual arrangement (except single-event basis))',
    coterieAkHash: 'a3006673912960c8a75aada3c3815b25',
    coterieId: 10676,
  },
  {
    description:
      'Food service contractors, concession operators (e.g., convention facilities, entertainment facilities, sporting facilities)',
    coterieAkHash: '84dd1b67c065dcba1bca8b9fb01fdba8',
    coterieId: 10154,
  },
  {
    description:
      'Food concession contractors (e.g., convention facilities, entertainment facilities, sporting facilities)',
    coterieAkHash: '9302df9a947749dbbaaff1085a9632c8',
    coterieId: 10676,
  },
  {
    description: 'Caterers',
    coterieAkHash: '42d0a019dcf18e2ba02478febf36930c',
    coterieId: 10676,
  },
  {
    description: 'Catering services, social',
    coterieAkHash: '51a4892eac985fb8ca73730cab57b9b6',
    coterieId: 10676,
  },
  {
    description: 'Food carts, mobile',
    coterieAkHash: '30cfd5d06ab97ed3cf7a389589cf8c48',
    coterieId: 10676,
  },
  {
    description: 'Diners, full service',
    coterieAkHash: 'a65b22c2cc2a5c130ee7db8a8bc579db',
    coterieId: 10207,
  },
  {
    description: 'Fast casual restaurants',
    coterieAkHash: 'aa0787fc6cd001c764c3597faaf061d9',
    coterieId: 10318,
  },
  {
    description: 'Cafeterias',
    coterieAkHash: '44226a49ad5443ea3160a90d908d5254',
    coterieId: 10103,
  },
  {
    description: 'Buffet eating places',
    coterieAkHash: '86d2ab3ec168314467f92e50637d8112',
    coterieId: 10103,
  },
  {
    description: 'Cookie shops, on premise baking and carryout service',
    coterieAkHash: 'a9ac1c1f7f1e048590ef612b0127cbbc',
    coterieId: 10067,
  },
  {
    description: 'Radio repair, automotive, without retailing new',
    coterieAkHash: '72344ba185dbd66671c6d4e041a5e8bc',
    coterieId: 10041,
  },
  {
    description:
      'Computer peripheral equipment repair and maintenance, without retailing new computer peripheral equipment',
    coterieAkHash: '3537d7f920ca7e67b7abb7e991e395f4',
    coterieId: 10041,
  },
  {
    description: 'Public address system repair and maintenance services',
    coterieAkHash: '174643f1f42878d79397cd0c3bf1ec9a',
    coterieId: 10041,
  },
  {
    description: 'Radar and sonar equipment repair and maintenance services',
    coterieAkHash: '0563791653956a7276ee4187d7f1ecff',
    coterieId: 10041,
  },
  {
    description:
      'Commercial refrigeration equipment repair and maintenance services',
    coterieAkHash: '7c23ff1bdfa9089cd3e4159b58ecc07f',
    coterieId: 10584,
  },
  {
    description: 'Commercial gaming machine repair and maintenance services',
    coterieAkHash: '00dd01940e270471c45b6894b0f09772',
    coterieId: 10038,
  },
  {
    description: 'Welding repair services (e.g., automotive, general)',
    coterieAkHash: '1595bea9756a653a9e6684ff4ac165ba',
    coterieId: 10038,
  },
  {
    description:
      'Electric motor repair and maintenance services, commercial or industrial',
    coterieAkHash: '26e5a39a79ca0c4a93c59127903c21fc',
    coterieId: 10038,
  },
  {
    description: 'Food machinery repair and maintenance services',
    coterieAkHash: '2eb23843f07b9f39015202b969f187ea',
    coterieId: 10038,
  },
  {
    description:
      'Armature rewinding services (except on an assembly line or factory basis)',
    coterieAkHash: '35aa280291f3cca72780ca8ae5393ddf',
    coterieId: 10038,
  },
  {
    description: 'Machine tools repair and maintenance services',
    coterieAkHash: '35c594fe71aa280c646726d57df5a851',
    coterieId: 10038,
  },
  {
    description:
      'Service machinery and equipment repair and maintenance services',
    coterieAkHash: '5104f5793155884628de851c1f2ccb73',
    coterieId: 10038,
  },
  {
    description: 'Truck refrigeration repair and maintenance services',
    coterieAkHash: '517cb52f8bb2c5eb7f951bc2fac421e6',
    coterieId: 10584,
  },
  {
    description: 'Material handling equipment repair and maintenance services',
    coterieAkHash: '5d881e9d4735bf8a80a2026952132107',
    coterieId: 10038,
  },
  {
    description:
      'Fire extinguisher repair and maintenance, without installation',
    coterieAkHash: '6354484d0dc29f831c23c6ef073672cb',
    coterieId: 10038,
  },
  {
    description: 'Freezer, commercial, repair and maintenance services',
    coterieAkHash: '6d98226d065bf5908c0c51160120e49c',
    coterieId: 10038,
  },
  {
    description: 'Paper making machinery repair and maintenance services',
    coterieAkHash: '73d90c611684be1af67f3f2af3d2d5df',
    coterieId: 10038,
  },
  {
    description: 'Reconditioning shipping barrels and drums',
    coterieAkHash: '79b325a9c1dc5a57614064b4193e918c',
    coterieId: 10038,
  },
  {
    description: 'Coil rewinding (except on an assembly line or factory basis)',
    coterieAkHash: '81778050c47255cc1fe4113b9196fbe5',
    coterieId: 10038,
  },
  {
    description:
      'Foundry machinery and equipment repair and maintenance services',
    coterieAkHash: 'ab0a565216cfe4e7c4f89c2a67696bf4',
    coterieId: 10038,
  },
  {
    description: 'Cargo container repair and maintenance services',
    coterieAkHash: 'b418cef654c892e1de6615b0caa5ec38',
    coterieId: 10038,
  },
  {
    description:
      'Waterless fire suppression system repair and maintenance, without installation',
    coterieAkHash: 'c102c669062503c1f420d6969d37f2ed',
    coterieId: 10038,
  },
  {
    description: 'Engine repair (except automotive, small engine)',
    coterieAkHash: 'c173ab3f171d32711eda6c6ee91d191b',
    coterieId: 10038,
  },
  {
    description:
      'Refrigeration equipment repair and maintenance services, industrial and commercial-type',
    coterieAkHash: 'c4f1ee7d7e5cbc712eb384aded2cf6de',
    coterieId: 10584,
  },
  {
    description:
      'Rewinding armatures (except on an assembly line or factory basis)',
    coterieAkHash: 'd2e8a5ee8a90ce55cedbe441e0afd848',
    coterieId: 10038,
  },
  {
    description: 'Printing trade machinery repair and maintenance services',
    coterieAkHash: 'd59289b91226ff17fb83f46cbb8e83b7',
    coterieId: 10038,
  },
  {
    description:
      'Blade sharpening, commercial and industrial machinery and equipment',
    coterieAkHash: 'dc38878a65b4fcc699ae2d9b0a8f1118',
    coterieId: 10038,
  },
  {
    description: 'Textile machinery repair and maintenance services',
    coterieAkHash: 'e8d32a8a6fe967a50612732be90ae21b',
    coterieId: 10038,
  },
  {
    description:
      'Commercial and industrial machinery repair and maintenance services',
    coterieAkHash: 'f396bb266f5904e0db517caf1f13ec37',
    coterieId: 10038,
  },
  {
    description:
      'Electrical generating and transmission equipment repair and maintenance services',
    coterieAkHash: 'f42dc335b758f4c940b8e78b14dce084',
    coterieId: 10038,
  },
  {
    description: 'Small engine repair and maintenance shops',
    coterieAkHash: '07e38a77d063b5dfb4fa66476ec859a2',
    coterieId: 10041,
  },
  {
    description:
      'Water heater repair and maintenance services without retailing new water heaters',
    coterieAkHash: '0e3325e2e6c2e72f4ca1d584e9ad6c4f',
    coterieId: 10041,
  },
  {
    description: 'Restoration and repair of antique furniture',
    coterieAkHash: '1493e93fa84d4bba06369ae4f8206eb0',
    coterieId: 10041,
  },
  {
    description: 'Antique furniture repair and restoration shops',
    coterieAkHash: '1961c402f0be29a642513afdef32a640',
    coterieId: 10041,
  },
  {
    description: 'Upholstery (except motor vehicle) repair services',
    coterieAkHash: '54f0eb82375ab66452c4c459cea74924',
    coterieId: 10041,
  },
  {
    description: 'Furniture refinishing shops',
    coterieAkHash: '57f52a19d2b7e99111409b61273d9922',
    coterieId: 10041,
  },
  {
    description: 'Reupholstery shops, furniture',
    coterieAkHash: 'a65b2a31a3327d54c7a89950bc6c3f62',
    coterieId: 10041,
  },
  {
    description: 'Furniture repair shops',
    coterieAkHash: 'bdae05c72f486daef6c8dbc4e2fb3e92',
    coterieId: 10041,
  },
  {
    description: 'Furniture reupholstering shops',
    coterieAkHash: 'c8f38c8eeeb31c9716d63f5c630bd2cf',
    coterieId: 10041,
  },
  {
    description:
      'Leather goods repair shops without retailing new leather goods',
    coterieAkHash: '0ff6b174fa585540cf87c2378b7447aa',
    coterieId: 10639,
  },
  {
    description: 'Clock repair shops without retailing new clocks',
    coterieAkHash: '02ed067da49bfc0c7847305c2a47534b',
    coterieId: 10041,
  },
  {
    description: 'Fur garment repair shops without retailing new fur garments',
    coterieAkHash: '0312998cf56ff0bd23704edcedb776a6',
    coterieId: 10675,
  },
  {
    description:
      'Venetian blind repair and maintenance shops without retailing new venetian blinds',
    coterieAkHash: '078e5599e98dc71b6c454c683040ff90',
    coterieId: 10041,
  },
  {
    description:
      'Garment alteration and/or repair shops without retailing new garments',
    coterieAkHash: '12747c7d984dd41f58ccc3d529f5d1fb',
    coterieId: 10675,
  },
  {
    description: 'Tuning and repair of musical instruments',
    coterieAkHash: '17f624d3ae1ed6b084710de26930a775',
    coterieId: 10041,
  },
  {
    description: 'Tailor shops, alterations only',
    coterieAkHash: '1a66431f6c4e0e2370ad194943f6d655',
    coterieId: 10675,
  },
  {
    description:
      'Musical instrument repair shops without retailing new musical instruments',
    coterieAkHash: '3158e0f95f5669fade337cf22574c806',
    coterieId: 10041,
  },
  {
    description: 'Watch repair shops without retailing new watches',
    coterieAkHash: '689e2683637d2e8324e99f6eb8536fa4',
    coterieId: 10397,
  },
  {
    description: 'Key duplicating shops',
    coterieAkHash: '7825ede5965c6fc5714b08a7efcc2f59',
    coterieId: 10319,
  },
  {
    description:
      'Exercise and athletic equipment repair and maintenance services without retailing new exercise and athletic equipment',
    coterieAkHash: 'a58c89838bf27d1ed65faa44d76dcfba',
    coterieId: 10041,
  },
  {
    description: 'Window shade repair and maintenance shops',
    coterieAkHash: 'aa50ddd7f2eba3cac62543d6f178da56',
    coterieId: 10041,
  },
  {
    description: 'Clothing repair shops, alterations only',
    coterieAkHash: 'b05f4a00ba18ef5d70b876586c388884',
    coterieId: 10675,
  },
  {
    description:
      'Sporting equipment repair and maintenance without retailing new sports equipment',
    coterieAkHash: 'b4579e096d51be3ccd66bc33e09bb72c',
    coterieId: 10041,
  },
  {
    description: 'Jewelry repair shops without retailing new jewelry',
    coterieAkHash: 'b5be75ded34fac729d10bfad165ce649',
    coterieId: 10397,
  },
  {
    description: 'Cutlery (e.g., knives, scissors) sharpening, household-type',
    coterieAkHash: 'c411dadb0e920e9f2e64d49712d2a630',
    coterieId: 10041,
  },
  {
    description: 'China repair services',
    coterieAkHash: 'cacf9778cf2e0a716cbc171ab101dc1a',
    coterieId: 10041,
  },
  {
    description:
      'Bicycle repair and maintenance shops without retailing new bicycles',
    coterieAkHash: '0b5239554393d6746bebae81b43da9f1',
    coterieId: 10074,
  },
  {
    description: "Hair stylist shops, men's",
    coterieAkHash: '6f4d837d2b6582da7db8185850281ea5',
    coterieId: 10069,
  },
  {
    description: 'Esthetician (i.e., skin care) services',
    coterieAkHash: '1c42c5afe9ed0e7d75a7dd041fe7dcb0',
    coterieId: 10255,
  },
  {
    description: 'Manicurist services',
    coterieAkHash: '076d5f33b644a4b35e08e288171a65e6',
    coterieId: 10475,
  },
  {
    description: 'Weight loss centers, non-medical',
    coterieAkHash: '0e9fe0c30d6e81f7c0bb444fc8cd1478',
    coterieId: 10204,
  },
  {
    description: 'Baths, steam or turkish',
    coterieAkHash: '071f0f08acc7d0109b5fb1ad28a696e8',
    coterieId: 10071,
  },
  {
    description:
      'Hair removal (i.e., depilatory, electrolysis, laser, waxing) services',
    coterieAkHash: '327585a9ab481aa828c53688cbdcd5c6',
    coterieId: 10255,
  },
  {
    description: 'Depilatory (i.e., hair removal) salons',
    coterieAkHash: '33df129bddfdaea823a2bd58a34414a1',
    coterieId: 10255,
  },
  {
    description: 'Makeup salons, permanent',
    coterieAkHash: '4d99b91187585bfe4c990a7f58488528',
    coterieId: 10070,
  },
  {
    description: 'Ear piercing services',
    coterieAkHash: '5c81b107cf812c1f03017ff7fadf5764',
    coterieId: 10255,
  },
  {
    description: 'Steam baths',
    coterieAkHash: '6cabd273d446a0d7996bf7d06c77c340',
    coterieId: 10071,
  },
  {
    description: 'Turkish baths',
    coterieAkHash: '78f0d0e4b8ea869d29f5e694fad35cd5',
    coterieId: 10071,
  },
  {
    description: 'Electrolysis (i.e., hair removal) salons',
    coterieAkHash: '80ffa20bdd780fa3caa2f0f78ef399c8',
    coterieId: 10255,
  },
  {
    description: 'Massage parlors',
    coterieAkHash: '85aa3758b62d1ab1e67860ac34e3bd94',
    coterieId: 10448,
  },
  {
    description: 'Sun tanning salons',
    coterieAkHash: '8a5ed7e6e9a05f2c44f72269470a556d',
    coterieId: 10071,
  },
  {
    description: 'Day spas',
    coterieAkHash: '99560a62d3abb66193cef13b75464043',
    coterieId: 10071,
  },
  {
    description: 'Turkish bathhouses',
    coterieAkHash: 'b26a5242958a56b7db892a7c5494c3a7',
    coterieId: 10071,
  },
  {
    description: 'Hair replacement services (except by offices of physicians)',
    coterieAkHash: 'b9129e7d95fa80af345c20f514f2852f',
    coterieId: 10255,
  },
  {
    description: 'Scalp treating services',
    coterieAkHash: 'df166bb73dfc17eea69ce7ece64cb286',
    coterieId: 10255,
  },
  {
    description: 'Saunas',
    coterieAkHash: 'e471f279733a300a8c51bffed8301cba',
    coterieId: 10071,
  },
  {
    description: 'Tanning salons',
    coterieAkHash: 'ebda2e8b37d7731a1d8b30669fe8a0a5',
    coterieId: 10071,
  },
  {
    description: 'Color consulting services (i.e., personal care services)',
    coterieAkHash: 'f075cb9220189e459502f044b5a48ed8',
    coterieId: 10071,
  },
  {
    description: 'Hair weaving services',
    coterieAkHash: 'f2e3b711507ec6d8def24e8f0c11fec3',
    coterieId: 10071,
  },
  {
    description: 'Undertaker services',
    coterieAkHash: '1e58113ef0bb81aab290a019b89b36b9',
    coterieId: 10293,
  },
  {
    description: 'Laundries, coin-operated or similar self-service',
    coterieAkHash: '009513a07c304700496b561ebcf806cb',
    coterieId: 10408,
  },
  {
    description: 'Power laundries, family',
    coterieAkHash: '021ef576228c710bdcabc7585b6478d9',
    coterieId: 10409,
  },
  {
    description: 'Bed linen supply services',
    coterieAkHash: '05e4b99ebec078b029afa6c08a674dfa',
    coterieId: 10392,
  },
  {
    description: 'Flame resistant clothing supply services',
    coterieAkHash: '0028606761e5519c25b0e34b26b4d33b',
    coterieId: 10392,
  },
  {
    description: 'Obedience training services, pet',
    coterieAkHash: '1b3b922fc036c0d00cb9367931c0343b',
    coterieId: 10518,
  },
  {
    description: 'Sitting services, pet',
    coterieAkHash: '20c4b8d3342ea84304773d8f10978bd8',
    coterieId: 10515,
  },
  {
    description: 'Pet training services',
    coterieAkHash: '53c824c87c3fb123edd1a0799e8d8254',
    coterieId: 10518,
  },
  {
    description: 'Boarding services, pet',
    coterieAkHash: '7095dc9e8586d3e926b4f8203b051e74',
    coterieId: 10518,
  },
  {
    description: 'Pet sitting services',
    coterieAkHash: '7ac0ae0270657fecf5ed809b28ef15a2',
    coterieId: 10515,
  },
  {
    description: 'Pet grooming services',
    coterieAkHash: '84f0c9c2999638e1533a06a41415f2be',
    coterieId: 10518,
  },
  {
    description: 'Grooming services, animal',
    coterieAkHash: 'ae884448a16bcd0f098e019da79577fc',
    coterieId: 10518,
  },
  {
    description: 'Pet boarding services',
    coterieAkHash: 'e98d6821fd037793e40e8ad7530cc66c',
    coterieId: 10518,
  },
  {
    description: 'Animal grooming services',
    coterieAkHash: 'f739727af1ef8f46954c5cc995a0ec30',
    coterieId: 10518,
  },
  {
    description: 'Bootblack parlors',
    coterieAkHash: '05c9f8f7f05d4a7f02ed69c6f5765d0a',
    coterieId: 10639,
  },
  {
    description: 'Check room services',
    coterieAkHash: '796f5d34695ffd1f6fb6965c8632e3c0',
    coterieId: 10515,
  },
  {
    description: 'Life coach services',
    coterieAkHash: '80434777455a71712fc817794697b999',
    coterieId: 10108,
  },
  {
    description: 'Personal chef services',
    coterieAkHash: '903fd3b45129819f0bdc6a6d41436711',
    coterieId: 10515,
  },
  {
    description: 'Personal fitness training services',
    coterieAkHash: '39c33b2f8fe71a4716f92728aba92278',
    coterieId: 10517,
  },
  {
    description: 'Personal organizer services',
    coterieAkHash: '6e305157d24b4150568fd0e157f8e9fb',
    coterieId: 10515,
  },
  {
    description: 'Personal shopping services',
    coterieAkHash: 'd320a595a901eb1a8b0468b697d1b0f9',
    coterieId: 10515,
  },
  {
    description: 'Shoeshine parlors',
    coterieAkHash: '678a26bc495ddd3583f699fd42b4c124',
    coterieId: 10639,
  },
  {
    description: 'Shoeshine services',
    coterieAkHash: '1c53c5cd08ff9c1a5df406d166184ec4',
    coterieId: 10639,
  },
  {
    description: 'Shopping services, personal',
    coterieAkHash: '60cc8e3e0128b6647fa5cb7751af8d56',
    coterieId: 10515,
  },
  {
    description: 'Fan clubs',
    coterieAkHash: '0685400bc8ca13c843820c9a7aff784b',
    coterieId: 10149,
  },
  {
    description: 'Ethnic associations',
    coterieAkHash: '238e4de7de04ab85f2946212ba3f28a5',
    coterieId: 10149,
  },
  {
    description: 'Granges',
    coterieAkHash: '242bf55a326a93e22ebeeddcc77d75e0',
    coterieId: 10149,
  },
  {
    description: 'NOAA (National Oceanic and Atmospheric Administration)',
    coterieAkHash: '259152f7af03fa333d77ec7d26ff5ad8',
    coterieId: 10149,
  },
  {
    description: 'Membership associations, civic or social',
    coterieAkHash: '304785445b0a8b47ca2f20a3123eae10',
    coterieId: 10149,
  },
  {
    description: "Women's auxiliaries",
    coterieAkHash: '31dae57ef29b4593b64966c0fc8f499a',
    coterieId: 10149,
  },
  {
    description: 'Golden age clubs',
    coterieAkHash: '362c323a7cc0dfce305e1e5cfb630ffe',
    coterieId: 10149,
  },
  {
    description: "Senior citizens' associations, social",
    coterieAkHash: '39a02233ec2987d4b389b7aed80cecc3',
    coterieId: 10149,
  },
  {
    description: 'Alumni associations',
    coterieAkHash: '3d809d1b5e0f3dba43ab1f17080da69a',
    coterieId: 10149,
  },
  {
    description: "Parent-teachers' associations",
    coterieAkHash: '4b6517b6e45bac2e824852e311cb99bf',
    coterieId: 10149,
  },
  {
    description: 'Alumni clubs',
    coterieAkHash: '52723af58b54c0f1b5425c9f8523cffa',
    coterieId: 10149,
  },
  {
    description: 'Farm granges',
    coterieAkHash: '5459bb3ed92a7747e08dc6ae82b6e15b',
    coterieId: 10149,
  },
  {
    description: 'Booster clubs',
    coterieAkHash: '59787de97eee3445af031ede8f1764c7',
    coterieId: 10149,
  },
  {
    description: 'Historical clubs',
    coterieAkHash: '687283e776b253a3e2377067c5abab0c',
    coterieId: 10149,
  },
  {
    description: 'Public speaking improvement clubs',
    coterieAkHash: '786c638e442dc6a33d73c93c14147ba8',
    coterieId: 10149,
  },
  {
    description: 'Writing clubs',
    coterieAkHash: '80359dbad2f3c5c6e35ee883a8cafb5a',
    coterieId: 10149,
  },
  {
    description: 'Computer enthusiasts clubs',
    coterieAkHash: '845304e04de4a9a09e2cfb6dc032275f',
    coterieId: 10149,
  },
  {
    description: 'University clubs',
    coterieAkHash: '905e251777c574f569ba6605e3e5233d',
    coterieId: 10149,
  },
  {
    description: 'Retirement associations, social',
    coterieAkHash: '91072944a3df36015b9c83cbf7cb8383',
    coterieId: 10149,
  },
  {
    description: 'Scouting organizations',
    coterieAkHash: '9faca6ca0bad9c90355bcca774451084',
    coterieId: 10149,
  },
  {
    description: "Women's clubs",
    coterieAkHash: 'a3e8ed696577605039873b431e45a27c',
    coterieId: 10149,
  },
  {
    description: 'Social organizations, civic and fraternal',
    coterieAkHash: 'a8426f11b2c0c6ea71049602ed7db7c5',
    coterieId: 10149,
  },
  {
    description: "Students' associations",
    coterieAkHash: 'b12d7a21b2572286e6ac8c03bc318b49',
    coterieId: 10149,
  },
  {
    description: 'Singing societies',
    coterieAkHash: 'b32feef9dee0dd20f2cdb2ddd89705e6',
    coterieId: 10149,
  },
  {
    description: 'Book discussion clubs',
    coterieAkHash: 'c685b443cc6c6876b1448f22e6874868',
    coterieId: 10149,
  },
  {
    description: 'Civic associations',
    coterieAkHash: 'c6916385cab713032e78ece4210c9618',
    coterieId: 10149,
  },
  {
    description: "Veterans' membership organizations",
    coterieAkHash: 'cd7bc231db59f0d8176c1ba91be72a74',
    coterieId: 10149,
  },
  {
    description: "Speakers' clubs",
    coterieAkHash: 'd3e6fb1f5cf277ef31cb04b7d5ed7890',
    coterieId: 10149,
  },
  {
    description: 'Garden clubs',
    coterieAkHash: 'd6f8f952a33bc414a813739ddb8fba4d',
    coterieId: 10149,
  },
  {
    description: 'Student clubs',
    coterieAkHash: 'd8c62c13d4c486a2d9d2361639d2bc33',
    coterieId: 10149,
  },
  {
    description: 'Poetry clubs',
    coterieAkHash: 'db81f4cbcf90a65723b39332f46094b0',
    coterieId: 10149,
  },
  {
    description: 'Fraternal lodges',
    coterieAkHash: 'e67a5bb5346a2c891f2e01c44ae955ae',
    coterieId: 10149,
  },
  {
    description: 'Social clubs',
    coterieAkHash: 'f8ed69d68236addf94ad291ea4cd9030',
    coterieId: 10149,
  },
  {
    description: 'Better business bureaus',
    coterieAkHash: '01060e19e7c9a0f20e044ee7e31deed4',
    coterieId: 10149,
  },
  {
    description: "Distributors' associations",
    coterieAkHash: '021f4b01466a7887fe66a25f1afafe93',
    coterieId: 10149,
  },
  {
    description: "Bankers' associations",
    coterieAkHash: '0b2353fa0ea6a82f7495f3b6e35b7875',
    coterieId: 10149,
  },
  {
    description: 'Industrial associations',
    coterieAkHash: '138c1bcb3143d81bcf3226248823fc2c',
    coterieId: 10149,
  },
  {
    description: 'Business associations',
    coterieAkHash: '1a36be76440dbf07b4ce7dfeb55a9178',
    coterieId: 10149,
  },
  {
    description: "Contractors' associations",
    coterieAkHash: '215e1fd48bf4991db2c5ddf7b5d01e39',
    coterieId: 10149,
  },
  {
    description: "Shipping companies' associations",
    coterieAkHash: '224499f26178e03e9c9aab916928ce60',
    coterieId: 10149,
  },
  {
    description: 'Trade associations',
    coterieAkHash: '311e305d5dd099c39fe48b53e374db94',
    coterieId: 10149,
  },
  {
    description: 'Junior chambers of commerce',
    coterieAkHash: '3378b3876b3cf8c458dbcd235083835c',
    coterieId: 10149,
  },
  {
    description: 'Construction associations',
    coterieAkHash: '374f788a3d958eb016a17330becb6745',
    coterieId: 10149,
  },
  {
    description: 'Mining associations',
    coterieAkHash: '3c386a3a7f3edb08fac0d288e20bf246',
    coterieId: 10149,
  },
  {
    description: "Manufacturers' associations",
    coterieAkHash: '3e50f1d703efa1f7801b683582408b1b',
    coterieId: 10149,
  },
  {
    description: 'Warehousing associations',
    coterieAkHash: '480ad4234d1c748a7dd5ac80731074b5',
    coterieId: 10149,
  },
  {
    description: "Farmers' unions",
    coterieAkHash: '5245b7f2a70f6d1a8330531bb28532f9',
    coterieId: 10149,
  },
  {
    description: "Farmers' associations",
    coterieAkHash: '53a23536d563fa0f1f526e66495aed3b',
    coterieId: 10149,
  },
  {
    description: 'Chambers of commerce',
    coterieAkHash: '5c1548adf01c601780f8aade56820f42',
    coterieId: 10149,
  },
  {
    description: "Insurers' associations",
    coterieAkHash: '74cf635ded4e85d1f9999a4d25cbb5ef',
    coterieId: 10149,
  },
  {
    description: "Merchants' associations",
    coterieAkHash: '852181ceb2725e8284100311ca7f1ed9',
    coterieId: 10149,
  },
  {
    description: 'Public utility associations',
    coterieAkHash: 'aff43bb430a7d8c64b3c22eed02cb869',
    coterieId: 10149,
  },
  {
    description: 'Boards of trade',
    coterieAkHash: 'b0877e16c02ba1874c5976fa9dc15511',
    coterieId: 10149,
  },
  {
    description: 'Real estate boards',
    coterieAkHash: 'b598e4ebc172d09aad74b3584be770e8',
    coterieId: 10149,
  },
  {
    description: "Growers' associations",
    coterieAkHash: 'bf4ea2b59e9a068af798d9d4daad4c1f',
    coterieId: 10149,
  },
  {
    description: "Producers' associations",
    coterieAkHash: 'c1d6c5f364dea821b810a1d5a2eef3c5',
    coterieId: 10149,
  },
  {
    description: 'Service industries associations',
    coterieAkHash: 'd039eff66489484f693b396c8de7d58b',
    coterieId: 10149,
  },
  {
    description: "Animal breeders' associations",
    coterieAkHash: 'e46ac3f659a7b41ade7a5e9794cba2d7',
    coterieId: 10149,
  },
  {
    description: "Wholesalers' associations",
    coterieAkHash: 'eb0302a49c449cf4fb5151b878633756',
    coterieId: 10149,
  },
  {
    description: 'Restaurant associations',
    coterieAkHash: 'ee217cd66e457024d0c311649f44a1fe',
    coterieId: 10149,
  },
  {
    description:
      'Agricultural organizations (except youth farming organizations, farm granges)',
    coterieAkHash: 'f796bd7a6aa48cb33d24daf3e9f76759',
    coterieId: 10149,
  },
  {
    description: "Retailers' associations",
    coterieAkHash: 'f81e6209e5f98a02e5be9374dcd99eb5',
    coterieId: 10149,
  },
  {
    description: "Architects' associations",
    coterieAkHash: '02db470716433e877159d7de89cd4897',
    coterieId: 10149,
  },
];
export const sortedCoterieHashData = [...coterieHashData].sort((a, b) =>
  a.description.localeCompare(b.description)
);
