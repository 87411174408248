
import { all, fork, put, takeLeading } from 'redux-saga/effects';
import * as carrierDetailsActions from 'constants/action-types';
import { errorHandler } from '../actions/error';
import axios from 'util/api';
import { getFileExtension } from 'util/extra';

function* downloadFile({ payload, targetFileName }) {
    try {
        const details = yield axios.post('/api/v1/vendor/aws-config', payload);

        if (!details.data)
            return;

        const { returnData } = details.data.data;
        yield axios.get(returnData.signedRequest, { responseType: 'blob' })
            .then(resp => resp.data)
            .then(blob => {
                const url = window.URL.createObjectURL(blob);

                const returnedDecodedUrl = decodeURIComponent(returnData.url);
                const originalFileName = returnedDecodedUrl.substr(
                    returnedDecodedUrl.lastIndexOf('/') + 1
                );

                const extension = getFileExtension(originalFileName);
                const fileName = targetFileName ? `${targetFileName}${extension}` : originalFileName;

                // for ie and edge
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                    window.navigator.msSaveOrOpenBlob(blob, fileName);
                    return;
                }

                const a = document.createElement('a');
                a.style.display = 'none';
                a.href = url;
                a.download = fileName;
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
            })
            .catch(() => {
                // error here
            });
    } catch (error) {
        yield put(errorHandler(error));
    }
}

export function* downloadFileWatcher() {
    yield takeLeading(
        carrierDetailsActions.DOWNLOAD_APPOINTMENT_FILE,
        downloadFile
    );
}

// ------ ROOT SAGA -----------------
export default function* rootSaga() {
    yield all([
        fork(downloadFileWatcher),
    ]);
}
