import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import * as FullStory from '@fullstory/browser';
import { DEVELOPMENT } from 'constants/constant';
import config, { env } from 'config';

FullStory.init({
  orgId: config.FULL_STORY_ORG_ID,
  devMode: env === DEVELOPMENT,
});

let rootEl = document.getElementById('app-root');
if (!rootEl) {
  rootEl = document.getElementById('app-root-change-password');
}
// Create a reusable render method that we can call more than once
let render = () => {
  // Dynamically import our main App component, and render it
  const MainApp = require('./main-app').default;
  ReactDOM.render(<MainApp />, rootEl);
};

if (module.hot) {
  module.hot.accept('./main-app', () => {
    const MainApp = require('./main-app').default;
    render(<MainApp />, rootEl);
  });
}

render();
