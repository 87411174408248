import React from 'react';
import clsx from 'clsx';

function DancingDots({ className, fullpageWrapper = false }) {
  return (
    <div
      className={clsx({
        'dancing-dots--full-page': fullpageWrapper,
        [className]: !!className,
      })}
    >
      <div className="dancing-dots" aria-label="loading indicator">
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
  );
}

export default DancingDots;
