import * as types from '../../constants/action-types';

const initState = () => ({
  loader: false,
  appointmentSettings: {},
  carrier: {},
  commissions: [],
  allowedToFeaturedNew: false,
  message: null,
});

export default (state = initState(), action) => {
  switch (action.type) {
    case types.SET_CARRIER_ALREADY_FEATURED_RESPONSE: {
      return {
        ...state,
        allowedToFeaturedNew: action.payload.allowedToFeaturedNew,
        message: action.payload.message,
      };
    }

    case types.SET_CARRIER_APPOINTMENT_SETTING_LOADER: {
      return {
        ...state,
        loader: action.payload,
      };
    }
    default:
      return state;
  }
};
