import * as dashboardActionType from '../../constants/action-types';

// -------------- Policy Graph Details---------------------

// get the policy and premium bond details from server.
export const fetchPolicyPremiumDetails = (producer_id) => ({
  type: dashboardActionType.FETCH_POLICY_PREMIUM_DETAILS,
  producer_id,
});

// update the store for policy and premium bond details.
export const storePolicyPremiumDetails = (list) => ({
  type: dashboardActionType.STORE_POLICY_PREMIUM_DETAILS,
  payload: list,
});

// graph loader
export const setPolicyPremiumDetailsLoader = (flag) => ({
  type: dashboardActionType.POLICY_PREMIUM_DETAILS_LOADER,
  payload: flag,
});

// get upcoming renewal details from server.
export const fetchUpcomingRenewals = (payload) => ({
  type: dashboardActionType.FETCH_UPCOMING_RENEWALS,
  payload,
});

// update the store for upcoming renewal details.
export const storeUpcomingRenewals = (list) => ({
  type: dashboardActionType.STORE_UPCOMING_RENEWALS,
  payload: list,
});

// upcoming renewal table loader
export const setUpcomingRenewalsLoader = (flag) => ({
  type: dashboardActionType.UPCOMING_RENEWALS_LOADER,
  payload: flag,
});

// Producer renewal notifications
export const renewalNotificationFun = (flag) => ({
  type: dashboardActionType.RENEWAL_NOTIFICATIONS_STATUS,
  payload: flag,
});

// Producer renewal notifications Flag set False
export const renewalNotificationFlagFun = (flag) => ({
  type: dashboardActionType.RENEWAL_NOTIFICATIONS_FLAG_STATUS_CHANGE,
  payload: flag,
});

export const fetchCarrierPortalsDetails = () => ({
  type: dashboardActionType.FETCH_CARRIER_PORTALS_DETAILS,
});

export const setCarrierPortalLoader = (flag) => ({
  type: dashboardActionType.SET_CARRIER_PORTAL_LOADER,
  payload: flag,
});

export const setCarrierPortalsDetails = (list) => ({
  type: dashboardActionType.SET_CARRIER_PORTALS_DETAILS,
  payload: list,
});

export const setCarrierUrlLoader = (flag) => ({
  type: dashboardActionType.SET_CARRIER_URL_LOADER,
  payload: flag,
});

export const getCarrierLink = (data) => ({
  type: dashboardActionType.GET_CARRIER_LINK,
  payload: data,
});

export const setCarrierPortalErrorDialog = (payload) => ({
  type: dashboardActionType.SET_CARRIER_PORTAL_ERROR_DIALOG,
  payload,
});

// ---------- Quotes -------------
export const fetchQuotesList = (data) => ({
  type: dashboardActionType.FETCH_QUOTES_LIST,
  payload: data,
});

export const setQuotesListLoader = (data) => ({
  type: dashboardActionType.SET_QUOTES_LIST_LOADER,
  payload: data,
});

export const setQuotesList = (data) => ({
  type: dashboardActionType.SET_QUOTES_LIST,
  payload: data,
});

export const updateAgentNameInSignUpProcess = (payload) => ({
  type: dashboardActionType.UPDATE_AGENT_NAME_IN_SIGNUP_PROCESS,
  payload,
});

export const fetchProducerQuoteOptions = (payload) => ({
  type: dashboardActionType.FETCH_PRODUCER_QUOTE_OPTIONS,
  payload,
});

export const setProducerQuotesOptionsLoader = (data) => ({
  type: dashboardActionType.SET_PRODUCER_QUOTES_OPTIONS_LOADER,
  payload: data,
});

export const setProducerQuoteOptionsList = (data) => ({
  type: dashboardActionType.SET_PRODUCER_QUOTES_OPTIONS_LIST,
  payload: data,
});

export const setCarriersCredentialList = (data) => ({
  type: dashboardActionType.SET_CARRIERS_CREDENTIAL_LIST,
  payload: data,
});

export const fetchCarrierCredential = (data) => ({
  type: dashboardActionType.FETCH_CARRIER_CREDENTIAL,
  payload: data,
});

export const carrierCredentialLoader = (data) => ({
  type: dashboardActionType.CARRIER_CREDENTIAL_LOADER,
  payload: data,
});

export const setCarrierProducerAppointmentId = (data) => ({
  type: dashboardActionType.SET_CARRIER_PRODUCER_APPOINTMENT_ID,
  payload: data,
});

export const setAccessQuotingPageLoader = (data) => ({
  type: dashboardActionType.SET_ACCESS_QUOTING_PAGE_LOADER,
  payload: data,
});

export const accessQuotingPage = (data) => ({
  type: dashboardActionType.ACCESS_QUOTING_PAGE,
  payload: data,
});

export const setLaunchUrl = (data) => ({
  type: dashboardActionType.SET_LAUNCH_URL,
  payload: data,
});

// ---------- Quotes Notification -------------
export const updateQuoteNotificationIndicator = (payload) => ({
  type: dashboardActionType.UPDATE_QUOTE_NOTIFICATION_INDICATOR,
  payload,
});

export const setQuoteNotificationIndicatorLoader = (payload) => ({
  type: dashboardActionType.UPDATE_QUOTE_NOTIFICATION_INDICATOR_LOADER,
  payload,
});

export const setCommercialLineLaunchUrl = (data) => ({
  type: dashboardActionType.SET_COMMERCIAL_LINE_LAUNCH_URL,
  payload: data,
});

export const setIsSelectingProduct = (payload) => ({
  type: dashboardActionType.SET_IS_SELECTING_PRODUCT,
  payload,
});

export const setSelectedProduct = (payload) => ({
  type: dashboardActionType.SET_SELECTED_PRODUCT,
  payload,
});

export const setProducts = (payload) => ({
  type: dashboardActionType.SET_PRODUCTS,
  payload,
});

export const setConsentTextFlag = (payload) => ({
  type: dashboardActionType.SET_CONSENT_TEXT_DATA,
  payload,
});

export const markAppointedCarriersViewed = (payload) => ({
  type: dashboardActionType.MARK_APPOINTED_CARRIERS_AS_VIEWED,
  payload,
});

export const setCarrierUrl = (payload) => ({
  type: dashboardActionType.SET_CARRIER_URL,
  payload,
});

export const setCarrierType = (payload) => ({
  type: dashboardActionType.SET_CARRIER_TYPE,
  payload,
});

export const setCurrentCarrier = (payload) => ({
  type: dashboardActionType.SET_CURRECT_CARRIER_URL,
  payload,
});

export const updateWholsalerCarrierData = (payload) => ({
  type: dashboardActionType.UPDATE_WHOLESALER_CARRIER_DATA,
  payload,
});

export const setCarrierFlowViewed = () => ({
  type: dashboardActionType.SET_CARRIER_FLOW_VIEWED,
});

export const setShowFastStartBanner = (payload) => ({
  type: dashboardActionType.SHOW_FAST_START_BANNER,
  payload,
});

export const viewFastStartModal = (payload) => ({
  type: dashboardActionType.SHOW_FAST_START_MODAL,
  payload,
});

export const setCarrierStoreModal = (payload) => ({
  type: dashboardActionType.SET_CARRIER_STORE_MODAL,
  payload,
});

export const setW9infoModal = (payload) => ({
  type: dashboardActionType.SET_W9IINFO_MODAL,
  payload,
});

export const setRestrictionModal = (payload) => ({
  type: dashboardActionType.SET_RESTRICTION_MODAL,
  payload,
});

export const setConsentModal = (payload) => ({
  type: dashboardActionType.SET_CONSENT_MODAL,
  payload,
});

export const setBankInfoModal = (payload) => ({
  type: dashboardActionType.SET_BANK_INFO_MODAL,
  payload,
});

export const setFastStartModal = (payload) => ({
  type: dashboardActionType.SET_FAST_START_MODAL,
  payload,
});

export const setFastStartModalViewed = (payload) => ({
  type: dashboardActionType.SET_FAST_START_MODAL_VIEWED,
  payload,
});

export const setFastStartPolicyListLoader = (data) => ({
  type: dashboardActionType.SET_FAST_START_POLICY_LIST_LOADER,
  payload: data,
});

export const setFastStartPolicyList = (data) => ({
  type: dashboardActionType.SET_FAST_START_POLICY_LIST,
  payload: data,
});

export const fetchFastStartPolicies = () => ({
  type: dashboardActionType.FETCH_FAST_START_POLICIES,
});

export const toggleCarrierDropdown = () => ({
  type: dashboardActionType.TOGGLE_CARRIER_DROPDOWN,
});

export const addFastStartPolicy = (payload) => ({
  type: dashboardActionType.ADD_FAST_START_POLICIES,
  payload,
});

export const setAddFastStartPolicyLoader = (data) => ({
  type: dashboardActionType.ADD_FAST_START_POLICIES_LOADER,
  payload: data,
});

export const setAddedFastStartDetails = (data) => ({
  type: dashboardActionType.ADDED_FAST_START_DETAILS,
  payload: data,
});

export const setIsFastStartAdded = (data) => ({
  type: dashboardActionType.SET_IS_FAST_START_ADDED_FLAG,
  payload: data,
});

export const setIsFastStartPolicyAddedd = (data) => ({
  type: dashboardActionType.SET_IS_FAST_START_POLICY_ADDED,
  payload: data,
});
