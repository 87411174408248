import { all, fork, put, select, takeEvery } from 'redux-saga/effects';
import { errorHandler } from '../actions/error';
import {
  fastStartDetailLoader,
  storeFastStartDetail,
} from '../actions/fast-start';
import axios from 'util/api';
import * as fastStart from 'constants/action-types';
import { getAuthDetails } from 'util/extra';
import { PORTAL } from 'constants/heap-events';
import { BANNER_TYPE, POLICY_WRITTEN } from 'constants/constant';

// Get fastStart details
export function* fetchFastStartDetails() {
  try {
    yield put(fastStartDetailLoader(true));
    const store = yield select();
    const { auth, impersonate } = store;
    const { producer, isImpersonate } = impersonate;
    const { daysRemaining } = auth;
    const { producer_id } = getAuthDetails({
      auth,
      producer,
      isImpersonate,
    });
    const responseData = yield axios.get(
      `/api/v1/producer/fast-start-details/${producer_id}`
    );
    if (!!responseData?.data) {
      const { fastStartDetails } = responseData.data;
      yield put(storeFastStartDetail(fastStartDetails));
      if (daysRemaining <= 10 && daysRemaining >= 1) {
        if (fastStartDetails.policyCount >= 1) {
          window.heap.track(PORTAL.DASHBOARD_FAST_START_VIEW, {
            [BANNER_TYPE]: 'urgent',
            [POLICY_WRITTEN]: 'yes',
          });
        } else {
          window.heap.track(PORTAL.DASHBOARD_FAST_START_VIEW, {
            [BANNER_TYPE]: 'urgent',
            [POLICY_WRITTEN]: 'no',
          });
        }
      } else if (daysRemaining > 10) {
        if (fastStartDetails.policyCount >= 1) {
          window.heap.track(PORTAL.DASHBOARD_FAST_START_VIEW, {
            [BANNER_TYPE]: 'normal',
            [POLICY_WRITTEN]: 'yes',
          });
        } else {
          window.heap.track(PORTAL.DASHBOARD_FAST_START_VIEW, {
            [BANNER_TYPE]: 'normal',
            [POLICY_WRITTEN]: 'no',
          });
        }
      } else if (daysRemaining >= -30 && daysRemaining < 1) {
        if (fastStartDetails.policyCount >= 1) {
          window.heap.track(PORTAL.DASHBOARD_FAST_START_VIEW, {
            [BANNER_TYPE]: 'expired',
            [POLICY_WRITTEN]: 'yes',
          });
        } else {
          window.heap.track(PORTAL.DASHBOARD_FAST_START_VIEW, {
            [BANNER_TYPE]: 'expired',
            [POLICY_WRITTEN]: 'no',
          });
        }
      }
    }
  } catch (error) {
    yield put(errorHandler(error));
  }
  yield put(fastStartDetailLoader(false));
}

export function* fetchfetchFastStartDetails() {
  yield takeEvery(fastStart.FETCH_FAST_START_DETAIL, fetchFastStartDetails);
}

export default function* rootSaga() {
  yield all([fork(fetchfetchFastStartDetails)]);
}
