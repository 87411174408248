// 🚨 Until those configs are migrated into a separate package, or dual app setup is removed
// 🚨 part of auth0-related code is duplicated between old and new FE repositories.
// 🚨 Any changes to this file need to be reflected in the new FE.

import { Auth0Client } from '@auth0/auth0-spa-js';
import config from 'config';
import {
  getIsPasswordlessFlow,
  getIsPasswordlessRememberMe,
} from './auth0.utils';

const sharedAuthParams = {
  domain: config.AUTH0_DOMAIN,
  authorizationParams: {
    audience: config.AUTH0_AUDIENCE,
    redirect_uri: window.location.origin + '/signin',
  },
  useRefreshTokens: true,
  cacheLocation: 'localstorage',
};

const auth0ClientRememberedUser = new Auth0Client({
  ...sharedAuthParams,
  clientId: config.AUTH0_CLIENT_ID_REMEMBER_ME,
});

const auth0ClientTemporaryUser = new Auth0Client({
  ...sharedAuthParams,
  clientId: config.AUTH0_CLIENT_ID,
});

export const getAuth0SpaClient = () => {
  const isPasswordless = getIsPasswordlessFlow();

  if (isPasswordless) {
    const isRememberMe = getIsPasswordlessRememberMe();
    const client = isRememberMe
      ? auth0ClientRememberedUser
      : auth0ClientTemporaryUser;

    return client;
  }

  // Use temporary client for password SPA flow
  return auth0ClientTemporaryUser;
};

/**
 * Returns the parameters for the Auth0 UL (Universal Login) template.
 *
 * @param {object} props
 * @param {string} [props.producerCode]
 * @param {boolean} [props.isReauthenticateBannerShown] - Use to force the primary producer flag. Default value is false.
 * @param {boolean} [props.isVerifyEmailBannerShown] - Use to force the verify email banner. Default value is false.
 *
 * @returns {{
 *  ['ext-app-origin']: string;
 *  ['ext-heap-id']: string;
 *  ['ext-fs-org-id']: string;
 *  ['ext-producer-code']: string;
 *  ['ext-is-logout-banner-shown']: boolean;
 *  ['ext-is-verify-email-banner-shown']: boolean;
 * }} - The UL template parameters.
 */
export const getULTemplateParams = ({
  producerCode,
  isReauthenticateBannerShown,
  isVerifyEmailBannerShown,
} = {}) => {
  return {
    ['ext-app-origin']: window.location.origin,
    ['ext-heap-id']: config['HEAP_ID'],
    ['ext-fs-org-id']: config['FULL_STORY_ORG_ID'],
    ['ext-producer-code']: producerCode,
    ['ext-is-logout-banner-shown']: isReauthenticateBannerShown || false,
    ['ext-is-verify-email-banner-shown']: isVerifyEmailBannerShown || false,
  };
};
