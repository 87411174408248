import { all, fork, put, takeLatest } from 'redux-saga/effects';
import { displayErrorMessage } from 'util/user-feedback';
import axios from 'util/api';
import * as carrierListActions from 'constants/action-types';
import {
  setCarrierAlreadyFeaturedResponse,
  setCarrierAppointmentSettingsLoader,
} from '../actions/carrier-appointment-settings';
import { errorHandler } from '../actions/error';

function* fetchCarrierAlreadyFeatured({ payload }) {
  try {
    let response;
    yield put(setCarrierAppointmentSettingsLoader(true));
    if (!!payload) {
      response = yield axios.get(
        `/api/v1/carrier/check-featured-new-eligibility/${payload}`
      );
    }
    let messageResponse = {
      allowedToFeaturedNew: false,
      message: null,
    };
    if (response.data) {
      messageResponse.allowedToFeaturedNew = response.data.allowedToFeaturedNew;
      if (response.data.allowedToFeaturedNew) {
        yield put(setCarrierAlreadyFeaturedResponse(messageResponse));
      } else {
        messageResponse.message =
          `Carrier ` +
          response.data.carrier.display_name +
          ` is already featured`;

        yield displayErrorMessage(messageResponse.message);
        yield put(setCarrierAlreadyFeaturedResponse(messageResponse));
      }
    }
  } catch (error) {
    yield put(errorHandler(error));
  }
  yield put(setCarrierAppointmentSettingsLoader(false));
}

export function* fetchCarrierAlreadyFeaturedWatcher() {
  yield takeLatest(
    carrierListActions.FETCH_CARRIER_ALREADY_FEATURED,
    fetchCarrierAlreadyFeatured
  );
}

// ------ ROOT SAGA -----------------
export default function* rootSaga() {
  yield all([
    fork(fetchCarrierAlreadyFeaturedWatcher),
  ]);
}
