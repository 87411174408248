import * as types from '../../constants/action-types';

const initState = () => ({
  updatingPassword: false,
  passwordToken: null,
  updatePasswordEmailSent: {
    success: false,
    responseStatus: null,
  },
  updatePasswordCompleted: false,
  isTokenValid: false,
  producerEmail: null,
  retryable: true,
  loader: false,
});
export default (state = initState(), action) => {
  switch (action.type) {
    case types.UPDATE_PASSWORD_LOADER: {
      return {
        ...state,
        updatingPassword: action.payload,
      };
    }

    case types.SET_UPDATE_PASSWORD_TOKEN: {
      return {
        ...state,
        passwordToken: action.payload,
      };
    }

    case types.SET_IS_TOKEN_VALID: {
      return {
        ...state,
        isTokenValid: action.payload.isTokenValid,
        producerEmail: action.payload.producerEmail,
        retryable: action.payload.retryable,
      };
    }

    case types.UPDATE_TOKEN_LOADER: {
      return {
        ...state,
        loader: action.payload,
      };
    }

    case types.UPDATE_PASSWORD_EMAIL_SENT: {
      return {
        ...state,
        updatePasswordEmailSent: action.payload,
      };
    }

    case types.UPDATE_PASSWORD_COMPLETED: {
      return {
        ...state,
        updatePasswordCompleted: action.payload,
      };
    }

    default:
      return state;
  }
};
