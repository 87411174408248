import { all, fork, put, select, takeEvery } from 'redux-saga/effects';
import axios from 'util/api';
import { push } from 'connected-react-router';
import { isEmpty } from 'lodash';
import * as impersonateAction from 'constants/action-types';
import {
  setImpersonateData,
  setImpersonateLoader,
} from 'redux/actions/impersonate';
import { errorHandler } from '../actions/error';
import {
  CARRIER_APPOINTMENT_REQ_COUNT,
  CARRIER_STORE_AGENT_APPLICATION_RESTRICTION_ENABLED,
  IMPERSONATE_BFF_HEADER,
  IN_COMPLETE,
  PRODUCER_KEY,
} from 'constants/constant';
import { getDefaultPath, getModulePermission } from 'util/extra';
import {
  carrierStoreThankYouModalClose,
  setApplicationModal,
  setAuthInfo,
  setCarrierFloodRater,
  setCarrierStoreDetails,
  setCarrierTend,
  updateCarrierAppointmentRequestCount,
  userSignInSuccess,
} from 'redux/actions/auth';
import { viewFastStartModal } from '../actions/dashboard';
import { decryptData, isHex } from '../../util/crypto';
import { storeQ2IncentiveInitialDetail } from 'redux/actions/q2-incentive';
import {
  setQ3DaysRemaining,
  setQ4DaysRemaining,
} from 'redux/actions/incentives';
import { fetchProducerFlags, fetchProducerSettings } from 'redux/actions';
export function* setImpersonateMode({ payload: { impersonateID } }) {
  try {
    yield put(setImpersonateLoader(true));
    const response = yield axios.get(
      `api/v1/producer/impersonate/${impersonateID}`
    );
    if (response?.status === 200 && response?.data?.success) {
      const { permissions, producer } = response.data;
      // TODO : Optimize code
      yield put(
        setCarrierStoreDetails({
          carrierAppointmentRequestCount:
            producer?.carrierAppointmentRequestCount || 0,
          carrierStoreAgentApplicationRestrictionEnabled:
            producer?.carrierStoreAgentApplicationRestrictionEnabled || false,
        })
      );
      yield put(
        setApplicationModal(producer?.shouldShowCarrierStoreFlowModal || false)
      );

      const adminAuthToken = yield select((state) => state.auth.authToken);

      yield put(
        userSignInSuccess({
          appointments: !!producer?.appointments ? producer.appointments : [],
          writesUnderAgencyLicense:
            producer?.licenseAndEnO?.writesUnderAgencyLicense || false,
          daysRemaining: !!producer?.fastStart?.daysRemaining
            ? producer?.fastStart?.daysRemaining
            : '0',
          producerLeaderboardData: !!producer?.fastStart
            ?.producerLeaderboardData
            ? producer?.fastStart?.producerLeaderboardData
            : null,
          isInitialModalViewed:
            producer?.fastStart?.isInitialModalViewed || false,
          isSecondaryModalViewed:
            producer?.fastStart?.isSecondaryModalViewed || false,
          isTertiaryModalViewed:
            producer?.fastStart?.isTertiaryModalViewed || false,
          shouldShowFastStart:
            producer?.fastStart?.shouldShowFastStart || false,
          policyCount: producer?.fastStart?.policyCount,
          carrierCount: producer?.fastStart?.carrierCount,
          totalEarnings: producer?.fastStart?.totalEarnings,
          carrierURL: producer?.fastStart?.carrierURL,
          authToken: adminAuthToken,
          earningSettings: producer?.fastStart?.earningSettings,
        })
      );

      yield put(
        setQ3DaysRemaining({
          q3DaysRemaining:
            producer?.q3Incentive !== null
              ? producer?.q3Incentive?.daysRemaining
              : null,
        })
      );
      yield put(
        setQ4DaysRemaining({
          q4DaysRemaining:
            producer?.q4Incentive !== null
              ? producer?.q4Incentive?.daysRemaining
              : null,
        })
      );
      yield put(
        storeQ2IncentiveInitialDetail({
          q2IncentiveDaysRemaining:
            producer?.q2Incentive !== null
              ? producer?.q2Incentive?.daysRemaining
              : null,
          isQ2IncentiveInitialModalViewed:
            producer?.q2Incentive?.isInitialModalViewed || false,
          isQ2IncentiveSecondaryModalViewed:
            producer?.q2Incentive?.isSecondaryModalViewed || false,
          isQ2IncentiveTertiaryModalViewed:
            producer?.q2Incentive?.isTertiaryModalViewed || false,
          isQ2IncentiveQuaternaryModalViewed:
            producer?.q2Incentive?.isQuaternaryModalViewed || false,
          showQ2IncentiveModal: true,
        })
      );
      producer.authUser = producer.first_name;
      producer.loginName = producer.first_name;
      producer.userRole = producer.role_category;
      producer.userStatus = producer.status ? producer.status : '';
      producer.userLastName = producer.last_name;
      producer.complianceInfo = !isEmpty(producer.complianceInfo)
        ? producer.complianceInfo
        : {};

      producer.updateDefaultPassword = !!producer?.must_update_password
        ? true
        : false;
      producer.mustUpdatePassword = !!producer?.must_update_password
        ? true
        : false;
      producer.signupFlow = !!producer?.signupFlow
        ? producer.signupFlow
        : PRODUCER_KEY;
      producer.producertaxStatus = !!producer?.tax?.taxStatus
        ? isHex(producer.tax.taxStatus)
          ? decryptData(producer.tax.taxStatus)
          : producer.tax.taxStatus
        : '';
      producer.agencyName = !!producer?.agency_name ? producer.agency_name : '';
      producer.fkParentProducerId = !!producer?.FK_parent_producer_id
        ? producer.FK_parent_producer_id
        : null;
      producer.hasAcceptedAgreement = producer.has_accepted_agreement;
      producer.appointments = !!producer?.appointments
        ? producer.appointments
        : [];
      producer.isWholesaler = !!producer?.is_principle_wholesaler
        ? true
        : false;
      let impersonatedData = {
        producer,
        permissions,
        isImpersonateMode: true,
      };
      producer.hasQuotingEngineAccess = !!producer?.hasQuotingEngineAccess
        ? true
        : false;
      let agentStatus = {
        status: !!producer?.status ? producer.status : '',
        principleStatus: !!producer?.principle_status
          ? producer.principle_status
          : '',
      };
      yield put(
        setAuthInfo({
          enableMenuItem:
            producer?.carrierAppointmentRequestCount >= 3 ? true : false,
        })
      );
      localStorage.setItem(
        'q2IncentiveDaysRemaining',
        producer?.q2Incentive !== null
          ? producer?.q2Incentive?.daysRemaining
          : null
      );
      localStorage.setItem(
        'daysRemaining',
        !!producer?.fastStart?.daysRemaining
          ? producer?.fastStart?.daysRemaining
          : '0'
      );
      localStorage.setItem(
        'producerLeaderboardData',
        !!producer?.fastStart?.producerLeaderboardData
          ? producer?.fastStart?.producerLeaderboardData
          : null
      );
      localStorage.setItem(
        'isInitialModalViewed',
        producer?.fastStart?.isInitialModalViewed || false
      );
      localStorage.setItem(
        'isSecondaryModalViewed',
        producer?.fastStart?.isSecondaryModalViewed || false
      );
      localStorage.setItem(
        'isTertiaryModalViewed',
        producer?.fastStart?.isTertiaryModalViewed || false
      );
      localStorage.setItem(
        'isQ2IncentiveInitialModalViewed',
        producer?.q2Incentive?.isQ2IncentiveInitialModalViewed || false
      );
      localStorage.setItem(
        'isQ2IncentiveSecondaryModalViewed',
        producer?.q2Incentive?.isQ2IncentiveSecondaryModalViewed || false
      );
      localStorage.setItem(
        'isQ2IncentiveTertiaryModalViewed',
        producer?.q2Incentive?.isQ2IncentiveTertiaryModalViewed || false
      );
      localStorage.setItem(
        'carrierAppointmentRequestCount',
        producer?.carrierAppointmentRequestCount || 0
      );
      localStorage.setItem(
        'enableMenuItem',
        producer?.carrierAppointmentRequestCount >= 3 ? true : false
      );
      localStorage.setItem(
        'carrierStoreAgentApplicationRestrictionEnabled',
        producer?.carrierStoreAgentApplicationRestrictionEnabled || false
      );

      localStorage.setItem(
        'isWholesaler',
        !!producer?.isWholesaler ? true : false
      );
      localStorage.setItem(
        'isCarrierStoreEnabled',
        !!producer.agencyProfile.isCarrierStoreEnabled ? true : false
      );
      localStorage.setItem('agentStatus', JSON.stringify(agentStatus));
      localStorage.setItem('impersonateData', JSON.stringify(impersonatedData));
      localStorage.setItem('impersonatedProducerId', impersonateID);
      localStorage.setItem('impersonateSignupFlow', producer?.signupFlow);
      localStorage.setItem(
        'writesUnderAgencyLicense',
        producer?.licenseAndEnO?.writesUnderAgencyLicense || false
      );
      localStorage.setItem('isImpersonatedDataPresent', true);
      localStorage.setItem('activeUrl', window.location.pathname);
      localStorage.setItem(
        'hasAppointmentConsoleAccess',
        producer?.hasAppointmentConsoleAccess || false
      );
      localStorage.setItem(
        'availableCarrierCount',
        producer.status !== IN_COMPLETE ? producer?.availableCarrierCount : null
      );
      const { floodRaterCarrierId } = !!producer && producer;
      const { tendCarrierId } = !!producer && producer;
      let data = {};
      if (floodRaterCarrierId) {
        const { floodRaterCarrierId: carrier_id } = producer;
        data = {
          floodRaterCarrier: {
            producer_id: producer.producer_id,
            carrier_id,
          },
        };
      } else {
        data = {
          floodRaterCarrier: undefined,
        };
      }
      yield put(setCarrierFloodRater(data));
      localStorage.setItem('floodRaterCarrier', JSON.stringify(data));
      let tendData = {};
      if (tendCarrierId) {
        const { tendCarrierId: carrier_id } = producer;
        tendData = {
          tendCarrier: {
            producer_id: producer.producer_id,
            carrier_id,
          },
        };
      } else {
        tendData = {
          tendCarrier: undefined,
        };
      }
      yield put(setCarrierTend(tendData));
      localStorage.setItem('tendCarrier', JSON.stringify(tendData));

      yield put(
        setImpersonateData({
          permissions: permissions,
          producer: { ...producer, ...producer.fastStart },
          isImpersonate: true,
        })
      );
      yield put(fetchProducerSettings());
      yield put(fetchProducerFlags());

      let updatedUrl = window.location.pathname
        .split('/')
        .splice(0, 3)
        .toString()
        .replace(/,/g, '/');
      window.history.pushState({}, '', updatedUrl);
      const store = yield select();

      const { modulePermissions, impersonate, auth, producerSettings } = store;
      const { isNewCarrierStoreEnabled } = producerSettings;
      const { isImpersonate } = impersonate;
      let modulePermissionsData = getModulePermission(
        modulePermissions.modulePermissionsData,
        permissions,
        isImpersonate
      );
      const { isWholesaler, producer_code, producer_id } = producer;
      const match = {
        url: window.location.pathname,
      };
      let getPath =
        !!modulePermissionsData &&
        getDefaultPath({
          module: modulePermissionsData,
          match,
          isWholesaler,
          producerDetails: { producer_id, producer_code },
          carrierStoreAgentApplicationRestrictionEnabled: JSON.parse(
            localStorage.getItem(
              CARRIER_STORE_AGENT_APPLICATION_RESTRICTION_ENABLED
            )
          ),
          carrierAppointmentRequestCount: JSON.parse(
            localStorage.getItem(CARRIER_APPOINTMENT_REQ_COUNT)
          ),
          enableMenuItem: auth?.enableMenuItem || false,
          availableCarrierCount: JSON.parse(
            localStorage.getItem('availableCarrierCount') || null
          ),
          isNewCarrierStoreEnabled,
        });
      yield put(push(getPath));
    } else {
      yield put(
        setImpersonateData({
          permissions: null,
          producer: null,
          isImpersonate: false,
        })
      );
    }
  } catch (error) {
    yield put(errorHandler(error));
  }
  yield put(setImpersonateLoader(false));
}

export function* setImpersonateModeWatcher() {
  yield takeEvery(impersonateAction.GET_IMPERSONATE_MODE, setImpersonateMode);
}

export function* clearImpersonateMode() {
  try {
    yield put(
      setImpersonateData({
        permission: null,
        producer: null,
        isImpersonate: false,
      })
    );
    const adminUrl = localStorage.getItem('activeUrl');
    yield put(push(adminUrl));
    localStorage.removeItem('impersonateData');
    localStorage.removeItem('impersonatedProducerId');
    localStorage.removeItem('agentStatus');
    localStorage.removeItem('floodRaterCarrier');
    localStorage.removeItem('tendCarrier');
    localStorage.setItem('isImpersonatedDataPresent', false);
    localStorage.setItem('impersonateSignupFlow', 'PRODUCER');
    localStorage.setItem('isWholesaler', false);
    localStorage.setItem('enableMenuItem', false);
    localStorage.removeItem('daysRemaining');
    localStorage.removeItem('isInitialModalViewed');
    localStorage.removeItem('isSecondaryModalViewed');
    localStorage.removeItem('isTertiaryModalViewed');
    localStorage.removeItem('isQ2IncentiveInitialModalViewed');
    localStorage.removeItem('isQ2IncentiveSecondaryModalViewed');
    localStorage.removeItem('isQ2IncentiveTertiaryModalViewed');
    localStorage.removeItem('clickOnFilter');
    localStorage.removeItem('availableCarrierCount');
    window.resetActiveMenuItem = true;
    localStorage.setItem(CARRIER_APPOINTMENT_REQ_COUNT, 0);
    yield put(
      setAuthInfo({
        enableMenuItem: false,
      })
    );
    yield put(updateCarrierAppointmentRequestCount(0));
    yield put(carrierStoreThankYouModalClose(false));
    yield put(viewFastStartModal(true));
  } catch (error) {
    yield put(errorHandler(error));
  }
}

export function* clearImpersonateModeWatcher() {
  yield takeEvery(
    impersonateAction.CLEAR_IMPERSONATE_MODE,
    clearImpersonateMode
  );
}

export function* isImpersonated() {
  const { impersonate } = yield select();
  return impersonate?.isImpersonate;
}

export function* getImpersonateBFFRequestConfig() {
  const { impersonate } = yield select();
  const impersonateProducerId =
    impersonate?.isImpersonate && impersonate?.producer?.producer_id;
  const requestConfig = impersonateProducerId
    ? {
        headers: {
          [IMPERSONATE_BFF_HEADER]: impersonateProducerId,
        },
      }
    : {};
  return requestConfig;
}

export default function* rootSaga() {
  yield all([
    fork(setImpersonateModeWatcher),
    fork(clearImpersonateModeWatcher),
  ]);
}
