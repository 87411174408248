import * as types from '../../constants/action-types';

const initState = () => ({
  agentMessagingLoader: false,
  agentMessagingProcessSuccess: false,
  agentMessagingList: [],
  isUploading: false,
  uploaded: false,
  isProcessing: false,
  agentMessageState: 'idle',
});

export default (state = initState(), action) => {
  switch (action.type) {
    case types.SET_MESSAGING_LIST: {
      return {
        ...state,
        ...action.payload,
      };
    }

    case types.AGENT_MESSAGING_LOADER: {
      return {
        ...state,
        agentMessagingLoader: action.payload,
      };
    }

    case types.AGENT_MESSAGE_STATE: {
      return {
        ...state,
        agentMessageState: action.payload,
      };
    }

    case types.AGENT_MESSAGING_PROCESS_SUCCESS: {
      return {
        ...state,
        agentMessagingProcessSuccess: action.payload,
      };
    }

    case types.AGENT_MESSAGING_FILES_UPLOADING: {
      return {
        ...state,
        isUploading: action.payload,
      };
    }

    case types.AGENT_MESSAGING_PROCESSING: {
      return {
        ...state,
        isProcessing: action.payload,
      };
    }

    case types.AGENT_MESSAGING_SUCCESS: {
      let updatedData = [...state.agentMessagingList];
      const elementsIndex = updatedData.findIndex(
        element => element?.id === action?.payload?.id
      );
      if (elementsIndex === -1) {
        updatedData = [{ ...action.payload }, ...updatedData];
      } else if (action?.payload?.type === 'error') {
        updatedData = updatedData.filter(
          item => item.id !== action?.payload?.id
        );
      } else {
        updatedData[elementsIndex] = {
          ...action.payload,
        };
      }
      return {
        ...state,
        isUploading: false,
        uploaded: true,
        agentMessagingList: [...updatedData],
      };
    }

    default:
      return state;
  }
};
