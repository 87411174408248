import { useSelector } from 'react-redux';
import {
  ALTERNATE_SUB_PRODUCER,
  NEW_APP_ROUTES,
  PRINCIPAL_ACCESS_ROLE,
  PRODUCER,
  SUB_PRODUCER_KEY
} from '../../constants/constant';
import SecondaryNavigationIcons from './secondary-navigation-icons';
import { getAuthDetails } from '../../util/extra';

export const CASH_CONNECT_MODULE = 'CASH-CONNECT';
export const HELP_CENTER_MODULE = 'HELP-CENTER';
export const APPETITE_GUIDE_MODULE = 'APPETITE-GUIDE';
export const AGENT_TOOLS_MODULE = 'AGENT-TOOLS';
export const RATERS_MODULE = 'RATERS';

export const MODULES_WITH_NEW_LABEL = [];

// Some modules are implemented in the new app and their links need to be handled differently
export const NEW_APP_MODULES = [
  CASH_CONNECT_MODULE,
  APPETITE_GUIDE_MODULE,
  AGENT_TOOLS_MODULE,
  RATERS_MODULE,
];

function useIsReferralProgramPermitted() {
  return true;
}

function useIsRewardsProgramPermitted() {
  const { producer, isImpersonate } = useSelector((state) => state.impersonate);
  const auth = useSelector((state) => state.auth);
  const { role } = getAuthDetails({
    auth,
    producer,
    isImpersonate,
  });
  const producerStatus = auth.userStatus;
  const isPrincipalAccessProducer = role === PRINCIPAL_ACCESS_ROLE;
  const isPrincipalProducer = role === PRODUCER;

  return Boolean(
    (isPrincipalProducer || isPrincipalAccessProducer) &&
      producerStatus &&
      ['Active', 'Pending', 'Verified', 'Restricted'].includes(producerStatus)
  );
}

export function useIsFloodRaterPermitted() {
  const auth = useSelector((state) => state.auth);
  const floodRaterCarrier = auth?.floodRaterCarrier;
  return Boolean(floodRaterCarrier);
}

export function useIsQuoteConnectorPermitted() {
  const auth = useSelector((state) => state.auth);
  const hasQuotingEngineAccess = auth?.hasQuotingEngineAccess;
  return Boolean(hasQuotingEngineAccess);
}

function useIsCarrierIncentivesPermitted() {
  const { producer, isImpersonate } = useSelector((state) => state.impersonate);
  const auth = useSelector((state) => state.auth);
  const { role } = getAuthDetails({
    auth,
    producer,
    isImpersonate,
  });
  const isPrincipalProducer = role === PRODUCER;
  const isPrincipalAccessProducer = role === PRINCIPAL_ACCESS_ROLE;
  return Boolean(isPrincipalProducer || isPrincipalAccessProducer);
}

export function useIsIncentivePerformancePermitted() {
  const { producer, isImpersonate } = useSelector((state) => state.impersonate);
  const auth = useSelector((state) => state.auth);
  const { role,signupFlow,isWholesaler } = getAuthDetails({
    auth,
    producer,
    isImpersonate,
  });

  const isSubProducer = [ALTERNATE_SUB_PRODUCER, SUB_PRODUCER_KEY].includes(
    signupFlow
  );
  const isPrincipalAccessProducer = role === PRINCIPAL_ACCESS_ROLE;

  return Boolean(
    (!isSubProducer || isPrincipalAccessProducer) && !isWholesaler
  );
}

export const SECONDARY_NAVIGATION_MODULES = {
  'CASH-CONNECT': [
    {
      title: 'Incentives & Performance',
      url: NEW_APP_ROUTES.CASH_CONNECT_INCENTIVES,
      IconComponent: SecondaryNavigationIcons.IncentivesIcon,
      getIsPermitted: useIsIncentivePerformancePermitted,
    },
    {
      title: 'Carrier Incentives',
      url: NEW_APP_ROUTES.CASH_CONNECT_CARRIER_INCENTIVES,
      IconComponent: SecondaryNavigationIcons.CarrierIncentivesIcon,
      pillText: 'New',
      getIsPermitted: useIsCarrierIncentivesPermitted,
    },
    {
      title: 'Rewards Program 2025',
      url: NEW_APP_ROUTES.CASH_CONNECT_REWARDS_PROGRAM,
      IconComponent: SecondaryNavigationIcons.RewardsProgramIcon,
      getIsPermitted: useIsRewardsProgramPermitted,
    },
    {
      title: 'Referral Program',
      url: NEW_APP_ROUTES.CASH_CONNECT_REFERRAL_PROGRAM,
      IconComponent: SecondaryNavigationIcons.ReferralProgramIcon,
      getIsPermitted: useIsReferralProgramPermitted,
    },
  ],
  RATERS: [
    {
      url: NEW_APP_ROUTES.RATERS_QUOTE_LIST,
      title: 'Quote Connector',
      IconComponent: SecondaryNavigationIcons.QuoteConnectorIcon,
      getIsPermitted: useIsQuoteConnectorPermitted,
    },
    {
      url: '#flood-rater',
      title: 'Flood Rater',
      IconComponent: SecondaryNavigationIcons.FloodRaterIcon,
      getIsPermitted: useIsFloodRaterPermitted,
    },
  ],
};

export const MODULE_TO_TITLE_MAP = {
  'CASH-CONNECT': 'Cash Connect',
  RATERS: 'Raters',
};
