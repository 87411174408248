import { all, call, fork, put, select, takeEvery } from 'redux-saga/effects';
import { displayErrorMessage, displaySuccessMessage } from 'util/user-feedback';
import { isEmpty } from 'lodash';
import axios from 'util/api';
import { uploadFile } from 'util/extra';
import * as productActions from 'constants/action-types';
import {
  productFileUploadError,
  productServerError,
  productServerErrorMessage,
  setAddProductLoader,
  setNewProduct,
  setProductCategories,
  setProductList,
  setProductListLoader,
  setUpdatedProduct,
  updateProductLoader,
} from '../actions/product';
import { errorHandler } from '../actions/error';
import { STATIC_ASSETS } from 'constants/constant';

// Get Product List
function* fetchProductList() {
  try {
    yield put(setProductListLoader(true));
    const productListResponse = yield axios.get('/api/v1/product/product-list');
    if (productListResponse) {
      const { data, status } = productListResponse;
      if (status === 200 && data?.success) {
        const { products } = data;
        yield put(setProductList(products));
      } else {
        yield put(setProductList([]));
      }
    }
  } catch (error) {
    if (!!error.response && error.response.status === 401) {
      yield displayErrorMessage(error.response.data.message);
      yield put(errorHandler(error));
    } else {
      yield put(errorHandler(error));
    }
  }
  yield put(setProductListLoader(false));
}

function* fetchProductCategories() {
  try {
    const productCategoriesResponse = yield axios.get(
      '/api/v1/product/product-categories'
    );
    if (productCategoriesResponse) {
      const { data, status } = productCategoriesResponse;
      if (status === 200 && data?.success) {
        yield put(setProductCategories(data.productCategories));
      } else {
        yield put(setProductCategories([]));
      }
    }
  } catch (error) {
    if (!!error.response && error.response.status === 401) {
      yield displayErrorMessage(error.response.data.message);
      yield put(errorHandler(error));
    } else {
      yield put(errorHandler(error));
    }
  }
}

export function* fetchProductListWatcher() {
  yield takeEvery(productActions.GET_PRODUCT_LIST, fetchProductList);
}

export function* fetchProductCategoriesWatcher() {
  yield takeEvery(
    productActions.GET_PRODUCT_CATEGORIES,
    fetchProductCategories
  );
}

// Add new product
function* addNewProduct({ payload }) {
  try {
    yield put(setAddProductLoader(true));
    yield put(productFileUploadError(false));
    const { productImage } = payload;
    const fileURL = `${STATIC_ASSETS}/products/${productImage[0].name}`;
    const result = yield call(uploadFile, productImage[0], fileURL);
    if (result && result.status === 200) {
      const { productName, root_category, top_category, second_level } =
        payload;
      const response = yield axios.post('/api/v1/product/add-product', {
        productImage: result.data.file,
        productName,
        root_category,
        top_category,
        second_level,
      });
      if (response.status === 200 && response.data.success) {
        const { product, message } = response.data;
        yield put(setNewProduct(product));
        yield displaySuccessMessage(message);
      } else {
        yield displayErrorMessage(response.data.message);
      }
    } else {
      yield put(errorHandler(result));
      yield put(productFileUploadError(true));
    }
  } catch (error) {
    if (!!error.response && error.response.status === 401) {
      yield displayErrorMessage(error.response.data.message);
      yield put(errorHandler(error));
    } else {
      yield put(errorHandler(error));
    }
  }
  yield put(setAddProductLoader(false));
}

export function* addNewProductWatcher() {
  yield takeEvery(productActions.ADD_NEW_PRODUCT, addNewProduct);
}

// Update existing product
function* updateProductDetails({ payload }) {
  try {
    yield put(updateProductLoader(true));
    yield put(productFileUploadError(false));
    let isFileUploadErrorPresent = false;
    const { productImage, logoURL, root_category, top_category, second_level } =
      payload;
    let productLogoUrl = logoURL;
    if (!isEmpty(productImage)) {
      const fileURL = `${STATIC_ASSETS}/products/${productImage[0].name}`;
      const result = yield call(uploadFile, productImage[0], fileURL);
      if (result && result.status === 200) {
        isFileUploadErrorPresent = false;
        const { file } = result.data;
        productLogoUrl = file;
      } else {
        isFileUploadErrorPresent = true;
        yield put(errorHandler(result));
        yield put(productFileUploadError(true));
      }
      yield put(productServerError(false));
      yield put(productServerErrorMessage(''));
    }
    if (!isFileUploadErrorPresent) {
      const response = yield axios.post('/api/v1/product/update-product', {
        productImage: productLogoUrl,
        productName: payload.productName,
        productId: payload.productId,
        root_category,
        top_category,
        second_level,
      });
      const reduxState = yield select();
      let productList = reduxState.product.productList;
      let productIndex = productList.findIndex((item) => {
        return item.productId === payload.productId;
      });
      if (response.status === 200 && response.data.success) {
        const { product, message } = response.data;
        productList[productIndex] = {
          displayName: payload.productName,
          productCode: product.productCode,
          productId: product.productId,
          productImage: product.productImage,
          updatedAt: product.updatedAt,
          updatedBy: product.updatedBy,
          agentFacingName: response.data.product.agentFacingName,
          rootCategory: response.data.product.rootCategory,
          secondLevel: response.data.product.secondLevel,
          topCategory: response.data.product.topCategory,
        };
        yield put(productServerError(false));
        yield put(productServerErrorMessage(''));
        yield displaySuccessMessage(message, '', 300);
      } else {
        yield put(productServerError(true));
        yield put(productServerErrorMessage(response.data.message));
        yield displayErrorMessage(response.data.message);
      }
      yield put(setUpdatedProduct(productList));
    }
  } catch (error) {
    if (!!error.response && error.response.status === 401) {
      yield displayErrorMessage(error.response.data.message);
      yield put(errorHandler(error));
    } else {
      yield put(errorHandler(error));
      yield put(productServerError(true));
      yield put(productServerErrorMessage(error.response.data.message));
    }
  }
  yield put(updateProductLoader(false));
}

export function* updateProductWatcher() {
  yield takeEvery(productActions.UPDATE_PRODUCT, updateProductDetails);
}

// ------ ROOT SAGA -----------------
export default function* rootSaga() {
  yield all([
    fork(fetchProductListWatcher),
    fork(fetchProductCategoriesWatcher),
    fork(addNewProductWatcher),
    fork(updateProductWatcher),
  ]);
}
