/**
 * Executes the reCAPTCHA check.
 *
 * @param {import('react-google-recaptcha-v3').IGoogleReCaptchaConsumerProps} googleReCaptchaProps - The Google reCAPTCHA properties coming from withGoogleReCaptcha HoC.
 * @param {string} [action='homepage'] - The action to be performed: <https://developers.google.com/recaptcha/docs/v3#interpreting_the_score>
 * @returns {Promise<string>} - The reCAPTCHA response token.
 * @throws {Error} - If there is an error executing the reCAPTCHA check.
 */
export async function executeRecaptchaCheck(
  googleReCaptchaProps,
  action = 'homepage'
) {
  const { executeRecaptcha } = googleReCaptchaProps;

  if (!executeRecaptcha) {
    throw new Error('Recaptcha has not been loaded');
  }

  try {
    return await executeRecaptcha(action);
  } catch (error) {
    throw new Error(`Recaptcha error: ${error}`);
  }
}

export function showRecaptchaBadge() {
  const badge = document.querySelector('.grecaptcha-badge');

  if (badge) {
    badge.style.visibility = 'visible';
  } else {
    // Element might take some time to load
    setTimeout(() => {
      showRecaptchaBadge();
    }, 1000);
  }
}

export function hideRecaptchaBadge() {
  const badge = document.querySelector('.grecaptcha-badge');

  if (badge) {
    badge.style.visibility = 'hidden';
  } else {
    // Element might take some time to load
    setTimeout(() => {
      hideRecaptchaBadge();
    }, 1000);
  }
}
