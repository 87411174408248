// @ts-check

import { isExperimentalMode } from 'util/is-experimental-mode';

// 🚨 Until those configs are migrated into a separate package, or dual app setup is removed
// 🚨 part of auth0-related code is duplicated between old and new FE repositories.
// 🚨 Any changes to this file need to be reflected in the new FE.

// Flag indicating whether passwordless or traditional (password) flow is in use.
// Retrieved from the BE during pre-login check.
// Persisted for the duration of the session.
// Cleared on logout.
export const AUTH_FLOW_PASSWORDLESS_ENABLED = 'passwordlessSignInEnabled';

// Indicates whether passwordless flow runs in "remember me" or "temporary" mode.
// Needed for resolution of the Auth0 client with proper refresh token policy.
// Set by passwordless sign in form checkbox.
// Persisted between the sessions.
export const AUTH_FLOW_PASSWORDLESS_REMEMBER_ME =
  'passwordlessSignInRememberMe';

export const IS_POST_PASSWORDLESS_MIGRATION = isExperimentalMode();
