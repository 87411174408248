import * as productType from '../../constants/action-types';

// Get/Set Product List
export const getProductList = () => {
  return {
    type: productType.GET_PRODUCT_LIST,
  };
};

export const getProductCategories = () => {
  return {
    type: productType.GET_PRODUCT_CATEGORIES,
  };
};

export const setProductListLoader = payload => {
  return {
    type: productType.SET_PRODUCT_LIST_LOADER,
    payload,
  };
};

export const setProductList = payload => {
  return {
    type: productType.SET_PRODUCT_LIST,
    payload,
  };
};

export const setProductCategories = payload => {
  return {
    type: productType.SET_PRODUCT_CATEGORIES,
    payload,
  };
};

// Add new product to the list
export const addNewProduct = payload => {
  return {
    type: productType.ADD_NEW_PRODUCT,
    payload,
  };
};

export const setAddProductLoader = payload => {
  return {
    type: productType.NEW_PRODUCT_ADD_LOADER,
    payload,
  };
};

export const setNewProduct = payload => {
  return {
    type: productType.SET_NEW_PRODUCT,
    payload,
  };
};

// Update product to the list
export const updateProduct = payload => {
  return {
    type: productType.UPDATE_PRODUCT,
    payload,
  };
};

export const updateProductLoader = payload => {
  return {
    type: productType.UPDATE_PRODUCT_LOADER,
    payload,
  };
};

export const setUpdatedProduct = payload => {
  return {
    type: productType.SET_UPDATED_PRODUCT,
    payload,
  };
};

export const productFileUploadError = payload => {
  return {
    type: productType.PRODUCT_FILE_UPLOAD_ERROR,
    payload,
  };
};

export const productServerError = payload => {
  return {
    type: productType.PRODUCT_SERVER_ERROR,
    payload,
  };
};
export const productServerErrorMessage = payload => {
  return {
    type: productType.PRODUCT_SERVER_ERROR_MESSAGE,
    payload,
  };
};