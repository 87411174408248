import * as types from '../../constants/action-types';

const initState = () => ({
  fastStartLoader: false,
  policyCount: 0,
  producerLeaderboardData: null,
  carrierCount: 0,
  totalEarnings: 0,
  carrierURL: null,
});

export default (state = initState(), action) => {
  switch (action.type) {
    case types.FAST_START_DETAIL_LOADER: {
      return {
        ...state,
        fastStartLoader: action.payload,
      };
    }
    case types.STORE_FAST_START_DETAILS: {
      return {
        ...state,
        ...action.payload,
      };
    }
    default:
      return state;
  }
};
