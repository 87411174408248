import React from 'react';
import clsx from 'clsx';
import { connect } from 'react-redux';
import Avatar from '@material-ui/core/Avatar';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import UpdatePasswordDialog from 'components/update-password-dialog';
import { userSignOut } from 'redux/actions/auth';
import { changePasswordCompleted } from 'redux/actions/update-password';
import IntlMessages from 'util/intl-messages';
import { isIOS, isMobileOnly } from 'react-device-detect';
import {
  ADMIN,
  AS_ORGANIZATION_CODE,
  DM_ORGANIZATION_CODE,
  FC_ORGANIZATION_CODE,
  PM_ORGANIZATION_CODE,
} from 'constants/constant';
import { isEmpty } from 'lodash';
import { isCarrierStore } from 'util/extra';

const styles = () => ({
  root: {},
  userName: {
    marginBottom: 0,
    fontWeight: 'bold',
    '& span': {
      display: 'inline-block',
      maxWidth: '130px',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      paddingTop: '5px',
    },
    '& i': {
      paddingBottom: '5px',
    },
  },
  cursor: {
    cursor: 'pointer',
  },
});

class UserInfo extends React.Component {
  state = {
    anchorEl: null,
    open: false,
    openChangePasswordModal: false,
  };

  userMenuOpen = (event) => {
    this.setState({ open: true, anchorEl: event.currentTarget });
  };

  userMenuClose = () => {
    this.setState({ open: false });
  };

  handleModalClose = () => {
    this.setState({
      openChangePasswordModal: false,
    });
  };

  showProfile = () => {
    const { history, match } = this.props;
    history.push(`${match.url}/settings/profile`);
  };

  render() {
    const { openChangePasswordModal } = this.state;
    const {
      loginName,
      userLastName,
      classes,
      orgDetails,
      userRole,
      carrierStoreAgentApplicationRestrictionEnabled,
      carrierAppointmentRequestCount,
      enableMenuItem,
      isNewCarrierStoreEnabled
    } = this.props;
    const { organizationCode } = orgDetails || {};
    const firstNameinitial = loginName
      ?.split(' ')
      ?.map((n) => n[0])
      ?.join('');
    const lastNameinitial = userLastName
      ?.split(' ')
      ?.map((n) => n[0])
      ?.join('');
    const checkLastNameInitial = !isEmpty(userLastName) && lastNameinitial;
    const checkLastNameInitialString =
      !isEmpty(userLastName) && `${lastNameinitial}.`;
    const shouldShowCarrierStore = isCarrierStore(
      carrierStoreAgentApplicationRestrictionEnabled,
      carrierAppointmentRequestCount,
      enableMenuItem,
      isNewCarrierStoreEnabled
    );
    return (
      <div
        className={clsx(
          'user-profile',
          'd-flex',
          'flex-row',
          'align-items-center',
          {
            'navy-blue-bg': organizationCode === PM_ORGANIZATION_CODE,
            'purplish-blue-bg':
              organizationCode === DM_ORGANIZATION_CODE ||
              organizationCode === AS_ORGANIZATION_CODE,
          }
        )}
      >
        <Avatar alt={loginName} className="user-avatar with-initails">
          {firstNameinitial}
          {checkLastNameInitial}
        </Avatar>
        {organizationCode === FC_ORGANIZATION_CODE &&
          shouldShowCarrierStore && <span className="icon-user-lock"></span>}
        <div className="user-detail fs-exclude">
          <h4
            className={clsx(
              classes.userName,
              userRole === ADMIN || isMobileOnly || isIOS ? 'cursorEnable' : ''
            )}
            onClick={this.userMenuOpen}
          >
            <span>
              {loginName} {checkLastNameInitialString}
            </span>
            {(userRole === ADMIN || isMobileOnly || isIOS) && (
              <ExpandMoreIcon className="position-absolute right-0" />
            )}
          </h4>
        </div>
        {(userRole === ADMIN || isMobileOnly || isIOS) && (
          <Menu
            className="user-info user-list"
            id="simple-menu"
            anchorEl={this.state.anchorEl}
            open={this.state.open}
            onClose={this.userMenuClose}
            PaperProps={{
              style: {
                minWidth: 120,
                paddingTop: 0,
                paddingBottom: 0,
              },
            }}
          >
            {(isMobileOnly || isIOS) && (
              <MenuItem
                onClick={() => {
                  this.userMenuClose();
                  this.props.userSignOut();
                }}
              >
                <ExitToAppIcon fontSize="small" className="mr-2 exit-app" />
                <IntlMessages id="popup.logout" />
              </MenuItem>
            )}
            <MenuItem
              onClick={() => {
                this.setState(
                  {
                    openChangePasswordModal: true,
                  },
                  () => {
                    this.userMenuClose();
                    this.props.changePasswordCompleted(false);
                  }
                );
              }}
            >
              <LockOutlinedIcon className="mr-2 lock-icon" fontSize="small" />
              <IntlMessages id="popup.changePassword" />
            </MenuItem>
          </Menu>
        )}
        {openChangePasswordModal && (
          <UpdatePasswordDialog
            openUpdatePasswordDialog={openChangePasswordModal}
            onCloseUpdatePasswordDialog={this.handleModalClose}
            userSignOut={this.props.userSignOut}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = ({ settings, auth, organizationDetails , producerSettings}) => {
  const { locale } = settings;
  const {
    loginName,
    userLastName,
    userRole,
    carrierStoreAgentApplicationRestrictionEnabled,
    carrierAppointmentRequestCount,
    enableMenuItem,
  } = auth;
  const { orgDetails } = organizationDetails;
  const { isNewCarrierStoreEnabled } = producerSettings;

  return {
    locale,
    loginName,
    userLastName,
    orgDetails,
    userRole,
    carrierStoreAgentApplicationRestrictionEnabled,
    carrierAppointmentRequestCount,
    enableMenuItem,
    isNewCarrierStoreEnabled
  };
};

export default withStyles(styles)(
  connect(mapStateToProps, {
    userSignOut,
    changePasswordCompleted,
  })(UserInfo)
);
