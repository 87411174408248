import React from 'react';
import Drawer from '@material-ui/core/Drawer';
import Preview from 'components/incentive-banner/preview';
import UserInfo from 'components/user-info';
import {
  COLLAPSED_DRAWER,
  FIXED_DRAWER,
  HORIZONTAL_NAVIGATION,
} from 'constants/action-types';
import {
  ADMIN,
  FC_ORGANIZATION_CODE,
  INCENTIVE_BANNER_ROTATE_INTERVAL_IN_MS,
  PRODUCER,
} from 'constants/constant';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { toggleCollapsedNav, updateWindowWidth } from 'redux/actions/setting';
import {
  getAuthDetails,
  getModulePermission,
  isCarrierStore,
} from 'util/extra';
import SidenavContent from './side-nav-content';
import CrossSellSideNav from 'components/cse-announcement-modal/side-nav/index';
import './side-nav-content.scss';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import SwipeableViews from 'react-swipeable-views';
import SecondaryNavigation from './secondary-navigation';
class SideNav extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      activeStep: 0,
      secondaryNavigationParentModule: null,
    };
    this.bannerRotateInterval = null;
  }
  componentDidMount = () => {
    window.addEventListener('resize', () => {
      this.props.updateWindowWidth(window.innerWidth);
    });
    this.bannerRotateInterval = setInterval(
      this.handleNext,
      INCENTIVE_BANNER_ROTATE_INTERVAL_IN_MS
    );
  };

  componentWillUnmount = () => {
    clearInterval(this.bannerRotateInterval);
  };

  onToggleCollapsedNav = () => {
    const val = !this.props.navCollapsed;
    this.props.toggleCollapsedNav(val);
  };

  handleNext = () => {
    const { calloutIncentives = [] } = this.props;
    if (calloutIncentives.length <= 1) return;
    this.setState({
      activeStep:
        (this.state.activeStep + 1) % this.props.calloutIncentives.length,
    });
    clearInterval(this.bannerRotateInterval);
    this.bannerRotateInterval = setInterval(
      this.handleNext,
      INCENTIVE_BANNER_ROTATE_INTERVAL_IN_MS
    );
  };

  handleBack = () => {
    const { calloutIncentives = [] } = this.props;
    const { activeStep } = this.state;
    if (calloutIncentives.length <= 1) return;
    this.setState({
      activeStep:
        activeStep === 0 ? calloutIncentives.length - 1 : activeStep - 1,
    });
    clearInterval(this.bannerRotateInterval);
    this.bannerRotateInterval = setInterval(
      this.handleNext,
      INCENTIVE_BANNER_ROTATE_INTERVAL_IN_MS
    );
  };

  handleStepChange = (step) => {
    this.setState({ activeStep: step });
  };

  render() {
    const {
      navCollapsed,
      drawerType,
      width,
      navigationStyle,
      calloutIncentives = [],
      isImpersonate,
      producer,
      userRole,
      orgDetails,
      carrierAppointmentRequestCount,
      carrierStoreAgentApplicationRestrictionEnabled,
      enableMenuItem,
      modulePermissionsData,
      isNewCarrierStoreEnabled,
    } = this.props;
    const { organizationCode } = !!orgDetails && orgDetails;
    let drawerStyle = drawerType.includes(FIXED_DRAWER)
      ? 'd-xl-flex'
      : drawerType.includes(COLLAPSED_DRAWER)
        ? ''
        : 'd-flex';
    let type = 'permanent';
    if (
      drawerType.includes(COLLAPSED_DRAWER) ||
      (drawerType.includes(FIXED_DRAWER) && width < 1200)
    ) {
      type = 'temporary';
    }

    if (navigationStyle === HORIZONTAL_NAVIGATION) {
      drawerStyle = '';
      type = 'temporary';
    }
    let isCalloutIncentiveVisible =
      organizationCode === FC_ORGANIZATION_CODE &&
      ((userRole?.toLowerCase() === ADMIN &&
        isImpersonate &&
        producer?.role_category?.toLowerCase() === PRODUCER) ||
        userRole?.toLowerCase() === PRODUCER) &&
      calloutIncentives?.length > 0;
    const shouldShowCarrierStore = isCarrierStore(
      carrierStoreAgentApplicationRestrictionEnabled,
      carrierAppointmentRequestCount,
      enableMenuItem,
      isNewCarrierStoreEnabled
    );
    if (
      !!modulePermissionsData &&
      !!modulePermissionsData['CARRIER-APPOINTMENTS'] &&
      modulePermissionsData['CARRIER-APPOINTMENTS'].read
    ) {
      document.body.classList.add('carrierTooltip');
    } else {
      document.body.classList.remove('carrierTooltip');
    }

    return (
      <div
        className={`app-sidebar ${
          shouldShowCarrierStore ? 'collapse-app-sidebar' : ''
        } d-none ${drawerStyle}`}
        onMouseLeave={() => {
          this.setState({ secondaryNavigationParentModule: null });
        }}
      >
        <SecondaryNavigation
          mode="desktop"
          shouldDisplay={
            !type.includes('temporary') &&
            this.state.secondaryNavigationParentModule
          }
          onClose={() =>
            this.setState({ secondaryNavigationParentModule: null })
          }
          parentModule={this.state.secondaryNavigationParentModule}
        />
        <Drawer
          elevation-1="true"
          className="app-sidebar-content"
          variant={type}
          open={type.includes('temporary') ? navCollapsed : true}
          onClose={this.onToggleCollapsedNav}
          classes={{
            paper: 'side-nav',
          }}
        >
          <UserInfo {...this.props} />
          {!this.props.isCrossSellExperience ? (
            <>
              <SidenavContent
                {...this.props}
                isNavCollapsed={shouldShowCarrierStore}
                secondaryNavigationParentModule={
                  this.state.secondaryNavigationParentModule
                }
                setSecondaryNavigationParentModule={(
                  secondaryNavigationParentModule
                ) => this.setState({ secondaryNavigationParentModule })}
              />
              {isCalloutIncentiveVisible && !shouldShowCarrierStore && (
                <div className="position-relative producer-prev-container">
                  <SwipeableViews
                    index={this.state.activeStep}
                    onChangeIndex={this.handleStepChange}
                    enableMouseEvents
                  >
                    {calloutIncentives.map((ele) => (
                      <>
                        <Preview
                          mainImageURL={ele.mainImageURL}
                          animationURL={ele.animationURL}
                          ctaButtonBackgroundColor={
                            ele.ctaButtonBackgroundColor
                          }
                          ctaButtonLabelColor={ele.ctaButtonLabelColor}
                          ctaButtonLabelFont={ele.ctaButtonLabelFont}
                          ctaButtonLabel={ele.ctaButtonLabel}
                          ctaButtonURL={ele.ctaButtonURL}
                          ctaButtonAlignment={ele.ctaButtonAlignment}
                          animationAlignment={ele.animationAlignment}
                          ctaButtonIncluded={ele.ctaButtonIncluded}
                        />
                      </>
                    ))}
                  </SwipeableViews>
                  {calloutIncentives.length > 1 && (
                    <div>
                      <div className="step-btn-left">
                        <Button
                          onClick={this.handleBack}
                          className="step-btn btn-left justify-content-start"
                          disableRipple
                        >
                          <KeyboardArrowLeft />
                        </Button>
                      </div>
                      <div className="step-btn-right">
                        <Button
                          onClick={this.handleNext}
                          className="step-btn btn-right justify-content-end"
                          disableRipple
                        >
                          <KeyboardArrowRight />
                        </Button>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </>
          ) : (
            <CrossSellSideNav {...this.props} />
          )}
          <SecondaryNavigation
            mode="mobile"
            shouldDisplay={
              type.includes('temporary') &&
              this.state.secondaryNavigationParentModule
            }
            onClose={() =>
              this.setState({ secondaryNavigationParentModule: null })
            }
            parentModule={this.state.secondaryNavigationParentModule}
          />
        </Drawer>
      </div>
    );
  }
}

const mapStateToProps = ({
  auth,
  impersonate,
  organizationDetails,
  modulePermissions,
  settings,
  producerSettings,
}) => {
  const { navCollapsed, drawerType, width, navigationStyle } = settings;
  const { producer, permissions, isImpersonate } = impersonate;
  const { orgDetails } = organizationDetails;
  const {
    isCrossSellExperience,
    carrierAppointmentRequestCount,
    carrierStoreAgentApplicationRestrictionEnabled,
    enableMenuItem,
  } = auth;
  const { isNewCarrierStoreEnabled } = producerSettings;
  const { calloutIncentives, userRole } = getAuthDetails({
    auth,
    producer,
    isImpersonate,
  });
  const modulePermissionsData = getModulePermission(
    modulePermissions.modulePermissionsData,
    permissions,
    isImpersonate
  );

  return {
    navCollapsed,
    drawerType,
    width,
    navigationStyle,
    calloutIncentives,
    userRole,
    isImpersonate,
    producer,
    orgDetails,
    isCrossSellExperience,
    carrierAppointmentRequestCount,
    carrierStoreAgentApplicationRestrictionEnabled,
    enableMenuItem,
    modulePermissionsData,
    isNewCarrierStoreEnabled,
  };
};

export default withRouter(
  connect(mapStateToProps, { toggleCollapsedNav, updateWindowWidth })(SideNav)
);
