import React from 'react';
import { connect } from 'react-redux';
import { setCrossSellDetails } from 'redux/actions/auth';
import { clearImpersonateMode } from 'redux/actions/impersonate';
const Impersonate = props => {
  return (
    <>
      <div className="d-flex justify-content-end">
        <span
          onClick={() => {
            props.clearImpersonateMode(), props.setCrossSellDetails(false);
          }}
          className="pointer pulse impersion-pulse-icon"
        ></span>
      </div>
    </>
  );
};

const mapStateToProps = ({ impersonate }) => {
  const { loginName, userLastName } =
    !!impersonate?.producer && impersonate.producer;
  return {
    loginName,
    userLastName,
  };
};

export default connect(mapStateToProps, {
  clearImpersonateMode,
  setCrossSellDetails,
})(Impersonate);
