import {
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Typography,
} from '@material-ui/core';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import { CARRIER_NAME_KEY } from 'constants/constant';
import { PORTAL } from 'constants/heap-events';
import { isEmpty, startCase } from 'lodash';
import React, { useEffect, useRef, useState} from 'react';
import { connect } from 'react-redux';
import { setClickOnCrossSell, setCrossSellDetails } from 'redux/actions/auth';
import { setCarrierType } from 'redux/actions/dashboard';
import { suggestedCSECarriers } from 'util/extra';
import IntlMessages from 'util/intl-messages';
import './side-nav.scss';

const CrossSellSideNav = props => {
  const [openBackModal, setOpenBackModal] = useState(false);

  const handleBack = () => {
    setOpenBackModal(true);
    window.heap.track(PORTAL.CROSS_SELL_BACK_CLICK);
  };

  const backToDashboard = () => {
    props.setCrossSellDetails(false);
    props.setClickOnCrossSell(false);
  };

  const cancelBackModal = () => {
    setOpenBackModal(false);
  };

  const handleHomeCarrier = () => {
    let payload = {
      homeCarrier: {
        carrierName: 'Home',
        active: true,
      },
      suggestedCarriers: {
        carrierName: '',
        active: false,
      },
      isTendVisited: props.carrierType?.isTendVisited,
      isBlinkVisited: props.carrierType?.isBlinkVisited,
      isAnnexVisited: props.carrierType?.isAnnexVisited,
    };
    props.setCarrierType(payload);
    window.heap.track(PORTAL.CROSS_SELL_CHOOSE_PRODUCT_CLICK, {
      [CARRIER_NAME_KEY]: props.currentCarrier?.display_name,
      Status: 'Resume',
    });
  };

  const carrierStatus = {
    tend: props.carrierType?.isTendVisited ? 'Resume' : 'Purchase',
    blink: props.carrierType?.isBlinkVisited ? 'Resume' : 'Purchase',
    'annex-risk-solutions': props.carrierType?.isAnnexVisited
      ? 'Resume'
      : 'Purchase',
    default: 'Purchase',
  };

  const handleCarrier = code => {
    // eslint-disable-next-line no-console
    let payload = {
      homeCarrier: {
        carrierName: 'Home',
        active: false,
      },
      suggestedCarriers: {
        carrierName: code?.carrier_code,
        active: true,
      },
      isTendVisited:
        code?.carrier_code === 'tend' ? true : props.carrierType?.isTendVisited,
      isBlinkVisited:
        code?.carrier_code === 'blink'
          ? true
          : props.carrierType?.isBlinkVisited,
       isAnnexVisited:
        code?.carrier_code === 'annex-risk-solutions'
          ? true
          : props.carrierType?.isAnnexVisited,
    };
    window.heap.track(PORTAL.CROSS_SELL_CHOOSE_PRODUCT_CLICK, {
      [CARRIER_NAME_KEY]: startCase(code?.carrier_code),
      Status: carrierStatus[code?.carrier_code] || carrierStatus.default,
    });
    props.setCarrierType(payload);
  };

  useEffect(() => {
    document.addEventListener('click', handleClickCrossSale, true);
    return function cleanupListener() {
      document.removeEventListener('click', handleClickCrossSale, false);
    };
  }, []);
  const homeCrossSellRef = useRef(null);
  const suggestedCrossSellRef = useRef(null);

  const handleClickCrossSale = e => {
    if (
      homeCrossSellRef.current?.contains(e.target) ||
      suggestedCrossSellRef.current?.contains(e.target)
    ) {
      props.setClickOnCrossSell(true);
    }
  };

  return (
    <div className="cross-sell-wraper">
      <>
        <Button className="lbl-back" disableRipple={false} onClick={handleBack}>
          <KeyboardBackspaceIcon /> Back
        </Button>
      </>
      <Card
        className={`cross-sell-card ${
          props.carrierType?.homeCarrier?.active ? 'black-card' : 'white-card'
        }`}
        onClick={() => {
          handleHomeCarrier();
        }}
        ref={homeCrossSellRef}
      >
        <CardContent className="p-0">
          <div className="d-flex align-items-center justify-content-between first-wrap">
            <img
              src={props.currentCarrier?.logo_url}
              alt="logo"
              className="img-fluid"
            />
            <Typography
              className={`carrier-para ${
                props?.carrierType?.homeCarrier?.active ? 'orange-lbl' : ''
              }`}
            >
              <span className="logo-span icon-home mr-6"></span>
              <span className="label-span">Home</span>
            </Typography>
          </div>
          <Typography className="carrier-label">
            {props.currentCarrier?.display_name}
          </Typography>
          <Button
            color="default"
            variant="outlined"
            className="cross-sell-button"
            hidden={props?.carrierType?.homeCarrier?.active}
          >
            Resume
          </Button>
        </CardContent>
      </Card>
      {!isEmpty(props.suggestedCarriers) && (
        <>
          <Typography className="label-suggested">
            Suggested Cross Sells
          </Typography>
          <div ref={suggestedCrossSellRef}>
          {props.suggestedCarriers.map(ele => (
            <Card
              key={ele?.carrier_code}
              className={`cross-sell-card ${
                props.carrierType?.suggestedCarriers?.active &&
                props.carrierType?.suggestedCarriers?.carrierName ===
                  ele?.carrier_code
                  ? 'black-card'
                  : 'white-card'
              }`}
              onClick={() => {
                handleCarrier(ele);
              }}
            >
              <CardContent className="p-0">
                <div className="d-flex align-items-center justify-content-between first-wrap">
                  <img src={ele?.logo_url} alt="logo" className="img-fluid" />
                  <Typography
                    className={`carrier-para ${
                      props?.carrierType?.suggestedCarriers?.carrierName ===
                      ele?.carrier_code
                        ? 'orange-lbl'
                        : ''
                    }`}
                  >
                    <span
                      className={`logo-span mr-6 ${
                        suggestedCSECarriers?.[ele?.carrier_code]
                          ?.carrierType === 'Warranty'
                          ? 'icon-home'
                          : suggestedCSECarriers?.[ele?.carrier_code]
                              ?.carrierType === 'Flood'
                          ? 'icon-flood-cse'
                          : 'icon-cyber'
                      }`}
                    ></span>
                    <span className="label-span">
                      {suggestedCSECarriers?.[ele?.carrier_code]?.carrierType}
                    </span>
                  </Typography>
                </div>
                <Typography className="carrier-label">
                  {suggestedCSECarriers?.[ele?.carrier_code]?.carrierLabel}
                </Typography>
                <div className="d-flex justify-content-between align-items-center">
                  <Typography className="purchase-lbl">
                    <span className="font-700">
                      {suggestedCSECarriers?.[ele?.carrier_code]?.purchase}
                    </span>
                    {suggestedCSECarriers?.[ele?.carrier_code]?.month}
                  </Typography>
                </div>
                {!isEmpty(
                  suggestedCSECarriers?.[ele?.carrier_code]?.commission
                ) && (
                  <Typography
                    className={`commision-label ${
                      props.carrierType?.suggestedCarriers?.carrierName ===
                      ele?.carrier_code
                        ? 'black'
                        : ''
                    }`}
                  >
                    Commission:
                    <span className="font-weight-bold">
                      {suggestedCSECarriers?.[ele?.carrier_code]?.commission}
                    </span>
                  </Typography>
                )}
                 <Button
                    color="default"
                    variant="outlined"
                    className="cross-sell-button"
                    hidden={
                      props.carrierType?.suggestedCarriers?.active &&
                      props.carrierType?.suggestedCarriers?.carrierName ===
                        ele?.carrier_code
                    }
                  >
                    {carrierStatus?.[ele?.carrier_code]}
                  </Button>
              </CardContent>
            </Card>
          ))}
          </div>
        </>
      )}

      <Dialog
        className="go-back-modal"
        fullWidth={true}
        maxWidth="xs"
        aria-labelledby="max-width-dialog-title"
        open={openBackModal}
        onClose={cancelBackModal}
      >
        <DialogContent className="divider">
          <HighlightOffIcon
            className="cse-close-icon"
            onClick={cancelBackModal}
          />
          <Typography className="go-back-lbl p-0">
            Go back to the dashboard?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Grid item xs={12} sm={12} className="d-flex justify-content-between">
            <Button className="cse-white-button" onClick={cancelBackModal}>
              <IntlMessages id="label.cancel" />
            </Button>
            <Button className="cse-grey-button" onClick={backToDashboard}>
              <IntlMessages id="button.yes" />{' '}
            </Button>
          </Grid>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const mapStateToProps = ({ dashboard }) => {
  const { suggestedCarriers, carrierType, currentCarrier } = dashboard;
  return { suggestedCarriers, carrierType, currentCarrier };
};

export default connect(mapStateToProps, {
  setCrossSellDetails,
  setCarrierType,
  setClickOnCrossSell,

})(CrossSellSideNav);
