import * as advanceSearchActions from '../../constants/action-types';

export const fetchAdvanceSearchFields = () => ({
  type: advanceSearchActions.FETCH_ADVANCE_SEARCH_FIELDS,
});

export const advanceSearchFieldsLoader = payload => ({
  type: advanceSearchActions.ADVANCE_SEARCH_FIELDS_LOADER,
  payload,
});

export const setAdvanceSearchFields = payload => ({
  type: advanceSearchActions.SET_ADVANCE_SEARCH_FIELDS,
  payload,
});