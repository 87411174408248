// @ts-check

import {
  CardActions,
  CardContent,
  Checkbox,
  Input,
  Tab,
  Tabs,
  Typography,
} from '@material-ui/core';
import cx from 'classnames';
import PhoneMask from 'components/phone-mask';
import SubmitLoader from 'components/submit-loader';
import { PORTAL } from 'constants/heap-events';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { userSignIn } from 'redux/actions';
import IntlMessages from 'util/intl-messages';
import {
  useClearErrorsOnTabChangeEffect,
  useRememberMeValue,
} from './passwordless-sign-in-form.hooks';
import { useStyles } from './passwordless-sign-in-form.styles';
import {
  getDefaultPasswordlessSignInputVal,
  getDefultPasswordlessFormTab,
  PWDLESS_SIGNIN_DEFAUlT_SELECTED_TAB_KEY,
  storeDefaultPasswordlessSignInputVal,
  validateEmail,
  validatePhoneNumber,
} from './passwordless-sign-in-form.utils';

/**
 *
 * @param {Object} props
 * @param {() => void} props.redirectToSignUp
 * @param {string} [props.prefilledEmail]
 * @param {boolean} [props.isReauthenticate]
 * @returns
 */
export default function PasswordlessSignInForm({
  redirectToSignUp,
  prefilledEmail,
  isReauthenticate = false,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [selectedTab, setSelectedTab] = useState(
    getDefultPasswordlessFormTab()
  );
  const [emailVal, setEmailVal] = useState(
    prefilledEmail || getDefaultPasswordlessSignInputVal('email')
  );
  const [phoneNumberVal, setPhoneNumberVal] = useState(
    getDefaultPasswordlessSignInputVal('phone')
  );
  const [inputError, setInputError] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [rememberMe, setRememberMe] = useRememberMeValue();

  useClearErrorsOnTabChangeEffect({ setInputError, selectedTab });

  useEffect(() => {
    window.heap?.track(PORTAL.AUTH_FLOW_VIEW_LOGIN_PAGE, {
      'Auto logout': isReauthenticate,
    });
  }, []);

  const handleSelectTab = (tab) => {
    setSelectedTab(tab);
    if (tab) {
      localStorage.setItem(PWDLESS_SIGNIN_DEFAUlT_SELECTED_TAB_KEY, tab);
    }
  };

  // Handlers

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    if (selectedTab === 'email') {
      const trimmerdEmail = emailVal.trim();
      const emailError = validateEmail(trimmerdEmail);
      if (emailError) {
        setInputError(emailError);
        setIsSubmitting(false);
        return;
      }
      storeDefaultPasswordlessSignInputVal(trimmerdEmail, 'email');
      dispatch(userSignIn({ email: trimmerdEmail }));
    }

    if (selectedTab === 'phone') {
      const trimmedPhone = phoneNumberVal.trim();
      const phoneError = validatePhoneNumber(trimmedPhone);
      if (phoneError) {
        setInputError(phoneError);
        setIsSubmitting(false);
        return;
      }
      storeDefaultPasswordlessSignInputVal(trimmedPhone, 'phone');
      dispatch(userSignIn({ phoneNumber: trimmedPhone }));
    }
  };

  const isSubmitEnabled = () => {
    if (selectedTab === 'email') {
      return emailVal.trim().length > 0;
    }
    if (selectedTab === 'phone') {
      return phoneNumberVal.trim().length > 0;
    }
    return false;
  };

  return (
    <form className="form-width" onSubmit={handleSubmit}>
      <div className="form-card">
        <CardContent className={cx(['form-card-content', classes.container])}>
          <div className={cx(['mx-auto', classes.content])}>
            <div className="heading">
              <Typography
                className={cx([classes.typographyBase, classes.headerText])}
              >
                <IntlMessages id="label.login" />
              </Typography>
              <Typography
                className={cx([
                  classes.typographyBase,
                  classes.passwordlessPromptText,
                ])}
              >
                Enter the email or phone number associated with your account to
                receive a verification code.
              </Typography>
            </div>

            <Tabs
              value={selectedTab}
              onChange={(_, newVal) => handleSelectTab(newVal)}
              TabIndicatorProps={{ color: '#FF9A14' }}
              classes={{ flexContainer: classes.tabs }}
            >
              <Tab
                value="email"
                label="Email"
                classes={{ root: classes.tab, selected: classes.tabActive }}
              />
              <Tab
                value="phone"
                label="Text"
                classes={{ root: classes.tab, selected: classes.tabActive }}
              />
            </Tabs>
            <div className={cx([classes.inputContainer, 'input-field'])}>
              {selectedTab === 'email' && (
                <>
                  <input
                    id="email"
                    value={emailVal}
                    type="text"
                    className="fs-exclude validate"
                    maxLength={50}
                    onChange={(event) => {
                      setEmailVal(event.target.value);
                    }}
                    onBlur={() => {
                      setEmailVal((val) => val.trim());
                    }}
                    disabled={isSubmitting}
                  />
                  <label
                    htmlFor="email"
                    className={cx([classes.inputLabel, 'active'])}
                  >
                    <IntlMessages id="label.email" />
                    <span className="required">*</span>
                  </label>
                  <div className="fieldError">{inputError}</div>
                  <span
                    className={cx([
                      classes.typographyBase,
                      classes.inputSecondaryText,
                    ])}
                  >
                    Please be sure to enter the email associated with your
                    account
                  </span>
                </>
              )}
              {selectedTab === 'phone' && (
                <>
                  <Input
                    id="phone_number"
                    value={phoneNumberVal}
                    type="text"
                    fullWidth
                    inputComponent={PhoneMask}
                    className="fs-exclude validate"
                    onChange={(event) => {
                      setPhoneNumberVal(event.target.value);
                    }}
                    onBlur={() => {
                      setPhoneNumberVal((val) => val.trim());
                    }}
                    disabled={isSubmitting}
                  />
                  <label
                    htmlFor="phone_number"
                    className={cx([classes.inputLabel, 'active'])}
                  >
                    <IntlMessages id="label.phone" />
                    <span className="required">*</span>
                  </label>
                  <div className="fieldError">{inputError}</div>
                  <span
                    className={cx([
                      classes.typographyBase,
                      classes.inputSecondaryText,
                    ])}
                  >
                    Please be sure to enter the text-enabled phone number
                    associated with your account
                  </span>
                </>
              )}
            </div>
            <div className={classes.rememberMeContainer}>
              <Checkbox
                classes={{
                  root: classes.muiCheckbox,
                  checked: classes.muiCheckboxChecked,
                }}
                checked={rememberMe}
                onChange={(event) => setRememberMe(event.target.checked)}
              />
              <span
                className={cx([classes.typographyBase, classes.rememberMeText])}
              >
                Remember me
              </span>
            </div>
          </div>
        </CardContent>
        <CardActions
          className={`p-0 login-button-wrap ${classes.loginButtonWrap}`}
        >
          <button
            className={`${isSubmitEnabled() ? '' : 'disable'} login-btn ${
              classes.loginButton
            }`}
            disabled={isSubmitting || !isSubmitEnabled()}
          >
            Get Code
            {isSubmitting && <SubmitLoader className="ml-1" />}
          </button>

          <Typography
            className={`signup-text-label ${classes.signupTextLabel}`}
          >
            Not on First Connect? <br />
            <span className="resp-d-block">
              <span
                className="text-orange padding-left-1"
                onClick={redirectToSignUp}
              >
                Sign up
              </span>{' '}
              to start your application.
            </span>
          </Typography>
        </CardActions>
      </div>
    </form>
  );
}
