import DancingDots from 'components/dancing-dots';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { validateRequiredStateData } from 'redux/actions/refresh-state';

/**
 * Utility route used to handle missing/inclomplete persisted state in dual app setup.
 * If authenticated (by extension, state persisted in sessionStorage), navigates to provided URL.
 * @FIXME: This is temporary and non ideal solution for state sharing issues. Proper one is needed.
 */
const RefreshState = (props) => {
  useEffect(() => {
    props.validateRequiredStateData();
  }, []);

  return (
    <div style={{ height: '100%', width: '100%' }}>
      <DancingDots fullpageWrapper />
    </div>
  );
};

export default connect(null, { validateRequiredStateData })(RefreshState);
