import { all, call, fork, put, select, takeEvery } from 'redux-saga/effects';
import { displayErrorMessage, displaySuccessMessage } from 'util/user-feedback';
import axios from 'util/api';
import * as incentiveBanner from '../../constants/action-types';
import {
  incentiveFileUploadError,
  setIncentiveBannerList,
  setIncentiveBannerListLoader,
  setIncentiveBannerLoader,
  setIncentiveDetail,
  setIncentiveDetailLoader,
  setIncentiveFileUploadLoader,
  setNewIncentiveBanner,
  setSaveSuccess,
  setUpdatedIncentiveBanner,
} from '../actions/incentive-banner';
import { STATIC_ASSETS } from 'constants/constant';
import { errorHandler } from '../actions/error';
import { fileTypeCheck, uploadMultipleFile } from '../../util/extra';
import { ALIGN_TYPE } from '../../components/incentive-banner/incentive-banner-constant';
import { isEmpty } from 'lodash';

// Get incentive banner List
function* fetchIncentiveBannerList() {
  try {
    yield put(setIncentiveBannerListLoader(true));
    const incentiveBannerListResponse = yield axios.get(
      '/api/v1/incentive-banners/list'
    );
    if (incentiveBannerListResponse) {
      const { data, status } = incentiveBannerListResponse;
      if (status === 200 && data?.success) {
        const { incentiveBanners } = data;
        yield put(setIncentiveBannerList(incentiveBanners));
      } else {
        yield put(setIncentiveBannerList([]));
      }
    }
  } catch (error) {
    if (!!error.response && error.response.status === 401) {
yield displayErrorMessage(error.response.data.message)
      yield put(errorHandler(error));
    } else {
      yield put(errorHandler(error));
    }
  }
  yield put(setIncentiveBannerListLoader(false));
}

export function* fetchIncentiveBannerListWatcher() {
  yield takeEvery(
    incentiveBanner.GET_INCENTIVE_BANNER_LIST,
    fetchIncentiveBannerList
  );
}
const createUrl = (fileData, folder, type) => {
  const folderName = STATIC_ASSETS;
  const file = fileData[0];
  const name = file.name;
  return `${folderName}/${folder}/${type}/${name}`;
};

// Add new incentive banner
function* addNewIncentiveBanner({ payload }) {
  let messageResponse = {
    success: true,
    file: null,
    messageType: null,
  };
  try {
    yield put(setIncentiveFileUploadLoader(true));
    yield put(incentiveFileUploadError(messageResponse));
    const { mainImageFile, animationFile, producerListFile } = payload;
    let files = [];
    let filePath = [];
    const reduxState = yield select();
    let incentiveList = reduxState.incentiveBanner.incentiveDetails;
    if (!isEmpty(mainImageFile)) {
      const mainImageData = createUrl(
        mainImageFile,
        'incentive-banners',
        'main-image'
      );
      files.push(mainImageFile);
      filePath.push(mainImageData);
    }
    if (!isEmpty(animationFile)) {
      const animationUrlData = !!animationFile
        ? createUrl(animationFile, 'incentive-banners', 'animation')
        : '';
      files.push(animationFile);
      filePath.push(animationUrlData);
    }
    if (!isEmpty(producerListFile)) {
      const csvFileUrl = !!producerListFile
        ? createUrl(producerListFile, 'incentive-banners', 'producer-list')
        : '';
      files.push(producerListFile);
      filePath.push(csvFileUrl);
    }
    const fileResponse = yield call(
      uploadMultipleFile,
      files,
      JSON.stringify(filePath)
    );

    if (!!fileResponse && fileResponse.status === 200) {
      messageResponse.success = true;
      yield put(incentiveFileUploadError(messageResponse));
      yield put(setIncentiveBannerLoader(true));
      const { files: file_url } = fileResponse.data;
      let producerType = payload.incentiveBannerCriteria.producerType;
      let producerTypeValue =
        !!producerType && payload.targetBase === 'Dynamic'
          ? producerType?.map(obj => {
              return !!obj?.value ? obj?.value : obj;
            })
          : ['All'];
      let producerStatus = payload.incentiveBannerCriteria.producerStatus;
      let producerStatusValue =
        !!producerStatus && payload.targetBase === 'Dynamic'
          ? producerStatus?.map(obj => {
              return !!obj.value ? obj.value : obj;
            })
          : ['All'];
      let carrierAppointmentsRequired =
        payload.incentiveBannerCriteria.carrierAppointmentsRequired;
      let carrierAppointmentsRequiredValue =
        !!carrierAppointmentsRequired && payload.targetBase === 'Dynamic'
          ? carrierAppointmentsRequired.map(obj => {
              return !!obj.value ? obj.value : obj;
            })
          : null;
      let startDate = payload.startDate !== '-' ? payload.startDate : null;
      let endDate = payload.endDate !== '-' ? payload.endDate : null;

      const requestPayload = {
        name: payload.name,
        description: payload.description,
        status: payload.status,
        startDate: startDate,
        endDate: endDate,
        mainImageURL: file_url[0] ? file_url[0] : incentiveList?.mainImageURL,
        animationURL: file_url[1]
          ? !fileTypeCheck(file_url[1])
            ? file_url[1]
            : payload.id
            ? incentiveList?.animationURL
            : ''
          : '',
        animationAlignment: payload.animationAlignment || ALIGN_TYPE.TOP,
        ctaButtonIncluded: payload.ctaButtonIncluded,
        ctaButtonAlignment: payload.ctaButtonAlignment || ALIGN_TYPE.BOTTOM,
        ctaButtonURL: payload.ctaButtonURL,
        ctaButtonLabel: payload.ctaButtonLabel,
        ctaButtonLabelFont: payload.ctaButtonLabelFont,
        ctaButtonLabelColor: payload.ctaButtonLabelColor,
        ctaButtonBackgroundColor: payload.ctaButtonBackgroundColor,
        targetBase: payload.targetBase,
        producerListFileURL: file_url[2]
          ? file_url[2]
          : fileTypeCheck(file_url[1])
          ? file_url[1]
          : payload.id
          ? incentiveList?.producerListFileURL
          : '',
        incentiveBannerCriteria: {
          producerType: producerTypeValue,
          producerStatus: producerStatusValue,
          appointmentRequired:
            payload.incentiveBannerCriteria.appointedRequired,
          carrierAppointmentsRequired: carrierAppointmentsRequiredValue,
          excludedWholesaler: payload.incentiveBannerCriteria.excludeWholesaler,
          firstActivationValidationRequired:
            payload.incentiveBannerCriteria.firstActivationValidationRequired,
          firstActivationCondition:
            payload.incentiveBannerCriteria.firstActivationCondition,
          firstActivationDate:
            payload.incentiveBannerCriteria.firstActivationDate,
        },
      };

      let response = yield axios.post(
        'api/v1/incentive-banners',
        requestPayload
      );

      if (response.data.success) {
        const { data } = response.data;
        yield put(setNewIncentiveBanner(data));
        yield displaySuccessMessage(
          'Incentive Banner Saved Successfully.'
        );
        yield put(setSaveSuccess(true));
      } else {
        yield displayErrorMessage(response.data.message);
      }
    } else {
      yield put(errorHandler(fileResponse));
    }
  } catch (error) {
    if (
      !!error?.response &&
      error?.response?.status === 412 &&
      error?.response?.data?.messageType === 'errorProducerList'
    ) {
      messageResponse.success = false;
      messageResponse.file = error.response.data.file;
      messageResponse.messageType = 'errorProducerList';
      yield displayErrorMessage(
        'Errors with Producers list file, please check the error file.'
      );
      yield put(setSaveSuccess(false));
    } else {
      messageResponse.success = false;
      messageResponse.file = null;
      messageResponse.messageType = null;
      yield put(errorHandler(error));
    }
  }
  yield put(setIncentiveBannerLoader(false));
  yield put(setIncentiveFileUploadLoader(false));
}

export function* addNewIncentiveBannerWatcher() {
  yield takeEvery(
    incentiveBanner.ADD_NEW_INCENTIVE_BANNER,
    addNewIncentiveBanner
  );
}

// Update existing incentive banner
function* updateIncentiveBanner({ payload }) {
  let messageResponse = {
    success: true,
    file: null,
    messageType: null,
  };
  try {
    const { mainImageFile, animationFile, producerListFile } = payload;
    yield put(setIncentiveFileUploadLoader(true));
    yield put(incentiveFileUploadError(messageResponse));
    yield put(setIncentiveBannerLoader(true));
    const reduxState = yield select();
    let incentiveList = reduxState.incentiveBanner.incentiveDetails;
    let files = [];
    let filePath = [];
    let updatedUrl = [];
    let imageArray = [
      {
        type: 'main-image',
        url: Array.isArray(mainImageFile) ? mainImageFile[0] : mainImageFile,
      },
      {
        type: 'animation',
        url: Array.isArray(animationFile) ? animationFile[0] : animationFile,
      },
      {
        type: 'producer-list',
        url: Array.isArray(producerListFile)
          ? producerListFile[0]
          : producerListFile,
      },
    ];
    let isFileUploadErrorPresent = false;
    if (!isEmpty(mainImageFile)) {
      const mainImageUrl = createUrl(
        mainImageFile,
        'incentive-banners',
        'main-image'
      );
      updatedUrl.push({
        type: 'main-image',
        url: mainImageUrl,
        img: mainImageFile,
      });
    }
    if (!isEmpty(animationFile)) {
      const animationUrl = createUrl(
        animationFile,
        'incentive-banners',
        'animation'
      );
      updatedUrl.push({
        type: 'animation',
        url: animationUrl,
        img: animationFile,
      });
    }
    if (!isEmpty(producerListFile)) {
      const csvFileUrl = createUrl(
        producerListFile,
        'incentive-banners',
        'producer-list'
      );
      updatedUrl.push({
        type: 'producer-list',
        url: csvFileUrl,
        img: producerListFile,
      });
    }
    let fileResponse = '';
    updatedUrl.forEach(ele => {
      filePath.push(ele.url);
      files.push(ele.img);
    });
    if (!!updatedUrl && !isEmpty(updatedUrl)) {
      fileResponse = yield call(
        uploadMultipleFile,
        files,
        JSON.stringify(filePath)
      );
      if (fileResponse && fileResponse.status === 200) {
        messageResponse.success = true;
        yield put(incentiveFileUploadError(messageResponse));

        isFileUploadErrorPresent = false;
        const { files: file_url } = fileResponse.data;
        file_url.forEach((ele, indx) => {
          updatedUrl[indx].url = ele;
        });
        let updatedFileArray = imageArray.map(function(o1) {
          return updatedUrl.find(function(o2) {
            return o1.type === o2.type;
          });
        });
        imageArray = updatedFileArray;
      } else {
        isFileUploadErrorPresent = true;
        yield put(errorHandler(fileResponse));
      }
      yield put(setIncentiveFileUploadLoader(false));
    }
    if (!isFileUploadErrorPresent) {
      let producerType = payload.incentiveBannerCriteria.producerType;
      let producerTypeValue =
        !!producerType && payload.targetBase === 'Dynamic'
          ? producerType.map(obj => {
              return !!obj?.value ? obj?.value : obj;
            })
          : ['All'];
      let producerStatus = payload.incentiveBannerCriteria.producerStatus;
      let producerStatusValue =
        !!producerStatus && payload.targetBase === 'Dynamic'
          ? producerStatus.map(obj => {
              return !!obj?.value ? obj?.value : obj;
            })
          : ['All'];
      let carrierAppointmentsRequired =
        payload.incentiveBannerCriteria.carrierAppointmentsRequired;
      let carrierAppointmentsRequiredValue =
        !!carrierAppointmentsRequired && payload.targetBase === 'Dynamic'
          ? carrierAppointmentsRequired.map(obj => {
              return !!obj?.value ? obj?.value : obj;
            })
          : null;
      let startDate = payload.startDate !== '-' ? payload.startDate : null;
      let endDate = payload.endDate !== '-' ? payload.endDate : null;

      let response = yield axios.put(
        `api/v1/incentive-banners/${payload?.id}`,
        {
          name: payload.name,
          description: payload.description,
          status: payload.status,
          startDate: startDate,
          endDate: endDate,
          mainImageURL: imageArray[0]?.url
            ? imageArray[0]?.url
            : incentiveList?.mainImageURL,
          animationURL: imageArray[1]?.url
            ? imageArray[1]?.url
            : incentiveList?.animationURL,
          animationAlignment: !isEmpty(payload.animationAlignment)
            ? payload.animationAlignment
            : 'Top',
          ctaButtonIncluded: payload.ctaButtonIncluded,
          ctaButtonAlignment: payload.ctaButtonAlignment,
          ctaButtonURL: payload.ctaButtonURL,
          ctaButtonLabel: payload.ctaButtonLabel,
          ctaButtonLabelFont: payload.ctaButtonLabelFont,
          ctaButtonLabelColor: payload.ctaButtonLabelColor,
          ctaButtonBackgroundColor: payload.ctaButtonBackgroundColor,
          targetBase: payload.targetBase,
          producerListFileURL: imageArray[2]?.url
            ? imageArray[2]?.url
            : incentiveList?.producerListFileURL,
          incentiveBannerCriteria: {
            id: payload.incentiveBannerCriteria.id,
            producerType: producerTypeValue,
            producerStatus: producerStatusValue,
            appointmentRequired:
              payload.incentiveBannerCriteria.appointedRequired,
            carrierAppointmentsRequired: carrierAppointmentsRequiredValue,
            excludedWholesaler:
              payload.incentiveBannerCriteria.excludeWholesaler,
            firstActivationValidationRequired:
              payload.incentiveBannerCriteria.firstActivationValidationRequired,
            firstActivationCondition:
              payload.incentiveBannerCriteria.firstActivationCondition,
            firstActivationDate:
              payload.incentiveBannerCriteria.firstActivationDate,
          },
        }
      );
      if (
        !!response?.status &&
        response.status === 200 &&
        response.data.success
      ) {
        yield put(setUpdatedIncentiveBanner(response.data));
        yield displaySuccessMessage(
          'Incentive Banner Saved Successfully.'
        );
        yield put(setSaveSuccess(true));
      } else {
        yield displayErrorMessage(response.data.message);
      }
      yield put(setIncentiveBannerLoader(false));
    }
  } catch (error) {
    if (
      !!error?.response &&
      error?.response?.status === 412 &&
      error?.response?.data?.messageType === 'errorProducerList'
    ) {
      messageResponse.success = false;
      messageResponse.file = error.response.data.file;
      messageResponse.messageType = 'errorProducerList';
      yield displayErrorMessage(
        'Errors with Producers list file, please check the error file.'
      );
      yield put(setSaveSuccess(false));
    } else {
      messageResponse.success = false;
      messageResponse.file = null;
      messageResponse.messageType = null;
      yield put(errorHandler(error));
    }
    yield put(incentiveFileUploadError(messageResponse));
  }

  yield put(setIncentiveBannerLoader(false));
  yield put(setIncentiveFileUploadLoader(false));
}
export function* updateIncentiveBannerWatcher() {
  yield takeEvery(
    incentiveBanner.UPDATE_INCENTIVE_BANNER,
    updateIncentiveBanner
  );
}
// fetch details of a particular banner
function* fetchIncentiveDetails({ payload }) {
  try {
    yield put(setIncentiveDetailLoader(true));
    const response = yield axios.get(`/api/v1/incentive-banners/${payload}`);
    if (response.status === 200 && response.data) {
      yield put(setIncentiveDetail(response.data.incentiveBanner));
    }
    yield put(setIncentiveDetailLoader(false));
  } catch (error) {
    if (!!error.response && error.response.status === 401) {
yield displayErrorMessage(error.response.data.message)
      yield put(errorHandler(error));
    } else {
      yield put(errorHandler(error));
    }
  }
  yield put(setIncentiveDetailLoader(false));
}

export function* fetchIncentiveDetailsWatcher() {
  yield takeEvery(incentiveBanner.GET_INCENTIVE_DETAILS, fetchIncentiveDetails);
}

// ------ ROOT SAGA -----------------
export default function* rootSaga() {
  yield all([
    fork(fetchIncentiveBannerListWatcher),
    fork(addNewIncentiveBannerWatcher),
    fork(updateIncentiveBannerWatcher),
    fork(fetchIncentiveDetailsWatcher),
  ]);
}
