import * as types from '../../constants/action-types';

const initState = () => ({
  categoryList: null,
  categoryListLoader: false,
  editRolePermissionLoader: false,
  editRolePermissionList: null,
  rolePermissionListLoader: false,
  rolePermissionList: null,
  responceMessage: null,
  roleDetails: null,
  success: null,
  isFetchFailed: false,
});

export default (state = initState(), action) => {
  switch (action.type) {
    case types.SET_ROLE_PERMISSION_LIST: {
      return {
        ...state,
        rolePermissionList: action.payload,
      };
    }
    case types.ROLE_PERMISSION_LIST_LOADER: {
      return {
        ...state,
        rolePermissionListLoader: action.payload,
      };
    }
    case types.SET_ROLE_EDIT_PERMISSION_LIST: {
      return {
        ...state,
        editRolePermissionList: action.payload.sortModules,
        roleDetails: action.payload.role_details,
      };
    }
    case types.ROLE_EDIT_PERMISSION_LIST_LOADER: {
      return {
        ...state,
        editRolePermissionLoader: action.payload,
      };
    }
    case types.UPDATE_ROLE_PERMISSION_LOADER: {
      return {
        ...state,
        editRolePermissionLoader: action.payload,
        success: null,
      };
    }
    case types.SET_UPDATED_ROLE_PERMISSION: {
      return {
        ...state,
        editRolePermissionLoader: false,
        editRolePermissionList: action.payload.updateEditRolePermissionList,
        success: action.payload.success,
      };
    }
    case types.CLEAR_ROLE_PERMISSION_LIST: {
      return {
        ...state,
        rolePermissionList: null,
        editRolePermissionList: null,
      };
    }
    case types.SET_NEW_ROLE: {
      return {
        ...state,
        rolePermissionList: [action.payload.role, ...state.rolePermissionList],
        responceMessage: action.payload.message,
      };
    }
    case types.CLEAR_RESPONCE_MESSAGE: {
      return {
        ...state,
        responceMessage: null,
      };
    }
    case types.SET_CATEGORY_LIST: {
      return {
        ...state,
        categoryList: action.payload,
      };
    }
    case types.CATEGORY_LIST_LOADER: {
      return {
        ...state,
        categoryListLoader: action.payload,
      };
    }
    case types.SET_IS_FETCH_FAILED: {
      return { ...state, isFetchFailed: action.payload };
    }
    default:
      return state;
  }
};
