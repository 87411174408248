import * as carrierOnboardActions from '../../constants/action-types';

export const fetchCarrierOnboardList = () => ({
  type: carrierOnboardActions.FETCH_CARRIER_ONBOARD_LIST,
});

export const setCarrierOnboardList = payload => ({
  type: carrierOnboardActions.SET_CARRIER_ONBOARD_LIST,
  payload,
});

export const setCarrierOnboardListLoader = payload => ({
  type: carrierOnboardActions.CARRIER_ONBOARD_LIST_LOADER,
  payload,
});

export const clearList = () => ({
  type: carrierOnboardActions.CLEAR_CARRIER_ONBOARD_LIST,
});

export const addNewCarrier = payload => ({
  type: carrierOnboardActions.ADD_NEW_CARRIER,
  payload,
});

export const setNewCarrier = payload => ({
  type: carrierOnboardActions.SET_NEW_CARRIER,
  payload,
});

export const setNewCarrierLoader = payload => ({
  type: carrierOnboardActions.ADD_NEW_CARRIER_LOADER,
  payload,
});

export const setNewProductLoader = payload => ({
  type: carrierOnboardActions.ADD_NEW_PRODUCT_LOADER,
  payload,
});

export const fetchCarrierProductLoader = payload => ({
  type: carrierOnboardActions.FETCH_CARRIER_PRODUCT_LOADER,
  payload,
});

export const fetchCarrierProductsList = () => ({
  type: carrierOnboardActions.FETCH_CARRIER_PRODUCTS_LIST,
});

export const setCarrierProducts = payload => ({
  type: carrierOnboardActions.SET_CARRIER_PRODUCTS_LIST,
  payload,
});

export const addCarrierProduct = payload => ({
  type: carrierOnboardActions.ADD_CARRIER_PRODUCT,
  payload,
});
