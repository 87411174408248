import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { displayErrorMessage, displaySuccessMessage } from 'util/user-feedback';
import axios from 'util/api';
import * as bulkAppointmentsActions from 'constants/action-types';
import { errorHandler } from '../actions/error';
import {
  bulkAppointmentFilesUploadFailed,
  bulkAppointmentFilesUploading,
  bulkAppointmentSuccess,
  setAppointment,
  setAppointments,
  setAppointmentsCurrentData,
  setAppointmentsLoader,
  setAppointmentsProcessing,
  setAppointmentsSuccess,
} from 'redux/actions/bulk-appointments';
import { uploadFile } from 'util/extra';

function* fetchAppointmentsListDetails() {
  try {
    yield put(setAppointmentsLoader(true));
    const appointments = yield axios.get(`/api/v1/bulk-appointments/list`);
    if (appointments.data) {
      const { bulkApointments, sampleInputFie } = appointments.data;

      yield put(
        setAppointments({
          bulkApointmentsList: bulkApointments,
          sampleInputFile: sampleInputFie,
        })
      );
    }
  } catch (error) {
    if (!!error.response && error.response.status === 401) {
yield displayErrorMessage(error.response.data.message)
      yield put(errorHandler(error));
    } else {
      yield put(errorHandler(error));
    }
  }
  yield put(setAppointmentsLoader(false));
}

export function* fetchAppointmentsWatcher() {
  yield takeEvery(
    bulkAppointmentsActions.FETCH_APPOINTMENTS,
    fetchAppointmentsListDetails
  );
}

function* bulkAppointmentUpload({ payload }) {
  const { carrierName: carrierId, file, id } = payload;

  try {
    const file_name = file.name;
    yield put(bulkAppointmentFilesUploading(true));
    let response;
    const fileURL = `bulk-appointments/input-files/${file_name}`;
    response = yield call(uploadFile, file, fileURL);
    if (response && response.status === 200) {
      const { file: fileURLData } = response.data;
      yield put(setAppointmentsCurrentData({fileUrl: fileURLData }));
      const updatedResponse = yield axios.post(
        '/api/v1/bulk-appointments/upload',
        {
          carrierId,
          inputFile: fileURLData,
        }
      );
      yield put(bulkAppointmentSuccess({...updatedResponse.data, id}));
      if (updatedResponse && response.status === 200) {
        yield put(setAppointment('success'));
        yield displaySuccessMessage(updatedResponse.data.message);
        yield put(setAppointmentsCurrentData({fileUrl: '' }));
      } else {
        yield put(setAppointmentsSuccess(false));
        yield put(setAppointment('unsuccess'));
        yield put(bulkAppointmentSuccess({id, type: 'error'}));
        yield displayErrorMessage(updatedResponse.data.message);
      }
    } else {
      yield put(setAppointmentsProcessing(false));
      yield put(bulkAppointmentSuccess({id, type: 'error'}));
      yield put(bulkAppointmentFilesUploadFailed());
      yield put(setAppointment('unsuccess'));
      yield displayErrorMessage(response.message);
    }
  } catch (error) {
    yield put(errorHandler(error));
    yield put(bulkAppointmentSuccess({id, type: 'error'}));
    yield put(setAppointmentsProcessing(false));
  }
}

export function* bulkAppointmentUploadWatcher() {
  yield takeEvery(
    bulkAppointmentsActions.BULK_APPOINTMENT_UPDATE,
    bulkAppointmentUpload
  );
}

// ------ ROOT SAGA -----------------
export default function* rootSaga() {
  yield all([fork(fetchAppointmentsWatcher)]);
  yield all([fork(bulkAppointmentUploadWatcher)]);
}
