import * as types from '../../constants/action-types';

const initState = () => ({
  classCodesLoader: false,
  codeList: [],
  carriers: [],
  isSuccess: false,
  selectedLobListData: localStorage.getItem('selectedLobListData')
    ? [localStorage.getItem('selectedLobListData')]
    : [],
});

export default (state = initState(), action) => {
  switch (action.type) {
    case types.SET_CLASS_CODES: {
      return {
        ...state,
        codeList: [...action.payload],
      };
    }
    case types.SET_SELECTED_LOB_LIST: {
      return {
        ...state,
        selectedLobListData: action.payload,
      };
    }
    case types.CLASS_CODES_LOADER: {
      return {
        ...state,
        classCodesLoader: action.payload,
      };
    }
    case types.FETCH_CLASS_CARRIERS: {
      return {
        carriers: [...action.payload],
      };
    }
    case types.UPDATE_CLASS_CODE_DETAILS: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case types.CLEAR_CLASS_CODES_DETAILS: {
      return {
        ...state,
        ...initState(),
      };
    }
    default:
      return state;
  }
};
