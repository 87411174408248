import * as incentiveBanner from '../../constants/action-types';

// Get/Set admin users List
export const getIncentiveBannerList = () => {
  return {
    type: incentiveBanner.GET_INCENTIVE_BANNER_LIST,
  };
};

export const setIncentiveBannerListLoader = payload => {
  return {
    type: incentiveBanner.SET_INCENTIVE_BANNER_LIST_LOADER,
    payload,
  };
};

export const setIncentiveBannerList = payload => {
  return {
    type: incentiveBanner.SET_INCENTIVE_BANNER_LIST,
    payload,
  };
};

// fetch/set incentive detail
export const fetchIncentiveDetails = payload => {
  return {
    type: incentiveBanner.GET_INCENTIVE_DETAILS,
    payload,
  };
};

export const setIncentiveDetailLoader = payload => {
  return {
    type: incentiveBanner.SET_INCENTIVE_DETAIL_LOADER,
    payload,
  };
};

export const setIncentiveDetail = payload => {
  return {
    type: incentiveBanner.SET_INCENTIVE_DETAILS,
    payload,
  };
};

// add-edit incentives
export const addIncentiveBannerUser = payload => {
  return {
    type: incentiveBanner.ADD_NEW_INCENTIVE_BANNER,
    payload,
  };
};

export const setIncentiveBannerLoader = payload => {
  return {
    type: incentiveBanner.NEW_INCENTIVE_BANNER_LOADER,
    payload,
  };
};

export const setNewIncentiveBanner = payload => {
  return {
    type: incentiveBanner.SET_NEW_INCENTIVE_BANNER,
    payload,
  };
};

export const updateIncentiveBanner = payload => {
  return {
    type: incentiveBanner.UPDATE_INCENTIVE_BANNER,
    payload,
  };
};

export const setUpdatedIncentiveBanner = payload => {
  return {
    type: incentiveBanner.SET_UPDATED_INCENTIVE_BANNER,
    payload,
  };
};

export const setIncentiveFileUploadLoader = payload => {
  return {
    type: incentiveBanner.SET_INCENTIVE_FILE_UPLOAD_LOADER,
    payload,
  };
};

export const incentiveFileUploadError = payload => {
  return {
    type: incentiveBanner.SET_INCENTIVE_FILE_UPLOAD_ERROR,
    payload,
  };
};

export const setSaveSuccess = payload => {
  return {
    type: incentiveBanner.SET_SAVE_SUCCESS,
    payload,
  };
};

export const clearIncentiveDetails = () => {
  return { type: incentiveBanner.CLEAR_INCENTIVE_DETAILS };
};
