import * as carrierIncentive from '../../constants/action-types';

export const setCarrierIncentiveLoader = (payload) => ({
  type: carrierIncentive.CARRIER_INCENTIVE_LOADER,
  payload,
});

export const fetchCarrierIncentiveList = () => ({
  type: carrierIncentive.FETCH_INCENTIVE_LIST,
});

export const setCarrierIncentiveList = (payload) => ({
  type: carrierIncentive.SET_INCENTIVE_LIST,
  payload,
});

export const setCarrierIncentiveDetailLoader = (payload) => ({
  type: carrierIncentive.SET_CARRIER_INCENTIVE_DETAIL_LOADER,
  payload,
});

export const updateCarrierIncentiveDetails = (payload) => {
  return {
    type: carrierIncentive.SET_UPDATED_CARRIER_INCENTIVE,
    payload,
  };
};

export const addNewCarrierIncentive = (payload) => ({
  type: carrierIncentive.ADD_CARRIER_INCENTIVE,
  payload,
});

export const setNewCarrierIncentive = (payload) => ({
  type: carrierIncentive.SET_CARRIER_INCENTIVE,
  payload,
});

export const setIncentiveSubmitLoader = (payload) => ({
  type: carrierIncentive.SET_INCENTIVE_SUBMIT_LOADER,
  payload,
});
