import * as types from '../../constants/action-types';

const INIT_STATE = () => ({
  incentiveBannerList: [],
  incentiveBannerListLoader: false,
  incentiveBannerLoader: false,
  incentiveDetails: {},
  incentiveDetailLoader: false,
  incentiveFileUploadLoader: false,
  saveSuccess: false,
  incentiveFileUploadError: {
    success: true,
    messageType: null,
    file: null,
  },
});

export default (state = INIT_STATE(), action) => {
  switch (action.type) {
    case types.INIT_URL: {
      return { ...state, initURL: action.payload };
    }

    case types.SET_INCENTIVE_BANNER_LIST: {
      return {
        ...state,
        incentiveBannerList: [...action.payload],
      };
    }

    case types.SET_INCENTIVE_BANNER_LIST_LOADER: {
      return {
        ...state,
        incentiveBannerListLoader: action.payload,
      };
    }

    case types.NEW_INCENTIVE_BANNER_LOADER: {
      return {
        ...state,
        incentiveBannerLoader: action.payload,
      };
    }
    case types.SET_INCENTIVE_FILE_UPLOAD_LOADER: {
      return {
        ...state,
        incentiveFileUploadLoader: action.payload,
      };
    }
    case types.SET_INCENTIVE_FILE_UPLOAD_ERROR: {
      return {
        ...state,
        incentiveFileUploadError: action.payload,
      };
    }
    case types.SET_NEW_INCENTIVE_BANNER: {
      return {
        ...state,
        incentiveBannerListLoader: false,
        incentiveBannerList: [action.payload, ...state.incentiveBannerList],
      };
    }

    case types.SET_UPDATED_INCENTIVE_BANNER: {
      return {
        ...state,
        incentiveBannerListLoader: false,
        incentiveBannerList: action.payload,
      };
    }

    case types.SET_INCENTIVE_DETAILS: {
      return {
        ...state,
        incentiveDetails: action.payload,
      };
    }
    case types.SET_INCENTIVE_DETAIL_LOADER: {
      return {
        ...state,
        incentiveDetailLoader: action.payload,
      };
    }
    case types.SET_SAVE_SUCCESS: {
      return {
        ...state,
        saveSuccess: action.payload,
      };
    }
    case types.CLEAR_INCENTIVE_DETAILS: {
      return {
        ...state,
        incentiveDetails: {},
        incentiveFileUploadError: {
          success: true,
          messageType: null,
          file: null,
        },
      };
    }
    default:
      return state;
  }
};
