import {
  SET_IMPERSONATE_LOADER,
  SET_IMPERSONATE_MODE,
} from '../../constants/action-types';

const initState = () => ({
  permission: null,
  producer: null,
  isImpersonate: false,
  loading: false,
});

export default (state = initState(), action) => {
  switch (action.type) {
    case SET_IMPERSONATE_MODE: {
      const { permissions, producer, isImpersonate } = action.payload;
      return {
        permissions: permissions,
        producer: producer,
        isImpersonate: isImpersonate,
      };
    }
    case SET_IMPERSONATE_LOADER: {
      return {
        ...state,
        loading: action.payload,
      };
    }
    default:
      return state;
  }
};
