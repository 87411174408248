/* Advance Search */
import { all, fork, put, takeEvery } from 'redux-saga/effects';
import axios from 'util/api';
import * as advanceSearchActions from 'constants/action-types';
import {
  advanceSearchFieldsLoader,
  setAdvanceSearchFields,
} from 'redux/actions/advance-search';
import { errorHandler } from '../actions/error';
import { PRODUCERS } from 'constants/constant';

export function* fetchAdvanceSearch() {
  try {
    yield put(advanceSearchFieldsLoader(true));
    const responseDetails = yield axios.post(
      `/api/v1/common/get/filter-parameters`,
      { entity_name: PRODUCERS }
    );
    if (responseDetails.status === 200) {
      const { fields } = responseDetails.data;
      yield put(setAdvanceSearchFields(fields));
    }
  } catch (error) {
    yield put(errorHandler(error));
  }
  yield put(advanceSearchFieldsLoader(false));
}

export function* fetchAdvanceSearchWatcher() {
  yield takeEvery(
    advanceSearchActions.FETCH_ADVANCE_SEARCH_FIELDS,
    fetchAdvanceSearch
  );
}

export default function* rootSaga() {
  yield all([fork(fetchAdvanceSearchWatcher)]);
}
