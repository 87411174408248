import * as leaderboard from '../../constants/action-types';

export const fetchLeaderBoardDetails = () => ({
  type: leaderboard.FETCH_LEADER_BOARD_DETAIL,
});

export const setLeaderBoardDetails = (payload) => ({
  type: leaderboard.SET_LEADER_BOARD_DETAIL,
  payload,
});

export const setShouldShowLeaderBoard = (payload) => ({
  type: leaderboard.SET_SHOULD_SHOW_LEADER_BOARD,
  payload,
});

export const setLeaderBoardDetailLoader = (payload) => ({
  type: leaderboard.SET_LEADER_BOARD_DETAIL_LOADER,
  payload,
});

export const fetchIncentiveLeaderBoardDetails = (payload) => ({
  type: leaderboard.FETCH_INCENTIVE_LEADER_BOARD_DETAIL,
  payload,
});

export const setIncentiveLeaderBoardDetails = (payload) => ({
  type: leaderboard.SET_INCENTIVE_LEADER_BOARD_DETAIL,
  payload,
});
