// @ts-check

import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => {
  return {
    rootContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      padding: 16,
      gap: 16,
      borderRadius: 8,
      background:
        'linear-gradient(90.87deg, #FFEADB 0%, #FFFFFF 56.14%, #FFFFFF 99.64%)',
      margin: 16,
      marginBottom: 64,
      alignSelf: 'center',
      maxWidth: 830,
      width: 'min(830px, calc(100% - 32px))',
    },
    disclaimerContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      gap: 4,
      flex: '1 0 0',
    },
    disclaimerPrimary: {
      fontWeight: 600,
      fontSize: 16,
      lineHeight: '140%',
      color: '#525252',
    },
    disclaimerSecondary: {
      alignSelf: 'stretch',
      color: '#525252',
      fontSize: 14,
      fontWeight: 400,
      lineHeight: '140%',
    },
  };
});

/**
 * @returns {JSX.Element}
 */
function SignInInactivityReauthenticateBanner() {
  const classes = useStyles();
  return (
    <div className={classes.rootContainer}>
      <div className={classes.disclaimerContainer}>
        <span className={classes.disclaimerPrimary}>Please Log In Again</span>
        <span className={classes.disclaimerSecondary}>
          For your security, you have been logged out due to inactivity. Please
          log in again.
        </span>
      </div>
    </div>
  );
}

export default SignInInactivityReauthenticateBanner;
