// @ts-check

import { useEffect, useState } from 'react';

import legacyAxios from 'util/api';

/**
 * @returns {{isSuccess: boolean, isLoading: boolean, error: unknown}}
 */
export const useIsSSOLegacy = () => {
    const [isSuccess, setIsSuccess] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState();

    useEffect(() => {
        async function fetchData() {
            setIsLoading(true);
            setError(undefined);

            try {
                const res = await legacyAxios.get(`/api/v3/admin/me`);
                const isSSO = res.data['hippoSSO'] === true;

                setIsSuccess(isSSO);
            } catch(e) {
                // eslint-disable-next-line no-console
                console.error("Legacy isSSO check failed: ", e);

                setIsSuccess(false);
                setError(e);
            } finally {
                setIsLoading(false);
            }
        }

        fetchData();
    }, []);

    return { isSuccess, isLoading, error };
  };
