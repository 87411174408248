import React, { Fragment } from 'react';
import { Checkbox, Grid, Input } from '@material-ui/core';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import FeinMask from 'components/fein-mask';
import HtmlTooltip from 'components/html-tooltip';
import SsnMask from 'components/ssn-mask';
import SubmitLoader from 'components/submit-loader';
import { ToggleSwitch } from 'components/toggle-switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import SearchLocationInput from 'components/search-location-input';
import { isEmpty } from 'lodash';
import classnames from 'classnames';
import CardActions from '@material-ui/core/CardActions';
import {
  ADDRESS,
  AGENCY_FEIN,
  ALTERNATE_SUB_PRODUCER,
  BUTTON_TYPE,
  CITY,
  FEIN_ID,
  FINISH,
  INDIVIDUAL,
  INVALID,
  NEXT,
  NO,
  POST_SIGNUP_STEPS_NAME,
  PRODUCER_KEY,
  PRODUCERS,
  REQUIRED,
  SAME_ADDRESS,
  SELECT_KEY,
  SINGLE_MEMBER_LLC,
  SMALL,
  SSN_ID,
  STATE,
  SUBMIT,
  TAX_INFO_CONTENT,
  TAX_STATUS,
  TAX_STATUS_INFO_CONTENT,
  TAX_STATUS_KEY,
  TOP,
  TYPE_TEXT,
  W9,
  YES,
  ZIP_CODE,
} from 'constants/constant';
import { APPLICATION } from 'constants/heap-events';
import { connect } from 'react-redux';
import {
  setCurrentStepIndex,
  setDropboxLoader,
  stepSubmit,
  stepSubmitSuccess,
  submitLoaderHide,
  updateW9FormStatus,
} from 'redux/actions/post-sign-up';
import { checkBoxText, statusOption } from 'util/extra';
import IntlMessages from 'util/intl-messages';
import { stateName } from 'util/sign-up-process';
import dropboxSignClient, { TEMPLATE_NAME } from 'util/DropboxSign';
import FormInput from 'components/form-input';
import { blurHandlers, changeHandlers, validators } from 'util/form-handler';
import './step-3.scss';
import { W9Modal } from './W9Modal';
import { onSSNChange, onSSNKeyPress } from './step-3-helpers';
let originalSSNValue = '';
let hiddenSSNValue = '';
let show = false;
const initState = {
  isModalOpen: false,
  fieldsModified: false,
  showSSN: false,
  formValues: {
    address: '',
    city: '',
    FEIN: '',
    FEINNotPresent: false,
    registeredAddMailingAdd: YES.toLowerCase(),
    SSN: '',
    state: '',
    taxStatus: '',
    zipCode: '',
  },
  formErrors: {
    address: '',
    city: '',
    FEIN: '',
    registeredAddMailingAdd: '',
    SSN: '',
    state: '',
    taxStatus: '',
    zipCode: '',
  },
};

class Step3 extends React.Component {
  constructor(props) {
    super(props);
    this.state = initState;
    this.createRef();
    this.errorField = null;
  }

  componentDidMount = () => {
    const { tax, submitLoader } = this.props;
    if (tax.registeredAddMailingAdd === NO.toLowerCase() && !!tax.address) {
      this.defaultAddress = {
        address: tax.address,
        city: tax.city,
        state: tax.state,
        zipCode: tax.zipCode,
      };
    } else {
      this.defaultAddress = {
        address: '',
        city: '',
        state: '',
        zipCode: '',
      };
    }
    this.setState({
      formValues: {
        ...this.state.formValues,
        ...tax,
        FEIN: tax.FEIN ? this.adjustMaskLength(tax.FEIN) : '',
      },
    });
    originalSSNValue = tax.SSN;
    hiddenSSNValue = tax.SSN.replaceAll(/[0-9]/g, '*');
    show = false;
    this.taxStatusInput.focus();
    submitLoader && this.props.submitLoaderHide();
  };

  componentDidUpdate = (prevProps) => {
    const { isErrorPresent, submitLoader } = this.props;
    if (
      prevProps.submitLoader !== submitLoader &&
      !submitLoader &&
      isErrorPresent
    ) {
      // eslint-disable-next-line
      this.setState({
        fieldsModified: true,
        formValues: { ...this.state.formValues, fileName: '', file: null },
      });
    }
  };

  adjustMaskLength = (val) => {
    if (val.includes('*') & (val.length > 9)) {
      // SSN, FEIN have 9 digits
      return `*****${val.slice(-4)}`;
    }
    return val;
  };
  createRef() {
    this.refList = {
      taxStatus: React.createRef(),
      FEIN: React.createRef(),
      SSN: React.createRef(),
      address: React.createRef(),
      city: React.createRef(),
      state: React.createRef(),
      zipCode: React.createRef(),
    };
  }

  updateAddress = (notModify) => {
    const useMailAddress =
      this.state.formValues.registeredAddMailingAdd === YES.toLowerCase();
    const { agencyProfile, tax } = this.props;
    let updatedAddress = {};
    if (useMailAddress) {
      if (agencyProfile.isMailingAddressSame === NO.toLowerCase()) {
        updatedAddress = {
          address: agencyProfile.mailingAddress,
          city: agencyProfile.mailingCity,
          state: agencyProfile.mailingState,
          zipCode: agencyProfile.mailingZipCode,
        };
      } else {
        updatedAddress = {
          address: agencyProfile.address,
          city: agencyProfile.city,
          state: agencyProfile.state,
          zipCode: agencyProfile.zipCode,
        };
      }
    } else if (agencyProfile.address === tax.address && !notModify) {
      updatedAddress = {
        address: '',
        city: '',
        state: '',
        zipCode: '',
      };
    }
    this.setState({
      fieldsModified: !notModify,
      formValues: {
        ...this.state.formValues,
        ...updatedAddress,
      },
    });
  };
  checkBoxChecked = () => {
    const { formValues } = this.state;
    this.setState({
      fieldsModified: true,
      formValues: {
        ...formValues,
        FEIN: '',
        FEINNotPresent: !formValues.FEINNotPresent,
      },
      formErrors: {
        ...this.state.formErrors,
        FEIN: '',
      },
    });
  };

  validators = {
    taxStatus: [validators.selectRequired],
    FEIN: [
      (value) => {
        const { signupFlow, productionAccess } = this.props;
        if (
          (signupFlow === PRODUCER_KEY ||
            (signupFlow === ALTERNATE_SUB_PRODUCER && productionAccess)) &&
          !this.state.formValues.FEINNotPresent
        ) {
          return validators.required(value) || validators.FEINInvalid(value);
        }
        return '';
      },
    ],
    SSN: [
      (value) => {
        const { signupFlow, productionAccess } = this.props;
        if (
          signupFlow === PRODUCER_KEY ||
          (signupFlow === ALTERNATE_SUB_PRODUCER && productionAccess)
        ) {
          return validators.required(value) || validators.SSNInvalid(value);
        }
        return '';
      },
    ],
    registeredAddMailingAdd: [validators.required],
    address: [
      (value) =>
        this.state?.formValues?.registeredAddMailingAdd === NO.toLowerCase()
          ? validators.required(value, REQUIRED)
          : '',
    ],
    city: [
      (value) =>
        this.state?.formValues?.registeredAddMailingAdd === NO.toLowerCase()
          ? validators.required(value, REQUIRED)
          : '',
    ],
    state: [
      (value) =>
        this.state?.formValues?.registeredAddMailingAdd === NO.toLowerCase()
          ? validators.selectRequired(value, REQUIRED)
          : '',
    ],
    zipCode: [
      () =>
        this.state?.formValues?.registeredAddMailingAdd === NO.toLowerCase()
          ? validators.zipCodeInvalid(this.state?.formValues?.zipCode, INVALID)
          : '',
      () =>
        this.state?.formValues?.registeredAddMailingAdd === NO.toLowerCase()
          ? validators.required(this?.state?.formValues?.zipCode, REQUIRED)
          : '',
    ],
  };

  validate = () => {
    const { formValues, formErrors } = this.state;
    let isInvalid = false;
    const newErrors = { ...formErrors };
    for (const [key, validatorList] of Object.entries(this.validators)) {
      for (const validator of validatorList) {
        const error = validator(formValues[key]);
        newErrors[key] = error;
        if (error) {
          isInvalid = true;

          if (!this.errorField) {
            this.errorField = key;
          }

          break;
        }
      }
    }

    this.setState({ formErrors: newErrors });

    return !isInvalid;
  };

  toggleModal = () => {
    this.setState((prev) => ({
      isModalOpen: !prev.isModalOpen,
    }));
  };

  submitForm = (event) => {
    event.preventDefault();
    this.updateAddress(true);

    const { signupFlow, agencyProfile } = this.props;
    const { formValues, fieldsModified } = this.state;

    if (formValues.registeredAddMailingAdd === YES.toLowerCase()) {
      this.defaultAddress = {
        address: agencyProfile.address,
        city: agencyProfile.city,
        state: agencyProfile.state,
        zipCode: agencyProfile.zipCode,
      };
    } else {
      this.defaultAddress = {
        address: formValues.address,
        city: formValues.city,
        state: formValues.state,
        zipCode: formValues.zipCode,
      };
    }

    if (this.validate()) {
      if (fieldsModified) {
        this.props.updateW9FormStatus({ shouldBeSigned: true });
        this.props.stepSubmit({
          stepId: 'tax',
          entity: 'tax',
          completedSignupStep: POST_SIGNUP_STEPS_NAME.TAX_AND_W9,
          data: {
            ...formValues,
            SSN: originalSSNValue,
            system_file_type: W9,
            directory_name: PRODUCERS,
            ...this.defaultAddress,
          },
          final: this.props.final,
          signUpComplete: this.props.final,
          signupFlow,
          onFinish: this.toggleModal,
        });
      } else if (this.props.shouldW9BeSigned) {
        this.toggleModal();
      } else {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({ event: 'registrationComplete' });
        this.props.stepSubmitSuccess();
      }
    } else {
      this.refList[this.errorField]?.current?.scrollIntoView({
        behavior: 'smooth',
      });
      this.errorField = null;
    }

    window.heap.track(APPLICATION.TAX_PAGE_CLICK_SUBMIT, {
      [TAX_STATUS_KEY]: formValues?.taxStatus,
      [SAME_ADDRESS]: formValues?.registeredAddMailingAdd,
      [AGENCY_FEIN]: !formValues?.FEINNotPresent,
    });
  };

  callDropboxClient = async () => {
    const { formValues } = this.state;
    const firstName = this.props.producerDetails.producer_firstname;
    const lastName = this.props.producerDetails.producer_lastname;
    const email = this.props.agencyProfile.email;
    const agencyName = this.props.agencyProfile.agencyLegalName;
    const agencyDbaName = this.props.agencyProfile.agencyDbaName;
    const idNumber = formValues.FEIN
      ? { ein: formValues.FEIN }
      : { ssn: originalSSNValue };
    const resolveW9Name = (taxStatus) => {
      switch (taxStatus) {
        case 'Individual':
        case 'Single Member LLC':
          return `${firstName} ${lastName}`;
        default:
          return agencyName;
      }
    };
    const response = await dropboxSignClient.getEmbeddedSignUrl(
      TEMPLATE_NAME.W9_FORM,
      {
        name: resolveW9Name(formValues.taxStatus),
        email,
        businessName: agencyDbaName ?? undefined,
        taxStatus: formValues.taxStatus,
        address: formValues.address,
        address2: `${formValues.city}, ${formValues.state} ${formValues.zipCode}`,
        ...idNumber,
      }
    );
    this.props.setDropboxLoader(false);
    return response;
  };
  handleW9SignClick = async () => {
    this.props.setDropboxLoader(true);
    dropboxSignClient.open(await this.callDropboxClient(), () => {
      this.props.updateW9FormStatus({ shouldBeSigned: false });
      this.toggleModal();
      this.props.stepSubmitSuccess();
    });
  };

  setFEINs = () => {
    const { formValues } = this.state;
    const isTaxStatusIndividual = [INDIVIDUAL].includes(
      formValues.taxStatus?.toLowerCase()
    );
    this.setState({
      fieldsModified: true,
      formValues: {
        ...this.state.formValues,
        FEINNotPresent: isTaxStatusIndividual ? true : false,
        FEIN: isTaxStatusIndividual
          ? ''
          : formValues.FEIN
          ? formValues.FEIN
          : '',
      },
    });
  };

  setValue = (key, value, callback) => {
    // setValue is triggered even if the input was just focused and then blurred
    // Prevent toggling fieldsModified in such case
    if (this.state.formValues[key] !== value) {
      this.setState({
        fieldsModified: true,
      });
    }

    this.setState(
      {
        formValues: { ...this.state.formValues, [key]: value },
      },
      () => callback?.(key)
    );
  };

  toggleAddressFields(checked) {
    const { tax } = this.props;
    this.setState({
      fieldsModified: true,
      formValues: {
        ...this.state.formValues,
        registeredAddMailingAdd: checked ? NO.toLowerCase() : YES.toLowerCase(),
        ...(checked
          ? this.defaultAddress
          : {
              address: tax.address,
              city: tax.city,
              state: tax.state,
              zipCode: tax.zipCode,
            }),
      },
    });
  }

  inputChangeOnBlur = (e) => {
    const formValues = { ...this.state.formValues };
    if (e.target.name === ADDRESS) {
      formValues[e.target.name] = e.target.value.replace(/  +/g, ' ').trim();
    }
    this.setState((prevState) => ({
      ...prevState,
      formValues: { ...formValues },
    }));
  };

  handleClickShowSSN = () => {
    show = !this.state.showSSN;
    hiddenSSNValue = originalSSNValue.replaceAll(/[0-9]/g, '*');
    this.setState((prevState) => ({
      ...prevState,
      showSSN: !this.state.showSSN,
    }));
  };
  render() {
    const {
      classNames,
      currentStepIndex,
      productionAccess,
      signupFlow,
      submitLoader,
    } = this.props;
    const { formValues, formErrors } = this.state;
    const { FEINNotPresent } = formValues;
    const isDisabledForStateField =
      formValues?.state?.length === 0 ||
      (!isEmpty(formValues?.state) && formValues?.state === SELECT_KEY);
    const isEnableForFeinFeild = formValues.FEINNotPresent
      ? true
      : formValues.FEIN.length > 0;
    const isDisableNextForTaxStatus =
      formValues?.taxStatus?.length === 0 ||
      (!isEmpty(formValues?.taxStatus) && formValues?.taxStatus === SELECT_KEY);
    const isEnabledForMailingAddress =
      formValues.registeredAddMailingAdd === YES.toLowerCase()
        ? true
        : formValues.address.length > 0 &&
          formValues.city.length > 0 &&
          formValues.zipCode.length > 0 &&
          !isDisabledForStateField;

    const checkSignupFlow =
      signupFlow === PRODUCER_KEY ||
      (signupFlow === ALTERNATE_SUB_PRODUCER && productionAccess);
    const commonFieldsToCheck =
      isEnabledForMailingAddress && !isDisableNextForTaxStatus;
    const isEnabled = checkSignupFlow
      ? commonFieldsToCheck &&
        originalSSNValue.length > 0 &&
        isEnableForFeinFeild
      : commonFieldsToCheck;
    const isTaxStatusIndividual = [INDIVIDUAL].includes(
      formValues.taxStatus?.toLowerCase()
    );
    const isTaxStatusSingleMember = [SINGLE_MEMBER_LLC].includes(
      formValues.taxStatus?.toLowerCase()
    );
    return (
      <Fragment>
        <form
          className="post-signup-forms post-form-2 tax-w-form"
          onSubmit={this.submitForm}
        >
          <Grid
            spacing={0}
            container
            className={`${classNames.cardContent} card-content`}
          >
            <Grid item xs={12} sm={6} className="p-15">
              <label ref={this.refList.taxStatus}>
                <IntlMessages id="label.taxStatus" />
                <span className="required">*</span>
                {signupFlow === ALTERNATE_SUB_PRODUCER && (
                  <HtmlTooltip
                    title={
                      <>
                        <span>{TAX_STATUS_INFO_CONTENT}</span>
                        <br />
                        <span>{TAX_INFO_CONTENT}</span>
                      </>
                    }
                    placement={TOP}
                    fontSize={SMALL}
                    disableTouchListener
                  >
                    <InfoOutlinedIcon className="orange-text tooltipIcon pointer" />
                  </HtmlTooltip>
                )}
              </label>
              <div className="select-wrapper selectInfoIconWrapper">
                <select
                  className="select-dropdown fs-exclude "
                  name={TAX_STATUS}
                  value={formValues.taxStatus}
                  disabled={submitLoader}
                  onChange={(e) =>
                    this.setValue('taxStatus', e?.target?.value, this.setFEINs)
                  }
                  ref={(input) => {
                    this.taxStatusInput = input;
                  }}
                >
                  {statusOption.map((statusOpt) => (
                    <option key={statusOpt.label} value={statusOpt.value}>
                      {statusOpt.label}
                    </option>
                  ))}
                </select>
              </div>
              <div className="error-msg">{formErrors.taxStatus}</div>
            </Grid>

            <Grid item xs={12} sm={6} className="p-15"></Grid>
            {checkSignupFlow && (
              <>
                <Grid item xs={12} sm={6} className="p-15">
                  <label
                    ref={this.refList.FEIN}
                    className={` ${
                      isTaxStatusIndividual || FEINNotPresent
                        ? 'disabled-label'
                        : ''
                    }`}
                  >
                    <IntlMessages id="label.FEIN" />
                    {!isTaxStatusIndividual && (
                      <span className="required">*</span>
                    )}
                  </label>
                  <Input
                    id={FEIN_ID}
                    name={FEIN_ID}
                    className={classnames(
                      'validate',
                      isTaxStatusIndividual || FEINNotPresent
                        ? 'disabled-input'
                        : 'allFien'
                    )}
                    fullWidth={true}
                    disableUnderline={true}
                    type={TYPE_TEXT}
                    value={isTaxStatusIndividual ? '' : formValues.FEIN}
                    inputComponent={FeinMask}
                    disabled={
                      submitLoader ||
                      isTaxStatusIndividual ||
                      formValues.FEINNotPresent
                    }
                    error={formErrors.FEIN ? true : false}
                    onChange={(e) => this.setValue('FEIN', e.target.value)}
                    inputProps={{
                      className: 'fs-exclude',
                    }}
                  />
                  {(isTaxStatusIndividual || isTaxStatusSingleMember) && (
                    <div>
                      <label
                        className={`checked-check-box agency-fin-check taxw9CheckBox ${
                          !submitLoader ? 'cursor-pointer' : 'default-cursor'
                        } ${
                          isTaxStatusIndividual || FEINNotPresent
                            ? 'disabled-lable'
                            : ''
                        }`}
                      >
                        <FormControlLabel
                          control={
                            <Checkbox
                              size="small"
                              onChange={this.checkBoxChecked}
                              disabled={isTaxStatusIndividual || submitLoader}
                              checked={
                                isTaxStatusIndividual ||
                                formValues.FEINNotPresent
                              }
                            />
                          }
                          label={checkBoxText}
                        />
                      </label>
                    </div>
                  )}
                  <div className="error-msg">{formErrors.FEIN}</div>
                </Grid>

                <Grid item xs={12} sm={6} className="p-15">
                  <label ref={this.refList.SSN}>
                    <IntlMessages id="label.SSN" />
                    <span className="required">*</span>
                  </label>
                  <Input
                    id={SSN_ID}
                    name={SSN_ID}
                    className="validate"
                    fullWidth={true}
                    disableUnderline={true}
                    value={show ? originalSSNValue : hiddenSSNValue}
                    inputComponent={SsnMask}
                    disabled={submitLoader}
                    error={formErrors.SSN ? true : false}
                    onKeyDown={function (evt) {
                      if (!show) {
                        originalSSNValue = onSSNKeyPress(evt, originalSSNValue);
                        this.setValue('SSN', originalSSNValue);
                      }
                    }}
                    onChange={(e) => {
                      this.setValue('SSN', e.target.value);
                      hiddenSSNValue = e.target.value;
                      if (show) {
                        originalSSNValue = e.target.value;
                      } else {
                        originalSSNValue = onSSNChange(hiddenSSNValue);
                        hiddenSSNValue = hiddenSSNValue.replaceAll(
                          /[0-9]/g,
                          '*'
                        );
                      }
                      this.setValue('SSN', originalSSNValue);
                    }}
                    inputProps={{
                      className: 'fs-exclude',
                      autoComplete: 'off',
                    }}
                  />

                  <div className="error-msg">{formErrors.SSN}</div>
                </Grid>
              </>
            )}

            <Grid
              item
              xs={12}
              sm={12}
              className="p-15 d-flex align-items-center switch-toggle"
            >
              <label className="mr-36">
                <IntlMessages id="label.sameAddress" />
              </label>
              <ToggleSwitch
                onChange={(event) => {
                  this.toggleAddressFields(event.target.checked);
                }}
                text={formValues.registeredAddMailingAdd.toUpperCase()}
                checked={
                  formValues.registeredAddMailingAdd === YES.toLowerCase()
                    ? false
                    : true
                }
                disabled={submitLoader}
              />
            </Grid>

            {formValues.registeredAddMailingAdd !== YES.toLowerCase() && (
              <>
                <Grid item xs={12} sm={6} className="p-15">
                  <label ref={this.refList.address}>
                    <IntlMessages id="label.address" />
                    <span className="required">*</span>
                  </label>
                  <SearchLocationInput
                    id={ADDRESS}
                    type={TYPE_TEXT}
                    className={`fs-exclude ${
                      formValues.isMailingAddressSame === YES.toLowerCase()
                        ? 'disabledField'
                        : 'validate'
                    }`}
                    maxLength={150}
                    name={ADDRESS}
                    disabled={submitLoader}
                    value={formValues.address}
                    setValue={this.setValue}
                    mappingKeys={{
                      city: 'city',
                      zip: 'zipCode',
                      state: 'state',
                    }}
                    valueKey="address"
                    error={formErrors.address}
                    onBlur={this.inputChangeOnBlur}
                    handleChange={changeHandlers.addressString}
                  />
                </Grid>
                <Grid item xs={12} sm={6} className="p-15">
                  <label ref={this.refList.city}>
                    <IntlMessages id="label.city" />
                    <span className="required">*</span>
                  </label>
                  <FormInput
                    id={CITY}
                    type={TYPE_TEXT}
                    className="validate fs-exclude"
                    name={CITY}
                    handleChange={changeHandlers.alphaString}
                    value={formValues.city}
                    setValue={this.setValue}
                    valueKey="city"
                    handleBlur={blurHandlers.trim}
                    disabled={submitLoader}
                    maxLength={50}
                    error={formErrors.city}
                  />
                </Grid>
                <Grid item xs={12} sm={6} className="p-15">
                  <label ref={this.refList.state}>
                    <IntlMessages id="label.state" />
                    <span className="required">*</span>
                  </label>
                  <div className="select-wrapper">
                    <select
                      className="select-dropdown fs-exclude"
                      value={formValues.state}
                      onChange={(e) => this.setValue('state', e.target.value)}
                      name={STATE}
                      disabled={submitLoader}
                    >
                      {stateName.map((data) => (
                        <option key={data.label} value={data.value}>
                          {data.value}
                        </option>
                      ))}
                    </select>
                    <div className="error-msg">{formErrors.state}</div>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} className="p-15">
                  <label ref={this.refList.zipCode}>
                    <IntlMessages id="label.zipCode" />
                    <span className="required">*</span>
                  </label>
                  <FormInput
                    id="zip-code"
                    type={TYPE_TEXT}
                    className="validate fs-exclude"
                    name={ZIP_CODE}
                    value={formValues.zipCode}
                    setValue={this.setValue}
                    valueKey="zipCode"
                    maxLength={5}
                    handleChange={changeHandlers.numberString}
                    disabled={submitLoader}
                    error={formErrors.zipCode}
                  />
                </Grid>
              </>
            )}
          </Grid>
          <CardActions
            className="signup-btn-action-card d-flex justify-content-between"
            style={{
              top: `${this.props.visualViewportHeight}px`,
            }}
          >
            <button
              type={BUTTON_TYPE}
              className="white-back-button"
              disabled={submitLoader}
              onClick={() => {
                this.props.setCurrentStepIndex(currentStepIndex - 1);
              }}
            >
              <IntlMessages id="appModule.back" />
            </button>
            <button
              type={SUBMIT}
              className={`${
                !isEnabled ? 'disable-button-grey' : 'orange-next-button'
              }`}
              id={this.props.final ? FINISH : NEXT}
              disabled={submitLoader || !isEnabled}
            >
              <IntlMessages
                id={this.props.final ? 'label.finish' : 'appModule.next'}
              />
              {submitLoader && (
                <SubmitLoader className={classNames.submitLoader} />
              )}
            </button>
          </CardActions>
        </form>

        <W9Modal
          open={this.state.isModalOpen}
          onClose={this.toggleModal}
          onCtaClick={this.handleW9SignClick}
          isDropboxLoading={this.props.isDropboxLoading}
        />
      </Fragment>
    );
  }
}

const mapStateToProps = ({
  postSignup: {
    agencyProfile,
    currentStepIndex,
    isErrorPresent,
    submitLoader,
    tax,
    continueOnDevice,
    switchToComputer,
    shouldW9BeSigned,
    isDropboxLoading,
  },
  auth: { signupFlow },
  dashboard: { producerDetails },
}) => ({
  agencyProfile,
  currentStepIndex,
  isErrorPresent,
  signupFlow,
  submitLoader,
  tax,
  continueOnDevice,
  switchToComputer,
  producerDetails,
  shouldW9BeSigned,
  isDropboxLoading,
});

export default connect(mapStateToProps, {
  stepSubmit,
  setCurrentStepIndex,
  stepSubmitSuccess,
  updateW9FormStatus,
  submitLoaderHide,
  setDropboxLoader,
})(Step3);
