import * as types from '../../constants/action-types';

const initState = () => ({
  loader: false,
  submitLoader: false,
  signupStatus: false,
  signupresponse: '',
  existStatus: false,
  emailError: '',
});

export default (state = initState(), action) => {
  switch (action.type) {
    case types.PRE_SIGNUP_ON_SHOW_LOADER: {
      return {
        ...state,
        loader: true,
      };
    }
    case types.PRE_SIGNUP_ON_HIDE_LOADER: {
      return {
        ...state,
        loader: false,
      };
    }
    case types.PRE_SIGNUP_SET_RECORDS: {
      return {
        ...state,
        email: action.payload,
      };
    }
    case types.PRE_SIGNUP_USER_SUCCESS: {
      return {
        ...state,
        loader: false,
        signupStatus: action.payload,
      };
    }
    case types.PRE_SIGNUP_USER_EXIST: {
      return {
        ...state,
        existStatus: action.payload.existStatus,
        emailError: action.payload.message,
      };
    }
    case types.PRE_SIGNUP_USER_FAIL: {
      return {
        ...state,
        loader: false,
        message: action.payload,
      };
    }

    default:
      return state;
  }
};
