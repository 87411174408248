import * as types from '../../constants/action-types';

const initState = () => ({
  advanceSearchLoader: false,
  advanceSearchFields: [],
});

export default (state = initState(), action) => {
  switch (action.type) {
    case types.ADVANCE_SEARCH_FIELDS_LOADER: {
      return {
        ...state,
        advanceSearchLoader: action.payload,
      };
    }
    case types.SET_ADVANCE_SEARCH_FIELDS: {
      return {
        ...state,
        advanceSearchFields: action.payload,
      };
    }
    default:
      return state;
  }
};
