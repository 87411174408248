export const IS_CARRIER_STORE_ENABLED_KEY = 'isCarrierStoreEnabled';

const hasCarrierStoreAccess = () => {
  const isCarrierStoreEnabled = localStorage.getItem(
    IS_CARRIER_STORE_ENABLED_KEY
  );

  return !!JSON.parse(isCarrierStoreEnabled);
};

export default hasCarrierStoreAccess;
