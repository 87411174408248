import config from 'config';
import { createCipher, createDecipher } from 'crypto-browserify';
import { isEmpty } from 'lodash';

// @TODO: migrate old crypto-browserify <https://www.npmjs.com/package/crypto-browserify> utils to lightweight <https://www.npmjs.com/package/crypto-js>

// Encrypt provided data with crypto key
export const encryptData = (details) => {
  return encrypt(details, config.CRYPTO_KEY);
};

// Decript provided data with crypto key.
export const decryptData = (data) => {
  return decrypt(data, config.CRYPTO_KEY);
};

const decrypt = (data, cryptoKey) => {
  const key = createDecipher('aes-192-cbc', cryptoKey);
  let pass = key.update(data, 'hex', 'utf8');
  pass += key.final('utf8');
  return pass;
};

const encrypt = (data, cryptoKey) => {
  if (isEmpty(data)) {
    return data;
  }

  const key = createCipher('aes-192-cbc', cryptoKey);
  let hex = key.update(data, 'utf8', 'hex');
  hex += key.final('hex');
  return hex;
};

export const decryptUrlParams = (url) => {
  return decrypt(url, config.CRYPTO_KEY);
};

export const encryptUrlParams = (url) => {
  return encrypt(url, config.CRYPTO_KEY);
};

export const isHex = (value) => {
  const re = /[0-9A-Fa-f]{6}/g;
  return re.test(value);
};
