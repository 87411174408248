// @ts-check

/**
 * Save token to local storage
 * @TODO Delete this function when Carrier Store refactor is complete https://myhippo.atlassian.net/browse/FRC-4564
 * @param {string} token
 */
export function persistTokenInLocalStorage(token) {
  localStorage.setItem('Authorization', token);
}
