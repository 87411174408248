import React from 'react';
import { ConnectedRouter } from 'connected-react-router';
import { Provider } from 'react-redux';
import { Route, Switch, useLocation } from 'react-router-dom';
import { history, store } from './redux/store';
import App from './containers/app';
import ErrorBoundary from './components/error-boundary';
import { Helmet } from 'react-helmet';

const MainApp = () => (
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <UrlMetaTag />
      <ErrorBoundary>
        <Switch>
          <Route path="/" component={App} />
        </Switch>
      </ErrorBoundary>
    </ConnectedRouter>
  </Provider>
);

export default MainApp;

function UrlMetaTag() {
  const location = useLocation();
  return (
    <Helmet>
      <meta
        name="robots"
        content={location.pathname === '/' ? 'noindex' : 'all'}
      />
    </Helmet>
  );
}
