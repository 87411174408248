// @ts-check

const IS_NEW_SIGN_UP_FLOW_ENABLED_KEY = 'isNewSignUpFlowEnabled';

// const DEFAULT_VALUE = true;
const DEFAULT_VALUE = [
  'portal.firstconnectinsurance.com',
  'portal.firstconnect-staging.myhippo.io',
  'portal.firstconnect-dev.myhippo.io',
  'localhost',
].some((allowedUrl) => window.location.href.includes(allowedUrl));

/**
 * Retrieves the value of the flag indicating whether the new sign-up flow is enabled.
 * @returns {boolean} The value of the flag indicating whether the new sign-up flow is enabled.
 */
export function getIsNewSignUpFlowEnabled() {
  const flag = localStorage.getItem(IS_NEW_SIGN_UP_FLOW_ENABLED_KEY);

  if (flag === 'true' || flag === 'false') {
    return flag === 'true';
  }

  return DEFAULT_VALUE;
}
