// IMPORTANT NOTE:
// This file is a copy of the original file (with small changes) from the new frontend repository.
// Bear that in mind when updating this file, and make the same changes in the original file.

import { useFlagship, useFsFlag } from '@flagship.io/react-sdk';
import { useEffect, useMemo } from 'react';

/**
 * Converts a string value to the specified type.
 * @template T
 * @param {string} value - The string value to convert.
 * @param {T} castValue - The type to cast the value to.
 * @returns {T} The converted value.
 */
function convertStringToType(value, castValue) {
  switch (typeof castValue) {
    case 'boolean': {
      return value === 'true';
    }

    case 'number': {
      const parsedNumber = Number(value);

      return isNaN(parsedNumber) ? castValue : parsedNumber;
    }

    case 'object': {
      try {
        return JSON.parse(value);
      } catch {
        return castValue;
      }
    }

    default: {
      return value;
    }
  }
}

/**
 * Custom hook to retrieve the value of a Flagship experiment.
 * @param {Object} options - The options for the hook.
 * @param {T} options.defaultValue - The default value of the flag.
 * @param {string} options.experimentKey - The key of the query param or experiment used in Flagship.
 * @param {boolean} [options.isDisabled=false] - Flag to forcefully disable the experiment without refactor.
 * @param {boolean} [options.shouldExposeVisitor=true] - Flag to expose visitor to the experiment when this hook is used.
 * @returns {{ flagValue: T, isTestingMode: boolean }} The value of the flag and whether the experiment is in testing mode.
 * @template T
 */
export function useFlagshipExperiment({
  defaultValue,
  experimentKey,
  isDisabled = false,
  shouldExposeVisitor = true,
}) {
  const queryValue = new URLSearchParams(window.location.search).get(
    experimentKey
  );
  const { visitorId} = useFlagship();
  const flag = useFsFlag(experimentKey);

  const isFlagInQuery = queryValue !== null;

  const experiment = useMemo(() => {
    if (isDisabled || !visitorId) {
      return null;
    }

    return flag;
  }, [flag, visitorId, isDisabled]);

  useEffect(() => {
    if (!visitorId) {
      console.error(
        `Flagship Error: Failed to expose visitor ${visitorId} to experiment ${experimentKey} - no visitorId`
      );

      return;
    }

    if (shouldExposeVisitor && experiment?.exists() && !isFlagInQuery) {
      experiment.visitorExposed().catch(() => {
        console.error(
          `Flagship Error: Failed to expose visitor ${visitorId} to experiment ${experimentKey} - visitorExposed failed`
        );
      });
    }
  }, [
    experiment,
    shouldExposeVisitor,
    experimentKey,
    visitorId,
    isFlagInQuery,
  ]);

  const flagValue = useMemo(() => {
    return isFlagInQuery
      ? convertStringToType(queryValue, defaultValue)
      : experiment?.getValue(defaultValue, false);
  }, [queryValue, isFlagInQuery, defaultValue, experiment]);

  return {
    flagValue,
    isTestingMode: isFlagInQuery,
  };
}
