import { all, fork, put, takeEvery } from 'redux-saga/effects';
import axios from 'util/api';
import { errorHandler } from '../actions/error';
import {
  setCarrierCategories,
  setCarrierCategoriesLoader,
} from 'redux/actions/carrier-categories';
import { FETCH_CARRIERS_CATEGORIES } from 'constants/action-types';

function* fetchCarrierCategories() {
  try {
    yield put(setCarrierCategoriesLoader(true));
    const carrierCategories = yield axios.get(
      `api/v1/carrier/get-all-categories`
    );

    if (carrierCategories.data) {
      yield put(setCarrierCategories(carrierCategories.data.categories));
    }
  } catch (error) {
    yield put(errorHandler(error));
  }
  yield put(setCarrierCategoriesLoader(false));
}

export function* fetchCarrierCategoriesWatcher() {
  yield takeEvery(FETCH_CARRIERS_CATEGORIES, fetchCarrierCategories);
}

// ------ ROOT SAGA -----------------
export default function* rootSaga() {
  yield all([fork(fetchCarrierCategoriesWatcher)]);
}
