import React, { useState } from 'react';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import './apetite.scss';

const CarrierSelection = props => {
  const { carriers } = props;
  const [selectedCarrier, setSelectedCarrier] = useState({});

  const onCarrierSelection = (event, item) => {
    event.preventDefault();
    let list = selectedCarrier;
    list = {
      ...list,
      [item.carrierName]: !selectedCarrier[item.carrierName],
    };
    setSelectedCarrier({
      ...list,
    });
    const selectedCarrierList = Object.keys(list).filter(key => !!list[key]);
    props.onCarrierSelection(
      carriers.filter(carrierItem =>
        selectedCarrierList.includes(carrierItem.carrierName)
      )
    );
  };

  let carrierData = [];

  carrierData = carriers?.map((carrier, carrierIndex) => {
    return (
      <>
        <div
          className="lob-option"
          key={carrierIndex}
          onClick={event => onCarrierSelection(event, carrier)}
        >
          <div className="checkbox-div">
            {selectedCarrier[carrier.carrierName] ? (
              <CheckBoxIcon className="check-icon" />
            ) : (
              <CheckBoxOutlineBlankIcon className="unchecked-icon" />
            )}
          </div>
          <img
            src={carrier.carrierLogoURL}
            className="img-fluid product-icon d-flex justify-content-center mx-auto"
            alt={carrier?.carrierName}
            title={carrier?.carrierName}
          />
        </div>
      </>
    );
  });

  return <div className="select-carrier-wrap">{carrierData}</div>;
};

export default CarrierSelection;
