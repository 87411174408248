import * as types from '../../constants/action-types';
import isEmpty from 'lodash/filter';

const initState = () => ({
  stepLoader: false,
  submitLoader: false,
  isErrorPresent: null,
  completedStepsCount: 0,
  postEmailVerification: {
    username: '',
    agencyname: '',
    productcode: '',
  },
  agencyProfile: {
    aboutFirstconnect: '',
    additionalAgents: '',
    address: '',
    agencyDbaName: '',
    agencyEstablishedYear: '',
    agencyLegalName: '',
    approximateTotalProduction: '',
    city: '',
    email: '',
    file: '',
    firstName: '',
    isAgencyManagementSystem: '',
    isComparativeRater: '',
    isFullTimeAgent: '',
    isMailingAddressSame: '',
    is_wholesaler: false,
    knowAboutFirstconnectBy: '',
    lastName: '',
    mailingAddress: '',
    mailingCity: '',
    mailingState: '',
    mailingZipCode: '',
    nameOfRater: '',
    nameofAMS: '',
    phone: '',
    poc_emails: null,
    productionPercentage: null,
    securityQuestion: '',
    securityResponce: '',
    state: '',
    status: '',
    subProducerAgency: '',
    zipCode: '',
  },
  tax: {
    taxStatus: '',
    registeredAddMailingAdd: 'yes',
    address: '',
    city: '',
    state: '',
    zipCode: '',
    phone: '',
    personAuthorizing: '',
    downloadFile: '',
    fileurl: '',
    fileName: '',
    SSN: '',
    FEIN: '',
    FEINNotPresent: false,
  },
  licenseAndEnO: {
    licenseNumber: '',
    licenseState: '',
    businessLicenseNumber: '',
    businesslicenseState: '',
    insuranceCarrier: '',
    aggregateLimit: '',
    expirationDate: '',
    fileurl: '',
    fileName: '',
    individualLicenseUrl: '',
    individualFileName: '',
    businessLicenseUrl: '',
    businessFileName: '',
    businessNPN: '',
    individualNPN: '',
    primaryBusinessLicense: '',
    primaryIndividualLicense: '',
    isIndividualNPNVerified: false,
    isBusinessNPNVerified: false,
    writesUnderAgencyLicense: undefined,
  },
  bankInformation: {
    bankName: '',
    bankNameAccount: '',
    bankActNumber: '',
    bankTransitNumber: '',
    accountType: '',
  },
  agreementData: {
    conditionCheckBox: '',
  },
  shouldW9BeSigned: true,
  uploaded: false,
  niprLoader: false,
  individualLicenseError: '',
  agencyLicenseError: '',
  stepDataFetchRequired: true,
  currentStepIndex: 0,
  isAgreementAccepted:
    JSON.parse(localStorage.getItem('isAgreementAccepted')) || false,
  leadStatus: '',
  leadStatusLoader: false,
  isSignedUpByReferral: false,
  referralData: {
    first_name: '',
    last_name: '',
    agency_name: '',
    email: '',
    phone_number: '',
    city: '',
    state: '',
    utm_source: null,
    referred_code: null,
  },
  continueOnDevice: {
    onComputer: false,
    onPhone: false,
  },
  deviceTransitionLoader: false,
  cancleSwitchDeviceNotification: false,
  switchToComputer: false,
  refetchedLicenseLoader: false,
  individualLicenseList: [],
  businessLicenseList: [],
  isDropboxLoading: false,
});

export default (state = initState(), action) => {
  switch (action.type) {
    case types.STEP_LOADER_SHOW: {
      return {
        ...state,
        stepLoader: true,
        individualLicenseError: '',
        agencyLicenseError: '',
        submitLoader: false,
      };
    }

    case types.STEP_LOADER_HIDE: {
      return {
        ...state,
        stepLoader: false,
      };
    }

    case types.FILES_UPLOAD_SUCCESS: {
      return {
        ...state,
        [action.payload.entity]: {
          ...state[action.payload.entity],
          fileurl: action.payload.fileurl,
          fileName: action.payload.fileName,
        },
        uploaded: true,
      };
    }

    case types.FILES_UPLOAD_FAILED: {
      return {
        ...state,
        uploaded: false,
      };
    }

    case types.POST_VERIFICATION_SUCCESS: {
      return {
        ...state,
        stepLoader: false,
        postEmailVerification: { ...action.payload },
      };
    }

    case types.UPDATE_W9_FORM_STATUS: {
      return {
        ...state,
        shouldW9BeSigned: action.payload.shouldBeSigned,
      };
    }

    case types.UPDATE_STEP_DATA: {
      let taxAddressDetails = {};
      // if 'yes' then set address value to default value of agency profile.
      if (
        action.payload.entity === 'agencyProfile' &&
        state?.tax?.registeredAddMailingAdd === 'yes'
      ) {
        if (action.payload.data.isMailingAddressSame === 'no') {
          taxAddressDetails = {
            address: action.payload.data.mailingAddress,
            city: action.payload.data.mailingCity,
            state: action.payload.data.mailingState,
            zipCode: action.payload.data.mailingZipCode,
            phone: action.payload.data.phone,
          };
        } else {
          taxAddressDetails = {
            address: action.payload.data.address,
            city: action.payload.data.city,
            state: action.payload.data.state,
            zipCode: action.payload.data.zipCode,
            phone: action.payload.data.phone,
          };
        }
      }

      return {
        ...state,
        [action.payload.entity]: {
          ...state[action.payload.entity],
          ...action.payload.data,
        },
        ...(action.payload.entity === 'agencyProfile'
          ? {
              tax: {
                ...state.tax,
                ...taxAddressDetails,
              },
            }
          : {}),
      };
    }

    case types.STEP_DATA_FETCH_SUCCESS: {
      let taxAddressDetails = {};
      // if 'yes' then set address value to default value of agency profile.
      if (
        // eslint-disable-next-line
        action.payload.hasOwnProperty('agencyProfile') &&
        action?.payload?.tax?.registeredAddMailingAdd === 'yes'
      ) {
        taxAddressDetails = {
          address: action.payload.agencyProfile.address,
          city: action.payload.agencyProfile.city,
          state: action.payload.agencyProfile.state,
          zipCode: action.payload.agencyProfile.zipCode,
          phone: action.payload.agencyProfile.phone,
        };
      }

      return {
        ...state,
        agencyProfile: {
          ...state.agencyProfile,
          ...action.payload.agencyProfile,
        },
        producer_code: action.payload.producer_code,
        tax: {
          ...state.tax,
          ...action.payload.tax,
          ...taxAddressDetails,
        },
        licenseAndEnO: {
          ...state.licenseAndEnO,
          ...action.payload.licenseAndEnO,
          file: null,
          individualFile: null,
          businessFile: null,
        },
        bankInformation: {
          ...state.bankInformation,
          ...action.payload.bankInformation,
        },
        agreementData: {
          ...state.agreementData,
          ...action.payload.agreementData,
        },
        hasAgreementAccepted: action.payload?.has_accepted_agreement,
        uploadedAgreement: action.payload?.uploadedAgreement,
        stepDataFetchRequired: false,
      };
    }

    case types.STEP_DATA_FETCH_FAIL: {
      return {
        ...state,
        stepDataFetchRequired: true,
        stepLoader: false,
      };
    }

    case types.SUBMIT_LOADER_SHOW: {
      return {
        ...state,
        submitLoader: true,
      };
    }

    case types.SUBMIT_LOADER_HIDE: {
      return {
        ...state,
        submitLoader: false,
      };
    }

    case types.STEP_SUBMIT_SUCCESS: {
      return {
        ...state,
        submitLoader: false,
        currentStepIndex: state.currentStepIndex + 1,
        completedStepsCount:
          state.completedStepsCount === state.currentStepIndex
            ? state.currentStepIndex + 1
            : state.completedStepsCount,
      };
    }

    case types.STEP_SUBMIT_FAIL: {
      return {
        ...state,
        submitLoader: false,
      };
    }

    // on update license click set loader for nipr
    case types.SET_NIPPR_LOADER: {
      return {
        ...state,
        niprLoader: action.payload,
      };
    }

    case types.SET_LICENSE_URL: {
      return {
        ...state,
        [action.payload.entity]: {
          ...state[action.payload.entity],
          individualLicenseUrl: !isEmpty(action.payload.individualLicense)
            ? action.payload.individualLicense
            : state[action.payload.entity].individualLicenseUrl,
          businessLicenseUrl: !isEmpty(action.payload.businessLicense)
            ? action.payload.businessLicense
            : state[action.payload.entity].businessLicenseUrl,
        },
      };
    }

    case types.SET_INDIVIDUAL_NPN_VERIFIED: {
      return {
        ...state,
        licenseAndEnO: {
          ...state.licenseAndEnO,
          isIndividualNPNVerified: action.payload,
        },
      };
    }

    case types.SET_BUSINESS_NPN_VERIFIED: {
      return {
        ...state,
        licenseAndEnO: {
          ...state.licenseAndEnO,
          isBusinessNPNVerified: action.payload,
        },
      };
    }

    case types.INDIVIDUAL_LICENSE_ERROR: {
      return {
        ...state,
        individualLicenseError: action.payload,
      };
    }

    case types.AGENCY_LICENSE_ERROR: {
      return {
        ...state,
        agencyLicenseError: action.payload,
      };
    }

    case types.CLEAR_DATA_ON_WRITE_UNDER_AGENCY: {
      return {
        ...state,
        licenseAndEnO: {
          ...state.licenseAndEnO,
          individualNPN: '',
          individualFile: null,
          individualFileName: '',
          individualLicenseUrl: '',
          businessFileName: '',
          businessFile: null,
          businessNPN: '',
          businessLicenseUrl: '',
          writesUnderAgencyLicense: action.payload,
          primaryIndividualLicense: '',
          primaryBusinessLicense: '',
        },
      };
    }

    case types.UPDATE_LICENSE_DATA: {
      return {
        ...state,
        licenseAndEnO: {
          ...state.licenseAndEnO,
          ...action.payload,
        },
      };
    }
    case types.CLEAR_NPN_LICENSES: {
      return {
        ...state,
        individualLicenseError: '',
        agencyLicenseError: '',
        licenseAndEnO: {
          ...state.licenseAndEnO,
          primaryIndividualLicense: '',
          primaryBusinessLicense: '',
        },
      };
    }

    case types.FETCH_AGENT_STARTED:
      return {
        ...state,
        producersLoader: true,
      };
    case types.SET_LICENSE_LIST: {
      return {
        ...state,
        licenseAndEnO: {
          ...state.licenseAndEnO,
          ...action.payload,
        },
      };
    }
    case types.FETCH_AGENT_SUCCESS:
      return {
        ...state,
        producersLoader: false,
        producers: action.payload,
      };
    case types.SET_POST_SIGNUP_FILE_UPLOAD_ERROR: {
      return {
        ...state,
        isErrorPresent: action.payload,
      };
    }
    case types.SET_CURRENT_STEP_INDEX: {
      return {
        ...state,
        currentStepIndex: action.payload,
      };
    }
    case types.SET_UPDATED_STEP: {
      return {
        ...state,
        currentStepIndex: action.payload,
        completedStepsCount: action.payload,
      };
    }

    case types.SET_HAS_ACCEPTED_AGREEMENT: {
      return {
        ...state,
        isAgreementAccepted: action.payload,
      };
    }
    case types.SET_LEAD_STATUS: {
      return { ...state, leadStatus: action.payload };
    }
    case types.SET_LEAD_STATUS_LOADER: {
      return { ...state, leadStatusLoader: action.payload };
    }
    case types.SET_INDIVIDUAL_LICENSE_LIST: {
      return { ...state, individualLicenseList: action.payload };
    }
    case types.SET_BUSINESS_LICENSE_LIST: {
      return { ...state, businessLicenseList: action.payload };
    }
    case types.SET_REFETCH_LICENSE_LOADER: {
      return { ...state, refetchedLicenseLoader: action.payload };
    }
    case types.SET_CONTINUE_ON_COMPUTER: {
      return { ...state, continueOnDevice: action.payload };
    }
    case types.SET_CANCLE_SWITCH_VALUE: {
      return { ...state, cancleSwitchDeviceNotification: action.payload };
    }
    case types.SET_DEVICE_TRANSITION_EMAIL_LOADER: {
      return { ...state, deviceTransitionLoader: action.payload };
    }
    case types.SWITCH_TO_COMPUTER: {
      return { ...state, switchToComputer: action.payload };
    }
    case types.SET_SIGNUP_BY_REFERRAL: {
      return {
        ...state,
        isSignedUpByReferral: true,
        referralData: {
          ...state.referralData,
          ...action.payload,
          first_name:
            action.payload.first_name || state.agencyProfile.firstName,
          last_name: action.payload.last_name || state.agencyProfile.lastName,
        },
        agencyProfile: {
          ...state.agencyProfile,
          city: action.payload.city || state.agencyProfile.city,
          state: action.payload.state || state.agencyProfile.state,
        },
      };
    }
    case types.SET_IS_SIGN_UP_BY_REFERRAL: {
      return { ...state, isSignedUpByReferral: action.payload };
    }
    case types.SET_DROPBOX_LOADER: {
      return {
        ...state,
        isDropboxLoading: action.payload,
      };
    }
    default:
      return state;
  }
};
