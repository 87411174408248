// @ts-check

import { useEffect, useState } from 'react';
import axios from 'util/api';
import config from 'config';
import { getToken } from 'util/auth/get-token';

/**
 * @returns {{isSuccess: boolean, isLoading: boolean, error: unknown}}
 */
export const useIsSSOBFF = () => {
  const [isSuccess, setIsSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState();

  useEffect(() => {
    async function fetchData() {
      setIsLoading(true);
      setError(undefined);

      const token = await getToken();

      try {
        if (!token) {
          setIsSuccess(false);
          return;
        }

        const res = await axios.get(`${config.BFF_URL}/api/v1/admin/me`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const isSSO = res.data['hippoSSO'] === true;

        setIsSuccess(isSSO);
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error('BFF isSSO check failed: ', e);

        setIsSuccess(false);
        setError(e);
      } finally {
        setIsLoading(false);
      }
    }

    fetchData();
  }, []);

  return { isSuccess, isLoading, error };
};
