// @ts-check

import { useEffect, useState } from 'react';
import { AUTH_FLOW_PASSWORDLESS_REMEMBER_ME } from 'util/auth/auth0.constants';

/**
 * Hook that manages the 'remember me' state and its persistence in local storage.
 *
 * @returns {[boolean, function]} A state variable `rememberMe` and a function to update it.
 */
export function useRememberMeValue() {
  const [rememberMe, setRememberMe] = useState(
    localStorage.getItem(AUTH_FLOW_PASSWORDLESS_REMEMBER_ME) === 'true'
  );

  useEffect(
    function () {
      if (rememberMe) {
        localStorage.setItem(AUTH_FLOW_PASSWORDLESS_REMEMBER_ME, 'true');
      } else {
        localStorage.removeItem(AUTH_FLOW_PASSWORDLESS_REMEMBER_ME);
      }
    },
    [rememberMe]
  );

  return [rememberMe, setRememberMe];
}

/**
 * Hook that clears input errors when the selected tab changes.
 *
 * @param {object} props The hook props.
 * @param {function} props.setInputError Function to set input error.
 * @param {string} props.selectedTab The selected tab.
 * @returns {void}
 */
export function useClearErrorsOnTabChangeEffect({
  setInputError,
  selectedTab,
}) {
  useEffect(
    function clearErrors() {
      setInputError('');
    },
    [selectedTab]
  );
}
