import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import axios from 'util/api';
import * as authType from 'constants/action-types';
import { push } from 'connected-react-router';
import { displayErrorMessage } from 'util/user-feedback';
import {
  preSignupLoaderHide,
  preSignupLoaderShow,
  preSignupSetRecords,
  preSignupUserFail,
  preSignupUserSuccess,
} from '../actions/pre-sign-up';
import { teamMemberEmailDuplicate } from '../actions/my-account';
import { errorHandler } from '../actions/error';
import {
  DIRECT,
  EMAIL_ALREADY_USED,
  EMAIL_CONFLICT,
  UTM_SOURCE_KEY,
} from 'constants/constant';
import { setAgreementData, setLocalStorage, setReducer } from './auth';
import {
  setIsSignupByReferral,
  stepDataFetch,
  stepSubmitSuccess,
  submitLoaderHide,
  submitLoaderShow,
} from '../actions/post-sign-up';
import { decryptModulePermissions } from 'util/extra';
export function* createUserAccount() {
  yield takeLatest(authType.PRE_SIGNUP_USER, createUserWithEmail);
}
function* createUserWithEmail({ payload }) {
  const { signup_details } = payload;
  let messageResponse = {
    success: false,
    message: null,
    messageType: null,
  };
  yield put(preSignupLoaderShow());
  if (signup_details.agencyProfile) {
    yield put(submitLoaderShow());
  }
  yield put(teamMemberEmailDuplicate(messageResponse));
  try {
    const response = yield axios.post('/api/v1/producer/signup', {
      data: { ...signup_details },
    });
    if (response.status === 200) {
      const producerCode = response.data?.producer?.producer_code;
      window.heap?.identify(producerCode);
      window.heap.addUserProperties({
        [UTM_SOURCE_KEY]: signup_details.utm_source || DIRECT,
      });
      messageResponse.success = true;
      yield put(preSignupUserSuccess(true));
      let permissionDetails =
        response?.data?.permissions ??
        (!!response?.data?.token &&
          decryptModulePermissions(response.data.token)); // Decodes received permission token
      yield call(
        setLocalStorage,
        response.data.producer,
        response,
        permissionDetails
      );
      yield call(
        setReducer,
        permissionDetails,
        response.data.producer,
        response
      );
      localStorage.setItem(
        'shouldShowProgressBar',
        response.data.producer.shouldShowProgressBar
      );

      yield call(setAgreementData, response.data.producer);
      yield put(teamMemberEmailDuplicate(messageResponse));
      yield put(preSignupSetRecords(signup_details.email));
      yield put(
        stepDataFetch({
          producer_id: response.data.producer.producer_id,
        })
      );
      yield put(setIsSignupByReferral(false));
      yield put(stepSubmitSuccess());
    } else {
      yield put(preSignupUserSuccess(false));
    }
  } catch (error) {
    if (error.response?.status === 409) {
      messageResponse.success = false;
      messageResponse.message = EMAIL_ALREADY_USED;
      messageResponse.messageType = EMAIL_CONFLICT;
    } else {
      messageResponse.success = false;
      messageResponse.message = null;
      messageResponse.messageType = null;
    }
    yield put(teamMemberEmailDuplicate(messageResponse));
    yield put(preSignupUserSuccess(false));
    if (payload.signup_details.agencyProfile) {
      yield put(push('/'));
      displayErrorMessage(messageResponse.message);
    } else {
      if(messageResponse.messageType !== EMAIL_CONFLICT) {
        yield put(errorHandler(error));
      }
    }
    yield put(preSignupUserFail(error.message));
  }
  yield put(preSignupLoaderHide());
  if (payload.signup_details.agencyProfile) {
    yield put(submitLoaderHide());
  }
}
// ------ ROOT SAGA -----------------
export default function* rootSaga() {
  yield all([fork(createUserAccount)]);
}
