import DancingDots from 'components/dancing-dots';
import React from 'react';

export function GlobalAuthLoader() {
  return (
    <div className="app-container login-container">
      <div className="app-main-container">
        <div className="loader-view" id="app loader">
          <DancingDots fullpageWrapper />
        </div>
      </div>
    </div>
  );
}
