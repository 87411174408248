// @ts-check
import { saveState } from 'redux/persist';
import { store } from 'redux/store';

/**
 * @param {string} route
 * @param {string} [queryParams]
 * @returns {void}
 */
export function navigateToNewAppRoute(route, queryParams = '') {
  try {
    if (!route) {
      return;
    }
    const state = store.getState();
    saveState(state);
    window.location.href = `${route}${queryParams ? `?${queryParams}` : ''}`;
  } catch (error) {
    console.error(error);
  }
}
