import * as carrierActions from '../../constants/action-types';

export const setCarrierAppointmentSettingsLoader = payload => ({
  type: carrierActions.SET_CARRIER_APPOINTMENT_SETTING_LOADER,
  payload,
});

export const fetchCarrierAlreadyFeatured = payload => ({
  type: carrierActions.FETCH_CARRIER_ALREADY_FEATURED,
  payload,
});

export const setCarrierAlreadyFeaturedResponse = payload => ({
  type: carrierActions.SET_CARRIER_ALREADY_FEATURED_RESPONSE,
  payload,
});
