import { all, fork, put, takeLatest } from 'redux-saga/effects';
import { displayErrorMessage } from 'util/user-feedback';
import axios from 'util/api';
import * as carrierListActions from 'constants/action-types';
import { setCarrierAppointmentList, setCarrierAppointmentListLoader } from '../actions/carrier-appointment-list';
import { errorHandler } from '../actions/error';

function* fetchCarrierListDetails({ payload: { producer_id } } = {}) {
  try {
    yield put(setCarrierAppointmentListLoader(true));
    const carrierDetails = yield axios.post('/api/v1/producer/carrier-appointments/get-carriers', {
      producer_id
    });

    if (carrierDetails.data) {
      const carrierList = carrierDetails.data.carriers;
      yield put(setCarrierAppointmentList(carrierList));
    }

  } catch (error) {
    if (!!error.response && error.response.status === 401) {
yield displayErrorMessage(error.response.data.message)
      yield put(errorHandler(error));
    } else {
      yield put(errorHandler(error));
    }
  }
  yield put(setCarrierAppointmentListLoader(false));
}

export function* fetchCarrierListWatcher() {
  yield takeLatest(
    carrierListActions.FETCH_CARRIER_APPOINTMENT_LIST,
    fetchCarrierListDetails
  );
}

// ------ ROOT SAGA -----------------
export default function* rootSaga() {
  yield all([fork(fetchCarrierListWatcher)]);
}
