import * as types from '../../constants/action-types';

const initState = () => ({
  discardAd: false,
  showAd: false,
  quoteCarrierList: [],
  paymentDetails: {
    newPaymentLoader: false,
    list: [],
  },
  subscribed: false,
  submitLoader: false,
  updateCarrier: false,
  updateCarrierConfirmation: false,
  showPayment: false,
  carrierUserData: {
    userDataLoader: false,
    list: [],
  },
  quoteUrlData: '',
  isNewQuoteConnectorEnabled: null,
});

export default (state = initState(), action) => {
  switch (action.type) {
    case types.INIT_URL: {
      return {
        ...state,
        initURL: action.payload,
        submitLoader: false,
        showPayment: false,
      };
    }

    case types.QUOTE_URL_DATA: {
      return {
        ...state,
        quoteUrlData: action.payload,
      };
    }

    case types.SET_IS_NEW_QUOTE_CONNECTOR_ENABLED: {
      return {
        ...state,
        isNewQuoteConnectorEnabled: action.payload,
      }
    }

    default:
      return state;
  }
};
