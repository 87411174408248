import * as myPolicyActions from '../../constants/action-types';

// agent policy list details fetch
export const fetchAgentPolicies = (payload, callback) => ({
  type: myPolicyActions.FETCH_AGENT_POLICIES,
  payload,
  callback,
});
// ---------- My Policies Notification -------------
export const updatePolicyNotificationIndicator = (payload, callback) => ({
  type: myPolicyActions.UPDATE_MY_POLICY_NOTIFICATION_INDICATOR,
  payload,
  callback,
});
