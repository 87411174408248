import * as quoteConnectorType from '../../constants/action-types';

export const setQuoteUrlData = (payload) => ({
  type: quoteConnectorType.QUOTE_URL_DATA,
  payload,
});

export const fetchQuoteConnectorSettings = () => ({
  type: quoteConnectorType.FETCH_QUOTE_CONNECTOR_SETTINGS,
});

export const fetchQuoteConnectorSettingsResult = () => ({
  type: quoteConnectorType.FETCH_QUOTE_CONNECTOR_SETTINGS_RESULT,
});

export const setIsNewQuoteConnectorEnabled = (isEnabled) => ({
  type: quoteConnectorType.SET_IS_NEW_QUOTE_CONNECTOR_ENABLED,
  payload: isEnabled,
});
