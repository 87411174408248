import React from 'react';

const ErrorBoundaryIcon = () => (
  <svg viewBox='0 0 96 96' fill='none' width='96px' height='96px'>
    <path
      d='M35.8663 63.8775H13.9167C13.7712 63.8775 13.6271 63.8488 13.4927 63.7931C13.3582 63.7374 13.236 63.6558 13.1331 63.5529C13.0302 63.45 12.9486 63.3278 12.8929 63.1934C12.8372 63.0589 12.8086 62.9148 12.8086 62.7693V16.1612C12.8086 16.0157 12.8372 15.8716 12.8929 15.7371C12.9486 15.6027 13.0302 15.4805 13.1331 15.3776C13.236 15.2747 13.3582 15.1931 13.4927 15.1374C13.6271 15.0817 13.7712 15.053 13.9167 15.053H71.414C71.7084 15.053 71.9908 15.1696 72.1995 15.3773C72.4082 15.585 72.526 15.8668 72.5274 16.1612V38.5659'
      stroke='#495165'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M33.4357 22.2107C33.826 22.2107 34.1423 21.8943 34.1423 21.5041C34.1423 21.1138 33.826 20.7974 33.4357 20.7974C33.0454 20.7974 32.729 21.1138 32.729 21.5041C32.729 21.8943 33.0454 22.2107 33.4357 22.2107Z'
      stroke='#495165'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M27.5574 21.5684C27.5574 21.4286 27.516 21.2919 27.4384 21.1757C27.3607 21.0595 27.2504 20.9689 27.1213 20.9155C26.9921 20.862 26.85 20.848 26.713 20.8752C26.5759 20.9025 26.45 20.9698 26.3512 21.0686C26.2523 21.1675 26.185 21.2934 26.1577 21.4305C26.1305 21.5675 26.1445 21.7096 26.198 21.8388C26.2515 21.9679 26.342 22.0783 26.4582 22.1559C26.5744 22.2336 26.7111 22.275 26.8508 22.275C27.0383 22.275 27.218 22.2006 27.3505 22.068C27.483 21.9355 27.5574 21.7558 27.5574 21.5684Z'
      stroke='#495165'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M20.9779 21.6326C20.979 21.4915 20.938 21.3533 20.8604 21.2355C20.7828 21.1177 20.672 21.0256 20.542 20.9709C20.412 20.9162 20.2687 20.9013 20.1302 20.9282C19.9917 20.9551 19.8645 21.0225 19.7644 21.1218C19.6642 21.2212 19.5958 21.348 19.5679 21.4863C19.54 21.6246 19.5538 21.768 19.6075 21.8984C19.6612 22.0289 19.7525 22.1404 19.8697 22.2188C19.9869 22.2973 20.1248 22.3392 20.2659 22.3392C20.4538 22.3392 20.6341 22.2649 20.7675 22.1326C20.9009 22.0002 20.9765 21.8205 20.9779 21.6326Z'
      stroke='#495165'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M12.8031 28.0836H72.522'
      stroke='#495165'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M42.9705 21.5683H66.7617'
      stroke='#495165'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M56.8841 72.6841C66.5909 72.6841 74.4598 64.8152 74.4598 55.1084C74.4598 45.4016 66.5909 37.5326 56.8841 37.5326C47.1773 37.5326 39.3083 45.4016 39.3083 55.1084C39.3083 64.8152 47.1773 72.6841 56.8841 72.6841Z'
      stroke='#495165'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M56.8842 40.7608C56.216 40.7628 55.5488 40.8111 54.8873 40.9054C58.3163 41.3863 61.4559 43.0902 63.7279 45.7031C65.9999 48.316 67.2512 51.6619 67.2512 55.1244C67.2512 58.587 65.9999 61.9328 63.7279 64.5457C61.4559 67.1586 58.3163 68.8625 54.8873 69.3435C56.841 69.6227 58.8313 69.4963 60.734 68.9722C62.6367 68.4481 64.4111 67.5375 65.9463 66.2973C67.4815 65.057 68.7446 63.5137 69.6568 61.7636C70.569 60.0135 71.1108 58.0942 71.2484 56.1254C71.3859 54.1566 71.1162 52.1807 70.4562 50.3207C69.7961 48.4608 68.7599 46.7568 67.4121 45.3151C66.0643 43.8735 64.4339 42.725 62.6225 41.9414C60.8112 41.1578 58.8578 40.756 56.8842 40.7608Z'
      fill='#FF8125'
    />
    <path
      d='M59.031 77.3738C64.3668 77.3738 69.4842 75.2541 73.2571 71.4812C77.0301 67.7082 79.1497 62.5909 79.1497 57.2551'
      stroke='#495165'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M74.808 69.7396L82.5172 77.4487C82.952 77.8852 83.1961 78.4762 83.1961 79.0923C83.1961 79.7083 82.952 80.2994 82.5172 80.7358C82.3018 80.9524 82.0457 81.1242 81.7636 81.2415C81.4815 81.3588 81.1791 81.4192 80.8736 81.4192C80.5682 81.4192 80.2658 81.3588 79.9837 81.2415C79.7016 81.1242 79.4455 80.9524 79.2301 80.7358L71.521 73.0267'
      stroke='#495165'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M28.4086 60.6654C23.5913 57.5927 19.887 53.0553 17.8407 47.7205L16.0312 42.9879V60.6868L28.4086 60.6654Z'
      fill='#FF8125'
    />
  </svg>
);

export default ErrorBoundaryIcon;