import React from 'react';
import IntlMessages from 'util/intl-messages';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import AllInboxIcon from '@material-ui/icons/AllInbox';
import AssignmentIndOutlinedIcon from '@material-ui/icons/AssignmentIndOutlined';
import BarChartIcon from '@material-ui/icons/BarChart';
import ChangeHistoryOutlinedIcon from '@material-ui/icons/ChangeHistoryOutlined';
import DashboardOutlinedIcon from '@material-ui/icons/DashboardOutlined';
import LocalMallOutlinedIcon from '@material-ui/icons/LocalMallOutlined';
import MonetizationOnOutlinedIcon from '@material-ui/icons/MonetizationOnOutlined';
import PaymentOutlinedIcon from '@material-ui/icons/PaymentOutlined';
import PersonIcon from '@material-ui/icons/Person';
import PolicyOutlinedIcon from '@material-ui/icons/PolicyOutlined';
import SettingsIcon from '@material-ui/icons/Settings';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHospital, faKey } from '@fortawesome/free-solid-svg-icons';
import { ADMIN } from 'constants/constant';
import config from 'config';
import { SearchOutlined } from '@material-ui/icons';
import ExistingCoverageIcon from '../assets/images/existing-coverage.svg';
import LeadFeedIcon from '../assets/images/lead-feed.svg';
import AgentToolsIcon from '../assets/images/agent-tools.svg';

export const getModules = (
  role,
  module,
  isImpersonate,
  isSubProducer = false
) => {
  let modulesObj;

  if (!!role && role.toLowerCase() === ADMIN && !isImpersonate) {
    modulesObj = adminModules(role);
  } else {
    modulesObj = producerModules(role, isSubProducer);
  }

  return modulesObj[module];
};

const adminModules = (role) => ({
  'AGENT-DIRECTORY': {
    title: <IntlMessages id="admin.agentDirectory" />,
    icon: <AllInboxIcon />,
  },
  'AGENT-DIRECTORY-ALL': {
    countType: 'all',
    url: `/app/${role}/agent-directory/all`,
    title: <IntlMessages id="admin.agentDirectory.all" />,
  },
  'AGENT-DIRECTORY-ACTIVE': {
    countType: 'active',
    url: `/app/${role}/agent-directory/active`,
    title: <IntlMessages id="admin.agentDirectory.active" />,
  },
  'AGENT-DIRECTORY-PENDING': {
    countType: 'pending',
    url: `/app/${role}/agent-directory/pending`,
    title: <IntlMessages id="admin.agentDirectory.pending" />,
  },
  'AGENT-DIRECTORY-RESTRICTED': {
    countType: 'restricted',
    url: `/app/${role}/agent-directory/restricted`,
    title: <IntlMessages id="admin.agentDirectory.restricted" />,
  },
  'AGENT-DIRECTORY-SUSPEND': {
    countType: 'suspend',
    url: `/app/${role}/agent-directory/suspend`,
    title: <IntlMessages id="admin.agentDirectory.suspend" />,
  },
  'AGENT-DIRECTORY-DORMANT': {
    countType: 'dormant',
    url: `/app/${role}/agent-directory/dormant`,
    title: <IntlMessages id="admin.agentDirectory.dormant" />,
  },
  'AGENT-DIRECTORY-TERMINATED': {
    countType: 'terminated',
    url: `/app/${role}/agent-directory/terminated`,
    title: <IntlMessages id="admin.agentDirectory.terminated" />,
  },
  'AGENT-DIRECTORY-INCOMPLETE-SIGNUP': {
    countType: 'incompleteSignup',
    url: `/app/${role}/agent-directory/incompletesignup`,
    title: <IntlMessages id="admin.agentDirectory.incompletesignup" />,
  },
  'AGENT-DIRECTORY-ERROR-AND-OMMISSION': {
    isNestedItem: true,
    countType: 'errorOmission',
    url: `/app/${role}/agent-directory/errorsandomissions`,
    title: <IntlMessages id="admin.agentDirectory.errorsandomissions" />,
  },
  'AGENT-DIRECTORY-NPN': {
    isNestedItem: true,
    countType: 'npn',
    url: `/app/${role}/agent-directory/npn`,
    title: <IntlMessages id="admin.agentDirectory.npn" />,
  },
  'AGENT-DIRECTORY-W9': {
    isNestedItem: true,
    countType: 'taxAgent',
    url: `/app/${role}/agent-directory/w9`,
    title: <IntlMessages id="admin.agentDirectory.w9" />,
  },
  'AGENT-DIRECTORY-REGULATORY_ACTION': {
    isNestedItem: true,
    countType: 'regulatoryAction',
    url: `/app/${role}/agent-directory/regulatory-action`,
    title: <IntlMessages id="admin.agentDirectory.regulatoryAction" />,
  },
  COMMISSIONS: {
    url: `/app/${role}/commissions`,
    title: <IntlMessages id="admin.commissions" />,
    icon: <MonetizationOnOutlinedIcon />,
  },
  CARRIERS: {
    url: `/app/${role}/carriers`,
    title: <IntlMessages id="admin.carriers" />,
    icon: <FontAwesomeIcon icon={faHospital} className="carrierIcon" />,
  },
  'ADHOC-PAYMENTS': {
    url: `/app/${role}/adhoc-payments`,
    title: <IntlMessages id="label.additionalPayments" />,
    icon: <PaymentOutlinedIcon />,
  },
  REPORTS: {
    title: <IntlMessages id="admin.reports" />,
    icon: <BarChartIcon />,
  },
  'MONTHLY-COMMISSION-REPORT': {
    url: `/app/${role}/monthly-commission-report`,
    title: <IntlMessages id="admin.monthlyCommissionReport" />,
  },
  'AGENT-SYNC-REPORTS': {
    url: `/app/${role}/agent-sync-report`,
    title: <IntlMessages id="admin.agentSyncReport" />,
  },
  'AGENT-ONBOARDING-REPORTS': {
    url: `/app/${role}/agent-on-boarding`,
    title: <IntlMessages id="admin.agentOnBoarding" />,
  },
  'QUICKBOOKS-EXTRACT': {
    url: `/app/${role}/quickbooks-extract`,
    title: <IntlMessages id="admin.quickbooksExtract" />,
  },
  'FAST-START-REPORT': {
    url: `/app/${role}/fast-start-report`,
    title: <IntlMessages id="admin.fastStartReport" />,
  },
  'INCENTIVE-REPORT': {
    url: `/app/${role}/incentive-report`,
    title: <IntlMessages id="admin.incentiveReport" />,
  },
  'APPOINTMENT-FAILURE-REPORT': {
    url: `/app/${role}/appointment-failure-report`,
    title: <IntlMessages id="admin.appointmentFailure" />,
  },
  'MOTION-AUTO-DOCUMENT': {
    url: `/app/${role}/motion-auto-s3-bucket`,
    title: <IntlMessages id="admin.motion.auto.s3.bucket" />,
  },
  'CARRIER-LOB-MARKETS': {
    url: `/app/${role}/carrier-lob-markets-report`,
    title: <IntlMessages id="admin.carrierLobMarkets" />,
  },
  'REWARDS-PROGRAM-REPORT': {
    url: `/app/${role}/rewards-program-report`,
    title: <IntlMessages id="admin.rewardsProgramsReport" />,
  },

  PERMISSION: {
    title: <IntlMessages id="admin.permission" />,
    icon: <FontAwesomeIcon icon={faKey} className="width-25" />,
  },
  ROLES: {
    url: `/app/${role}/permission/roles`,
    title: <IntlMessages id="admin.permission.role" />,
    icon: <FontAwesomeIcon icon={faKey} className="width-25" />,
  },
  USERS: {
    url: `/app/${role}/permission/users`,
    title: <IntlMessages id="admin.permission.users" />,
  },
  'AGENT-DIRECTORY-NEEDS-APPROVAL': {
    countType: 'needsApproval',
    url: `/app/${role}/agent-directory/needs-approval`,
    title: <IntlMessages id="label.needs.approval" />,
  },
  'PORTAL-SETTINGS': {
    title: <IntlMessages id="label.portal.settings" />,
    icon: <SettingsIcon />,
  },
  PRODUCTS: {
    url: `/app/${role}/portal-setting/product`,
    title: <IntlMessages id="label.product" />,
  },
  'ADMIN-USERS': {
    url: `/app/${role}/portal-setting/admin-users`,
    title: <IntlMessages id="label.admin.users" />,
  },
  'LOA-FILTERS': {
    url: `/app/${role}/portal-setting/loa-filters`,
    title: <IntlMessages id="label.loa.filters" />,
  },
  'INCENTIVE-BANNERS': {
    url: `/app/${role}/portal-setting/incentive-banners`,
    title: <IntlMessages id="label.incentive.banners" />,
  },
  'BULK-APPOINTMENTS': {
    url: `/app/${role}/portal-setting/bulk-appointments`,
    title: <IntlMessages id="label.appointments.banners" />,
  },
  'ADMIN-MESSAGING': {
    url: `/app/${role}/portal-setting/agent-messaging`,
    title: <IntlMessages id="label.agent.messaging" />,
    hide: true,
  },
  'CARRIER-INCENTIVES': {
    url: `/app/${role}/portal-setting/carriers-incentive`,
    title: <IntlMessages id="label.carrier.incentives" />,
  },
  ORGANIZATIONS: {
    url: `/app/${role}/organizations`,
    title: <IntlMessages id="label.organizations" />,
    icon: <AccountTreeIcon />,
  },
  NOTIFICATIONS: {
    url: `/app/${role}/portal-setting/agent-messaging`,
    title: <IntlMessages id="label.agent.messaging" />,
  },
});

/**
 *
 * @param {"admin" | "producer"} role
 * @param {boolean} isSubProducer
 * @returns Producer Modules
 */
const producerModules = (role, isSubProducer) => ({
  DASHBOARD: {
    url: `/app/${role}/dashboard`,
    title: <IntlMessages id="producer.dashboard" />,
    icon: <DashboardOutlinedIcon />,
  },
  'APPETITE-GUIDE': {
    url: '/appetite-finder/start',
    title: <IntlMessages id="producer.appetiteGuide" />,
    icon: <SearchOutlined />,
  },
  'MY-COMMISSION': {
    url: `/app/${role}/my-commission`,
    title: <IntlMessages id="producer.commission" />,
    icon: <MonetizationOnOutlinedIcon />,
  },
  'MY-ACCOUNT': {
    url: `/app/${role}/my-account`,
    title: <IntlMessages id="producer.myAccount" />,
    icon: <PersonIcon />,
  },
  'FLOOD-RATER': {
    url: false,
    title: <IntlMessages id="producer.floodRater" />,
    icon: <span className="icon-floods"></span>,
  },
  'TEND-HOME': {
    url: false,
    title: <IntlMessages id="producer.tendHome" />,
    icon: <span className="icon-tend"></span>,
  },
  'MY-POLICIES': {
    url: `/app/${role}/my-policies`,
    title: (
      <IntlMessages
        id={isSubProducer ? 'producer.agencyPolicies' : 'producer.myPolicies'}
      />
    ),
    icon: <PolicyOutlinedIcon />,
  },
  'AGENCY-COMMISSION': {
    url: `/app/${role}/agency-commission`,
    title: <IntlMessages id="producer.agency.commission" />,
    icon: <MonetizationOnOutlinedIcon />,
  },
  'AGENCY-PROFILE': {
    url: `/app/${role}/agency-profile`,
    title: <IntlMessages id="producer.agency.profile" />,
    icon: <AssignmentIndOutlinedIcon />,
  },
  'CARRIER-APPOINTMENTS': {
    url: `/app/${role}/carrier-store`,
    title: (
      <>
        <IntlMessages id="producer.carrierStore" />
      </>
    ),
    icon: <LocalMallOutlinedIcon />,
  },
  'FAST-START': {
    url: `/app/${role}/fast-start`,
    title: <IntlMessages id="producer.fastStart" />,
    icon: <ChangeHistoryOutlinedIcon className="change-history-icon" />,
  },
  'CASH-CONNECT': {
    url: `/cash-connect`,
    title: <IntlMessages id="producer.cashConnect" />,
    icon: <ChangeHistoryOutlinedIcon className="change-history-icon" />,
    has_secondary_navigation: true,
  },
  'CANOPY-CONNECT': {
    url: config.CANOPY_CONNECT_URL,
    title: <IntlMessages id="producer.existingCoverage" />,
    icon: (
      <img
        src={ExistingCoverageIcon}
        alt="Existing Coverage Icon"
        className="w-20"
      />
    ),
  },
  'LEAD-FEED': {
    url: config.LEAD_FEED_URL,
    title: <IntlMessages id="producer.leadFeed" />,
    icon: <img src={LeadFeedIcon} alt="Lead Feed Icon" className="w-20" />,
  },
  'AGENT-TOOLS': {
    url: `/agent-tools`,
    title: <IntlMessages id="producer.agentTools" />,
    icon: <img src={AgentToolsIcon} alt="Agent Tools Icon" className="w-20" />,
  },
  'HELP-CENTER': {
    url: config.HELP_CENTER_URL,
    title: <IntlMessages id="producer.helpCenter" />,
    icon: <HelpOutlineIcon />,
  },
  RATERS: {
    url: '/raters',
    title: <IntlMessages id="producer.raters" />,
    icon: (
      <svg viewBox="0 0 24 24" fill="none">
        <path
          d="M4 2H20C20.5523 2 21 2.44772 21 3V21C21 21.5523 20.5523 22 20 22H4C3.44772 22 3 21.5523 3 21V3C3 2.44772 3.44772 2 4 2ZM5 4V20H19V4H5ZM7 6H17V10H7V6ZM7 12H9V14H7V12ZM7 16H9V18H7V16ZM11 12H13V14H11V12ZM11 16H13V18H11V16ZM15 12H17V18H15V12Z"
          fill="currentColor"
        />
      </svg>
    ),
    has_secondary_navigation: true,
    pillText: 'New',
  },
});
