export const ALIGN_TYPE = {
  TOP: 'Top',
  MIDDLE: 'Middle',
  BOTTOM: 'Bottom',
};

export const TARGET_BASE = [
  {
    default: true,
    id: 1,
    name: 'Static',
    value: 'Static',
  },
  {
    default: false,
    id: 2,
    name: 'Dynamic',
    value: 'Dynamic',
  },
];
export const BUTTON_LABEL_FONT = [
  {
    default: true,
    id: 1,
    name: 'Open Sans',
    value: 'Open Sans',
  },
  {
    default: false,
    id: 2,
    name: 'Nunito Sans',
    value: 'Nunito Sans',
  },
  {
    default: false,
    id: 3,
    name: 'Arial',
    value: 'Arial',
  },
  {
    default: false,
    id: 4,
    name: 'Roboto',
    value: 'Roboto',
  },
];
export const PRODUCER_TYPE = [
  {
    default: true,
    id: 1,
    name: 'All',
    value: 'All',
  },
  {
    default: false,
    id: 2,
    name: 'Producer',
    value: 'Producer',
  },
  {
    default: false,
    id: 3,
    name: 'Sub-producer',
    value: 'Sub-producer',
  },
];
export const PRODUCER_STATUS = [
  {
    default: false,
    id: 1,
    name: 'Active',
    value: 'Active',
  },
  {
    default: true,
    id: 2,
    name: 'Inactive',
    value: 'Inactive',
  },
];
export const REQUIRED_APPOINTMENT = [
  {
    default: false,
    id: 1,
    name: 'Any',
    value: 'any',
  },
  {
    default: true,
    id: 2,
    name: 'None',
    value: 'none',
  },
  {
    default: false,
    id: 3,
    name: 'Select Carriers',
    value: 'carriers',
  },
];
export const CONDITION = [
  {
    default: true,
    id: 1,
    name: 'Before',
    value: 'Before',
  },
  {
    default: false,
    id: 2,
    name: 'After',
    value: 'After',
  },
];
export const CTA = [
  { default: true, id: 1, name: 'No', value: false },
  { default: false, id: 2, name: 'Yes', value: true },
];
export const WHOLESALE = [
  { default: true, id: 1, name: 'No', value: false },
  { default: false, id: 2, name: 'Yes', value: true },
];
export const FIRST_ACTIVATION = [
  { default: true, id: 1, name: 'No', value: false },
  { default: false, id: 2, name: 'Yes', value: true },
];
export const ASTERISK = '*';
export const RENAME = 'rename';
export const SELECT_CARRIERS = [
  { value: 'aegies', label: 'Aegies' },
  { value: 'annex', label: 'Annex' },
  { value: 'hippo', label: 'Hippo' },
];
export const PRODUCER_LIST = [
  {
    label: 'All',
    value: 'All',
  },
  {
    label: 'Active',
    value: 'Active',
  },
  {
    label: 'Pending',
    value: 'Pending',
  },
  {
    label: 'Restricted',
    value: 'Restricted',
  },
  {
    label: 'Suspend',
    value: 'Suspend',
  },
];

export const SELECT_ALL_OPTION = { value: 'All', label: 'All' };