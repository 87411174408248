import * as myCommissionActions from '../../constants/action-types';

// agent report list details fetch
export const fetchAgentReportList = payload => ({
  type: myCommissionActions.FETCH_AGENT_REPORT_LIST,
  payload,
});

// set store for fetched report list
export const setAgentReportList = details => ({
  type: myCommissionActions.SET_AGENT_REPORT_LIST,
  payload: details,
});

// set agent report list loader.
export const setAgentReportListLoader = flag => ({
  type: myCommissionActions.AGENT_REPORT_LIST_LOADER,
  payload: flag,
});

// fetch agent adhoc report
export const fetchAgentAdhocReport = payload => ({
  type: myCommissionActions.FETCH_AGENT_ADHOC_REPORT_LIST,
  payload
})

// set agent adhoc report list
export const setAgentAdhocReportList = list => ({
  type: myCommissionActions.SET_AGENT_ADHOC_REPROT_LIST,
  payload: list
})

// set agent adhoc report list loader
export const setAgentAdhocReportListloader = flag => ({
  type: myCommissionActions.AGENT_ADHOC_REPORT_LIST_LOADER,
  payload: flag
})

// get commission recommendation banner
export const getCommissionRecommendationBanner = payload => ({
  type: myCommissionActions.GET_COMMISSION_RECOMMENDATION_BANNER,
  payload
});

// set commission recommendation banner
export const setCommissionRecommendationBanner = payload => ({
  type: myCommissionActions.SET_COMMISSION_RECOMMENDATION_BANNER,
  payload
});
