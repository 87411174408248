import { all, fork, put, takeEvery } from 'redux-saga/effects';
import { displayErrorMessage } from 'util/user-feedback';
import axios from 'util/api';
import * as carrierListActions from 'constants/action-types';
import { setCarrierList, setCarrierListLoader } from '../actions/carrier-list';
import { errorHandler } from '../actions/error';

function* fetchCarrierListDetails() {
  try {
    yield put(setCarrierListLoader(true));
    const carrierDetails = yield axios.get('/api/v1/carrier/get/carriers');
    if (carrierDetails.data) {
      const carrierList = carrierDetails.data.carriers;
      yield put(setCarrierList(carrierList));
    }
  } catch (error) {
    if (!!error.response && error.response.status === 401) {
yield displayErrorMessage(error.response.data.message)
      yield put(errorHandler(error));
    } else {
      yield put(errorHandler(error));
    }
  }
  yield put(setCarrierListLoader(false));
}

export function* fetchCarrierListWatcher() {
  yield takeEvery(
    carrierListActions.FETCH_CARRIER_LIST,
    fetchCarrierListDetails
  );
}

// ------ ROOT SAGA -----------------
export default function* rootSaga() {
  yield all([fork(fetchCarrierListWatcher)]);
}