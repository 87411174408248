import React, { Component, Fragment } from 'react';
import clsx from 'clsx';
import {
  Card,
  CardActions,
  Grid,
  Input,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { InfoOutlined } from '@material-ui/icons';
import FormInput from 'components/form-input';
import HtmlTooltip from 'components/html-tooltip';
import { withStyles } from '@material-ui/core/styles';
import SearchLocationInput from 'components/search-location-input';
import SsnMask from 'components/ssn-mask';
import SubmitLoader from 'components/submit-loader';
import { ToggleSwitch } from 'components/toggle-switch';
import PhoneMask from 'components/phone-mask';
import {
  ADDRESS,
  AGENCY_DBA_NAME,
  AGENCY_DETAILS,
  AGENCY_LEGAL_NAME,
  AGENCY_LEGAL_NAME_KEY,
  AGENCY_PROFILE,
  AGNCY_DBA_NAME_KEY,
  CITY,
  FIRST_NAME,
  LAST_NAME,
  LIFE_PREMIUM,
  MAILING_ADDRESS,
  MAILING_CITY,
  MAILING_STATE,
  MAILING_ZIP_CODE,
  MAX_NAME_LENGTH,
  NEXT,
  NO,
  NOT_APPLICABLE,
  OFF,
  OWNER_FIRST_NAME,
  OWNER_LAST_NAME,
  PC_PREMIUM,
  PHONE,
  PHONE_ALREADY_EXIST,
  PLEASE_ENTER_SECURITY_RESPONSE,
  PLEASE_SELECT_SECURITY_QUESTION,
  POST_SIGNUP_STEPS_NAME,
  PRODUCER_KEY,
  SECU_RESPONSE,
  SECURITY_QUESTION,
  SECURITY_RESPONSE,
  SMALL,
  SSN_ID,
  STATE,
  SUB_PRODUCER_KEY,
  SUBMIT,
  TAX_INFO_CONTENT,
  TAX_STATUS_INFO_CONTENT,
  TOP,
  TYPE_TEXT,
  YES,
  ZIP_CODE,
  ZIP_CODE_KEY,
} from 'constants/constant';
import { APPLICATION } from 'constants/heap-events';
import { connect } from 'react-redux';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import {
  setCurrentStepIndex,
  stepSubmit,
  stepSubmitSuccess,
  submitLoaderHide,
  updateW9FormStatus,
} from 'redux/actions/post-sign-up';
import { dollarRange, securityQuestionOptions } from 'util/extra';
import { blurHandlers, changeHandlers, validators } from 'util/form-handler';
import IntlMessages from 'util/intl-messages';
import { getStateNamebyAbbreviation, stateName } from 'util/sign-up-process';
import * as FullStory from '@fullstory/browser';
import './step-2.scss';

const LightTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: '#fff',
    color: '#525252',
    boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.2)',
    fontSize: '12px',
    borderRadius: '4px',
    padding: '10px',
    width: '230px',
  },
}))(Tooltip);

const dollarRangeLH = dollarRange.concat({
  label: NOT_APPLICABLE,
  value: NOT_APPLICABLE,
});

const initState = {
  fieldsModified: false,
  formValues: {
    firstName: '',
    lastName: '',
    phone: '',
    agencyLegalName: '',
    isFullTimeAgent: YES.toLowerCase(),
    isMailingAddressSame: YES.toLowerCase(),
    mailingAddress: '',
    mailingCity: '',
    mailingState: '',
    mailingZipCode: '',
    addressState: '',
    securityQuestion: '',
    securityResponce: '',
    SSN: '',
    zipCode: '',
    city: '',
    address: '',
  },
  formErrors: {
    firstName: '',
    lastName: '',
    phone: '',
    agencyLegalName: '',
    addressState: '',
    city: '',
    zipCode: '',
    address: '',
    isFullTimeAgent: '',
    isMailingAddressSame: YES.toLowerCase(),
    mailingAddress: '',
    mailingCity: '',
    mailingState: '',
    mailingZipCode: '',
    securityQuestion: '',
    securityResponce: '',
    SSN: '',
  },
};

class Step2 extends Component {
  constructor(props) {
    super(props);
    this.isSubProducerWithProductionAccess =
      props.productionAccess && props.signupFlow === SUB_PRODUCER_KEY;
    this.state = initState;
    this.errorField = null;
    this.createRef();
  }

  componentDidMount() {
    const { agencyProfile, submitLoader, referralData, producer_code } =
      this.props;

    FullStory.setUserVars({
      producerCode: producer_code,
    });

    this.setState({
      formValues: {
        ...this.state.formValues,
        ...agencyProfile,
        addressState: getStateNamebyAbbreviation(agencyProfile.state),
        firstName: referralData?.first_name || agencyProfile.firstName,
        lastName: referralData?.last_name || agencyProfile.lastName,
        phone: referralData?.phone_number || agencyProfile.phone,
        agencyLegalName:
          referralData?.agency_name || agencyProfile.agencyLegalName,
        isFullTimeAgent: agencyProfile.isFullTimeAgent || YES.toLowerCase(),
        isMailingAddressSame:
          agencyProfile.isMailingAddressSame || YES.toLowerCase(),
      },
    });
    this.setState({
      formErrors: {
        phone: '',
      },
    });
    submitLoader && this.props.submitLoaderHide();
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    let formErrors = { ...this.state.formErrors };
    if (
      this.props.duplicatePhoneNumber !== newProps.duplicatePhoneNumber &&
      newProps.duplicatePhoneNumber
    ) {
      this.setState((prevValue) => ({
        ...prevValue,
        formErrors: {
          ...formErrors,
          phone: PHONE_ALREADY_EXIST,
        },
      }));
    }
  }

  componentDidUpdate = (_, prevState) => {
    const { producer_code } = this.props;

    // previous code was written as if producer_code could be optional value
    // verify if issue is logged - if not, remove the if condition
    if (producer_code) {
      window.heap?.identify(producer_code);
    } else {
      // eslint-disable-next-line no-console
      console.error(
        '[HEAP TRACKING] Producer code not found. Cannot identify user.'
      );
    }

    const {
      formValues: { isMailingAddressSame, mailingAddress, address, isMount },
    } = this.state;
    // if isMailingAddressSame changes to No, and values are same as address,
    // remove them else keep them.
    // isMount check stops changing address on first render.
    if (
      isMount === prevState.isMount &&
      isMailingAddressSame !== prevState.formValues.isMailingAddressSame &&
      isMailingAddressSame === NO.toLowerCase() &&
      mailingAddress === address
    ) {
      this.setState({
        fieldsModified: true,
        formValues: {
          ...this.state.formValues,
          mailingAddress: '',
          mailingCity: '',
          mailingState: '',
          mailingZipCode: '',
        },
      });
    }
  };

  createRef() {
    this.refList = {
      firstName: React.createRef(),
      lastName: React.createRef(),
      phone: React.createRef(),
      address: React.createRef(),
      city: React.createRef(),
      addressState: React.createRef(),
      zipCode: React.createRef(),
      agencyLegalName: React.createRef(),
      mailingAddress: React.createRef(),
      mailingCity: React.createRef(),
      mailingState: React.createRef(),
      mailingZipCode: React.createRef(),
    };
  }

  mailingAddressRequired = (value) => {
    const { formValues } = this.state;
    if (formValues.isMailingAddressSame === NO.toLowerCase()) {
      return validators.required(value) || validators.selectRequired(value);
    }
    return '';
  };

  validators = {
    firstName: [validators.required, validators.nameInvalid],
    lastName: [validators.required, validators.nameInvalid],
    phone: [validators.required, validators.usPhoneInvalid],
    address: [validators.required],
    city: [validators.required],
    addressState: [validators.selectRequired],
    zipCode: [validators.required, validators.zipCodeInvalid],
    agencyLegalName: [
      (value) => {
        if (!this.props.isSubProducer) {
          return validators.required(value);
        }
        return '';
      },
    ],
    mailingAddress: [this.mailingAddressRequired],
    mailingCity: [this.mailingAddressRequired],
    mailingState: [this.mailingAddressRequired],
    mailingZipCode: [
      (value) => {
        const { formValues } = this.state;
        if (formValues.isMailingAddressSame === NO.toLowerCase()) {
          return validators.required(value) || validators.zipCodeInvalid(value);
        }
        return '';
      },
    ],
    securityQuestion: [
      (value) => {
        return this.props.isSubProducer
          ? validators.selectRequired(value, PLEASE_SELECT_SECURITY_QUESTION)
          : '';
      },
    ],
    securityResponce: [
      (value) => {
        return this.props.isSubProducer
          ? validators.required(value, PLEASE_ENTER_SECURITY_RESPONSE)
          : '';
      },
    ],
    SSN: [
      // check only if subproducer with production access
      (value) => {
        if (this.isSubProducerWithProductionAccess) {
          return validators.required(value) || validators.SSNInvalid(value);
        }
        return '';
      },
    ],
    isFullTimeAgent: !this.props.isSubProducer ? [validators.required] : [],
  };

  validate = () => {
    const { formValues, formErrors } = this.state;
    let isInvalid = false;
    const newErrors = { ...formErrors };
    for (const [key, validatorList] of Object.entries(this.validators)) {
      for (const validator of validatorList) {
        const error = validator(formValues[key]);
        newErrors[key] = error;
        if (error) {
          isInvalid = true;

          if (!this.errorField) {
            this.errorField = key;
          }

          break;
        }
      }
    }

    this.setState({ formErrors: newErrors });

    return !isInvalid;
  };

  submitForm = (event) => {
    event.preventDefault();
    const { formValues, fieldsModified } = this.state;
    const {
      firstName,
      lastName,
      phone,
      agencyLegalName,
      addressState,
      zipCode,
      address,
      city,
      securityQuestion,
      securityResponce,
      SSN,
    } = formValues;
    const { tax } = this.props;
    const { signupFlow } = this.props;

    if (this.validate()) {
      if (fieldsModified) {
        this.props.updateW9FormStatus({ shouldBeSigned: true });
        this.props.stepSubmit({
          stepId: AGENCY_PROFILE,
          entity: AGENCY_PROFILE,
          completedSignupStep: POST_SIGNUP_STEPS_NAME.AGENCY_DETAILS,
          data: {
            ...formValues,
            firstName,
            phone: phone.replace(/[^\d]/g, ''),
            phone_number: phone.replace(/[^\d]/g, ''),
            lastName,
            state: addressState,
            agencyLegalName: agencyLegalName,
            zipCode,
            address,
            city,
            securityQuestion,
            securityResponce,
            SSN,
          },
          tax: signupFlow === SUB_PRODUCER_KEY ? { ...tax, SSN } : undefined,
          signupFlow,
          stepProgress: AGENCY_DETAILS,
        });
      } else {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({ event: 'registrationComplete' });
        this.props.stepSubmitSuccess();
      }

      window.heap.track(APPLICATION.DETAILS_PAGE_CLICK_SUBMIT, {
        Agents: formValues?.additionalAgents,
        [PC_PREMIUM]: dollarRange.find(
          (obj) => obj.value === formValues?.approximateTotalProduction
        )?.label,
        [LIFE_PREMIUM]: dollarRangeLH.find(
          (obj) => obj.value === formValues?.approxLifeAndHealthProduction
        )?.label,
      });
    } else {
      this.refList[this.errorField]?.current?.scrollIntoView({
        behavior: 'smooth',
      });
      this.errorField = null;
    }
  };

  setValue = (key, value, callback) => {
    // setValue is triggered even if the input was just focused and then blurred
    // Prevent toggling fieldsModified in such case
    if (this.state.formValues[key] !== value) {
      this.setState({
        fieldsModified: true,
      });
    }

    this.setState(
      {
        formValues: { ...this.state.formValues, [key]: value },
      },
      () => callback?.(key)
    );
  };

  inputChangeOnBlur = (e) => {
    const formValues = { ...this.state.formValues };
    if (e.target.name === ADDRESS || e.target.name === MAILING_ADDRESS) {
      formValues[e.target.name] = e.target.value.replace(/  +/g, ' ').trim();
    }
    this.setState((prevState) => ({
      ...prevState,
      formValues: { ...formValues },
    }));
  };

  render() {
    const { formValues, formErrors } = this.state;
    const { submitLoader, classNames, signupFlow, isSubProducer } = this.props;
    const mailingAddressSameClass =
      formValues.isMailingAddressSame === YES.toLowerCase()
        ? 'disabledField'
        : 'validate';
    const isEnabled =
      formValues.isMailingAddressSame !== YES.toLowerCase()
        ? formValues?.mailingAddress?.length > 0 &&
          formValues?.mailingCity?.length > 0 &&
          formValues?.mailingState?.length > 0 &&
          formValues?.mailingState !== 'Select' &&
          formValues?.mailingZipCode?.length > 0 &&
          formValues?.zipCode?.length > 0 &&
          formValues?.city?.length > 0 &&
          formValues?.addressState?.length > 0 &&
          formValues?.addressState !== 'Select' &&
          formValues?.address?.length > 0 &&
          formValues?.agencyLegalName?.length > 0 &&
          formValues?.firstName?.length > 0 &&
          formValues?.lastName?.length > 0 &&
          formValues?.phone?.length > 0
        : formValues?.zipCode?.length > 0 &&
          formValues?.city?.length > 0 &&
          formValues?.addressState?.length > 0 &&
          formValues?.addressState !== 'Select' &&
          formValues?.address?.length > 0 &&
          formValues?.agencyLegalName?.length > 0 &&
          formValues?.firstName?.length > 0 &&
          formValues?.lastName?.length > 0 &&
          formValues?.phone?.length > 0;
    return (
      <Fragment>
        <Card className={`signup-steps-wrap ${classNames.card}`}>
          <form className="post-signup-forms" onSubmit={this.submitForm}>
            <Grid
              spacing={0}
              container
              direction="row"
              className={`${classNames.cardContent} card-content`}
            >
              {!isSubProducer && (
                <Grid
                  xs={12}
                  item
                  sm={12}
                  className="sub-head-wrap padding-left-10"
                >
                  <h2 className="subhead">Principal Writing Agent</h2>
                </Grid>
              )}
              <Grid xs={12} item sm={6} className="p-15">
                <label
                  ref={this.refList.firstName}
                  className={clsx({
                    'label-error-color': formErrors.firstName,
                  })}
                >
                  <IntlMessages id="label.firstName" />
                  <span className="required">*</span>
                </label>
                <FormInput
                  id={OWNER_FIRST_NAME}
                  type={TYPE_TEXT}
                  className={clsx('fs-exclude', 'validate', {
                    'error-border': formErrors.firstName,
                  })}
                  name={FIRST_NAME}
                  maxLength={MAX_NAME_LENGTH}
                  disabled={submitLoader}
                  value={formValues.firstName}
                  setValue={this.setValue}
                  valueKey="firstName"
                  handleBlur={blurHandlers.startCapital}
                  error={isSubProducer && formErrors.firstName}
                />
                {!isSubProducer && (
                  <div>
                    {formErrors.firstName && (
                      <div className="error-msg height-20">
                        {formErrors.firstName}
                      </div>
                    )}
                    <span
                      className={clsx(
                        'hint-info',
                        formErrors.firstName && 'errorMsg'
                      )}
                    >
                      <IntlMessages id="name.hint" />
                    </span>
                  </div>
                )}
              </Grid>
              <Grid xs={12} item sm={6} className="p-15">
                <label
                  ref={this.refList.lastName}
                  className={clsx({
                    'label-error-color': formErrors.lastName,
                  })}
                >
                  <IntlMessages id="label.lastName" />
                  <span className="required">*</span>
                </label>
                <FormInput
                  id={OWNER_LAST_NAME}
                  type={TYPE_TEXT}
                  className={clsx('fs-exclude', 'validate', {
                    'error-border': formErrors.lastName,
                  })}
                  name={LAST_NAME}
                  maxLength={MAX_NAME_LENGTH}
                  disabled={submitLoader}
                  value={formValues.lastName}
                  setValue={this.setValue}
                  valueKey="lastName"
                  handleBlur={blurHandlers.startCapital}
                  error={isSubProducer && formErrors.lastName}
                />
                {!isSubProducer && (
                  <div>
                    {formErrors.firstName && (
                      <div className="error-msg height-20">
                        {formErrors.lastName}
                      </div>
                    )}
                    <span
                      className={clsx(
                        'hint-info',
                        formErrors.lastName && 'errorMsg'
                      )}
                    >
                      <IntlMessages id="name.hint" />
                    </span>
                  </div>
                )}
              </Grid>

              <Grid xs={12} item sm={6} className="p-15">
                <label
                  ref={this.refList.address}
                  className={clsx({ 'label-error-color': formErrors.address })}
                >
                  <IntlMessages id="label.address" />
                  <span className="required">*</span>
                </label>
                <SearchLocationInput
                  id={ADDRESS}
                  type={TYPE_TEXT}
                  className={clsx('fs-exclude', 'validate', {
                    'error-border': formErrors.address,
                  })}
                  name={ADDRESS}
                  maxLength={150}
                  disabled={submitLoader}
                  value={formValues.address}
                  setValue={this.setValue}
                  mappingKeys={{
                    city: 'city',
                    zip: 'zipCode',
                    state: 'addressState',
                  }}
                  onBlur={this.inputChangeOnBlur}
                  valueKey="address"
                  error={formErrors.address}
                  handleChange={changeHandlers.addressString}
                />
              </Grid>

              <Grid xs={12} item sm={6} className="p-15">
                <label
                  ref={this.refList.city}
                  className={clsx({ 'label-error-color': formErrors.city })}
                >
                  <IntlMessages id="admin.city" />
                  <span className="required">*</span>
                </label>
                <FormInput
                  id={CITY}
                  type={TYPE_TEXT}
                  className={clsx('fs-exclude', 'validate', {
                    'error-border': formErrors.city,
                  })}
                  name={CITY}
                  maxLength={50}
                  disabled={submitLoader}
                  value={formValues.city}
                  setValue={this.setValue}
                  valueKey="city"
                  handleChange={changeHandlers.alphaString}
                  handleBlur={changeHandlers.trim}
                  error={formErrors.city}
                />
              </Grid>
              <Grid xs={6} item sm={3} className="p-15">
                <label
                  ref={this.refList.addressState}
                  className={clsx({
                    'label-error-color': formErrors.addressState,
                  })}
                >
                  <IntlMessages id="label.state" />
                  <span className="required">*</span>
                </label>
                <div className="select-wrapper">
                  <select
                    className={clsx('fs-exclude', 'select-dropdown', {
                      'error-border': formErrors.addressState,
                    })}
                    value={formValues.addressState}
                    onChange={(event) => {
                      this.setValue('addressState', event.target.value);
                    }}
                    name={STATE}
                    valueKey="addressState"
                    disabled={submitLoader}
                  >
                    {stateName.map((data) => (
                      <option key={data.label} value={data.value}>
                        {data.value}
                      </option>
                    ))}
                  </select>
                  <div className="error-msg">{formErrors.addressState}</div>
                </div>
              </Grid>
              <Grid xs={6} item sm={3} className="p-15">
                <label
                  ref={this.refList.zipCode}
                  className={clsx({ 'label-error-color': formErrors.zipCode })}
                >
                  <IntlMessages id="label.zipCode" />
                  <span className="required">*</span>
                </label>
                <FormInput
                  id={ZIP_CODE_KEY}
                  type={TYPE_TEXT}
                  className={clsx('fs-exclude', 'validate', {
                    'error-border': formErrors.zipCode,
                  })}
                  name={ZIP_CODE}
                  maxLength={5}
                  disabled={submitLoader}
                  value={formValues.zipCode}
                  setValue={this.setValue}
                  valueKey="zipCode"
                  handleChange={changeHandlers.numberString}
                  error={formErrors.zipCode}
                />
              </Grid>
              <Grid xs={12} item sm={6} className="p-15">
                <label
                  ref={this.refList.phone}
                  className={clsx({ 'label-error-color': formErrors.phone })}
                >
                  <IntlMessages id="appModule.phone" />
                  <span className="required">*</span>
                </label>
                <Input
                  autoComplete={OFF}
                  value={formValues.phone}
                  onChange={(e) => {
                    this.setValue('phone', e.target.value);
                  }}
                  name={PHONE}
                  id={PHONE}
                  className={clsx('phone', {
                    'error-border': formErrors.phone,
                  })}
                  inputProps={{
                    className: 'fs-exclude',
                  }}
                  inputComponent={PhoneMask}
                  disabled={submitLoader}
                />
                <div className="error-msg">{formErrors.phone}</div>
              </Grid>
              {!isSubProducer && (
                <>
                  <Grid xs={12} item sm={6} className="p-15">
                    <label
                      className={clsx('agency-name-field', {
                        'label-error-color': formErrors.agencyLegalName,
                      })}
                      ref={this.refList.agencyLegalName}
                    >
                      <IntlMessages id="label.agencyName" />
                      <span className="required">*</span>
                      <span className="info-icon">
                        <LightTooltip
                          title={<IntlMessages id="profileInfo.hint" />}
                          placement="top"
                          fontSize="small"
                          disableTouchListener
                        >
                          <InfoOutlinedIcon className="info-icon" />
                        </LightTooltip>
                      </span>
                    </label>
                    <FormInput
                      id={AGENCY_LEGAL_NAME}
                      type={TYPE_TEXT}
                      className={clsx('fs-exclude', 'validate', {
                        'error-border': formErrors.agencyLegalName,
                      })}
                      name={AGENCY_LEGAL_NAME_KEY}
                      maxLength={150}
                      disabled={submitLoader}
                      value={formValues.agencyLegalName}
                      setValue={this.setValue}
                      valueKey="agencyLegalName"
                      handleChange={changeHandlers.alphaNumeric}
                      handleBlur={changeHandlers.trim}
                      error={formErrors.agencyLegalName}
                    />
                  </Grid>
                  <Grid xs={12} item sm={6} className="p-15">
                    <label>
                      <IntlMessages id="label.agency.dba.name" />
                    </label>
                    <FormInput
                      id={AGENCY_DBA_NAME}
                      type={TYPE_TEXT}
                      className="fs-exclude validate"
                      name={AGNCY_DBA_NAME_KEY}
                      maxLength={150}
                      disabled={submitLoader}
                      value={formValues.agencyDbaName}
                      setValue={this.setValue}
                      valueKey="agencyDbaName"
                      handleChange={changeHandlers.alphaNumeric}
                      handleBlur={changeHandlers.trim}
                    />
                  </Grid>
                </>
              )}
              <Grid xs={12} item sm={6} className="p-15">
                <Grid container>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    className="d-flex align-items-center"
                  >
                    <label>
                      {signupFlow === PRODUCER_KEY ? (
                        <IntlMessages id="label.agencyMailSameAddress" />
                      ) : (
                        <IntlMessages id="label.mailing.address" />
                      )}
                    </label>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    className="p-15 grey-toggle"
                    style={{ marginLeft: '-6%' }}
                  >
                    <ToggleSwitch
                      onChange={(event) => {
                        this.setValue(
                          'isMailingAddressSame',
                          event.target.checked
                            ? NO.toLowerCase()
                            : YES.toLowerCase()
                        );
                      }}
                      text={formValues.isMailingAddressSame.toUpperCase()}
                      checked={
                        formValues.isMailingAddressSame === YES.toLowerCase()
                          ? false
                          : true
                      }
                      disabled={submitLoader}
                    />
                  </Grid>
                </Grid>
              </Grid>
              {this.isSubProducerWithProductionAccess && (
                <>
                  <Grid xs={12} item sm={6}>
                    <label
                      className={clsx({ 'label-error-color': formErrors.SSN })}
                    >
                      <IntlMessages id="label.agent.ssn" />
                      <span className="required">*</span>
                    </label>
                  </Grid>

                  <Grid xs={12} className="input-field" sm={6} item>
                    <div className="ssnInfoIcon">
                      <FormInput
                        id={SSN_ID}
                        name={SSN_ID}
                        inputProps={{
                          className: 'fs-exclude',
                        }}
                        className={clsx('validate', {
                          'error-border': formErrors.SSN,
                        })}
                        fullWidth={true}
                        type={TYPE_TEXT}
                        value={formValues.SSN}
                        inputComponent={SsnMask}
                        setValue={this.setValue}
                        disabled={submitLoader}
                        handleChange={changeHandlers.default}
                        error={formErrors.SSN ? true : false}
                        valueKey="SSN"
                      />
                      <HtmlTooltip
                        title={
                          <>
                            <span>{TAX_STATUS_INFO_CONTENT}</span>
                            <br />
                            <span>{TAX_INFO_CONTENT}</span>
                          </>
                        }
                        placement={TOP}
                        fontSize={SMALL}
                        disableTouchListener
                      >
                        <InfoOutlined className="text-blue ssnInfoMargin pointer" />
                      </HtmlTooltip>
                    </div>
                    <div className="error-msg">{formErrors.SSN}</div>
                  </Grid>
                </>
              )}
              {!isSubProducer && (
                <Grid xs={12} item sm={6} className="p-15">
                  <Grid container>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      className="d-flex align-items-center"
                    >
                      <label>
                        <IntlMessages id="label.fullTimeAgent" />
                      </label>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      className="p-15 grey-toggle"
                      style={{ marginLeft: '-6%' }}
                    >
                      <ToggleSwitch
                        onChange={(event) => {
                          this.setValue(
                            'isFullTimeAgent',
                            event.target.checked
                              ? NO.toLowerCase()
                              : YES.toLowerCase()
                          );
                        }}
                        text={formValues.isFullTimeAgent.toUpperCase()}
                        checked={
                          formValues.isFullTimeAgent === YES.toLowerCase()
                            ? false
                            : true
                        }
                        disabled={submitLoader}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              )}
              {formValues.isMailingAddressSame === NO.toLowerCase() && (
                <>
                  <Grid
                    xs={12}
                    spacing={0}
                    className={`${classNames.cardContent} mailling-addres-wrap d-flex justify-content-center`}
                    justifyContent="center"
                  >
                    <Grid xs={12} container direction="row">
                      <Grid xs={12} item sm={12} className="pb-0 p-15">
                        <Typography className="mailing-heading">
                          Mailing Address
                        </Typography>
                      </Grid>
                      <Grid xs={12} item sm={12} className="p-15">
                        <label
                          ref={this.refList.mailingAddress}
                          className={clsx({
                            'label-error-color': formErrors.mailingAddress,
                          })}
                        >
                          <IntlMessages id="label.address" />
                          <span className="required">*</span>
                        </label>
                        <SearchLocationInput
                          id={MAILING_ADDRESS}
                          name={MAILING_ADDRESS}
                          type={TYPE_TEXT}
                          className={clsx(
                            'fs-exclude',
                            mailingAddressSameClass,
                            { 'error-border': formErrors.mailingAddress }
                          )}
                          maxLength={150}
                          disabled={submitLoader}
                          value={formValues.mailingAddress}
                          setValue={this.setValue}
                          mappingKeys={{
                            city: 'mailingCity',
                            zip: 'mailingZipCode',
                            state: 'mailingState',
                          }}
                          valueKey="mailingAddress"
                          error={formErrors.mailingAddress}
                          onBlur={this.inputChangeOnBlur}
                          handleChange={changeHandlers.addressString}
                        />
                      </Grid>
                      <Grid xs={12} item sm={6} className="p-15">
                        <label
                          ref={this.refList.mailingCity}
                          className={clsx({
                            'label-error-color': formErrors.address,
                          })}
                        >
                          <IntlMessages id="label.city" />
                          <span className="required">*</span>
                        </label>
                        <FormInput
                          id={MAILING_CITY}
                          type={TYPE_TEXT}
                          isMailingAddressSameClass
                          className={clsx(
                            'fs-exclude',
                            mailingAddressSameClass,
                            { 'error-border': formErrors.mailingCity }
                          )}
                          name={MAILING_CITY}
                          maxLength={50}
                          disabled={submitLoader}
                          value={formValues.mailingCity}
                          setValue={this.setValue}
                          valueKey="mailingCity"
                          handleChange={changeHandlers.alphaString}
                          handleBlur={blurHandlers.trim}
                          error={formErrors.mailingCity}
                        />
                      </Grid>
                      <Grid xs={6} item sm={3} className="p-15">
                        <label
                          ref={this.refList.mailingState}
                          className={clsx({
                            'label-error-color': formErrors.mailingState,
                          })}
                        >
                          <IntlMessages id="label.state" />
                          <span className="required">*</span>
                        </label>
                        <div className="select-wrapper">
                          <select
                            className={clsx('fs-exclude', 'select-dropdown', {
                              'error-border': formErrors.mailingState,
                            })}
                            value={formValues.mailingState}
                            onChange={(event) => {
                              this.setValue('mailingState', event.target.value);
                            }}
                            name={MAILING_STATE}
                            disabled={submitLoader}
                          >
                            {stateName.map((data) => (
                              <option key={data.label} value={data.value}>
                                {data.value}
                              </option>
                            ))}
                          </select>
                          <div className="error-msg">
                            {formErrors.mailingState}
                          </div>
                        </div>
                      </Grid>
                      <Grid xs={6} item sm={3} className="p-15">
                        <label
                          ref={this.refList.mailingZipCode}
                          className={clsx({
                            'label-error-color': formErrors.address,
                          })}
                        >
                          <IntlMessages id="label.zipCode" />
                          <span className="required">*</span>
                        </label>
                        <FormInput
                          id={MAILING_ZIP_CODE}
                          type={TYPE_TEXT}
                          className={clsx(
                            'fs-exclude',
                            mailingAddressSameClass,
                            { 'error-border': formErrors.mailingZipCode }
                          )}
                          name={MAILING_ZIP_CODE}
                          maxLength={5}
                          disabled={submitLoader}
                          value={formValues.mailingZipCode}
                          setValue={this.setValue}
                          valueKey="mailingZipCode"
                          handleChange={changeHandlers.numberString}
                          error={formErrors.mailingZipCode}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              )}

              {isSubProducer && (
                <>
                  <Grid xs={12} item sm={5}>
                    <label
                      className={clsx({
                        'label-error-color': formErrors.securityQuestion,
                      })}
                    >
                      <IntlMessages id="label.security.question" />
                      <span className="required">*</span>
                    </label>
                  </Grid>
                  <Grid xs={12} className="input-field" sm={7} item>
                    <div className="select-wrapper">
                      <select
                        className={clsx('select-dropdown', {
                          'error-border': formErrors.securityQuestion,
                        })}
                        value={formValues.securityQuestion}
                        disabled={submitLoader}
                        onChange={(event) =>
                          this.setValue('securityQuestion', event.target.value)
                        }
                        name={SECURITY_QUESTION}
                      >
                        {securityQuestionOptions.map((opt) => (
                          <option key={opt.label} value={opt.value}>
                            {opt.value}
                          </option>
                        ))}
                      </select>
                      <div className="error-msg">
                        {formErrors.securityQuestion}
                      </div>
                    </div>
                  </Grid>

                  <Grid xs={12} item sm={5}>
                    <label
                      className={`${
                        formErrors.securityResponce ? 'label-error-color' : ''
                      }`}
                    >
                      <IntlMessages id="label.security.response" />
                      <span className="required">*</span>
                    </label>
                  </Grid>
                  <Grid xs={12} className="input-field" sm={7} item>
                    <FormInput
                      id={SECU_RESPONSE}
                      type={TYPE_TEXT}
                      className={`validate ${
                        formErrors.securityResponce ? 'error-border' : ''
                      }`}
                      name={SECURITY_RESPONSE}
                      maxLength={150}
                      disabled={submitLoader}
                      value={formValues.securityResponce}
                      setValue={this.setValue}
                      valueKey="securityResponce"
                      handleBlur={blurHandlers.trim}
                      error={formErrors.securityResponce}
                    />
                  </Grid>
                </>
              )}
            </Grid>
            <CardActions
              className="signup-btn-action-card"
              style={{
                top: `${this.props.visualViewportHeight}px`,
              }}
            >
              <Grid xs={12} item sm={12} className="p-0">
                <div
                  className={`btn-wrap signup-button-wrap mt-0 ${classNames.nextBtn}`}
                >
                  <button
                    type={SUBMIT}
                    className={`btn-width postsignup-next ${
                      !isEnabled ? 'disable-button-grey' : ''
                    }`}
                    id={NEXT}
                    disabled={submitLoader || !isEnabled}
                  >
                    <span>
                      <IntlMessages id="appModule.next" />
                    </span>
                    {submitLoader && (
                      <SubmitLoader className={classNames.submitLoader} />
                    )}
                  </button>
                </div>
              </Grid>
            </CardActions>
          </form>
        </Card>
      </Fragment>
    );
  }
}

const mapStateToProps = ({
  postSignup: {
    agencyProfile,
    submitLoader,
    currentStepIndex,
    isSignedUpByReferral,
    referralData,
    producer_code,
  },
  auth: { signupFlow, fkParentProducerId },
  myAccount: { duplicatePhoneNumber },
}) => ({
  agencyProfile,
  currentStepIndex,
  signupFlow,
  submitLoader,
  isSignedUpByReferral,
  referralData,
  producer_code,
  fkParentProducerId,
  duplicatePhoneNumber,
});

export default connect(mapStateToProps, {
  stepSubmit,
  setCurrentStepIndex,
  stepSubmitSuccess,
  submitLoaderHide,
  updateW9FormStatus,
})(Step2);
