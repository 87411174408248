import { all, fork, put, select, takeEvery } from 'redux-saga/effects';
import { displayErrorMessage, displaySuccessMessage } from 'util/user-feedback';
import { updateDefaultPasswordFlag } from '../actions/auth';
import {
  changePasswordCompleted,
  setIsTokenValue,
  setPasswordUpdateLoader,
  setValidEmailTokenLoader,
  updatePasswordEmailSend,
} from '../actions/update-password';
import * as updatePasswordTypes from 'constants/action-types';
import axios from 'util/api';
import { decryptData } from 'util/crypto';
import { getAuthDetails } from 'util/extra';

// when recover password using email
function* sendForgotPassword({ payload }) {
  yield put(setPasswordUpdateLoader(true));
  let passwordUpdate = {
    success: false,
    responseStatus: null,
  };
  try {
    const response = yield axios.post('/api/v1/producer/forgot-password', {
      producer_email: payload.producerEmail,
      captchaToken: payload.captchaToken,
    });
    if (response.status === 200 && response.data) {
      passwordUpdate.success = true;
      passwordUpdate.responseStatus = response.status;
      passwordUpdate.responseMessage = response.data.message;
      yield put(updatePasswordEmailSend(passwordUpdate));
    }
  } catch (error) {
    passwordUpdate.success = false;
    passwordUpdate.responseStatus = error.response.data.error;
    passwordUpdate.responseMessage = error.response.data.message;
    yield put(updatePasswordEmailSend(passwordUpdate));

    if (error.response) {
      yield displayErrorMessage(error.response.data.message)
    } else {
      yield displayErrorMessage(error.message);
    }
  }
  yield put(setPasswordUpdateLoader(false));
}

export function* sendForgotPasswordLinkWatcher() {
  yield takeEvery(updatePasswordTypes.FORGOT_PASSWORD, sendForgotPassword);
}

function* sendUpdatePassword({ payload }) {
  yield put(setPasswordUpdateLoader(true));
  const reduxState = yield select();
  const { auth, impersonate } = reduxState;
  const { producer, isImpersonate } = impersonate;
  const { updateDefaultPassword } = getAuthDetails({
    auth,
    producer,
    isImpersonate,
  });
  try {
    const {
      passwordSettings: { passwordToken },
    } = yield select();

    // condition is for passowrd change from email or from login menu.
    if (passwordToken) {
      // set decrypted token to update the password.
      const options = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${decryptData(passwordToken)}`,
        },
      };

      // send updated password data
      const response = yield axios.post(
        '/api/v1/producer/update-password',
        {
          ...payload,
        },
        options
      );
      // provide user feedback related to updated password
      if (response.status === 200 && response.data) {
        yield put(changePasswordCompleted(true));
        if (updateDefaultPassword) {
          yield put(updateDefaultPasswordFlag(false));
        }
      }
    } else {
      const response = yield axios.post('/api/v1/producer/update-password', {
        ...payload,
      });
      if (response.status === 200 && response.data) {
        yield put(changePasswordCompleted(true));
        yield displaySuccessMessage(response.data.message);
      }
    }
    // check response
  } catch (error) {
    if (error.response) {
yield displayErrorMessage(error.response.data.message)
    } else {
      yield displayErrorMessage(error.message);
    }
  }
  yield put(setPasswordUpdateLoader(false));
}

export function* updatePasswordWatcher() {
  yield takeEvery(updatePasswordTypes.CHANGE_PASSWORD, sendUpdatePassword);
}

export function* validateEmailToken({ payload }) {
  yield put(setValidEmailTokenLoader(true));
  let value = {
    isTokenValid: false,
    producerEmail: null,
    retryable: true,
  };
  try {
    const response = yield axios.post('/api/v1/producer/validate-email-token', {
      token: payload,
    });
    value.isTokenValid = response?.data?.isTokenValid;
    value.producerEmail = response?.data?.producerEmail;
    value.retryable = response?.data?.retryable;
    yield put(setIsTokenValue(value));
  } catch (error) {
    if (error.response) {
yield displayErrorMessage(error.response.data.message)
    } else {
      yield displayErrorMessage(error.message);
    }
  }
  yield put(setValidEmailTokenLoader(false));
}

export function* validateEmailTokenWatcher() {
  yield takeEvery(updatePasswordTypes.VALIDATE_EMAIL_TOKEN, validateEmailToken);
}

// ------ ROOT SAGA -----------------
export default function* rootSaga() {
  yield all([
    fork(sendForgotPasswordLinkWatcher),
    fork(updatePasswordWatcher),
    fork(validateEmailTokenWatcher),
  ]);
}
