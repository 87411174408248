import { all, fork, put, select, takeEvery } from 'redux-saga/effects';
import {
  setIncentiveDetails,
  setIncentiveDetailsLoader,
  setIncentiveEarningDetails,
} from '../actions/incentives';
import axios from 'util/api';
import * as bannerIncentive from 'constants/action-types';
import { getAuthDetails, showIncentiveDashboardBanner } from 'util/extra';
import { getImpersonateBFFRequestConfig } from './impersonate';
import { errorHandler } from '../actions/error';
import { PORTAL } from 'constants/heap-events';
import { isEmpty } from 'lodash';
import config from 'config';

// Get q3/q4-incentive details
export function* fetchBannerIncentiveDetails({ payload }) {
  try {
    yield put(setIncentiveDetailsLoader(true));
    const store = yield select();
    const { auth, impersonate, incentive: incentives } = store;
    const { producer, isImpersonate } = impersonate;
    const { producer_id } = getAuthDetails({
      auth,
      producer,
      isImpersonate,
    });

    const { q3DaysRemaining, q4DaysRemaining } = incentives;
    const showQ3IncentiveBanner = showIncentiveDashboardBanner(
      payload === 'Q3' ? q3DaysRemaining : q4DaysRemaining
    );

    const requestConfig = yield getImpersonateBFFRequestConfig();
    const responseData = yield axios.get(
      `${config.BFF_URL}/api/v1/incentive/producer/${producer_id}`,
      requestConfig
    );
    if (!!responseData?.data) {
      let producerContext = {};
      if (!isEmpty(responseData.data?.internalIncentives)) {
        responseData.data?.internalIncentives.forEach((item) => {
          if (
            (item.code === 'Q3' && payload === 'Q3') ||
            (item.code === 'Q4' && payload === 'Q4')
          ) {
            producerContext = item.producerContext;
          }
        });
        if (!isEmpty(producerContext)) {
          if (showQ3IncentiveBanner) {
            window.heap.track(
              payload === 'Q3'
                ? PORTAL.DASHBOARD_Q3_INCENTIVE_VIEW
                : PORTAL.DASHBOARD_Q4_INCENTIVE_VIEW
            );
          }
          yield put(setIncentiveDetails(producerContext));
          if (payload === 'Q3') {
            localStorage.setItem(
              'q3TotalEarnings',
              producerContext.totalEarnings
            );
          }
        }
      }
    }
  } catch (error) {
    yield put(errorHandler(error));
  }
  yield put(setIncentiveDetailsLoader(false));
}

export function* fetchBannerIncentiveDetailsWatcher() {
  yield takeEvery(
    bannerIncentive.FETCH_Q3_INCENTIVE_DETAIL,
    fetchBannerIncentiveDetails
  );
}

export function* fetchIncentiveEarning() {
  try {
    const requestConfig = yield getImpersonateBFFRequestConfig();
    const responseData = yield axios.get(
      `${config.BFF_URL}/api/v1/incentive/earnings`,
      requestConfig
    );
    if (!!responseData?.data) {
      const { fastStart } = responseData?.data;
      yield put(setIncentiveEarningDetails(fastStart));
    }
  } catch (error) {
    yield put(errorHandler(error));
  }
}

export function* fetchIncentiveEarningWatcher() {
  yield takeEvery(
    bannerIncentive.FETCH_INCENTIVE_EARNING,
    fetchIncentiveEarning
  );
}

export default function* rootSaga() {
  yield all([fork(fetchBannerIncentiveDetailsWatcher)]);
  yield all([fork(fetchIncentiveEarningWatcher)]);
}
