// @ts-check

import React from 'react';
import ABTastyFlagshipProvider from '@flagship.io/react-sdk';
import config from 'config';
import { FLAGSHIP } from 'constants/heap-events';
import { GlobalAuthLoader } from '../../components/global-loader';

// https://docs.developers.flagship.io/docs/react-js-reference#loglevel
const LOG_LEVEL = process.env.NODE_ENV === 'production' ? 0 : 4;

/**
 * Short notice on Flagship implementation in legacy.
 * This implementation differs from new FE and is simplified.
 * The reason for this is that the new FE requires to wait for the user data to load
 * before fetching flags.
 * Here, achieving the same behaviour would be difficult due to state of the code,
 * and frankly, it's not required in the legacy FE.
 */

/**
 * @param {object} props
 * @param {string | undefined} props.producerCode
 * @param {string | undefined} props.userRole
 * @param {React.ReactNode} props.children
 */
function FlagshipProvider({ producerCode, userRole, children }) {
  // @ts-expect-error config can't resolve envs coming from appConfig.js
  if (!config.FLAGSHIP_API_KEY || !config.FLAGSHIP_ENV_ID) {
    console.warn(
      'Flagship not initialized. Missing FLAGSHIP_API_KEY and/or FLAGSHIP_ENV_ID'
    );
    return <>{children}</>;
  }

  return (
    <ABTastyFlagshipProvider
      // @ts-expect-error config can't resolve envs coming from appConfig.js
      envId={config.FLAGSHIP_ENV_ID}
      // @ts-expect-error config can't resolve envs coming from appConfig.js
      apiKey={config.FLAGSHIP_API_KEY}
      visitorData={{
        isAuthenticated: Boolean(producerCode),
        id: producerCode,
        hasConsented: true,
        context: {
          userRole: userRole ?? '',
        },
      }}
      logLevel={LOG_LEVEL}
      loadingComponent={<GlobalAuthLoader />}
      onVisitorExposed={handleABHeapTracking}
    >
      {children}
    </ABTastyFlagshipProvider>
  );
}

/**
 * @param {import('@flagship.io/react-sdk').OnVisitorExposed} visitor
 */
function handleABHeapTracking(visitor) {
  const { metadata, value } = visitor.fromFlag;
  if (metadata.campaignType !== 'ab') {
    return;
  }

  window.heap.track(FLAGSHIP.EXPERIMENT_SEEN, {
    ...metadata,
    flagValue: value,
  });
}

export default FlagshipProvider;
