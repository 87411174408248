export const ORGANIZATION_DETAILS = {
  abbreviation: 'FC',
  organizationCode: 'first-connect',
  footer: 'First Connect Insurance',
  logo: 'https://dlgpvk3jc64o0.cloudfront.net/static-assets/FC_black.svg',
  logoSmall:
    'https://dte8uermb9uev.cloudfront.net/static-assets/first-connect/firstconnect-logo-small.png',
  organizationName: 'First Connect',
  privacyPolicy: 'https://www.firstconnectinsurance.com/privacy-policy/',
  termsAndConditions: 'https://www.firstconnectinsurance.com/terms-conditions/',
};
