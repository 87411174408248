import * as types from '../../constants/action-types';

const initState = () => ({
  initURL: '',
  adhocPaymentLoaderFlag: false,
  adhocPaymentList: [],
  uploaded: false,
});

export default (state = initState(), action) => {
  switch (action.type) {
    case types.INIT_URL: {
      return { ...state, initURL: action.payload };
    }

    case types.ADHOC_PAYMENT_LOADER: {
      return {
        ...state,
        adhocPaymentLoaderFlag: action.payload,
      };
    }

    case types.ADHOC_PAYMENT_ADD_ROW_SUCCESS: {
      return {
        ...state,
        adhocPaymentList: [action.payload, ...state.adhocPaymentList],
      };
    }

    case types.ADHOC_PAYMENT_EDIT_TITLE_SUCCESS: {
      return {
        ...state,
        adhocPaymentList: [...action.payload],
      };
    }

    case types.ADHOC_PAYMENT_FILE_UPLOAD_SUCCESS: {
      const { index, data } = action.payload;
      let adhocPaymentListData = state.adhocPaymentList;
      let muteobj = { ...adhocPaymentListData[index] };

      muteobj = {
        ...muteobj,
        uploaded_file: data.file,
        processed_at: data.processed_at,
        processed_by: data.processed_by,
        file_processing_status: data.file_processing_status,
        generated_file: data.generated_file,
        processed_records: data.processed_records,
        summary_statement: data.summary_statement,
        total_records: data.total_records,
        status: data.status,
      };
      adhocPaymentListData[index] = muteobj;
      return {
        ...state,
        adhocPaymentList: [...adhocPaymentListData],
        uploaded: true,
      };
    }

    default:
      return state;
  }
};
