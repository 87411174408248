import React, { Component } from 'react';
import clsx from 'clsx';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import SubmitLoader from 'components/submit-loader';
import { isEmpty, isUndefined } from 'lodash';
import PropTypes from 'prop-types';
import {
  AGENT_MESSAGING,
  AGREEMENT,
  APPOINTED_DOCUMENT,
  INSTRUCTION_DOCUMENT,
  PDF,
  SELECT_KEY,
} from 'constants/constant';
import Tooltip from '@material-ui/core/Tooltip';
import CloseIcon from '@material-ui/icons/Close';
import PublishIcon from '@material-ui/icons/Publish';
import IntlMessages from 'util/intl-messages';
import { globalMessages } from 'util/extra';
import { displayInfoMessage } from 'util/user-feedback';

const allowedFileTypes = {
  [AGREEMENT]: [
    'image/x-png',
    'image/png',
    'image/jpg',
    'image/jpeg',
    'application/pdf',
  ],
  [PDF]: ['application/pdf'],
  [INSTRUCTION_DOCUMENT]: [
    'image/jpg',
    'image/jpeg',
    'image/png',
    'application/pdf',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  ],
  [APPOINTED_DOCUMENT]: [
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.ms-excel',
  ],
  [AGENT_MESSAGING]: [
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.ms-excel',
  ],
};

class Dropzone extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hightlight: false,
      fileName: '',
    };

    this.fileInputRef = React.createRef();
    this.onBind();
  }

  onBind() {
    this.openFileDialog = this.openFileDialog.bind(this);
    this.onFileAdded = this.onFileAdded.bind(this);
    this.onDragOver = this.onDragOver.bind(this);
    this.onDragLeave = this.onDragLeave.bind(this);
    this.onDrop = this.onDrop.bind(this);
  }

  openFileDialog() {
    if (this.props.checkDashboard) {
      if (this.props.typesOfFile === SELECT_KEY) {
        this.props.fileErrorCheck(true);
        return;
      } else {
        if (this.props.disabled) return;
        this.fileInputRef.current.click();
        this.fileInputRef.current.value = '';
        this.props.fileErrorCheck(false);
      }
    } else {
      if (this.props.disabled) return;
      this.fileInputRef.current.click();
      this.fileInputRef.current.value = '';
    }
  }

  onFileAdded(evt) {
    const { typesOfFile } = this.props;
    const files = evt?.target?.files;
    const { type } = files[0];
    const allowedFilesTypes = allowedFileTypes[typesOfFile];
    const validExts = ['.xlsx', '.xls'];

    if (typesOfFile === APPOINTED_DOCUMENT) {
      let fileExt = evt.target.value;
      fileExt = fileExt.substring(fileExt.lastIndexOf('.'));
      if (validExts.indexOf(fileExt) < 0) {
        const message = globalMessages.invalidUploadFile.replace(
          /__PLACEHOLDER__/g,
          validExts.toString()
        );
        displayInfoMessage(message);
        return false;
      }
    }

    if (
      allowedFilesTypes &&
      !allowedFilesTypes.some(allowedType => allowedType === type)
    ) {
      this.props.isAgeementFileTypeError();
      this.onClearFile();
      return;
    }

    if (this.props.disabled || evt?.target?.files?.length < 1) {
      this.onFileExist('');
      return;
    }

    if (this.props.onFileAdded) {
      const array = this.fileListToArray(files);
      this.props.onFileAdded(array);
      this.setState({ fileName: array[0].name });
    }
  }

  onDragOver(evt) {
    evt.preventDefault();
    if (this.props.disabled) return;
    this.setState({ hightlight: true });
  }

  onDragLeave() {
    this.setState({ hightlight: false });
  }

  onClearFile() {
    this.fileInputRef.current.value = '';
    this.setState({ fileName: null });
    this.props.onFileAdded([]);
  }

  onFileExist(fileName) {
    if (this.props.disabled) {
      return;
    }

    this.setState({ fileName });
  }

  onDrop(event) {
    event.preventDefault();
    const { typesOfFile } = this.props;
    const files = event?.dataTransfer?.files;
    const { type } = files[0];
    const validExts = ['.xlsx', '.xls'];
    const allowedFilesTypes = allowedFileTypes[typesOfFile];

    if (typesOfFile === APPOINTED_DOCUMENT) {
      let fileExt = files[0]?.name;
      fileExt = fileExt.substring(fileExt.lastIndexOf('.'));
      if (validExts.indexOf(fileExt) < 0) {
        const message = globalMessages.invalidUploadFile.replace(
          /__PLACEHOLDER__/g,
          validExts.toString()
        );
        displayInfoMessage(message);
        return false;
      }
    }

    if (
      allowedFilesTypes &&
      !allowedFilesTypes.some(allowedType => allowedType === type)
    ) {
      this.props.isAgeementFileTypeError();
      this.onClearFile();
    } else {
      if (this.props.disabled) return;
      if (this.props.onFileAdded) {
        const array = this.fileListToArray(files);
        this.props.onFileAdded(array);
        this.setState({ fileName: array[0].name });
      }
      this.setState({ hightlight: false });
    }
  }

  fileListToArray(list) {
    const array = [];
    for (var i = 0; i < list.length; i++) {
      array.push(list.item(i));
    }
    return array;
  }

  render() {
    const { fileName } = this.state;
    const {
      documentDownloadLoader,
      documentLoader,
      isLoading,
      buttonName,
    } = this.props;

    return (
      <>
        <div
          className={clsx('btn-file', 'document-btn-file', {
            Highlight: this.state.hightlight,
            disabledComponent: this.props.disabled,
            'disabled-on-download': documentDownloadLoader || documentLoader,
          })}
          onDragOver={this.onDragOver}
          onDragLeave={this.onDragLeave}
          onDrop={this.onDrop}
          onClick={this.openFileDialog}
        >
          <input
            type="file"
            ref={this.fileInputRef}
            className={this.props.componentClass}
            name={this.props.componentName}
            onChange={this.onFileAdded}
            disabled={!!this?.props?.disabled}
            accept={allowedFileTypes[this.props.typesOfFile]?.join(', ')}
          />
          <label
            htmlFor="w9FileUpload"
            className={`text-ellipsis file div mob-dropzone btn-filed-flex d-flex align-items-center justify-content-center ${
              this.props.disabled
                ? 'text-disable-grey default disabledComponent'
                : ''
            }`}
          >
            {this.props.typesOfFile === APPOINTED_DOCUMENT ? (
              <>
                {isEmpty(fileName) && (
                  <Tooltip
                    enterDelay={100}
                    leaveDelay={200}
                    title={<IntlMessages id="label.uploadFile" />}
                    aria-label="upload file"
                  >
                    <PublishIcon
                      fontSize="small"
                      className="text-blue"
                    ></PublishIcon>
                  </Tooltip>
                )}
              </>
            ) : (
              <>
                {!buttonName &&
                  (!fileName ? (
                    <span className="icon-upload-icon"></span>
                  ) : fileName === this.props.defaultText ? (
                    <span className="icon-download"></span>
                  ) : (
                    <CheckCircleOutlineIcon
                      fontSize="small"
                      className="orange-text mr-2"
                    />
                  ))}

                {buttonName && buttonName === 'change' && (
                  <span className="icon-upload-icon"></span>
                )}

                <span
                  className={`text-ellipsis grey-text &{ this.props.disabled ? 'text-disable-grey' : 'text-blue'}`}
                >
                  {!buttonName
                    ? fileName
                      ? fileName
                      : this.props.defaultText
                    : this.props.defaultText}
                </span>
              </>
            )}

            {!isUndefined(isLoading) && isLoading && (
              <span>
                &nbsp;&nbsp;
                <SubmitLoader />
              </span>
            )}
          </label>

          {this.props.isRequired && this.props.isRequired.length > 0 && (
            <div className="error-msg text-left ml-1 mb-4">
              {this.props.isRequired}
            </div>
          )}
        </div>
        {this.props.typesOfFile === APPOINTED_DOCUMENT && !isEmpty(fileName) && (
          <div className="d-flex">
            <div className="text-blue text-left text-ellipsis ml-4">
              {fileName}
            </div>
            <div>
              <CloseIcon
                className="close-icon"
                onClick={() => {
                  this.onClearFile();
                }}
              />
            </div>
          </div>
        )}
      </>
    );
  }
}

Dropzone.propTypes = {
  onFileAdded: PropTypes.func.isRequired,
  componentClass: PropTypes.string.isRequired,
  defaultText: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  componentName: PropTypes.string.isRequired,
  isAgeementFileTypeError: PropTypes.func,
};

Dropzone.defaultProps = {
  isAgeementFileTypeError: () => {},
};

export default Dropzone;
