import React, { createRef } from 'react';
import clsx from 'clsx';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Checkbox from '@material-ui/core/Checkbox';
import { Card, CardActions, Grid, Typography } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import FormInput from 'components/form-input';
import HtmlTooltip from 'components/html-tooltip';
import SubmitLoader from 'components/submit-loader';
import {
  AGENCY,
  AGENCY_NPN,
  ALTERNATE_SUB_PRODUCER,
  BUTTON_TYPE,
  CONFIRM_DATA,
  ENTER_KEY_CODE,
  ENTITY_TYPE,
  EO,
  FIRM,
  INDIVIDUAL,
  INDIVIDUAL_NPN,
  LICENSE_AND_ENO,
  NEXT,
  POST_SIGNUP_STEPS_NAME,
  PRIMARY_BUSINESS_LICENSE,
  PRIMARY_INDIVIDUAL_LCENSE,
  PRODUCER_KEY,
  PRODUCERS,
  RENAME,
  SELECT,
  SMALL,
  SUB_PRODUCER_KEY,
  SUBMIT,
  TOP,
  TYPE_TEXT,
  WRITE_UNDER_AGENCY_LICENSE,
} from 'constants/constant';
import { get, isEmpty, isUndefined, map, startCase } from 'lodash';
import { connect } from 'react-redux';
import { renewalNotificationFlagFun } from 'redux/actions/dashboard';
import { fetchAllStates } from 'redux/actions/loa-filters';
import {
  agencyLicenseError,
  fetchLicense,
  getNPN,
  individualLicenseError,
  setBusinessLicenseList,
  setCurrentStepIndex,
  setFileUploadError,
  setIndividualLicenseList,
  setNiprLoader,
  stepSubmit,
  stepSubmitSuccess,
  submitLoaderHide,
} from 'redux/actions/post-sign-up';
import { setDashboardView } from 'redux/actions/auth';
import {
  getStateNameToStateCode,
  isHyphen,
  validateFileName,
} from 'util/extra';
import { changeHandlers, validators } from 'util/form-handler';
import IntlMessages from 'util/intl-messages';
import { only_zero_regex } from 'util/sign-up-process';
import './step-4.scss';

const dropDownInitialData = {
  value: '',
  label: startCase(SELECT),
  id: 0,
};

const initState = {
  fieldsModified: false,
  formValues: {
    individualNPN: '',
    licenseState: '',
    businessNPN: '',
    businessLicenseState: '',
    individualFile: null,
    individualFileName: '',
    businessFile: null,
    businessFileName: '',
    writesUnderAgencyLicense: false,
    primaryIndividualLicense: '',
    primaryBusinessLicense: '',
  },
  formErrors: {
    individualNPN: '',
    primaryIndividualLicense: '',
    primaryBusinessLicense: '',
    businessNPN: '',
    individualFileName: '',
    businessFileName: '',
  },
  isAgencyLicense: false,
};

let ownerLicenseValues = [dropDownInitialData];
let agencyLicenseValues = [dropDownInitialData];

class Step4 extends React.Component {
  constructor(props) {
    super(props);
    const { licenseAndEnO } = this.props;
    const { writesUnderAgencyLicense } = licenseAndEnO;
    this.state = initState;
    this.agentState = getStateNameToStateCode(props.agencyProfile.state);
    this.showBusinessNpn = false;
    this.state.formValues.writesUnderAgencyLicense =
      this.props.isSubProducer &&
      this.props.productionAccess &&
      !this.props.isWholesaler &&
      !isUndefined(writesUnderAgencyLicense) &&
      !writesUnderAgencyLicense
        ? true
        : false;
    this.createRef();
  }

  componentDidMount() {
    const {
      agencyProfile,
      licenseAndEnO,
      tax,
      isErrorPresent,
      submitLoader,
      niprLoader,
      individualLicenseList,
      businessLicenseList,
    } = this.props;
    if (isErrorPresent) {
      this.props.setFileUploadError(false);
    }
    if (!isEmpty(individualLicenseList)) {
      this.setIndividualLicenseList(individualLicenseList);
    }
    if (!isEmpty(businessLicenseList)) {
      this.setBusinessLicenseList(businessLicenseList);
    }

    if (agencyProfile?.state) {
      this.agentState = getStateNameToStateCode(agencyProfile?.state);
    }
    this.setState({
      formValues: {
        ...this.state.formValues,
        ...this.props.licenseAndEnO,
        licenseState: tax.state,
        businessLicenseState: tax.state,
      },
    });
    if (licenseAndEnO?.otherStateLicenses?.licenseData) {
      this.setDropdownValue(licenseAndEnO.otherStateLicenses.licenseData);
    }
    this.props.fetchAllStates();
    submitLoader && this.props.submitLoaderHide();
    niprLoader && this.props.setNiprLoader(false);
  }

  componentDidUpdate = (prevProps) => {
    const {
      individualNPNLicenseError,
      isErrorPresent,
      agencyNPNLicenseError,
      licenseAndEnO: {
        isIndividualNPNVerified,
        isBusinessNPNVerified,
        otherStateLicenses,
      },
      individualLicenseList,
      businessLicenseList,
    } = this.props;
    if (!isEmpty(individualLicenseList)) {
      this.setIndividualLicenseList(individualLicenseList);
    }
    if (!isEmpty(businessLicenseList)) {
      this.setBusinessLicenseList(businessLicenseList);
    }

    if (prevProps.isErrorPresent !== isErrorPresent && isErrorPresent) {
      // eslint-disable-next-line
      this.individualLincense.current?.onClearFile();
      // eslint-disable-next-line
      this.businessLincense.current?.onClearFile();
      this.setState({
        fieldsModified: true,
        formValues: {
          ...this.state.formValues,
          individualFile: null,
          individualFileName: '',
          businessFile: null,
          businessFileName: '',
        },
      });
    } else if (
      prevProps.individualNPNLicenseError !== individualNPNLicenseError ||
      prevProps.agencyNPNLicenseError !== agencyNPNLicenseError
    ) {
      this.setState({
        formErrors: {
          ...this.state.formErrors,
          individualNPN: individualNPNLicenseError,
          businessNPN: agencyNPNLicenseError,
        },
      });
    } else if (
      prevProps.licenseAndEnO.isIndividualNPNVerified !==
        isIndividualNPNVerified ||
      prevProps.licenseAndEnO.isBusinessNPNVerified !== isBusinessNPNVerified
    ) {
      if (otherStateLicenses?.licenseData) {
        this.setDropdownValue(otherStateLicenses.licenseData);
      }
    }
  };

  createRef() {
    this.dropZone = createRef();
    this.individualLincense = createRef();
    this.businessLincense = createRef();
    this.refList = {
      individualNPN: React.createRef(),
      primaryIndividualLicense: React.createRef(),
    };
  }

  setIndividualLicenseList = (individualLicenseList) => {
    const individualList = this.filterLicenses(individualLicenseList);
    ownerLicenseValues = !isEmpty(individualList)
      ? [...this.ownerLicenseData(individualList, INDIVIDUAL)]
      : [dropDownInitialData];
    this.props.setIndividualLicenseList([]);
    if (ownerLicenseValues.length > 1) {
      this.setState({
        formErrors: {
          ...this.state.formErrors,
          primaryIndividualLicense: '',
        },
      });
    }
  };
  setBusinessLicenseList = (businessLicenseList) => {
    const businessList = this.filterLicenses(businessLicenseList);
    agencyLicenseValues = !isEmpty(businessList)
      ? [...this.ownerLicenseData(businessList, AGENCY)]
      : [dropDownInitialData];
    this.props.setBusinessLicenseList([]);
    if (agencyLicenseValues.length > 1) {
      this.setState({
        formErrors: {
          ...this.state.formErrors,
          primaryBusinessLicense: '',
        },
      });
    }
  };
  filterLicenses = (data) => {
    const agentStateLower = this.agentState?.toLowerCase();
    return data.filter(
      (row) =>
        row?.state?.toLowerCase() === agentStateLower &&
        (row?.agentTypes.includes('Producer') || row?.agentTypes.length === 0)
    );
  };
  setDropdownValue = (data) => {
    const agentStateLower = this.agentState?.toLowerCase();
    const individualList = data.filter(
      (row) => row?.licenseType?.toLowerCase() === INDIVIDUAL
    );
    const businessList = data.filter(
      (row) => row?.licenseType?.toLowerCase() === FIRM.toLowerCase()
    );

    const individualLicenseList = data.filter(
      (row) =>
        row?.licenseType?.toLowerCase() === INDIVIDUAL &&
        row?.state?.toLowerCase() === agentStateLower &&
        // <https://myhippo.atlassian.net/browse/FRC-5183>
        (row?.agentTypes.includes('Producer') || row?.agentTypes.length === 0)
    );
    const businessLicenseList = data.filter(
      (row) =>
        row?.licenseType?.toLowerCase() === FIRM.toLowerCase() &&
        row?.state?.toLowerCase() === agentStateLower &&
        // <https://myhippo.atlassian.net/browse/FRC-5183>
        (row?.agentTypes.includes('Producer') || row?.agentTypes.length === 0)
    );
    ownerLicenseValues = !isEmpty(individualLicenseList)
      ? [...this.ownerLicenseData(individualLicenseList, INDIVIDUAL)]
      : [dropDownInitialData];
    agencyLicenseValues = !isEmpty(businessLicenseList)
      ? [...this.ownerLicenseData(businessLicenseList, AGENCY)]
      : [dropDownInitialData];
    this.setState((prevState) => ({
      formErrors: {
        ...prevState.formErrors,
        primaryIndividualLicense: isEmpty(individualList)
          ? 'Not Found'
          : isEmpty(individualLicenseList)
          ? CONFIRM_DATA
          : '',
        primaryBusinessLicense: isEmpty(businessList)
          ? 'Not Found'
          : isEmpty(businessLicenseList)
          ? CONFIRM_DATA
          : '',
      },
    }));
  };

  ownerLicenseData = (list = [], type) => {
    const mappedList = map(list, (indLicData) => ({
      value: indLicData.uuid || '',
      label: get(indLicData, 'loa')
        ? `${indLicData.licenseNumber}|${indLicData.loa}|${indLicData.state}`
        : '',
      id: indLicData.uuid || '',
    }));
    if (mappedList.length === 1) {
      if (type === INDIVIDUAL) {
        this.setState((prevState) => ({
          fieldsModified: true,
          formValues: {
            ...prevState.formValues,
            primaryIndividualLicense: mappedList[0].value,
          },
        }));
      } else {
        this.setState((prevState) => ({
          fieldsModified: true,
          formValues: {
            ...prevState.formValues,
            primaryBusinessLicense: mappedList[0].value,
          },
        }));
      }
    }
    return [
      {
        value: startCase(SELECT),
        label: startCase(SELECT),
        id: 0,
      },
      ...mappedList,
    ];
  };

  NPNChangeCallBack = (key) => {
    const { formErrors, formValues } = this.state;
    if (formErrors[key] && !formValues[key]) {
      if (key === 'individualNPN') {
        this.props.individualLicenseError('');
      } else if (key === 'businessNPN') {
        this.props.agencyLicenseError('');
      }
    }
  };

  NPNBlurCallBack = (key) => {
    const npnTypes = {
      individualNPN: ENTITY_TYPE.INDIVIDUAL,
      businessNPN: ENTITY_TYPE.FIRM,
    };
    const { formValues } = this.state;
    const value = formValues[key];

    if (value && !only_zero_regex.test(value)) {
      this.props.getNPN({
        type: npnTypes[key] || '',
        NPN: value,
        writesUnderAgencyLicense: formValues.writesUnderAgencyLicense,
      });
    }
  };

  validators = {
    individualFileName: [
      (value) => (value ? validateFileName(value, RENAME) : ''),
    ],
    businessFileName: [
      (value) => (value ? validateFileName(value, RENAME) : ''),
    ],
    individualNPN: [
      validators.required,
      validators.onlyZeroInvalid,
      () => this.props.individualNPNLicenseError || '',
    ],
    primaryIndividualLicense: [
      (value) => {
        const {
          licenseAndEnO: { otherStateLicenses, isIndividualNPNVerified },
        } = this.props;
        if (isIndividualNPNVerified) {
          const individualList = otherStateLicenses?.licenseData?.filter(
            (row) => row?.licenseType?.toLowerCase() === INDIVIDUAL
          );
          return isEmpty(individualList) && ownerLicenseValues?.length === 1
            ? 'Not Found'
            : ownerLicenseValues?.length === 1
            ? CONFIRM_DATA
            : validators.selectRequired(value);
        }
        return '';
      },
    ],
    businessNPN: [
      (value) => {
        if (this.showBusinessNpn) {
          return (
            validators.required(value) || validators.onlyZeroInvalid(value)
          );
        }
        return '';
      },
      () => this.props.agencyNPNLicenseError || '',
    ],
    primaryBusinessLicense: [
      (value) => {
        const {
          licenseAndEnO: { otherStateLicenses },
        } = this.props;
        if (
          this.showBusinessNpn &&
          this.props.licenseAndEnO?.isBusinessNPNVerified
        ) {
          const businessList = otherStateLicenses?.licenseData?.filter(
            (row) => row?.licenseType?.toLowerCase() === FIRM.toLowerCase()
          );
          return isEmpty(businessList) && agencyLicenseValues?.length === 1
            ? 'Not Found'
            : agencyLicenseValues?.length === 1
            ? CONFIRM_DATA
            : validators.selectRequired(value);
        }
        return '';
      },
    ],
  };

  validate = () => {
    const { formValues, formErrors } = this.state;
    const { isWholesaler } = this.props;

    if (!isWholesaler && formValues.writesUnderAgencyLicense) {
      return true;
    }

    let isInvalid = false;
    const newErrors = { ...formErrors };

    for (const [key, validatorList] of Object.entries(this.validators)) {
      for (const validator of validatorList) {
        const error = validator(formValues[key]);
        newErrors[key] = error;
        if (error) {
          isInvalid = true;

          if (!this.errorField) {
            this.errorField = key;
          }

          break;
        }
      }
    }
    this.setState({ formErrors: newErrors });

    return !isInvalid;
  };

  submitForm = (event) => {
    event.preventDefault();

    const { formValues, fieldsModified } = this.state;
    const { signupFlow, licenseAndEnO, isSubProducer, productionAccess } =
      this.props;

    localStorage.setItem(
      'writesUnderAgencyLicense',
      isUndefined(formValues.writesUnderAgencyLicense)
        ? false
        : formValues.writesUnderAgencyLicense
    );
    if (this.validate()) {
      if (fieldsModified || isSubProducer) {
        if (formValues.writesUnderAgencyLicense) {
          this.props.stepSubmit({
            stepId: LICENSE_AND_ENO,
            entity: LICENSE_AND_ENO,
            final: signupFlow !== PRODUCER_KEY,
            signUpComplete: signupFlow !== PRODUCER_KEY,
            data: {
              ...licenseAndEnO,
              ...formValues,
              // reset becausese overrided by ...formValues,
              isIndividualNPNVerified: licenseAndEnO.isIndividualNPNVerified,
              otherStateLicenses: licenseAndEnO.otherStateLicenses,
              isBusinessNPNVerified: licenseAndEnO.isBusinessNPNVerified,
              system_file_type: EO,
              directory_name: PRODUCERS,
            },
            signupFlow,
          });
        } else if (!!isSubProducer && !!productionAccess) {
          this.props.stepSubmit({
            stepId: LICENSE_AND_ENO,
            entity: LICENSE_AND_ENO,
            final: signupFlow !== PRODUCER_KEY,
            signUpComplete: signupFlow !== PRODUCER_KEY,
            data: {
              ...licenseAndEnO,
              ...formValues,
              // reset becausese overrided by ...formValues,
              isIndividualNPNVerified: licenseAndEnO.isIndividualNPNVerified,
              otherStateLicenses: licenseAndEnO.otherStateLicenses,
              isBusinessNPNVerified: licenseAndEnO.isBusinessNPNVerified,
              system_file_type: EO,
              directory_name: PRODUCERS,
            },
            signupFlow,
          });
        } else {
          this.props.stepSubmit({
            stepId: LICENSE_AND_ENO,
            entity: LICENSE_AND_ENO,
            completedSignupStep: POST_SIGNUP_STEPS_NAME.LICENSE,
            data: {
              ...licenseAndEnO,
              ...formValues,
              isIndividualNPNVerified: licenseAndEnO.isIndividualNPNVerified,
              otherStateLicenses: licenseAndEnO.otherStateLicenses,
              isBusinessNPNVerified: licenseAndEnO.isBusinessNPNVerified,
              system_file_type: EO,
              directory_name: PRODUCERS,
              multipleFile: true,
            },
            signupFlow,
          });
        }
      } else {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({ event: 'registrationComplete' });
        if (signupFlow !== PRODUCER_KEY) {
          this.props.setDashboardView(true);
          localStorage.setItem('dashboard', true);
          this?.props?.history?.push('/');
        } else {
          this.props.stepSubmitSuccess();
        }
      }
    } else {
      this.refList[this.errorField]?.current?.scrollIntoView({
        behavior: 'smooth',
      });
      this.errorField = null;
    }
  };

  handleFileChange = (file, type) => {
    if (file[0]) {
      let filename = file[0].name;
      this.setState({
        fieldsModified: true,
        formValues: {
          ...this.state.formValues,
          [type]: file,
          [type + 'Name']: filename,
        },
      });
    }
  };

  onAgencyLicenseChange = () => {
    const { formValues } = this.state;
    this.setState({
      fieldsModified: true,
      formValues: {
        ...this.state.formValues,
        writesUnderAgencyLicense: !formValues.writesUnderAgencyLicense,
      },
    });
  };

  setValue = (key, value, callback) => {
    this.setState(
      {
        fieldsModified: true,
        formValues: { ...this.state.formValues, [key]: value },
      },
      () => callback?.(key)
    );
  };

  getRefetchedLicense = (e, type) => {
    e.preventDefault();
    this.props.fetchLicense({ type: type });
  };
  render() {
    const {
      submitLoader,
      tax,
      signupFlow,
      classNames,
      currentStepIndex,
      producer_code,
      isWholesaler,
      licenseAndEnO,
      isSubProducer,
    } = this.props;
    const { formValues, formErrors } = this.state;
    const { writesUnderAgencyLicense } = licenseAndEnO;
    this.showBusinessNpn =
      !tax.FEINNotPresent && signupFlow !== SUB_PRODUCER_KEY;
    const showLicenceFields =
      !formValues.writesUnderAgencyLicense ||
      (isHyphen(producer_code) && isWholesaler) ||
      (!isUndefined(writesUnderAgencyLicense) && !writesUnderAgencyLicense) ||
      false;
    return (
      <>
        <Card className={`license-height ${classNames.card}`}>
          <form
            className="post-signup-forms post-form-2 position-relative h-100"
            // onSubmit={this.submitForm}
          >
            {isSubProducer &&
              isHyphen(producer_code) &&
              isUndefined(writesUnderAgencyLicense) && (
                <Grid
                  container
                  className={`writeUnderAgency pb-0 ${classNames.cardContent} card-content`}
                >
                  <Grid item xs={12} sm={8} className="fs-exclude p-15">
                    <label
                      className={`tax-checked-check-box ${
                        !submitLoader ? 'cursor-pointer' : 'default-cursor'
                      }`}
                    >
                      <Checkbox
                        size="small"
                        onChange={this.onAgencyLicenseChange}
                        disabled={submitLoader}
                        name={WRITE_UNDER_AGENCY_LICENSE}
                        checked={formValues.writesUnderAgencyLicense}
                      />
                      <span className="license-checkbox">
                        <IntlMessages id="note.agency.license" />
                      </span>
                    </label>
                    <HtmlTooltip
                      title={
                        <IntlMessages id="label.subAgent.signupFlow.tooltip" />
                      }
                      placement={TOP}
                      fontSize={SMALL}
                      disableTouchListener
                    >
                      <InfoOutlinedIcon className="orange-text license-tooltip pointer" />
                    </HtmlTooltip>
                  </Grid>
                </Grid>
              )}
            {showLicenceFields && (
              <>
                <Grid
                  container
                  className={`${
                    isHyphen(producer_code) && !isWholesaler ? 'pt-0' : ''
                  } ${
                    !isUndefined(writesUnderAgencyLicense) &&
                    !writesUnderAgencyLicense
                      ? 'agencyLicenceNo'
                      : ''
                  } ${classNames.cardContent} card-content pb-0 `}
                >
                  <Grid item xs={12} sm={6} className="p-15">
                    <label
                      ref={this.refList.individualNPN}
                      className={clsx({
                        'label-error-color': formErrors.individualNPN,
                      })}
                    >
                      <IntlMessages
                        id={`${
                          isHyphen(producer_code) &&
                          !isUndefined(writesUnderAgencyLicense) &&
                          !writesUnderAgencyLicense
                            ? 'label.individual.npn'
                            : 'label.primaryIndividualLicense'
                        }`}
                      />
                      <span className="required">*</span>
                    </label>
                    <div
                      className="fieldWithIcon"
                      onKeyDown={(e) => {
                        if (e.keyCode === ENTER_KEY_CODE) {
                          this.NPNBlurCallBack(INDIVIDUAL_NPN);
                        }
                      }}
                    >
                      <FormInput
                        placeholder=""
                        id={INDIVIDUAL_NPN}
                        type={TYPE_TEXT}
                        className={clsx('fs-exclude', 'validate', {
                          'error-border': formErrors.individualNPN,
                        })}
                        name={INDIVIDUAL_NPN}
                        handleChange={changeHandlers.numberString}
                        onChangeCallBack={this.NPNChangeCallBack}
                        onBlurCallBack={this.NPNBlurCallBack}
                        maxLength={30}
                        value={formValues.individualNPN}
                        valueKey="individualNPN"
                        setValue={this.setValue}
                        error={formErrors.individualNPN}
                        disabled={
                          submitLoader || licenseAndEnO.isIndividualNPNVerified
                        }
                      />
                      <span
                        className={`mat-icon ${
                          !this.props.licenseAndEnO.isIndividualNPNVerified
                            ? ''
                            : 'check-box'
                        }`}
                      >
                        {this.props.licenseAndEnO.isIndividualNPNVerified && (
                          <HtmlTooltip
                            title={<IntlMessages id="label.verify" />}
                            placement={TOP}
                            fontSize={SMALL}
                            disableTouchListener
                          >
                            <IconButton aria-label="Question">
                              <FontAwesomeIcon
                                className={`check-box`}
                                icon={faCheck}
                              />
                            </IconButton>
                          </HtmlTooltip>
                        )}
                      </span>
                    </div>
                  </Grid>
                  {licenseAndEnO.isIndividualNPNVerified && (
                    <>
                      <Grid xs={12} item sm={6} className="p-15">
                        <label
                          ref={this.refList.primaryIndividualLicense}
                          className={clsx({
                            'label-error-color':
                              formErrors.primaryIndividualLicense,
                          })}
                        >
                          {(!formValues.writesUnderAgencyLicense ||
                            !writesUnderAgencyLicense) &&
                          (signupFlow === SUB_PRODUCER_KEY ||
                            signupFlow === ALTERNATE_SUB_PRODUCER) ? (
                            <IntlMessages id="label.primaryLicense" />
                          ) : (
                            <IntlMessages id="label.primaryOwnerLicense" />
                          )}
                          <span className="required">*</span>
                        </label>
                        <div className="select-wrapper">
                          <select
                            className={clsx('fs-exclude', 'select-dropdown', {
                              'error-border':
                                formErrors.primaryIndividualLicense,
                            })}
                            name={PRIMARY_INDIVIDUAL_LCENSE}
                            disabled={
                              !this.props.licenseAndEnO?.isIndividualNPNVerified
                            }
                            onChange={(e) => {
                              this.setValue(
                                'primaryIndividualLicense',
                                e.target.value
                              );
                            }}
                            value={
                              formValues.primaryIndividualLicense ||
                              startCase(SELECT)
                            }
                          >
                            {ownerLicenseValues.map((data) => (
                              <option key={data.id} value={data.value}>
                                {data.label}
                              </option>
                            ))}
                          </select>
                          {formErrors.primaryIndividualLicense ===
                          'Not Found' ? (
                            <Typography
                              className="referLbl text-blue fs-exclude"
                              onClick={(e) =>
                                this.getRefetchedLicense(e, 'INDIVIDUAL_NPN')
                              }
                            >
                              <span
                                className="error-msg pr-1"
                                style={{ fontWeight: 400 }}
                              >
                                Something went wrong.
                              </span>

                              <span
                                style={{
                                  fontSize: '11px',
                                  marginBottom: '0px',
                                  cursor: 'pointer',
                                  textDecoration: 'underline',
                                  fontWeight: 700,
                                }}
                              >
                                Refetch your license.
                              </span>
                            </Typography>
                          ) : (
                            <div className="error-msg">
                              {formErrors.primaryIndividualLicense}
                            </div>
                          )}
                        </div>
                      </Grid>
                    </>
                  )}
                </Grid>
                <Grid
                  container
                  className={`pt-0 ${classNames.cardContent} card-content`}
                >
                  {this.showBusinessNpn && (
                    <>
                      <Grid xs={12} sm={6} item className="p-15">
                        <label
                          className={`${
                            formErrors.businessNPN ? 'label-error-color' : ''
                          }`}
                        >
                          <IntlMessages id="label.primaryBusinessLicense" />
                          <span className="required">*</span>
                        </label>
                        <div
                          className="fieldWithIcon"
                          onKeyDown={(e) => {
                            if (e.keyCode === ENTER_KEY_CODE) {
                              this.NPNBlurCallBack(AGENCY_NPN);
                            }
                          }}
                        >
                          <FormInput
                            placeholder=""
                            id={AGENCY_NPN}
                            type={TYPE_TEXT}
                            className={`validate fs-exclude ${
                              formErrors.businessNPN ? 'error-border' : ''
                            }`}
                            name={AGENCY_NPN}
                            handleChange={changeHandlers.numberString}
                            onChangeCallBack={this.NPNChangeCallBack}
                            onBlurCallBack={this.NPNBlurCallBack}
                            maxLength={30}
                            value={formValues.businessNPN}
                            valueKey="businessNPN"
                            setValue={this.setValue}
                            error={formErrors.businessNPN}
                            disabled={
                              submitLoader ||
                              this.props.licenseAndEnO.isBusinessNPNVerified
                            }
                          />
                          <span
                            className={`mat-icon ${
                              !this.props.licenseAndEnO.isBusinessNPNVerified
                                ? ''
                                : 'check-box'
                            }`}
                          >
                            {this.props.licenseAndEnO.isBusinessNPNVerified && (
                              <HtmlTooltip
                                title={<IntlMessages id="label.verify" />}
                                placement={TOP}
                                fontSize={SMALL}
                                disableTouchListener
                              >
                                <IconButton aria-label="Question">
                                  <FontAwesomeIcon
                                    className={`check-box`}
                                    icon={faCheck}
                                  />
                                </IconButton>
                              </HtmlTooltip>
                            )}
                          </span>
                        </div>
                      </Grid>

                      {this?.props?.licenseAndEnO?.isBusinessNPNVerified && (
                        <>
                          <Grid xs={12} sm={6} item className="p-15">
                            <label
                              className={clsx({
                                'label-error-color':
                                  formErrors.primaryBusinessLicense,
                              })}
                            >
                              <IntlMessages id="label.agencyPrimaryLicense" />
                              <span className="required">*</span>
                            </label>
                            <div className="select-wrapper">
                              <select
                                className={clsx(
                                  'fs-exclude',
                                  'select-dropdown',
                                  {
                                    'error-border':
                                      formErrors.primaryBusinessLicense,
                                  }
                                )}
                                name={PRIMARY_BUSINESS_LICENSE}
                                disabled={
                                  !this?.props?.licenseAndEnO
                                    ?.isBusinessNPNVerified
                                }
                                value={
                                  formValues.primaryBusinessLicense ||
                                  startCase(SELECT)
                                }
                                onChange={(e) => {
                                  this.setValue(
                                    'primaryBusinessLicense',
                                    e.target.value
                                  );
                                }}
                              >
                                {agencyLicenseValues.map((data) => (
                                  <option key={data.id} value={data.value}>
                                    {data.label}
                                  </option>
                                ))}
                              </select>
                              {formErrors.primaryBusinessLicense ===
                              'Not Found' ? (
                                <Typography
                                  className="referLbl text-blue fs-exclude"
                                  onClick={(e) =>
                                    this.getRefetchedLicense(e, 'BUSINESS_NPN')
                                  }
                                >
                                  <span
                                    className="error-msg pr-1"
                                    style={{ fontWeight: 400 }}
                                  >
                                    Something went wrong.
                                  </span>

                                  <span
                                    style={{
                                      fontSize: '11px',
                                      marginBottom: '0px',
                                      cursor: 'pointer',
                                      textDecoration: 'underline',
                                      fontWeight: 700,
                                    }}
                                  >
                                    Refetch your license.
                                  </span>
                                </Typography>
                              ) : (
                                <div className="error-msg">
                                  {formErrors.primaryBusinessLicense}
                                </div>
                              )}
                            </div>
                          </Grid>
                        </>
                      )}
                    </>
                  )}
                </Grid>
              </>
            )}
            <CardActions
              className="license-btn-position signup-btn-action-card d-flex justify-content-between"
              style={{
                top: `${this.props.visualViewportHeight}px`,
              }}
            >
              <button
                type={BUTTON_TYPE}
                className="white-back-button"
                disabled={submitLoader}
                onClick={() => {
                  this.props.setFileUploadError(false);
                  this.props.setCurrentStepIndex(currentStepIndex - 1);
                }}
              >
                <IntlMessages id="appModule.back" />
              </button>
              {isHyphen(producer_code) ? (
                <button
                  type={SUBMIT}
                  className={`${'orange-next-button'}`}
                  onClick={this.submitForm}
                  id={NEXT}
                  disabled={submitLoader}
                >
                  <IntlMessages id="label.finish" />
                  {submitLoader && (
                    <SubmitLoader className={classNames.submitLoader} />
                  )}
                </button>
              ) : (
                <button
                  type={SUBMIT}
                  className={`${'orange-next-button'}`}
                  id={NEXT}
                  onClick={this.submitForm}
                  disabled={submitLoader}
                >
                  <IntlMessages id="appModule.next" />
                  {submitLoader && (
                    <SubmitLoader className={classNames.submitLoader} />
                  )}
                </button>
              )}
            </CardActions>
          </form>
        </Card>
        {/* )} */}
      </>
    );
  }
}

const mapStateToProps = ({
  postSignup: {
    agencyProfile,
    licenseAndEnO,
    submitLoader,
    tax,
    producer_code,
    niprLoader,
    individualLicenseError: individualNPNLicenseError,
    agencyLicenseError: agencyNPNLicenseError,
    isErrorPresent,
    currentStepIndex,
    continueOnDevice,
    switchToComputer,
    individualLicenseList,
    businessLicenseList,
    refetchedLicenseLoader,
  },
  auth: { signupFlow, isWholesaler },
  loaFilters: { allLOAFilterStates },
}) => ({
  agencyNPNLicenseError,
  agencyProfile,
  allLOAFilterStates,
  currentStepIndex,
  individualNPNLicenseError,
  isErrorPresent,
  licenseAndEnO,
  isWholesaler,
  niprLoader,
  producer_code,
  signupFlow,
  submitLoader,
  tax,
  continueOnDevice,
  switchToComputer,
  individualLicenseList,
  businessLicenseList,
  refetchedLicenseLoader,
});

export default connect(mapStateToProps, {
  agencyLicenseError,
  fetchAllStates,
  getNPN,
  individualLicenseError,
  renewalNotificationFlagFun,
  setDashboardView,
  setCurrentStepIndex,
  setFileUploadError,
  stepSubmit,
  stepSubmitSuccess,
  submitLoaderHide,
  setNiprLoader,
  fetchLicense,
  setIndividualLicenseList,
  setBusinessLicenseList,
})(Step4);
