import React from 'react';
import PropTypes from 'prop-types';
import {
  ALTERNATE_SUB_PRODUCER,
  DIRECTAGREEMENT,
  SUB_PRODUCER_KEY,
} from 'constants/constant';
import { withStyles } from '@material-ui/core/styles';
import { LinearProgress, Tooltip } from '@material-ui/core';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import IntlMessages from 'util/intl-messages';

/* eslint-disable no-unused-vars */
const BorderLinearProgress = withStyles(() => ({
  root: {
    height: 12,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: '#FFF',
  },
  bar: {
    borderRadius: 5,
    backgroundColor: '#FF9B15',
  },
}))(LinearProgress);
const stepProgress = [25, 40, 55, 70, 85, 100];
const subWithProdAcc = [40, 70, 100];
const subWithoutProdAcc = [50, 100];
const alternateSubWithProdAcc = [40, 60, 80, 100];
const PostSignupHeader = ({
  accessibleSteps,
  currentStepIndex,
  from,
  steps,
  signupFlow,
  productionAccess,
}) => {
  const currentStep = steps?.[accessibleSteps?.[currentStepIndex]];
  const progress = stepProgress?.[currentStepIndex];
  const subProgress = subWithProdAcc?.[currentStepIndex];
  const subWithoutProdProgress = subWithoutProdAcc?.[currentStepIndex];
  const twfgWithProdProgress = alternateSubWithProdAcc?.[currentStepIndex];
  const { title, stepId, tooltipsValue } = !!currentStep && currentStep;
  const labelTitle =
    from === DIRECTAGREEMENT ? (
      <IntlMessages id="label.please.accept.agreement" />
    ) : stepId === 'agencyProfile' ? (
      signupFlow === SUB_PRODUCER_KEY ||
      signupFlow === ALTERNATE_SUB_PRODUCER ? (
        'Agent Profile'
      ) : (
        'Agency Profile'
      )
    ) : (
      title
    );

  /* eslint-disable no-unused-vars */
  let progressValue = [];

  if (signupFlow === SUB_PRODUCER_KEY) {
    if (productionAccess) {
      progressValue = subProgress;
    } else {
      progressValue = subWithoutProdProgress;
    }
  } else if (signupFlow === ALTERNATE_SUB_PRODUCER) {
    if (productionAccess) {
      progressValue = twfgWithProdProgress;
    } else {
      progressValue = subProgress;
    }
  } else {
    progressValue = progress;
  }

  /* eslint-disable no-unused-vars */
  const showProgressBar =
    localStorage.getItem('shouldShowProgressBar') === 'undefined'
      ? false
      : JSON.parse(localStorage.getItem('shouldShowProgressBar')) !== null
      ? JSON.parse(localStorage.getItem('shouldShowProgressBar'))
      : true;
  return (
    <div className="details-head">
      {/* FIXME: maybe required in future  */}
      {/* {from !== DIRECTAGREEMENT && showProgressBar ? (
        <BorderLinearProgress variant="determinate" value={progressValue} />
      ) : (
        ''
      )} */}
      <h3 className="big-head">
        {labelTitle}
        {tooltipsValue && (
          <Tooltip
            enterDelay={100}
            leaveDelay={200}
            placement="right"
            title={tooltipsValue}
            aria-label={title?.trim}
          >
            <InfoOutlinedIcon className="info-icon" />
          </Tooltip>
        )}
      </h3>
    </div>
  );
};

PostSignupHeader.propTypes = {
  accessibleSteps: PropTypes.array,
  completedStepsCount: PropTypes.number,
  currentStepIndex: PropTypes.number,
  from: PropTypes.string,
  steps: PropTypes.object,
};

PostSignupHeader.defaultProps = {
  accessibleSteps: [],
  completedStepsCount: 0,
  currentStepIndex: 0,
  from: '',
  steps: {},
};

export default PostSignupHeader;
