import { isEmpty } from 'lodash';
import {
  INVALID,
  INVALID_FILE_NAME,
  PASSWORD_HINT,
  REQUIRED,
  SELECT,
  ZERO_4,
  ZERO_5,
} from '../constants/constant';
import { checkFileNameOrThrowError } from './extra.js';
import {
  alphanumeric_regex,
  bankname_regex,
  email_regex,
  empty_string_regex,
  fin_regex,
  getStartCase,
  hex_color_code_regex,
  neglect_zero_regex,
  number_regex,
  only_number_symbol_regex,
  only_zero_regex,
  password_regex,
  phone_regex,
  policy_number_regex,
  ssn_regex,
  string_regex,
  string_with_regx,
  url_regex,
  zero_regex,
} from './sign-up-process.js';
import { isValidPhoneNumber } from 'libphonenumber-js';

export const changeHandlers = {
  default: (value) => [value, true],
  alphaNumeric: (value) => {
    if (!value || alphanumeric_regex.test(value)) {
      return [value, true];
    }
    return [value, false];
  },
  alphaString: (value) => {
    if (!value || string_regex.test(value)) {
      return [value, true];
    }
    return [value, false];
  },
  numberString: (value) => {
    if (value?.match(number_regex) === null) {
      return [value, false];
    }
    return [value, true];
  },
  bankName: (value) => {
    if (value && !bankname_regex.test(value)) {
      return [value, false];
    }
    return [value, true];
  },
  addressString: (value) => {
    if (!value || value.trim().length !== 0) {
      return [value, true];
    }
    return [value, false];
  },
};

export const blurHandlers = {
  default: (value) => [value, true],
  startCapital: (value) => {
    if (value) {
      return [getStartCase(value?.toLowerCase()), true];
    }
    return [value, false];
  },
  trim: (value) => {
    if (value) {
      return [value?.trim(), true];
    }
    return [value, false];
  },
};

const errorMessages = {
  required: REQUIRED,
  invalid: INVALID,
  invalidEmail: 'Email address format is not valid',
  passwordInvalid: PASSWORD_HINT,
  invalidXlsFile: 'Only XLS, XLSX files are allowed.',
  fileNameInvalid: INVALID_FILE_NAME,
  invalidPngFile: 'Only JPG, PNG and JPEG files are allowed.',
  invalidGifFile: 'Only GIF files are allowed.',
  invalidNumber: 'Invalid',
  invalidURL: 'Link must start with https://',
  phoneInvalidNotValid: 'Phone number is not valid',
};

export const validators = {
  required: (value, message) => {
    if (!value || value?.length <= 0) {
      return message || errorMessages.required;
    }
    return '';
  },
  SSNInvalid: (value, message) => {
    if (value && value.includes('*')) {
      // Pass masked values - BE will ignore
      return '';
    }
    if (!value || !ssn_regex.test(value)) {
      return message || errorMessages.invalid;
    }
    return '';
  },
  invalidFileName: (value, message) => {
    if (!isEmpty(value) && checkFileNameOrThrowError(value)) {
      return message || errorMessages.fileNameInvalid;
    }
  },
  invalidXlsFile: (value, message) => {
    const validExts = ['.xlsx', '.xls'];
    if (!value) {
      return '';
    }
    if (!validExts.includes(value.substring(value.lastIndexOf('.')))) {
      return message || errorMessages.invalidXlsFile;
    }
  },
  FEINInvalid: (value, message) => {
    if (value && value.includes('*')) {
      // Pass masked values - BE will ignore
      return '';
    }
    if (!value || !fin_regex.test(value)) {
      return message || errorMessages.invalid;
    }
    return '';
  },
  nameInvalid: (value, message) => {
    if (!value || !string_with_regx.test(value)) {
      return message || errorMessages.invalid;
    }
    return '';
  },
  selectRequired: (value, message) => {
    if (!value || value?.toLowerCase() === SELECT) {
      return message || errorMessages.required;
    }
    return '';
  },
  arraySelectRequired: (value, message) => {
    if (isEmpty(value)) {
      return message || errorMessages.required;
    }
    return '';
  },
  minLengthInvalid: (value, minLength, message) => {
    if (!value || value?.length < minLength) {
      return message || errorMessages.invalid;
    }
    return '';
  },
  maxLengthInvalid: (value, maxLength, message) => {
    if (!value || value?.length > maxLength) {
      return message || errorMessages.invalid;
    }
    return '';
  },
  phoneInvalid: (value, message) => {
    if (!value || !phone_regex.test(value)) {
      return message || errorMessages.invalid;
    }
    return '';
  },
  emailInvalid: (value, message) => {
    if (value && !email_regex.test(value)) {
      return message || errorMessages.invalidEmail;
    }
    return '';
  },
  invalidGifFile: (value, message) => {
    const validExts = ['.gif'];
    if (!value) {
      return '';
    }
    if (!validExts.includes(value.substring(value.lastIndexOf('.')))) {
      return message || errorMessages.invalidGifFile;
    }
  },
  invalidPngFile: (value, message) => {
    const validExts = ['.png', '.jpg', 'jpeg'];
    if (!value) {
      return '';
    }
    if (!validExts.includes(value.substring(value.lastIndexOf('.')))) {
      return message || errorMessages.invalidPngFile;
    }
  },
  urlInvalid: (value, message) => {
    if (value && !url_regex.test(value)) {
      return message || errorMessages.invalidURL;
    }
    return '';
  },
  hexColorInvalid: (value, message) => {
    if (value && !hex_color_code_regex.test(value)) {
      return message || errorMessages.invalid;
    }
    return '';
  },
  minDateInvalid: (value, date, message) => {
    if (new Date(value) < new Date(date) || value === '-') {
      return message || errorMessages.invalid;
    }
    return '';
  },
  maxDateInvalid: (value, date, message) => {
    if (new Date(value) > new Date(date) || value === '-') {
      return message || errorMessages.invalid;
    }
    return '';
  },
  dateRequired: (value, message) => {
    if (!value || new Date(value).toString() === 'Invalid Date') {
      return message || errorMessages.invalid;
    }
    return '';
  },
  bankAccNumInvalid: (value, message) => {
    if (value?.length < 4 || value?.length > 17 || !zero_regex.test(value)) {
      return message || errorMessages.invalid;
    }
    return '';
  },
  zipCodeInvalid: (value, message) => {
    if (value && (value.length < 4 || [ZERO_4, ZERO_5].includes(value))) {
      return message || errorMessages.invalid;
    }
    return '';
  },
  onlyZeroInvalid: (value, message) => {
    if (value && only_zero_regex.test(value)) {
      return message || errorMessages.invalid;
    }
    return '';
  },
  minIntInvalid: (value, minValue, message) => {
    const newValue = parseInt(value, 10);
    if (isNaN(newValue) || newValue < minValue) {
      return message || errorMessages.invalid;
    }
    return '';
  },
  passwordInvalid: (value, message) => {
    if (value && !password_regex.test(value)) {
      return message || errorMessages.passwordInvalid;
    }
    return '';
  },
  onlyNumSymbolInvalid: (value, message) => {
    if (value && only_number_symbol_regex.test(value)) {
      return message || errorMessages.invalid;
    }
    return '';
  },
  numberInvalid: (value, message) => {
    if (value && !number_regex.test(value)) {
      return message || errorMessages.invalidNumber;
    }
    return '';
  },
  policyNumberInvalid: (value, message) => {
    if (value && !policy_number_regex.test(value)) {
      return message || errorMessages.invalidNumber;
    }
    return '';
  },
  negativeInvalid: (value, message) => {
    if (value && !neglect_zero_regex.test(value)) {
      return message || errorMessages.invalid;
    }
    return '';
  },
  emptyValue: (value, message) => {
    if (value && empty_string_regex.test(value)) {
      return message || errorMessages.invalid;
    }
    return '';
  },
  usPhoneInvalid: (value, message) => {
    if (!value || !isValidPhoneNumber(value, 'US')) {
      return message || errorMessages.phoneInvalidNotValid;
    }
    return '';
  },
};
