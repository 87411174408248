import React from 'react';
import PropTypes from 'prop-types';
import Testimonials from 'containers/post-signup/post-signup-process/step-progress/testimonials';
import {
  fastStartContent,
  socialProofContent,
  testimonialContent,
} from 'util/sign-up-process';
import { connect } from 'react-redux';
import { getAuthDetails } from 'util/extra';

const Socialproof = (data) => {
  const { title, agencyProfileSocialFlow } = data?.content;
  return (
    <div className="agency-social-proof-wrap">
      <h2 className="social-heading">{title}</h2>
      {agencyProfileSocialFlow?.map(({ content, image }) => (
        <div className="agency-social-proof-card" key={content}>
          <div className="agency-social-proof-icon">
            <img src={image} className="img-fluid" />
          </div>
          <div className="agency-social-proof-content">{content}</div>
        </div>
      ))}
    </div>
  );
};

const StepProgress = ({
  accessibleSteps,
  classNames,
  currentStepIndex,
  steps,
  signupFlow,
  isWholesaler,
  fastStartEarning,
}) => {
  const currentStep = steps[accessibleSteps[currentStepIndex]];
  const { fastStartLogo } = fastStartContent;
  if (['agencyProfile'].includes(currentStep?.stepId)) {
    if (!signupFlow && !isWholesaler) {
      return (
        <>
          <div className="fast-start-wrap">
            <img src={fastStartLogo} />
            <div className="fast-start-content-wrap">
              <div className="fast-start-content">
                <p>
                  Earn{' '}
                  <span className="bold-text">${fastStartEarning?.new}</span>
                  <span className="break-text">
                    for first policy bound with{' '}
                    <span className="semibold">each</span> new carrier,
                  </span>
                </p>
                <p>
                  and{' '}
                  <span className="bold-text">
                    ${fastStartEarning?.additional}
                  </span>
                  <span className="break-text">
                    for every additional policy bound in your first 60 days
                  </span>
                </p>
              </div>
            </div>
          </div>
        </>
      );
    } else {
      return <Socialproof content={socialProofContent} />;
    }
  } else if (['agencyDetails'].includes(currentStep?.stepId)) {
    return (
      <>
        <div className="fast-start-wrap">
          <img src={fastStartLogo} />
          <div className="fast-start-content-wrap">
            <div className="fast-start-content">
              <p>
                Earn <span className="bold-text">${fastStartEarning?.new}</span>
                <span className="break-text">
                  for first policy bound with{' '}
                  <span className="semibold">each</span> new carrier,
                </span>
              </p>
              <p>
                and{' '}
                <span className="bold-text">
                  ${fastStartEarning?.additional}
                </span>
                <span className="break-text">
                  for every additional policy bound in your first 60 days
                </span>
              </p>
            </div>
          </div>
        </div>
      </>
    );
  }
  return (
    <>
      {currentStepIndex < 5 && (
        <Testimonials
          socialProofContent={testimonialContent?.[currentStepIndex - 1]}
          classNames={classNames}
        />
      )}
    </>
  );
};

StepProgress.propTypes = {
  accessibleSteps: PropTypes.array,
  classNames: PropTypes.object,
  currentStepIndex: PropTypes.number,
  steps: PropTypes.object,
  isWholesaler: PropTypes.bool,
  fastStartEarning: PropTypes.object,
};

StepProgress.defaultProps = {
  accessibleSteps: [],
  classNames: {},
  currentStepIndex: 0,
  steps: {},
  isWholesaler: false,
  fastStartEarning: {},
};

const mapStateToProps = ({ auth, impersonate, incentive }) => {
  const { isImpersonate, producer } = impersonate;
  const { isWholesaler } = getAuthDetails({ auth, producer, isImpersonate });
  const { fastStartEarning } = incentive;
  return {
    isWholesaler,
    fastStartEarning,
  };
};

export default connect(mapStateToProps, {})(StepProgress);
