import { Grid, TextField, Typography } from '@material-ui/core';
import NumberFormatCustom from 'components/currency-mask';
import Dropzone from 'components/dropzone';
import FormInput from 'components/form-input';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import SubmitLoader from 'components/submit-loader';
import CardActions from '@material-ui/core/CardActions';
import {
  AGGREGATE_LIMIT,
  AGGREGATE_LIMIT_ERROR,
  AGREEMENT,
  BUTTON_TYPE,
  DAYS,
  ENO_INFO,
  ENO_INFORMATION_FILE,
  EO,
  EXPIRATION_DATE,
  INPUT,
  INSURANCE_CARRIER,
  INVALID_DATE_FORMAT,
  LICENSE_AND_ENO,
  MM_DD_YYYY,
  MUST_BE_FUTURE_DATE,
  NEXT,
  POST_SIGNUP_STEPS_NAME,
  PRODUCERS,
  RENAME,
  SUBMIT,
  TYPE_TEXT,
} from 'constants/constant';
import { isEmpty, isUndefined } from 'lodash';
import * as moment from 'moment';
import React, { createRef } from 'react';
import { connect } from 'react-redux';
import {
  setCurrentStepIndex,
  setFileUploadError,
  stepSubmit,
  stepSubmitSuccess,
  submitLoaderHide,
} from 'redux/actions/post-sign-up';
import {
  imagePdfFileTypeMessage,
  stringIsEmpty,
  validateFileName,
} from 'util/extra';
import { blurHandlers, validators } from 'util/form-handler';
import IntlMessages from 'util/intl-messages';
import { date_format } from 'util/sign-up-process';
import MobileTransition from 'components/mobile-trasition';
import './step-5.scss';
import { isMobileOnly } from 'react-device-detect';
import SwitchToComputer from 'components/mobile-trasition/switch-to-computer';

const minAggregateLimit = 1000000;

const initState = {
  fieldsModified: false,
  formValues: {
    insuranceCarrier: '',
    aggregateLimit: '',
    expirationDate: '',
    file: null,
    fileName: '',
  },
  formErrors: {
    insuranceCarrier: '',
    aggregateLimit: '',
    expirationDate: '',
    fileName: '',
  },
};

class Step5 extends React.Component {
  constructor(props) {
    super(props);
    this.state = initState;
    this.createRef();
    this.errorField = null;
  }

  componentDidMount = () => {
    const { licenseAndEnO, submitLoader } = this.props;
    this.setState({
      formValues: {
        ...this.state.formValues,
        ...licenseAndEnO,
      },
    });
    if (licenseAndEnO?.fileName) {
      this?.dropZone?.current?.onFileExist(this.props.licenseAndEnO?.fileName);
    }
    submitLoader && this.props.submitLoaderHide();
  };

  componentDidUpdate = (prevProps) => {
    const { isErrorPresent } = this.props;
    if (prevProps.isErrorPresent !== isErrorPresent && isErrorPresent) {
      // eslint-disable-next-line
      this.dropZone.current?.onClearFile();
      this.setState({
        fieldsModified: true,
        formValues: { ...this.state.formValues, fileName: '', file: null },
      });
    }
  };

  createRef() {
    this.dropZone = createRef();
    this.refList = {
      insuranceCarrier: React.createRef(),
      aggregateLimit: React.createRef(),
      expirationDate: React.createRef(),
      fileName: React.createRef(),
    };
  }

  aggregateLimitChange = (e) => {
    // detects ONLY user changes(not plugin auto change trigger event).
    if (!isUndefined(window.event) && window.event.type === INPUT) {
      this.setState({
        fieldsModified: true,
        formValues: {
          ...this.state.formValues,
          [e.target.name]: e.target.value,
        },
      });
    }
  };

  validators = {
    insuranceCarrier: [validators.required],
    aggregateLimit: [
      validators.required,
      (value) =>
        validators.minIntInvalid(
          value,
          minAggregateLimit,
          AGGREGATE_LIMIT_ERROR
        ),
    ],
    expirationDate: [
      validators.required,
      (value) => {
        const d = moment();
        const today = moment(d).add(-1, DAYS);
        let compareDate = moment(value, date_format);
        let startDate = moment(today, date_format);
        const date = moment(value).add(-1, DAYS);
        let selectedDate = moment(date, date_format);
        if (!selectedDate.isValid()) {
          return INVALID_DATE_FORMAT;
        } else if (moment(compareDate).isBefore(startDate)) {
          return MUST_BE_FUTURE_DATE;
        }
        return '';
      },
    ],
    fileName: [(value) => validateFileName(value, RENAME)],
  };

  validate = () => {
    const { formValues, formErrors } = this.state;
    let isInvalid = false;
    const newErrors = { ...formErrors };

    for (const [key, validatorList] of Object.entries(this.validators)) {
      for (const validator of validatorList) {
        const error = validator(formValues[key]);
        newErrors[key] = error;

        if (error) {
          isInvalid = true;

          if (!this.errorField) {
            this.errorField = key;
          }

          break;
        }
      }
    }
    this.setState({ formErrors: newErrors });

    return !isInvalid;
  };

  submitForm = (event) => {
    event.preventDefault();
    const { signupFlow } = this.props;
    const { fieldsModified, formValues } = this.state;

    if (this.validate()) {
      if (fieldsModified) {
        this.props.stepSubmit({
          stepId: LICENSE_AND_ENO,
          entity: LICENSE_AND_ENO,
          completedSignupStep: POST_SIGNUP_STEPS_NAME.ENO,
          data: {
            ...formValues,
            expirationDate: moment(formValues.expirationDate).format(
              MM_DD_YYYY
            ),
            system_file_type: EO,
            directory_name: PRODUCERS,
            multipleFile: false,
          },
          signupFlow,
          stepProgress: ENO_INFO,
        });
      } else {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({ event: 'registrationComplete' });
        this.props.stepSubmitSuccess();
      }
    } else {
      this.refList[this.errorField]?.current?.scrollIntoView({
        behavior: 'smooth',
      });
      this.errorField = null;
    }
    localStorage.removeItem('page');
  };

  handleFileChange = (file, type) => {
    if (file[0]) {
      const filename = file[0].name;
      const { formErrors, formValues } = this.state;

      this.setState({
        formValues: {
          ...formValues,
          [type]: file,
          [type + 'Name']: filename,
        },
        formErrors: {
          ...formErrors,
          fileName: '',
        },
        fieldsModified: true,
      });
    }
  };

  setValue = (key, value, callback) => {
    this.setState(
      {
        fieldsModified: true,
        formValues: { ...this.state.formValues, [key]: value },
      },
      () => callback?.(key)
    );
  };

  fileTypeError = () => {
    const { formErrors, formValues } = this.state;

    this.setState({
      fieldsModified: true,
      formErrors: {
        ...formErrors,
        fileName: imagePdfFileTypeMessage,
      },
      formValues: {
        ...formValues,
        file: null,
        fileName: '',
      },
    });
  };

  render() {
    const { submitLoader, classNames, currentStepIndex, continueOnDevice } =
      this.props;
    const { formValues, formErrors } = this.state;
    const signupDeviceTransitionEmail = localStorage.getItem(
      'signupDeviceTransitionEmail'
    );
    const isSignupDeviceTransitionViewed = JSON.parse(
      localStorage.getItem('isSignupDeviceTransitionViewed')
    );
    const showSignupMobileTransition = JSON.parse(
      localStorage.getItem('showSignupMobileTransition')
    );
    const isEnabled =
      formValues.aggregateLimit.length > 0 &&
      formValues.expirationDate !== null &&
      formValues.insuranceCarrier.length > 0 &&
      formValues.fileName.length > 0;
    return (
      <>
        <div className="step-5">
          {((!continueOnDevice?.onPhone &&
            !continueOnDevice?.onComputer &&
            isMobileOnly) ||
            (!continueOnDevice?.onPhone &&
              continueOnDevice?.onComputer &&
              isMobileOnly)) &&
          (this.props?.switchToComputer || !isSignupDeviceTransitionViewed) &&
          showSignupMobileTransition ? (
            <>
              <Grid xs={12} item sm={6} className="p-15">
                <MobileTransition
                  email={
                    signupDeviceTransitionEmail !== 'null' &&
                    signupDeviceTransitionEmail
                      ? signupDeviceTransitionEmail
                      : this.props.agencyProfile.email
                  }
                  page="eno"
                />
              </Grid>
            </>
          ) : (
            <form
              className="post-signup-forms post-form-2 position-relative h-100"
              autoComplete="off"
              onSubmit={this.submitForm}
            >
              <Grid
                spacing={0}
                container
                className={`pb-0 ${classNames.cardContent} card-content`}
              />
              <Grid
                spacing={0}
                container
                className={`pb-0 ${classNames.cardContent} card-content`}
              >
                <Grid xs={12} item sm={6} className="p-15">
                  <label
                    className="text-label"
                    ref={this.refList.insuranceCarrier}
                  >
                    <IntlMessages id="label.enoInsuranceCarrier" />
                    <span className="required">*</span>
                  </label>
                  <FormInput
                    id={INSURANCE_CARRIER}
                    type={TYPE_TEXT}
                    className="validate fs-exclude"
                    name={INSURANCE_CARRIER}
                    maxLength={50}
                    handleBlur={blurHandlers.trim}
                    value={formValues.insuranceCarrier}
                    setValue={this.setValue}
                    valueKey="insuranceCarrier"
                    disabled={submitLoader}
                    error={formErrors.insuranceCarrier}
                    autoFocus={true}
                  />
                </Grid>
                <Grid xs={12} item sm={6} className="p-15">
                  <label
                    className="text-label"
                    ref={this.refList.aggregateLimit}
                  >
                    <IntlMessages id="label.enoAgrementLimit" />
                    <span className="required">*</span>
                  </label>
                  <TextField
                    id={AGGREGATE_LIMIT}
                    type={TYPE_TEXT}
                    className="aggregateLimit"
                    name={AGGREGATE_LIMIT}
                    onChange={this.aggregateLimitChange}
                    value={formValues.aggregateLimit}
                    disabled={submitLoader}
                    InputProps={{
                      inputComponent: NumberFormatCustom,
                    }}
                    inputProps={{
                      className: 'fs-exclude',
                    }}
                  />
                  <div className="error-msg">{formErrors.aggregateLimit}</div>
                </Grid>

                <Grid xs={12} item sm={6} className="license-step expDate p-15">
                  <label
                    className="text-label"
                    ref={this.refList.expirationDate}
                  >
                    <IntlMessages id="label.enoExpirationDate" />
                    <span className="required">*</span>
                  </label>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DesktopDatePicker
                      disablePast
                      name={EXPIRATION_DATE}
                      helperText={''}
                      disableToolbar
                      disabled={submitLoader}
                      error={false}
                      className="fs-exclude"
                      variant="inline"
                      format="MM/dd/yyyy"
                      placeholder="MM/DD/YYYY"
                      margin="normal"
                      id="date-picker-inline"
                      value={formValues.expirationDate}
                      onChange={(date) => this.setValue('expirationDate', date)}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </LocalizationProvider>
                  <div className="error-msg">{formErrors.expirationDate}</div>
                </Grid>
              </Grid>
              <Grid
                spacing={0}
                container
                className={`${classNames.cardContent} card-content`}
              >
                {isMobileOnly && !isEmpty(formValues.fileName) && (
                  <Grid xs={12} item sm={6} className="mob-doc-wrap">
                    <div className="doc-label-wrap">
                      <Typography
                        ref={this.refList.fileName}
                        variant="span"
                        className="doc-name"
                      >
                        {formValues.fileName}
                      </Typography>
                      <Dropzone
                        onFileAdded={(event) =>
                          this.handleFileChange(event, 'file')
                        }
                        ref={this.dropZone}
                        defaultText="Change"
                        componentClass="btn-change"
                        componentName={ENO_INFORMATION_FILE}
                        checkDashboard={false}
                        disabled={submitLoader}
                        buttonName="mobile-file-change"
                        typesOfFile={AGREEMENT}
                      >
                        Change
                      </Dropzone>
                    </div>
                  </Grid>
                )}
                <Grid
                  xs={12}
                  item
                  sm={6}
                  className="mar-t-40 upload-orange pt-0 expDate p-15"
                  ref={this.refList.fileName}
                >
                  <Dropzone
                    onFileAdded={(event) =>
                      this.handleFileChange(event, 'file')
                    }
                    ref={this.dropZone}
                    defaultText={
                      <IntlMessages id="label.uploadEandODocument" />
                    }
                    componentClass="inputfile inputfile-1 fs-exclude"
                    componentName={ENO_INFORMATION_FILE}
                    checkDashboard={false}
                    disabled={submitLoader}
                    buttonName={isMobileOnly ? 'change' : ''}
                    typesOfFile={AGREEMENT}
                    isAgeementFileTypeError={this.fileTypeError}
                  />
                  {!stringIsEmpty(formErrors.fileName) && (
                    <div className="error-msg">{formErrors.fileName}</div>
                  )}
                </Grid>
                {isMobileOnly && <SwitchToComputer />}
              </Grid>
              <CardActions
                className="signup-btn-action-card d-flex justify-content-between"
                style={{
                  top: `${this.props.visualViewportHeight}px`,
                }}
              >
                <button
                  type={BUTTON_TYPE}
                  className="white-back-button"
                  disabled={submitLoader}
                  onClick={() => {
                    this.props.setCurrentStepIndex(currentStepIndex - 1);
                    this.props.setFileUploadError(false);
                  }}
                >
                  <IntlMessages id="appModule.back" />
                </button>
                <button
                  type={SUBMIT}
                  className={`${
                    !isEnabled ? 'disable-button-grey' : 'orange-next-button'
                  }`}
                  id={NEXT}
                  disabled={submitLoader || !isEnabled}
                >
                  <IntlMessages id="appModule.next" />
                  {submitLoader && (
                    <SubmitLoader className={classNames.submitLoader} />
                  )}
                </button>
              </CardActions>
            </form>
          )}
        </div>
      </>
    );
  }
}

const mapStateToProps = ({
  postSignup: {
    agencyProfile,
    licenseAndEnO,
    submitLoader,
    validatedSteps,
    isErrorPresent,
    currentStepIndex,
    continueOnDevice,
    switchToComputer,
  },
  auth: { signupFlow },
}) => {
  return {
    agencyProfile,
    currentStepIndex,
    isErrorPresent,
    licenseAndEnO,
    signupFlow,
    submitLoader,
    validatedSteps,
    continueOnDevice,
    switchToComputer,
  };
};

export default connect(mapStateToProps, {
  stepSubmit,
  setFileUploadError,
  setCurrentStepIndex,
  stepSubmitSuccess,
  submitLoaderHide,
})(Step5);
