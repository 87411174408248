import React from 'react';
import { Box } from '@material-ui/core';

import './pill-label.scss';

/**
 * @param {{ children: React.ReactNode; styles?: React.CSSProperties }} props
 * @returns {JSX.Element}
 */
function PillLabel({ children, styles = {} }) {
  return (
    <Box style={styles} className="pill-label">
      {children}
    </Box>
  );
}

export default PillLabel;
