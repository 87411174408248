import * as userPermissionAction from '../../constants/action-types';

// User Permission action methods
export const fetchUserPermissionList = () => ({
  type: userPermissionAction.FETCH_USER_PERMISSION_LIST,
});

export const setUserPermissionList = payload => ({
  type: userPermissionAction.SET_USER_PERMISSION_LIST,
  payload,
});

export const setUserPermissionLoader = payload => ({
  type: userPermissionAction.USER_PERMISSION_LIST_LOADER,
  payload,
});

export const addNewUser = payload => ({
  type: userPermissionAction.ADD_NEW_USER,
  payload,
});

export const setNewUser = payload => ({
  type: userPermissionAction.SET_NEW_USER,
  payload,
});

export const addNewUserLoader = payload => ({
  type: userPermissionAction.ADD_NEW_USER_LOADER,
  payload,
});

export const clearUserData = payload => ({
  type: userPermissionAction.CLEAR_USER_DATA,
  payload,
});

export const fetchRolesList = () => ({
  type: userPermissionAction.FETCH_ROLES_LIST,
});

export const setRolesList = payload => ({
  type: userPermissionAction.SET_ROLES_LIST,
  payload,
});

export const fetchRolesListLoader = payload => ({
  type: userPermissionAction.FETCH_ROLES_LIST_LOADER,
  payload,
});

export const clearRolesData = payload => ({
  type: userPermissionAction.CLEAR_ROLES_DATA,
  payload,
});

export const updateUser = payload => ({
  type: userPermissionAction.UPDATE_USER_DATA,
  payload,
});

export const setUserUpdatedData = payload => ({
  type: userPermissionAction.SET_UPDATED_USER_DATA,
  payload,
});
