export const MODAL_TYPE = {
  CONSENT_FORM: 'consent-form',
  POST_CONFIRMATION: 'post-confirmation',
  CONSENT_RECONFIRMATION: 'consent-reconfirmation',
  GREETINGS: 'greetings',
};

export const CONSENT_CONFIRMATION_ERROR_MESSAGE =
  'Please check to consent to receive updates.';

export const CONSENT_FORM = {
  PHONE_LABEL: 'Your phone number',
  PHONE_KEY: 'phone',
  CONSENT_CONFIRMATION_CHECKBOX_LABEL:
    'By selecting “Confirm” below, you consent to receive automated business and marketing calls and/or texts from or on behalf of First Connect Insurance Services and its affiliates regarding available products, services, incentives, and other offerings, at the phone number listed above. Consent is not a condition of doing business with First Connect. You can opt out at any time.',
  IS_CONCENT_CONFIRMED_KEY: 'isConsentConfirmed',
};
export const CONSENT_MODAL_CONTENT = {
  NOT_INTERSTED_IN_NEW_CARRRIER: `No thanks, I’m not interested in new carriers or incentives`,
  PLEASE_CIONFIRM_PHONE_NO_CONTENT: `As always, your information stays with us and you’ll only get the
  relevant updates. Please confirm your phone number below:`,
  ANNOUNCING_NEW_CARRIER:
    'we’ll be announcing many new carriers and incentives.',
  IN_NEXT_FEW_MONTH: 'In the next few months, ',
  UPTO_DATE_WITH_ALL_CONTENT: `like to make sure you’re kept up-to-date with all of
  these.`,
  ARE_YOU_SURE: 'Are you sure?',
  WE_NEED_YOUR_CONSENT: `We need your consent to send you messages when we launch new
  carriers that match your profile (there are many exciting ones in
  the pipeline!) or when we announce a new incentive that you could
  benefit from. You’ll be able to easily opt out at any time. What
  do you say?`,
  DONT_UPDATE_ME: `Don't update me`,
  I_AM_IN: `I'm in!`,
  OK_THEN: `Ok, then...`,
  WE_WILL_NOT_UPDATE_YOU: `We will not text you with important updates. Please reach out to us
  if you change your mind.`,
  THANK_YOU: 'Thank you!',
  WE_WILL_UPDATE_YOU:
    'We’ll keep you up-to-date about new carriers and incentives.',
};
