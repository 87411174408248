import * as types from '../../constants/action-types';

const initState = () => ({
  reportListLoader: false,
  agencyName: '',
  agentReportList: [],
  agentAdhocReportlist: [],
  agentAdhocReportListLoader: false,
  producerCode: '',
  commissionRecommendationBanner: null,
});

export default (state = initState(), action) => {
  switch (action.type) {
    case types.SET_AGENT_REPORT_LIST: {
      return { ...state, agentReportList: [...action.payload] };
    }

    case types.AGENT_REPORT_LIST_LOADER: {
      return {
        ...state,
        reportListLoader: action.payload,
      };
    }

    case types.SET_AGENT_ADHOC_REPROT_LIST: {
      const { agentAdhocReportlist, agencyName, producerCode } = action.payload;
      return {
        ...state,
        agencyName: agencyName,
        agentAdhocReportlist: [...agentAdhocReportlist],
        producerCode: producerCode,
      };
    }

    case types.AGENT_ADHOC_REPORT_LIST_LOADER: {
      return {
        ...state,
        agentAdhocReportListLoader: action.payload,
      };
    }

    case types.SET_COMMISSION_RECOMMENDATION_BANNER: {
      return {
        ...state,
        commissionRecommendationBanner: action.payload,
      }
    }

    default:
      return state;
  }
};
