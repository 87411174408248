// @ts-check
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Badge } from '@material-ui/core';
import NotificationsBellIcon from 'assets/images/notifications-bell.svg';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import { navigateToNewAppRoute } from '../../util/navigate-to-new-app-route';
import { NEW_APP_ROUTES } from '../../constants/constant';
import { fetchNotifications as fetchNotificationsAction } from '../../redux/actions';
import { NOTIFICATION_CENTER } from '../../constants/heap-events';

const useStyles = makeStyles({
  root: {
    marginRight: '16px',
    position: 'relative',
    '&:hover': {
      backgroundColor: 'unset',
    },
  },
  customBadge: {
    '& > .MuiBadge-dot': {
      background: '#DF534F',
      width: '16px',
      height: '16px',
      borderRadius: '50%',
      top: '4px',
      right: '4px',
    },
  },
});

/**
 * @param {object} props
 * @param {number} props.unseenNotificationsCount
 * @param {Function} props.fetchNotifications
 * @returns {JSX.Element}
 */
function NotificationsBell({
  fetchNotifications,
  unseenNotificationsCount,
}) {
  const styles = useStyles();
  const handleClick = () => {
    window.heap.track(NOTIFICATION_CENTER.CLICK, {
      'Unread Messages': unseenNotificationsCount,
    });
    navigateToNewAppRoute(`${NEW_APP_ROUTES.DASHBOARD}?notifications=true`);
  };

  useEffect(() => {
    let intervalId;
    fetchNotifications();
    intervalId = setInterval(fetchNotifications, 1000 * 60 * 5); // fetch every 5 minutes
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [fetchNotifications]);


  return (
    <IconButton disableRipple className={styles.root} onClick={handleClick}>
      <Badge
        invisible={unseenNotificationsCount === 0}
        variant="dot"
        className={styles.customBadge}
        anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
      >
        <img
          src={NotificationsBellIcon}
          alt="Notifications Icon"
          className="w-24"
        />
      </Badge>
    </IconButton>
  );
}

const mapStateToProps = ({ auth }) => {
  const { unseenNotificationsCount } = auth;
  return { unseenNotificationsCount };
};

export default connect(mapStateToProps, {
  fetchNotifications: fetchNotificationsAction,
})(NotificationsBell);
