import * as rolePermissionActions from 'constants/action-types';
import { ACTIVE_STATUS, ERROR_MESSAGE } from 'constants/constant';
import { cloneDeep, sortBy } from 'lodash';
import { displayErrorMessage, displaySuccessMessage } from 'util/user-feedback';
import { all, fork, put, select, takeEvery } from 'redux-saga/effects';
import axios from 'util/api';
import { errorHandler } from '../actions/error';
import {
  categoryListLoader,
  setCategoryList,
  setIsFetchFailed,
  setNewRolePermissionList,
  setRoleEditPermissionList,
  setRoleEditPermissionListLoader,
  setRolePermissionList,
  setRolePermissionLoader,
  setUpdatedRolePermission,
  updateRolePermissionLoader,
} from '../actions/role-permission';

// ---------------- FETCH ROLE PERMISSIO LIST-----------------
function* fetchRolePermissionList() {
  try {
    yield put(setRolePermissionLoader(true));
    const rolePermissionDetails = yield axios.get('/api/v1/permission/roles');
    if (!!rolePermissionDetails) {
      const { status, data } = rolePermissionDetails;
      if (status === 200) {
        const roleList = data.roles;
        yield put(setRolePermissionList(roleList));
      }
    }
  } catch (error) {
    if (!!error.response && error.response.status === 401) {
yield displayErrorMessage(error.response.data.message)
    } else {
      yield put(errorHandler(error));
    }
  }
  yield put(setRolePermissionLoader(false));
}

export function* fetchRolesPermissionListWatcher() {
  yield takeEvery(
    rolePermissionActions.FETCH_ROLE_PERMISSION_LIST,
    fetchRolePermissionList
  );
}

// ----------------ADD NEW ROLE-----------------
function* addNewRoleData({ payload }) {
  try {
    yield put(setRolePermissionLoader(true));
    const reduxState = yield select();
    let producer = {
      firstname: reduxState.auth.loginName,
      lastname: reduxState.auth.userLastName,
    };
    const response = yield axios.post('/api/v1/permission/add-role', {
      display_name: payload.display_name,
      description: payload.description,
      category_code: payload.category_code,
      status: ACTIVE_STATUS,
    });
    const { data, status } = response;
    if (status === 200 && data.success) {
      const { role, message } = response.data;
      yield put(
        setNewRolePermissionList({
          role: {
            created_at: role.created_at,
            created_by: role.created_by,
            description: role.description,
            display_name: role.display_name,
            id: role.id,
            role_code: role.role_code,
            status: role.status,
            total_users: 0,
            updated_at: role.updated_at,
            updated_by: role.updated_by,
            updated_by_user: producer,
            role_category: payload.category_display_name,
          },
          message,
        })
      );
      yield displaySuccessMessage(data.message);
      yield put(setRolePermissionLoader(false));
    } else {
      yield displayErrorMessage(response.data.message);
      yield put(setRolePermissionLoader(false));
    }
  } catch (error) {
    if (!!error.response && error.response.status === 401) {
yield displayErrorMessage(error.response.data.message)
    } else {
      yield put(errorHandler(error));
    }
    yield put(setRolePermissionLoader(false));
  }
}

export function* addNewRoleWatcher() {
  yield takeEvery(rolePermissionActions.ADD_NEW_ROLE, addNewRoleData);
}

// ---------------- FETCH ROLE EDIT PERMISSIO LIST-----------------
function* fetchRoleEditPermissionList({ payload }) {
  try {
    yield put(setRoleEditPermissionListLoader(true));
    const roleEditPermissionList = yield axios.get(
      `/api/v1/permission/role-permissions/${payload.role}`
    );
    if (!!roleEditPermissionList) {
      const { status, data } = roleEditPermissionList;
      if (status === 200) {
        const { modules, success, role_details } = data;
        const sortModules = sortBy(modules, ['display_order']);
        if (success) {
          yield put(setRoleEditPermissionList({ sortModules, role_details }));
        }
      }
    }
  } catch (error) {
    if (!!error.response && error.response.status === 401) {
yield displayErrorMessage(error.response.data.message)
    } else {
      yield put(setIsFetchFailed(true));
      yield put(errorHandler(error));
    }
  }
  yield put(setRoleEditPermissionListLoader(false));
}

export function* fetchRoleEditsPermissionListWatcher() {
  yield takeEvery(
    rolePermissionActions.FETCH_ROLE_EDIT_PERMISSION_LIST,
    fetchRoleEditPermissionList
  );
}

// ---------------- UPDATE ROLE PERMISSION-----------------
function* updateRolePermissionData({ payload }) {
  try {
    const reduxState = yield select();
    let updateEditRolePermissionList =
      reduxState.rolePermission.editRolePermissionList;
    yield put(updateRolePermissionLoader(true));
    const updateRoleResponse = yield axios.post(
      `/api/v1/permission/add-role-permission`,
      payload
    );
    if (!!updateRoleResponse) {
      const { status, data } = updateRoleResponse;
      const { message, success } = data;
      if (status === 200 && success && !!payload) {
        let updatedArray = cloneDeep(payload.modules);

        let existingFilteredList = updateEditRolePermissionList.filter(
          o => !payload.modules.find(o2 => o.id === o2.id)
        );
        existingFilteredList.map(item => updatedArray.push(item));
        const sortedModules = sortBy(updatedArray, ['display_order']);
        yield put(
          setUpdatedRolePermission({
            updateEditRolePermissionList: sortedModules,
            success: true,
          })
        );
        yield displaySuccessMessage(message);
      } else {
        yield displayErrorMessage(message);
      }
    } else {
      yield displayErrorMessage(ERROR_MESSAGE);
    }
  } catch (error) {
    if (!!error.response && error.response.status === 401) {
yield displayErrorMessage(error.response.data.message)
    } else {
      yield put(errorHandler(error));
    }
  }
  yield put(updateRolePermissionLoader(false));
}

export function* updateRolePermissionWatcher() {
  yield takeEvery(
    rolePermissionActions.UPDATE_ROLE_PERMISSION,
    updateRolePermissionData
  );
}

// ---------------- DELETE ROLE ROW-----------------
function* deleteRole({ payload }) {
  try {
    yield put(setRolePermissionLoader(true));
    const reduxState = yield select();
    const responceData = yield axios.post(`/api/v1/permission/delete-role`, {
      role_id: payload.role_id,
      role_code: payload.role_code,
    });
    const { data } = responceData;
    if (data.success) {
      var updatedRole = reduxState.rolePermission.rolePermissionList.filter(
        function(el) {
          return (
            el.id !== payload.role_id && el.role_code !== payload.role_code
          );
        }
      );
      yield displaySuccessMessage(data.message);
      yield put(setRolePermissionList(updatedRole));
    } else {
      yield displayErrorMessage(data.message);
    }
  } catch (error) {
    if (!!error.response && error.response.status === 401) {
yield displayErrorMessage(error.response.data.message)
      yield put(errorHandler(error));
    } else {
      yield put(errorHandler(error));
    }
  }
  yield put(setRolePermissionLoader(false));
}

export function* deleteRoleWatcher() {
  yield takeEvery(rolePermissionActions.DELETE_ROLE, deleteRole);
}

function* fetchCategoryList() {
  try {
    yield put(categoryListLoader(true));
    const response = yield axios.get('/api/v1/permission/all-categories');
    const { data } = response;
    if (response.status === 200 && data.success) {
      yield put(setCategoryList(data.userCategories));
    } else {
      yield put(categoryListLoader(false));
    }
  } catch (error) {
    if (!!error.response && error.response.status === 401) {
yield displayErrorMessage(error.response.data.message)
      yield put(errorHandler(error));
    } else {
      yield put(errorHandler(error));
    }
  }
  yield put(categoryListLoader(false));
}
export function* fetchCategoryListWatcher() {
  yield takeEvery(rolePermissionActions.FETCH_CATEGORY_LIST, fetchCategoryList);
}

// ------ ROOT SAGA -----------------
export default function* rootSaga() {
  yield all([
    fork(fetchRolesPermissionListWatcher),
    fork(fetchRoleEditsPermissionListWatcher),
    fork(addNewRoleWatcher),
    fork(deleteRoleWatcher),
    fork(updateRolePermissionWatcher),
    fork(fetchCategoryListWatcher),
  ]);
}
