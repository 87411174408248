import React from 'react';
import { connect } from 'react-redux';
import { hideMessage, setInitUrl, userAutoSignIn } from '../redux/actions/auth';
import { NotificationContainer } from 'react-notifications';
import getQueryParams from 'util/query-string';
import ProcessHeader from 'components/process-header';
import Footer from 'components/process-footer';
import EmailVerifiedCard from 'components/email-verified-card';
import { displayErrorMessage } from 'util/user-feedback';
import DancingDots from 'components/dancing-dots';

class EmailVerification extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
  }

  componentDidMount() {
    const { location } = this.props;
    const id = getQueryParams(location.search, 'id');
    // if id found then update store otherwise go to login page.
    if (id) {
      this.props.userAutoSignIn({ id });
    } else {
      this.props.setInitUrl('/signin');
      this.props.history.push('/signin');
    }
  }

  componentDidUpdate() {
    if (this.props.showMessage) {
      setTimeout(() => {
        this.props.hideMessage();
      });
    }
  }

  gotoSignInPage = () => {
    this.props.setInitUrl('/signin');
    this.props.history.push('/signin');
  };

  render() {
    const { loader, alertMessage, showMessage } = this.props;

    return (
      <div className={`app-container pre-signup-wrapper`}>
        <div className="app-main-container">
          <div className={`app-header`}>
            <ProcessHeader />
          </div>
          <main className="app-main-content-wrapper email-verification">
            <div className="app-main-content">
              <div className="app-login-container d-flex justify-content-center align-items-center">
                {loader ? (
                  <div className="loader-view">
                    <DancingDots fullpageWrapper />
                  </div>
                ) : (
                  <EmailVerifiedCard
                    onClick={this.gotoSignInPage}
                    loader={loader}
                  />
                )}
                {showMessage && displayErrorMessage(alertMessage)}
                <NotificationContainer />
              </div>
            </div>
            <Footer />
          </main>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ auth }) => {
  const { authUser, loader, alertMessage, showMessage, initURL } = auth;
  return { authUser, loader, alertMessage, showMessage, initURL };
};

export default connect(mapStateToProps, {
  userAutoSignIn,
  hideMessage,
  setInitUrl,
})(EmailVerification);
