import * as types from '../../constants/action-types';
import { findIndex, forEach, get, isEmpty, remove, uniq } from 'lodash';
import {
  BUSINESS,
  FIRM,
  INDIVIDUAL,
  LICENSE_AND_ENO,
  UPDATED_KEY,
} from 'constants/constant';

const initState = () => ({
  loader: false,
  details: null,
  documentLoader: false,
  bucket_url: null,
  directoryList: [],
  checkBankActDetDuplication: false,
  closedModel: false,
  isAgentDocumentUploading: false,
  isAgentDocumentUploaded: false,
  emailDuplication: false,
  licenseListLoader: false,
  isSubagentStatusUpdated: false,
  isTeamMemberAdded: false,
  teamMemberEmailDuplicate: {
    success: false,
    message: null,
    messageType: null,
  },
  niperRefreshConfirmation: false,
  fetchNipperListLoader: false,
  niprResponseModal: false,
  refreshMessages: [],
  validateNiprResponse: [],
  isAgentDocumentUploadErrorPresent: false,
  invalidFileType: null,
  licenseFileLoader: false,
  activate: false,
  showW9DownloadBlockedModal: false,
  teamMemberDetailsLoader: false,
  teamMemberDuplicateEmailList: [],
  duplicatePhoneNumber: false,
  isStepUpModalShown: false,
});

export default (state = initState(), action) => {
  switch (action.type) {
    case types.SET_AGENT_DETAILS: {
      return {
        ...state,
        details: { ...action.payload, niperRefreshConfirmation: false },
        niprResponseModal: false,
        refreshMessages: [],
      };
    }

    case types.SET_SUB_AGENT_PROMPT_VALUE: {
      return {
        ...state,
        details: {
          ...state.details,
          producers: {
            ...state.details.producers,
            sub_agent_prompt: action.payload,
          },
        },
      };
    }
    case types.AGENT_DETAILS_LOADER: {
      return {
        ...state,
        loader: action.payload,
        niperRefreshConfirmation: false,
        refreshMessages: [],
        licenseFileLoader: false,
        licenseListLoader: false,
      };
    }

    case types.DASHBOARD_AGENT_INFO_UPDATE: {
      return {
        ...state,
        details: {
          ...state.details,
          producers: {
            ...state.details.producers,
            agencyProfile: {
              ...state.details.producers.agencyProfile,
              ...action.payload.agencyProfile,
            },
            bankInformation: {
              ...state.details.producers.bankInformation,
              ...action.payload.bankInformation,
            },
            tax: {
              ...state.details.producers.tax,
              ...action.payload.tax,
            },
            licenseAndEnO: {
              ...state.details.producers.licenseAndEnO,
              ...action.payload.licenseAndEnO,
            },
          },
          niperRefreshConfirmation: false,
        },
      };
    }

    case types.UPDATE_STORED_SETTINGS: {
      const {
        newData: {
          name,
          incentive,
          commission,
          appointed,
          mapping,
          agencyCode,
        },
        oldData,
      } = action.payload;
      let settingList = [
        ...state.details.producers.commission_payment_settings,
      ];
      // for existing data updates
      if (!isEmpty(oldData)) {
        const index = findIndex(settingList, (item) => {
          return item.display_name === oldData.name;
        });

        settingList[index] = {
          ...settingList[index],
          incentive_paid_to: incentive,
          commission_paid_to: commission,
          display_name: name,
          appointed,
          carrier_producer_code: mapping,
          carrier_agency_code: agencyCode,
        };
      } else {
        // for new data updates
        settingList = [
          ...settingList,
          {
            incentive_paid_to: incentive,
            commission_paid_to: commission,
            display_name: name,
            appointed,
            carrier_producer_code: mapping,
            carrier_agency_code: agencyCode,
          },
        ];
      }

      return {
        ...state,
        details: {
          ...state.details,
          producers: {
            ...state.details.producers,
            commission_payment_settings: [...settingList],
          },
        },
      };
    }

    case types.UPDATE_STORED_LICENSE_LIST: {
      const {
        oldData,
        newData: {
          license,
          date,
          dne,
          stateData,
          loa,
          licenseType,
          status,
          isPrimary,
          licenseClass,
          uuid,
          producerUpdated,
          updatedByProducerName,
          fromNIPR,
          updatedAt,
        },
        licenseComplianceStatus,
        uploadFileUrl,
      } = action.payload;
      let licenseList;
      let otherStateLicensesData;
      if (
        !get(state.details.producers.licenseAndEnO, 'otherStateLicenses') ||
        !state.details.producers.licenseAndEnO.otherStateLicenses
      ) {
        otherStateLicensesData = {};
        licenseList = [];
      } else {
        licenseList = [
          ...state.details.producers.licenseAndEnO.otherStateLicenses
            .licenseData,
        ];
      }

      // Existing License details update.
      if (!isEmpty(oldData)) {
        if (oldData.licenseType.toLowerCase() === BUSINESS) {
          oldData.licenseType = FIRM;
        } else if (
          oldData.licenseType.toLowerCase() === INDIVIDUAL.toLowerCase()
        ) {
          oldData.licenseType = INDIVIDUAL.toUpperCase();
        }
        const index = findIndex(licenseList, (item) => {
          let newLicenseClass = !isEmpty(get(item, 'licenseClass', ''))
            ? item.licenseClass.toLowerCase()
            : '';
          let newLicenseClassCopy =
            !!newLicenseClass && newLicenseClass.toLowerCase();
          let oldLicenseClassCopy =
            !!oldData?.licenseClass && oldData.licenseClass.toLowerCase();
          let checkData =
            item.licenseType === oldData.licenseType &&
            item.licenseNumber === oldData.license &&
            item.loa === oldData.loa &&
            item.state === oldData.stateData &&
            item.status === oldData.status &&
            newLicenseClassCopy === oldLicenseClassCopy;
          return checkData;
        });
        licenseList[index] = {
          ...licenseList[index],
          licenseNumber: license,
          expiryDate: !dne ? date : null,
          state: stateData,
          loa: loa,
          licenseType,
          status,
          isPrimary: !!isPrimary ? isPrimary : false,
          producerUpdated: !!producerUpdated ? producerUpdated : false,
          licenseClass: !!licenseClass ? licenseClass : '',
          uuid,
          fileUrl: uploadFileUrl,
          updatedByProducerName: updatedByProducerName,
          fromNIPR: fromNIPR,
          updatedAt: updatedAt,
        };
      } else {
        // for new data updates
        licenseList = [
          ...licenseList,
          {
            licenseNumber: license,
            expiryDate: !dne ? date : null,
            state: stateData,
            loa: loa,
            licenseType,
            status,
            isPrimary: !!isPrimary ? isPrimary : false,
            producerUpdated: !!producerUpdated ? producerUpdated : false,
            licenseClass: !!licenseClass ? licenseClass : '',
            uuid,
            fileUrl: uploadFileUrl,
            updatedByProducerName: updatedByProducerName,
            fromNIPR: fromNIPR,
            updatedAt: updatedAt,
          },
        ];
      }

      return {
        ...state,
        details: {
          ...state.details,
          producers: {
            ...state.details.producers,
            licenseAndEnO: {
              ...state.details.producers.licenseAndEnO,
              license_compliance_status: licenseComplianceStatus,
              otherStateLicenses: {
                ...otherStateLicensesData,
                licenseData: [...licenseList],
              },
            },
          },
          niperRefreshConfirmation: false,
        },
        activate: !!action.payload.activate && action.payload.activate,
      };
    }

    // On License list loader
    case types.LICENSE_LIST_LOADER: {
      return {
        ...state,
        licenseListLoader: action.payload,
      };
    }

    // Updating stored sub agent list on '+' click of modal.
    case types.STORE_NEW_SUBAGENT: {
      const { license_info, ...rest } = action.payload;
      const newSubAgent = {
        ...rest,
        writesUnderAgencyLicense: license_info?.writesUnderAgencyLicense,
      };
      return {
        ...state,
        details: {
          ...state.details,
          producers: {
            ...state.details.producers,
            sub_producers: [
              ...state.details.producers.sub_producers,
              { ...newSubAgent },
            ],
          },
        },
      };
    }

    case types.SET_AGENT_YTD_STATS: {
      return {
        ...state,
        details: {
          ...state.details,
          producers: {
            ...state.details?.producers,
            ...action.payload,
          },
        },
      };
    }

    case types.STORE_AGENT_DETAILS: {
      const {
        data,
        entity,
        isActivate,
        responseStatus,
        commissionPaymentSetting,
        updateNPN,
        content,
      } = action.payload;
      if (entity === LICENSE_AND_ENO && updateNPN) {
        if (!data?.individualNPN) {
          data.individualNPN = null;
        }
        if (!data?.businessNPN) {
          data.businessNPN = null;
        }
      }
      return {
        ...state,
        details: {
          ...state.details,
          producers: {
            ...state.details.producers,
            activate: isActivate,
            [entity]: {
              ...state.details.producers[entity],
              ...data,
            },
            commission_payment_settings: !!commissionPaymentSetting
              ? commissionPaymentSetting
              : [...state.details.producers.commission_payment_settings],
            notes: {
              ...state?.details?.producers?.notes,
              content: isEmpty(content)
                ? state?.details?.producers?.notes?.content
                : content,
            },
          },
          responseStatus,
        },
        niprResponseModal: false,
        refreshMessages: [],
        validateNiprResponse: [],
      };
    }

    case types.UPDATE_LICENSE_LIST: {
      return {
        ...state,
        details: {
          ...state.details,
          producers: {
            ...state.details.producers,
            licenseAndEnO: {
              ...state.details.producers.licenseAndEnO,
              license_compliance_status: UPDATED_KEY,
              otherStateLicenses: { ...action.payload },
            },
          },
          niperRefreshConfirmation: false,
        },
      };
    }

    case types.STORED_DOCUMENT_LOADER: {
      return {
        ...state,
        documentLoader: action.payload,
      };
    }

    case types.STORE_DOCUMENT_DETAILS: {
      const files = get(action, 'payload.files', []);
      const directories = get(action, 'payload.directories', []);
      const directoryName = get(action, 'payload.directoryName', '');
      const listNames = [];

      if (directories.length > 0) {
        forEach(directories, (dir) => {
          listNames.push(dir.Key.replace(/\/$/, ''));
        });
      }

      const uniqList = uniq([
        ...state.directoryList,
        directoryName,
        ...listNames,
      ]);

      return {
        ...state,
        directoryList: [...uniqList],
        [action.payload.directoryName]: {
          documentFilesDetails: [...files],
          documentFoldersDetails: [...directories],
        },
        bucket_url: action.payload.bucket_url,
      };
    }

    case types.CLEAR_FOLDER_LIST: {
      return {
        ...state,
        directoryList: [],
      };
    }

    case types.DELETE_DOCUMENT_DETAILS: {
      return {
        ...state,
        [action.payload.folder_name]: {
          ...state[action.payload.folder_name],
          documentFilesDetails: [...action.payload.updatedArray],
        },
      };
    }

    case types.RENAME_DOCUMENT_DETAILS: {
      return {
        ...state,
        [action.payload.folder_name]: {
          ...state[action.payload.folder_name],
          documentFilesDetails: [...action.payload.updatedArray],
        },
      };
    }

    case types.AGENT_DOCUMENT_FILES_UPLOADING: {
      return {
        ...state,
        isAgentDocumentUploading: true,
        isAgentDocumentUploaded: false,
      };
    }

    case types.AGENT_DOCUMENT_FILES_UPLOAD_DONE: {
      return {
        ...state,
        isAgentDocumentUploading: false,
        isAgentDocumentUploaded: true,
      };
    }

    case types.AGENT_DOCUMENT_FILES_TREE_UPDATE: {
      var filteredArray =
        !!state[action?.payload?.folder_name] &&
        state[action?.payload?.folder_name]?.documentFilesDetails
          ? remove(
              state[action.payload.folder_name].documentFilesDetails,
              function (file) {
                return !file.Key.includes(action.payload.file.Key);
              }
            )
          : [];
      return {
        ...state,
        [action.payload.folder_name]: {
          ...state[action.payload.folder_name],
          documentFilesDetails: [...filteredArray, { ...action.payload.file }],
        },
      };
    }

    case types.CHECK_BANKACTDETAILS_DUPLICATION: {
      return {
        ...state,
        checkBankActDetDuplication: action.payload,
      };
    }

    case types.CLOSED_MODEL: {
      return {
        ...state,
        closedModel: action.payload,
      };
    }

    case types.STORE_SUB_AGENT_STATUS: {
      const { status } = action.payload;
      return {
        ...state,
        details: {
          ...state.details,
          producers: {
            ...state.details.producers,
            agencyProfile: {
              ...state.details.producers.agencyProfile,
              status: status,
            },
            status: status,
          },
        },
      };
    }

    case types.SUBAGENT_ADDED: {
      return {
        ...state,
        isTeamMemberAdded: action.payload,
      };
    }

    case types.SUBAGENT_EMAIL_DUPLICATION: {
      return {
        ...state,
        teamMemberEmailDuplicate: action.payload,
      };
    }
    case types.INVALID_FILE_TYPE: {
      return {
        ...state,
        invalidFileType: action.payload,
      };
    }
    case types.STORE_AGREEMENT_DATA: {
      return {
        ...state,
        details: {
          ...state.details,
          producers: {
            ...state.details.producers,
            has_accepted_agreement: action.payload,
          },
        },
      };
    }
    case types.LICENSE_FILE_LOADER: {
      return {
        ...state,
        licenseFileLoader: action.payload,
      };
    }
    case types.UPDATE_NPN_DATA: {
      return {
        ...state,
        details: {
          ...state.details,
          producers: {
            ...state.details.producers,
            licenseAndEnO: {
              ...state.details.producers.licenseAndEnO,
              ...action.payload,
            },
          },
          niperRefreshConfirmation: false,
        },
      };
    }

    case types.UPDATE_AGENT_ROLE: {
      return {
        ...state,
        details: {
          ...state.details,
          producers: {
            ...state.details.producers,
            role: action.payload,
          },
        },
      };
    }

    case types.STORE_COMPIANCE_LICENSE: {
      return {
        ...state,
        details: {
          ...state.details,
          producers: {
            ...state.details.producers,
            licenseAndEnO: {
              ...state.details.producers.licenseAndEnO,
              ...action.payload.data,
              businessCompanyName: action.payload.data.businessCompanyName,
              individualName: action.payload.data.individualName,
            },
          },
        },
        niprResponseModal: action.payload.show,
        refreshMessages: [...action.payload.refreshMessages],
      };
    }

    case types.RESPONSE_MODEL: {
      return {
        ...state,
        niprResponseModal: action.payload,
      };
    }

    case types.CLEAR_REFRESH_MESSAGE: {
      return {
        ...state,
        niprResponseModal: false,
        refreshMessages: null,
      };
    }

    case types.UPDATE_LICENSE_ON_REFRESH: {
      const { licenseInfo, refreshResponse } = action.payload;
      return {
        ...state,
        details: {
          ...state.details,
          producers: {
            ...state.details.producers,
            licenseAndEnO: {
              ...state.details.producers.licenseAndEnO,
              otherStateLicenses: licenseInfo.otherStateLicenses,
              niprLicenseRefreshResponse: refreshResponse,
            },
          },
          niperRefreshConfirmation: true,
        },
      };
    }

    case types.UPDATE_LICENSE_ON_REFRESH_LOADER: {
      return {
        ...state,
        details: {
          ...state.details,
          fetchNipperListLoader: action.payload,
          niperRefreshConfirmation: false,
        },
      };
    }

    case types.SET_VALIDATE_NIPR_RESPONSE: {
      return {
        ...state,
        validateNiprResponse: [...action.payload],
      };
    }

    case types.UPDATE_AGENT_STATUS_REASON: {
      const { content } = action.payload;
      return {
        ...state,
        details: {
          ...state.details,
          producers: {
            ...state.details.producers,
            notes: { content: content },
          },
        },
      };
    }
    case types.UPDATE_AGENT_COMPLIANCE: {
      const { complianceStatus } = action.payload;
      return {
        ...state,
        details: {
          ...state.details,
          producers: {
            ...state.details.producers,
            tax: {
              ...state.details.producers.tax,
              w9_compliance_status: !!complianceStatus?.w9
                ? complianceStatus.w9
                : { ...state.details.producers.tax.w9_compliance_status },
            },
            licenseAndEnO: {
              ...state.details.producers.licenseAndEnO,
              license_compliance_status: !!complianceStatus?.license
                ? complianceStatus.license
                : {
                    ...state.details.producers.licenseAndEnO
                      .license_compliance_status,
                  },
              eno_compliance_status: !!complianceStatus?.eno
                ? complianceStatus.eno
                : {
                    ...state.details.producers.licenseAndEnO
                      .eno_compliance_status,
                  },
            },
          },
        },
      };
    }

    case types.AGENT_DOCUMENT_FILE_UPLOAD_ERROR: {
      return {
        ...state,
        isAgentDocumentUploadErrorPresent: action.payload,
      };
    }
    case types.CLEAR_DATA: {
      return initState();
    }

    case types.UPDATE_SHOW_W9_DOWNLOAD_BLOCKED_MODAL: {
      return {
        ...state,
        showW9DownloadBlockedModal: action.payload,
      };
    }

    // On team member details save event
    case types.TEAM_MEMBER_LOADER: {
      return {
        ...state,
        teamMemberDetailsLoader: action.payload,
      };
    }

    case types.TEAM_MEMBER_DUPLICATE_EMAIL: {
      return {
        ...state,
        teamMemberDuplicateEmailList: action.payload,
      };
    }

    case types.DUPLICATE_PHONE_NUMBER: {
      return {
        ...state,
        duplicatePhoneNumber: action.payload,
      };
    }

    case types.SHOW_REAUTHENITACATE_MODAL: {
      return {
        ...state,
        isStepUpModalShown: true,
      };
    }

    default:
      return state;
  }
};
