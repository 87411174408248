import * as carrierCredentials from '../../constants/action-types';

// Fetch carrier credentials list
export const fetchCarrierCredentialsList = payload => ({
  type: carrierCredentials.FETCH_CARRIER_CREDENTIALS,
  id: payload,
});

// Fetch carrier credentials list loader
export const setCarrierCredentialsListLoader = payload => ({
  type: carrierCredentials.FETCH_CARRIER_CREDENTIALS_LOADER,
  payload,
});

// Set carrier credentials list
export const setCarrierCredentialsList = payload => ({
  type: carrierCredentials.SET_CARRIER_CREDENTIALS,
  payload,
});

// Fetch Carrier Credentials details
export const fetCarrierCredentialDetails = payload => ({
  type: carrierCredentials.FETCH_CARRIER_CREDENTIALS_DETAILS,
  data: payload,
});

// Fetch Carrier Credentials details loader
export const setCarrierCredentialDetailsLoader = payload => ({
  type: carrierCredentials.FETCH_CARRIER_CREDENTIALS_DETAILS_LOADER,
  payload,
});

// Fetch Carrier Credentials details
export const setCarrierCredentialDetails = payload => ({
  type: carrierCredentials.SET_CARRIER_CREDENTIALS_DETAILS,
  payload,
});

// Update Carrier Credentials Appointment Type
export const updateAppontmentType = payload => ({
  type: carrierCredentials.UPDATE_APPOINTMENT_TYPE,
  payload,
});

// Store Update Carrier Credentials Appointment Type
export const storeUpdatedAppointMentType = payload => ({
  type: carrierCredentials.STORE_UPDATED_APPOINTMENT_TYPE,
  payload,
});

// Clear Is Update Data Of Credentials Appointment Type
export const clearIsUpdatedAppointmentType = () => ({
  type: carrierCredentials.CLEAR_IS_UPDATED_APPOINTMENT_DATA,
});

// Update Carrier Producer Crediential
export const updateCarrierProducerCrediential = payload => ({
  type: carrierCredentials.UPDATE_CARRIER_PRODUCER_CREDIENTIAL,
  payload,
});

// Store Updated Carrier Producer Crediential
export const storeUpdatedCarrierProducerCreduential = payload => ({
  type: carrierCredentials.STORE_UPDATED_CARRIER_PRODUCER_CREDIENTIAL,
  payload,
});

// Clear Is Producer Credientials Updated
export const clearIsProducerCredentialsUpdated = () => ({
  type: carrierCredentials.CLEAR_IS_PRODUCER_CREDIENTIAL_UPDATED,
});

// Clear Carrier Producer Credientials
export const clearCarrierProducerCredentials = () => ({
  type: carrierCredentials.CLEAR_CARRIER_PRODUCER_CREDENTIALS,
});

// Delete Carrier Credientials state
export const deleteCarrierCredentialStateRow = payload => ({
  type: carrierCredentials.DELETE_CARRIER_CREDENTIAL_STATE,
  payload,
});

// Delete Carrier Credentials loaderr
export const deleteCarrierCredentialStateLoader = payload => ({
  type: carrierCredentials.DELETE_CARRIER_CREDENTIAL_STATE_LOADER,
  payload,
});
