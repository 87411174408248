// @ts-check

import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import Lock from '@material-ui/icons/Lock';
import LockOpen from '@material-ui/icons/LockOpen';
import Tooltip from '@material-ui/core/Tooltip';
import { connect } from 'react-redux';

import { useIsSSO } from './hooks';
import config from 'config';

const SSO_ERROR_MSG = 'non-TwinGate access will be restricted on November 13th, 2023';

function IsSSOHeaderButton({ isImpersonate }) {
  const { result, isLoading, error, textResult } = useIsSSO();

  if (isImpersonate) {
    return null;
  }

  const enforcementLevel = config.ADMIN_SSO_ENFORCEMENT_LEVEL ?? 0;

  // no enforcement - show nothing
  if (enforcementLevel === 0) {
    return null;
  }

  // enforcement level 1 - show only success icon
  if (enforcementLevel === 1) {
    return !isLoading && result ? (
      <Tooltip
        title={<div style={{ whiteSpace: 'pre-line' }}>{textResult}</div>}
      >
        <IconButton>
          <Lock htmlColor="green" />
        </IconButton>
      </Tooltip>
    ) : null;
  }

  const SuccessOrWarningIcon = (
    <Tooltip title={<div style={{ whiteSpace: 'pre-line' }}>{textResult}</div>}>
      <IconButton>
        {isLoading ? (
          <span
            className="spinner-border spinner-border-sm mr-1"
            role="status"
            aria-hidden="true"
          />
        ) : (
          <>
            {result && !error ? (
              <Lock htmlColor="green" />
            ) : (
              <LockOpen htmlColor="red" />
            )}
          </>
        )}
      </IconButton>
    </Tooltip>
  );

  // enforcement level 2 - show success and warning icons
  if (enforcementLevel >= 2) {
    return SuccessOrWarningIcon;
  }

  // enforcement level 3 - show success and warning icons and disclaimer label - !! currently disabled !!
  // eslint-disable-next-line no-constant-condition
  if (false) {
    return (
      <>
        {!result && !isLoading && (
          <span
            style={{
              color: 'red',
              position: 'relative',
              top: '2px',
              fontSize: '16px',
            }}
          >
            {SSO_ERROR_MSG}
          </span>
        )}
        {SuccessOrWarningIcon}
      </>
    );
  }

  return null;
}

const mapStateToProps = ({ impersonate }) => {
  const { isImpersonate } = impersonate;

  return {
    isImpersonate,
  };
};

export default connect(mapStateToProps, null)(IsSSOHeaderButton);
