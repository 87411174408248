import React, { Component } from 'react';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import FormHelperText from '@material-ui/core/FormHelperText';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { isEmpty } from 'lodash';
import {
  changePasswordCompleted,
  updatePassword,
} from 'redux/actions/update-password';
import SubmitLoader from 'components/submit-loader';
import { globalMessages } from 'util/extra';
import { password_regex } from 'util/sign-up-process';
import IntlMessages from 'util/intl-messages';
import {
  CONFIRM_PASSWORD,
  ENTER_KEY_CODE,
  NEW_PASSWORD,
  OLD_PASSWORD,
  PASSWORD_DO_NO_MATCHED,
  REQUIRED,
  SAME_PASSWORD,
  TYPE_PASSWORD,
} from 'constants/constant';
import { DialogActions, Divider } from '@material-ui/core';
import grey from '@material-ui/core/colors/grey';
import './update-password-dialog.scss';

const styles = () => ({
  root: {},
  userName: {
    color: '#fff',
    marginBottom: 0,
    cursor: 'pointer',
    '& span': {
      display: 'inline-block',
      maxWidth: '130px',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      paddingTop: '5px',
    },
    '& i': {
      paddingBottom: '5px',
    },
  },
  passwordDialogHeader: {
    margin: 0,
  },
  updatePasswordButton: {
    fontSize: '17px !important',
    fontWeight: '500 !important',
  },
  divider: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  roundedButton: {
    borderRadius: '8px',
  },
});

export class UpdatePassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
      openUpdatePasswordModal: false,
      openChangePasswordModal: false,
      oldPassword: '',
      newPassword: '',
      confirmPassword: '',
      inputError: {
        oldPasswordError: '',
        newPasswordError: '',
        confirmPasswordError: '',
      },
      openSuccessModal: false,
    };
  }

  componentDidMount = () => {
    const { updateDefaultPassword, openUpdatePasswordDialog } = this.props;
    if (updateDefaultPassword || openUpdatePasswordDialog) {
      this.setState({ openUpdatePasswordModal: true });
    }
  };

  UNSAFE_componentWillReceiveProps(newProps) {
    const { updatePasswordCompleted } = newProps;
    if (updatePasswordCompleted) {
      this.setState({
        oldPassword: '',
        newPassword: '',
        confirmPassword: '',
      });
      if (!this.state.openSuccessModal) {
        this.handleModalClose();
      }
    }
  }

  handleModalClose = () => {
    const {
      onCloseUpdatePasswordDialog,
      userSignOut,
      updatePasswordCompleted,
    } = this.props;
    this.setState({
      openChangePasswordModal: false,
      oldPassword: '',
      newPassword: '',
      confirmPassword: '',
      inputError: {
        oldPasswordError: '',
        newPasswordError: '',
        confirmPasswordError: '',
      },
      openUpdatePasswordModal: false,
    });
    !!onCloseUpdatePasswordDialog && onCloseUpdatePasswordDialog();
    updatePasswordCompleted && userSignOut();
  };

  onSubmitUpdatePassword = (e) => {
    const { newPassword, oldPassword } = this.state;
    if (this.validate()) {
      this.props.updatePassword({
        old_password: oldPassword,
        new_password: newPassword,
      });
      this.setState({
        openSuccessModal: true,
      });
    }
    e.preventDefault();
  };

  validateEmptyPassword = () => {
    const { newPassword, oldPassword, confirmPassword } = this.state;
    if (newPassword && oldPassword && confirmPassword) {
      return true;
    }
    return false;
  };

  validate = () => {
    const { newPassword, oldPassword, confirmPassword } = this.state;
    let inputError = {
      oldPasswordError: '',
      newPasswordError: '',
      confirmPasswordError: '',
    };

    if (!oldPassword.length > 0) {
      inputError.oldPasswordError = REQUIRED;
    }
    if (!newPassword.length > 0) {
      inputError.newPasswordError = REQUIRED;
    }
    if (newPassword.length > 0 && !password_regex.test(newPassword)) {
      inputError.newPasswordError = globalMessages.passwordMessage;
    }
    if (!confirmPassword.length > 0) {
      inputError.confirmPasswordError = REQUIRED;
    }
    if (
      newPassword !== confirmPassword &&
      confirmPassword.length > 0 &&
      newPassword.length > 0
    ) {
      inputError.confirmPasswordError = PASSWORD_DO_NO_MATCHED;
    }
    if (
      newPassword === oldPassword &&
      newPassword.length > 0 &&
      oldPassword.length > 0
    ) {
      inputError.newPasswordError = SAME_PASSWORD;
    }

    this.setState({
      inputError,
    });

    if (
      inputError.newPasswordError ||
      inputError.confirmPasswordError ||
      inputError.oldPasswordError
    ) {
      return false;
    }

    return true;
  };

  render() {
    const {
      confirmPassword,
      inputError,
      newPassword,
      oldPassword,
      openUpdatePasswordModal,
      openSuccessModal,
    } = this.state;
    const { updatingPassword, updatePasswordCompleted, updateDefaultPassword } =
      this.props;
    const isEnabled =
      !isEmpty(newPassword) &&
      !isEmpty(oldPassword) &&
      !isEmpty(confirmPassword);
    return (
      <div>
        <Dialog
          class="change-password-dialog-content"
          maxWidth={'xs'}
          fullWidth={true}
          onClose={this.handleModalClose}
          open={openUpdatePasswordModal && !updatePasswordCompleted}
          disableBackdropClick={updateDefaultPassword}
          onKeyUp={(e) => {
            // 13 is the key code for enter key
            if (e.keyCode === ENTER_KEY_CODE && updatePasswordCompleted) {
              this.handleModalClose();
            } else if (e.keyCode === ENTER_KEY_CODE) {
              this.onSubmitUpdatePassword(e);
            }
          }}
        >
          <DialogContent
            style={{
              padding: '2.5em',
            }}
          >
            <React.Fragment>
              <Typography
                gutterBottom
                className="d-flex"
                style={{
                  fontSize: '20px',
                  fontWeight: 'bold',
                  color: grey[700],
                  marginBottom: '20px',
                }}
              >
                <IntlMessages id="label.changePassword" />
              </Typography>
              <Typography
                style={{
                  fontSize: '12px',
                  color: grey[600],
                }}
              >
                <IntlMessages id="label.changePasswordValidation" />
              </Typography>
              <Grid
                style={{
                  marginTop: '2.5rem',
                  marginBottom: '1rem',
                }}
                container
                direction="column"
                justifyContent="space-around"
              >
                <Grid item xs={12} sm={12} className="pt-0">
                  <TextField
                    id={OLD_PASSWORD}
                    type={TYPE_PASSWORD}
                    name={OLD_PASSWORD}
                    label={
                      <>
                        <IntlMessages id="label.oldPassword" />
                        <span
                          style={{
                            marginLeft: '5px',
                            color: '#ff9a13',
                          }}
                        >
                          *
                        </span>
                      </>
                    }
                    value={oldPassword}
                    fullWidth
                    autoComplete="new-password"
                    inputProps={{
                      className: 'fs-exclude',
                    }}
                    onChange={(event) =>
                      this.setState({
                        oldPassword: event.target.value,
                      })
                    }
                    disabled={updatingPassword}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  <FormHelperText className="error-text">
                    {inputError.oldPasswordError}
                  </FormHelperText>
                </Grid>
                <Grid
                  style={{
                    marginTop: '15%',
                  }}
                  item
                  xs={12}
                  sm={12}
                  className="pt-0"
                >
                  <TextField
                    id={NEW_PASSWORD}
                    type={TYPE_PASSWORD}
                    name={NEW_PASSWORD}
                    label={
                      <>
                        <IntlMessages id="label.newPassword" />
                        <span
                          style={{
                            marginLeft: '5px',
                            color: '#ff9a13',
                          }}
                        >
                          *
                        </span>
                      </>
                    }
                    value={newPassword}
                    fullWidth
                    inputProps={{
                      className: 'fs-exclude',
                    }}
                    onChange={(event) =>
                      this.setState({
                        newPassword: event.target.value,
                      })
                    }
                    disabled={updatingPassword}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  <FormHelperText className="error-text">
                    {inputError.newPasswordError}
                  </FormHelperText>
                </Grid>
                <Grid
                  style={{
                    marginTop: '15%',
                  }}
                  item
                  xs={12}
                  sm={12}
                  className="pt-0"
                >
                  <TextField
                    id={CONFIRM_PASSWORD}
                    type={TYPE_PASSWORD}
                    name={CONFIRM_PASSWORD}
                    label={
                      <>
                        <IntlMessages id="label.confirmPassword" />
                        <span
                          style={{
                            marginLeft: '5px',
                            color: '#ff9a13',
                          }}
                        >
                          *
                        </span>
                      </>
                    }
                    value={confirmPassword}
                    fullWidth
                    inputProps={{
                      className: 'fs-exclude',
                    }}
                    onChange={(event) =>
                      this.setState({
                        confirmPassword: event.target.value,
                      })
                    }
                    disabled={updatingPassword}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  <FormHelperText className="error-text">
                    {inputError.confirmPasswordError}
                  </FormHelperText>
                </Grid>
              </Grid>
            </React.Fragment>
          </DialogContent>

          <Divider />

          <DialogActions className="p-0">
            {!updatePasswordCompleted ? (
              <Grid
                item
                xs={12}
                sm={12}
                className="col-lg-12 d-flex justify-content-center"
              >
                <Button
                  disabled={updatingPassword || !isEnabled}
                  className={`
                  button-password
                  ${!isEnabled ? 'btn-grey' : 'xcc'}`}
                  onClick={this.onSubmitUpdatePassword}
                >
                  <IntlMessages id="label.updatePassword" />
                  {updatingPassword && (
                    <span>
                      &nbsp; <SubmitLoader />
                    </span>
                  )}
                </Button>
              </Grid>
            ) : (
              <Grid
                item
                xs={12}
                sm={12}
                className="d-flex justify-content-center m-2"
              >
                <Button
                  color="primary"
                  className={`bg-primary`}
                  onClick={this.handleModalClose}
                  style={{
                    fontWeight: '400',
                  }}
                >
                  <IntlMessages id="label.close" />
                </Button>
              </Grid>
            )}
          </DialogActions>
        </Dialog>

        <Dialog
          className="update-pass-dialog"
          open={openSuccessModal && updatePasswordCompleted}
        >
          <DialogContent
            className="d-flex justify-content-center align-items-center"
            style={{
              width: '23rem',
              height: '7rem',
            }}
          >
            <Grid container center="true">
              <Grid item xs={12} sm={12} className="mt-2 font-weight-bold">
                <Typography className="message-label">
                  {globalMessages.changePasswordMessage}
                </Typography>
              </Grid>
            </Grid>
          </DialogContent>

          <DialogActions>
            <Grid
              item
              xs={12}
              sm={12}
              className="d-flex justify-content-end m-2"
            >
              <Button
                className="btn-done"
                onClick={() => {
                  this.setState(
                    {
                      openSuccessModal: false,
                    },
                    () => this.handleModalClose()
                  );
                }}
              >
                <IntlMessages id="label.done" />
              </Button>
            </Grid>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = ({ auth, passwordSettings }) => {
  const { updateDefaultPassword } = auth;
  const { passwordToken, updatingPassword, updatePasswordCompleted } =
    passwordSettings;
  return {
    passwordToken,
    updatingPassword,
    updatePasswordCompleted,
    updateDefaultPassword,
  };
};
export default withStyles(styles)(
  connect(mapStateToProps, {
    updatePassword,
    changePasswordCompleted,
  })(UpdatePassword)
);
