import { isEmpty } from 'lodash';
import * as types from '../../constants/action-types';

const initState = () => ({
  detailsLoader: true,
  carrierDetailsData: '',
  carrierMarkets: [],
  commissionsData: '',
  updateLoader: false,
  updatedRowResponse: null,
  updateCommissionPaidOnData: null,
  newCarrierProduct: '',
  updatedProductMarkets: null,
  deletedCommissionProductData: null,
  updateTable: false,
  updatedMarketProductData: null,
  isProductEnableResponse: null,
  isImageUploadError: false,
  isFetchCarrierError: false,
  isWebinarAdded: false,
  webinarDetails: [],
  isWebinarDetailsEdit: false,
});

const getUpdatedWebinarDetails = (payload, state) => {
  const { id } = payload;
  const { webinarDetails } = state;
  if (!isEmpty(webinarDetails)) {
    if (isEmpty(payload) || !id) {
      return [...webinarDetails];
    } else {
      return webinarDetails.map((webinarLink) => {
        if (webinarLink?.id === id) {
          return payload;
        } else {
          return webinarLink;
        }
      });
    }
  } else {
    return [];
  }
};

const getDeletedWebinarDetails = (payload, state) => {
  const { id } = payload;
  const { webinarDetails } = state;
  if (isEmpty(webinarDetails) && !!id) return [];
  return webinarDetails.filter((webinarLink) => webinarLink?.id !== id);
};

export default (state = initState(), action) => {
  switch (action.type) {
    case types.INIT_URL: {
      return { state, initURL: action.payload };
    }
    case types.SET_CARRIER_DETAILS: {
      return {
        ...state,
        detailsLoader: false,
        carrierDetailsData: action.payload.carrier,
        carrierMarkets: action.payload.carrier.market,
        commissionsData: action.payload.commissions,
        updatedRowResponse: null,
        isProductEnableResponse: null,
        deletedCommissionProductData: null,
      };
    }
    case types.SET_CARRIER_UPDATE_DETAILS: {
      return {
        ...state,
        detailsLoader: false,
        carrierDetailsData: action.payload.carrier,
        carrierMarkets: action.payload.carrierMarkets,
        commissionsData: action.payload.commissions,
        updatedRowResponse: null,
        isProductEnableResponse: null,
        deletedCommissionProductData: null,
      };
    }
    case types.CARRIER_DETAILS_LOADER: {
      return {
        ...state,
        detailsLoader: true,
      };
    }
    case types.CARRIER_UPDATE_LOADER: {
      return {
        ...state,
        updateLoader: action.payload,
      };
    }
    case types.SET_COMMISSION_TABLE_DATA: {
      return {
        ...state,
        carrierDetailsData: { ...state.carrierDetailsData },
        carrierMarkets: action.payload.market,
        commissionsData: action.payload.updatedCommissionData,
        updatedRowResponse: action.payload,
        deletedCommissionProductData: null,
        isProductEnableResponse: null,
        updatedMarketProductData: null,
      };
    }
    case types.SET_COMMISSION_PAID_ON: {
      return {
        ...state,
        carrierDetailsData: { ...state.carrierDetailsData },
        carrierMarkets: action.payload.markets,
        commissionsData: action.payload.updatedCommissionProduct,
        updateCommissionPaidOnData: {
          productID: action.payload.productID,
          commissionPaidValue: action.payload.commissionPaidOnValue,
          commissionTypeValue: action.payload.commissionType,
        },
        updatedProductMarkets: null,
        updatedRowResponse: null,
        isProductEnableResponse: null,
        deletedCommissionProductData: null,
        is_available_for_quoting: action.payload.is_available_for_quoting,
      };
    }
    case types.UPDATE_COMMISSION_TABLE_ON_STATE_UPDATE: {
      return {
        ...state,
        updateTable: action.payload,
        carrierDetailsData: { ...state.carrierDetailsData },
        carrierMarkets: [...state.carrierDetailsData.market],
        commissionsData: [...state.commissionsData],
        updatedProductMarkets: action.payload,
        updatedRowResponse: null,
        isProductEnableResponse: null,
        deletedCommissionProductData: null,
      };
    }
    case types.SET_CARRIER_PRODUCT_MARKET: {
      return {
        ...state,
        updateTable: false,
        carrierDetailsData: { ...state.carrierDetailsData },
        carrierMarkets: action.payload.carrierMarkets,
        commissionsData: action.payload.updatedCommissionData,
        updatedProductMarkets: action.payload,
        updatedRowResponse: null,
        updatedMarketProductData: action.payload,
        isProductEnableResponse: null,
        deletedCommissionProductData: null,
      };
    }
    case types.UPDATE_COMMISSION_TABLE_AFTER_DELETE: {
      return {
        ...state,
        carrierDetailsData: { ...state.carrierDetailsData },
        carrierMarkets: action.payload.markets,
        commissionsData: action.payload.updatedCommissionData,
        deletedCommissionProductData: action.payload.deleteCommissionData,
        updatedRowResponse: null,
        updatedMarketProductData: null,
        isProductEnableResponse: null,
      };
    }
    case types.SET_NEW_CARRIER_PRODUCT: {
      const { product, carrierMarkets } = action.payload;
      return {
        ...state,
        carrierDetailsData: { ...state.carrierDetailsData },
        carrierMarkets: carrierMarkets,
        commissionsData: [product, ...state.commissionsData],
        newCarrierProduct: product,
        updatedRowResponse: null,
        isProductEnableResponse: null,
        deletedCommissionProductData: null,
      };
    }
    case types.SET_NEW_CARRIER_PRODUCT_EMPTY: {
      return {
        ...state,
        carrierDetailsData: { ...state.carrierDetailsData },
        commissionsData: [...state.commissionsData],
        newCarrierProduct: action.payload.newCarrierProduct,
        updatedRowResponse: null,
        isProductEnableResponse: null,
        deletedCommissionProductData: null,
      };
    }
    case types.SET_CARRIER_PRODUCT_ACCESSIBILITY: {
      return {
        ...state,
        carrierDetailsData: { ...state.carrierDetailsData },
        carrierMarkets: action.payload.markets,
        commissionsData: action.payload.commissionData,
        newCarrierProduct: '',
        updatedRowResponse: null,
        isProductEnableResponse: action.payload,
        deletedCommissionProductData: null,
      };
    }

    case types.CLEAR_UPDATED_ROW_REPONSE: {
      return {
        ...state,
        updatedRowResponse: null,
      };
    }

    case types.SET_IMAGE_UPLOAD_ERROR: {
      return {
        ...state,
        isImageUploadError: action.payload,
      };
    }
    case types.CLEAR_CARRIER_RESPONSE: {
      return {
        ...state,
        carrierDetailsData: {
          ...state?.carrierDetailsData,
          responseMessage: null,
        },
      };
    }
    case types.SET_IS_FETCH_CARRIER_ERROR: {
      return { ...state, isFetchCarrierError: action.payload };
    }

    case types.SET_WEBINAR_LINK_DETAILS_IN_STORE: {
      return {
        ...state,
        webinarDetails: [...action.payload],
      };
    }

    case types.UPDATE_WEBINAR_LINK_DETAILS_IN_STORE: {
      return {
        ...state,
        webinarDetails: getUpdatedWebinarDetails(action.payload, state),
      };
    }

    case types.DELETE_WEBINAR_LINK_DETAILS_FROM_STORE: {
      return {
        ...state,
        webinarDetails: getDeletedWebinarDetails(action.payload, state),
      };
    }

    case types.SET_IS_WEBINAR_LINK_DETAILS_EDIT: {
      return {
        ...state,
        isWebinarDetailsEdit: action.payload,
      };
    }

    default:
      return state;
  }
};
