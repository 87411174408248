import { Typography } from '@material-ui/core';
import React from 'react';
import './mobile-to-desk.scss';
import {
  setCancleSwitchDeviceValue,
  setContinueOnComputer,
  setSwitchToComputer,
} from 'redux/actions/post-sign-up';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

const SwitchToComputer = ({ ...props }) => {
  const continueOnComputor = () => {
    const continueOnDevice = {
      onComputer: false,
      onPhone: false,
    };
    props?.setContinueOnComputer(continueOnDevice);
    props?.setSwitchToComputer(true);
    localStorage.setItem('showSignupMobileTransition', true);
  };

  return (
    <div className="switch-notification">
      <Typography className="orange-label" onClick={continueOnComputor}>
        Prefer uploading from your computer?
      </Typography>
    </div>
  );
};

const mapStateToProps = ({ postSignup }) => {
  const { continueOnDevice, cancleSwitchDeviceNotification, switchToComputer } =
    postSignup;
  return {
    continueOnDevice,
    cancleSwitchDeviceNotification,
    switchToComputer,
  };
};

SwitchToComputer.propTypes = {
  setContinueOnComputer: PropTypes.func.isRequired,
  setCancleSwitchDeviceValue: PropTypes.func.isRequired,
};
export default connect(mapStateToProps, {
  setContinueOnComputer,
  setCancleSwitchDeviceValue,
  setSwitchToComputer,
})(SwitchToComputer);
