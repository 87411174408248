import * as classCodes from '../../constants/action-types';

// selected agent details fetch

export const fetchClassCodes = (payload) => ({
  type: classCodes.FETCH_CLASS_CODES,
  payload,
});

export const saveClassCodes = (payload) => ({
  type: classCodes.SAVE_CLASS_CODES,
  payload,
});

export const setClassCodes = (payload) => ({
  type: classCodes.SET_CLASS_CODES,
  payload,
});

export const setClassCodesLoader = (payload) => ({
  type: classCodes.CLASS_CODES_LOADER,
  payload,
});

export const fetchClassCarriers = () => ({
  type: classCodes.FETCH_CLASS_CARRIERS,
});

export const updateClassCodesDetails = (payload) => ({
  type: classCodes.UPDATE_CLASS_CODE_DETAILS,
  payload,
});

export const resetClassCodeDetails = () => ({
  type: classCodes.CLEAR_CLASS_CODES_DETAILS,
});

export const setSelectedLobList = (payload) => {
  return {
    type: classCodes.SET_SELECTED_LOB_LIST,
    payload,
  };
};

export const fetchSelectedLobList = () => ({
  type: classCodes.FETCH_SELECTED_LOB_LIST,
});
