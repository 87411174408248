import * as types from '../../constants/action-types';

const initState = () => ({
  adminUserList: [],
  adminCategoryRoleList: null,
  adminUserListLoader: false,
  adminUserLoader: false,
  adminCategoryRoleListLoader: false,
});

export default (state = initState(), action) => {
  switch (action.type) {
    case types.INIT_URL: {
      return { ...state, initURL: action.payload };
    }

    case types.SET_ADMIN_USER_LIST: {
      return {
        ...state,
        adminUserList: [...action.payload],
      };
    }

    case types.SET_ADMIN_USER_LIST_LOADER: {
      return {
        ...state,
        adminUserListLoader: action.payload,
      };
    }

    case types.NEW_ADMIN_USER_LOADER: {
      return {
        ...state,
        adminUserLoader: action.payload,
      };
    }

    case types.SET_NEW_ADMIN_USER: {
      return {
        ...state,
        adminUserListLoader: false,
        adminUserList: [action.payload, ...state.adminUserList],
      };
    }

    case types.SET_UPDATED_ADMIN_USER: {
      return {
        ...state,
        adminUserListLoader: false,
        adminUserList: [...action.payload],
      };
    }

    case types.SET_ADMIN_CATEGORY_ROLE_LIST: {
      return {
        ...state,
        adminCategoryRoleList: action.payload,
      };
    }

    case types.ADMIN_CATEGORY_ROLE_LIST_LOADER: {
      return {
        ...state,
        adminCategoryRoleListLoader: action.payload,
      };
    }
    default:
      return state;
  }
};
