import * as getOrganizationDetails from '../../constants/action-types';

export const setSignupTransLoginSuccess = data => {
  return {
    type: getOrganizationDetails.SET_SIGNUP_TRANSITION_LOGIN_SUCCESS,
    payload: data,
  };
};

export const setSignupTransGetAgentSuccess = data => {
  return {
    type: getOrganizationDetails.SET_SIGNUP_TRANSITION_GET_AGENT_SUCCESS,
    payload: data,
  };
};
