import React, { Component } from 'react';
import { Card, CardActions, Grid, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import SubmitLoader from 'components/submit-loader';
import {
  AGREEMENT_DATA,
  BUTTON_TYPE,
  IP_ADDRESS,
  NEXT,
  POST_SIGNUP_STEPS_NAME,
  PRODUCER_KEY,
  SUBMIT,
} from 'constants/constant';
import { connect } from 'react-redux';
import { renewalNotificationFlagFun } from 'redux/actions/dashboard';
import { getDocumentPath } from 'redux/actions/document-download';
import {
  getAgent,
  setCurrentStepIndex,
  setDropboxLoader,
  stepSubmit,
  submitLoaderHide,
} from 'redux/actions/post-sign-up';
import { validators } from 'util/form-handler';
import IntlMessages from 'util/intl-messages';
import { getStartCase } from 'util/sign-up-process'; // To check string value
import dropboxSignClient, { TEMPLATE_NAME } from 'util/DropboxSign';
import AgentAgreementImage from 'assets/images/agent-agreement.svg';
import { APPLICATION } from 'constants/heap-events';

import './step-7.scss';
import { fetchPermissions } from 'redux/actions/auth';
import DancingDots from 'components/dancing-dots';

const initState = {
  formValues: { name: '', by: '', checkBoxChecked: false },
  formErrors: { name: '', by: '' },
};

const styles = () => ({
  errorMessage: {
    fontSize: '0.75rem',
    textAlign: 'left',
    fontFamily: ['Roboto', 'Helvetica', 'Arial', 'sans-serif'],
    fontWeight: '400',
    lineHeight: '1.66',
    letterSpacing: '0.03333em',
    color: 'red',
    marginLeft: '0px',
  },
  dateCursor: {
    Cursor: 'default',
  },
  mt20: {
    marginTop: '20px',
  },
  wrapper: {
    background: '#FFF5E9',
  },
  w100: { width: '100%' },
  loaderCentered: {
    position: 'relative',
  },
});

class Step7 extends Component {
  constructor(props) {
    super(props);
    this.state = initState;
    this.agentName = null;
  }

  componentDidMount = () => {
    const {
      producerDetails: { producer_firstname, producer_lastname },
      producer_id,
      submitLoader,
    } = this.props;
    const token = localStorage.getItem('Authorization');
    if (token) {
      this.props.fetchPermissions({ token });
    }
    this.props.getAgent({ producer_id });
    let name = '';
    if (producer_firstname && producer_lastname) {
      name = `${producer_firstname} ${producer_lastname}`;
    }
    this.setState({
      formValues: {
        ...this.state.formValues,
        ...this.props.agreementData,
        name,
        by: name,
      },
    });
    this.agentName =
      producer_firstname && producer_lastname
        ? `${producer_firstname} ${producer_lastname || ''}`
        : null;
    submitLoader && this.props.submitLoaderHide();
  };

  componentDidUpdate = (prevProps) => {
    const { producers, producersLoader } = this.props;
    if (prevProps.producersLoader !== producersLoader) {
      this.setState({ producers });
    }
  };

  onChangeName = ({ target: { value, name } }) => {
    const err = validators.required(value) || validators.nameInvalid(value);
    this.setState({
      formValues: {
        ...this.state.formValues,
        [name]: value,
      },
      formErrors: {
        ...this.state.formErrors,
        [name]: err,
      },
    });
  };

  onBlurName = ({ target: { value, name } }) => {
    this.setState({
      formValues: { ...this.state.formValues, [name]: getStartCase(value) },
    });
  };

  checkBoxChecked = () => {
    const { formValues } = this.state;
    this.setState({
      formValues: {
        ...formValues,
        conditionCheckBox: !formValues.conditionCheckBox,
      },
    });
  };

  callDropboxClient = async () => {
    const {
      producerDetails: { producer_firstname, producer_lastname },
      producers: {
        agencyProfile: { email, agencyDbaName, agencyLegalName },
      },
    } = this.props;
    const signer = `${producer_firstname} ${producer_lastname}`;
    const agencyName = agencyDbaName
      ? `${agencyLegalName} dba ${agencyDbaName}`
      : agencyLegalName;

    const response = await dropboxSignClient.getEmbeddedSignUrl(
      TEMPLATE_NAME.AGENT_AGREEMENT,
      { name: signer, email, agencyName }
    );
    this.props.setDropboxLoader(false);
    return response;
  };

  onSignClickHandler = async () => {
    this.props.setDropboxLoader(true);

    dropboxSignClient.open(await this.callDropboxClient(), () => {
      const { producerDetails, signupFlow } = this.props;

      const name = `${producerDetails.producer_firstname} ${producerDetails.producer_lastname}`;
      this.props.renewalNotificationFlagFun(false);
      window.heap?.track(APPLICATION.VIEW_SUCCESS_PAGE);
      this.props.stepSubmit({
        stepId: AGREEMENT_DATA,
        entity: AGREEMENT_DATA,
        final: !!signupFlow ? signupFlow !== PRODUCER_KEY : false,
        signUpComplete: !!signupFlow ? signupFlow !== PRODUCER_KEY : false,
        completedSignupStep: POST_SIGNUP_STEPS_NAME.AGENT_AGREEMENT,
        data: {
          version: producerDetails.id,
          name,
          by: name,
          ip: IP_ADDRESS,
        },
        signupFlow: !!signupFlow ? signupFlow : PRODUCER_KEY ,
      });
    });
  };

  setValue = (key, value, callback) => {
    this.setState(
      {
        fieldsModified: true,
        formValues: { ...this.state.formValues, [key]: value },
      },
      () => callback?.(key)
    );
  };

  render() {
    const { submitLoader, classes, classNames, currentStepIndex } = this.props;

    return (
      <Card className={`${classes.wrapper}`}>
        <Grid container className={`card-content page-agreement-wrapper`}>
          {this.props.producersLoader ? (
            <div className="loader-div">
              <DancingDots className={classes.loaderCentered} />
            </div>
          ) : (
            <>
              <img src={AgentAgreementImage} height="225" />
              <Typography className="heading">
                <IntlMessages id="signup.agentAgreement.heading" />
              </Typography>
              <Typography className="text">
                <IntlMessages id="signup.agentAgreement.text" />
              </Typography>
              <CardActions className="signup-btn-action-card w-100 d-flex justify-content-between">
                <button
                  type={BUTTON_TYPE}
                  className="white-back-button"
                  disabled={submitLoader}
                  onClick={() => {
                    this.props.setCurrentStepIndex(currentStepIndex - 1);
                  }}
                >
                  <IntlMessages id="appModule.back" />
                </button>
                <button
                  type={SUBMIT}
                  className={`${
                    submitLoader ? 'disable-button-grey' : 'orange-next-button'
                  }`}
                  onClick={this.onSignClickHandler}
                  id={NEXT}
                  disabled={submitLoader || this.props.isDropboxLoading}
                >
                  <IntlMessages id="signup.cta" />
                  {(submitLoader || this.props.isDropboxLoading) && (
                    <SubmitLoader className={classNames.submitLoader} />
                  )}
                </button>
              </CardActions>
            </>
          )}
        </Grid>
      </Card>
    );
  }
}

const mapStateToProps = ({
  postSignup: {
    submitLoader,
    tax,
    producers,
    producersLoader,
    currentStepIndex,
    isDropboxLoading,
  },
  auth: { signupFlow, agencyName, producer_id },
  dashboard: { producerDetails },
}) => {
  return {
    agencyName,
    currentStepIndex,
    producer_id,
    producerDetails,
    producers,
    producersLoader,
    signupFlow,
    submitLoader,
    tax,
    isDropboxLoading,
  };
};

export default connect(mapStateToProps, {
  stepSubmit,
  renewalNotificationFlagFun,
  getAgent,
  getDocumentPath,
  setCurrentStepIndex,
  submitLoaderHide,
  fetchPermissions,
  setDropboxLoader,
})(withStyles(styles)(Step7));
