import { Box, withStyles } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';

export const styles = ({
  stack: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    '& > * + *': {
      marginTop: 'var(--spacing)',
    }
  },
  background: {
    background: '#E6EAF5',
    width: '100%',
    height: '100%',
    padding: '16px'
  },
  wrapper: {
    background: '#F4F6FB',
    padding: '48px',
    margin: '0 auto',
    borderRadius: '8px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  title: {
    fontSize: '20px',
    fontWeight: '400',
    lineHeight: '140%',
    color: '#495165',
  },
  message: {
    fontSize: '16px',
    lineHeight: '140%',
    fontWeight: '300',
    color: '#747F9A'
  },
  button: {
    background: '#FF8125',
    padding: '8px 24px',
    color: 'white',
    borderRadius: '16px',
    fontSize: '12px',
    lineHeight: '140%',
    '&:hover': {
      background: '#FF8125'
    }
  }
});

export const Stack = withStyles(styles)(({ children, spacing = 4, classes, className, ...rest }) => {
  return (
    <Box className={clsx([classes.stack, className])} style={{'--spacing': `${spacing * 4}px`}} {...rest}>
      {children}
    </Box>
  );
});
