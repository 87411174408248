import * as commissionActions from '../../constants/action-types';
// all monthly commission statements fetch
export const fetchCommissionStatements = () => ({
  type: commissionActions.FETCH_COMMISSION_STATEMENTS,
});

export const setCommissionDetails = (details) => ({
  type: commissionActions.SET_COMMISSION_DETAILS,
  payload: details,
});

export const setCommissionLoader = (flag) => ({
  type: commissionActions.SET_COMMISSION_LOADER,
  payload: flag,
});

// Monthly commission stmt. view details.
export const fetchStatementData = (payload) => ({
  type: commissionActions.FETCH_STATEMENT_DATA,
  payload,
});

// add Montly commission with '+' sign
export const addCommissionMonth = () => ({
  type: commissionActions.ADD_COMMISSION_MONTH,
});

// update stepper month heading details and month payout id
export const updateMonthDetails = (payload) => ({
  type: commissionActions.UPDATE_MONTH_DETAILS,
  payload,
});

export const setStatementData = (details) => ({
  type: commissionActions.SET_STATEMENT_DATA,
  payload: details,
});

export const commissionFilesUpload = (
  files,
  index,
  carrier_id,
  monthly_commission_id,
  component_name,
  adhocPayments_id
) => ({
  type: commissionActions.COMMISSION_FILES_UPLOAD,
  payload: {
    index,
    files,
    carrier_id,
    monthly_commission_id,
    component_name,
    adhocPayments_id,
  },
});

export const commissionFilesUploading = () => {
  return {
    type: commissionActions.COMMISSION_FILES_UPLOADING,
  };
};

export const commissionFilesUploadSuccess = (fileurl, index) => {
  return {
    type: commissionActions.COMMISSION_FILES_UPLOAD_SUCCESS,
    payload: {
      index,
      fileurl,
    },
  };
};

export const carrierCommissionUpdate = (uploadResponse, index) => {
  return {
    type: commissionActions.CARRIER_COMMISSION_UPDATE,
    payload: {
      uploadResponse,
      index,
    },
  };
};

export const commissionFilesUploadFailed = () => {
  return {
    type: commissionActions.COMMISSION_FILES_UPLOAD_FAILED,
  };
};
export const commissionFilesUploadSuccessFlag = () => {
  return {
    type: commissionActions.COMMISSION_FILES_UPLOAD_SUCCESS_FLAG,
  };
};

// delete carrier commission record for uploading new file.
export const deleteCarrierCommissionRecord = (payload) => ({
  type: commissionActions.DELETE_CARRIER_COMMISSION_RECORD,
  payload,
});

// update store details after commission record has been deleted in server.
export const storeDeletedCarrierCommissionRecord = (payload) => ({
  type: commissionActions.STORE_DELETED_COMMISSION_RECORD,
  payload,
});

export const fetchFinalDocuments = () => ({
  type: commissionActions.FETCH_CARRIER_DOCUMENTS,
});

export const setReviewDocuments = (payload) => ({
  type: commissionActions.SET_REIVEW_DOCUMENTS,
  payload,
});

// set loader for review document on continue pending carrier list modal.
export const setReviewDocumentLoader = (payload) => ({
  type: commissionActions.REVIEW_DOCUMENT_LOADER,
  payload,
});

// on 'continue' of publish modal.
export const finalPublish = () => ({
  type: commissionActions.FINAL_PUBLISH,
});

export const setPublishLoader = (payload) => ({
  type: commissionActions.PUBLISH_LOADER,
  payload,
});

// Month Already Processed Publish
export const monthAlreadyProcessedCall = (payload) => ({
  type: commissionActions.MONTH_ALREADY_PROCEED,
  payload,
});

export const anomalyCheck = () => ({
  type: commissionActions.ANOMALY_CHECK,
});

export const setAnomalyCheckLoader = (payload) => ({
  type: commissionActions.ANOMALY_CHECK_LOADER,
  payload,
});

export const setAnomaliesDetected = (payload) => ({
  type: commissionActions.SET_ANOMALIES_DETECTED,
  payload,
});

export const setAnomaliesData = (payload) => ({
  type: commissionActions.SET_ANOMALIES_DATA,
  payload,
});
