import { displayErrorMessage, displaySuccessMessage } from 'util/user-feedback';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { isArray } from 'lodash';
import axios, { download } from 'util/api';
import { errorHandler } from '../actions/error';
import {
  carrierLobMarketReportLoader,
  motionAutoListLoader,
  rewardsProgramReportLoader,
  setAgentOnboardReport,
  setAgentSyncReport,
  setAppointmentFailureReport,
  setCommissionReport,
  setFastStartReport,
  setIncentiveReport,
  setMonthlyCommissionDate,
  setMonthlyCommissionLoader,
  setMonthlyCommissionReport,
  setMotionAutoReport,
  setQuickbooksreport,
} from '../actions/reports';
import * as reportsActions from 'constants/action-types';
import config from 'config';
import { getImpersonateBFFRequestConfig } from './impersonate';

function* fetchAgentOnboardData({ payload }) {
  try {
    const agentOnboardReport = yield axios.post(
      `/api/v1/producer/agent-reports`,
      payload
    );
    if (!!agentOnboardReport && agentOnboardReport.data) {
      const { agentReport, success, message } = agentOnboardReport.data;
      const reportURL = agentReport;
      if (success) {
        const data = {
          file_url: reportURL,
          method_type: 'download',
        };
        const details = yield axios.post('/api/v1/vendor/aws-config', data);
        if (details.data) {
          const { returnData } = details.data.data;
          const fileUrl = returnData.signedRequest;
          const signedURL = yield call(fetch, fileUrl);
          const responseBody = yield signedURL.blob();
          const url = window.URL.createObjectURL(responseBody);
          yield put(
            setAgentOnboardReport({
              message: null,
              returnData,
              responseBody,
              success,
              url,
            })
          );
        } else {
          yield put(
            setAgentOnboardReport({ message: details.data, success: false })
          );
        }
      } else {
        yield put(setAgentOnboardReport({ message, success: false }));
      }
    } else {
      yield put(
        setAgentOnboardReport({
          message: agentOnboardReport.data.message,
          success: false,
        })
      );
    }
  } catch (error) {
    if (error.response?.status === 401) {
      yield displayErrorMessage(error.response.data.message);
      yield put(errorHandler(error));
    } else {
      yield put(
        setAgentOnboardReport({ message: error.message, success: false })
      );
    }
  }
}

export function* fetchAgentOnboardReport() {
  yield takeEvery(
    reportsActions.FETCH_AGENT_ONBOARD_REPORT,
    fetchAgentOnboardData
  );
}

function* fetchCommissionReport({ payload }) {
  try {
    const commissionReport = yield axios.post(
      `/api/v1/commission/custom-agent-commission-statement`,
      payload
    );

    if (commissionReport.data) {
      if (commissionReport.data.success) {
        const { commissionReports, success } = commissionReport.data;
        yield put(
          setCommissionReport({
            message: null,
            commissionReports,
            success,
          })
        );
        yield displaySuccessMessage(commissionReport.data.message);
      } else {
        yield put(
          setCommissionReport({
            message: commissionReport.data.message,
            success: false,
          })
        );
      }
    }
  } catch (error) {
    if (error.response?.status === 401) {
      yield displayErrorMessage(error.response.data.message);
      yield put(errorHandler(error));
    } else {
      yield put(
        setCommissionReport({ message: error.message, success: false })
      );
    }
  }
}

export function* watcherFetchCommissionReport() {
  yield takeEvery(
    reportsActions.FETCH_COMMISSION_REPORT,
    fetchCommissionReport
  );
}

function* fetchAgentSyncReports({ payload }) {
  try {
    const agentSyncReport = yield axios.post(
      `/api/v1/producer/agent-sync-reports`,
      payload
    );
    if (!!agentSyncReport && agentSyncReport.data) {
      const { agentReport, success, message } = agentSyncReport.data;
      const agentReportUrl = agentReport;
      if (success) {
        const data = {
          file_url: agentReportUrl,
          method_type: 'download',
        };
        const details = yield axios.post('/api/v1/vendor/aws-config', data);
        if (details.data) {
          const { returnData } = details.data.data;
          const fileUrl = returnData.signedRequest;
          const signedURL = yield call(fetch, fileUrl);
          const responseBody = yield signedURL.blob();
          const url = window.URL.createObjectURL(responseBody);
          yield put(
            setAgentSyncReport({
              message: null,
              returnData,
              responseBody,
              success,
              url,
            })
          );
        } else {
          yield put(
            setAgentSyncReport({ message: details.data, success: false })
          );
        }
      } else {
        yield put(setAgentSyncReport({ message, success: false }));
      }
    } else {
      yield put(
        setAgentSyncReport({
          message: agentSyncReport.data.message,
          success: false,
        })
      );
    }
  } catch (error) {
    if (error.response?.status === 401) {
      yield displayErrorMessage(error.response.data.message);
      yield put(errorHandler(error));
    } else {
      yield put(setAgentSyncReport({ message: error.message, success: false }));
    }
  }
}

export function* fetchAgentSyncReportsWatcher() {
  yield takeEvery(
    reportsActions.FETCH_AGENT_SYNC_REPORT,
    fetchAgentSyncReports
  );
}

function* fetchMonthlyCommissionDate() {
  try {
    yield put(setMonthlyCommissionLoader(true));
    const commissionMonth = yield axios.get(
      '/api/v1/commission/get-latest-commission-date'
    );
    if (commissionMonth.data) {
      const { latestCommissionMonth } = commissionMonth.data;

      yield put(setMonthlyCommissionDate(latestCommissionMonth));
    }
  } catch (error) {
    if (!!error.response && error.response.status === 401) {
      yield displayErrorMessage(error.response.data.message);
    } else {
      yield put(errorHandler(error));
    }
  }
  yield put(setMonthlyCommissionLoader(false));
}

export function* fetchMonthlyCommissionDateWatcher() {
  yield takeEvery(
    reportsActions.FETCH_MONTHLY_COMMISSION_DATE,
    fetchMonthlyCommissionDate
  );
}

function* fetchMonthlyCommissionReports({ payload }) {
  try {
    yield put(setMonthlyCommissionLoader(true));
    const monthlyCommissionReport = yield axios.post(
      `/api/v1/script/monthly-commission-report`,
      payload
    );
    if (!!monthlyCommissionReport && monthlyCommissionReport.data) {
      const { agentReport, success, message } = monthlyCommissionReport.data;
      const agentReportUrl = agentReport;
      if (success) {
        const data = {
          file_url: agentReportUrl,
          method_type: 'download',
        };
        const details = yield axios.post('/api/v1/vendor/aws-config', data);
        if (details.data) {
          const { returnData } = details.data.data;
          const fileUrl = returnData.signedRequest;
          const signedURL = yield call(fetch, fileUrl);
          const responseBody = yield signedURL.blob();
          const url = window.URL.createObjectURL(responseBody);
          yield put(
            setMonthlyCommissionReport({
              message: null,
              returnData,
              responseBody,
              success,
              url,
            })
          );
        } else {
          yield put(
            setMonthlyCommissionReport({
              message: details.data,
              success: false,
            })
          );
        }
      } else {
        yield put(setMonthlyCommissionReport({ message, success: false }));
      }
    } else {
      yield put(
        setMonthlyCommissionReport({
          message: monthlyCommissionReport.data.message,
          success: false,
        })
      );
    }
  } catch (error) {
    if (error.response?.status === 401) {
      yield displayErrorMessage(error.response.data.message);
      yield put(errorHandler(error));
    } else {
      yield put(
        setMonthlyCommissionReport({ message: error.message, success: false })
      );
    }
  }
  yield put(setMonthlyCommissionLoader(false));
}

export function* fetchMonthlyCommissionReportsWatcher() {
  yield takeEvery(
    reportsActions.FETCH_MONTHLY_COMMISSION_REPORT,
    fetchMonthlyCommissionReports
  );
}

function* fetchFastStartReports({ payload }) {
  try {
    const fastStartReport = yield axios.post(
      `/api/v3/fast-start/report`,
      payload
    );
    if (!!fastStartReport && fastStartReport.data) {
      const { success, message } = fastStartReport.data;
      const agentReportUrl = fastStartReport?.data?.fastStartReport;
      if (success) {
        const data = {
          file_url: agentReportUrl,
          method_type: 'download',
        };
        const details = yield axios.post('/api/v1/vendor/aws-config', data);
        if (details.data) {
          const { returnData } = details.data.data;
          const fileUrl = returnData.signedRequest;
          const signedURL = yield call(fetch, fileUrl);
          const responseBody = yield signedURL.blob();
          const url = window.URL.createObjectURL(responseBody);
          yield put(
            setFastStartReport({
              message: null,
              returnData,
              responseBody,
              success,
              url,
            })
          );
        } else {
          yield put(
            setFastStartReport({ message: details.data, success: false })
          );
        }
      } else {
        yield put(setFastStartReport({ message, success: false }));
      }
    } else {
      yield put(
        setFastStartReport({
          message: fastStartReport.data.message,
          success: false,
        })
      );
    }
  } catch (error) {
    if (error.response?.status === 401) {
      yield displayErrorMessage(error.response.data.message);
      yield put(errorHandler(error));
    } else {
      yield put(setFastStartReport({ message: error.message, success: false }));
    }
  }
}

export function* fetchFastStartReportsWatcher() {
  yield takeEvery(
    reportsActions.FETCH_FAST_START_REPORT,
    fetchFastStartReports
  );
}

function* fetchAppointmentFailures({ payload }) {
  try {
    const AppointmentFailure = yield axios.post(
      `/api/v1/script/carrier-appointment-request-failure-report`,
      payload
    );
    if (!!AppointmentFailure && AppointmentFailure.data) {
      const { agentReport, success, message } = AppointmentFailure.data;
      const agentReportUrl = agentReport;
      if (success) {
        const data = {
          file_url: agentReportUrl,
          method_type: 'download',
        };
        const details = yield axios.post('/api/v1/vendor/aws-config', data);
        if (details.data) {
          const { returnData } = details.data.data;
          const fileUrl = returnData.signedRequest;
          const signedURL = yield call(fetch, fileUrl);
          const responseBody = yield signedURL.blob();
          const url = window.URL.createObjectURL(responseBody);
          yield put(
            setAppointmentFailureReport({
              message: null,
              returnData,
              responseBody,
              success,
              url,
            })
          );
        } else {
          yield put(
            setAppointmentFailureReport({
              message: details.data,
              success: false,
            })
          );
        }
      } else {
        yield put(setAppointmentFailureReport({ message, success: false }));
      }
    } else {
      yield put(
        setAppointmentFailureReport({
          message: AppointmentFailure.data.message,
          success: false,
        })
      );
    }
  } catch (error) {
    if (error.response?.status === 401) {
      yield displayErrorMessage(error.response.data.message);
      yield put(errorHandler(error));
    } else {
      yield put(
        setAppointmentFailureReport({ message: error.message, success: false })
      );
    }
  }
}

export function* fetchAppointmentFailuresWatcher() {
  yield takeEvery(
    reportsActions.FETCH_APPOINTMENT_FAILURE_REPORT,
    fetchAppointmentFailures
  );
}

function* fetchQuickbooksExtractReport({ payload }) {
  try {
    const quickbookExtractReport = yield axios.post(
      `/api/v1/script/quickbooks-extract`,
      payload
    );
    if (!!quickbookExtractReport && quickbookExtractReport.data) {
      const { data, success, message } = quickbookExtractReport.data;
      const { url } = data;
      if (success) {
        const fileData = {
          file_url: url,
          method_type: 'download',
        };
        const details = yield axios.post('/api/v1/vendor/aws-config', fileData);
        if (details.data) {
          const { returnData } = details.data.data;
          const fileUrl = returnData.signedRequest;
          const signedURL = yield call(fetch, fileUrl);
          const responseBody = yield signedURL.blob();
          const getUrl = window.URL.createObjectURL(responseBody);
          yield put(
            setQuickbooksreport({
              message: null,
              returnData,
              responseBody,
              success,
              url: getUrl,
            })
          );
        } else {
          yield put(
            setQuickbooksreport({ message: details.data, success: false })
          );
        }
      } else yield put(setQuickbooksreport({ message, success: false }));
    } else {
      yield put(
        setQuickbooksreport({
          message: quickbookExtractReport.data.message,
          success: false,
        })
      );
    }
  } catch (error) {
    if (error.response?.status === 401) {
      yield displayErrorMessage(error.response.data.message);
      yield put(errorHandler(error));
    } else {
      yield put(
        setQuickbooksreport({ message: error.message, success: false })
      );
    }
  }
}

export function* fetchQuickbooksExtractReportWatcher() {
  yield takeEvery(
    reportsActions.FETCH_QUICKBOOKS_EXTRACT_REPORT,
    fetchQuickbooksExtractReport
  );
}

export function* fetchMotionAutoReport({ payload }) {
  try {
    yield put(motionAutoListLoader(true));
    const responseData = yield axios.post('/api/v1/vendor/get-carrier-files', {
      folder_name: payload.toString(),
    });
    if (!!responseData?.data) {
      const { data } = responseData;
      let updatedPayload = Object.assign({}, data, {
        directoryName: isArray(payload) ? payload[0] : payload,
      });
      yield put(setMotionAutoReport(updatedPayload));
    }
  } catch (error) {
    if (error.response?.status === 401) {
      yield displayErrorMessage(error.response.data.message);
      yield put(errorHandler(error));
    } else {
      yield put(
        setCommissionReport({ message: error.message, success: false })
      );
    }
  }
  yield put(motionAutoListLoader(false));
}

export function* fetchMotionAutoReportWatcher() {
  yield takeEvery(reportsActions.FETCH_MOTION_AUTO_LIST, fetchMotionAutoReport);
}

function* fetchIncentiveReports({ payload }) {
  try {
    const incentiveReport = yield axios.post(
      `/api/v3/incentive/report`,
      payload
    );
    if (!!incentiveReport && incentiveReport.data) {
      const { success, message } = incentiveReport.data;
      const agentReportUrl = incentiveReport?.data?.incentiveReport;
      if (success) {
        const data = {
          file_url: agentReportUrl,
          method_type: 'download',
        };
        const details = yield axios.post('/api/v1/vendor/aws-config', data);
        if (details.data) {
          const { returnData } = details.data.data;
          const fileUrl = returnData.signedRequest;
          const signedURL = yield call(fetch, fileUrl);
          const responseBody = yield signedURL.blob();
          const url = window.URL.createObjectURL(responseBody);
          yield put(
            setIncentiveReport({
              message: null,
              returnData,
              responseBody,
              success,
              url,
            })
          );
        } else {
          yield put(
            setIncentiveReport({ message: details.data, success: false })
          );
        }
      } else {
        yield put(setIncentiveReport({ message, success: false }));
      }
    } else {
      yield put(
        setIncentiveReport({
          message: incentiveReport.data.message,
          success: false,
        })
      );
    }
  } catch (error) {
    if (error.response?.status === 401) {
      yield displayErrorMessage(error.response.data.message);
      yield put(errorHandler(error));
    } else {
      yield put(setIncentiveReport({ message: error.message, success: false }));
    }
  }
}

export function* fetchIncentiveReportsWatcher() {
  yield takeEvery(reportsActions.FETCH_INCENTIVE_REPORT, fetchIncentiveReports);
}

function* fetchCarrierLobMarketReport() {
  let details = null;
  try {
    yield put(carrierLobMarketReportLoader(true));
    const carrierLobMarketReport = yield axios.get(
      `/api/v3/reports/carrier-lob-markets`
    );
    if (!!carrierLobMarketReport && carrierLobMarketReport?.data) {
      const data = {
        file_url: carrierLobMarketReport?.data?.url,
        method_type: 'download',
      };
      details = yield axios.post('/api/v1/vendor/aws-config', data);
      if (details.data) {
        const { returnData } = details.data.data;
        yield download(returnData);
      }
    }
  } catch (error) {
    yield put(errorHandler(error));
  }
  yield put(carrierLobMarketReportLoader(false));
}

export function* fetchCarrierLobMarketReportWatcher() {
  yield takeEvery(
    reportsActions.FETCH_CARRIER_LOB_MARKET_REPORT,
    fetchCarrierLobMarketReport
  );
}

function* fetchRewardsProgramReport() {
  let year = 2024;
  try {
    const requestConfig = yield getImpersonateBFFRequestConfig();
    yield put(rewardsProgramReportLoader(true));
    const rewardsProgramReport = yield axios.post(
      `${config.BFF_URL}/api/v1/rewards-program/leaderboard/${year}/admin-report`,
      requestConfig,
    );
    if (!!rewardsProgramReport && rewardsProgramReport?.data) {
      const fileUrl = rewardsProgramReport?.data?.reportUrl;
      const signedURL = yield call(fetch, fileUrl);
      const responseBody = yield signedURL.blob();

      const url = window.URL.createObjectURL(responseBody);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'rewards_program_report.xlsx'; // specified name for the downloaded report
      a.click();
      a.remove();
    }
  } catch (error) {
    yield put(errorHandler(error));
  }
  yield put(rewardsProgramReportLoader(false));
}

export function* fetchRewardsProgramReportWatcher() {
  yield takeEvery(
    reportsActions.FETCH_REWARDS_PROGRAM_REPORT,
    fetchRewardsProgramReport
  );
}

// ------ ROOT SAGA -----------------
export default function* rootSaga() {
  yield all([
    fork(fetchAgentSyncReportsWatcher),
    fork(fetchAgentOnboardReport),
    fork(fetchQuickbooksExtractReportWatcher),
    fork(watcherFetchCommissionReport),
    fork(fetchAppointmentFailuresWatcher),
    fork(fetchFastStartReportsWatcher),
    fork(fetchMonthlyCommissionReportsWatcher),
    fork(fetchMonthlyCommissionDateWatcher),
    fork(fetchMotionAutoReportWatcher),
    fork(fetchIncentiveReportsWatcher),
    fork(fetchCarrierLobMarketReportWatcher),
    fork(fetchRewardsProgramReportWatcher),
  ]);
}
