import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import CheckIcon from '@material-ui/icons/Check';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import StepConnector from '@material-ui/core/StepConnector';
import './progressbar.scss';
import { Typography } from '@material-ui/core';
import { connect } from 'react-redux';
import { setApplicationModal } from '../../redux/actions/auth';

const useQontoStepIconStyles = makeStyles({
  root: {
    color: '#EFEFED',
    display: 'flex',
    height: 22,
    alignItems: 'center',
  },
  active: {
    color: '#784af4',
  },
  circle: {
    width: 8,
    height: 8,
    borderRadius: '50%',
    backgroundColor: 'currentColor',
  },
  completed: {
    color: '#784af4',
    zIndex: 1,
    fontSize: 18,
  },
});

function QontoStepIcon(props) {
  const classes = useQontoStepIconStyles();
  const { active, completed } = props;

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
      })}
    >
      {completed ? (
        <CheckIcon className={classes.completed} />
      ) : (
        <div className={classes.circle} />
      )}
    </div>
  );
}

QontoStepIcon.propTypes = {
  /**
   * Whether this step is active.
   */
  active: PropTypes.bool,
  /**
   * Mark the step as completed. Is passed to child components.
   */
  completed: PropTypes.bool,
};

const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 14,
  },
  active: {
    '& $line': {
      backgroundColor: '#FF9A14',
    },
  },
  completed: {
    '& $line': {
      backgroundColor: '#FF9A14',
    },
  },
  line: {
    height: 8,
    border: 0,
    backgroundColor: '#eaeaf0',
    borderRadius: 1,
  },
})(StepConnector);

const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: '#fff',
    zIndex: 1,
    color: '#A5A4A1',
    width: 20,
    height: 20,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '4px',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  },
  active: {
    width: 30,
    height: 30,
    color: '#fff',
    marginTop: '0px',
    backgroundColor: '#FF9A14',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  },
  completed: {
    width: 30,
    height: 30,
    color: '#fff',
    marginTop: '0px',
    backgroundColor: '#FF9A14',
  },
});

function ColorlibStepIcon(props) {
  const classes = useColorlibStepIconStyles();
  const { active, completed } = props;

  const icons = {
    1: <CheckIcon />,
    2: 1,
    3: 2,
    4: 3,
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {icons[String(props.icon)]}
    </div>
  );
}

ColorlibStepIcon.propTypes = {
  /**
   * Whether this step is active.
   */
  active: PropTypes.bool,
  /**
   * Mark the step as completed. Is passed to child components.
   */
  completed: PropTypes.bool,
  /**
   * The label displayed in the step icon.
   */
  icon: PropTypes.node,
};

function getSteps() {
  return ['', '', '', ''];
}

function CustomizedSteppers(props) {
  const [activeStep, setActiveStep] = React.useState(
    props.carrierAppointmentRequestCount
  );
  const steps = getSteps();
  const openApplicationModal = () => {
    props.setApplicationModal(true);
  };

  useEffect(() => {
    setActiveStep(props.carrierAppointmentRequestCount);
  }, [props.carrierAppointmentRequestCount, props.enableMenuItem]);

  return (
    <div className="progress-bar-inerwrap">
      <Typography className={'progress-label'}>
        Your Progress
        <InfoOutlinedIcon
          className={'info-icon'}
          onClick={openApplicationModal}
        />
      </Typography>
      <Stepper
        alternativeLabel
        activeStep={activeStep}
        connector={<ColorlibConnector />}
      >
        {steps.map(label => (
          <Step key={label}>
            <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </div>
  );
}

const mapStateToProps = ({ auth }) => {
  const { carrierAppointmentRequestCount, enableMenuItem } = auth;
  return {
    carrierAppointmentRequestCount,
    enableMenuItem,
  };
};

CustomizedSteppers.propTypes = {
  carrierAppointmentRequestCount: PropTypes.string,
  enableMenuItem: PropTypes.bool,
};

CustomizedSteppers.defaultProps = {
  carrierAppointmentRequestCount: null,
};

export default connect(mapStateToProps, { setApplicationModal })(
  CustomizedSteppers
);
