import React, { useEffect, useState } from 'react';
import { Button, IconButton, Typography } from '@material-ui/core';
import './mobile-to-desk.scss';
import FormControl from '@mui/material/FormControl';
import Input from '@mui/material/Input';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import mobileLoader from '../../assets/images/Mobile_Desktop.gif';
import { setContinueOnComputer } from 'redux/actions/post-sign-up';
import {
  setCancleSwitchDeviceValue,
  setCurrentStepIndex,
  setDeviceTransitionEmail,
  setDeviceTransitionViewed,
  setSwitchToComputer,
} from 'redux/actions';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { BUTTON_TYPE } from 'constants/constant';
import { isEmpty } from 'lodash';
import { validators } from 'util/form-handler';
import { APPLICATION } from 'constants/heap-events';

/**
 * @param {string | undefined} fallbackEmail
 * @returns {string | null} email to which the device transition email should be sent
 */
const getDeviceTransitionEmail = (fallbackEmail) => {
  const lsDeviceTransitionEmail = localStorage.getItem('deviceTransitionEmail');
  if (lsDeviceTransitionEmail) {
    return lsDeviceTransitionEmail;
  }
  return fallbackEmail ?? null;
};

const MobileTransition = ({ ...props }) => {
  const deviceTransitionEmail = getDeviceTransitionEmail(props?.email);
  const [editButton, setEditButton] = useState(true);
  const [email, setEmail] = useState(deviceTransitionEmail);

  useEffect(() => {
    window?.heap?.track(APPLICATION.VIEW_SWITCH_TO_DESKTOP);
  }, []);

  const [emailError, setEmailError] = useState('');
  const continueOnComputor = () => {
    if (isEmpty(emailError)) {
      const payload = {
        deviceTransitionEmail: email,
        page: props?.page,
      };
      props.setDeviceTransitionEmail(payload);
      const continueOnDevice = {
        onComputer: true,
        onPhone: false,
      };
      props.setContinueOnComputer(continueOnDevice);
      props.setDeviceTransitionViewed();
    }
  };

  const continueOnPhone = () => {
    const continueOnDevice = {
      onComputer: false,
      onPhone: true,
    };
    props.setContinueOnComputer(continueOnDevice);
    props.setDeviceTransitionViewed();
    props?.setCancleSwitchDeviceValue(false);
    localStorage.setItem('showSignupMobileTransition', false);
  };
  useEffect(() => {
    props?.setCancleSwitchDeviceValue(true);
    setEmailError(validators.required(email) || validators.emailInvalid(email));
  }, [email, emailError]);

  return (
    <div className="mobile-to-desk-wrap row">
      <div className="col-sm-12">
        {!!props?.continueOnDevice &&
        props?.continueOnDevice?.onComputer &&
        !props.deviceTransitionLoader ? (
          <Typography className="first-heading">
            Let’s move to your computer!
          </Typography>
        ) : (
          <Typography className="first-heading">
            You’re almost there!
          </Typography>
        )}
      </div>
      <div className="col-sm-12">
        {!!props?.continueOnDevice &&
        props?.continueOnDevice?.onComputer &&
        !props.deviceTransitionLoader ? (
          <Typography className="m-para">
            We’ve just <strong>emailed you a link</strong> to continue from this
            step on your computer. See you there!
          </Typography>
        ) : (
          <Typography className="m-para">
            The following step requires attaching a document, we recommend
            continuing on your computer. We’re going to send you a{' '}
            <strong>link</strong> to your mailbox.
          </Typography>
        )}
      </div>
      {!!props?.continueOnDevice &&
      props?.continueOnDevice?.onComputer &&
      !props.deviceTransitionLoader ? (
        <div className="d-flex justify-content-center">
          <img src={mobileLoader} alt="loader" className="mobile-loader" />
        </div>
      ) : (
        <div className="col-sm-12">
          <Typography className="label-email">
            Make sure your email is correct
          </Typography>
          <FormControl variant="standard" fullWidth>
            {editButton ? (
              <div className="fs-exclude text-field-label d-flex">
                <div className="w-100 text-elipsis">{email}</div>
                <IconButton
                  className="edit-btn p-0"
                  onClick={() => setEditButton(false)}
                  component="span"
                >
                  <EditOutlinedIcon className="orange-text edit-icon" />
                </IconButton>
              </div>
            ) : (
              <>
                <div className="fs-exclude text-field d-flex">
                  <Input
                    fullWidth
                    id="standard-adornment-password"
                    defaultValue={email}
                    onChange={(event) => {
                      setEmail(event.target.value);
                    }}
                  />
                  <button
                    className="lbl-cancel"
                    onClick={() => {
                      setEmail(getDeviceTransitionEmail(props?.email));
                      setEmailError('');
                      setEditButton(true);
                    }}
                  >
                    cancel
                  </button>
                  <div className="error-position">{emailError}</div>
                </div>
              </>
            )}
          </FormControl>
          <div className="d-flex justify-content-center">
            <Button
              className={`${
                !isEmpty(emailError) ? 'mt-40' : 'mt-32'
              } m-orange-button`}
              onClick={continueOnComputor}
            >
              Continue on your computer
            </Button>
          </div>
        </div>
      )}

      <div className="col-sm-12">
        <Typography className="label-orange" onClick={continueOnPhone}>
          Continue on your phone
        </Typography>
      </div>
      <div className="col-sm-12 mob-position">
        <button
          type={BUTTON_TYPE}
          className="mob-white-back-button"
          onClick={continueOnPhone}
        ></button>
      </div>
    </div>
  );
};
const mapStateToProps = ({ postSignup }) => {
  const {
    continueOnDevice,
    deviceTransitionLoader,
    cancleSwitchDeviceNotification,
    currentStepIndex,
  } = postSignup;
  return {
    continueOnDevice,
    deviceTransitionLoader,
    cancleSwitchDeviceNotification,
    currentStepIndex,
  };
};

MobileTransition.propTypes = {
  setContinueOnComputer: PropTypes.func.isRequired,
  deviceTransitionLoader: PropTypes.bool,
  accessibleSteps: PropTypes.array,
  completedStepsCount: PropTypes.number,
  currentStepIndex: PropTypes.number,
  from: PropTypes.string,
  steps: PropTypes.object,
};

export default connect(mapStateToProps, {
  setContinueOnComputer,
  setDeviceTransitionEmail,
  setDeviceTransitionViewed,
  setSwitchToComputer,
  setCurrentStepIndex,
  setCancleSwitchDeviceValue,
})(MobileTransition);
