import * as types from '../../constants/action-types';

const initState = () => ({
  addLOAFilterLoader: false,
  allLOAFilterStates: [],
  allLOAFilterStateLoader: false,
  loaDetailsLoader: false,
  loaDetails: {},
  loaFilterList: [],
  loaListLoader: false,
  responseMessage: null,
});

export default (state = initState(), action) => {
  switch (action.type) {
    case types.LOA_FILTERS_LIST_LOADER: {
      return {
        ...state,
        loaListLoader: !!action?.payload ? true : false,
        responseMessage: null,
      };
    }
    case types.STORE_LOA_FILTERS_LIST: {
      const { loaFilterList, loaGroups } = action.payload;
      return {
        ...state,
        loaFilterList: loaFilterList ? loaFilterList : [...state.loaFilterList],
        loaGroups: loaGroups ? loaGroups : [...state.loaGroups],
        responseMessage: null,
      };
    }

    case types.LOA_FILTER_DETAILS_LOADER: {
      return {
        ...state,
        loaDetailsLoader: !!action?.payload ? true : false,
      };
    }

    case types.STORE_LOA_FILTERS_DETAILS: {
      const { loaData, loaStateMapping, responseMessage } = action.payload;
      return {
        ...state,
        loaDetails: {
          loaData: loaData,
          loaStateMapping: loaStateMapping,
        },
        responseMessage,
      };
    }

    case types.CLEAR_LOA_FILTER: {
      return {
        ...state,
        loaDetails: {},
        responseMessage: null,
      };
    }

    case types.ADD_NEW_LOA_FILTER_LOADER: {
      return {
        ...state,
        addLOAFilterLoader: action.payload,
        responseMessage: !action?.payload ? action.payload : null,
      };
    }

    case types.SET_ALL_LOA_FILTER_STATES: {
      return {
        ...state,
        allLOAFilterStates: action.payload,
        responseMessage: null,
      };
    }
    case types.SET_ALL_STATES_LOADER: {
      return {
        ...state,
        allLOAFilterStateLoader: action.payload,
      };
    }

    default:
      return state;
  }
};
