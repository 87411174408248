import { displaySuccessMessage } from 'util/user-feedback';
import { all, fork, put, takeEvery } from 'redux-saga/effects';
import * as carrierCredentialsActions from 'constants/action-types';
import { errorHandler } from '../actions/error';
import axios from 'util/api';
import {
  clearCarrierProducerCredentials,
  deleteCarrierCredentialStateLoader,
  setCarrierCredentialDetails,
  setCarrierCredentialDetailsLoader,
  setCarrierCredentialsList,
  setCarrierCredentialsListLoader,
  storeUpdatedAppointMentType,
  storeUpdatedCarrierProducerCreduential,
} from '../actions/carrier-credentials';

function* fetchCarrierCredentials(payload) {
  try {
    const { id } = payload;
    yield put(setCarrierCredentialsListLoader(true));
    let producer_id = id;
    let list = yield axios.get(
      `/api/v1/carrier/get-carrier-producer-appointments/${producer_id}`
    );
    if (list?.data?.success) {
      const { carrierProducerAppointments } = list.data;
      yield put(
        setCarrierCredentialsList({ list: carrierProducerAppointments })
      );
    }
    yield put(setCarrierCredentialsListLoader(true));
  } catch (error) {
    yield put(errorHandler(error));
  }
  yield put(setCarrierCredentialsListLoader(false));
}

export function* fetchCarrierCredentialsListWatcher() {
  yield takeEvery(
    carrierCredentialsActions.FETCH_CARRIER_CREDENTIALS,
    fetchCarrierCredentials
  );
}

function* fetchCarrierCredentialDetails(payload) {
  const { data } = payload;
  try {
    yield put(setCarrierCredentialDetailsLoader(true));
    let details = yield axios.post(
      '/api/v1/carrier/get-carrier-producer-credentials',
      data
    );
    if (details.status === 200 && details?.data?.success) {
      const { carrier, carrierProducerAppointment } = details.data;
      yield put(
        setCarrierCredentialDetails({
          carrierCredentialDetails: {
            carrier: carrier,
            carrierProducerAppointment: carrierProducerAppointment,
          },
        })
      );
    } else {
      yield put(setCarrierCredentialDetailsLoader(false));
    }
  } catch (error) {
    yield put(errorHandler(error));
  }
  yield put(setCarrierCredentialDetailsLoader(false));
}

export function* fetchCarrierCredentialDetailsWatcher() {
  yield takeEvery(
    carrierCredentialsActions.FETCH_CARRIER_CREDENTIALS_DETAILS,
    fetchCarrierCredentialDetails
  );
}

function* updateAppointmentTypeData({ payload }) {
  try {
    yield put(setCarrierCredentialDetailsLoader(true));
    let responseData = yield axios.post(
      '/api/v1/carrier/update-carrier-appointment-type',
      {
        ...payload,
      }
    );
    if (responseData?.status === 200 && responseData?.data?.success) {
      const { message, carrierProducerCredential } =
        !!responseData?.data && responseData.data;
      payload.carrierProducerAppointmentId =
        carrierProducerCredential?.carrierProducerAppointmentId &&
        carrierProducerCredential.carrierProducerAppointmentId;
      yield put(storeUpdatedAppointMentType(payload));
      yield put(clearCarrierProducerCredentials());
      yield displaySuccessMessage(message);
    } else {
      yield put(setCarrierCredentialDetailsLoader(false));
    }
  } catch (error) {
    yield put(errorHandler(error));
  }
  yield put(setCarrierCredentialDetailsLoader(false));
}

export function* updateAppointmentTypeDataWatcher() {
  yield takeEvery(
    carrierCredentialsActions.UPDATE_APPOINTMENT_TYPE,
    updateAppointmentTypeData
  );
}

function* updateCarrierProducerCredientialData({ payload }) {
  try {
    yield put(setCarrierCredentialDetailsLoader(true));
    let responseData = yield axios.post(
      '/api/v1/carrier/update-carrier-producer-credential',
      {
        ...payload,
      }
    );
    if (responseData?.status === 200 && responseData?.data?.success) {
      const { message, carrierProducerCredential, updatedAt } =
        !!responseData?.data && responseData.data;
      let newData = {
        ...payload,
        carrierProducerAppointmentId:
          !!payload?.isAdd || !!!payload?.carrierProducerAppointmentId
            ? carrierProducerCredential.carrierProducerAppointmentId
            : payload.carrierProducerAppointmentId,
        updatedAt,
      };
      yield put(storeUpdatedCarrierProducerCreduential(newData));
      yield displaySuccessMessage(message);
    } else {
      yield put(setCarrierCredentialDetailsLoader(false));
    }
  } catch (error) {
    yield put(errorHandler(error));
  }
  yield put(setCarrierCredentialDetailsLoader(false));
}

export function* updateCarrierProducerCredientialDataWatcher() {
  yield takeEvery(
    carrierCredentialsActions.UPDATE_CARRIER_PRODUCER_CREDIENTIAL,
    updateCarrierProducerCredientialData
  );
}

function* deleteCarrierCredentialState({ payload }) {
  try {
    const { carrierProducerAppointmentId } = payload;
    yield put(deleteCarrierCredentialStateLoader(true));
    let responseData = yield axios.post(
      '/api/v1/carrier/delete-carrier-producer-credential',
      {
        credentialId: carrierProducerAppointmentId,
      }
    );
    if (responseData?.status === 200 && responseData?.data?.success) {
      const { message, updatedAt } = !!responseData?.data && responseData.data;
      yield put(
        storeUpdatedCarrierProducerCreduential({
          ...payload,
          isDeleted: true,
          updatedAt,
        })
      );
      yield displaySuccessMessage(message);
    }
  } catch (error) {
    yield put(errorHandler(error));
  }
  yield put(deleteCarrierCredentialStateLoader(false));
}

export function* deleteCarrierCredentialStateWatcher() {
  yield takeEvery(
    carrierCredentialsActions.DELETE_CARRIER_CREDENTIAL_STATE,
    deleteCarrierCredentialState
  );
}

export default function* rootSaga() {
  yield all([
    fork(fetchCarrierCredentialsListWatcher),
    fork(fetchCarrierCredentialDetailsWatcher),
    fork(updateAppointmentTypeDataWatcher),
    fork(updateCarrierProducerCredientialDataWatcher),
    fork(deleteCarrierCredentialStateWatcher),
  ]);
}
