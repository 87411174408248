import React from 'react';
import { connect } from 'react-redux';
import {
  setInitUrl,
  signinAuth0HandleRedirect,
  userSignIn,
} from '../redux/actions';
import getQueryParams from 'util/query-string';
import ProcessHeader from 'components/process-header';
import Footer from 'components/process-footer';
import { setSignupTransLoginSuccess } from '../redux/actions/organization-details';
import DancingDots from 'components/dancing-dots';

class SignupTransition extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
  }

  componentDidMount() {
    const { location } = this.props;
    const page = getQueryParams(location.search, 'page');

    this.props.setSignupTransLoginSuccess(0);

    localStorage.setItem('magicToken', 'true');
    localStorage.setItem('page', page);
    this.props.signinAuth0HandleRedirect();
  }

  componentDidUpdate() {
    const { transitionGetAgentSuccess, transitionLoginSuccess } = this.props;
    if (transitionGetAgentSuccess === 2 && transitionLoginSuccess === 2) {
      this.props.history.push('/post-signup/process');
    } else if (
      transitionGetAgentSuccess === 1 ||
      transitionLoginSuccess === 1
    ) {
      this.props.setInitUrl('/signin');
      this.props.history.push('/signin');
    }
  }

  render() {
    return (
      <div className={`app-container pre-signup-wrapper`}>
        <div className="app-main-container">
          <div className={`app-header`}>
            <ProcessHeader />
          </div>
          <main className="app-main-content-wrapper email-verification">
            <div className="app-main-content">
              <div className="app-login-container d-flex justify-content-center align-items-center">
                <div className="loader-view">
                  <DancingDots fullpageWrapper />
                </div>
              </div>
            </div>
            <Footer />
          </main>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ auth, organizationDetails }) => {
  const { loader, initURL } = auth;
  const { transitionLoginSuccess, transitionGetAgentSuccess } =
    organizationDetails;
  return {
    loader,
    initURL,
    transitionLoginSuccess,
    transitionGetAgentSuccess,
  };
};

export default connect(mapStateToProps, {
  userSignIn,
  setInitUrl,
  setSignupTransLoginSuccess,
  signinAuth0HandleRedirect,
})(SignupTransition);
