import * as rolePermissionAction from '../../constants/action-types';

// Role Permission action methods
export const fetchRolePermissionList = () => ({
  type: rolePermissionAction.FETCH_ROLE_PERMISSION_LIST,
});

export const setRolePermissionList = payload => ({
  type: rolePermissionAction.SET_ROLE_PERMISSION_LIST,
  payload,
});

export const setRolePermissionLoader = payload => ({
  type: rolePermissionAction.ROLE_PERMISSION_LIST_LOADER,
  payload,
});

// Clear role permission list data
export const clearRolePermissionList = () => ({
  type: rolePermissionAction.CLEAR_ROLE_PERMISSION_LIST,
});

// Edit Role Permission List
export const fetchRoleEditPermissionList = payload => ({
  type: rolePermissionAction.FETCH_ROLE_EDIT_PERMISSION_LIST,
  payload,
});

export const setRoleEditPermissionListLoader = payload => ({
  type: rolePermissionAction.ROLE_EDIT_PERMISSION_LIST_LOADER,
  payload,
});

export const setRoleEditPermissionList = payload => ({
  type: rolePermissionAction.SET_ROLE_EDIT_PERMISSION_LIST,
  payload,
});

// Update Role Permission List
export const updateRolePermissionLoader = payload => ({
  type: rolePermissionAction.UPDATE_ROLE_PERMISSION_LOADER,
  payload,
});

export const updateRolePermission = payload => ({
  type: rolePermissionAction.UPDATE_ROLE_PERMISSION,
  payload,
});

export const setUpdatedRolePermission = payload => ({
  type: rolePermissionAction.SET_UPDATED_ROLE_PERMISSION,
  payload,
});

// add new role
export const addNewRolePermissionList = payload => ({
  type: rolePermissionAction.ADD_NEW_ROLE,
  payload,
});

// save new role
export const setNewRolePermissionList = payload => ({
  type: rolePermissionAction.SET_NEW_ROLE,
  payload,
});

// clear responce message
export const clearResponseMessage = payload => ({
  type: rolePermissionAction.CLEAR_RESPONCE_MESSAGE,
  payload,
});

// clear responce message
export const deleteRole = payload => ({
  type: rolePermissionAction.DELETE_ROLE,
  payload,
});

export const fetchCategoryList = payload => ({
  type: rolePermissionAction.FETCH_CATEGORY_LIST,
  payload,
});

export const setCategoryList = payload => ({
  type: rolePermissionAction.SET_CATEGORY_LIST,
  payload,
});

export const categoryListLoader = payload => ({
  type: rolePermissionAction.CATEGORY_LIST_LOADER,
  payload,
});

export const setIsFetchFailed = payload => ({
  type: rolePermissionAction.SET_IS_FETCH_FAILED,
  payload,
});
