import { forEach, get, uniq } from 'lodash';
import * as types from '../../constants/action-types';

const initState = () => ({
  agentOnboardResponse: null,
  agentSyncReportData: null,
  bucket_url: null,
  directoryList: [],
  documentLoader: false,
  quickbooksReportData: null,
  monthlyCommissionLoader: false,
  monthlyCommissionReportData: null,
  latestCommissionMonth: null,
  commissionResponse: null,
  appointmentFailureReportData: null,
  fastStartReportData: null,
  incentiveReportData: null,
  carrierLobMarketReportLoader: false,
  rewardsProgramReportsLoader: false,
});

export default (state = initState(), action) => {
  switch (action.type) {
    case types.SET_ONBOARD_REPORT: {
      return {
        agentOnboardResponse: action.payload,
      };
    }
    case types.SET_AGENT_SYNC_REPORT: {
      return {
        agentSyncReportData: action.payload,
      };
    }
    case types.SET_APPOINTMENT_FAILURE_REPORT: {
      return {
        appointmentFailureReportData: action.payload,
      };
    }
    case types.SET_FAST_START_REPORT: {
      return {
        fastStartReportData: action.payload,
      };
    }

    case types.SET_MONTHLY_COMMISSION_REPORT: {
      return {
        ...state,
        monthlyCommissionReportData: action.payload,
      };
    }
    case types.SET_MONTHLY_COMMISSION_DATE: {
      return {
        ...state,
        latestCommissionMonth: action.payload,
      };
    }
    case types.MONTHLY_COMMISSION_DATE_LOADER: {
      return {
        ...state,
        monthlyCommissionLoader: action.payload,
      };
    }
    case types.SET_QUICKBOOKS_REPORT: {
      return {
        quickbooksReportData: action.payload,
      };
    }
    case types.SET_COMMISSION_REPORT: {
      return {
        commissionResponse: action.payload,
      };
    }
    case types.MOTION_AUTO_LIST_LOADER: {
      return {
        ...state,
        documentLoader: action.payload,
      };
    }
    case types.SET_MOTION_AUTO_REPORT: {
      const files = get(action, 'payload.files', []);
      const directories = get(action, 'payload.directories', []);
      const directoryName = get(action, 'payload.directoryName', '');
      const listNames = [];
      if (directories.length > 0) {
        forEach(directories, (dir) => {
          listNames.push(dir.Key.replace(/\/$/, ''));
        });
      }
      const uniqList = uniq([state.directoryList, directoryName, ...listNames]);
      return {
        ...state,
        directoryList: [...uniqList],
        [action.payload.directoryName]: {
          documentFilesDetails: [...files],
          documentFoldersDetails: [...directories],
        },
        bucket_url: action.payload.bucket_url,
      };
    }
    case types.SET_INCENTIVE_REPORT: {
      return {
        incentiveReportData: action.payload,
      };
    }
    case types.CARRIER_LOB_MARKET_REPORT_LOADER: {
      return {
        carrierLobMarketReportLoader: action.payload,
      };
    }
    case types.REWARDS_PROGRAM_REPORT_LOADER: {
      return {
        rewardsProgramReportsLoader: action.payload,
      };
    }

    default:
      return state;
  }
};
