import config from 'config';
import crypto from 'crypto-js';

export const encryptStore = (store) => {
  return crypto.AES.encrypt(store, config.CRYPTO_KEY).toString();
};

export const decryptStore = (encryptedStore) => {
  return crypto.AES.decrypt(encryptedStore, config.CRYPTO_KEY).toString(
    crypto.enc.Utf8
  );
};
