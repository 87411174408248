import * as incentive from '../../constants/action-types';

export const fetchBannerIncentiveDetails = (payload) => ({
  type: incentive.FETCH_Q3_INCENTIVE_DETAIL,
  payload,
});

export const setIncentiveDetails = (payload) => ({
  type: incentive.SET_Q3_INCENTIVE_DETAIL,
  payload,
});

export const setQ3DaysRemaining = (payload) => ({
  type: incentive.SET_Q3_DAYSREMAINING,
  payload,
});

export const setIncentiveDetailsLoader = (payload) => ({
  type: incentive.SET_Q3_INCENTIVE_DETAIL_LOADER,
  payload,
});

export const setQ4DaysRemaining = (payload) => ({
  type: incentive.SET_Q4_DAYSREMAINING,
  payload,
});

export const setIncentiveEarningDetails = (payload) => ({
  type: incentive.SET_INCENTIVE_EARNING,
  payload,
});

export const fetchIncentiveEarning = () => ({
  type: incentive.FETCH_INCENTIVE_EARNING,
});
