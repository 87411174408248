import { all, fork, put, takeEvery } from 'redux-saga/effects';
import { displayErrorMessage } from 'util/user-feedback';
import axios from 'util/api';
import * as classCodesActions from 'constants/action-types';
import { errorHandler } from '../actions/error';
import {
  setClassCodes,
  setClassCodesLoader,
  updateClassCodesDetails,
} from 'redux/actions/class-codes';

function* fetchClassCodeListDetails({ payload }) {
  const { stateName } = payload;

  try {
    yield put(setClassCodesLoader(true));
    const classCode = yield axios.get(
      `/api/v1/partner/wc-class-codes/${stateName}`
    );
    if (classCode.data) {
      const codeList = classCode.data.classCodes;
      yield put(setClassCodes(codeList));
    }
  } catch (error) {
    if (!!error.response && error.response.status === 401) {
yield displayErrorMessage(error.response.data.message)
      yield put(errorHandler(error));
    } else {
      yield put(errorHandler(error));
    }
  }
  yield put(setClassCodesLoader(false));
}

export function* fetchClassCodesWatcher() {
  yield takeEvery(
    classCodesActions.FETCH_CLASS_CODES,
    fetchClassCodeListDetails
  );
}

function* saveClassCodeListDetails({ payload }) {
  try {
    yield put(setClassCodesLoader(true));
    const {
      businessName,
      stateName: state,
      codeList: classCodes,
      selectedLineOfBusinesses,
      producerId,
      industry,
    } = payload;
    const details = yield axios.post(
      '/api/v1/partner/validate-carrier-appetite',
      {
        state,
        businessName,
        classCodes,
        selectedLineOfBusinesses,
        producerId,
        industry,
      }
    );

    if (details.data.carriers) {
      const classCodesDetails = {
        carriers: details?.data?.carriers,
        isSuccess: details?.data?.success,
      };
      yield put(updateClassCodesDetails(classCodesDetails));
    } else {
      yield put(updateClassCodesDetails({ isSuccess: details?.data?.success }));
    }
  } catch (error) {
    if (!!error.response && error.response.status === 401) {
yield displayErrorMessage(error.response.data.message)
      yield put(errorHandler(error));
    } else {
      yield put(errorHandler(error));
    }
  }
  yield put(setClassCodesLoader(false));
}

export function* saveClassCodesWatcher() {
  yield takeEvery(classCodesActions.SAVE_CLASS_CODES, saveClassCodeListDetails);
}

// ------ ROOT SAGA -----------------
export default function* rootSaga() {
  yield all([fork(fetchClassCodesWatcher), fork(saveClassCodesWatcher)]);
}
