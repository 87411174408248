import * as types from '../../constants/action-types';

const initState = () => ({
  carrierLoader: false,
  carrierAppointmentFileLoader: false,
  carrierList: [],
});

export default (state = initState(), action) => {
  switch (action.type) {
    case types.INIT_URL: {
      return { ...state, initURL: action.payload };
    }

    case types.SET_CARRIER_APPOINTMENT_LIST: {
      return {
        ...state,
        carrierList: [...action.payload], // carrier list as an array of objects
      };
    }

    case types.CARRIER_APPOINTMENT_LIST_LOADER: {
      return {
        ...state,
        carrierLoader: action.payload,
      };
    }
    case types.CARRIER_APPOINTMENT_FILE_LOADER: {
      return {
        ...state,
        carrierAppointmentFileLoader: action.payload,
      };
    }

    default:
      return state;
  }
};
