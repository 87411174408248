import * as types from '../../constants/action-types';

const initState = () => ({
  loader: true,
  carrierOnboardList: [],
  addCarrierLoader: false,
  addedCarrierData: {},
  carrierProductList: [],
  carrierListFetchSuccess: true,
});

export default (state = initState(), action) => {
  switch (action.type) {
    case types.INIT_URL: {
      return { state, initURL: action.payload };
    }

    case types.SET_CARRIER_ONBOARD_LIST: {
      return {
        loader: false,
        carrierOnboardList: action.payload.carrierList, // carrier onboard list as an array of objects
        carrierListFetchSuccess: action.payload.success,
      };
    }

    case types.CARRIER_ONBOARD_LIST_LOADER: {
      return {
        ...state,
        loader: action.payload,
      };
    }

    case types.CLEAR_CARRIER_ONBOARD_LIST: {
      return {
        carrierOnboardList: [],
      };
    }

    case types.ADD_NEW_CARRIER_LOADER: {
      return {
        ...state,
        addCarrierLoader: action.payload,
      };
    }

    case types.SET_NEW_CARRIER: {
      return {
        ...state,
        loader: false,
        carrierOnboardList: [action.payload, ...state.carrierOnboardList], // Append new carrier object to existing carrierOnboardList
        addedCarrierData: action.payload,
      };
    }

    case types.FETCH_CARRIER_PRODUCT_LOADER: {
      return {
        ...state,
        carrierProductLoader: action.payload,
      };
    }

    case types.SET_CARRIER_PRODUCTS_LIST: {
      return {
        ...state,
        carrierProductList: [...action.payload],
      };
    }

    case types.ADD_NEW_PRODUCT_LOADER: {
      return {
        ...state,
        addCarrierProductLoader: action.payload,
      };
    }

    case types.CLEAR_CARRIER_RESPONSE: {
      return {
        ...state,
        addedCarrierData: {
          ...state?.addedCarrierData,
          responseMessage: null,
        },
      };
    }

    default:
      return state;
  }
};
