import * as adhocPaymentActions from '../../constants/action-types';

// selected agent details fetch
export const fetchAdHocPaymentList = () => ({
  type: adhocPaymentActions.FETCH_ADHOC_PAYMENT_LIST,
});

export const adHocPaymentLoader = details => ({
  type: adhocPaymentActions.ADHOC_PAYMENT_LOADER,
  payload: details,
});

export const fetchAdHocPaymentListSuccess = details => ({
  type: adhocPaymentActions.FETCH_ADHOC_PAYMENT_LIST_SUCCESS,
  payload: details,
});

export const adhocPaymentEditTitleFun = details => ({
  type: adhocPaymentActions.ADHOC_PAYMENT_EDIT_TITLE,
  payload: details,
});

export const adhocPaymentEditTitleSuccess = details => ({
  type: adhocPaymentActions.ADHOC_PAYMENT_EDIT_TITLE_SUCCESS,
  payload: details,
});
export const adHocPaymentAddRowData = details => ({
  type: adhocPaymentActions.ADHOC_PAYMENT_ADD_ROW,
  payload: details,
});
export const adHocPaymentAddRowDataSuccess = details => ({
  type: adhocPaymentActions.ADHOC_PAYMENT_ADD_ROW_SUCCESS,
  payload: details,
});

export const adHocPaymentFileUploadSuccess = details => ({
  type: adhocPaymentActions.ADHOC_PAYMENT_FILE_UPLOAD_SUCCESS,
  payload: details,
});

export const adHocPaymentListDelete = details => ({
  type: adhocPaymentActions.FETCH_ADHOC_PAYMENT_LIST_DELETE,
  payload: details,
});

export const adHocPaymentLockPayout = details => ({
  type: adhocPaymentActions.ADHOC_PAYMENT_LOCK_PAYOUT,
  payload: details,
});
