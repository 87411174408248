import * as types from '../../constants/action-types';

const initState = () => ({
  modulePermissionsData: null,
});

export default (state = initState(), action) => {
  switch (action.type) {
    case types.SET_MODULE_PERMISSIONS: {
      let modulePermissions = action.payload;
      return {
        ...state,
        modulePermissionsData: modulePermissions,
      };
    }
    default:
      return state;
  }
};
