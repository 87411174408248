import React, { useEffect, useState } from 'react';
import { encryptUrlParams } from 'util/crypto';

const CarrierAppetiteIFrame = ({ selectedCarrier, quoteUrl }) => {
  const [encryptedUrl, setEncryptedUrl] = useState('');

  useEffect(() => {
    const encryptedwidgetUrlParam = encryptUrlParams(JSON.stringify(quoteUrl));
    setEncryptedUrl(encryptedwidgetUrlParam);
  }, [encryptedUrl, quoteUrl]);

  const srcUrl = `${selectedCarrier?.carrierQuotingURL}/${encryptedUrl}`;
  return (
    <iframe
      src={srcUrl}
      title={selectedCarrier?.carrierName}
      width="100%"
      height="100%"
      frameBorder="0"
    />
  );
};

export default CarrierAppetiteIFrame;
