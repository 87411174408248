import { displayErrorMessage, displaySuccessMessage } from 'util/user-feedback';
import {
  all,
  fork,
  put,
  select,
  takeEvery,
  takeLeading,
} from 'redux-saga/effects';
import { errorHandler } from 'redux/actions/error';
import axios from 'util/api';
import * as actions from 'constants/action-types';
import {
  saveAppointmentSettings,
  setAppointmentEnabled,
  setshowInStore,
  setUpdateAppointmentSettingLoader,
} from 'redux/actions/carrier-appointment-settings-admin';
import mapper from 'util/mapper/CarrierAppointmentsMapper';
import config from 'config';

function* toggleCarrierAppointmentSettings({ payload }) {
  try {
    const toggleCarrierAppointmentSettingsResponse = yield axios.post(
      '/api/v1/carrier/toggle-carrier-appointment-settings',
      payload
    );

    if (!!toggleCarrierAppointmentSettingsResponse.data) {
      const { success, message, appointments_enabled, show_in_store } =
        toggleCarrierAppointmentSettingsResponse.data;

      if (toggleCarrierAppointmentSettingsResponse.status === 200 && success) {
        yield put(
          setAppointmentEnabled({
            acceptingAppointments: appointments_enabled,
          })
        );
        yield put(
          setshowInStore({
            showInStore: show_in_store,
          })
        );
        yield displaySuccessMessage(message);
      } else {
        yield displayErrorMessage(message);
      }
    }
  } catch (error) {
    if (!!error.response && error.response.status === 401) {
      yield displayErrorMessage(error.response.data.message);
      yield put(errorHandler(error));
    } else {
      yield put(errorHandler(error));
    }
    yield put(
      setAppointmentEnabled({
        acceptingAppointments: false,
      })
    );
    yield put(
      setshowInStore({
        showInStore: false,
      })
    );
  }
}

async function uploadFile(file, producerCode) {
  if (!file) {
    return null;
  }

  const name = file.name;
  const bodyFormData = new FormData();
  bodyFormData.append(
    'filePath',
    `${config.PRODUCER_UPLOAD_FOLDER}/${producerCode}/more-information-documents/${name}`
  );
  bodyFormData.append('file', file);

  const options = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };

  const response = await axios.post(
    '/api/v1/vendor/upload-file',
    bodyFormData,
    options
  );

  if (!response || !response.data.success) {
    throw new Error('Error uploading file');
  }

  return response.data.file.includes(' ')
    ? encodeURI(response.data.file)
    : response.data.file;
}

export function* toggleCarrierAppointmentSettingsWatcher() {
  yield takeEvery(
    actions.TOGGLE_CARRIER_APPOINTMENT_SETTINGS,
    toggleCarrierAppointmentSettings
  );
}

function* updateCarrierAppointmentSettings({ payload }) {
  yield put(setUpdateAppointmentSettingLoader(true));
  try {
    const { newFilesToUpload, ...request } = payload;

    const newFiles = [];
    for (const file of newFilesToUpload) {
      const producerCode = yield select((state) => state.auth.producer_code);
      const url = yield uploadFile(file.content, producerCode);
      newFiles.push({
        id: file.id,
        name: file.name,
        date: file.date,
        type: file.content.type,
        url,
      });
    }

    request.more_information_documents.push(...newFiles);

    const toggleCarrierAppointmentSettingsResponse = yield axios.post(
      '/api/v1/carrier/update-carrier-appointment-settings',
      request
    );

    if (!!toggleCarrierAppointmentSettingsResponse.data) {
      const { success, message, carrierAppointmentSettings } =
        toggleCarrierAppointmentSettingsResponse.data;

      if (toggleCarrierAppointmentSettingsResponse.status === 200 && success) {
        yield put(
          saveAppointmentSettings(
            mapper.mapSaveAppointmentSettings(carrierAppointmentSettings)
          )
        );
        yield displaySuccessMessage(message);
      } else {
        yield displayErrorMessage(message);
      }
    }
  } catch (error) {
    if (!!error.response && error.response.status === 401) {
      yield displayErrorMessage(error.response.data.message);
      yield put(errorHandler(error));
    } else {
      yield put(errorHandler(error));
    }
  }
  yield put(setUpdateAppointmentSettingLoader(false));
}

export function* updateCarrierAppointmentSettingsWatcher() {
  yield takeLeading(
    actions.UPDATE_CARRIER_APPOINTMENT_SETTINGS,
    updateCarrierAppointmentSettings
  );
}

export default function* rootSaga() {
  yield all([
    fork(toggleCarrierAppointmentSettingsWatcher),
    fork(updateCarrierAppointmentSettingsWatcher),
  ]);
}
