import * as LOAFilterAction from 'constants/action-types';

export const loaFilterListLoader = flag => ({
  type: LOAFilterAction.LOA_FILTERS_LIST_LOADER,
  payload: flag,
});

export const fetchFiltersList = () => ({
  type: LOAFilterAction.FETCH_LOA_FILTERS,
});

export const storeLOAFiltersList = payload => ({
  type: LOAFilterAction.STORE_LOA_FILTERS_LIST,
  payload,
});

export const loaFilterDetailLoader = flag => ({
  type: LOAFilterAction.LOA_FILTER_DETAILS_LOADER,
  payload: flag,
});

export const fetchFiltersDetails = payload => ({
  type: LOAFilterAction.FETCH_LOA_FILTERS_DETAILS,
  payload,
});

export const storeLOAFiltersDetails = payload => ({
  type: LOAFilterAction.STORE_LOA_FILTERS_DETAILS,
  payload,
});

export const clearLoaFilter = payload => ({
  type: LOAFilterAction.CLEAR_LOA_FILTER,
  payload,
});

export const addNewLoaFilter = payload => ({
  type: LOAFilterAction.ADD_NEW_LOA_FILTER,
  payload,
});

export const addNewLoaFilterLoader = payload => ({
  type: LOAFilterAction.ADD_NEW_LOA_FILTER_LOADER,
  payload,
});

export const fetchAllStates = payload => ({
  type: LOAFilterAction.FETCH_ALL_STATES,
  payload,
});

export const setAllStateLoader = payload => ({
  type: LOAFilterAction.SET_ALL_STATES_LOADER,
  payload,
});

export const setAllLOAFilterStates = payload => ({
  type: LOAFilterAction.SET_ALL_LOA_FILTER_STATES,
  payload,
});

export const updateLoaFilter = payload => ({
  type: LOAFilterAction.UPDATE_LOA_FILTER,
  payload,
});
