// @ts-check
import Cookies from 'universal-cookie';
import getQueryParams from 'util/query-string';
import { PRODUCER, REDIRECT_URL, USER_ROLE } from 'constants/constant';

/**
 * Returns redirect URL from query params.
 * @returns {string | null}
 */
export const getRedirectUrlFromParams = () => {
  const url = getQueryParams(location.search, 'redirectUrl');
  return url ? decodeURIComponent(url) : null;
};

/**
 * Returns user role from URL.
 * This function works only for the legacy routes, as new ones
 * follow simpler pattern without explicit role in the URL.
 * @param {string} url
 * @returns {string} 'producer' is default
 * @example
 * getUserRoleFromUrl('/app/admin/dashboard'); // returns 'admin'
 * getUserRoleFromUrl('/cash-connect'); // returns 'producer'
 */
export const getUserRoleFromUrl = (url) => {
  return url.split('/')?.[2] ?? PRODUCER;
};

/**
 * @param {string} url
 * @param {string} userRole
 * @returns {void}
 */
export const setRedirectUrlCookie = (url, userRole) => {
  const cookies = new Cookies();
  const cookieOptions = {
    secure: true,
    path: '/',
    maxAge: 604800, // 7 days expiration time
  };

  if (url) {
    cookies.set(REDIRECT_URL, url, cookieOptions);
    cookies.set(USER_ROLE, userRole, cookieOptions);
  }
};

/**
 * @returns {{ url: string | null, isExternalRoute: boolean }}}
 */
export const getRedirectUrlFromCookie = () => {
  const cookies = new Cookies();
  const userRoleInCookie = cookies.get(USER_ROLE) || '';
  const userRoleInLocalStorage = localStorage.getItem('role_category');

  if (userRoleInCookie !== userRoleInLocalStorage) {
    return {
      url: null,
      isExternalRoute: false,
    };
  }

  const redirectUrl = cookies.get(REDIRECT_URL) ?? null;
  const isExternalRoute = redirectUrl ? redirectUrl.startsWith('http') : false;

  return {
    url: redirectUrl,
    isExternalRoute,
  };
};

/**
 * @returns {void}
 */
export const cleanupRedirectCookieData = () => {
  const cookies = new Cookies();
  cookies.remove(REDIRECT_URL, { path: '/' });
  cookies.remove(USER_ROLE, { path: '/' });
};
