import { all, fork, put, select, takeEvery } from 'redux-saga/effects';
import {
  setIncentiveLeaderBoardDetails,
  setLeaderBoardDetailLoader,
  setLeaderBoardDetails,
  setShouldShowLeaderBoard,
} from '../actions/leaderboard';
import axios from 'util/api';
import * as fastStart from 'constants/action-types';
import { getAuthDetails } from 'util/extra';
import { getImpersonateBFFRequestConfig } from './impersonate';
import { errorHandler } from '../actions/error';
import config from 'config';

// Get leaderboard details
export function* fetchLeaderBoardDetails() {
  try {
    const store = yield select();
    const { auth, impersonate } = store;
    const { producer, isImpersonate } = impersonate;
    const { producer_id } = getAuthDetails({
      auth,
      producer,
      isImpersonate,
    });

    const requestConfig = yield getImpersonateBFFRequestConfig();
    const responseData = yield axios.get(
      `${config.BFF_URL}/api/v1/incentive/producer/${producer_id}/leaderboard/fast-start`,
      requestConfig
    );
    yield put(setLeaderBoardDetailLoader(false));
    yield put(setLeaderBoardDetails(responseData.data));
  } catch (error) {
    yield put(setShouldShowLeaderBoard(false));
    yield put(setLeaderBoardDetailLoader(false));
  }
}

export function* fetchLeaderBoardDetailsWatcher() {
  yield takeEvery(fastStart.FETCH_LEADER_BOARD_DETAIL, fetchLeaderBoardDetails);
}

export function* fetchIncentiveLeaderBoardDetails({ payload }) {
  try {
    const store = yield select();
    const { auth, impersonate } = store;
    const { producer, isImpersonate } = impersonate;
    const { producer_id } = getAuthDetails({
      auth,
      producer,
      isImpersonate,
    });

    const requestConfig = yield getImpersonateBFFRequestConfig();
    const responseData = yield axios.get(
      `${config.BFF_URL}/api/v1/incentive/producer/${producer_id}/leaderboard/${payload}/tiered?`,
      requestConfig
    );
    yield put(setIncentiveLeaderBoardDetails(responseData.data.tiers));
  } catch (error) {
    yield put(errorHandler(error));
  }
}

export function* fetchIncentiveLeaderBoardDetailsWatcher() {
  yield takeEvery(
    fastStart.FETCH_INCENTIVE_LEADER_BOARD_DETAIL,
    fetchIncentiveLeaderBoardDetails
  );
}

export default function* rootSaga() {
  yield all([
    fork(fetchIncentiveLeaderBoardDetailsWatcher),
    fork(fetchLeaderBoardDetailsWatcher),
  ]);
}
