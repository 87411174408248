import React from 'react';
import clsx from 'clsx';
import { Card, Typography } from '@material-ui/core';

const Testimonials = ({ classNames, socialProofContent }) => (
  <Card className={clsx('social-proof-card-wrap', classNames?.card)}>
    <div className={clsx(classNames?.cardContent, 'card-content')}>
      <Typography className="social-heading">
        {socialProofContent?.title}
      </Typography>
      <div className="social-content">
        <div className="w-100 social-para">{socialProofContent?.content}</div>
        <div className="social-content-footer">
          <div className='mb-16'>
            <span className="lbl-auther">{socialProofContent?.author}</span>
            <span className="lbl-state">{socialProofContent?.state}</span>
          </div>
          <span className='logo'>
            <img src={socialProofContent?.image} className="img-fluid" />
          </span>
        </div>
      </div>
    </div>
  </Card>
);

export default Testimonials;
