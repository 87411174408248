import React from 'react';
import PropTypes from 'prop-types';
import { PRODUCER } from 'constants/constant';
import { getModules } from 'constants/modules';
import { connect, useSelector } from 'react-redux';
import { getCarrierLink } from 'redux/actions/dashboard';

const CarrierNavItem = ({
  module,
  impersonate,
  carrier_id,
  producer_id,
  ...props
}) => {
  const dispatchGetCarrier = () => {
    if (!carrier_id || !producer_id) return;
    props.getCarrierLink({
      carrier_id,
      producer_id,
      isFromNavItem: true,
    });
  };
  const details = getModules(PRODUCER, module, impersonate);
  const dashboardState = useSelector(state => state.dashboard);
  const { icon, title } = details;
  const { carrierLink } = !!dashboardState && dashboardState;
  return (
    <span
      href={carrierLink}
      rel="noopener noreferrer"
      target="blank"
      className="carrier-item"
      onClick={dispatchGetCarrier}
    >
      {!!icon && icon}
      <span className="nav-text">{!!title && title}</span>
    </span>
  );
};

CarrierNavItem.propTypes = {
  module: PropTypes.string.isRequired,
  impersonate: PropTypes.bool.isRequired,
};

export default connect(null, {
  getCarrierLink,
})(CarrierNavItem);
