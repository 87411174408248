import React from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import SubmitLoader from 'components/submit-loader';
import { withStyles } from '@material-ui/core/styles';
import {
  sendForgotPasswordLink,
  updatePasswordEmailSend,
} from '../../redux/actions/update-password';
import { setInitUrl } from '../../redux/actions/auth';
import ProcessHeader from 'components/process-header';
import ProcessFooter from 'components/process-footer';
import { email_regex } from 'util/sign-up-process';
import { globalMessages } from 'util/extra';
import { Card, CardActions, CardContent, Typography } from '@material-ui/core';
import { isEmpty } from 'lodash';
import { withGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { executeRecaptchaCheck } from 'util/recaptcha';

const styles = () => ({
  fieldError: {
    position: 'relative',
    width: '100%',
    height: '0px',
    top: '0px',
    left: '0px',
    fontSize: '13px',
    color: '#f00',
  },
  loginReturnLink: {
    display: 'block',
    width: '100%',
    paddingTop: '10px',
    textAlign: 'center',
    textDecoration: 'underline !important',
  },
  returnLabel: {
    fontFamily: 'Nunito Sans',
    fontWeight: '600',
    fontSize: '12px',
    lineHeight: '16px',
    textDecoration: 'underline !important',
    marginBottom: '16px',
    cursor: 'pointer',
  },
});

class ForgotPassword extends React.Component {
  constructor() {
    super();
    this.state = {
      email: '',
      formError: {
        emailError: '',
      },
    };
  }

  componentDidMount() {
    const { authUser } = this.props;

    if (authUser) {
      this.props.setInitUrl('/signin');
      this.props.history.push('/signin');
    }
  }

  onRecoverPassword = async (event) => {
    event.preventDefault();
    const { email } = this.state;

    if (!this.validate()) {
      return;
    }

    try {
      const captchaToken = await executeRecaptchaCheck(
        this.props.googleReCaptchaProps
      );
      this.props.sendForgotPasswordLink({
        producerEmail: email,
        captchaToken,
      });
    } catch (error) {
      console.error(error);
    }
  };

  validate = () => {
    const { email } = this.state;
    let formError = { ...this.state.formError };

    formError = {
      emailError: '',
    };

    if (!email.length > 0) {
      formError.emailError = 'Required';
    }

    if (email.length > 0 && !email_regex.test(email)) {
      formError.emailError = 'Invalid';
    }

    this.setState({
      formError,
    });

    if (formError.emailError) {
      return false;
    }

    return true;
  };

  render() {
    const { email, formError } = this.state;
    const { classes, updatingPassword, updatePasswordEmailSent } = this.props;
    const isEnabled = !isEmpty(email);
    const passwordUpdate = {
      success: false,
      responseStatus: null,
    };

    return (
      <div className="app-container post-signup-wrapper login-container">
        <div className="app-main-container">
          <div className={`app-header`}>
            <ProcessHeader />
          </div>

          <main className="app-main-content-wrapper">
            <div className="app-main-content">
              <div
                className="section no-pad-bot blue-grey lighten-5"
                id="sign-up-form-field"
              >
                <div className="container">
                  <div className="signin-form d-flex justify-content-center">
                    <div className="recover-pwd-wrap">
                      <div className="recover-password-form animated slideInUpTiny animation-delay-1">
                        {!updatePasswordEmailSent.success ? (
                          <form
                            className="sign-up-form signin"
                            onSubmit={this.onRecoverPassword}
                          >
                            <Card>
                              <CardContent className="p-40 border-bottom">
                                <div>
                                  <Typography className="recover-password-heading">
                                    Recover Password
                                  </Typography>
                                </div>

                                <div className="w-100 mx-auto">
                                  <div className="input-field">
                                    <input
                                      id="email"
                                      value={email}
                                      type="text"
                                      className="fs-exclude validate"
                                      maxLength="50"
                                      onChange={(event) =>
                                        this.setState({
                                          email: event.target.value,
                                        })
                                      }
                                      onBlur={(event) =>
                                        this.setState({
                                          email: event.target.value.trim(),
                                        })
                                      }
                                      disabled={updatingPassword}
                                    />
                                    <label htmlFor="email" className="active">
                                      Email <span className="required">*</span>
                                    </label>
                                    <div className={classes.fieldError}>
                                      {formError.emailError}
                                    </div>
                                  </div>
                                </div>
                              </CardContent>
                              <CardActions className="p-0">
                                <div className="row w-100 mx-auto">
                                  <div
                                    className={`col-lg-12 d-flex justify-content-center ${classes.sendButton}`}
                                  >
                                    <button
                                      className={`${
                                        !isEnabled ? 'disable' : ''
                                      } send-request-button`}
                                      disabled={updatingPassword || !isEnabled}
                                    >
                                      submit
                                      {/* {globalMessages.sendMessages} */}
                                      {updatingPassword && <SubmitLoader />}
                                    </button>
                                  </div>
                                  <div className="col-lg-12 d-flex justify-content-center">
                                    <NavLink
                                      to="/signin"
                                      className={`orange-text ${classes.returnLabel}`}
                                      onClick={() => {
                                        this.props.updatePasswordEmailSend(
                                          passwordUpdate
                                        );
                                      }}
                                    >
                                      {globalMessages.returnMessage}
                                    </NavLink>
                                  </div>
                                </div>
                              </CardActions>
                            </Card>
                          </form>
                        ) : (
                          <Card>
                            <CardContent>
                              <div className="row font-weight-bold pt-2 mx-auto">
                                <div
                                  className="mt-2"
                                  style={{ textAlign: 'initial' }}
                                >
                                  {updatePasswordEmailSent?.responseMessage}
                                </div>
                                <div className=" mt-2">
                                  <NavLink
                                    to="/signin"
                                    className={`orange-text ${classes.loginReturnLink}`}
                                    onClick={() => {
                                      this.props.updatePasswordEmailSend(
                                        passwordUpdate
                                      );
                                    }}
                                  >
                                    <span className="nav-text orange-text">
                                      {globalMessages.returnMessage}{' '}
                                    </span>
                                  </NavLink>
                                </div>
                              </div>
                            </CardContent>
                          </Card>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
          <ProcessFooter />
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ auth, passwordSettings }) => {
  const { updatingPassword, updatePasswordEmailSent } = passwordSettings;
  const { authUser } = auth;
  return {
    updatingPassword,
    authUser,
    updatePasswordEmailSent,
  };
};

export default connect(mapStateToProps, {
  setInitUrl,
  sendForgotPasswordLink,
  updatePasswordEmailSend,
})(withStyles(styles)(withGoogleReCaptcha(ForgotPassword)));
