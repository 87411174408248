import { ORGANIZATION_DETAILS } from 'constants/organization-details';
import * as types from '../../constants/action-types';

const initState = () => ({
  orgDetails: ORGANIZATION_DETAILS,
  orgDetailsLoader: false,
  transitionLoginSuccess: 0,
  transitionGetAgentSuccess: 0,
});

export default (state = initState(), action) => {
  switch (action.type) {
    case types.SET_SIGNUP_TRANSITION_LOGIN_SUCCESS: {
      return {
        ...state,
        transitionLoginSuccess: action.payload,
      };
    }
    case types.SET_SIGNUP_TRANSITION_GET_AGENT_SUCCESS: {
      return {
        ...state,
        transitionGetAgentSuccess: action.payload,
      };
    }

    default:
      return state;
  }
};
