import { all, fork, put, takeEvery } from 'redux-saga/effects';
import axios from 'util/api';
import * as myPolicyActions from 'constants/action-types';
import { errorHandler } from '../actions/error';
import { ENTITY_POLICY } from 'constants/constant';

function* fetchAgentPolicyDetails({ payload: { producer_id }, callback }) {
  try {
    const details = yield axios.post(`/api/v1/producer/agent-policies`, {
      producer_id,
    });
    callback(details?.data?.polcies);
  } catch (error) {
    callback([]);
    yield put(errorHandler(error));
  }
}

export function* fetchAgentPolicyDetailsWatcher() {
  yield takeEvery(
    myPolicyActions.FETCH_AGENT_POLICIES,
    fetchAgentPolicyDetails
  );
}

function* updateMyPolicyNotificationVisibility({
  payload: { id, policyList },
  callback,
}) {
  try {
    let updatedMyPolicyList = policyList;
    const updateIndicator = yield axios.post(
      'api/v1/quote/update-notification',
      {
        id: id,
        entity: ENTITY_POLICY,
      }
    );
    if (updateIndicator.data.success) {
      let updatedMyPolicyIndex = updatedMyPolicyList.findIndex(
        item => item.id === id
      );
      updatedMyPolicyList[
        updatedMyPolicyIndex
      ].displayPolicyNofificationIndicator = false;
      callback(updatedMyPolicyList);
    } else {
      updatedMyPolicyList(policyList);
    }
  } catch (error) {
    yield put(errorHandler(error));
  }
}

export function* updateMyPolicyNotificationIndicator() {
  yield takeEvery(
    myPolicyActions.UPDATE_MY_POLICY_NOTIFICATION_INDICATOR,
    updateMyPolicyNotificationVisibility
  );
}

// ------ ROOT SAGA -----------------
export default function* rootSaga() {
  yield all([
    fork(fetchAgentPolicyDetailsWatcher),
    fork(updateMyPolicyNotificationIndicator),
  ]);
}
