/* eslint-disable */
// @ts-check
import { applyMiddleware, compose, createStore } from 'redux';
import reducers from '../reducers/index';
import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';
import createSagaMiddleware from 'redux-saga';
import rootSaga from '../saga';
import { persistanceStateConfig, persistedState } from '../persist';
import * as authActionType from '../../constants/action-types';
// TODO : import { updateImpersonateMode } from 'redux/middleware/impersonateInterceptor';

const history = createBrowserHistory();
const routeMiddleware = routerMiddleware(history);
const sagaMiddleware = createSagaMiddleware();

const middlewares = [sagaMiddleware, routeMiddleware];
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

function configureStore() {
  // on user signout needs to reset the redux store.
  const resetEnhancer = rootReducer => (state, action) => {
    if (action.type !== authActionType.SIGNOUT_USER_SUCCESS)
      return rootReducer(state, action);
    sessionStorage.removeItem('state');
    const newState = rootReducer(undefined, {});
    newState.router = state.router;
    return newState;
  };

  const store = createStore(
    resetEnhancer(reducers(history)),
    persistedState,
    composeEnhancers(applyMiddleware(...middlewares))
  );

  sagaMiddleware.run(rootSaga);

  // start listening to the store
  persistanceStateConfig(store);

  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('../reducers/index', () => {
      const nextRootReducer = require('../reducers/index');
      store.replaceReducer(nextRootReducer);
    });
  }
  return store;
}

const store = configureStore();
export { history, store };
