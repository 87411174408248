import * as types from '../../constants/action-types';

const initState = () => ({
  apiSuccess: false,
  isAppointmentDialogOpen: false,
  isSuccessDialogOpen: false,
});

export default (state = initState(), action) => {
  switch (action.type) {
    case types.SET_CARRIER_APPOINTMENT_REQUEST_SUCCESS: {
      return {
        ...state,
        apiSuccess: true,
        isAppointmentDialogOpen: false,
        isSuccessDialogOpen: true,
      };
    }
    case types.SET_CARRIER_APPOINTMENT_REQUEST_FAILED: {
      return {
        ...state,
        apiSuccess: false,
        isAppointmentDialogOpen: true,
        isSuccessDialogOpen: false,
      };
    }
    default:
      return state;
  }
};
