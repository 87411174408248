// @ts-check

// 🚨 Until those configs are migrated into a separate package, or dual app setup is removed
// 🚨 part of auth0-related code is duplicated between old and new FE repositories.
// 🚨 Any changes to this file need to be reflected in the new FE.

import {
  AUTH_FLOW_PASSWORDLESS_ENABLED,
  AUTH_FLOW_PASSWORDLESS_REMEMBER_ME,
} from './auth0.constants';
import { getAuth0SpaClient } from './auth0-spa-client';
import { persistTokenInLocalStorage } from './persist-token';

export const getIsPasswordlessFlow = () => {
  return localStorage.getItem(AUTH_FLOW_PASSWORDLESS_ENABLED) === 'true';
};

export const getIsPasswordlessRememberMe = () => {
  return localStorage.getItem(AUTH_FLOW_PASSWORDLESS_REMEMBER_ME) === 'true';
};

export const logAuthError = (msg) => {
  if (
    msg?.error === 'invalid_grant' &&
    msg?.error_description
      ?.toLowerCase()
      ?.includes('unknown or invalid refresh token')
  ) {
    // Standard error on expired session - ignore
    return;
  }

  console.error(`AUTH0 ERROR: ${JSON.stringify(msg)}`);
};

export const getAuth0SpaToken = async () => {
  const isAuthenticated = await getAuth0SpaClient().isAuthenticated();

  if (!isAuthenticated) {
    return null;
  }

  try {
    const token = await getAuth0SpaClient().getTokenSilently();

    if (token) {
      persistTokenInLocalStorage(token);
      return token;
    } else {
      throw new Error('getAuth0SpaToken: No Token received from Auth0 client.');
    }
  } catch (error) {
    logAuthError(error);
  }

  return null;
};
