// @ts-check
import { useEffect } from 'react';

const MOBILE_WIDTH = 600;
const AUTOCOMPLETE_HEIGHT = 180;
const AUTOCOMPLETE_SELECTOR = '.pac-container';
const AUTOCOMPLETE_MODIFIER_CLASS = 'pac-container-translated';
const ADDRESS_INPUT_SELECTOR = '#address';
const FULL_STORY_EXCLUDE_CLASS = 'fs-exclude';

/**
 * @param {HTMLDivElement | null} autocompleteElement
 * @param {HTMLInputElement | null} addressInput
 * @param {VisualViewport} visualViewport
 * @returns {void}
 */
function setAutocompletePosition(
  autocompleteElement,
  addressInput,
  visualViewport
) {
  if (!(autocompleteElement && addressInput)) {
    return;
  }

  const { y: inputY } = addressInput.getBoundingClientRect();
  const autocompleteVerticalFootprint = inputY + AUTOCOMPLETE_HEIGHT;

  if (visualViewport.height < 0.95 * autocompleteVerticalFootprint) {
    autocompleteElement.classList.add(AUTOCOMPLETE_MODIFIER_CLASS);
  } else {
    autocompleteElement.classList.remove(AUTOCOMPLETE_MODIFIER_CLASS);
  }
}

export function useAutocompletePosition() {
  const handleVisualViewportChange = (event) => {
    /** @type {HTMLDivElement | null} */
    const autocompleteElement = document.querySelector(AUTOCOMPLETE_SELECTOR);
    /** @type {HTMLInputElement | null} */
    const addressInput = document.querySelector(ADDRESS_INPUT_SELECTOR);
    setAutocompletePosition(autocompleteElement, addressInput, event.target);
  };

  useEffect(() => {
    if (window.innerWidth <= MOBILE_WIDTH) {
      window.visualViewport?.addEventListener(
        'resize',
        handleVisualViewportChange
      );
    }

    return () => {
      window.visualViewport?.removeEventListener(
        'resize',
        handleVisualViewportChange
      );
    };
  }, []);
}

/**
 * @param {string} inputValue
 * @return {void}
 */
export function useAddFullStoryExcludeClass(inputValue) {
  useEffect(() => {
    /** @type {NodeListOf<Element>} */
    const autocompleteElements = document.querySelectorAll(AUTOCOMPLETE_SELECTOR);

    if (autocompleteElements.length === 0) {
      return;
    }

    autocompleteElements.forEach(element => {
      if (inputValue && inputValue.length > 0) {
        element.classList.add(FULL_STORY_EXCLUDE_CLASS);
      }
    });

    return () => {
      autocompleteElements.forEach(element => {
        element.classList.remove(FULL_STORY_EXCLUDE_CLASS);
      });
    };
  }, [inputValue]);
}
