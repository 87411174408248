import * as types from '../../constants/action-types';

const initState = () => ({
  isNewDashboardEnabled: null,
  isMarketFinderEnabled: null,
  isCanopyConnectEnabled: null,
  isLeadFeedEnabled: null,
  isAgentToolsEnabled: null,
  isNewCarrierStoreEnabled: null,
});

export default (state = initState(), action) => {
  switch (action.type) {
    case types.SET_IS_NEW_DASHBOARD_ENABLED: {
      return {
        ...state,
        isNewDashboardEnabled: action.payload,
      };
    }
    case types.SET_IS_CANOPY_CONNECT_ENABLED: {
      return {
        ...state,
        isCanopyConnectEnabled: action.payload,
      };
    }
    case types.SET_IS_LEAD_FEED_ENABLED: {
      return {
        ...state,
        isLeadFeedEnabled: action.payload,
      };
    }
    case types.SET_IS_AGENT_TOOLS_ENABLED: {
      return {
        ...state,
        isAgentToolsEnabled: action.payload,
      };
    }
    case types.SET_IS_NEW_CARRIER_STORE_ENABLED: {
      return {
        ...state,
        isNewCarrierStoreEnabled: action.payload,
      };
    }
    default:
      return state;
  }
};
