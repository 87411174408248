import '../fonts/style.scss';

export * from 'components/consent-text-modal/constants';
export const ALL = 'all';
export const ACTIVE = 'active';
export const CANCELLED = 'cancelled';
export const COMPLIANCE = 'compliance';
export const BANKING_INFO = 'bankingInfo';
export const DORMANT = 'dormant';
export const PENDING = 'pending';
export const RESTRICTED = 'restricted';
export const SUSPEND = 'suspend';
export const TERMINATED = 'terminated';
export const CANCEL = 'cancel';
export const RESTRICT = 'Restrict';
export const DEVELOPMENT = 'development';

export const AGREEMENT = 'agreement';
export const PDF = 'pdf';
export const INSTRUCTION_DOCUMENT = 'instruction';
export const APPOINTED_DOCUMENT = 'appointment';

// Carrier onboard column
export const CARRIER_NAME = 'carrier_name';
export const POC_NAME = 'poc_name';
export const IS_ENABLE = 'is_enable';
export const UPDATED_BY = 'updated_by';
export const UPDATED_AT = 'updated_at';
export const STATUS = 'status';
export const DISPLAY_NAME = 'display_name';
export const SETTIGS_COMMISSION_PAID_TO = 'settings.commission_paid_to';
export const PRODUCER = 'producer';
export const EARNED = 'Earned';

// Add new carrier input field title
export const CARRIER_NAME_INPUT = 'carrierName';
export const AGENT_COMMISSION_PAID = 'agentCommissionPaid';
export const POC_NAME_INPUT = 'pocName';
export const CARRIER_URL = 'carrierUrl';
export const CARRIER_PORTAL_URL = 'carrierPortalUrl';
export const CARRIER_PORTAL_URL_SUBAGENT = 'carrierPortalUrlSubagent';
export const HUBSPOT_APPOINTMENT_CODE = 'appointmentRequestCode';
export const PHONE = 'phone';
export const POC_USERNAME = 'pocUsername';
export const EMAIL = 'email';
export const CARRIER_PASSWORD = 'carrierPassword';
export const TYPE_TEXT = 'text';
export const TYPE_PASSWORD = 'password';
export const ENABLE_ON_WEBSITE_INPUT = 'enableOnWebsite';
export const LINKED_TO_CARRIER_PORTAL = 'linkedToCarrierPortal';
export const ALLOWED_FOR_WHOLESALER = 'allowedForWholesaler';
export const COMMISSIO_PAID_TO_INPUT = 'commissionPaidTo';
export const HAS_SSO = 'hasSSO';
export const ADD_CARRIER_FORM_ID = 'myform';
export const SUBMIT = 'submit';
export const PRIMARY = 'primary';
export const H6 = 'h6';
export const MEDIUM = 'md';
export const COMMISION_LIST = [
  {
    default: true,
    id: 1,
    name: 'Organization',
    value: 'Organization',
  },
  {
    default: false,
    id: 2,
    name: 'Direct to Producer',
    value: 'Direct to Producer',
  },
];
export const BOOLEAN_ARRAY = [
  {
    default: true,
    id: 2,
    name: 'Yes',
    value: true,
  },
  {
    default: false,
    id: 3,
    name: 'No',
    value: false,
  },
];

export const STATUS_VALUE = [
  {
    default: true,
    id: 2,
    name: 'Active',
    value: true,
  },
  {
    default: false,
    id: 3,
    name: 'Inactive',
    value: false,
  },
];
// boolean array value
export const BOOLEAN_ARRAY_VALUE = [
  {
    default: false,
    id: 1,
    name: 'Yes',
    value: true,
  },
  {
    default: true,
    id: 2,
    name: 'No',
    value: false,
  },
];
// Update Carrier Info
export const EDIT_CARRIER_FORM_ID = 'myform';
// License Info Update Strings
export const BUSINESS = 'business';
export const FIRM = 'FIRM';
export const INDIVIDUAL = 'individual';
export const TRANSPARENT = 'transparent';
export const ACTIVE_STATUS = 'Active';
export const INACTIVE_ACTIVE = 'Inactive';
export const YES = 'Yes';
export const NO = 'No';
export const ORGANIZATION = 'Organization';
export const ALREADY_EXIST = 'Already exist';
export const INVALID = 'Invalid';
export const INVALID_FILE_TYPE = 'Invalid file type';
export const REQUIRED = 'Required';
export const CATEGORY = 'Category';
export const EMAIL_CONFLICT = 'emailConflict';
export const EMAIL_ALREADY_USED = 'An account already exists for this email';
export const PASSWORD_DO_NO_MATCHED = 'Passwords do not match.';
export const SAME_PASSWORD = 'New password is same as old password.';
export const OLD_PASSWORD = 'oldPassword';
export const NEW_PASSWORD = 'newPassword';
export const CONFIRM_PASSWORD = 'confirmPassword';
export const SAVE = 'save';
export const OBJECT = 'object';
export const UPDATE_SUCCESS_RESPONSE = 'updated successfully';
export const STATE_COLUMN = 'State';
export const AGENT_COLUMN = 'Agent';
export const ACTION_COLUMN = 'Action';
export const INCORRECT_EMAIL = 'Email is incorrect';
export const INCORRECT_PHONE_NUMBER = 'Phone number is incorrect';
// Commission Paid on
export const COMMISION_PAID_ON = [
  {
    default: true,
    id: 1,
    name: 'Earned',
    value: 'Earned',
  },
  {
    default: false,
    id: 2,
    name: 'Collected',
    value: 'Collected',
  },
  {
    default: false,
    id: 3,
    name: 'Written',
    value: 'Written',
  },
];

export const NOT_APPLICABLE = 'Not Applicable';

export const ALL_STATES = 'All States';
export const CARRIER_ADD_PRODUCT = 'Carrier Add Product';

// US state
export const usStateArray = [
  'AK',
  'AL',
  'AR',
  'AZ',
  'CA',
  'CO',
  'CT',
  'DC',
  'DE',
  'FL',
  'GA',
  'HI',
  'IA',
  'ID',
  'IL',
  'IN',
  'KS',
  'KY',
  'LA',
  'MA',
  'MD',
  'ME',
  'MI',
  'MN',
  'MO',
  'MS',
  'MT',
  'NC',
  'ND',
  'NE',
  'NH',
  'NJ',
  'NM',
  'NV',
  'NY',
  'OH',
  'OK',
  'OR',
  'PA',
  'RI',
  'SC',
  'SD',
  'TN',
  'TX',
  'UT',
  'VA',
  'VT',
  'WA',
  'WI',
  'WV',
  'WY',
];

export const SUCCESS = 'success';
export const ERROR = 'error';

// Agent adhoc report
export const PRODUCER_CODE = 'producerCode';
export const AGENCY_NAME = 'agencyName';
export const STATE = 'state';
export const AMOUNT = 'amount';
export const PROCESSED_AT = 'processedAt';
export const COMMENT = 'comment';
export const HTML_EM = '--';
export const DOLLAR_ZERO = '$0';
export const ADMIN = 'admin';
export const SUPER_ADMIN = 'super-admin';
export const ADHOC_STATEMENT_TITLE = 'title';
export const ERROR_MESSAGE = 'Someting Went Wrong';
export const STATIC_ASSETS = 'static-assets';

// roles table field
export const ROLE_NAME = 'roleName';
export const ROLE_CATEGORY = 'roleCategory';
export const ROLE_CODE = 'roleCode';
export const USER_COUNT = 'userCount';
export const CREATED_AT = 'createdAt';
export const CREATED_BY = 'createdBy';
export const VIEW = 'view';
export const DELETE = 'delete';
export const ROLES_TABLE_HEADING = 'Roles';
export const ADD_ROLES_FORM_ID = 'addRolesForm';
export const DESCRIPTION = 'description';

// Permission user module
export const PERMISSION_ARRAY = ['READ', 'WRITE', 'DELETE'];
export const FIRST_NAME = 'firstName';
export const LAST_NAME = 'lastName';
export const ROLE = 'role';
export const LICENSED_UNDER_AGENCY = 'licensedUnderAgency';
export const CREATED_DATE = 'createdDate';
export const USERS = 'Users';
export const ADD_USER_FORM_ID = 'addUserForm';
export const INACTIVE_STATUS = 'Inactive';
export const PENDING_STATUS = 'Pending';

export const COMMISION_TYPE = [
  {
    default: true,
    id: 1,
    name: 'Percentage',
    value: 'Percentage',
  },
  {
    default: false,
    id: 2,
    name: 'Fixed',
    value: 'Fixed',
  },
];
// Module and Sub modules
export const AGENT_DIRECTORY = 'AGENT-DIRECTORY';
export const AGENT_DIRECTORY_ALL = 'AGENT-DIRECTORY-ALL';
export const AGENT_DIRECTORY_ACTIVE = 'AGENT-DIRECTORY-ACTIVE';
export const AGENT_DIRECTORY_PENDING = 'AGENT-DIRECTORY-PENDING';
export const AGENT_DIRECTORY_RESTRICTED = 'AGENT-DIRECTORY-RESTRICTED';
export const AGENT_DIRECTORY_SUSPEND = 'AGENT-DIRECTORY-SUSPEND';
export const AGENT_DIRECTORY_DORMANT = 'AGENT-DIRECTORY-DORMANT';
export const AGENT_DIRECTORY_TERMINATED = 'AGENT-DIRECTORY-TERMINATED';
export const AGENT_DIRECTORY_INCOMPLETE_SIGNUP =
  'AGENT-DIRECTORY-INCOMPLETE-SIGNUP';
export const AGENT_DIRECTORY_ERROR_AND_OMMISSION =
  'AGENT-DIRECTORY-ERROR-AND-OMMISSION';
export const AGENT_DIRECTORY_NPN = 'AGENT-DIRECTORY-NPN';
export const AGENT_DIRECTORY_W9 = 'AGENT-DIRECTORY-W9';
export const AGENT_DIRECTORY_REGULATORY_ACTION =
  'AGENT-DIRECTORY-REGULATORY_ACTION';
export const COMMISSIONS = 'COMMISSIONS';
export const CARRIERS = 'CARRIERS';
export const ADHOC_PAYMENTS = 'ADHOC-PAYMENTS';
export const REPORTS = 'REPORTS';
export const MONTHLY_COMMISSION_REPORT = 'MONTHLY_COMMISSION_REPORTS';
export const AGENT_ONBOARDING_REPORTS = 'AGENT-ONBOARDING-REPORTS';
export const AGENT_SYNC_REPORTS = 'AGENT-SYNC-REPORTS';
export const QUICKBOOKS_EXTRACT = 'QUICKBOOKS-EXTRACT';
export const FAST_START_REPORT = 'FAST-START-REPORT';
export const INCENTIVE_REPORT = 'INCENTIVE-REPORT';
export const REWARDS_PROGRAM_REPORT = 'REWARDS-PROGRAM-REPORT';
export const APPOINTMENT_FAILURE_REPORT = 'APPOINTMENT-FAILURE-REPORT';
export const MOTION_AUTO_DOCUMENT = 'MOTION-AUTO-DOCUMENT';
export const CARRIER_LOB_MARKETS_REPORT = 'CARRIER-LOB-MARKETS-REPORT';
export const ROLES = 'ROLES';
export const DASHBOARD = 'DASHBOARD';
export const MY_COMMISSION = 'MY-COMMISSION';
export const MY_ACCOUNT = 'MY-ACCOUNT';
export const MY_POLICIES = 'MY-POLICIES';
export const UPCOMING_RENEWALS = 'UPCOMING-RENEWALS';
export const TOTAL_POLICY_TILL_DATE = 'TOTAL-POLICY-TILL-DATE';
export const TOTAL_PREMIUM_TILL_DATE = 'TOTAL-PREMIUM-TILL-DATE';
export const POLICY_BY_CARRIER = 'POLICY-BY-CARRIER';
export const AGENT_PROFILE = 'AGENT-PROFILE';
export const ENO_INFORMATION = 'ENO-INFORMATION';
export const LICENSE = 'LICENSE';
export const W9_INFORMATION = 'W9-INFORMATION';
export const BANK_INFORMATION = 'BANK-INFORMATION';
export const BANKING_INFO_MODAL = 'BANKING_INFO_MODAL';
export const ADD_TEAM_MEMBERS_FORM = 'ADD_TEAM_MEMBERS_FORM';
export const EO_MODAL = 'EO_MODAL';
export const DOCUMENTS = 'DOCUMENTS';
export const SUB_AGENT = 'SUB-AGENT';
export const COMMISSION_INCENTIVE_YTD = 'COMMISSION-INCENTIVE-YTD';
export const PRODUCTION_ACCESS = 'PRODUCTION-ACCESS';
export const RESOURCE_CENTER = 'RESOURSE-CENTER';
export const FLOOD_RATER = 'FLOOD-RATER';
export const TEND_HOME = 'TEND-HOME';
export const PORTAL_SETTINGS = 'PORTAL-SETTINGS';
export const PRODUCTS_MENU = 'PRODUCTS';
export const ADMIN_USERS_MENU = 'ADMIN-USERS';
export const LOA_FILTERS = 'LOA-FILTERS';
export const INCENTIVE_BANNERS = 'INCENTIVE-BANNERS';
export const BULK_APPOINTMENTS = 'BULK-APPOINTMENTS';
export const AGENT_MESSAGING = 'AGENT-MESSAGING';
export const MODULE = 'module';
export const SUB_PRODUCER = 'sub-producer';
export const DIRECTAGREEMENT = 'directAgreement';
export const PERCENTAGE = 'Percentage';
export const MAX_COMMISSION_PERCENTAGE_ALLOWED = 999;
export const LICENSE_FIELD = 'license';
export const DATE_FIELD = 'date';
export const STATE_DATA = 'stateData';
export const LOA = 'loa';
export const LICENSE_TYPE = 'licenseType';
export const IS_PRIMARY = 'isPrimary';
export const MM_DD_YYYY = 'MM/DD/YYYY';
export const DATE_FORMAT = 'MM/dd/yyyy';
export const ADD = 'add';
export const EDIT = 'edit';
export const APPROVE = 'APPROVE';
export const REJECT = 'REJECT';
export const COMMISSION_STATEMENT = 'Commission Statement';
export const MONTH = 'month';
export const OUTPUT_FILES = 'outputFiles';
export const ADHOC_PAYMENTS_TITLE = 'Ad hoc Payments';
export const TITLE = 'title';
export const UPLOAD_FILE = 'uploadFile';
export const UN_SUCCESSFUL = 'unsuccessful';
export const UPLOAD_ICON = 'uploadIcon';
export const IN_PROCESS = 'In Process ';
export const PROCESSED_DATE = 'processedDate';
export const ACTION = 'action';
export const DOWNLOAD = 'download';
export const STEP_1 = 'step1';
export const STEP_2 = 'step2';
export const STEP_3 = 'step3';
export const STEP_4 = 'step4';
export const STEP_5 = 'step5';
export const TAX = 'Tax';
export const CITY = 'city';
export const ADDRESS = 'address';
export const TAX_STATUS = 'taxStatus';
export const TAX_NAME = 'taxName';
export const TAX_ID = 'taxId';
export const SSN_ID = 'SSN';
export const FEIN_ID = 'FEIN';
export const TAX_FILE_URL =
  'https://d2wwjzitjgv7lg.cloudfront.net/static-assets/fw9.pdf';
export const SELECT = 'select';
export const REGISTERED_MAILING_ADD = 'registeredAddMailingAdd';
export const PERSON_AUTHORIZING = 'personAuthorizing';
export const RADIO_TYPE = 'radio';
export const ZIP_CODE = 'zipCode';
export const AGENCY_NPN = 'businessNPN';
export const INDIVIDUAL_NPN = 'individualNPN';
export const SINGLE_MEMBER_LLC = 'single member llc';
export const W9_FILE_UPLOAD = 'w9FileUpload';
export const BUTTON_TYPE = 'button';
export const NEXT = 'Next';
export const ZERO_4 = '0000';
export const ZERO_5 = '00000';
export const PRODUCERS = 'producers';
export const W9 = 'w9';
export const NPN = 'npn';
export const CODE = 'code';
export const NAME = 'name';
export const BANK_ACCOUNT_ALREADY_EXIST = 'Bank account number already exist';
export const EMAIL_ALREDY_EXIST = 'Email already exist';
export const PHONE_ALREADY_EXIST = 'Phone number already exists.';
export const BANK_NAME = 'bankName';
export const BANK_ACCOUNT_NUMBER = 'bankActNumber';
export const BANK_TRANSIT_NUMBER = 'bankTransitNumber';
export const FULL_NAME = 'fullName';
export const FUNCTION = 'function';
export const PHONE_NUMBER = 'phoneNumber';
export const AGENT_EMAIL = 'agentemail';
export const AGENT_STATUS = 'agentStatus';
export const SELECT_KEY = 'Select';
export const ACCOUNT_TYPE = 'accountType';
export const TAX_ID_KEY = 'taxID';
export const TAXINFOEDIT = 'TaxInfoEdit';
export const ENOINFOEDIT = 'EnOInfoEdit';
export const PRIMARY_CONTACT = 'primaryContact';
export const ACTIVATED_AT = 'activatedAt';
export const IN_COMPLETE = 'incomplete';
export const IN_COMPLIANCE = 'incompliance';
export const IN_ACTIVE = 'in active';
export const PHONE_NUMBER_KEY = 'phone_number';
export const AGENT_DIRECTORY_TITLE = 'Agent Directory';
export const ENO_INFORMATION_FILE = 'eandoFile';
export const EXPIRATION_DATE = 'expirationDate';
export const SMALL = 'small';
export const TOP = 'top';
export const AGGREGATE_LIMIT = 'aggregateLimit';
export const INSURANCE_CARRIER = 'insuranceCarrier';
export const BUISSNESS_LICENSE_STATE = 'businessLicenseState';
export const PRIMARY_BUSINESS_LICENSE = 'primaryBusinessLicense';
export const BUISSNESS_LICENSE_NUMBER = 'businessLicenseNumber';
export const LICENSE_STATE = 'licenseState';
export const PRIMARY_INDIVIDUAL_LCENSE = 'primaryIndividualLicense';
export const LICENSE_NUMBER = 'licenseNumber';
export const LICENSE_AND_ENO = 'licenseAndEnO';
export const ENO_INFO = 'enoInfo';
export const INPUT = 'input';
export const AGGREGATE_LIMIT_ERROR = 'Minimum should be $1,000,000';
export const DAYS = 'days';
export const MUST_BE_FUTURE_DATE = 'Must be a future date';
export const INVALID_DATE_FORMAT = 'Invalid Date Format';
export const EO = 'eo';
export const STEP_7 = 'step7';
export const AGREEMENT_DATA = 'agreementData';
export const BY = 'by';
export const SUCCESSFUL = 'successful';
export const CARRIER = 'carrier';
export const FILE_NAME = 'fileName';
export const INSERTED_RECORDS = 'insertedRecords';
export const VIEW_FILE = 'viewFile';
export const DELETE_FILE = 'deleteFile';
export const CHART = 'chart';
export const PAID = 'paid';
export const COMMISSION_FILE_FORMAT = 'commissionFileFormat';
export const BANK_INFORMATION_KEY = 'bankInformation';
export const AGENCY_PROFILE = 'agencyProfile';
export const TAX_KEY = 'tax';
export const BANK_ACCOUNT = 'bankaccount';
export const E_AND_O = 'e&o';
export const FILE = 'file';
export const COMMISSION_REPORT = 'Commission Report';
export const SERIAL_NUMBER = 'serialNumber';
export const TOTAL_PAID = 'totalPaid';
export const FILE_URL = 'fileUrl';
export const ZERO = '0';
export const PRIMARY_INDIVIDUAL_LICENSE = 'Primary Individual License';
export const BASIC = 'Basic';
export const ADVANCE = 'Advance';
export const FIELD = 'field';
export const TYPE = 'type';
export const VALUE = 'value';
export const OPERATOR = 'operator';
export const TWFG_SUB_PRODUCER = 'twfg-sub-producer';
export const SECURITY_RESPONSE = 'securityResponce';
export const PLEASE_SELECT_SECURITY_QUESTION =
  'Please select a security question';
export const PLEASE_ENTER_SECURITY_RESPONSE = 'Please enter security response';
export const OWNER_FIRST_NAME = 'owner-first-name';
export const OWNER_LAST_NAME = 'owner-last-name';
export const AGENCY_LEGAL_NAME = 'agency-legal-name';
export const AGENCY_LEGAL_NAME_KEY = 'agencyLegalName';
export const AGNCY_DBA_NAME_KEY = 'agencyDbaName';
export const AGENCY_DBA_NAME = 'agency-dba-name';
export const ZIP_CODE_KEY = 'zip-code';
export const POC_EMAILS_PLACEHOLDER = 'Type and press enter to add email';
export const OFF = 'off';
export const SUB_PRODUCER_AGENCY = 'subProducerAgency';
export const SECURITY_QUESTION = 'securityQuestion';
export const SECU_RESPONSE = 'secu-response';
export const TYPE_CHECKBOX = 'checkbox';
export const IS_CHECKED = 'isChecked';
export const BANK_NAME_ACCOUNT = 'bankNameAccount';
export const IP_ADDRESS = '192.186.0.110';
export const PRODUCER_KEY = 'PRODUCER';
export const SUB_PRODUCER_KEY = 'SUB_PRODUCER';
export const ALTERNATE_SUB_PRODUCER = 'ALTERNATE_SUB_PRODUCER';
export const STRING = 'string';
export const PRODUCER_EMAIL_KEY = 'producerEmail';
export const PRODUCER_CODE_KEY = 'producer_code';
export const PRODUCER_ID_KEY = 'producer_id';
export const IS_WHOLE_SALER = 'is_wholesaler';
export const CREATED_AT_KEY = 'created_at';
export const PRODUCER_COMPLIANCE_STATUS = 'producer_compliance_status';
export const POLICY_COUNT = 'Policy Count';
export const agentStatus = {
  all: ALL,
  active: ACTIVE,
  pending: PENDING,
  incomplete: IN_COMPLETE,
  incompliance: IN_COMPLIANCE,
  inactive: IN_ACTIVE,
  cancel: CANCEL,
  restricted: RESTRICTED,
  suspend: SUSPEND,
  dormant: DORMANT,
  terminated: TERMINATED,
};
export const INCOMPLETE_SIGNUP = 'incompleteSignup';
export const BASIC_SEARCH_LABEL = 'Basic Search';
export const AGENCY_NAME_KEY = 'agency_name';
export const PRIMARY_CONTACT_KEY = 'primary_contact';
export const LICENSE_AGENT_LIST = 'licenseAgentList';
export const LICENSE_REVIEW = 'License Review';
export const ENO = 'eno';
export const ERROR_AND_OMISSION = 'errorOmission';
export const REGULATORY_ACTION = 'regulatoryAction';
export const W9_DOCUMENT = 'wDoc';
export const LIMIT_LABEL = 'limit';
export const EXPIRY_DATE_LABEL = 'exp_date';
export const DOCUMENT_LABEL = 'document';
export const ENO_LIST = 'errorOmissionList';
export const TAX_AGENT_LIST = 'taxAgentList';
export const NPN_AGENT_LIST = 'npnAgentList';
export const REGULATORY_ACTION_LIST = 'regulatoryActionList';
export const REGULATORY_ACTION_API_COMPLIANCE_MODULE = 'regulatory_action';
export const LIST_LABEL = 'list';
export const AGREEMENT_CONTENT_AGENCY_NAME = '{{agency_name}}';
export const ENTER_KEY = 'Enter';
export const TABLE_NAME_KEY = 'table_name';
export const CARRIER_PRODUCER_CODE = 'carrier_producer_code';
export const AGENT_TYPE = 'agent_type';
export const POC_EMAIL_LABEL = 'POC Email';
export const NAN = 'NaN';
export const TO = 'to';
export const NEEDS_APPROVAL = 'needsApproval';
export const ENO_STATUS = 'enoStatus';
export const NPN_STATUS = 'npnStatus';
export const W9_STATUS = 'w9Status';
export const NEEDS_APPROVAL_TITLE = 'Needs Approval';
export const TAX_INFO_CONTENT = 'This assists with carrier appointments.';
export const TAX_STATUS_INFO_CONTENT = 'Why do we need this?';
export const ACTIVATED_AT_KEY = 'activated_at';
export const OPTIONS = 'options';
export const PUT = 'put';
export const AUTHORIZATION = 'Authorization';
export const DBA = 'dba';
export const WHOLESALER = 'wholesaler';
export const EXCEL = 'excel';
export const IN_COMPLIANCE_KEY = 'IN_COMPLIANCE';
export const UPDATED_KEY = 'UPDATED';
export const LICENSE_CLASS_FIELD = 'licenseClass';
export const IS_MAILING_ADDRESS = 'isMailingAddressSame';
export const IS_FULL_TIME_AGENT = 'isFullTimeAgent';
export const MAILING_ADDRESS = 'mailingAddress';
export const MAILING_CITY = 'mailingCity';
export const MAILING_STATE = 'mailingState';
export const MAILING_ZIP_CODE = 'mailingZipCode';
export const AT_THE_RATE = '@';
export const OWNER_NAME = 'Owner Name';
export const AGENCY_NAME_TITLE = 'Agency Name';
export const PHYSICAL_ADDRESS = 'Physical Address';
export const MAILING_ADDRESS_TITLE = 'Mailing Address';
export const PRODUCER_CODE_TITLE = 'Producer Code';
export const CONTACT_ID = 'Contact';
export const COMPANY_ID = 'Company';
export const MEMBER_SINCE_TITLE = 'Member Since';
export const PUBLISHED_AT = 'publishedAt';
export const APPOINTED = 'appointed';
export const AGENCY_CODE = 'agencyCode';
export const MAPPING = 'mapping';
export const COMMISSION = 'commission';
export const APPOINTMENT_TYPE_SETTING = 'appointmentType';
export const INCENTIVE = 'incentive';
export const AGENCY_MANAGER = 'agency-manager';
export const PRINCIPAL_PRODUCER = 'principal-producer';
export const AGENCY_PRODUCER = 'agency-producer';
export const VIEW_AGENCY_PROFILE = 'View Agency Profile';
export const QUICK_BOOKS_EXTRACT_REPORT_DATE = '2019/01/01';
export const COMMISSION_REPORT_DATE = '2019/10/01';
export const REFER_A_FRIEND = 'Refer Friend';
export const AGENCY_COMMISSION = 'AGENCY-COMMISSION';
export const AGENCY_PROFILE_MENU = 'AGENCY-PROFILE';
export const BANK = 'bank';
export const LICENSE_LIST = 'licenseList';
export const QUOTES = 'Quotes';
export const COMPARATIVE_RATER = 'Comparative Rater';
export const APPLICANT_NAME = 'applicantName';
export const POLICY_TYPE = 'policyType';
export const MODIFIED_DATE = 'modifiedDate';
export const EFFECTIVE_DATE = 'effectiveDate';
export const SOURCE = 'source';
export const PARENT_AGENCY_NAME = 'Parent Agency Name';
export const WRITE_UNDER_AGENCY_LICENSE = 'writesUnderAgencyLicense';
export const DNE = 'dne';
export const HOUSE = 'HO3';
export const HO_5 = 'HO5';
export const CONDO = 'HO6';
export const PREMIUM = 'premium';
export const PLACEHOLDER_CHAR = '\u2000';
export const TOTAL_PREMINUM = 'Total Premium';
export const COLLECTED_PREMINUM = 'Collected Premium';
export const NO_OF_POLICIES = 'No. Of Policies';
export const COMMISSION_TO = 'Commission To ';
export const COMMISSION_TO_AGENT = 'Commission To Agent';
export const INCENTIVE_TO = 'Incentives To ';
export const INCENTIVE_TO_AGENT = 'Incentives To Agent';
export const GROSS = 'Gross';
export const NET = 'Net';
export const AGENT_ROLE = 'agentRole';
export const TEAM_MEMBERS = 'Team Members';
export const SETTINGS = 'Settings';
export const ADDITIONAL_PAYMENTS = 'Additional Payments';
export const ANALYTICS = 'Analytics';
export const QUOTE_CONNECTOR = 'Quote Connector';
export const PARENT = 'Parent';
export const AGENCY_NPN_LABEL = 'Agency NPN';
export const INDIVIDUAL_NPN_LABEL = 'Individual NPN';
export const NIPR_INDIVIDUAL = 'NIPR Result (Individual Name)';
export const NIPR_AGENCY = 'NIPR Result (Agency Name)';
export const COMMERCIAL_LINE = 'commercial-line';
export const AGENCY = 'Agency';
export const APPROXIMATE_TOTAL_PRODUCTION = 'approximateTotalProduction';
export const PRODUCTION_PERCENTAGE = 'productionPercentage';
export const IS_COMPARATIVE_RATER = 'isComparativeRater';
export const NAME_OF_RATER = 'nameOfRater';
export const IS_AGENCY_MANAGEMENT_SYSTEM = 'isAgencyManagementSystem';
export const NAME_OF_AMS = 'nameofAMS';
export const AGENCY_ESTABLISHED_YEAR = 'agencyEstablishedYear';
export const ABOUT_FIRSTCONNECT = 'aboutFirstconnect';
export const AGENCY_DETAILS = 'agencyDetails';
export const ADDITIONAL_AGENTS = 'additionalAgents';
export const SEARCH_ENGINE = 'Search Engine';
export const AGENT_REFFERAL = 'Agent Referral';
export const CARRIER_REFFERAL = 'Carrier Referral';
export const CONVENTION = 'Convention';
export const OTHERS = 'Others';
export const KNOW_ABOUT_FIRSTCONNECT_BY = 'knowAboutFirstconnectBy';
export const REFERRED = 'referred';
export const DECLINED = 'declined';
export const IN_PROGRESS = 'in progress';
export const APPROVED = 'approved';
export const REQUESTED = 'requested';
export const EXPIRED = 'expired';
export const MINIMAL_DATE = '1900/01/01';
export const MAXMIMAL_DATE_ERROR = 'Year should not be a future year';
export const BIND = 'Bind';
export const NA = 'N/A';
export const HIPPO = 'HIPPO';
export const GREAT_AMERICAN = 'great-american';
export const INVALID_YEAR = 'Invalid year';
export const APPROXIMATE_LIFE_AND_HEALTH_PRODUCTION =
  'approxLifeAndHealthProduction';
export const OTHER = 'Other';
export const INVALID_FILE_NAME = 'Invalid file name';
export const POST = 'post';
export const IMPERSONATED = 'impersonated';
export const IMPERSONATE = 'impersonate';
export const ENABLE_IMPERSONTE_MODE = 'Enable Impersonate Mode';
export const BUSINESS_NPN = 'BUSINESS_NPN';
export const INDIVIDUAL_NPN_TYPE = 'INDIVIDUAL_NPN';
export const IMPERSONATE_DATA = 'impersonateData';
export const PRODUCER_DASHBOARD = '/app/producer/dashboard';
export const APPLICATION_ID = 'applicationId';
export const NOTIFICATION_INDICATOR = 'displayNofificationIndicator';
export const RESOURCE_CENTER_LABEL = 'Resource Center';
export const AGENT_AGREEMENT = 'agentAgreement';
export const CONTACT_EMAIL = 'agentsupport@firstconnectinsurance.com';
export const CONTACT_PHONE = '877-287-6055';
export const POLICY_NUMBER = 'policyNumber';
export const CUSTOMER_NAME = 'customerName';
export const PAYMENT_FREQUENCY = 'paymentFrequency';
export const TERM_PREMIUM = 'termPremium';
export const MY_POLICIES_TITLE = 'My Policies';
export const AGENCY_POLICIES_TITLE = 'Agency Policies';
export const PENDING_APPROVAL = 'Pending Approval';
export const QUOTE_ID = 'quoteId';
export const NEW_QUOTE = 'New Quote';
export const ENTITY_QUOTE = 'quote';
export const ENTITY_POLICY = 'policy';
export const QUOTED_BY = 'quotedBy';
export const NEW_POLICY = 'New Policy';
export const PRODUCTS_TITLE = 'Products';
export const ADD_PRODUCT = 'Add Product';
export const PRODUCT_ID = 'productId';
export const DISPLAY_NAME_KEY = 'displayName';
export const AGENT_FACING_NAME = 'agentFacingName';
export const PRODUCT_IMAGE_URL = 'productImageUrl';
export const UPDATED_AT_KEY = 'updatedAt';
export const UPDATED_BY_KEY = 'updatedBy';
export const PRODUCT_NAME = 'Product Name';
export const AGENT_FACING_VALUE = 'Agent Facing Value';
export const PRODUCT_CODE = 'productCode';
export const ROOT_CATEGORY = 'Root Category';
export const TOP_CATEGORY = 'Top Category';
export const SECOND_LEVEL = 'Second Level';
export const UPLOAD_NEW_FILE = 'Upload File';
export const ADMIN_USER_TITLE = 'Admin Users';
export const INCENTIVE_BANNER = 'Incentive Banners';
export const BULK_APPOINTMENT = 'Bulk Appointments';
export const RUN_DATE = 'RUN_DATE';
export const ADD_APPOINTMENTS = 'Add Appointment';
export const ADD_ADMIN_USER = 'Add Admin User';
export const LOA_NAME = 'LOA Name';
export const LOA_CATEGORY = 'LOA Category';
export const LOA_LABEL_TITLE = 'loaLabel';
export const ADD_LOA = 'Add LOA';
export const ALL_CASE = 'All';
export const INDIVIDUAL_CASE = 'Individual';
export const ALL_INDIVIDUAL = 'ALL_INDIVIDUAL';
export const ALL_AGENCY = 'ALL_AGENCY';
export const INDIVIDUAL_CHANGE = 'INDIVIDUAL_CHANGE';
export const AGENCY_CHANGE = 'AGENCY_CHANGE';
export const DEFAULT_THEME = 'sky-blue';
export const W9_REVIEW = 'W9 Review';
export const NPN_REVIEW = 'NPN Review';
export const REGULATORY_ACTION_REVIEW = 'Regulatory Action Review';
export const ORGANIZATIONS = 'Organizations';
export const ORG_NAME = 'orgName';
export const ORG_CODE = 'orgCode';
export const ORG_SHORT_NAME = 'orgShortName';
export const ORG_URL = 'orgUrl';
export const ORG_THEME = 'orgTheme';
export const ORG_LOGO = 'orgLogo';
export const ORG_FAV_ICON = 'orgFavIcon';
export const ORG_FORM_NAME = 'orgFormName';
export const ORG_EMAIL_ADDRESS = 'orgEmailAddress';
export const ORG_SMALL_LOGO = 'orgSmallLogo';
export const ORGANIZATION_MEDIA_FOLDER_NAME = 'organization-media-temp';
export const LOGO = 'logo';
export const SMALL_LOGO = 'small-logo';
export const FAV_ICON = 'fav-icon';
export const CREDENTIALS = 'Credentials';
export const CARRIER_CREDENTIALS = 'Carrier Credentials';
export const CARRIER_CREDENTIAL_TABLE_TITLE =
  'Manage your Quote Connector carriers credentials';
export const CARRIER_LOGO = 'carrierLogo';
export const APPOINTMENT_TYPE = 'appointmentType';
export const APPOINTMENT_TYPE_LIST = [
  {
    key: 'direct',
    value: 'Direct',
  },
];
export const DIRECT = 'Direct';
export const MGA = 'MGA';
export const WHOLESALE_CREDENTIALS = 'Wholesaler Credentials';
export const PM_HIPPO = 'pm-hippo';
export const FC_ORGANIZATION_CODE = 'first-connect';
export const PM_ORGANIZATION_CODE = 'penny-mac';
export const AGENCY_DETAILS_KEY = 'AGENCY-DETAILS';
export const GATOR = 'Quoting Engine';
export const COMPLETED = 'Completed';
export const UPLOAD = 'Upload';
export const ADHOC_PAYMENT = 'Adhoc Payment';
export const WORKERS_COMP_DATA = {
  LOBs: [
    {
      code: 'WORKERS-COMP',
      display_name: "Worker's Comp",
      id: '84bf3a75-fac9-4f45-bea5-524fdcee2ccf',
      type_of_business: 'Commercial',
    },
  ],
};
export const DM_ORGANIZATION_CODE = 'doma';
export const ASTERISK_PASSWORD = '******';
export const IS_ACCESS_QC_ENABLED = 'hasQuotingEngineAccess';
export const CARRIER_CREDENTIALS_LOB_LIST = [
  {
    default: true,
    id: 1,
    value: null,
    name: 'All',
  },
  {
    default: false,
    id: 2,
    value: 'HOME',
    name: 'Home',
  },
  {
    default: false,
    id: 3,
    value: 'FLOOD',
    name: 'Flood',
  },
  {
    default: false,
    id: 4,
    value: 'AUTO',
    name: 'Auto',
  },
];
export const LOB_LABEL = 'LOB';
export const FREQUENCY = 'Frequency';
export const TARGET = 'Target';
export const INCENTIVE_DESCRIPTION = 'Description';
export const DATE_RANGE = 'Date Range';
export const LABEL_ACCESS_QC_ENABLED = 'Quoting Engine Enabled';
export const ASTERISK = '*';
export const POC_PHONE = 'pocPhone';
export const POC_EMAIL = 'pocEmail';
export const COMMISSION_PAID_TO = 'commisionPaidTo';
export const ENABLE_ON_SITE = 'enableOnSite';

export const HOVER_TEXT = 'hoverText';
export const QUOTE_ENGINE_CARRIER_ID = 'quotingEngineCarrierId';
export const LABEL = 'Label';
export const USERNAME_LABEL = 'Username';
export const PASSWORD_LABEL = 'Password';
export const IMG = 'img';
export const CENTER = 'center';
export const SUCCESSFULLY = 'successfully';
export const AVAILABLE_FOR_QUOTING = 'Available For Quoting';
export const ENABLE_CARRIER_PORTAL_LINK = 'Enable Carrier Portal Link';
export const COMMISSION_PAID_TO_TITLE = 'Commission Paid To';
export const ENABLE_ON_WEBSITE_TITLE = 'Enable On Website';
export const ALLOWED_FOR_WHOLESALE = 'Allowed For Wholesale';
export const MEMBERSHIP_DATE = 'membershipDate';
export const REPORTS_CHECKLIST = [
  {
    name: 'Active',
    value: 'Active',
    checked: true,
  },
  {
    name: 'Pending',
    value: 'Pending',
    checked: false,
  },
  {
    name: 'Restricted',
    value: 'Restricted',
    checked: false,
  },
  {
    name: 'Suspend',
    value: 'Suspend',
    checked: false,
  },
  {
    name: 'Dormant',
    value: 'Dormant',
    checked: false,
  },
];
export const FIELD_TYPES = {
  TEXT: 'Text',
  YES_NO: 'Yes/No',
  EMAIL: 'Email',
  PHONE_NUMBER: 'PhoneNumber',
};
export const CUSTOM_FIELD_STATUS = {
  ACTIVE: 'active',
  DELETED: 'deleted',
  INACTIVE: 'inactive',
};
export const APPROVAL_TYPE = {
  INSTANT: 'Instant',
  RAPID: 'Rapid',
  STANDARD: 'Standard',
};
export const REQUEST_PROCESS_STEPS = {
  FC_REQUEST_VALIDATION: 'FC Request Validation',
  SEND_REQUEST_TO_CARRIER: 'Send Request to Carrier',
  RESPONSE_FROM_CARRIER: 'Response from Carrier',
};
export const SEND_REQUEST_CARRIER_METHODS = {
  EMAIL: 'Email',
  FORM: 'Form',
  SHARED_SHEET: 'Shared Sheet',
};
export const RESPONSE_FROM_CARRIER_TYPE = {
  APPROVED: 'Approved',
  ADDITIONAL_REQUIREMENTS: 'Additional Requirements',
  REJECTED: 'Rejected',
  DECLINED: 'Declined',
};
export const CARRIER_APPOINTMENT_STATUS = {
  AVAILABLE: 'Available',
  REQUEST_PENDING: 'Request Pending',
  LICENSE_REQUIRED: 'License Required',
  APPOINTED: 'Appointed',
  REJECTED: 'Rejected',
};
export const CARRIER_APPROVAL_TYPE = {
  RAPID: 'rapid',
  INSTANT: 'instant',
};
export const COMMERCIAL_LINE_CODE = 'commercial_lines';
export const PERSONAL_LINE_CODE = 'personal_line';
export const GREAT_AMERICAN_LABEL = 'great american';
export const WORKERS_COMP_LABEL = `workers comp`;
export const CARRIER_LINES = {
  COMMERCIAL: 'Commercial',
  PERSONAL: 'Personal',
};
export const SHOW_HELP_TEXT_SWITCH = 'showHelpTextSwitch';
export const HELP_TEXT = 'helpText';
export const HELP_URL = 'helpUrl';
export const CARRIER_APPOINTMENTS = 'CARRIER-APPOINTMENTS';
export const CARRIER_FILE_TEMP_FOLDER = 'carrier-temp';
export const CREDENTIAL_TYPE = 'credentialType';
export const INTEGRATION_TYPE = 'integrationType';

export const CREDENTIAL_TYPE_ARRAY = [
  {
    name: 'Producer',
    value: 'Producer',
    default: true,
  },
  {
    name: 'MGA',
    value: 'MGA',
    default: false,
  },
  {
    name: 'Hybrid',
    value: 'Hybrid',
    default: false,
  },
];
export const INTEGRATION_TYPE_ARRAY = [
  {
    name: 'Aggregator',
    value: 'Aggregator',
    default: true,
  },
  {
    name: 'Direct',
    value: 'Direct',
    default: false,
  },
];

export const API = 'API';
export const ENTER_KEY_CODE = 13;
export const FILE_PATH_NULL_BYTE_CHECK = /(0x00)|(%00)/;
export const EMPTY_SPACE_ERROR = 'File name cannot be empty or white spaces';
export const FILE_NAME_ERROR =
  'File name cannot contain any special characters';
export const DUPICATE_FILE_ERROR = 'File name cannot be duplicated';
export const RENAME = 'rename';

export const POST_SIGNUP_ALLOWED_STEPS = {
  ALL_STEPS: [STEP_1, STEP_2, STEP_3, STEP_4, STEP_5, STEP_7],
  ALTERNATE_SUB_PRODUCER_PRODUCTION_ACCESS: [STEP_1, STEP_3, STEP_4],
  ALTERNATE_SUB_PRODUCER_NON_PRODUCTION_ACCESS: [STEP_1, STEP_3],
  SUB_PRODUCER_PRODUCTION_ACCESS: [STEP_1, STEP_4],
  SUB_PRODUCER_NON_PRODUCTION_ACCESS: [STEP_1],
  SUB_PRODUCER_PRODUCTION_ACCESS_WITH_AGENCY_LOGIN: [STEP_1],
  SUB_PRODUCER_PRINCIPAL_ACCESS: [STEP_1],
};

export const POST_SIGNUP_STEPS_NAME = {
  AGENCY_PROFILE: 'AGENCY_PROFILE',
  AGENCY_DETAILS: 'AGENCY_DETAILS',
  TAX_AND_W9: 'TAX_AND_W9',
  LICENSE: 'LICENSE',
  ENO: 'ENO',
  AGENT_AGREEMENT: 'AGENT_AGREEMENT',
};

export const MAPPING_POST_SIGNUP_STEPS_NAME = {
  AGENCY_PROFILE: 1,
  AGENCY_DETAILS: 2,
  TAX_AND_W9: 2,
  W9_SIGN_COMPLETE: 3,
  LICENSE: 4,
  ENO: 5,
  AGENT_AGREEMENT: 6,
};

export const COMMISSION_HELPER_TEXT =
  'Paid Date will reflect the month commissions were paid, not the month the policy was written.';
export const ENTITY_TYPE = {
  INDIVIDUAL: 'INDIVIDUAL',
  FIRM: 'FIRM',
};
export const TRAILING_SLASH_URL = '/:url*(/+)';
export const LOBS = 'lobs';
export const OWNER_NPN_PREFILLED = 'Owner NPN prefilled';
export const AGENCY_NPN_PREFILLED = 'Agency NPN prefilled';
export const GO_BEYOND_YOUR_BASELINE = 'GO BEYOND YOUR <br /> BASELINE!';
export const START_EARNING_TODAY = 'START EARNING <br /> TODAY!';
export const TO_SEE_AVAILABLE_INCENTIVES =
  'To see <br /> available incentives!';
export const BASELINE = 'BASELINE';
export const PC_PREMIUM = 'PC Premium';
export const LIFE_PREMIUM = 'Life Premium';
export const TAX_STATUS_KEY = 'Tax Status';
export const MAGIC_LINK = 'Magic Link';
export const SAME_ADDRESS = 'Same address';
export const AGENCY_FEIN = 'Agency FEIN';
export const NPN_CALL_SUCCESS = 'NPN call success';
export const CARRIER_NAME_KEY = 'Carrier name';
export const CARRIER_URL_KEY = 'Carrier URL';
export const KEYDOWN = 'keydown';
export const ESC_KEYCODE = 27;
export const HYBRID = 'Hybrid';
export const QUOTING_ENGINE_ID = 'Quoting Engine ID';
export const HUBSPOT_LEAD_STATUSES = {
  QUALIFIED: 'qualified',
  WAITING_FOR_FC_APP_COMPLETION: 'waiting for fc app completion',
  FOLLOWING_UP: 'following up',
};
export const HUBSPOT_LEAD_STATUSES_FOR_WELCOME_PAGE = [
  HUBSPOT_LEAD_STATUSES.QUALIFIED,
  HUBSPOT_LEAD_STATUSES.WAITING_FOR_FC_APP_COMPLETION,
  HUBSPOT_LEAD_STATUSES.FOLLOWING_UP,
];
export const WIN_TRIP_TO = 'WIN A TRIP TO';
export const LAS_VEGAS = 'las vegas';
export const FOR_INCENTIVE_DETAILS = 'for incentive Details';
export const INCENTIVE_BANNER_ROTATE_INTERVAL_IN_MS = 12000;
export const INVALID_EMAIL_NOTIFICATION = 'Email provided is Invalid';
export const PASSWORD_HINT =
  'Use 8+ characters with uppercase, lowercase, and numbers';
export const AS_ORGANIZATION_CODE = 'ameri-save';
export const NONE = 'none';
export const CONFIRM = 'Confirm';
export const CLOSE = 'Close';
export const SHOW_CONSENT_MODAL = 'showConsentModal';
export const IS_CROSS_SELL_EXPERIENCE_ENABLED = 'isCrossSellExperienceEnabled';
export const CROSS_SELL_EXPERIENCE = 'Cross-Sell Experience Enabled';
export const AGENCY_PROFILE_TAB = 'agency-profile';
export const COTERIE_CARRIER_CODES = ['BOP', 'PL', 'GL'];
export const REDIRECT_URL = 'redirectUrl';
export const USER_ROLE = 'userRole';
export const IS_CARRIER_STORE_ENABLED = 'isCarrierStoreEnabled';
export const CARRIER_STORE_AGENT_APPLICATION_RESTRICTION_ENABLED =
  'carrierStoreAgentApplicationRestrictionEnabled';
export const CONFIRM_DATA =
  'Please confirm your State, SSN, and/or FEIN from previous steps';
export const ADD_NEW_CARRIER = 'Add new carrier';
export const CARRIER_NUMBER_LIMIT_IN_CARRIER_PORTALS_CARD = 11;
export const MAX_EMAIL_LENGTH = 45;
export const MAGIC_LINK_USED = 'Magic Link Used';
export const VIEW_AGENT = 'View Agent';
export const VIEW_QUOTE = 'View Quote';
export const FINISH = 'Finish';
export const UTM_SOURCE_KEY = 'utm-source';
export const MAX_NAME_LENGTH = 30;
export const BUSINESS_NAME = 'Business Name';
export const PRIMARY_STATE = 'Primary State';
export const VALIDATE_CLASS_CODE = 'validateForm';
export const CLASS_CODES = 'Class Codes';
export const PLEASE_SELECT_A_STATE = 'Please select a state';
export const PLEASE_SELECT_CLASS_CODE = 'Please select a class code for quotes';
export const THANK_YOU = 'Thank you for requesting your appointments!';
export const VERIFYING_PROCESS =
  'We are in the process of verifying your account over the next couple of days.';
export const FC_PLATFORM =
  'In the meantime, you can request more appointments from our world-class carriers or explore the unlocked sections on the First Connect platform!';
export const ARRAY_VALUE = [
  {
    default: true,
    id: 1,
    name: 'Yes',
    value: true,
  },
  {
    default: false,
    id: 2,
    name: 'No',
    value: false,
  },
];
export const MESSAGE = 'message';
export const CARRIER_APPOINTMENT_REQ_COUNT = 'carrierAppointmentRequestCount';
export const INVALID_URL = 'Please enter a valid URL';

export const WHOLESALE_USERNAME = 'wholesaleUsername';
export const WHOLESALE_PASSWORD = 'wholesalePassword';
export const WHOLESALE_PRODUCER_CODE = 'wholesaleProducerCode';
// Used to force using halloween theme on a page. Testing purposes only.
export const FORCE_HALLOWEEN_THEME_STORAGE_KEY = 'forceHalloweenTheme';
export const ENABLE_HALLOWEEN_THEME = true;
export const HALLOWEEN_MODAL_CLOSED_EVENT = 'halloweenModalWasClosed';

export const DISPLAY_COMMERICAL_NAME = 'Commercial Lines';
export const LOB_CODE = 'WORKERS-COMP';

export const FORCE_HOLIDAY_THEME_STORAGE_KEY = 'forceHolidayTheme';
export const DAYS_LEFT_FOR_TRIAL = 'daysLeftForTrial';
export const ENABLE_HOLIDAY_THEME = false;
export const INPUT_FILE = 'inputFile';
export const RESULTS = 'Results';

/* Quote Connector Modal */
export const QC_MODAL_TYPE = {
  TRIAL_MODAL: 'trialModal',
  UPGRADE_MODAL: 'upgradeModal',
  SUCCESS_MODAL: 'successModal',
};

export const COMPLIANCE_STATUS = {
  IN_COMPLIANCE: 'IN_COMPLIANCE',
  REJECTED: 'REJECTED',
  INVALID: 'INVALID',
  UPDATED: 'UPDATED',
  IN_REVIEW: 'IN_REVIEW',
};
export const REDIRECT = 'Redirect';

export const ADD_BUTTON = 'ADD_BUTTON';
export const PLUS_BUTTON = 'PLUS_BUTTON';
export const ROW_VALUE = 'NONE';
export const COMMISSION_APPOINTMENT_TYPE = 'appointment_type';
export const STATE_ABBREVATION = 'state_abbrevation';
export const COMMISSION_TIER = 'FK_carrier_tiers_mapping_id';
export const ENO_VALIDATION_RESULT_KEY_MESSAGE_MAPPING = {
  signature: 'signature',
  insuredName: 'name',
  insuranceType: 'type',
  insuranceLimit: 'limit',
  insuranceCarrier: 'carrier',
  insuranceStartEndDate: 'date',
};

export const REPORT_BIND = 'Report Bind';

export const SHARE_AGENCY_LOGINS_VALUE = {
  YES: 'yes',
  NO: 'no',
  NA: 'n/a',
};

export const SHARE_AGENCY_LOGINS_VALUE_OPTION = [
  {
    name: 'Yes',
    value: 'Yes',
  },
  {
    name: 'No',
    value: 'No',
  },
];

export const MAP_SHARE_AGENCY_LOGINS_VALUE = {
  true: 'YES',
  false: 'NO',
};

export const MAP_SHARE_AGENCY_LOGINS_PAYLOAD = {
  yes: true,
  no: false,
  'n/a': null,
};

export const shareAgencyLoginValue = SHARE_AGENCY_LOGINS_VALUE.YES;

// Agent Messaging
export const AGENT_MESSAGING_TITLE = 'Agent Messaging';
export const TEXT_SENT = 'textSent';
export const SEND_TIME = 'sendTime';
export const FORMAT = 'format';
export const AGENT_MESSAGING_TABLE_TITLE = 'title';

export const AGENT_MESSAGE_STATE = {
  PROCESSING: 'processing',
  IDLE: 'idle',
  SUCCESS: 'success',
  UNSUCCESS: 'unsuccess',
};
export const CLOUD_FRONT_URL = 'cloudFrontUrl';
export const AGENT_SAMPLE_FILE = 'static-assets/sample-admin-messaging.xlsx';
export const CTA_TYPE = {
  CUSTOM_URL: 'URL',
  CARRIER: 'CARRIER',
  NO_CTA: 'NO_CTA',
};

// Carrier Tooltip
export const CARRIER_TOOLTIP = 'carrier_tooltip';
export const CARRIER_TOOLIP_STATE = {
  ADDED: 'added',
  REMOVED: 'removed',
};
export const BANNER_TYPE = 'Type';
export const POLICY_WRITTEN = 'Policy Written';
export const WORKERS_COMP = 'WORKERS-COMP';
export const BOP = 'BOP';
export const GL = 'GL';
export const PL = 'PL';
export const MANAGED_BY_FIRST_CONNECT = 'Managed by First Connect';
export const ADD_PRODUCT_MAP = 'addProductMap';
export const PRODUCT_MAP = 'productMap';
export const APPOINTMENT_MAP = 'appointmentMap';
export const MAX_STATES = 51;

export const IMPERSONATE_BFF_HEADER = 'x-impersonate-producer-id';

export const mapMessageType = {
  SMS: 'Text',
  NOTIFICATION: 'Notification',
};

export const MESSAGE_TYPE = {
  SMS: 'SMS',
  NOTIFICATION: 'Notification',
};

export const MODAL_DAYS_KEY = 'Modal Days';

export const NEW_APP_ROUTES = {
  QUOTE_CONNECTOR_START: '/quote-connector/start',
  QUOTE_CONNECTOR: '/quote-connector',
  APPETITE_GUIDE_START: '/appetite-finder/start',
  APPETITE_GUIDE: '/appetite-finder',
  CASH_CONNECT: '/cash-connect',
  CASH_CONNECT_INCENTIVES: '/cash-connect/incentives',
  CASH_CONNECT_REFERRAL_PROGRAM: '/cash-connect/referral-program',
  CASH_CONNECT_REWARDS_PROGRAM: '/cash-connect/rewards-program',
  CASH_CONNECT_CARRIER_INCENTIVES: '/cash-connect/carrier-incentives',
  DASHBOARD: '/dashboard',
  CARRIER_STORE: '/carrier-store',
  CARRIER_STORE_PERSONALIZATION: '/carrier-store/personalization',
  RATERS: '/raters',
  RATERS_QUOTE_LIST: '/raters/quote-list',
};

// q2-incentive modal
export const carrierThreshold = 2;
export const policyBindThreshold = 12;
export const SUPPORT_PHONE_NUMBER = '877-287-6055';
export const BACKSPACE_KEYCODE = 8;
export const DELETE_KEYCODE = 46;
export const REFRESHED_AT_SESSION_STORAGE_KEY = 'fc-refreshed-at';
export const TAX_AGENT = 'taxAgent';
export const QUERY_KEY = {
  OPEN: 'open',
  TAB: 'tab',
};
export const EDIT_AGENCY_PROFILE_MODAL = 'EDIT_AGENCY_PROFILE_MODAL';
export const EXPERIMENTAL_MODE_KEY = 'fcExperimentalFeatures';

export const PRINCIPAL_ACCESS_ROLE = 'principal-access';

export const PRODUCER_ROLES = {
  AGENCY_MANAGER: 'Agency Manager',
  PRODUCER_ACCESS: 'Producer Access',
};
export const roleList = [
  {
    label: 'Producer Access',
    value: 'agency-producer',
  },
  {
    label: 'Principal Access',
    value: 'principal-access',
  },
];
export const TOTAL_MEMBER_ADDED = 'Total members added';
export const PRODUCER_ACCESS_ADDED = 'Producer Access Added';
export const PRINCIPAL_ACCESS_ADDED = 'Principal Access Added';
export const OFFICE_PHONE = 'officePhone';
export const SEND_TO_ALL_PRODUCERS = 'Send To All Producers';

// <https://myhippo.atlassian.net/browse/FRC-443>
export const MINIMUM_AVALIABLE_CARRIERS_COUNT_TO_LOCK_PORTAL_USAGE = 5;

export const MODAL_DEFERRED_SIGNUP_TIMESTAMP_KEY =
  'modalDeferredSignupTimestamp';
export const EO_BANNER_LAST_DISMISSED_AT_KEY = 'eoBannerLastDismissedAt';

export const SIGNOUT_EMAIL_SEARCH_PARAM = 'email';
export const SIGNOUT_IS_PASSWORDLESS_SEARCH_PARAM = 'passwordless';
export const SIGNOUT_IS_STEPUP_REAUTHENTICATE_SEARCH_PARAM = 'stepup';
export const SIGNOUT_IS_EMAIL_VERIFY_SEARCH_PARAM = 'emailVerify';
export const SIGNOUT_IS_INACTIVITY_REAUTHENTICATE_SEARCH_PARAM = 'reauth';

export const SIGNOUT_IS_SSO_ERROR = 'isSSOError';
export const SIGNOUT_KEEP_REDIRECT_COOKIE = 'keepRedirect';

// <https://myhippo.atlassian.net/browse/FRC-5319>
export const EMAIL_VERIFICATION_STARTED_COOKIE_KEY = 'emailVerificationStarted';
export const UW_EMAIL = 'uwEmail';
export const UW_PHONE = 'uwPhone';

// Carrier Incentive
export const CARRIERS_INCENTIVE_TITLE = 'Carriers Incentives';

export const HELP_CENTER_URL = 'helpCenterUrl';
