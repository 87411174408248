import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { errorHandler } from 'redux/actions/error';
import axios from 'util/api';
import * as actions from 'constants/action-types';
import {
  carrierZipCodeFileLoader,
  setSelectedStates,
  setZipCodeFileModal,
} from 'redux/actions/carrier-appointment-settings-admin';
import config from 'config';
import { displaySuccessMessage } from 'util/user-feedback';
import { getStatesWithToggleTrue } from 'util/extra';

function* fetchZipCodeFile({ payload }) {
  const { carrierId } = payload;
  try {
    yield put(carrierZipCodeFileLoader(true));
    const response = yield axios.get(
      `${config.BFF_URL}/api/v1/carrier/states-zip-codes-file-url/${carrierId}`
    );
    if (!!response?.data?.signedUrl) {
      const fileUrl = response?.data?.signedUrl;
      const signedURL = yield call(fetch, fileUrl);
      const responseBody = yield signedURL.blob();
      const url = window.URL.createObjectURL(responseBody);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'carrier_zip_code.xlsx';
      a.click();
      a.remove();
    }
  } catch (error) {
    yield put(errorHandler(error));
  }
  yield put(carrierZipCodeFileLoader(false));
}

export function* fetchRewardsProgramReportWatcher() {
  yield takeEvery(actions.FETCH_ZIP_CODE_FILE, fetchZipCodeFile);
}

function* uploadCarrierZipCodeFile({ payload }) {
  yield put(carrierZipCodeFileLoader(true));
  try {
    const { file, carrierId } = payload;
    const response = yield axios.post(
      `${config.BFF_URL}/api/v1/carrier/states-zip-codes/${carrierId}`,
      {
        fileContent: file,
      }
    );

    if (!!response?.data?.success) {
      const statesWithToggleTrue = getStatesWithToggleTrue(
        response?.data?.stateZipCodeToggle
      );
      yield put(
        setSelectedStates({
          selectedStates: statesWithToggleTrue,
        })
      );
      yield displaySuccessMessage('File Uploaded Successfully');
      yield put(setZipCodeFileModal(false));
    }
  } catch (error) {
    yield put(errorHandler(error));
  } finally {
    yield put(carrierZipCodeFileLoader(false));
  }
}

export function* addCarrierZipCodeWatcher() {
  yield takeEvery(actions.ADD_CARRIER_ZIP_CODE_FILE, uploadCarrierZipCodeFile);
}

export default function* rootSaga() {
  yield all([
    fork(addCarrierZipCodeWatcher),
    fork(fetchRewardsProgramReportWatcher),
  ]);
}
