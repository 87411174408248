import { all, fork, put, select, takeEvery } from 'redux-saga/effects';
import { errorHandler } from '../actions/error';
import {
  q2IncentiveDetailLoader,
  setAddedQ2IncentiveDetails,
  setAddQ2IncentivePolicyLoader,
  setIsQ2IncentiveAdded,
  setQ2IncentivePolicyList,
  setQ2IncentivePolicyListLoader,
  storeQ2IncentiveDetail,
} from '../actions/q2-incentive';
import axios from 'util/api';
import * as q2Incentive from 'constants/action-types';
import { getAuthDetails } from 'util/extra';
import { PORTAL } from 'constants/heap-events';

// Get Q2 Incentive details
export function* fetchQ2IncentiveDetails() {
  try {
    yield put(q2IncentiveDetailLoader(true));
    const store = yield select();
    const { auth, impersonate, q2Incentive: incentive } = store;
    const { producer, isImpersonate } = impersonate;
    const { producer_id } = getAuthDetails({
      auth,
      producer,
      isImpersonate,
    });
    const { q2IncentiveDaysRemaining } = incentive;
    const responseData = yield axios.get(
      `/api/v1/producer/q2-incentive-details/${producer_id}`
    );
    if (!!responseData?.data) {
      if (q2IncentiveDaysRemaining <= 90 && q2IncentiveDaysRemaining >= -30) {
        window.heap.track(PORTAL.DASHBOARD_Q2_INCENTIVE_VIEW);
      }
      const { q2IncentiveDetails } = responseData.data;
      yield put(storeQ2IncentiveDetail(q2IncentiveDetails));
    }
  } catch (error) {
    yield put(errorHandler(error));
  }
  yield put(q2IncentiveDetailLoader(false));
}

export function* fetchQ2IncentiveDetailsWatcher() {
  yield takeEvery(
    q2Incentive.FETCH_Q2_INCENTIVE_DETAIL,
    fetchQ2IncentiveDetails
  );
}

function* fetchQ2IncentivePolicies() {
  try {
    yield put(setQ2IncentivePolicyListLoader(true));
    const { auth, impersonate } = yield select();
    const { producer, isImpersonate } = impersonate;
    const { producer_id } = getAuthDetails({
      auth,
      producer,
      isImpersonate,
    });

    const response = yield axios.get(
      `api/v1/producer/q2-incetive-policies/${producer_id}`
    );
    if (!!response.data) {
      const { q2IncentiveDetails, appointedCarriers } = response.data;
      yield put(
        setQ2IncentivePolicyList({
          q2IncentiveDetails: q2IncentiveDetails,
          appointedCarriers: appointedCarriers,
        })
      );
    }
  } catch (error) {
    yield put(errorHandler(error));
  }
  yield put(setQ2IncentivePolicyListLoader(false));
}

export function* fetchQ2IncentivePoliciesWatcher() {
  yield takeEvery(
    q2Incentive.FETCH_Q2_INCENTIVE_POLICIES,
    fetchQ2IncentivePolicies
  );
}

function* addQ2IncentivePolicy({ payload }) {
  try {
    const { auth, impersonate } = yield select();
    const { producer, isImpersonate } = impersonate;
    const { producer_id } = getAuthDetails({
      auth,
      producer,
      isImpersonate,
    });

    yield put(setAddQ2IncentivePolicyLoader(true));
    const response = yield axios.post(
      'api/v1/producer/add-q2-incentive-policy',
      {
        carrierId: payload.carrierId,
        policyNumber: payload.policyNumber,
        policyPremium: payload.policyPremium,
        policyEffectiveDate: payload.policyEffectiveDate,
        producerId: producer_id,
      }
    );

    if (response.data.success) {
      const { q2IncentivePolicy } = response.data;
      yield put(setAddedQ2IncentiveDetails(q2IncentivePolicy));
      yield put(setIsQ2IncentiveAdded(true));
    }
  } catch (error) {
    yield put(errorHandler(error));
  }
  yield put(setAddQ2IncentivePolicyLoader(false));
}

export function* addQ2IncentivePolicyWatcher() {
  yield takeEvery(q2Incentive.ADD_Q2_INCENTIVE_POLICIES, addQ2IncentivePolicy);
}

function* setQ2IncentiveModalViewed({ payload }) {
  try {
    const { auth, impersonate } = yield select();
    const { producer, isImpersonate } = impersonate;
    const { producer_id } = getAuthDetails({
      auth,
      producer,
      isImpersonate,
    });
    yield axios.post('api/v1/producer/mark-q2-incentive-modal-viewed', {
      producerId: producer_id,
      q2IncentiveModal: payload,
    });
  } catch (error) {
    yield put(errorHandler(error));
  }
}

export function* setQ2IncentiveModalViewedWatcher() {
  yield takeEvery(
    q2Incentive.SET_Q2_INCENTIVE_MODAL_VIEWED,
    setQ2IncentiveModalViewed
  );
}


export default function* rootSaga() {
  yield all([
    fork(fetchQ2IncentiveDetailsWatcher),
    fork(fetchQ2IncentivePoliciesWatcher),
    fork(addQ2IncentivePolicyWatcher),
    fork(setQ2IncentiveModalViewedWatcher)
  ]);
}
