// @ts-check

import { NEW_APP_ROUTES } from 'constants/constant';
import { navigateToNewAppRoute } from 'util/navigate-to-new-app-route';
import { carrierNameToIdMapping } from 'constants/carrier-name-to-id-mapping';
import { useEffect } from 'react';

/**
 * This function preserves query params between old and new carrier store,
 * when a user is trying to access a carrier via url.
 * @returns {string | undefined} Query params for the new carrier store or undefined
 */
const getNewCarrierStoreQueryParams = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const carrierName = queryParams.get('carrier_name');

  if (!carrierName) {
    return;
  }

  const carrier = carrierNameToIdMapping.find(
    ({ carrier_code, display_name }) =>
      display_name.toLowerCase() === carrierName.toLowerCase() ||
      carrier_code.toLowerCase() === carrierName.toLowerCase()
  );

  if (!carrier) {
    return;
  }

  const params = new URLSearchParams();
  params.set('carrier_id', carrier.id.toString());
  params.set('source', 'Carrier Store Card');
  return params.toString();
};

const CarrierStore = () => {
  useEffect(function redirectToNewCarrierStore() {
    const queryParams = getNewCarrierStoreQueryParams();
    navigateToNewAppRoute(NEW_APP_ROUTES.CARRIER_STORE, queryParams);
  }, []);

  return null;
};

export default CarrierStore;
