import React, { useState } from 'react';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import {
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import IntlMessages from 'util/intl-messages';
import '../cross-sell.scss';
import { setCrossSellViewed } from 'redux/actions/auth';
const AnnouncementModal = props => {
  const [openAppointmentModal, setOpenAppointmentModal] = useState(
    !!props?.isCrossSellExperienceViewed
      ? !props?.isCrossSellExperienceViewed
      : true
  );

  const closeAppointmentModal = () => {
    let payload = {};
    if (!props?.isImpersonate) {
      payload = {
        impersonated: false,
      };
      props.setCrossSellViewed(payload);
    }
    setOpenAppointmentModal(false);
  };

  return (
    <Dialog
      className="cross-sell-welcome-modal"
      fullWidth={true}
      maxWidth="xs"
      aria-labelledby="max-width-dialog-title"
      open={openAppointmentModal}
      disableBackdropClick={true}
    >
      <DialogContent className="welcome-wrap">
        <div className="welcome-title p-0">
          Hey&nbsp;
        {!!props.isImpersonate ? ( props?.producer?.first_name):( props?.loginName)},
        </div>
        <Typography className="welcome-content">
          <div className="mb-3">
            <IntlMessages id="label.announcement.modal.first" />
            <span className="font-bold">
              {' '}
              <IntlMessages id="label.announcement.modal.second" />
            </span>
            <IntlMessages id="label.announcement.modal.third" />
          </div>
          <div>
            <IntlMessages id="label.announcement.modal.fourth" />{' '}
            <IntlMessages id="label.announcement.modal.fifth" />
          </div>
        </Typography>
      </DialogContent>
      <DialogActions>
        <Grid item xs={12} sm={12} className="d-flex justify-content-end">
          <Button
            className="orange-button get-started-btn"
            onClick={closeAppointmentModal}
          >
            <IntlMessages id="label.get.started" />
          </Button>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

const mapStateToProps = ({ auth, impersonate, dashboard }) => {
  const { loginName } = auth;
  const { producer, isImpersonate } = impersonate;
  const {
    carrierPortals,
    isCrossSellExperienceViewed,
    suggestedCarriers,
  } = dashboard;

  return {
    loginName,
    producer,
    isImpersonate,
    carrierPortals,
    isCrossSellExperienceViewed,
    suggestedCarriers,
  };
};
export default connect(mapStateToProps, { setCrossSellViewed })(
  AnnouncementModal
);
