import * as types from '../../constants/action-types';

const initState = () => ({
  q2IncentiveLoader: false,
  policyCount: 0,
  carrierCount: 0,
  totalEarnings: 0,
  carrierURL: null,
  addQ2IncentiveLoader: false,
  q2IncentiveEarning: null,
  isQ2IncentiveAdded: false,
  q2IncentiveDaysRemaining:
    localStorage.getItem('q2IncentiveDaysRemaining') !== 'undefined'
      ? JSON.parse(localStorage.getItem('q2IncentiveDaysRemaining'))
      : null,
  isQ2IncentiveInitialModalViewed: JSON.parse(
    localStorage.getItem('isQ2IncentiveInitialModalViewed')
  ),
  isQ2IncentiveSecondaryModalViewed: JSON.parse(
    localStorage.getItem('isQ2IncentiveSecondaryModalViewed')
  ),
  isQ2IncentiveTertiaryModalViewed: JSON.parse(
    localStorage.getItem('isQ2IncentiveTertiaryModalViewed')
  ),
  isQ2IncentiveQuaternaryModalViewed: JSON.parse(
    localStorage.getItem('isQ2IncentiveQuaternaryModalViewed')
  ),
  showQ2IncentiveModal: localStorage.getItem('showQ2IncentiveModal')
    ? JSON.parse(localStorage.getItem('showQ2IncentiveModal'))
    : false,
  q2IncentivePolicyListLoader: false,
  q2IncentiveDetails: null,
  appointedCarriers: [],
});

export default (state = initState(), action) => {
  switch (action.type) {
    case types.Q2_INCENTIVE_INITIAL_DETAIL: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case types.Q2_INCENTIVE_DETAIL_LOADER: {
      return {
        ...state,
        q2IncentiveLoader: action.payload,
      };
    }
    case types.STORE_Q2_INCENTIVE_DETAILS: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case types.ADD_Q2_INCENTIVE_POLICIES_LOADER: {
      return {
        ...state,
        addQ2IncentiveLoader: action.payload,
      };
    }
    case types.ADDED_Q2_INCENTIVE_DETAILS: {
      const { earning } = action.payload;
      return {
        ...state,
        q2IncentiveEarning: earning,
      };
    }
    case types.SET_IS_Q2_INCENTIVE_ADDED_FLAG: {
      return {
        ...state,
        isQ2IncentiveAdded: action.payload,
      };
    }
    case types.SET_Q2_INCENTIVE_POLICY_LIST_LOADER: {
      return {
        ...state,
        q2IncentivePolicyListLoader: action.payload,
      };
    }
    case types.SHOW_Q2_INCENTIVE_MODAL: {
      return {
        ...state,
        showQ2IncentiveModal: action.payload,
      };
    }
    case types.SET_Q2_INCENTIVE_POLICY_LIST: {
      const { q2IncentiveDetails, appointedCarriers } = action.payload;
      return {
        ...state,
        q2IncentiveDetails: q2IncentiveDetails,
        appointedCarriers: appointedCarriers,
      };
    }
    default:
      return state;
  }
};
