/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
/**
 * @example
 * this.state = {
 *   formValues: {
 *     firstName: ''
 *   }
 *   formErrors: {
 *     firstName: ''
 *   }
 * }
 * <FormInput
 *   {...inputProps}
 *   handleChange={value => value.trim()}
 *   handleBlur={value => value.trim()}
 *   setValue={this.setValue}
 *   value={formValues.firstName}
 *   valueKey="firstName"
 *   error={formErrors.firstName}
 *   onChangeCallBack={value => { console.log(value); }}
 *   onBlurCallBack={value => {
 *     this.setValue('firstName',startCase(value));
 *   }}
 * />
 * @param handleChange `(value:string) => [string,boolean]`
 * __returns__  new value and shouldChange after onChange event triggers
 * @param handleBlur `(value:string) => [string,boolean]`
 * __returns__ new value, shouldChange after onBlur event trigger
 * @param setValue `(key:string, value:string, callback?:(key:string) => void)`
 * setState wrapper function of parent component
 * @param valueKey keyname of the value in parent component state.formValues
 * @param {string} error - error text string
 * @param onChangeCallBack `function (value : string)` callback function to execute after onChange event
 * @param onBlurCallBack `function (value : string)` callback function to execute after onBlur event
 */
class FormInput extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onChangeHandler = event => {
    const { handleChange, setValue, valueKey, onChangeCallBack } = this.props;
    const [newValue, change] = handleChange?.(event.target.value) || [
      event.target.value,
      true,
    ];
    if (change) {
      setValue(valueKey, newValue, onChangeCallBack);
    }
  };

  onBlurHandler = event => {
    const { handleBlur, setValue, valueKey, onBlurCallBack } = this.props;
    const [newValue, change] = handleBlur?.(event.target.value) || [
      event.target.value,
      true,
    ];
    if (change) {
      setValue(valueKey, newValue, onBlurCallBack);
    }
  };

  render = () => {
    const {
      error,
      handleChange,
      handleBlur,
      setValue,
      valueKey,
      onChangeCallBack,
      onBlurCallBack,
      ...rest
    } = this.props;

    return (
      <>
        <input
          {...rest}
          onChange={this.onChangeHandler}
          onBlur={this.onBlurHandler}
        />
        <div className="error-msg">{error || ''}</div>
      </>
    );
  };
}
export default FormInput;
