import * as types from '../../constants/action-types';

const initState = () => ({
  open: false,
  appointmentSettingId: null,
  config: {
    acceptingAppointments: false,
    showInStore: false,
    approvalType: '',
    customFieldsEnabled: false,
    externalRequestFormEnabled: false,
    requestFormURL: '',
    pdfApplicationEnabled: false,
    meetingRequiredEnabled: false,
    meetingURL: '',
    moreInformationEnabled: false,
    files: [],
    selectedStates: [],
    customFields: [],
  },
  updateAppointmentSettingLoader: false,
  carrierZipCodeFileLoader: false,
  carrierZipCodeFileModal: false,
});

export default (prevState = initState(), { type, payload } = {}) => {
  switch (type) {
    case types.SET_APPOINTMENT_ENABLED:
      return {
        ...prevState,
        config: {
          ...prevState.config,
          acceptingAppointments: payload.acceptingAppointments,
        },
      };
    case types.SET_SHOW_IN_STORE_ENABLED:
      return {
        ...prevState,
        config: {
          ...prevState.config,
          showInStore: payload.showInStore,
        },
      };

    case types.OPEN_APPOINTMENT_SETTING:
      return {
        ...prevState,
        open: !prevState.open,
      };
    case types.INIT_DATA_CARRIER_APPOINTMENTS:
      return {
        ...prevState,
        appointmentSettingId: payload.appointmentSettingId,
        config: payload.config,
      };
    case types.SAVE_APPOINTMENT_SETTINGS:
      return {
        open: false,
        appointmentSettingId: payload.appointmentSettingId,
        config: {
          ...prevState.config,
          ...payload.config,
        },
      };
    case types.SET_UPDATE_APPOINTMENT_SETTING_LOADER: {
      return {
        ...prevState,
        updateAppointmentSettingLoader: payload,
      };
    }
    case types.SET_SELECTED_STATES:
      return {
        ...prevState,
        config: {
          ...prevState.config,
          selectedStates: payload.selectedStates,
        },
      };
    case types.CARRIER_ZIP_CODE_FILE_LOADER: {
      return {
        ...prevState,
        carrierZipCodeFileLoader: payload,
      };
    }
    case types.CARRIER_ZIP_CODE_FILE_MODAL: {
      return {
        ...prevState,
        carrierZipCodeFileModal: payload,
      };
    }
    default:
      return prevState;
  }
};
