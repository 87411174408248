// @ts-check

import { SET_SIGN_IN_COHORT_DATA } from 'constants/action-types';

/**
 * @param {Object} payload - The sign-in cohort data.
 * @param {string} payload.email - The email of the user.
 * @param {string} payload.unsafeProducerCode - The unsafe producer code.
 * @param {string} payload.isVerified - Is email verified.
 */
export function setSignInCohortData(payload) {
  return {
    type: SET_SIGN_IN_COHORT_DATA,
    payload,
  };
}
