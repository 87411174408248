// @ts-check

import { BACKSPACE_KEYCODE, DELETE_KEYCODE } from 'constants/constant';

/**
 * @param {string} hiddenSSNValue
 * @returns {string}
 */

let originalSSNValue = '';

const addDash = (num) => {
  originalSSNValue =
    originalSSNValue.substring(0, num) + '-' + originalSSNValue.substring(num);
};

export const onSSNChange = (hiddenSSNValue) => {
  let hideSSNValue = hiddenSSNValue.replaceAll('-', '');
  let showSSNValue = originalSSNValue.replaceAll('-', '');
  for (let i in hideSSNValue) {
    if (hideSSNValue[i] >= '0' && hideSSNValue[i] <= '9') {
      showSSNValue =
        showSSNValue.substr(0, Number(i)) +
        hideSSNValue[i] +
        showSSNValue.substr(Number(i));
    }
  }
  originalSSNValue = showSSNValue.substring(0, 9);
  if (originalSSNValue.length > 3) {
    addDash(3);
  }
  if (originalSSNValue.length > 6) {
    addDash(6);
  }
  return originalSSNValue;
};

export const onSSNKeyPress = (event, originalValue) => {
  originalSSNValue = originalValue;
  const t = event.target;
  if (event.keyCode === BACKSPACE_KEYCODE || event.keyCode === DELETE_KEYCODE) {
    let newSSNValue = originalSSNValue;
    let modifiedSSNValue =
      t.selectionStart === t.selectionEnd
        ? newSSNValue.substr(0, t.selectionStart - 1) +
          newSSNValue.substr(t.selectionStart, newSSNValue.length)
        : newSSNValue.substr(0, t.selectionStart) +
          newSSNValue.substr(t.selectionEnd, newSSNValue.length);
    originalSSNValue = modifiedSSNValue;
    originalSSNValue = originalSSNValue.replaceAll('-', '');
    if (originalSSNValue.length > 3) {
      addDash(3);
    }
    if (originalSSNValue.length > 6) {
      addDash(6);
    }
  }
  return originalSSNValue;
};
