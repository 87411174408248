import { concat, isEmpty, map, uniqBy } from 'lodash';
import { CUSTOM_FIELD_STATUS, FIELD_TYPES } from 'constants/constant';
import { getStatesWithToggleTrue } from 'util/extra';

export default class CarrierAppointmentsMapper {
  static mapAppointmentsData(data, defaultValue = {}) {
    if (data) {
      return {
        appointmentSettingId: data.id,
        config: {
          acceptingAppointments: data.appointments_enabled || false,
          showInStore: data.show_in_store || false,
          approvalType: data.approval_type || '',
          customFieldsEnabled: data.custom_fields_enabled || false,
          externalRequestFormEnabled:
            data.external_request_form_enabled || false,
          requestFormURL: data.external_request_form_url || '',
          pdfApplicationEnabled: data.pdf_application_enabled || false,
          meetingRequiredEnabled: data.meeting_required_enabled || false,
          meetingURL: data.meeting_url || '',
          moreInformationEnabled: data.more_information_enabled || false,
          files: map(data.more_information_documents, item => {
            if (!(item.date instanceof Date)) {
              item.date = new Date(item.date);
            }

            return item;
          }),
          selectedStates: getStatesWithToggleTrue(data.states_zip_codes_available_for_appointments) || [],
          customFields: this.mapCustomFieldsData(data.custom_fields),
        },
      };
    }

    return defaultValue;
  }

  static mapCustomFieldsData(data) {
    if (data) {
      return uniqBy(
        data.map(item => ({
          customFieldId: item.id,
          fieldOrder: item.field_order,
          fieldName: item.field_name || '',
          type: item.field_type || FIELD_TYPES.TEXT,
          required: item.required || false,
          characterLimit: item.character_limit || undefined,
          status: item.status || CUSTOM_FIELD_STATUS.ACTIVE,
        })),
        'fieldOrder'
      );
    }

    return [];
  }

  static mapCustomFieldData(fieldOrder, data = []) {
    const record = data.find(item => item.fieldOrder === fieldOrder) || {};

    return {
      fieldOrder: record.fieldOrder || fieldOrder,
      fieldName: record.fieldName || '',
      type: record.type || FIELD_TYPES.TEXT,
      required: record.required || false,
      characterLimit: record.characterLimit || undefined,
      status: record.status || CUSTOM_FIELD_STATUS.INACTIVE,
      errors: record.errors || {},
    };
  }

  static mapCustomFieldDataToRender(fieldOrder, data = []) {
    const record =
      data.find(item => (item.fieldOrder || item.field_order) === fieldOrder) ||
      {};

    if (!isEmpty(record)) {
      return {
        fieldOrder: fieldOrder,
        id: record.id || '',
        fieldName: record.field_name || '',
        response: record.response || '',
        required: record.required || false,
      };
    }

    return null;
  }

  static mapUpdateAppointmentSettings(
    carrierId,
    appointmentSettingId,
    newData = {}
  ) {
    const {
      appointmentSettings,
      customFieldsEnabled,
      filesEnabled,
      customFields = [],
      customFieldsRemoved = [],
      files = [],
    } = newData;
    const customFieldsList = concat(customFields, customFieldsRemoved);

    return {
      id: appointmentSettingId,
      FK_carrier_id: carrierId,
      approval_type: appointmentSettings.approvalType || '',
      external_request_form_enabled:
        appointmentSettings.externalRequestFormEnabled || false,
      external_request_form_url: appointmentSettings.requestFormURL || '',
      pdf_application_enabled:
        appointmentSettings.pdfApplicationEnabled || false,
      meeting_required_enabled:
        appointmentSettings.meetingRequiredEnabled || false,
      meeting_url: appointmentSettings.meetingURL || '',
      more_information_enabled: filesEnabled || false,
      states_available_for_appointments: newData.selectedStates || [],
      custom_fields_enabled: customFieldsEnabled || false,
      custom_fields:
        customFieldsList.map(item => ({
          id: item.customFieldId,
          field_order: item.fieldOrder,
          field_name: item.fieldName,
          field_type: item.type,
          required: item.required,
          character_limit: item.characterLimit || null,
          status: item.status,
        })) || [],
      more_information_documents: files.filter(file => !file.new) || [],
      newFilesToUpload: files.filter(file => file.new) || [],
    };
  }

  static mapSaveAppointmentSettings(data = {}) {
    if (data) {
      return {
        open: false,
        appointmentSettingId: data.id,
        config: {
          approvalType: data.approval_type || '',
          customFieldsEnabled: data.custom_fields_enabled || false,
          externalRequestFormEnabled:
            data.external_request_form_enabled || false,
          requestFormURL: data.external_request_form_url || '',
          pdfApplicationEnabled: data.pdf_application_enabled || false,
          meetingRequiredEnabled: data.meeting_required_enabled || false,
          meetingURL: data.meeting_url || '',
          moreInformationEnabled: data.more_information_enabled || false,
          files: map(data.more_information_documents, item => {
            item.date = new Date(item.date);

            return item;
          }),
          selectedStates: getStatesWithToggleTrue(data.states_zip_codes_available_for_appointments) || [],
          customFields: this.mapCustomFieldsData(data.custom_fields) || [],
        },
      };
    }

    return {};
  }
}
