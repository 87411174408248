import { all, fork, put, select, takeEvery } from 'redux-saga/effects';
import { displayErrorMessage, displaySuccessMessage } from 'util/user-feedback';
import { errorHandler } from '../actions/error';
import {
  addNewLoaFilterLoader,
  loaFilterDetailLoader,
  loaFilterListLoader,
  setAllLOAFilterStates,
  setAllStateLoader,
  storeLOAFiltersDetails,
  storeLOAFiltersList,
} from '../actions/loa-filters';
import axios from 'util/api';
import * as loaActions from 'constants/action-types';

// Get LOA list
export function* fetchLoaFiltersList() {
  try {
    yield put(loaFilterListLoader(true));
    const responseData = yield axios.get('/api/v1/loa/list');
    const groupResponseData = yield axios.get('api/v1/loa/groups');
    if (
      !!responseData?.data &&
      !!responseData?.status &&
      responseData.status === 200 &&
      !!groupResponseData?.data &&
      !!groupResponseData?.status &&
      groupResponseData.status === 200
    ) {
      const { data } = responseData;
      let loaData = {
        loaFilterList: !!data?.LOAs ? data.LOAs : [],
        loaGroups: !!groupResponseData?.data?.LOAGroups
          ? groupResponseData.data.LOAGroups
          : [],
      };
      yield put(storeLOAFiltersList(loaData));
    }
  } catch (error) {
    yield put(errorHandler(error));
  }
  yield put(loaFilterListLoader(false));
}

export function* fetchLoaFiltersListWatcher() {
  yield takeEvery(loaActions.FETCH_LOA_FILTERS, fetchLoaFiltersList);
}

// Get LOA details data
export function* fetchLoaFiltersDetails({ payload }) {
  const { id } = payload;
  try {
    yield put(loaFilterDetailLoader(true));
    const responseData = yield axios.get(`/api/v1/loa/details/${id}`);
    if (
      !!responseData?.data &&
      !!responseData?.status &&
      responseData.status === 200
    ) {
      const { data } = responseData;
      let loaDetailsData = {
        loaData: !!data?.LOA ? data.LOA : '',
        loaStateMapping: !!data?.stateMapping ? data.stateMapping : [],
      };
      yield put(storeLOAFiltersDetails(loaDetailsData));
    }
  } catch (error) {
    yield put(errorHandler(error));
  }
  yield put(loaFilterDetailLoader(false));
}

export function* fetchLoaFiltersDetailsWatcher() {
  yield takeEvery(loaActions.FETCH_LOA_FILTERS_DETAILS, fetchLoaFiltersDetails);
}

// Add new loa filter
export function* addNewLoaFilter({ payload }) {
  try {
    yield put(addNewLoaFilterLoader(true));
    const responseData = yield axios.post(`/api/v1/loa/add`, payload);
    if (
      !!responseData?.data &&
      !!responseData?.status &&
      responseData.status === 200
    ) {
      const { success, message } = responseData.data;
      let redux = yield select();
      let loaList = redux.loaFilters.loaFilterList;
      const { LOA, stateMappings } = payload;
      const { id } = !!responseData.data?.LOA && responseData.data.LOA;
      let loaDetailsData = {
        loaData: {
          ...LOA,
          id: id,
        },
        loaStateMapping: stateMappings ? stateMappings : [],
        responseMessage: !success ? message : null,
      };
      yield put(storeLOAFiltersDetails(loaDetailsData));
      if (success) {
        yield put(
          storeLOAFiltersList({
            loaFilterList: [loaDetailsData.loaData, ...loaList],
          })
        );
      }
    }
    responseData?.data?.success
      ? yield displaySuccessMessage(responseData.data.message)
      : yield displayErrorMessage(responseData.data.message);
  } catch (error) {
    yield put(errorHandler(error));
  }
  yield put(addNewLoaFilterLoader(false));
}

export function* addNewLoaFiltersWatcher() {
  yield takeEvery(loaActions.ADD_NEW_LOA_FILTER, addNewLoaFilter);
}

// Fetch state array
export function* getAllStates() {
  try {
    yield put(setAllStateLoader(true));
    const responseData = yield axios.get(`/api/v1/producer/states`);
    if (
      !!responseData?.data &&
      !!responseData?.status &&
      responseData.status === 200
    ) {
      const { data } = !!responseData?.data && responseData.data;
      let updatedState = [];
      !!data &&
        data.length > 0 &&
        data.map(item => {
          return updatedState.push({
            abbreviation: item.abbreviation,
            stateId: item.id,
            stateName: item.name,
            individual: false,
            firm: false,
            isAgencyLicenseOptional: !!item?.isAgencyLicenseOptional
              ? true
              : false,
          });
        });
      yield put(setAllLOAFilterStates(updatedState));
    }
  } catch (error) {
    yield put(errorHandler(error));
  }
  yield put(setAllStateLoader(false));
}

export function* addAllStatesWatcher() {
  yield takeEvery(loaActions.FETCH_ALL_STATES, getAllStates);
}

// Update LOA data and list
export function* updateLoaFilters(data) {
  try {
    const { payload } = data;
    yield put(addNewLoaFilterLoader(true));
    const responseData = yield axios.post(`/api/v1/loa/update`, { ...payload });
    if (
      !!responseData?.data &&
      !!responseData?.status &&
      responseData.status === 200
    ) {
      const { success, message } = responseData.data;
      let redux = yield select();
      const { loaFilterList, loaDetails } = redux.loaFilters;
      let loaList = loaFilterList;
      let stateMapping = null;
      if (success) {
        let updatedLoaIndex =
          loaList.length > 0 &&
          loaList.findIndex(item => {
            return item.id === payload.LOA.id;
          });
        if (updatedLoaIndex > -1) {
          loaList.splice(updatedLoaIndex, 1);
        }
        stateMapping = loaDetails.loaStateMapping;
        payload.stateMappings.map(loa => {
          let updateLoaStateIndex = stateMapping.findIndex(item => {
            return item.stateId === loa.stateId;
          });
          return (stateMapping[updateLoaStateIndex] = loa);
        });
      }
      let loaDetailsData = {
        loaData: success
          ? payload?.LOA
            ? payload.LOA
            : loaDetails.LOA
          : loaDetails.LOA,
        loaStateMapping: success
          ? payload?.stateMappings
            ? stateMapping
            : loaDetails.loaStateMapping
          : loaDetails.loaStateMapping,
        responseMessage: !success ? message : null,
      };
      yield put(storeLOAFiltersDetails(loaDetailsData));
      if (success) {
        yield put(
          storeLOAFiltersList({
            loaFilterList: [payload.LOA, ...loaList],
          })
        );
      }
      responseData?.data?.success
        ? yield displaySuccessMessage(responseData.data.message)
        : yield displayErrorMessage(responseData.data.message);
    }
  } catch (error) {
    yield put(errorHandler(error));
  }
  yield put(addNewLoaFilterLoader(false));
}

export function* updateLoaFiltersWatcher() {
  yield takeEvery(loaActions.UPDATE_LOA_FILTER, updateLoaFilters);
}

export default function* rootSaga() {
  yield all([
    fork(fetchLoaFiltersListWatcher),
    fork(fetchLoaFiltersDetailsWatcher),
    fork(addNewLoaFiltersWatcher),
    fork(addAllStatesWatcher),
    fork(updateLoaFiltersWatcher),
  ]);
}
