import * as bulkAppointments from '../../constants/action-types';

export const setAppointments = payload => ({
  type: bulkAppointments.SET_APPOINTMENTS,
  payload,
});

export const setAppointmentsLoader = payload => ({
  type: bulkAppointments.APPOINTMENTS_LOADER,
  payload,
});

export const setAppointmentsSuccess = payload => ({
  type: bulkAppointments.APPOINTMENTS_SUCCESS,
  payload,
});

export const setAppointmentsProcessing = payload => ({
  type: bulkAppointments.APPOINTMENTS_PROCESSING,
  payload,
});

export const setAppointment = payload => ({
  type: bulkAppointments.APPOINTMENTS,
  payload,
});

export const setAppointmentsCurrentData = payload => ({
  type: bulkAppointments.APPOINTMENTS_CURRENT_DATA,
  payload,
});

export const fetchsampleInputFile = () => ({
  type: bulkAppointments.FETCH_SAMPLE_FILE,
});

export const fetchAppointments = () => ({
  type: bulkAppointments.FETCH_APPOINTMENTS,
});

export const bulkAppointmentUpdate = uploadResponse => {
  return {
    type: bulkAppointments.BULK_APPOINTMENT_UPDATE,
    payload: uploadResponse,
  };
};

export const bulkAppointmentFilesUploading = () => {
  return {
    type: bulkAppointments.BULK_APPOINTMENT_FILES_UPLOADING,
  };
};

export const bulkAppointmentSuccess = payload => {
  return {
    type: bulkAppointments.BULK_APPOINTMENT_SUCCESS,
    payload,
  };
};

export const bulkAppointmentFilesUploadFailed = () => {
  return {
    type: bulkAppointments.BULK_APPOINTMENT_FILES_UPLOAD_FAILED,
  };
};
