import * as impersonateAction from '../../constants/action-types';

export const fetchImpersonateData = payload => ({
  type: impersonateAction.GET_IMPERSONATE_MODE,
  payload,
});

export const setImpersonateData = payload => ({
  type: impersonateAction.SET_IMPERSONATE_MODE,
  payload,
});

export const setImpersonateLoader = payload => ({
  type: impersonateAction.SET_IMPERSONATE_LOADER,
  payload,
});

export const clearImpersonateMode = () => ({
  type: impersonateAction.CLEAR_IMPERSONATE_MODE,
});
