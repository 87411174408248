import { displayErrorMessage } from 'util/user-feedback';
import { all, fork, put, takeEvery } from 'redux-saga/effects';
import { errorHandler } from '../actions/error';
import {
  setAgentAdhocReportList,
  setAgentAdhocReportListloader,
  setAgentReportList,
  setAgentReportListLoader,
  setCommissionRecommendationBanner,
} from '../actions/my-commission';
import * as myCommissionActions from 'constants/action-types';
import axios from 'util/api';
import config from 'config';
import { getImpersonateBFFRequestConfig } from './impersonate';

function* fetchAgentReportList({ payload: { producer_id } }) {
  try {
    yield put(setAgentReportListLoader(true));
    const details = yield axios.get(
      `/api/v1/producer/agent-commissions-list/${producer_id}`
    );
    if (details.data) {
      yield put(setAgentReportList(details.data.agent_commission_report));
    }
  } catch (error) {
    yield put(setAgentReportList([]));
    yield put(errorHandler(error));
  }
  yield put(setAgentReportListLoader(false));
}

export function* fetchAgentReportListWatcher() {
  yield takeEvery(
    myCommissionActions.FETCH_AGENT_REPORT_LIST,
    fetchAgentReportList
  );
}

function* fetchAgentAdhocReportList({ payload }) {
  try {
    yield put(setAgentAdhocReportListloader(true));
    const response = yield axios.get(
      `api/v1/producer/adhoc-payments/${payload.producer_id}`
    );
    const { data } = response;
    if (response.data) {
      yield put(
        setAgentAdhocReportList({
          agentAdhocReportlist: data.adhocPaymentDetails,
          agencyName: data.agency_name,
          producerCode: data.producer_code,
        })
      );
    }
  } catch (error) {
    yield put(setAgentAdhocReportList([]));
    if (error.response?.status === 401) {
yield displayErrorMessage(error.response.data.message)
    } else {
      yield put(errorHandler(error));
    }
  }
  yield put(setAgentAdhocReportListloader(false));
}

export function* fetchAgentAdhocReportListWatcher() {
  yield takeEvery(
    myCommissionActions.FETCH_AGENT_ADHOC_REPORT_LIST,
    fetchAgentAdhocReportList
  );
}

function* fetchCommissionRecommendationBanner() {
  try {
    const requestConfig = yield getImpersonateBFFRequestConfig();
    const response = yield axios.get(
      `${config.BFF_URL}/api/v1/producer/recommendations/commissions-banner`,
      requestConfig
    );
    const bannerType = response.data?.bannerType;
    if (bannerType) {
      yield put(setCommissionRecommendationBanner(bannerType));
    }
  } catch (error) {
    yield put(errorHandler(error));
  }
}

export function* fetchCommissionRecommendationBannerWatcher() {
  yield takeEvery(
    myCommissionActions.GET_COMMISSION_RECOMMENDATION_BANNER,
    fetchCommissionRecommendationBanner
  );
}

// ------ ROOT SAGA -----------------
export default function* rootSaga() {
  yield all([fork(fetchAgentReportListWatcher)]);
  yield all([fork(fetchAgentAdhocReportListWatcher)]);
  yield all([fork(fetchCommissionRecommendationBannerWatcher)]);
}
