import * as AdminUserType from '../../constants/action-types';

// Get/Set admin users List
export const getAdminUserList = () => {
  return {
    type: AdminUserType.GET_ADMIN_USER_LIST,
  };
};

export const setAdminUserListLoader = payload => {
  return {
    type: AdminUserType.SET_ADMIN_USER_LIST_LOADER,
    payload,
  };
};

export const setAdminUserList = payload => {
  return {
    type: AdminUserType.SET_ADMIN_USER_LIST,
    payload,
  };
};

export const addNewAdminUser = payload => {
  return {
    type: AdminUserType.ADD_NEW_ADMIN_USER,
    payload,
  };
};

export const setAdminUserLoader = payload => {
  return {
    type: AdminUserType.NEW_ADMIN_USER_LOADER,
    payload,
  };
};

export const setNewAdminUser = payload => {
  return {
    type: AdminUserType.SET_NEW_ADMIN_USER,
    payload,
  };
};

export const updateAdminUser = payload => {
  return {
    type: AdminUserType.UPDATE_ADMIN_USER,
    payload,
  };
};

export const setUpdatedAdminUser = payload => {
  return {
    type: AdminUserType.SET_UPDATED_ADMIN_USER,
    payload,
  };
};

export const fetchAdminCategoryRoleList = () => {
  return {
    type: AdminUserType.FETCH_ADMIN_CATEGORY_ROLE_LIST,
  }
};

export const setAdminCategoryRoleList = payload => {
  return {
    type: AdminUserType.SET_ADMIN_CATEGORY_ROLE_LIST,
    payload,
  }
};

export const setAdminCategoryRoleLoader = payload => {
  return {
    type: AdminUserType.ADMIN_CATEGORY_ROLE_LIST_LOADER,
    payload,
  }
};

