import React from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { isIOS, isMobileOnly } from 'react-device-detect';
import { Helmet } from 'react-helmet';
import Producer from './routes/producer';
import { Dialog, DialogActions, DialogContent } from '@material-ui/core';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import IntlMessages from 'util/intl-messages';
import Button from '@material-ui/core/Button';
import { clearImpersonateMode } from 'redux/actions/impersonate';
import Admin from './routes/admin';
import { withStyles } from '@material-ui/core/styles';
import Footer from 'components/footer';
import Header from 'components/header/index';
import Sidebar from 'containers/side-nav/index';
import TopNav from 'components/top-nav';
import asyncComponent from 'util/async-component';
import { isNull, isUndefined } from 'lodash';
import { getAuthDetails } from 'util/extra';
import {
  ABOVE_THE_HEADER,
  BELOW_THE_HEADER,
  COLLAPSED_DRAWER,
  FIXED_DRAWER,
  HORIZONTAL_NAVIGATION,
} from 'constants/action-types';
import { ADMIN, PRIMARY } from 'constants/constant';
import CrossSellIframe from 'components/cse-announcement-modal/cross-sell-iframe';
import AnnouncementModal from 'components/cse-announcement-modal/announcement-modal/index';
import ErrorBoundary from '../components/error-boundary';

const styles = () => ({
  root: {
    height: '100%',
    flex: '1 1',
  },
});

class App extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      openConfirmationDialog: false,
    };
  }

  componentDidMount = () => {
    const { dashboard, history, updateDefaultPassword } = this.props;
    if (!dashboard || updateDefaultPassword) {
      history.push('/post-signup');
    }
  };

  UNSAFE_componentWillReceiveProps() {
    if (
      window.location.pathname === localStorage.getItem('activeUrl') &&
      JSON.parse(localStorage.getItem('isImpersonatedDataPresent'))
    ) {
      window.history.go(1);
    }
    window.addEventListener('popstate', this.confirmationPromptOnback);
  }

  confirmationPromptOnback = () => {
    let routeToAdmin =
      window.location.pathname === localStorage.getItem('activeUrl');
    let impersonatedDataPresent =
      !isNull(localStorage.getItem('isImpersonatedDataPresent')) &&
      !isUndefined(localStorage.getItem('isImpersonatedDataPresent')) &&
      JSON.parse(localStorage.getItem('isImpersonatedDataPresent'));
    routeToAdmin &&
    impersonatedDataPresent &&
    this.setState({ openConfirmationDialog: true });
  };

  backClickAction = flag => {
    const { history } = this.props;
    if (flag) {
      this.props.clearImpersonateMode();
    } else {
      history.push(window.location.pathname);
    }
    this.setState({ openConfirmationDialog: false });
  };

  render() {
    const {
      match,
      classes,
      userRole,
      drawerType,
      navigationStyle,
      horizontalNavPosition,
      isImpersonate,
      shouldShowFastStart,
    } = this.props;
    const { openConfirmationDialog } = this.state;
    const drawerStyle = drawerType.includes(FIXED_DRAWER)
      ? 'fixed-drawer'
      : drawerType.includes(COLLAPSED_DRAWER)
        ? 'collapsible-drawer'
        : 'mini-drawer';

    // Set default height and overflow for iOS mobile Safari 10+ support.
    if (isIOS || isMobileOnly) {
      document.body.classList.add('ios-mobile-view-height');
    } else if (document.body.classList.contains('ios-mobile-view-height')) {
      document.body.classList.remove('ios-mobile-view-height');
    }

    const isfastStart =
      window.location.href.includes('fast-start') && shouldShowFastStart;
    return (
      <div className={`app-container ${drawerStyle}`}>
        <Helmet defaultTitle='First Connect' />
        <Sidebar {...this.props} />
        <div className='app-main-container'>
          <div
            className={`app-header  ${
              navigationStyle === HORIZONTAL_NAVIGATION
                ? 'app-header-horizontal'
                : ''
            }`}
          >
            {navigationStyle === HORIZONTAL_NAVIGATION &&
              horizontalNavPosition === ABOVE_THE_HEADER && (
                <TopNav styleName='app-top-header' />
              )}
            <Header />
            {navigationStyle === HORIZONTAL_NAVIGATION &&
              horizontalNavPosition === BELOW_THE_HEADER && <TopNav />}
          </div>
          <ErrorBoundary>
            <main className='app-main-content-wrapper'>
              <div className='app-main-content'>
                <div
                  className={`app-wrapper ${classes.root} ${
                    isfastStart ? 'fast-start-bg' : ''
                  } ${isIOS && 'tablet-view'}`}
                >
                  {!this.props?.isCrossSellExperience ? (
                    <Switch>
                      <Route
                        path={`${match.url}/${userRole}`}
                        component={
                          userRole === ADMIN
                            ? isImpersonate
                              ? Producer
                              : Admin
                            : Producer
                        }
                      />

                      <Route
                        component={asyncComponent(() =>
                          import('components/error-404'),
                        )}
                      />
                    </Switch>
                  ) : (
                    <>
                      <AnnouncementModal />
                      <CrossSellIframe />
                    </>
                  )}
                </div>
                <Dialog className='warning-wraper' open={openConfirmationDialog}>
                  <DialogContent className='divider pb-16'>
                    <div className='heading-label'>
                      <ReportProblemOutlinedIcon className='text-warning mr-2' />
                      <IntlMessages id='sweetAlerts.warning'></IntlMessages>
                    </div>
                    <IntlMessages id='warning.exit.impersonate.mode' />
                  </DialogContent>
                  <DialogActions className='d-flex justify-content-center'>
                    <Button
                      onClick={() => this.backClickAction(true)}
                      color={PRIMARY}
                      autoFocus
                      className='text-blue cancel-button m-2'
                    >
                      <IntlMessages id='button.yes' />
                    </Button>
                    <Button
                      onClick={() => this.backClickAction(false)}
                      color={PRIMARY}
                      autoFocus
                      className='bg-primary m-2'
                    >
                      <IntlMessages id='button.no' />
                      &nbsp;
                    </Button>
                  </DialogActions>
                </Dialog>
              </div>
              <Footer />
            </main>
          </ErrorBoundary>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ settings, auth, impersonate }) => {
  const {
    themeColor,
    drawerType,
    navigationStyle,
    horizontalNavPosition,
  } = settings;
  const { producer, isImpersonate } = impersonate;
  const { userRole } = getAuthDetails({
    auth,
    producer,
    isImpersonate,
  });
  const {
    dashboard,
    updateDefaultPassword,
    isCrossSellExperience,
    shouldShowFastStart,
  } = auth;
  return {
    dashboard,
    userRole,
    themeColor,
    drawerType,
    navigationStyle,
    horizontalNavPosition,
    isImpersonate,
    updateDefaultPassword,
    isCrossSellExperience,
    shouldShowFastStart,
  };
};
export default withRouter(
  connect(mapStateToProps, { clearImpersonateMode })(withStyles(styles)(App)),
);
