export const REFRESH_STATE_ROUTE = '/refresh-state';
export const SIGNIN_ROUTE = '/signin';
export const CLEAR_IMPERSONATE = '/clear-impersonate';
export const START_ROUTE = '/start';
export const NEW_AUTH_SIGN_UP_ROUTE = '/new-auth/sign-up';
export const NEW_AUTH_SIGN_IN_ROUTE = '/new-auth/sign-in';
{
  /* TO be removed after SIGNOUT route is deployed; kept to avoid breaking change */
}
export const POST_SIGNOUT_ROUTE = '/post-signout';
export const SIGNOUT_ROUTE = '/signout';
export const REAUTHENTICATE_ROUTE = '/reauthenticate';
