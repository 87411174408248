import * as types from '../../constants/action-types';
import { isEmpty } from 'lodash';

const initState = () => ({
  advanceSearchList: [],
  agentComplianceListUpdateLoader: false,
  agentList: [],
  errorOmissionList: [],
  openBankInfo: false,
  opneCredentialTab: false,
  filterCounts: {
    all: !isEmpty(JSON.parse(localStorage.getItem('producerStatusAggregate')))
      ? JSON.parse(localStorage.getItem('producerStatusAggregate')).all
      : 0,
    active: !isEmpty(
      JSON.parse(localStorage.getItem('producerStatusAggregate'))
    )
      ? JSON.parse(localStorage.getItem('producerStatusAggregate')).active
      : 0,
    pending: !isEmpty(
      JSON.parse(localStorage.getItem('producerStatusAggregate'))
    )
      ? JSON.parse(localStorage.getItem('producerStatusAggregate')).pending
      : 0,
    restricted: !isEmpty(
      JSON.parse(localStorage.getItem('producerStatusAggregate'))
    )
      ? JSON.parse(localStorage.getItem('producerStatusAggregate')).restricted
      : 0,
    suspend: !isEmpty(
      JSON.parse(localStorage.getItem('producerStatusAggregate'))
    )
      ? JSON.parse(localStorage.getItem('producerStatusAggregate')).suspend
      : 0,
    dormant: !isEmpty(
      JSON.parse(localStorage.getItem('producerStatusAggregate'))
    )
      ? JSON.parse(localStorage.getItem('producerStatusAggregate')).dormant
      : 0,
    terminated: !isEmpty(
      JSON.parse(localStorage.getItem('producerStatusAggregate'))
    )
      ? JSON.parse(localStorage.getItem('producerStatusAggregate')).terminated
      : 0,
    incompleteSignup: !isEmpty(
      JSON.parse(localStorage.getItem('producerStatusAggregate'))
    )
      ? JSON.parse(localStorage.getItem('producerStatusAggregate')).incomplete
      : 0,
    errorOmission: !isEmpty(
      JSON.parse(localStorage.getItem('producerStatusAggregate'))
    )
      ? JSON.parse(localStorage.getItem('producerStatusAggregate')).eno
      : 0,
    taxAgent: !isEmpty(
      JSON.parse(localStorage.getItem('producerStatusAggregate'))
    )
      ? JSON.parse(localStorage.getItem('producerStatusAggregate')).w9
      : 0,
    npn: !isEmpty(JSON.parse(localStorage.getItem('producerStatusAggregate')))
      ? JSON.parse(localStorage.getItem('producerStatusAggregate')).npn
      : 0,
    regulatoryAction: !isEmpty(
      JSON.parse(localStorage.getItem('producerStatusAggregate'))
    )
      ? JSON.parse(localStorage.getItem('producerStatusAggregate'))
          .regulatory_action
      : 0,
    needsApproval: !isEmpty(
      JSON.parse(localStorage.getItem('producerStatusAggregate'))
    )
      ? JSON.parse(localStorage.getItem('producerStatusAggregate'))
          .needsApproval
      : 0,
  },
  incompleteProducerDetails: {
    producer_id: '',
    producer_code: '',
    redirectMyAccount: false,
  },
  incompleteSignupList: [],
  licenseAgentList: [],
  listLoader: false,
  loaStateList: [],
  loaStateListLoader: false,
  needsApprovalList: [],
  saveAgentStatusLoader: false,
  taxAgentList: [],
  updateProducerLoader: false,
  fileUploadError: null,
  npnAgentList: [],
  searchList: '',
  rowSize: 10,
  pageNumber: 1,
  hubspotIdsDetailsLoader: false,
  isHubspotIdsDetailsSaved: false,
});

export default (state = initState(), action) => {
  switch (action.type) {
    case types.INIT_URL: {
      return { ...state, initURL: action.payload };
    }

    case types.SET_AGENT_LIST: {
      const agentList = !isEmpty(action.payload.data)
        ? action.payload.data
        : [];

      return {
        ...state,
        agentList,
        advanceSearchList: action.payload.data,
      };
    }

    case types.AGENT_LIST_LOADER: {
      return {
        ...state,
        listLoader: action.payload,
      };
    }

    case types.AGENT_UPDATE_LOADER: {
      return {
        ...state,
        updateProducerLoader: action.payload,
      };
    }

    case types.SET_AGENT_COMPLIANCE_LIST: {
      const { entity, data } = action.payload;
      return {
        ...state,
        [entity]: [...data],
      };
    }

    case types.AGENT_COMPLIANCE_LIST_UPDATE_LOADER: {
      return {
        ...state,
        agentComplianceListUpdateLoader: action.payload,
      };
    }

    case types.SET_COMPLETE_AGENT_PROFILE: {
      let producerData = action.payload;
      return {
        ...state,
        incompleteProducerDetails: {
          ...state.incompleteProducerDetails,
          producer_id: producerData.agentId,
          producer_code: producerData.agentCode,
          redirectMyAccount: producerData.redirectMyAccount,
        },
      };
    }

    // For licenseAgentList Model Approve remove
    case types.UPDATE_LICESE_DETAILS_MODAL: {
      let index = action.payload.index;
      let muteobj = [...state.licenseAgentList[index].licenses];
      muteobj = action.payload.data;
      state.licenseAgentList[index].licenses = muteobj;
      return {
        ...state,
      };
    }

    case types.SET_AGENT_STATUS_LOADER: {
      return {
        ...state,
        saveAgentStatusLoader: action.payload,
      };
    }

    case types.SET_AGENT_ERROR_MESSAGES: {
      return {
        ...state,
        saveAgentErrorMessages: action.payload,
      };
    }
    case types.LICENSE_LOA_STATE_LIST_LOADER: {
      return {
        ...state,
        loaStateListLoader: action.payload,
      };
    }
    case types.SET_LICENSE_LOA_STATE_LIST: {
      return {
        ...state,
        loaStateList: action.payload,
      };
    }
    case types.SET_NEEDS_APPROVAL_LIST: {
      const { data } = action.payload;
      return {
        ...state,
        needsApprovalList: data,
      };
    }
    case types.UPDATE_FILTER_COUNT: {
      let producerStatusAggregate = JSON.parse(
        localStorage.getItem('producerStatusAggregate')
      );
      return {
        ...state,
        filterCounts: {
          ...state.filterCounts,
          all: producerStatusAggregate.all,
          active: producerStatusAggregate.active || 0,
          pending: producerStatusAggregate.pending || 0,
          restricted: producerStatusAggregate.restricted || 0,
          suspend: producerStatusAggregate.suspend || 0,
          dormant: producerStatusAggregate.dormant || 0,
          terminated: producerStatusAggregate.terminated || 0,
          errorOmission: producerStatusAggregate.eno || 0,
          taxAgent: producerStatusAggregate.w9 || 0,
          license: producerStatusAggregate.npn || 0,
          needsApproval: producerStatusAggregate.needsApproval || 0,
          regulatoryAction: producerStatusAggregate.regulatoryAction || 0,
          incompleteSignup: producerStatusAggregate.incomplete || 0,
        },
      };
    }
    case types.SET_COMPLIANCE_FILE_UPLOAD_ERROR: {
      return {
        ...state,
        fileUploadError: action.payload,
      };
    }
    case types.SET_OPEN_BANK_INFO: {
      return {
        ...state,
        openBankInfo: action.payload,
      };
    }
    case types.SET_OPEN_CREDENTIAL_TAB: {
      return {
        ...state,
        opneCredentialTab: action.payload,
      };
    }
    case types.SET_TOTAL_PRODUCER_COUNT: {
      return {
        ...state,
        totalProducers: action.payload,
      };
    }
    case types.SET_HUBSPOT_IDS_DETAIL_LOADER: {
      return {
        ...state,
        hubspotIdsDetailsLoader: action.payload,
      };
    }
    case types.SET_HUBSPOT_IDS_DETAIL_SAVED: {
      return {
        ...state,
        isHubspotIdsDetailsSaved: action.payload,
      };
    }

    default:
      return state;
  }
};
