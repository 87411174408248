import * as types from '../../constants/action-types';

const initState = () => ({
  productListLoader: false,
  productList: [],
  productCategories: [],
  addNewProductLoader: false,
  updateProductLoader: false,
  isProductFileUploadError: false,
});

export default (state = initState(), action) => {
  switch (action.type) {
    case types.INIT_URL: {
      return { ...state, initURL: action.payload };
    }

    case types.SET_PRODUCT_LIST: {
      return {
        ...state,
        productList: [...action.payload],
      };
    }

    case types.SET_PRODUCT_CATEGORIES: {
      return {
        ...state,
        productCategories: action.payload,
      };
    }

    case types.SET_PRODUCT_LIST_LOADER: {
      return {
        ...state,
        productListLoader: action.payload,
      };
    }

    case types.NEW_PRODUCT_ADD_LOADER: {
      return {
        ...state,
        addNewProductLoader: action.payload,
      };
    }

    case types.SET_NEW_PRODUCT: {
      return {
        ...state,
        productListLoader: false,
        productList: [action.payload, ...state.productList],
      };
    }
    case types.UPDATE_PRODUCT_LOADER: {
      return {
        ...state,
        updateProductLoader: action.payload,
      };
    }

    case types.SET_UPDATED_PRODUCT: {
      return {
        ...state,
        productListLoader: false,
        productList: [...action.payload],
      };
    }

    case types.PRODUCT_FILE_UPLOAD_ERROR: {
      return {
        ...state,
        isProductFileUploadError: action.payload,
      };
    }

    case types.PRODUCT_SERVER_ERROR: {
      return {
        ...state,
        productServerError: action.payload,
      };
    }
    case types.PRODUCT_SERVER_ERROR_MESSAGE: {
      return {
        ...state,
        productServerErrorMessage: action.payload,
      };
    }
    default:
      return state;
  }
};
