import { cloneDeep, isEmpty } from 'lodash';
import { displayErrorMessage, displaySuccessMessage } from 'util/user-feedback';
import { all, call, fork, put, select, takeEvery } from 'redux-saga/effects';
import {
  clearCarrierResponse,
  imageUploadError,
  setNewCarrierProduct,
} from '../actions/carrier-details';
import { errorHandler } from '../actions/error';
import * as carrierListActions from 'constants/action-types';
import axios from 'util/api';
import { uploadMultipleFile } from 'util/extra';
import {
  fetchCarrierProductLoader,
  setCarrierOnboardList,
  setCarrierOnboardListLoader,
  setCarrierProducts,
  setNewCarrier,
  setNewCarrierLoader,
  setNewProductLoader,
} from '../actions/carrier-on-board-list';
import {
  ALL_STATES,
  CARRIER_FILE_TEMP_FOLDER,
  FILE,
  LOGO,
  STATIC_ASSETS,
} from 'constants/constant';

// ---------------- FETCH CARRIER ONBOARD LIST-----------------
function* fetchCarrierOnboardList() {
  try {
    yield put(setCarrierOnboardListLoader(true));
    const carrierDetails = yield axios.get(
      '/api/v1/carrier/get/carrier-details'
    );
    if (!!carrierDetails) {
      const { status, data } = carrierDetails;
      if (status === 200) {
        const carrierList = carrierDetails.data.carriers;
        yield put(setCarrierOnboardList({ carrierList, success: true }));
      } else {
        yield put(setCarrierOnboardList({ success: false }));
        yield displayErrorMessage(data.message);
      }
    }
  } catch (error) {
    if (!!error.response && error.response.status === 401) {
yield displayErrorMessage(error.response.data.message)
      yield put(errorHandler(error));
    } else {
      yield put(errorHandler(error));
    }
  }
  yield put(setCarrierOnboardListLoader(false));
}

export function* fetchCarrierOnboardListWatcher() {
  yield takeEvery(
    carrierListActions.FETCH_CARRIER_ONBOARD_LIST,
    fetchCarrierOnboardList
  );
}

// ---------------- FETCH ALL PRODUCTS LIST-----------------
function* fetchCarrierProductsList() {
  try {
    yield put(fetchCarrierProductLoader(true));
    const productDetails = yield axios.get('/api/v1/carrier/all-products');
    if (productDetails.status === 200 && productDetails.data.success) {
      const productsList = productDetails.data.products;
      yield put(setCarrierProducts(productsList));
    } else {
      yield displayErrorMessage(productDetails.data.message);
      yield put(fetchCarrierProductLoader(false));
    }
  } catch (error) {
    if (!!error.response && error.response.status === 401) {
yield displayErrorMessage(error.response.data.message)
      yield put(errorHandler(error));
    } else {
      yield put(errorHandler(error));
    }
  }
  yield put(fetchCarrierProductLoader(false));
}

export function* fetchCarrierProductsListWatcher() {
  yield takeEvery(
    carrierListActions.FETCH_CARRIER_PRODUCTS_LIST,
    fetchCarrierProductsList
  );
}

function* addNewCarrierProduct({ payload }) {

  let copyCommission = cloneDeep(payload.commissions);

  const updatedCommission = copyCommission.map(item => {
    const { state_abbrevation, ...rest } = item;
    return {
      ...rest,
      state_abbrevation:
        state_abbrevation === ALL_STATES ? null : state_abbrevation,
    };
  });

  const reduxState = yield select();
  let { carrierMarkets } = reduxState.carrierDetails;
  let updateMarketList = [];
  payload.markets.filter(item => {
    return !carrierMarkets.includes(item) && updateMarketList.push(item);
  });
  try {
    yield put(setNewProductLoader(true));
    const {
      commission_type,
      FK_carrier_id,
      FK_product_id,
      markets,
      payment_type,
      carrier_product_code,
      commission_tiers_enabled,
      is_available_for_quoting,
    } = payload;
    const response = yield axios.post('/api/v1/carrier/carrier-product', {
      FK_carrier_id,
      FK_product_id,
      payment_type,
      markets,
      commissions: updatedCommission,
      commission_type,
      carrier_product_code,
      commission_tiers_enabled,
      is_available_for_quoting,
    });
    if (response.status === 200 && response.data.success) {
      if (updateMarketList.length > 0) {
        carrierMarkets = carrierMarkets.concat(updateMarketList);
      }
      const { product } = response.data;
      yield put(
        setNewCarrierProduct({ product, carrierMarkets: carrierMarkets })
      );
      yield displaySuccessMessage(response.data.message);
    } else {
      yield displayErrorMessage(response.data.message);
      yield put(setNewProductLoader(false));
    }
  } catch (error) {
    if (!!error.response && error.response.status === 401) {
yield displayErrorMessage(error.response.data.message)
      yield put(errorHandler(error));
    } else {
      yield put(errorHandler(error));
    }
  }
  yield put(setNewProductLoader(false));
}

export function* addNewCarrierProductWatcher() {
  yield takeEvery(carrierListActions.ADD_CARRIER_PRODUCT, addNewCarrierProduct);
}

function* fetchNewCarrierData({ payload }) {
  try {
    yield put(setNewCarrierLoader(true));
    yield put(clearCarrierResponse());
    const reduxState = yield select();
    let producerId = reduxState.auth.producer_id;
    let producer = {
      firstname: reduxState.auth.loginName,
      lastname: reduxState.auth.userLastName,
    };
    const { logoData, credential_preferences, carrier_id } = payload;
    let file = credential_preferences.helpUrl?.file;
    let fileName = credential_preferences.helpUrl?.fileName;
    let carrierLogoUrl = logoData;
    let helpFileUrl = file;
    let multipleUrl = [];
    let files = [];
    let filePath = [];
    if (!isEmpty(logoData)) {
      let fileURL = `${STATIC_ASSETS}/carrier-logos/${logoData[0].name}`;
      filePath.push(fileURL);
      files.push(logoData);
      multipleUrl.push({
        type: LOGO,
      });
    }
    const folderName = isEmpty(carrier_id)
      ? CARRIER_FILE_TEMP_FOLDER
      : carrier_id;
    if (Array.isArray(file)) {
      let fileURL = `carriers/${folderName}/credential-help-documents/${file[0].name}`;
      filePath.push(fileURL);
      files.push(file);
      multipleUrl.push({
        type: FILE,
      });
    }
    const result = yield call(
      uploadMultipleFile,
      files,
      JSON.stringify(filePath)
    );
    if (result && result.status === 200) {
      const { files: file_url } = result.data;
      for (let item of multipleUrl) {
        if (item.type === LOGO) {
          carrierLogoUrl = file_url[0];
        }
        if (item.type === FILE) {
          helpFileUrl =
            !isEmpty(multipleUrl) && multipleUrl?.length === 1
              ? file_url[0]
              : file_url[1];
          fileName =
            !isEmpty(multipleUrl) && multipleUrl?.length === 1
              ? logoData[0]?.name ?? ''
              : file[0]?.name ?? '';
        }
      }
      let data = Object.assign({}, payload);
      let carrierData = Object.assign({}, data);
      carrierData.credential_preferences.helpUrl = {
        isEnabled: data?.credential_preferences?.helpUrl?.isEnabled,
        url: helpFileUrl,
        fileName: fileName,
      };
      delete data.logoData;
      let newPayload = {
        carrier: {
          ...data,
          logo_url: carrierLogoUrl,
          created_by: producerId,
          updated_by: producerId,
        },
      };
      const response = yield axios.post('/api/v1/carrier/add-carrier', {
        carrier: newPayload.carrier,
      });
      if (response.status === 200 && response.data.success) {
        const { carrier, message } = response.data;
        carrier.producer = producer;
        carrier.responseMessage = message;
        yield put(setNewCarrier({ ...carrier }));
        yield displaySuccessMessage(message);
        yield put(setNewCarrierLoader(false));
      } else {
        yield displayErrorMessage(response.data.message);
        yield put(setNewCarrierLoader(false));
      }
      yield put(imageUploadError(false));
    } else {
      yield put(errorHandler(result));
      yield put(imageUploadError(true));
    }
  } catch (error) {
    if (!!error.response && error.response.status === 401) {
yield displayErrorMessage(error.response.data.message)
      yield put(errorHandler(error));
    } else {
      yield put(errorHandler(error));
    }
  }
  yield put(setNewCarrierLoader(false));
}

export function* fetchNewCarrierWatcher() {
  yield takeEvery(carrierListActions.ADD_NEW_CARRIER, fetchNewCarrierData);
}

// ------ ROOT SAGA -----------------
export default function* rootSaga() {
  yield all([
    fork(fetchCarrierOnboardListWatcher),
    fork(fetchNewCarrierWatcher),
    fork(fetchCarrierProductsListWatcher),
    fork(addNewCarrierProductWatcher),
  ]);
}
