import {
  debouncedTriggerErrorSnackbar,
  triggerInfoSnackbar,
  triggerSuccessSnackbar,
} from './snackbar';

// Consider wrapping with one `displaySnackbarFeedback` function.

/**
 * Display success snackbar.
 * @param {string} message
 */
export const displaySuccessMessage = (message) => {
  triggerSuccessSnackbar(message);
};

/**
 * Display info snackbar.
 * @param {string} message
 */
export const displayInfoMessage = (message) => {
  triggerInfoSnackbar(message);
};

const mapErrorMessage = (message) => {
  const lowerCaseMsg = message.toLowerCase();
  if (
    lowerCaseMsg.includes('not authorized to access the api') ||
    lowerCaseMsg.includes('token payload does not have') ||
    lowerCaseMsg.includes('request token is invalid')
  ) {
    return 'Your session has expired. Please sign in again.';
  }

  return message;
};

const isIgnoredMessage = (message) => {
  const lowerCaseMsg = message.toLowerCase();
  if (
    lowerCaseMsg.includes('step-up forbidden')
  ) {
    return true;
  }

  return false;
}

/**
 * Map error message and display error snackbar.
 * @param {string} message
 */
export const displayErrorMessage = (message) => {
  if (message == null) {
    console.warn('displayErrorMessage: message is null or undefined');
    return;
  }

  if (isIgnoredMessage(message)) {
    return;
  }

  const mappedMessage = mapErrorMessage(message);

  // eslint-disable-next-line no-console
  console.error(mappedMessage);

  // debounce to avoid snacbkar spam
  debouncedTriggerErrorSnackbar(mappedMessage);
};
