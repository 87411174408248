import { displayErrorMessage, displaySuccessMessage } from 'util/user-feedback';
import { all, fork, put, select, takeEvery } from 'redux-saga/effects';
import * as userPermissionActions from 'constants/action-types';
import { INACTIVE_STATUS, PENDING_STATUS } from 'constants/constant';
import { errorHandler } from '../actions/error';
import axios from 'util/api';
import {
  addNewUserLoader,
  fetchRolesListLoader,
  setNewUser,
  setRolesList,
  setUserPermissionList,
  setUserPermissionLoader,
  setUserUpdatedData,
} from '../actions/user-permission';

// ----------- FETCH USER LIST --------------------
function* fetchUserPermissionList() {
  try {
    yield put(setUserPermissionLoader(true));
    const response = yield axios.get('/api/v1/user/users');
    const { data } = response;
    if (response.status === 200 && response.data) {
      let rowList = [];
      if (data.users.length > 0) {
        rowList = data.users.map(item => {
          if (item.status === INACTIVE_STATUS) {
            item.status = PENDING_STATUS;
          }
          return item;
        });
      }
      yield put(setUserPermissionList(rowList));
    } else {
      yield displayErrorMessage(data.message);
      yield put(setUserPermissionLoader(false));
    }
  } catch (error) {
    if (!!error.response && error.response.status === 401) {
yield displayErrorMessage(error.response.data.message)
      yield put(errorHandler(error));
    } else {
      yield put(errorHandler(error));
    }
  }
  yield put(setUserPermissionLoader(false));
}

export function* fetchUserPermissionListWatcher() {
  yield takeEvery(
    userPermissionActions.FETCH_USER_PERMISSION_LIST,
    fetchUserPermissionList
  );
}

// ----------- ADD NEW USER -----------------
function* addNewUser({ payload }) {
  const { firstName, lastName, email, role } = payload;
  const reduxState = yield select();
  let producer = {
    firstname: reduxState.auth.loginName,
    lastname: reduxState.auth.userLastName,
  };
  const userData = {
    firstname: firstName,
    lastname: lastName,
    login_password: null,
    email: email,
    phone: null,
    role: role,
    status: INACTIVE_STATUS,
  };
  try {
    yield put(addNewUserLoader(true));
    const response = yield axios.post('/api/v1/user/add-user', userData);
    const { data, status } = response;
    if (status === 200 && data.success) {
      data.user.status === INACTIVE_STATUS &&
        (data.user.status = PENDING_STATUS);
      data.user.user = producer;
      yield put(setNewUser(data));
      yield displaySuccessMessage(data.message);
    } else {
      yield displayErrorMessage(data.message);
      yield put(addNewUserLoader(false));
    }
  } catch (error) {
    if (!!error.response && error.response.status === 401) {
yield displayErrorMessage(error.response.data.message)
      yield put(errorHandler(error));
    } else {
      yield put(errorHandler(error));
    }
  }
  yield put(addNewUserLoader(false));
}

export function* addNewUserWatcher() {
  yield takeEvery(userPermissionActions.ADD_NEW_USER, addNewUser);
}

function* getPermissionRolesList() {
  try {
    yield put(fetchRolesListLoader(true));
    const response = yield axios.get('/api/v1/permission/all-roles');
    const { data } = response;
    if (response.status === 200 && data.success) {
      yield put(setRolesList(data.roles));
    } else {
      yield put(fetchRolesListLoader(false));
    }
  } catch (error) {
    if (!!error.response && error.response.status === 401) {
yield displayErrorMessage(error.response.data.message)
      yield put(errorHandler(error));
    } else {
      yield put(errorHandler(error));
    }
  }
  yield put(fetchRolesListLoader(false));
}

export function* getPermissionRolesListWatcher() {
  yield takeEvery(
    userPermissionActions.FETCH_ROLES_LIST,
    getPermissionRolesList
  );
}

function* updateUserData({ payload }) {
  const { id, firstName, lastName, email, role, status } = payload;
  const reduxState = yield select();
  let producer = {
    firstname: reduxState.auth.loginName,
    lastname: reduxState.auth.userLastName,
  };
  try {
    const userData = {
      id: id,
      firstname: firstName,
      lastname: lastName,
      email: email,
      role: role,
      status: status === PENDING_STATUS ? INACTIVE_STATUS : status,
      is_active: true,
    };
    const response = yield axios.post('/api/v1/user/update-user', userData);
    const { data } = response;
    if (data.success && response.status === 200) {
      data.user.user = producer;
      yield put(setUserUpdatedData(data));
      yield displaySuccessMessage(data.message);
    } else {
      yield displayErrorMessage(data.message);
    }
  } catch (error) {
    if (!!error.response && error.response.status === 401) {
yield displayErrorMessage(error.response.data.message)
      yield put(errorHandler(error));
    } else {
      yield put(errorHandler(error));
    }
  }
}

export function* updateUserDataWatcher() {
  yield takeEvery(userPermissionActions.UPDATE_USER_DATA, updateUserData);
}

// ------ ROOT SAGA -----------------
export default function* rootSaga() {
  yield all([
    fork(addNewUserWatcher),
    fork(fetchUserPermissionListWatcher),
    fork(getPermissionRolesListWatcher),
    fork(updateUserDataWatcher),
  ]);
}
