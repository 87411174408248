import React from 'react';
import './apetite.scss';
import errorimage from '../../assets/images/errorimage.png';
import { Typography } from '@material-ui/core';

const WCError = () => {
  return (
    <div className="wc-error-wrap">
      <img src={errorimage} alt="error-image" className="wc-error-image" />
      <Typography className="wc-error-label">
        There are no carriers with appetite at this time.
      </Typography>
    </div>
  );
};

export default WCError;
