import * as fastStart from '../../constants/action-types';

export const fastStartDetailLoader = (flag) => ({
  type: fastStart.FAST_START_DETAIL_LOADER,
  payload: flag,
});

export const storeFastStartDetail = (payload) => ({
  type: fastStart.STORE_FAST_START_DETAILS,
  payload,
});

export const fetchFastStartDetails = () => ({
  type: fastStart.FETCH_FAST_START_DETAIL,
});
