import * as agentMessaging from '../../constants/action-types';

export const setMessagingLoader = (payload) => ({
  type: agentMessaging.AGENT_MESSAGING_LOADER,
  payload,
});

export const setMessagingProcessSuccess = (payload) => ({
  type: agentMessaging.AGENT_MESSAGING_PROCESS_SUCCESS,
  payload,
});

export const setAgentMessageState = (payload) => ({
  type: agentMessaging.AGENT_MESSAGE_STATE,
  payload,
});

export const setAgentMessagingCurrentData = (payload) => ({
  type: agentMessaging.APPOINTMENTS_CURRENT_DATA,
  payload,
});

export const agentMessagingFilesUploading = (payload) => {
  return {
    type: agentMessaging.AGENT_MESSAGING_FILES_UPLOADING,
    payload,
  };
};

export const agentMessagingSuccess = (payload) => {
  return {
    type: agentMessaging.AGENT_MESSAGING_SUCCESS,
    payload,
  };
};

export const agentMessagingFilesUploadFailed = () => {
  return {
    type: agentMessaging.AGENT_MESSAGING_FILES_UPLOAD_FAILED,
  };
};

export const fetchAgentMessagingList = () => ({
  type: agentMessaging.FETCH_AGENT_MESSAGING,
});

export const setAgentMessagingProcessing = (payload) => ({
  type: agentMessaging.AGENT_MESSAGING_PROCESSING,
  payload,
});

export const setMessagingList = (payload) => ({
  type: agentMessaging.SET_MESSAGING_LIST,
  payload,
});

export const uploadAgentMessaging = (payload) => {
  return {
    type: agentMessaging.UPLOAD_AGENT_MESSAGING,
    payload,
  };
};

export const uploadAgentNotification = (payload) => {
  return {
    type: agentMessaging.UPLOAD_AGENT_NOTIFICATION,
    payload,
  };
};

export const notificationImageUploadError = (payload) => {
  return {
    type: agentMessaging.NOTIFICATION_IMAGE_UPLOAD_ERROR,
    payload,
  };
};
