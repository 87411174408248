// @ts-check

import { EXPERIMENTAL_MODE_KEY } from 'constants/constant';

export function isExperimentalMode() {
  return (
    window.location.search.includes('experimental=true') ||
    localStorage.getItem(EXPERIMENTAL_MODE_KEY) === 'true' ||
    sessionStorage.getItem(EXPERIMENTAL_MODE_KEY) === 'true'
  );
}
