import { all, delay, fork, put, select, takeEvery } from 'redux-saga/effects';
import axios from 'util/api';
import * as adhocPaymentActions from 'constants/action-types';
import {
  adHocPaymentAddRowDataSuccess,
  adhocPaymentEditTitleSuccess,
  adHocPaymentLoader,
} from 'redux/actions/adhoc-payment';
import { displaySuccessMessage } from 'util/user-feedback';
import { errorHandler } from '../actions/error';

// ---------- Fetch Adhoc payment list ---------
function* fetchAdHocPaymentList() {
  try {
    yield put(adHocPaymentLoader(true));
    const responseDetails = yield axios.get(
      `/api/v1/commission/adhoc-payment-list`
    );
    if (responseDetails.data) {
      yield put(
        adhocPaymentEditTitleSuccess(responseDetails.data.adhocPayment)
      );
    }
  } catch (error) {
    yield put(errorHandler(error));
  }
  yield put(adHocPaymentLoader(false));
}
export function* fetchAdHocPaymentListWatcher() {
  yield takeEvery(
    adhocPaymentActions.FETCH_ADHOC_PAYMENT_LIST,
    fetchAdHocPaymentList
  );
}

function* adHocPaymentAddRowData() {
  try {
    yield put(adHocPaymentLoader(true));
    const responseDetails = yield axios.post(
      'api/v1/commission/add-adhoc-payment',
      {}
    );
    if (responseDetails.data) {
      yield put(
        adHocPaymentAddRowDataSuccess(responseDetails.data.adhocPayment)
      );
    }
  } catch (error) {
    yield put(errorHandler(error));
  }
  yield put(adHocPaymentLoader(false));
}

export function* adHocPaymentAddRowDataWatcher() {
  yield takeEvery(
    adhocPaymentActions.ADHOC_PAYMENT_ADD_ROW,
    adHocPaymentAddRowData
  );
}

function* adhocPaymentEditTitle({ payload: { newData, oldData } }) {
  try {
    yield put(adHocPaymentLoader(true));
    yield delay(2000);

    const responseDetails = yield axios.post(
      `/api/v1/commission/update-adhoc-title`,
      { adhoc_payment_id: oldData.id, title_name: newData.title }
    );
    if (responseDetails.data) {
      const reduxState = yield select();
      const { adhocPaymentList } = reduxState.adhocPayments;
      let adhocPaymentListData = adhocPaymentList;

      let muteobj = { ...adhocPaymentListData[oldData.tableData.id] };
      muteobj = {
        ...muteobj,
        title: newData.title,
      };
      adhocPaymentListData[oldData.tableData.id] = muteobj;

      yield put(adhocPaymentEditTitleSuccess(adhocPaymentListData));
      yield displaySuccessMessage(responseDetails.data.message);
    }
  } catch (error) {
    yield put(errorHandler(error));
  }
  yield put(adHocPaymentLoader(false));
}

export function* adhocPaymentEditTitleFunWatcher() {
  yield takeEvery(
    adhocPaymentActions.ADHOC_PAYMENT_EDIT_TITLE,
    adhocPaymentEditTitle
  );
}

export function* adhocPaymentDelete(payload) {
  try {
    yield put(adHocPaymentLoader(true));

    const responseDetails = yield axios.post(
      `/api/v1/commission/delete-adhoc-payment`,
      { adhoc_payment_id: payload.payload.id }
    );
    if (responseDetails.data) {
      const reduxState = yield select();
      const { adhocPaymentList } = reduxState.adhocPayments;
      let adhocPaymentListData = adhocPaymentList;

      let muteobj = { ...adhocPaymentListData[payload.payload.tableData.id] };
      muteobj = {
        ...muteobj,
        id: payload.payload.id,
        title: payload.payload.title,
        uploaded_file: null,
        generated_file: null,
        total_records: null,
        processed_records: null,
        processed_at: null,
        processed_by: null,
        status: null,
        file_processing_status: null,
        summary_statement: null,
        created_at: null,
      };
      adhocPaymentListData[payload.payload.tableData.id] = muteobj;
      yield put(adhocPaymentEditTitleSuccess(adhocPaymentListData));
      yield displaySuccessMessage(responseDetails.data.message);
    }
  } catch (error) {
    yield put(errorHandler(error));
  }
  yield put(adHocPaymentLoader(false));
}

export function* adhocPaymentDeleteWatcher() {
  yield takeEvery(
    adhocPaymentActions.FETCH_ADHOC_PAYMENT_LIST_DELETE,
    adhocPaymentDelete
  );
}

export function* adhocPaymentLockPayout(payload) {
  try {
    yield put(adHocPaymentLoader(true));

    const responseDetails = yield axios.post(
      `/api/v1/commission/lock-adhoc-payout-payment`,
      { adhoc_payment_id: payload.payload.id }
    );
    if (responseDetails.data) {
      const reduxState = yield select();
      const { adhocPaymentList } = reduxState.adhocPayments;
      let adhocPaymentListData = adhocPaymentList;

      let muteobj = { ...adhocPaymentListData[payload.payload.tableData.id] };

      muteobj = {
        ...muteobj,
        status: 'Paid',
      };

      adhocPaymentListData[payload.payload.tableData.id] = muteobj;

      yield put(adhocPaymentEditTitleSuccess(adhocPaymentListData));
      yield displaySuccessMessage(responseDetails.data.message);
    }
  } catch (error) {
    yield put(errorHandler(error));
  }
  yield put(adHocPaymentLoader(false));
}

export function* adhocPaymentLockPayoutWatcher() {
  yield takeEvery(
    adhocPaymentActions.ADHOC_PAYMENT_LOCK_PAYOUT,
    adhocPaymentLockPayout
  );
}

// ------ ROOT SAGA -----------------
export default function* rootSaga() {
  yield all([
    fork(fetchAdHocPaymentListWatcher),
    fork(adHocPaymentAddRowDataWatcher),
    fork(adhocPaymentEditTitleFunWatcher),
    fork(adhocPaymentDeleteWatcher),
    fork(adhocPaymentLockPayoutWatcher),
  ]);
}
