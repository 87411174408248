import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';

const HtmlTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: 'rgba(0,0,0,0.5)',
    color: '#FFFFFF',
    fontSize: theme.typography.pxToRem(12),
    fontWeight: 400,
  },
}))(Tooltip);

export default HtmlTooltip;
