import * as q2Incentive from '../../constants/action-types';

export const q2IncentiveDetailLoader = (flag) => ({
  type: q2Incentive.Q2_INCENTIVE_DETAIL_LOADER,
  payload: flag,
});

export const storeQ2IncentiveDetail = (payload) => ({
  type: q2Incentive.STORE_Q2_INCENTIVE_DETAILS,
  payload,
});

export const storeQ2IncentiveInitialDetail = (payload) => ({
  type: q2Incentive.Q2_INCENTIVE_INITIAL_DETAIL,
  payload,
});

export const fetchQ2IncentiveDetails = () => ({
  type: q2Incentive.FETCH_Q2_INCENTIVE_DETAIL,
});

export const addQ2IncentivePolicy = (payload) => ({
  type: q2Incentive.ADD_Q2_INCENTIVE_POLICIES,
  payload,
});

export const setAddQ2IncentivePolicyLoader = (data) => ({
  type: q2Incentive.ADD_Q2_INCENTIVE_POLICIES_LOADER,
  payload: data,
});

export const setAddedQ2IncentiveDetails = (data) => ({
  type: q2Incentive.ADDED_Q2_INCENTIVE_DETAILS,
  payload: data,
});

export const setIsQ2IncentiveAdded = (data) => ({
  type: q2Incentive.SET_IS_Q2_INCENTIVE_ADDED_FLAG,
  payload: data,
});

export const setQ2IncentivePolicyListLoader = (data) => ({
  type: q2Incentive.SET_Q2_INCENTIVE_POLICY_LIST_LOADER,
  payload: data,
});

export const setQ2IncentivePolicyList = (data) => ({
  type: q2Incentive.SET_Q2_INCENTIVE_POLICY_LIST,
  payload: data,
});

export const fetchQ2IncentivePolicies = () => ({
  type: q2Incentive.FETCH_Q2_INCENTIVE_POLICIES,
});

export const viewQ2IncentiveModal = (payload) => ({
  type: q2Incentive.SHOW_Q2_INCENTIVE_MODAL,
  payload,
});

export const setQ2IncentiveModalViewed = (payload) => ({
  type: q2Incentive.SET_Q2_INCENTIVE_MODAL_VIEWED,
  payload,
});
