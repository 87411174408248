// @ts-check

import { makeStyles } from '@material-ui/core';

// prefer isolated !important over adjusting global styles
export const useStyles = makeStyles(() => ({
  container: {
    padding: '48px 40px 24px 40px !important',
    borderBottom: 'none !important',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
  },
  typographyBase: {
    color: '#525252',
    fontFamily: 'Nunito Sans',
    fontStyle: 'normal',
    lineHeight: '140%',
  },
  headerText: {
    fontSize: '20px',
    fontWeight: 700,
    marginBottom: '8px !important',
  },
  passwordlessPromptText: {
    fontSize: '14px',
    fontWeight: 400,
    marginBottom: '0px !important',
  },
  inputSecondaryText: {
    display: 'inline-block',
    marginTop: '8px',
    color: '#A5A4A1',
    fontSize: '11px',
    fontWeight: 600,
  },
  rememberMeText: {
    display: 'inline-block',
    marginTop: '2px',
    fontSize: '14px',
    fontWeight: 600,
  },
  tabs: {
    gap: '2px',
  },
  tab: {
    borderRadius: '8px 8px 0px 0px',
    background: '#F4F6FB',
    display: 'flex',
    height: '32px',
    padding: '0px 24px',
    justifyContent: 'center',
    alignItems: 'center',
    flex: '1 0 0',
    width: '149px',
    minWidth: 'unset',
    minHeight: '24px',
  },
  tabActive: {
    borderBottom: '2px solid #FF9A14',
    background: '#FFF6EB',
  },
  inputContainer: {
    marginTop: '24px',
    marginBottom: '0px !important',
  },
  inputLabel: {
    left: '0px !important',
  },
  muiInput: {
    borderBottom: 'unset',
  },
  rememberMeContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
  },
  muiCheckbox: {
    padding: '0px',
  },
  muiCheckboxChecked: {
    color: '#FF9A14 !important',
  },
  loginButtonWrap: {
    flexDirection: 'column !important',
    height: 'auto !important',
    gap: '24px !important',
    paddingBottom: '40px !important',
  },
  signupTextLabel: {
    textAlign: 'center !important',
    margin: '0 !important',
    whiteSpace: 'break-spaces !important',
    width: 'auto !important',
  },
  loginButton: {
    width: '100% !important',
  },
}));
