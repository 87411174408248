import * as carrierActions from '../../constants/action-types';

export const fetchCarrierAppointmentList = payload => ({
  type: carrierActions.FETCH_CARRIER_APPOINTMENT_LIST,
  payload,
});

export const setCarrierAppointmentList = payload => ({
  type: carrierActions.SET_CARRIER_APPOINTMENT_LIST,
  payload,
});

export const setCarrierAppointmentListLoader = payload => ({
  type: carrierActions.CARRIER_APPOINTMENT_LIST_LOADER,
  payload,
});

export const carrierAppointmentFileLoader = flag => ({
  type: carrierActions.CARRIER_APPOINTMENT_FILE_LOADER,
  payload: flag,
});
