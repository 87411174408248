import * as types from '../../constants/action-types';

const initState = () => ({
  q3DashboardBannerLoader: false,
  q3DaysRemaining: localStorage.getItem('q3DaysRemaining'),
  q4DaysRemaining: localStorage.getItem('q4DaysRemaining'),
  policiesCounters: [],
  tiers: [],
  totalEarnings: localStorage.getItem('q3TotalEarnings'),
  fastStartEarning: null,
});

export default (state = initState(), action) => {
  switch (action.type) {
    case types.SET_Q3_INCENTIVE_DETAIL: {
      return {
        ...state,
        tiers: action.payload.tiers,
        policiesCounters: action.payload.policiesCounters,
        totalEarnings: action.payload.totalEarnings,
      };
    }
    case types.SET_Q3_DAYSREMAINING: {
      return {
        ...state,
        q3DaysRemaining: action.payload.q3DaysRemaining,
      };
    }
    case types.SET_Q4_DAYSREMAINING: {
      return {
        ...state,
        q4DaysRemaining: action.payload.q4DaysRemaining,
      };
    }
    case types.SET_Q3_INCENTIVE_DETAIL_LOADER: {
      return {
        ...state,
        q3DashboardBannerLoader: action.payload,
      };
    }
    case types.SET_INCENTIVE_EARNING: {
      return {
        ...state,
        fastStartEarning: action.payload,
      };
    }
    default:
      return state;
  }
};
