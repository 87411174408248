// @ts-check

import config from 'config';
import { getAuth0SpaClient, getULTemplateParams } from './auth0-spa-client';

const GENERIC_LOGIN_ERROR =
  'Something went wrong. Please try logging in again.';

export async function handleAuth0SpaLoginRedirect() {
  if (location.search.includes('error=')) {
    console.error('Error logging in with Auth0: ' + location.search);
    throw new Error(GENERIC_LOGIN_ERROR);
  }

  const isAfterAuth0Redirect =
    location.search.includes('state=') && location.search.includes('code=');

  if (!isAfterAuth0Redirect) {
    return { shouldContinue: false };
  }

  try {
    await getAuth0SpaClient().handleRedirectCallback();
    window.history.replaceState({}, document.title, '/');

    return { shouldContinue: true };
  } catch (err) {
    console.error('Error logging in with Auth0: ' + err);
    throw new Error(GENERIC_LOGIN_ERROR);
  }
}

/**
 * Issues an Auth0 Single Page Application (SPA) password login.
 * @param {Object} options - The options for issuing the login.
 * @param {string} [options.email] - The email of the user.
 * @param {string} [options.producerCode] - The producer code (optional).
 * @param {boolean} [options.isReauthenticateBannerShown] - Indicates if the reauthenticate banner is shown (default: false).
 * @returns {Promise<void>} - A promise that resolves when the login is issued.
 */
export async function issueAuth0SpaPasswordLogin({
  email,
  producerCode,
  isReauthenticateBannerShown = false,
}) {
  await getAuth0SpaClient().loginWithRedirect({
    authorizationParams: {
      // @ts-expect-error: AUTH0_CONNECTION is defined
      connection: config.AUTH0_CONNECTION,
      login_hint: email || '',
      ...getULTemplateParams({
        producerCode,
        isReauthenticateBannerShown,
      }),
    },
  });
}
