import React from 'react';
import { get } from 'lodash';
import './graph-loader.scss';

const GraphLoader = props => {
  return (
    <div className="graph-spinner-wrapper">
      <div className="graph-container">
        <div className={`spinner ${props.typeClass}`}>
          <div className="rect1"></div>
          <div className="rect2"></div>
          <div className="rect3"></div>
          <div className="rect4"></div>
          <div className="rect5"></div>
        </div>
        {get(props, 'content') && (
          <span>
            <strong>{props.content}</strong>
          </span>
        )}
      </div>
    </div>
  );
};

export default GraphLoader;
