import * as type from '../../constants/action-types';

export const fetchProducerSettings = () => ({
  type: type.FETCH_PRODUCER_SETTINGS,
});

/**
 * @param {boolean} isEnabled
 * @returns {{type: string, payload: boolean}}
 */
export const setIsNewDashboardEnabled = (isEnabled) => ({
  type: type.SET_IS_NEW_DASHBOARD_ENABLED,
  payload: isEnabled,
});

/**
 * @param {boolean} isEnabled
 * @returns {{type: string, payload: boolean}}
 */

/**
 * @param {boolean} isEnabled
 * @returns {{type: string, payload: boolean}}
 */
export const setIsCanopyConnectEnabled = (isEnabled) => ({
  type: type.SET_IS_CANOPY_CONNECT_ENABLED,
  payload: isEnabled,
});

/**
 * @param {boolean} isEnabled
 * @returns {{type: string, payload: boolean}}
 */
export const setIsLeadFeedEnabled = (isEnabled) => ({
  type: type.SET_IS_LEAD_FEED_ENABLED,
  payload: isEnabled,
});

/**
 * @param {boolean} isEnabled
 * @returns {{type: string, payload: boolean}}
 */
export const setIsNewCarrierStoreEnabled = (isEnabled) => ({
  type: type.SET_IS_NEW_CARRIER_STORE_ENABLED,
  payload: isEnabled,
});
