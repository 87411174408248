import React, { Component } from 'react';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Chip,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import SubmitLoader from 'components/submit-loader';
import {
  BOP,
  BUSINESS_NAME,
  CLASS_CODES,
  DISPLAY_COMMERICAL_NAME,
  GL,
  PL,
  PRIMARY_STATE,
  SELECT,
  SUBMIT,
  VALIDATE_CLASS_CODE,
  WORKERS_COMP,
} from 'constants/constant';
import { isEmpty, startCase } from 'lodash';
import { connect } from 'react-redux';
import {
  fetchClassCodes,
  resetClassCodeDetails,
  saveClassCodes,
  updateClassCodesDetails,
} from 'redux/actions/class-codes';
import { fetchAllStates } from 'redux/actions/loa-filters';
import { getAuthDetails, getNextedTernaryDetails } from 'util/extra';
import { validators } from 'util/form-handler';
import IntlMessages from 'util/intl-messages';
import CarrierSelection from './carrier-selection';
import WCError from './error-404';
import { getStartCase } from 'util/sign-up-process';
import CarrierAppetiteIFrame from './appetite-iframe';
import './apetite.scss';
import { withRouter } from 'react-router-dom';
import {
  getCoterieAgencyIdFromQuoteUrl,
  getCoterieProducerIdFromQuoteUrl,
  getRequestIdFromQuoteUrl,
  selectedCarrierMapping,
  sortedCoterieHashData,
} from './utils';

class CarrierAppetite extends Component {
  constructor(props) {
    super(props);
    const { allLOAFilterStates, codeList } = !!this.props && this.props;
    this.state = {
      businessName: '',
      stateName: allLOAFilterStates.stateName || startCase(SELECT),
      classCodes: codeList.title || [],
      isFormSubmitted: false,
      selectedLob: '',
      error: {
        classCodes: '',
        stateName: '',
        businessName: '',
        coterieAkHash: '',
      },
      coterieAkHash: '',
      selectedCarrier: null,
      isShowIframe: false,
      carrierErrorMessage: '',
      encryptedUrlParam: '',
    };
  }

  componentDidMount() {
    this.props.resetClassCodeDetails();
    this.props.fetchAllStates();
    this.updateSelectedLob();
  }

  UNSAFE_componentWillReceiveProps = (newProps) => {
    this.setState({
      isFormSubmitted: newProps?.isSuccess,
    });
    if (newProps?.carriers?.length === 1) {
      this.setState((prevState) => ({
        ...prevState,
        selectedCarrier: newProps?.carriers[0],
        isShowIframe: true,
      }));
    }
  };
  updateSelectedLob() {
    const { href } = window.location;
    let selectedData;

    if (href.includes('workers-comp')) {
      selectedData = WORKERS_COMP;
    } else if (href.includes('business-owner-policy')) {
      selectedData = BOP;
    } else if (href.includes('general-liability')) {
      selectedData = GL;
    } else if (href.includes('professional-liability')) {
      selectedData = PL;
    }

    this.setState({ selectedLob: selectedData });
  }
  validators = {
    businessName: [validators.required],
    classCodes: [
      (value) => {
        if (this.state.selectedLob === WORKERS_COMP) {
          return validators.arraySelectRequired(value);
        }
        return '';
      },
    ],
    coterieAkHash: [
      (value) => {
        if (
          this.state.selectedLob === PL ||
          this.state.selectedLob === GL ||
          this.state.selectedLob === BOP
        ) {
          return validators.arraySelectRequired(value);
        }
        return '';
      },
    ],
    stateName: [validators.selectRequired],
  };

  validate = () => {
    const { error } = this.state;
    let isInvalid = false;
    const newErrors = { ...error };
    for (const [key, validatorList] of Object.entries(this.validators)) {
      for (const validator of validatorList) {
        const errors = validator(this.state[key]);
        newErrors[key] = errors;
        if (errors) {
          isInvalid = true;
          if (!this.errorField) {
            this.errorField = key;
          }
          break;
        }
      }
    }
    this.setState({ error: newErrors });
    return !isInvalid;
  };

  onStateChange = (e) => {
    const { selectedLob } = this.state;
    this.setState({
      stateName: e.target.value,
      classCodes: [],
    });
    if (selectedLob && selectedLob === WORKERS_COMP) {
      if (e.target.value === startCase(SELECT) || e.target.value === '') {
        this.props.resetClassCodeDetails();
      } else {
        let data = {
          stateName: e.target.value,
        };
        this.props.fetchClassCodes(data);
      }
    } else {
      this.setState({
        stateName: e.target.value,
        classCodes: [],
      });
    }
  };

  onCancel = (event) => {
    event.preventDefault();
    const classCodesDetails = {
      carriers: [],
      isSuccess: false,
    };
    this.setState((prevState) => ({
      ...prevState,
      selectedCarrier: '',
      isShowIframe: false,
    }));
    this.props.updateClassCodesDetails(classCodesDetails);
  };

  submitForm = (event) => {
    event.preventDefault();
    const { businessName, stateName, classCodes, coterieAkHash, selectedLob } =
      this.state;
    const { producerId, isImpersonate } = this.props;
    const isValid = this.validate();
    if (isValid) {
      let payload = {
        codeList: classCodes,
        businessName,
        stateName,
        selectedLineOfBusinesses: [selectedLob],
      };
      if (isImpersonate) {
        payload.producerId = producerId;
      }
      if (selectedLob && selectedLob !== WORKERS_COMP) {
        payload.industry = {
          cnaClassCode: '07821',
          ...coterieAkHash,
          ...classCodes,
        };
      }
      this.props.saveClassCodes(payload);
    }
  };

  goToIframe = () => {
    const { selectedCarrier } = this.state;
    this.setState((prevState) => ({
      ...prevState,
      isShowIframe: isEmpty(selectedCarrier) ? false : true,
      carrierErrorMessage: isEmpty(selectedCarrier)
        ? 'Please select atleast one carrier to continue'
        : '',
    }));
  };

  onCarrierSelection = (selectedCarrier) => {
    this.setState((prevState) => ({
      ...prevState,
      selectedCarrier: selectedCarrier,
    }));
  };

  selectCarrier = () => {
    const { quoteOptions } = this.props;
    const { selectedCarrier, selectedLob } = this.state;
    let selectedCarrierData = null;

    const availableCarriers =
      quoteOptions
        ?.filter((option) => option?.displayName === DISPLAY_COMMERICAL_NAME)[0]
        ?.LOBs?.filter((lob) => {
          return lob.code === selectedLob;
        })[0]?.carriers || [];
    if (selectedCarrier?.carrierName) {
      selectedCarrierData = availableCarriers?.find(
        (carrier) => carrier?.carrierName === selectedCarrier?.carrierName
      );
    }
    return selectedCarrierData || availableCarriers[0];
  };

  render() {
    const {
      businessName,
      stateName,
      classCodes,
      error,
      isFormSubmitted,
      isShowIframe,
      selectedCarrier,
      carrierErrorMessage,
      coterieAkHash,
      selectedLob,
    } = this.state;
    const {
      classCodesLoader,
      carriers,
      producerCode,
      producerId,
      producerFirstName,
      producerLastName,
      producerEmail,
    } = this.props;

    const wcQuoteUrl = {
      producerInfo: {
        producerCode,
        producerId,
        firstName: producerFirstName,
        lastName: producerLastName,
        email: producerEmail,
      },
      businessName,
      primaryState: stateName,
      classCodes,
      carriers: selectedCarrier?.length
        ? selectedCarrier?.map(
            (carrier) => selectedCarrierMapping[carrier?.carrierName]
          )
        : [selectedCarrierMapping[selectedCarrier?.carrierName]],

      requestId: getRequestIdFromQuoteUrl(
        selectedCarrier?.length
          ? selectedCarrier?.[0]?.carrierQuoteURL
          : selectedCarrier?.carrierQuoteURL
      ),
      product: [selectedLob],
    };
    const selectedCarrierName = selectedCarrier?.length
      ? selectedCarrier?.map(
          (carrier) => selectedCarrierMapping[carrier?.carrierName]
        )
      : '';
    const carrierName =
      selectedCarrierName &&
      selectedCarrierName.map((name) => name.toUpperCase());
    const bopQuoteUrl = {
      producerInfo: {
        producerCode,
        producerId,
        firstName: producerFirstName,
        lastName: producerLastName,
        email: producerEmail,
      },
      businessName,
      primaryState: stateName,
      classCodes: [
        {
          code: '3393',
          title: 'Frozen food and freezer meal plan providers, direct selling',
        },
      ],

      carriers: selectedCarrier?.length
        ? carrierName
        : [selectedCarrierMapping[selectedCarrier?.carrierName]],
      industry: {
        cnaClassCode: '1751250',
        ...coterieAkHash,
      },
      requestId: getRequestIdFromQuoteUrl(
        selectedCarrier?.length
          ? selectedCarrier?.[0]?.carrierQuoteURL
          : selectedCarrier?.carrierQuoteURL
      ),
      coterieProducerId: selectedCarrier?.length
        ? getCoterieProducerIdFromQuoteUrl(selectedCarrier[0], carriers)
        : getCoterieProducerIdFromQuoteUrl(selectedCarrier, carriers),

      coterieAgencyId: selectedCarrier?.length
        ? getCoterieAgencyIdFromQuoteUrl(selectedCarrier[0], carriers)
        : getCoterieAgencyIdFromQuoteUrl(selectedCarrier, carriers),
      product: [selectedLob],
    };

    return (
      <Card
        className="validate-carrier-appetite-card"
        fullWidth={true}
        maxWidth="xs"
        aria-labelledby="max-width-dialog-title"
      >
        {!isFormSubmitted ? (
          <Typography className="form-heading">
            <IntlMessages id="validate.apetite" />
          </Typography>
        ) : (
          !isEmpty(carriers) &&
          !isShowIframe && (
            <Typography className="form-heading">
              <IntlMessages id="select.carriers" />
            </Typography>
          )
        )}

        {!isFormSubmitted ? (
          <div className="row mx-auto">
            <div className="col-lg-3"></div>
            <div className="col-lg-9 d-flex justify-content-center">
              <form
                id={VALIDATE_CLASS_CODE}
                className="validate-carrier-appetite-form"
              >
                <CardContent className="pb-4">
                  <div className="row">
                    <div className="col-lg-8 text-field">
                      <InputLabel id="demo-multiple-checkbox-label">
                        <IntlMessages id="label.business.name" />
                        <span className="text-danger">*</span>
                      </InputLabel>
                      <TextField
                        fullWidth
                        name={BUSINESS_NAME}
                        inputProps={{
                          maxLength: 50,
                        }}
                        value={businessName}
                        onChange={(e) =>
                          this.setState({ businessName: e.target.value })
                        }
                        onBlur={(event) =>
                          this.setState({
                            businessName: getStartCase(
                              event.target.value.trim()
                            ),
                          })
                        }
                        error={error.businessName}
                        disabled={classCodesLoader}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                      <FormHelperText className={`error-text`}>
                        {error.businessName}
                      </FormHelperText>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-8 text-field">
                      <InputLabel id="demo-simple-select-helper-label">
                        <IntlMessages id="label.primary.state" />
                        <span className="text-danger">*</span>
                      </InputLabel>
                      <Select
                        value={stateName}
                        onChange={this.onStateChange}
                        name={PRIMARY_STATE}
                        label={PRIMARY_STATE}
                        error={error.stateName}
                        fullWidth
                        disabled={classCodesLoader}
                      >
                        <MenuItem
                          key={startCase(SELECT)}
                          value={startCase(SELECT)}
                        >
                          {startCase(SELECT)}
                        </MenuItem>
                        {this.props.allLOAFilterStates?.map(
                          (allLOAFilterStates) => (
                            <MenuItem
                              key={allLOAFilterStates.stateId}
                              value={allLOAFilterStates.abbreviation}
                            >
                              {allLOAFilterStates.stateName}
                            </MenuItem>
                          )
                        )}
                      </Select>
                      <FormHelperText className={`error-text`}>
                        {error.stateName}
                      </FormHelperText>
                    </div>
                  </div>
                  {((selectedLob && selectedLob === GL) ||
                    selectedLob === PL ||
                    selectedLob === BOP) && (
                    <div className="row">
                      <div className="col-lg-8 text-field">
                        <InputLabel id="demo-simple-select-helper-label">
                          <IntlMessages id="label.select.class.codes" />
                          <span className="text-danger">*</span>
                        </InputLabel>
                        <Autocomplete
                          disableClearable={true}
                          options={sortedCoterieHashData}
                          getOptionSelected={(option, value) =>
                            option.coterieAkHash === value.coterieAkHash
                          }
                          getOptionLabel={(option) => option.description || ''}
                          value={coterieAkHash}
                          onChange={(event, value) => {
                            this.setState({
                              coterieAkHash: value,
                            });
                          }}
                          renderInput={(params) => <TextField {...params} />}
                        />
                        <FormHelperText className={`error-text`}>
                          {error.coterieAkHash}
                        </FormHelperText>
                      </div>
                    </div>
                  )}
                  {selectedLob && selectedLob === WORKERS_COMP && (
                    <div className="row">
                      <div className="col-lg-8 text-field select-code">
                        <InputLabel id="demo-multiple-checkbox-label">
                          <IntlMessages id="label.select.class.codes" />
                          <span className="text-danger">*</span>
                        </InputLabel>

                        <Autocomplete
                          fullWidth
                          multiple
                          options={this.props.codeList}
                          value={classCodes}
                          name={CLASS_CODES}
                          label={CLASS_CODES}
                          error={error.classCodes}
                          onChange={(event, newValue) => {
                            this.setState({
                              classCodes: newValue || [],
                            });
                          }}
                          getOptionLabel={(option) =>
                            option?.title + ' (' + option?.code + ')'
                          }
                          disabled={
                            classCodesLoader ||
                            stateName === '' ||
                            stateName === 'Select'
                          }
                          renderTags={(tagValue, getTagProps) => {
                            return tagValue.map((option, index) => (
                              <Chip
                                {...getTagProps({ index })}
                                label={option.title}
                                key={option.title}
                                title={option.title + ' (' + option.code + ')'}
                              />
                            ));
                          }}
                          renderInput={(params) => (
                            <TextField
                              className="select-code-textfield"
                              {...params}
                              variant="outlined"
                              placeholder={
                                stateName === '' || stateName === 'Select'
                                  ? 'Please Select a state'
                                  : ''
                              }
                            />
                          )}
                          renderValue={(selected) => {
                            const values = [];
                            for (const value of selected) {
                              const index = this.props.codeList.findIndex(
                                (code) => code.code === value
                              );
                              if (index >= 0)
                                values.push(
                                  this.props.codeList[index].title +
                                    ' ' +
                                    '(' +
                                    this.props.codeList[index].code +
                                    ')'
                                );
                            }
                            return values.join(',');
                          }}
                        />
                        <FormHelperText className={`error-text`}>
                          {error.classCodes}
                        </FormHelperText>
                      </div>
                    </div>
                  )}
                </CardContent>
              </form>
            </div>
          </div>
        ) : (
          getNextedTernaryDetails(
            !isEmpty(carriers),
            getNextedTernaryDetails(
              !isEmpty(selectedCarrier) && !!isShowIframe,
              <CarrierAppetiteIFrame
                selectedCarrier={this.selectCarrier()}
                quoteUrl={
                  selectedLob === WORKERS_COMP ? wcQuoteUrl : bopQuoteUrl
                }
              />,
              <>
                <CarrierSelection
                  carriers={carriers}
                  disabled={classCodesLoader}
                  onCarrierSelection={this.onCarrierSelection}
                />
                <FormHelperText className={`error-text text-center`}>
                  {carrierErrorMessage}
                </FormHelperText>
              </>
            ),
            <WCError disabled={classCodesLoader} />
          )
        )}
        <CardActions>
          {!isFormSubmitted ? (
            <Grid
              item
              xs={12}
              sm={12}
              className="buttonGrid d-flex justify-content-center"
            >
              <>
                <Button
                  className="m-2 appetite-submit-button"
                  onClick={(e) => this.submitForm(e)}
                  disabled={classCodesLoader}
                >
                  <IntlMessages id="label.continue" />
                  &nbsp;
                  {classCodesLoader && <SubmitLoader />}
                </Button>
              </>
            </Grid>
          ) : (
            <Grid
              item
              xs={12}
              sm={12}
              className="buttonGrid d-flex justify-content-center"
            >
              <>
                <Button
                  className="m-2 appetite-cancel-button"
                  onClick={this.onCancel}
                  disabled={classCodesLoader}
                >
                  <IntlMessages id="label.cancel" />
                </Button>
                &nbsp;
                {!isEmpty(carriers) && (
                  <>
                    <Button
                      type={SUBMIT}
                      form={VALIDATE_CLASS_CODE}
                      className="m-2 appetite-submit-button"
                      onClick={() => this.goToIframe()}
                      disabled={classCodesLoader}
                    >
                      <IntlMessages id="label.continue" />
                    </Button>
                    &nbsp;
                    {classCodesLoader && <SubmitLoader />}
                  </>
                )}
                &nbsp;
                {classCodesLoader && <SubmitLoader />}
              </>
            </Grid>
          )}
        </CardActions>
      </Card>
    );
  }
}

const mapStateToProps = ({
  auth,
  loaFilters: { allLOAFilterStates },
  impersonate: { isImpersonate, producer },
  classCodes: { codeList, classCodesLoader, carriers, isSuccess } = {},
  dashboard,
} = {}) => {
  const {
    producer_id: producerId,
    producer_code: producerCode,
    producerEmail,
    principleFirstName,
    principleLastName,
    email,
    agencyProfile: { firstName, lastName } = {},
  } = getAuthDetails({
    auth,
    producer,
    isImpersonate,
  });
  const { quoteOptions } = dashboard;
  return {
    allLOAFilterStates,
    codeList,
    classCodesLoader,
    carriers,
    isImpersonate,
    isSuccess,
    producerId,
    producerCode,
    quoteOptions,
    producerFirstName: principleFirstName || firstName,
    producerLastName: principleLastName || lastName,
    producerEmail: producerEmail || email,
  };
};
const CarrierAppetiteWithRouter = withRouter(CarrierAppetite);
export default connect(mapStateToProps, {
  fetchAllStates,
  fetchClassCodes,
  resetClassCodeDetails,
  updateClassCodesDetails,
  saveClassCodes,
})(CarrierAppetiteWithRouter);
