import * as preSignupType from '../../constants/action-types';

export const preSignupUser = signupdata => {
  return {
    type: preSignupType.PRE_SIGNUP_USER,
    payload: signupdata,
  };
};
export const preSignupUserSuccess = signupstatus => {
  return {
    type: preSignupType.PRE_SIGNUP_USER_SUCCESS,
    payload: signupstatus,
  };
};
export const preSignupUserExist = payload => {
  return {
    type: preSignupType.PRE_SIGNUP_USER_EXIST,
    payload,
  };
};
export const preSignupUserFail = message => {
  return {
    type: preSignupType.PRE_SIGNUP_USER_FAIL,
    payload: message,
  };
};
export const preSignupLoaderShow = () => ({
  type: preSignupType.PRE_SIGNUP_ON_SHOW_LOADER,
});

export const preSignupLoaderHide = () => ({
  type: preSignupType.PRE_SIGNUP_ON_HIDE_LOADER,
});

export const preSignupSetRecords = records => ({
  type: preSignupType.PRE_SIGNUP_SET_RECORDS,
  payload: records,
});
