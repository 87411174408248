import DancingDots from 'components/dancing-dots';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { clearImpersonateMode } from 'redux/actions/impersonate';

/**
 * Utility route used for dual app reverse proxy setup.
 */
const ClearImpersonate = ({ clearImpersonate }) => {
  useEffect(() => {
    clearImpersonate();
  }, []);

  return (
    <div style={{ height: '100%', width: '100%' }}>
      <DancingDots fullpageWrapper />
    </div>
  );
};

export default connect(null, { clearImpersonate: clearImpersonateMode })(
  ClearImpersonate
);
