import HelloSign from 'hellosign-embedded';
import axios from 'util/api';

import config from 'config';

const EMBEDDED_SIGN_URL = '/api/v2/dropboxsign/get-embedded-sign-url';

export const TEMPLATE_NAME = {
  W9_FORM: 'w9-form',
  AGENT_AGREEMENT: 'agent-agreement',
};

let instance;

class DropboxSign {
  constructor() {
    if (instance) {
      throw new Error('New instance of DropboxSign cannot be created!');
    }

    this.client = new HelloSign({
      clientId: config.DROPBOX_SIGN_CLIENT_ID,
    });

    instance = this;
  }

  setSignCallback = (callback) => {
    this.client.off('sign');
    this.client.on('sign', () => {
      callback();
      this.client.close();
    });
  };

  getEmbeddedSignUrl = async (templateName, payload) => {
    const embeddedSignUrlResponse = await axios.post(
      `${EMBEDDED_SIGN_URL}/${templateName}`,
      {
        payload,
      }
    );

    return embeddedSignUrlResponse.data.url;
  };

  open = (url, callback) => {
    this.client.open(url, {
      skipDomainVerification: true,
    });

    this.setSignCallback(callback);
  };
}

let dropboxSignInstance = Object.freeze(new DropboxSign());

export default dropboxSignInstance;
