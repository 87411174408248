import { debounce } from 'lodash';
import { NotificationManager } from 'react-notifications';

const SNACKBAR_DEBOUNCE_PERIOD = 3000;

/**
 * @param {string} message
 */
const triggerErrorSnackbar = (message) => {
  NotificationManager.error(message);
};

/**
 * @param {string} message
 */
const triggerSuccessSnackbar = (message) => {
  NotificationManager.success(message);
};

/**
 * @param {string} message
 */
const triggerInfoSnackbar = (message) => {
  NotificationManager.info(message);
};

const debouncedTriggerErrorSnackbar = debounce(
  triggerErrorSnackbar,
  SNACKBAR_DEBOUNCE_PERIOD,
  {
    leading: true,
    trailing: false,
  }
);

export {
  triggerSuccessSnackbar,
  triggerInfoSnackbar,
  triggerErrorSnackbar,
  debouncedTriggerErrorSnackbar,
};
