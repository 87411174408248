import React, { Component } from 'react';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import './email-verified-card.scss';
import Typography from '@material-ui/core/Typography';

export default class EmailVerifiedCard extends Component {
  handleClick = () => {
    this.props.onClick();
  };
  render() {
    return (
      <React.Fragment>
        <Card className="card-content">
          <CardContent className="verification-form ">
            <div className="heading">
              <p className="congj-p text-blue">Congratulations</p>
              <Typography gutterBottom variant="h5" component="h2">
                Your account is already verified.
              </Typography>
              <CardActions className="justify-content-center">
                <Button
                  color="primary"
                  className="btn-primary btn-next background btn-email"
                  disabled={this.props.loader}
                  onClick={this.handleClick}
                >
                  Click Here to Login
                </Button>
              </CardActions>
            </div>
          </CardContent>
        </Card>
      </React.Fragment>
    );
  }
}
