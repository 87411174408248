import { FEATURE_FLAGS } from 'constants/feature-flags';
import { NEW_AUTH_SIGN_UP_ROUTE } from 'constants/routes';
import { useFlagshipExperiment } from 'containers/flagship/use-flagship-experiment';
import { useEffect } from 'react';
import { navigateToNewAppRoute } from 'util/navigate-to-new-app-route';

const IS_NEW_SIGN_UP_ENABLED_DEFAULT_VALUE = false;

function useIsNewSignUpEnabled() {
  return useFlagshipExperiment({
    defaultValue: IS_NEW_SIGN_UP_ENABLED_DEFAULT_VALUE,
    experimentKey: FEATURE_FLAGS.IS_NEW_SIGN_UP_ENABLED,
  }).flagValue;
}

export function NewSignupRedirectWrapper({ children }) {
  const isNewSignUpEnabled = useIsNewSignUpEnabled();

  useEffect(() => {
    if (isNewSignUpEnabled) {
      // Get query params from the URL and remove the initial '?' character
      const queryParams = window.location.search.slice(1);
      navigateToNewAppRoute(NEW_AUTH_SIGN_UP_ROUTE, queryParams);
    }
  }, [isNewSignUpEnabled]);

  return children;
}
