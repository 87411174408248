import {
  FETCH_CARRIERS_CATEGORIES,
  SET_CARRIERS_CATEGORIES,
  SET_CARRIERS_CATEGORIES_LOADER,
} from '../../constants/action-types';

export const fetchCarrierCategories = () => ({
  type: FETCH_CARRIERS_CATEGORIES,
});

export const setCarrierCategoriesLoader = flag => ({
  type: SET_CARRIERS_CATEGORIES_LOADER,
  payload: flag,
});

export const setCarrierCategories = categories => ({
  type: SET_CARRIERS_CATEGORIES,
  payload: categories,
});
