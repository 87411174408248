import { all, fork, put, select, takeEvery } from 'redux-saga/effects';
import { displayErrorMessage, displaySuccessMessage } from 'util/user-feedback';
import axios from 'util/api';
import * as adminUserActions from 'constants/action-types';
import {
  setAdminCategoryRoleList,
  setAdminCategoryRoleLoader,
  setAdminUserList,
  setAdminUserListLoader,
  setAdminUserLoader,
  setNewAdminUser,
  setUpdatedAdminUser,
} from '../actions/admin-users';
import { errorHandler } from '../actions/error';

// Get admin Users List
function* fetchAdminUserList() {
  try {
    yield put(setAdminUserListLoader(true));
    const adminUserListResponse = yield axios.get('/api/v1/admin/list');
    if (adminUserListResponse) {
      const { data, status } = adminUserListResponse;
      if (status === 200 && data?.success) {
        const { adminList } = data;
        yield put(setAdminUserList(adminList));
      } else {
        yield put(setAdminUserList([]));
      }
    }
  } catch (error) {
    if (!!error.response && error.response.status === 401) {
      yield displayErrorMessage(error.response.data.message)
      yield put(errorHandler(error));
    } else {
      yield put(errorHandler(error));
    }
  }
  yield put(setAdminUserListLoader(false));
}

export function* fetchAdminUserListWatcher() {
  yield takeEvery(adminUserActions.GET_ADMIN_USER_LIST, fetchAdminUserList);
}

// Add new admin user
function* addNewAdminUser({ payload }) {
  try {
    yield put(setAdminUserLoader(true));
    const response = yield axios.post('/api/v1/admin', {
      data: payload,
    });
    yield put(setAdminUserLoader(false));
    if (response.status === 200 && response.data.success) {
      const { data, message } = response.data;
      yield put(setNewAdminUser(data));
      yield displaySuccessMessage(message);
    } else {
      yield displayErrorMessage(response.data.message);
    }
  } catch (error) {
    if (!!error.response && error.response.status === 401) {
yield displayErrorMessage(error.response.data.message)
      yield put(errorHandler(error));
    } else {
      yield put(errorHandler(error));
    }
    yield put(setAdminUserLoader(false));
  }
}

export function* addNewAdminUserWatcher() {
  yield takeEvery(adminUserActions.ADD_NEW_ADMIN_USER, addNewAdminUser);
}

// Update existing admin user
function* updateAdminUserDetails({ payload }) {
  try {
    yield put(setAdminUserLoader(true));
    const response = yield axios.put('/api/v1/admin', {
      data: payload,
    });
    const reduxState = yield select();
    let adminList = reduxState.adminUsers.adminUserList;
    let adminUserIndex = adminList.findIndex(item => {
      return item.producer_id === payload.producer_id;
    });
    if (response.status === 200 && response.data.success) {
      const { data, message } = response.data;
      adminList[adminUserIndex] = {
        producer_id: data.producer_id,
        firstname: data.firstname,
        lastname: data.lastname,
        producer_email: data.producer_email,
        status: data.status,
        role: data.role,
      };
      yield put(setUpdatedAdminUser(adminList));
      yield displaySuccessMessage(message);
    } else {
      adminList[adminUserIndex] = {
        producer_id: adminList[adminUserIndex].producer_id,
        firstname: adminList[adminUserIndex].firstname,
        lastname: adminList[adminUserIndex].lastname,
        producer_email: adminList[adminUserIndex].producer_email,
        status: adminList[adminUserIndex].status,
        role: adminList[adminUserIndex].role,
      };
      yield put(setUpdatedAdminUser(adminList));
      yield displayErrorMessage(response.data.message);
    }
  } catch (error) {
    if (!!error.response && error.response.status === 401) {
yield displayErrorMessage(error.response.data.message)
      yield put(errorHandler(error));
    } else {
      yield put(errorHandler(error));
    }
  }
  yield put(setAdminUserLoader(false));
}

export function* updateAdminUserWatcher() {
  yield takeEvery(adminUserActions.UPDATE_ADMIN_USER, updateAdminUserDetails);
}

// Get admin category roles
function* fetchAdminCategoryRoles() {
  try {
    yield put(setAdminCategoryRoleLoader(true));
    const adminCategoryRoles = yield axios.get('/api/v1/admin/roles');
    if (!!adminCategoryRoles) {
      const { status, data } = adminCategoryRoles;
      if (status === 200) {
        const roleList = data.roles;
        yield put(setAdminCategoryRoleList(roleList));
      }
    }
  } catch (error) {
    if (!!error.response && error.response.status === 401) {
yield displayErrorMessage(error.response.data.message)
    } else {
      yield put(errorHandler(error));
    }
  }
  yield put(setAdminCategoryRoleLoader(false));
}

export function* fetchAdminCategoryRolesWatcher() {
  yield takeEvery(
    adminUserActions.FETCH_ADMIN_CATEGORY_ROLE_LIST,
    fetchAdminCategoryRoles
  );
}

// ------ ROOT SAGA -----------------
export default function* rootSaga() {
  yield all([
    fork(fetchAdminUserListWatcher),
    fork(addNewAdminUserWatcher),
    fork(updateAdminUserWatcher),
    fork(fetchAdminCategoryRolesWatcher),
  ]);
}
