import * as types from '../../constants/action-types';

const initState = () => ({
  appointmentsLoader: false,
  appointmentsSuccess: false,
  bulkApointmentsList: [],
  isUploading: false,
  uploaded: false,
  sampleInputFile: '',
  currentData: {
    carrierName: '',
    fileUrl: ''
  },
  appointment: 'idle',
  isProcessing: false,
});

export default (state = initState(), action) => {
  switch (action.type) {
    case types.SET_APPOINTMENTS: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case types.APPOINTMENTS_LOADER: {
      return {
        ...state,
        appointmentsLoader: action.payload,
      };
    }

    case types.APPOINTMENTS_SUCCESS: {
      return {
        ...state,
        appointmentsSuccess: action.payload,
      };
    }

    case types.APPOINTMENTS_PROCESSING: {
      return {
        ...state,
        isProcessing: action.payload,
      };
    }

    case types.APPOINTMENTS: {
      return {
        ...state,
        appointment: action.payload,
      };
    }

    case types.APPOINTMENTS_CURRENT_DATA: {
      return {
        ...state,
        currentData: {
          ...state.currentData,
          ...action.payload
        }
      };
    }

    case types.BULK_APPOINTMENT_SUCCESS: {
      let updatedData = [...state.bulkApointmentsList];
      const elementsIndex = updatedData.findIndex(
        element => element?.id === action?.payload?.id
      );
      if (elementsIndex === -1) {
        updatedData = [{ ...action.payload }, ...updatedData];
      } else if (action?.payload?.type === 'error') {
        updatedData = updatedData.filter(
          item => item.id !== action?.payload?.id
        );
      } else {
        updatedData[elementsIndex] = {
          ...action.payload,
        };
      }
      return {
        ...state,
        isUploading: false,
        uploaded: true,
        bulkApointmentsList: [...updatedData],
      };
    }

    case types.BULK_APPOINTMENT_FILES_UPLOADING: {
      return {
        ...state,
        isUploading: true,
      };
    }

    case types.BULK_APPOINTMENT_FILES_UPLOAD_FAILED: {
      return {
        ...state,
        isUploading: false,
        uploaded: false,
      };
    }

    default:
      return state;
  }
};
