import * as documentDownloaderdActionType from '../../constants/action-types';

// document loader
export const documentDownloadLoader = flag => ({
  type: documentDownloaderdActionType.DOCUMENT_DOWNLAOD_LOADER,
  payload: flag,
});

// get document path/url
export const getDocumentPath = payload => ({
  type: documentDownloaderdActionType.GET_DOCUMENT_PATH,
  payload,
});
