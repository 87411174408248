// @ts-check

import { getAuth0SpaToken } from './auth0.utils';

/**
 * Retrieves auth token. If Auth0 token is not available then it falls back to localStorage.
 * @returns {Promise<string | null>} A promise that resolves to the JWT auth token.
 */
export async function getToken() {
  return (await getAuth0SpaToken()) || localStorage.getItem('Authorization');
}
