/* eslint-disable no-unused-vars */
import React from 'react';
import MaskedInput from 'react-text-mask';
import PropTypes from 'prop-types';
import { PLACEHOLDER_CHAR } from 'constants/constant';

const inputMask = [
  '(',
  /[1-9]/,
  /\d/,
  /\d/,
  ')',
  ' ',
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];

const PhoneMask = props => {
  const { inputRef, ...other } = props;
  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={inputMask}
      placeholderChar={PLACEHOLDER_CHAR}
      guide={false}
    />
  );
};

PhoneMask.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

export default PhoneMask;
