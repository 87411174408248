import * as carrierActions from '../../constants/action-types';

// selected agent details fetch
export const fetchCarrierList = () => ({
  type: carrierActions.FETCH_CARRIER_LIST,
});

export const setCarrierList = payload => ({
  type: carrierActions.SET_CARRIER_LIST,
  payload,
});

export const setCarrierListLoader = payload => ({
  type: carrierActions.CARRIER_LIST_LOADER,
  payload,
});
