import * as agentDetailsActions from '../../constants/action-types';

// selected agent details fetch
export const fetchAgentList = (payload) => ({
  type: agentDetailsActions.FETCH_AGENT_LIST,
  payload,
});

export const setAgentList = (list) => ({
  type: agentDetailsActions.SET_AGENT_LIST,
  payload: list,
});

export const setAgentListLoader = (flag) => ({
  type: agentDetailsActions.AGENT_LIST_LOADER,
  payload: flag,
});

export const updateAgentLoader = (flag) => ({
  type: agentDetailsActions.AGENT_UPDATE_LOADER,
  payload: flag,
});

// Edit Agent Info for all details.
export const saveAgentDetails = (details) => ({
  type: agentDetailsActions.SAVE_AGENT_DETAILS,
  payload: details,
});

// Edit Agent Info for License details.
export const saveLicenseDetails = (details) => ({
  type: agentDetailsActions.SAVE_LICENSE_DETAILS,
  payload: details,
});

// Edit Agent Info for bank details.
export const saveBankDetails = (details) => ({
  type: agentDetailsActions.SAVE_BANK_DETAILS,
  payload: details,
});

// Edit Agent Info for Eno details.
export const saveEnoDetails = (details) => ({
  type: agentDetailsActions.SAVE_ENO_DETAILS,
  payload: details,
});

// Edit Agent Info for W9 details.
export const saveW9Details = (details) => ({
  type: agentDetailsActions.SAVE_W9_DETAILS,
  payload: details,
});
// -------------- For Settings Details -------------------

// Settings row edit on save event for server. - AgentDetails Saga
export const saveSettings = (details) => ({
  type: agentDetailsActions.SAVE_SETTINGS,
  payload: details,
});

// Update settings in store object for client - MyAccount Reducer
export const updateStoredSettings = (details) => ({
  type: agentDetailsActions.UPDATE_STORED_SETTINGS,
  payload: details,
});
// Used to update store sub agent array.
export const storeNewSubAgentDetails = (payload) => ({
  type: agentDetailsActions.STORE_NEW_SUBAGENT,
  payload,
});

// -------------------- Get All Incomplete Signup Agent List ----------//

// Delete incomplete signup
export const deleteIncompleteSignup = (data) => ({
  type: agentDetailsActions.DELETE_INCOMPLETE_SIGNUP,
  payload: data,
});

// used to updated the store list for Error Omission angents.
export const setAgentComplianceList = (payload) => ({
  type: agentDetailsActions.SET_AGENT_COMPLIANCE_LIST,
  payload,
});

export const setAgentComplianceListUpdate = (payload) => ({
  type: agentDetailsActions.SET_AGENT_COMPLIANCE_LIST_UPDATE,
  payload,
});

export const setAgentComplianceListUpdateLoader = (flag) => ({
  type: agentDetailsActions.AGENT_COMPLIANCE_LIST_UPDATE_LOADER,
  payload: flag,
});

export const licenseDetailsUpdate = (flag) => ({
  type: agentDetailsActions.UPDATE_LICESE_DETAILS_MODAL,
  payload: flag,
});

export const setLicenseDetailsFetch = (flag) => ({
  type: agentDetailsActions.SENT_LICESE_DETAILS_MODAL_DATA,
  payload: flag,
});

// AgentProfile Status Active, Restrict, Suspen, Dormant
export const setAgentStatus = (flag) => ({
  type: agentDetailsActions.SET_AGENT_STATUS,
  payload: flag,
});

export const setAgentStatusLoaderFun = (flag) => ({
  type: agentDetailsActions.SET_AGENT_STATUS_LOADER,
  payload: flag,
});

// storeAgentErrorMessages
export const storeAgentErrorMessages = (flag) => ({
  type: agentDetailsActions.SET_AGENT_ERROR_MESSAGES,
  payload: flag,
});

// selected incomplete agent to complte profile
export const completeAgentProfile = (payload) => ({
  type: agentDetailsActions.COMPLETE_AGENT_PROFILE,
  payload,
});

// used to setCompleteAgentProfile to redirect to agent
export const setCompleteAgentProfile = (payload) => ({
  type: agentDetailsActions.SET_COMPLETE_AGENT_PROFILE,
  payload,
});

// --------- ENO Approve and Reject Action ----------//
export const fetchENOApproveRejectActionFun = (payload) => ({
  type: agentDetailsActions.FETCH_ENO_APPROVE_REJECT_ACTION_FUN,
  payload,
});

// -------------- For License List Details -------------------

// License List on save event for server. - AgentDetails Saga
export const saveLicenseList = (details) => ({
  type: agentDetailsActions.SAVE_LICENSE_LIST,
  payload: details,
});

// Update settings in store object for client - MyAccount Reducer
export const updateLicenseList = (details) => ({
  type: agentDetailsActions.UPDATE_STORED_LICENSE_LIST,
  payload: details,
});

// On Save License List  loader.
export const licenseListLoader = (flag) => ({
  type: agentDetailsActions.LICENSE_LIST_LOADER,
  payload: flag,
});

// On Save License File
export const licenseFileLoader = (payload) => ({
  type: agentDetailsActions.LICENSE_FILE_LOADER,
  payload,
});

// License List on Update data event for server. - AgentDetails Saga
export const saveUpdateLicenseList = (details) => ({
  type: agentDetailsActions.SAVE_UPDATE_LICENSE_LIST,
  payload: details,
});

// Fetch LOA and state list
export const licenseLOAStateListLoader = (payload) => ({
  type: agentDetailsActions.LICENSE_LOA_STATE_LIST_LOADER,
  payload,
});

export const fetchLOAStateList = () => ({
  type: agentDetailsActions.FETCH_LICENSE_LOA_STATE_LIST,
});

export const setLOAStateList = (payload) => ({
  type: agentDetailsActions.SET_LICENSE_LOA_STATE_LIST,
  payload,
});

// Needs Approval List
export const setNeedsApprovalList = (payload) => ({
  type: agentDetailsActions.SET_NEEDS_APPROVAL_LIST,
  payload,
});

// Update Filter Count
export const updateFilterCount = (payload) => ({
  type: agentDetailsActions.UPDATE_FILTER_COUNT,
  payload,
});

// Update on prompt close of login for sub-producer
export const updatePromptOnClose = (payload) => ({
  type: agentDetailsActions.UPDATE_ON_PROMPT_CLOSE,
  producerId: payload,
});

// Add Producer
export const addProducer = (payload, callback) => ({
  type: agentDetailsActions.ADD_PRODUCER_DATA,
  payload,
  callback,
});

// Set compliance file upload error
export const setComplianceFileUploadError = (payload) => ({
  type: agentDetailsActions.SET_COMPLIANCE_FILE_UPLOAD_ERROR,
  payload,
});

export const setOpenBankInfo = (payload) => ({
  type: agentDetailsActions.SET_OPEN_BANK_INFO,
  payload,
});

export const setOpenCredentialTab = (payload) => ({
  type: agentDetailsActions.SET_OPEN_CREDENTIAL_TAB,
  payload,
});

// pagination in BE
export const fetchAgentListCounters = () => ({
  type: agentDetailsActions.FETCH_AGENT_LIST_COUNTERS,
});

export const fetchProducersDataList = (payload) => ({
  type: agentDetailsActions.FETCH_PROUDUCER_DATA_LIST,
  payload,
});

export const setTotalProducerCount = (payload) => ({
  type: agentDetailsActions.SET_TOTAL_PRODUCER_COUNT,
  payload,
});
// pagination in BE

export const setHubspotIdsDetailLoader = (payload) => ({
  type: agentDetailsActions.SET_HUBSPOT_IDS_DETAIL_LOADER,
  payload,
});

export const updateHubspotIdsDetail = (payload) => ({
  type: agentDetailsActions.UPDATE_HUBSPOT_IDS_DETAIL,
  payload,
});

export const setHubspotIdsDetailSaved = (payload) => ({
  type: agentDetailsActions.SET_HUBSPOT_IDS_DETAIL_SAVED,
  payload,
});
