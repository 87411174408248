import * as types from '../../constants/action-types';

const initState = () => ({
  leaderBoardLoader: true,
  results: null,
  producerLeaderboardData: null,
  shouldShowLeaderBoard: true,
  tiers:null,
});

export default (state = initState(), action) => {
  switch (action.type) {
    case types.SET_LEADER_BOARD_DETAIL: {
      return {
        ...state,
        results: action.payload.results,
        producerLeaderboardData: action.payload.producerLeaderboardData,
      };
    }
    case types.SET_SHOULD_SHOW_LEADER_BOARD: {
      return {
        ...state,
        shouldShowLeaderBoard: action.payload,
      };
    }
    case types.SET_LEADER_BOARD_DETAIL_LOADER: {
      return {
        ...state,
        leaderBoardLoader: action.payload,
      };
    }
    case types.SET_INCENTIVE_LEADER_BOARD_DETAIL:{
      return {
        ...state,
        tiers: action.payload,
      };
    }
    default:
      return state;
  }
};
