import * as updatePasswordTypes from '../../constants/action-types';

// on forgot password link.
export const sendForgotPasswordLink = payload => ({
  type: updatePasswordTypes.FORGOT_PASSWORD,
  payload,
});

// loader for update password.
export const setPasswordUpdateLoader = flag => ({
  type: updatePasswordTypes.UPDATE_PASSWORD_LOADER,
  payload: flag,
});

// email link token set
export const setUpdatePasswordToken = token => ({
  type: updatePasswordTypes.SET_UPDATE_PASSWORD_TOKEN,
  payload: token,
});

export const setIsTokenValue = payload => ({
  type: updatePasswordTypes.SET_IS_TOKEN_VALID,
  payload,
});

export const setValidEmailTokenLoader = flag => ({
  type: updatePasswordTypes.UPDATE_TOKEN_LOADER,
  payload: flag,
});

export const validateEmailToken = token => ({
  type: updatePasswordTypes.VALIDATE_EMAIL_TOKEN,
  payload: token,
});
// change passwored to new password.
export const updatePassword = details => ({
  type: updatePasswordTypes.CHANGE_PASSWORD,
  payload: { ...details },
});

// Forgot Password - request sent to email.
export const updatePasswordEmailSend = details => ({
  type: updatePasswordTypes.UPDATE_PASSWORD_EMAIL_SENT,
  payload: details,
});

// Change Password - new password updated.
export const changePasswordCompleted = flag => ({
  type: updatePasswordTypes.UPDATE_PASSWORD_COMPLETED,
  payload: flag,
});
