import { ButtonBase } from '@material-ui/core';
import React from 'react';
import { connect } from 'react-redux';
import {
  fetchProducerQuoteOptions,
  setIsSelectingProduct,
} from 'redux/actions/dashboard';
import { PORTAL } from 'constants/heap-events';
import { NEW_APP_ROUTES } from 'constants/constant';
import './new-quote-button.scss';
import { navigateToNewAppRoute } from 'util/navigate-to-new-app-route';
import Tooltip from '@material-ui/core/Tooltip';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';

const NewQuoteButton = (props) => {
  const handleNewQuote = () => {
    const { from } = props;

    window.heap.track(PORTAL.QUOTE_CONNECTOR_CLICK_NEW_QUOTE, {
      Source: from,
    });
    navigateToNewAppRoute(NEW_APP_ROUTES.QUOTE_CONNECTOR_START);
  };

  const isProducerActive = props.userStatus?.toLowerCase() === 'active';

  return (
    <Tooltip
      {...(isProducerActive
        ? { open: false }
        : {
            title:
              "Account status 'Active' is required to access Quote Connector",
          })}
    >
      <ButtonBase
        data-testid="header-new-quote-btn"
        id="header__new-quote-button"
        onClick={handleNewQuote}
        isDisabled={!isProducerActive}
      >
        <AddCircleOutlineIcon />
        <span>New Quote</span>
      </ButtonBase>
    </Tooltip>
  );
};
const mapStateToProps = ({ auth, quoteConnector }) => {
  const { hasQuotingEngineAccess, userRole, userStatus } = auth;
  const { isNewQuoteConnectorEnabled } = quoteConnector;
  return {
    hasQuotingEngineAccess,
    userRole,
    isNewQuoteConnectorEnabled,
    userStatus,
  };
};

NewQuoteButton.defaultProps = {
  from: '',
  onModalClose: () => {},
};

export default connect(mapStateToProps, {
  setIsSelectingProduct,
  fetchProducerQuoteOptions,
})(NewQuoteButton);
