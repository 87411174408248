import * as types from '../../constants/action-types';

const initState = () => ({
  carrierIncentiveLoader: false,
  carrierIncentiveList: [],
  carrierIncentiveDetails: {},
  carrierIncentiveDetailLoader: false,
  newCarrierIncentiveLoader: false,
  updateCarrierIncentiveLoader: false,
  incentiveSubmitLoader: false,
});

export default (state = initState(), action) => {
  switch (action.type) {
    case types.SET_INCENTIVE_LIST: {
      return {
        ...state,
        ...action.payload,
      };
    }

    case types.CARRIER_INCENTIVE_LOADER: {
      return {
        ...state,
        carrierIncentiveLoader: action.payload,
      };
    }

    case types.SET_CARRIER_INCENTIVE_DETAILS: {
      return {
        ...state,
        carrierIncentiveDetails: action.payload,
      };
    }

    case types.SET_CARRIER_INCENTIVE_DETAIL_LOADER: {
      return {
        ...state,
        carrierIncentiveDetailLoader: action.payload,
      };
    }

    case types.SET_CARRIER_INCENTIVE: {
      return {
        ...state,
        carrierIncentiveList: [action.payload, ...state.carrierIncentiveList],
      };
    }

    case types.SET_INCENTIVE_SUBMIT_LOADER: {
      return {
        ...state,
        incentiveSubmitLoader: action.payload,
      };
    }
    default:
      return state;
  }
};
