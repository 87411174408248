import * as types from '../../constants/action-types';

const initState = () => ({
  addUserLoader: false,
  responseMessage: '',
  getRolesLoader: false,
  rolesList: null,
  userPermissionListLoader: false,
  userPermissionList: null,
  updatedUserList: '',
  success: false,
});

export default (state = initState(), action) => {
  switch (action.type) {
    case types.SET_USER_PERMISSION_LIST: {
      return {
        userPermissionListLoader: false,
        userPermissionList: action.payload,
      };
    }
    case types.USER_PERMISSION_LIST_LOADER: {
      return {
        ...state,
        userPermissionListLoader: action.payload,
      };
    }
    case types.SET_NEW_USER: {
      const { user } = action.payload;
      return {
        ...state,
        addUserLoader: false,
        userPermissionList: [user, ...state.userPermissionList],
        success: action.payload.success,
      };
    }
    case types.ADD_NEW_USER_LOADER: {
      return {
        ...state,
        addUserLoader: action.payload,
      };
    }
    case types.CLEAR_USER_DATA: {
      return {
        ...state,
        userPermissionList: null,
        responseMessage: '',
      };
    }
    case types.SET_ROLES_LIST: {
      return {
        ...state,
        rolesList: action.payload,
      };
    }
    case types.FETCH_ROLES_LIST_LOADER: {
      return {
        ...state,
        getRolesLoader: action.payload,
      };
    }
    case types.CLEAR_ROLES_DATA: {
      return {
        ...state,
        rolesList: '',
        responseMessage: '',
      };
    }
    case types.SET_UPDATED_USER_DATA: {
      const { id } = action.payload.user;
      const index = state.userPermissionList.findIndex(item => {
        return item.id === id;
      });
      state.userPermissionList[index] = action.payload.user;
      return {
        ...state,
        updatedUserList: state.userPermissionList,
        responseMessage: action.payload.message,
      };
    }
    default:
      return state;
  }
};
