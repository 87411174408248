import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { getAuthDetails } from 'util/extra';
import RemindCrossSellTooltip from './remind-tooltip';


const CrossSellIframe = props => {
  const [showtooltips, setShowtooltips] = useState(false);

  useEffect(() => {
    setInterval(() => {
      setShowtooltips(true);
    }, 60000);
  }, []);

  let cseSuggestedCarrierIframe = [];
  props?.suggestedCarriers.forEach(carrier => {
    cseSuggestedCarrierIframe.push(
      <iframe
        title="home"
        src={carrier?.carrierPortalLink}
        width="100%"
        height="100%"
        frameBorder="0"
        hidden={
          !(
            props?.carrierType?.suggestedCarriers?.carrierName ===
            carrier?.carrier_code
          )
        }
      />
    );
  });

  return (
    <>
      <RemindCrossSellTooltip
        showtooltips={showtooltips}
        setShowtooltips={setShowtooltips}
      />
      <iframe
        title="Hippo"
        src={props?.carrier_link}
        width="100%"
        height="100%"
        frameBorder="0"
        hidden={!props?.carrierType?.homeCarrier?.active}
      />
      {cseSuggestedCarrierIframe}
    </>
  );
};
const mapStateToProps = ({ auth, impersonate, dashboard }) => {
  const { producer, isImpersonate } = impersonate;
  const { producer_id, producer_code } = getAuthDetails({
    auth,
    producer,
    isImpersonate,
  });
  const {
    carrierPortals,
    suggestedCarriers,
    carrier_link,
    carrierType,
  } = dashboard;

  return {
    producer_code,
    producer_id,
    carrierPortals,
    suggestedCarriers,
    carrier_link,
    carrierType,
  };
};

export default connect(mapStateToProps, {})(CrossSellIframe);
