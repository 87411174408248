import { omit, throttle } from 'lodash';
import { decryptStore, encryptStore } from '../../util/store-encryption';

const STORAGE_STATE_KEY = 'fc-state';

export const saveState = (state) => {
  try {
    let updatedState = omit(state, [
      'router',
      'passwordSettings',
      'agentDetails.advanceSearchList',
      'agentDetails.agentList',
      'agentDetails.incompleteSignupList',
      'dashboard.quotesList',
      'auth.unseenNotificationsCount',
    ]);

    updatedState.agentDetails.advanceSearchList = [];
    updatedState.agentDetails.agentList = [];
    updatedState.agentDetails.incompleteSignupList = [];
    updatedState.auth.unseenNotificationsCount = 0;

    const serializedEncryptedState = encryptStore(JSON.stringify(updatedState));
    sessionStorage.setItem(STORAGE_STATE_KEY, serializedEncryptedState);
  } catch (e) {
    // show error here
  }
};

export const loadState = () => {
  try {
    const serializedEncryptedState = sessionStorage.getItem(STORAGE_STATE_KEY);
    const decryptedSerializedSatate = decryptStore(serializedEncryptedState);
    if (decryptedSerializedSatate === null) return undefined;
    return JSON.parse(decryptedSerializedSatate);
  } catch (e) {
    return undefined;
  }
};

export const persistedState = loadState();

export const persistanceStateConfig = (store) => {
  store.subscribe(throttle(() => saveState(store.getState()), 1000));
};
