import React from 'react';
import { connect } from 'react-redux';
import arrow from 'assets/images/arrow-img.svg';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import '../cross-sell.scss';
import { Typography } from '@material-ui/core';
import { setClickOnCrossSell } from 'redux/actions';
import { isEmpty } from 'lodash';
const RemindCrossSellTooltip = props => {
  const { showtooltips, setShowtooltips, isClickOnCrossSell } = props;
  const toolTipRef = React.useRef(null);
  const cancleTooltips = () => {
    toolTipRef.current.classList.add('hide');
    toolTipRef.current.classList.remove('show');
    setTimeout(() => {
      setShowtooltips(false);
      props.setClickOnCrossSell(true);
    }, 3000);
  };
  return (
    <>
      {!isEmpty(props.suggestedCarriers) &&
        showtooltips &&
        !isClickOnCrossSell && (
          <div className="reminderTooltipWrap">
            <div
              className="show animated reminderTooltip animation-delay-1"
              ref={toolTipRef}
            >
              <span className="circleArrow">
                <img src={arrow} className="icon leftRightLoop" alt={arrow} />
              </span>
              <Typography className="TooltipContent">
                Hey {props.loginName}, click here to easily cross sell your
                customer.
              </Typography>
              <HighlightOffIcon
                onClick={cancleTooltips}
                className="close-icon"
              />
            </div>
          </div>
        )}
    </>
  );
};

const mapStateToProps = ({ auth, dashboard }) => {
  const { loginName } = auth;
  const { suggestedCarriers, isClickOnCrossSell } = dashboard;

  return {
    isClickOnCrossSell,
    loginName,
    suggestedCarriers,
  };
};

export default connect(mapStateToProps, {
  setClickOnCrossSell,
})(RemindCrossSellTooltip);
