import { capitalize, isUndefined } from 'lodash';
import React from 'react';
import {
  ALTERNATE_SUB_PRODUCER,
  PRINCIPAL_ACCESS_ROLE,
  POST_SIGNUP_ALLOWED_STEPS as PSAS,
  SUB_PRODUCER_KEY,
} from 'constants/constant';
import CalendarIcon from 'assets/images/social-proof/calender-icon.svg';
import EfficientIcon from 'assets/images/social-proof/icon-efficient.svg';
import PercentIcon from 'assets/images/social-proof/icon-percent.svg';
import CarrierLogoThird from 'assets/images/social-proof/image3.svg';
import CarrierLogoSecond from 'assets/images/social-proof/image4.svg';
import CarrierLogoTitum from 'assets/images/social-proof/titum-insurance-logo.svg';
import FastStartLogo from 'assets/images/fast-start-logo.svg';

export const string_regex = /^[A-Za-z]{1}[-'\u2019 A-Za-z]*$/;
export const address_validation = /^[#./0-9a-zA-Z\s',-]+$/i;
export const bankname_regex = /^[A-Za-z]{1}[-'.,&\u2019 A-Za-z]*$/;
export const alphanumeric_regex = /^[A-Za-z0-9,]{1}[-'\u2019 A-Za-z0-9,]*$/;
export const phone_regex = /^\s*(?:\+?(\d{1,3}))?[- (]*(\d{3})[- )]*(\d{3})[- ]*(\d{4})(?: *[x/#]{1}(\d+))?\s*$/;
export const numberhyphen_regex = /^[0-9 -]+$/;
export const forbidden_filename_characters_regex = /^[^\\/:*?"“”+<>|%]+$/;
export const policy_number_regex = /^[^\\/:*?"“”+<>_=)('&^%$#@!~`;.,{}|%]+$/;
export const filename_starting_with_dot = /^\./;
export const neglect_zero_regex = /^(?!0*(\.0+)?$)(\d+|\d*\.\d+)$/;
// SSN RegExpression
export const ssn_regex = /^(?!(000))\d{3}-(?!00)\d{2}-(?!0000)\d{4}$/;
export const fin_regex = /^[0-9](?!00)\d?-(?!0000000)\d{7}$/;
// At least one charcter in name
export const string_with_regx = /^(?=.*[a-zA-Z].*)([-'\u2019 a-zA-Z0-9]+)$/;

// For File Validation on document table
export const string_with_custom_doc_validation_regx = /^(?=.*[a-zA-Z].*)([-_'\u2019!@#$%^*(){}'";,.+?= a-zA-Z0-9]+)$/;

// eslint-disable-next-line
export const number_regex = /^[0-9]*$/;
// eslint-disable-next-line
export const email_regex = /^(([A-Za-z0-9]+_+)|([A-Za-z0-9]+\-+)|([A-Za-z0-9]+\.+)|([A-Za-z0-9]+\++))*[A-Za-z0-9]+@((\w+\-+)|(\w+\.))*\w{1,63}\.[a-zA-Z]{2,6}$/;

export const password_regex = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/;

export const zero_regex = /^[1-9][0-9]*$/;
// only zeros check
export const only_zero_regex = /^0+$/;

// DOB format
export const date_format = 'MM/DD/YYYY';

// only num symbol string
export const only_number_symbol_regex = /^[^a-zA-Z]+$/;

// Us State Array
export const stateName = [
  {
    value: 'Select',
    label: '',
  },
  {
    value: 'Alabama',
    label: 'Alabama',
  },
  {
    value: 'Alaska',
    label: 'Alaska',
  },
  {
    value: 'Arizona',
    label: 'Arizona',
  },
  {
    value: 'Arkansas',
    label: 'Arkansas',
  },
  {
    value: 'California',
    label: 'California',
  },
  {
    value: 'Colorado',
    label: 'Colorado',
  },
  {
    value: 'Connecticut',
    label: 'Connecticut',
  },
  {
    value: 'Delaware',
    label: 'Delaware',
  },
  {
    value: 'Florida',
    label: 'Florida',
  },
  {
    value: 'Georgia',
    label: 'Georgia',
  },
  {
    value: 'Hawaii',
    label: 'Hawaii',
  },
  {
    value: 'Idaho',
    label: 'Idaho',
  },
  {
    value: 'Illinois',
    label: 'Illinois',
  },
  {
    value: 'Indiana',
    label: 'Indiana',
  },
  {
    value: 'Iowa',
    label: 'Iowa',
  },
  {
    value: 'Kansas',
    label: 'Kansas',
  },
  {
    value: 'Kentucky',
    label: 'Kentucky',
  },
  {
    value: 'Louisiana',
    label: 'Louisiana',
  },
  {
    value: 'Maine',
    label: 'Maine',
  },
  {
    value: 'Maryland',
    label: 'Maryland',
  },
  {
    value: 'Massachusetts',
    label: 'Massachusetts',
  },
  {
    value: 'Michigan',
    label: 'Michigan',
  },
  {
    value: 'Minnesota',
    label: 'Minnesota',
  },
  {
    value: 'Mississippi',
    label: 'Mississippi',
  },
  {
    value: 'Missouri',
    label: 'Missouri',
  },
  {
    value: 'Montana',
    label: 'Montana',
  },
  {
    value: 'Nebraska',
    label: 'Nebraska',
  },

  {
    value: 'Nevada',
    label: 'Nevada',
  },
  {
    value: 'New Hampshire',
    label: 'New Hampshire',
  },
  {
    value: 'New Jersey',
    label: 'New Jersey',
  },
  {
    value: 'New Mexico',
    label: 'New Mexico',
  },
  {
    value: 'New York',
    label: 'New York',
  },
  {
    value: 'North Carolina',
    label: 'North Carolina',
  },
  {
    value: 'North Dakota',
    label: 'North Dakota',
  },
  {
    value: 'Ohio',
    label: 'Ohio',
  },
  {
    value: 'Oklahoma',
    label: 'Oklahoma',
  },
  {
    value: 'Oregon',
    label: 'Oregon',
  },
  {
    value: 'Pennsylvania',
    label: 'Pennsylvania',
  },
  {
    value: 'Rhode Island',
    label: 'Rhode Island',
  },
  {
    value: 'South Carolina',
    label: 'South Carolina',
  },
  {
    value: 'South Dakota',
    label: 'South Dakota',
  },
  {
    value: 'Tennessee',
    label: 'Tennessee',
  },
  {
    value: 'Texas',
    label: 'Texas',
  },
  {
    value: 'Utah',
    label: 'Utah',
  },
  {
    value: 'Vermont',
    label: 'Vermont',
  },
  {
    value: 'Virginia',
    label: 'Virginia',
  },
  {
    value: 'Washington',
    label: 'Washington',
  },
  {
    value: 'Washington DC',
    label: 'Washington DC',
  },
  {
    value: 'West Virginia',
    label: 'West Virginia',
  },
  {
    value: 'Wisconsin',
    label: 'Wisconsin',
  },
  {
    value: 'Wyoming',
    label: 'Wyoming',
  },
];

// Us State Array
export const stateLicenseName = [
  {
    value: 'Select',
    label: '',
  },
  {
    value: 'AK',
    label: 'AK',
  },
  {
    value: 'AL',
    label: 'AL',
  },
  {
    value: 'AR',
    label: 'AR',
  },
  {
    value: 'AZ',
    label: 'AZ',
  },
  {
    value: 'CA',
    label: 'CA',
  },
  {
    value: 'CO',
    label: 'CO',
  },
  {
    value: 'CT',
    label: 'CT',
  },
  {
    value: 'DC',
    label: 'DC',
  },
  {
    value: 'DE',
    label: 'DE',
  },
  {
    value: 'FL',
    label: 'FL',
  },
  {
    value: 'GA',
    label: 'GA',
  },
  {
    value: 'HI',
    label: 'HI',
  },
  {
    value: 'IA',
    label: 'IA',
  },
  {
    value: 'ID',
    label: 'ID',
  },
  {
    value: 'IL',
    label: 'IL',
  },
  {
    value: 'IN',
    label: 'IN',
  },
  {
    value: 'KS',
    label: 'KS',
  },
  {
    value: 'KY',
    label: 'KY',
  },
  {
    value: 'LA',
    label: 'LA',
  },
  {
    value: 'MA',
    label: 'MA',
  },
  {
    value: 'MD',
    label: 'MD',
  },
  {
    value: 'ME',
    label: 'ME',
  },
  {
    value: 'MI',
    label: 'MI',
  },
  {
    value: 'MN',
    label: 'MN',
  },
  {
    value: 'MO',
    label: 'MO',
  },
  {
    value: 'MS',
    label: 'MS',
  },
  {
    value: 'MT',
    label: 'MT',
  },
  {
    value: 'NC',
    label: 'NC',
  },
  {
    value: 'ND',
    label: 'ND',
  },
  {
    value: 'NE',
    label: 'NE',
  },
  {
    value: 'NH',
    label: 'NH',
  },
  {
    value: 'NJ',
    label: 'NJ',
  },
  {
    value: 'NM',
    label: 'NM',
  },
  {
    value: 'NV',
    label: 'NV',
  },
  {
    value: 'NY',
    label: 'NY',
  },
  {
    value: 'OH',
    label: 'OH',
  },
  {
    value: 'OK',
    label: 'OK',
  },
  {
    value: 'OR',
    label: 'OR',
  },
  {
    value: 'PA',
    label: 'PA',
  },
  {
    value: 'RI',
    label: 'RI',
  },
  {
    value: 'SC',
    label: 'SC',
  },
  {
    value: 'SD',
    label: 'SD',
  },
  {
    value: 'TN',
    label: 'TN',
  },
  {
    value: 'TX',
    label: 'TX',
  },
  {
    value: 'UT',
    label: 'UT',
  },
  {
    value: 'VA',
    label: 'VA',
  },
  {
    value: 'VT',
    label: 'VT',
  },
  {
    value: 'WA',
    label: 'WA',
  },
  {
    value: 'WI',
    label: 'WI',
  },
  {
    value: 'WV',
    label: 'WV',
  },
  {
    value: 'WY',
    label: 'WY',
  },
];

const stateAbbreviationMap = {
  AK: 'Alaska',
  AL: 'Alabama',
  AR: 'Arkansas',
  AZ: 'Arizona',
  CA: 'California',
  CO: 'Colorado',
  CT: 'Connecticut',
  DE: 'Delaware',
  FL: 'Florida',
  GA: 'Georgia',
  HI: 'Hawaii',
  IA: 'Iowa',
  ID: 'Idaho',
  IL: 'Illinois',
  IN: 'Indiana',
  KS: 'Kansas',
  KY: 'Kentucky',
  LA: 'Louisiana',
  MA: 'Massachusetts',
  MD: 'Maryland',
  ME: 'Maine',
  MI: 'Michigan',
  MN: 'Minnesota',
  MO: 'Missouri',
  MS: 'Mississippi',
  MT: 'Montana',
  NC: 'North Carolina',
  ND: 'North Dakota',
  NE: 'Nebraska',
  NH: 'New Hampshire',
  NJ: 'New Jersey',
  NM: 'New Mexico',
  NV: 'Nevada',
  NY: 'New York',
  OH: 'Ohio',
  OK: 'Oklahoma',
  OR: 'Oregon',
  PA: 'Pennsylvania',
  RI: 'Rhode Island',
  SC: 'South Carolina',
  SD: 'South Dakota',
  TN: 'Tennessee',
  TX: 'Texas',
  UT: 'Utah',
  VA: 'Virginia',
  VT: 'Vermont',
  WA: 'Washington',
  WI: 'Wisconsin',
  WV: 'West Virginia',
  WY: 'Wyoming',
  DC: 'Washington DC',
  PR: 'Puerto Rico',
  VI: 'Virgin Islands',
  GU: 'Guam',
};

export const getStateNamebyAbbreviation = key => {
  return stateAbbreviationMap[key?.toUpperCase()] || key;
};
// Address street and city in upper case
export const getStartCase = value => {
  return value
    .split(' ')
    .map(capitalize)
    .join(' ')
    .trim();
};

// String validation with dot and ampersand(both optional)
export const string_with_dot_ampersand = /^(?=.*[a-zA-Z].*)([-' &.a-zA-Z0-9]+)$/;

// Regex for url validation
export const url_regex = /^http[s]?:\/\/(?!w3|tempuri)([-\w]*\.)(?!w3|tempuri)\S*$/;

export const producer_code_regex = /^[fcFC]{2}[0-9]{5}$/;

export const loa_name_validation_regex = /^(?=.*[a-zA-Z].*)([-'() /,&.a-zA-Z0-9]+)$/;

export const file_name_regex = /^(?!\.)[^|*?\\:<>/$"]*[^.|*?\\:<>/$"]+$/;

export const hex_color_code_regex = /^#[0-9a-fA-F]{6}$|#[0-9a-fA-F]{3}$/;

export const empty_string_regex = /^\s+$/;

/**
 * @param {string} producerType
 * @param {boolean} productionAccess
 * @returns {string[]} steps that are accessible by given producer.
 * */
export const getAccessibleSteps = (
  role,
  producerType,
  productionAccess,
  writesUnderAgencyLicense
) => {
  if (role === PRINCIPAL_ACCESS_ROLE) {
    return PSAS.SUB_PRODUCER_PRINCIPAL_ACCESS;
  } else if (producerType === SUB_PRODUCER_KEY) {
    if (
      productionAccess &&
      !isUndefined(writesUnderAgencyLicense) &&
      writesUnderAgencyLicense
    ) {
      return PSAS.SUB_PRODUCER_PRODUCTION_ACCESS_WITH_AGENCY_LOGIN;
    } else if (productionAccess) {
      return PSAS.SUB_PRODUCER_PRODUCTION_ACCESS;
    } else {
      return PSAS.SUB_PRODUCER_NON_PRODUCTION_ACCESS;
    }
  } else if (producerType === ALTERNATE_SUB_PRODUCER) {
    if (productionAccess) {
      return PSAS.ALTERNATE_SUB_PRODUCER_PRODUCTION_ACCESS;
    } else {
      return PSAS.ALTERNATE_SUB_PRODUCER_NON_PRODUCTION_ACCESS;
    }
  } else {
    return PSAS.ALL_STEPS;
  }
};

export const carrier_url_regex = /^(https?|ftp):\/\/([a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)+)(:[0-9]{1,5})?(\/[^\s]*)?$/;

export const socialProofContent = {
  title: 'Why First Connect?',
  agencyProfileSocialFlow: [
    {
      content: 'Appointed quickly with dozens of carriers and insurtechs',
      image: CalendarIcon,
    },
    {
      content: 'Competitive commissions and exclusive agent incentives',
      image: PercentIcon,
    },
    {
      content:
        'Access to our powerful, simple-to-learn tools for greater efficiencies',
      image: EfficientIcon,
    },
  ],
};

export const testimonialContent = [
  {
    title: 'What Agents are saying:',
    content: `“As an independent agent I need to have as many options as possible to be competitive.
       First Connect has allowed me to obtain direct access to today's leading Insurtech carriers. First Connect & their carrier partners make enrolling & writing business easy!”`,
    image: CarrierLogoThird,
    author: 'Tony Lella',
    state: 'UT',
  },
  {
    title: 'What Agents are saying:',
    content: `“As an independent agent I need to have as many options as possible to be competitive.
       First Connect has allowed me to obtain direct access to today's leading Insurtech carriers. First Connect & their carrier partners make enrolling & writing business easy!”`,
    image: CarrierLogoThird,
    author: 'Tony Lella',
    state: 'UT',
  },
  {
    title: 'What Agents are saying:',
    content:
      '“An absolute game changer! Having access to First Connect’s Insurtech partners removes the need of staffing up a large customer service group in the office. You’d have to be crazy to not want these great Insurtech options!”',
    image: CarrierLogoTitum,
    author: 'John Tatum',
    state: 'TX',
  },
  {
    title: 'What Agents are saying:',
    content:
      '“Times are changing and First Connect is way ahead of the curve. They are providing outstanding service, and instant quote and bind capabilities for most any type of insurance. It’s literally changed the way we do business, and we couldn’t be happier!”',
    image: CarrierLogoSecond,
    author: 'Roger Hayak',
    state: 'CA',
  },
];

export const fastStartContent = {
  fastStartLogo: FastStartLogo,
  fastStartContentList: [
    {
      content: (
        <p>
          Earn <span className="bold-text">$100</span>
          <span className="break-text">
            for first policy bound with <span className="semibold">each</span>{' '}
            new carrier,
          </span>
        </p>
      ),
    },
    {
      content: (
        <p>
          and <span className="bold-text">$50</span>
          <span className="break-text">
            for every additional policy bound in your first 60 days
          </span>
        </p>
      ),
    },
  ],
};

export const agencyDetailContent = {
  title: 'Why First Connect?',
  agencyProfileSocialFlow: [
    {
      content: 'Appointed quickly with 60+ carriers and insurtechs',
      image: CalendarIcon,
    },
    {
      content: 'Competitive commissions and exclusive agent incentives',
      image: PercentIcon,
    },
    {
      content:
        'Access to our powerful, simple-to-learn tools for greater efficiencies',
      image: EfficientIcon,
    },
  ],
};
