import {
  all,
  call,
  fork,
  put,
  takeEvery,
  takeLeading,
} from 'redux-saga/effects';
import { displayErrorMessage, displaySuccessMessage } from 'util/user-feedback';
import axios from 'util/api';
import * as agentMessagingActions from 'constants/action-types';
import { errorHandler } from '../actions/error';
import { uploadFile } from 'util/extra';
import {
  agentMessagingFilesUploadFailed,
  agentMessagingFilesUploading,
  agentMessagingSuccess,
  notificationImageUploadError,
  setAgentMessageState,
  setAgentMessagingCurrentData,
  setAgentMessagingProcessing,
  setMessagingList,
  setMessagingLoader,
} from 'redux/actions/agent-messaging';
import {
  AGENT_MESSAGE_STATE,
  MESSAGE_TYPE,
  STATIC_ASSETS,
} from 'constants/constant';
import config from 'config';

function* agentMessagingUpload({ payload }) {
  const { file, id, textField, key, title, content } = payload;
  try {
    const fileName = file?.name;
    yield put(agentMessagingFilesUploading(true));
    yield put(setAgentMessagingProcessing(true));
    const fileURL = `agent-messaging/input-files/${fileName}`;
    const response = yield call(uploadFile, file, fileURL);
    if (content?.image_url) {
      yield put(notificationImageUploadError(false));
      const imageFileName = content.image_url[0].name;
      const imageURL = `${STATIC_ASSETS}/input-files/${imageFileName}`;
      const imageResponse = yield call(
        uploadFile,
        content.image_url[0],
        imageURL
      );
      if (imageResponse && imageResponse.status === 200) {
        const { file: fileURLData } = imageResponse.data;
        content.image_url = fileURLData;
      } else {
        yield put(notificationImageUploadError(true));
      }
    }
    if (response && response.status === 200) {
      const { file: fileURLData } = response.data;
      yield put(setAgentMessagingCurrentData({ fileUrl: fileURLData }));
      const agentMessagingPayload =
        key === MESSAGE_TYPE.SMS
          ? {
              type: key,
              inputFile: fileURLData,
              message: textField,
              title,
            }
          : {
              type: key,
              notificationType: 'ADMIN_MESSAGE',
              content: content,
              inputFile: fileURLData,
              title,
            };
      const updatedResponse = yield axios.post(
        `${config.BFF_URL}/api/v1/admin/user-messages`,
        agentMessagingPayload
      );
      yield put(agentMessagingSuccess({ ...updatedResponse.data, id }));
      if (
        updatedResponse &&
        (response.status === 200 || response.status === 201)
      ) {
        const messageToShow =
          Number(updatedResponse?.data?.processedRecords) <= 0
            ? 'No valid producers were found for messaging'
            : 'Messages sent successfullly';
        yield displaySuccessMessage(messageToShow);
        yield put(setAgentMessagingCurrentData({ fileUrl: '' }));
        yield put(setAgentMessageState(AGENT_MESSAGE_STATE.SUCCESS));
      } else {
        yield put(agentMessagingSuccess({ id, type: 'error' }));
        yield displayErrorMessage(updatedResponse.data.message);
        yield put(setAgentMessageState(AGENT_MESSAGE_STATE.UNSUCCESS));
      }
    } else {
      yield put(agentMessagingSuccess({ id, type: 'error' }));
      yield put(agentMessagingFilesUploadFailed());
      yield displayErrorMessage(response.message);
      yield put(agentMessagingFilesUploading(false));
      yield put(setAgentMessagingProcessing(false));
      yield put(setAgentMessageState(AGENT_MESSAGE_STATE.UNSUCCESS));
    }
  } catch (error) {
    yield put(errorHandler(error));
    yield put(agentMessagingSuccess({ id, type: 'error' }));
    yield put(agentMessagingFilesUploading(false));
    yield put(setAgentMessagingProcessing(false));
  }
}

export function* agentMessagingUploadWatcher() {
  yield takeEvery(
    agentMessagingActions.UPLOAD_AGENT_MESSAGING,
    agentMessagingUpload
  );
}

function* agentNotificationUpload({ payload }) {
  const { key, id, title, content } = payload;
  try {
    if (content.image_url) {
      yield put(notificationImageUploadError(false));
      const fileName = content.image_url[0].name;
      const fileURL = `${STATIC_ASSETS}/input-files/${fileName}`;
      const imageResponse = yield call(
        uploadFile,
        content.image_url[0],
        fileURL
      );
      if (imageResponse && imageResponse.status === 200) {
        const { file: fileURLData } = imageResponse.data;
        content.image_url = fileURLData;
      } else {
        yield put(notificationImageUploadError(true));
      }
    }
    yield put(setAgentMessagingProcessing(true));
    const response = yield axios.post(
      `${config.BFF_URL}/api/v1/admin/user-messages`,
      {
        type: key,
        notificationType: 'ADMIN_MESSAGE',
        content: content,
        inputFile: 'ALL_PRODUCERS',
        title,
      }
    );
    yield put(agentMessagingSuccess({ ...response.data, id }));
    if (response && (response.status === 200 || response.status === 201)) {
      const messageToShow =
        Number(response?.data?.processedRecords) <= 0
          ? 'No valid producers were found for messaging'
          : 'Messages sent successfullly';
      yield displaySuccessMessage(messageToShow);
      yield put(setAgentMessagingCurrentData({ fileUrl: '' }));
      yield put(setAgentMessageState(AGENT_MESSAGE_STATE.SUCCESS));
    } else {
      yield put(agentMessagingSuccess({ id, type: 'error' }));
      yield displayErrorMessage(response.data.message);
      yield put(setAgentMessageState(AGENT_MESSAGE_STATE.UNSUCCESS));
    }
  } catch (error) {
    yield put(errorHandler(error));
    yield put(agentMessagingSuccess({ id, type: 'error' }));
    yield put(agentMessagingFilesUploading(false));
    yield put(setAgentMessagingProcessing(false));
  }
}
export function* agentNotificationUploadWatcher() {
  yield takeLeading(
    agentMessagingActions.UPLOAD_AGENT_NOTIFICATION,
    agentNotificationUpload
  );
}

function* fetchAgentMessagingListDetails() {
  try {
    yield put(setMessagingLoader(true));
    const agentMessageData = yield axios.get(
      `${config.BFF_URL}/api/v1/admin/user-messages`
    );
    if (agentMessageData?.data) {
      yield put(
        setMessagingList({
          agentMessagingList: agentMessageData?.data.messages,
        })
      );
    }
  } catch (error) {
    if (!!error.response && error.response.status === 401) {
      yield displayErrorMessage(error.response.data.message);
      yield put(errorHandler(error));
    } else {
      yield put(errorHandler(error));
    }
  }
  yield put(setMessagingLoader(false));
}

export function* fetchAgentMessagingListDetailsWatcher() {
  yield takeEvery(
    agentMessagingActions.FETCH_AGENT_MESSAGING,
    fetchAgentMessagingListDetails
  );
}

// ------ ROOT SAGA -----------------
export default function* rootSaga() {
  yield all([fork(agentMessagingUploadWatcher)]);
  yield all([fork(agentNotificationUploadWatcher)]);
  yield all([fork(fetchAgentMessagingListDetailsWatcher)]);
}
