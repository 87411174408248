import {
  FETCH_CARRIERS_CATEGORIES,
  SET_CARRIERS_CATEGORIES,
  SET_CARRIERS_CATEGORIES_LOADER,
} from 'constants/action-types';

const initState = () => ({
  categoriesList: [],
  loadingCategoriesList: false,
});

export default (state = initState(), action) => {
  switch (action.type) {
    case FETCH_CARRIERS_CATEGORIES: {
      return { ...state };
    }

    case SET_CARRIERS_CATEGORIES_LOADER: {
      return { ...state, loadingCategoriesList: action.payload };
    }

    case SET_CARRIERS_CATEGORIES: {
      return { ...state, categoriesList: action.payload };
    }

    default:
      return state;
  }
};
