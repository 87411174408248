import * as carrierDetailsAction from '../../constants/action-types';

export const carrierDetailsLoader = () => ({
  type: carrierDetailsAction.CARRIER_DETAILS_LOADER,
});

export const fetchCarrierDetails = payload => ({
  type: carrierDetailsAction.FETCH_CARRIER_DETAILS,
  payload,
});

export const setCarrierDetails = payload => ({
  type: carrierDetailsAction.SET_CARRIER_DETAILS,
  payload,
});

export const updateCarrierDetails = payload => ({
  type: carrierDetailsAction.UPDATE_CARRIER_DETAILS,
  payload,
});

export const carrierUpdateLoader = flag => ({
  type: carrierDetailsAction.CARRIER_UPDATE_LOADER,
  payload: flag,
});

export const setCarrierUpdateDetails = payload => ({
  type: carrierDetailsAction.SET_CARRIER_UPDATE_DETAILS,
  payload,
});

export const updateCommissionTableItem = payload => ({
  type: carrierDetailsAction.UPDATE_COMMISSION_TABLE_DATA,
  payload,
});

export const setRowData = payload => ({
  type: carrierDetailsAction.SET_COMMISSION_TABLE_DATA,
  payload,
});

export const updateCommissionPaidOn = payload => ({
  type: carrierDetailsAction.UPDATE_COMMISSION_PAID_ON,
  payload,
});

export const setCommissionPaidOn = payload => ({
  type: carrierDetailsAction.SET_COMMISSION_PAID_ON,
  payload,
});

export const setNewCarrierProduct = payload => ({
  type: carrierDetailsAction.SET_NEW_CARRIER_PRODUCT,
  payload,
});

export const setNewCarrierProductEmpty = payload => ({
  type: carrierDetailsAction.SET_NEW_CARRIER_PRODUCT_EMPTY,
  payload,
});

export const updateCarrierProductMarket = payload => ({
  type: carrierDetailsAction.UPDATE_CARRIER_PRODUCT_MARKET,
  payload,
});

export const setCarrierProductMarket = payload => ({
  type: carrierDetailsAction.SET_CARRIER_PRODUCT_MARKET,
  payload,
});

export const deleteCommissionData = payload => ({
  type: carrierDetailsAction.DELETE_COMMISSION_TABLE_DATA,
  payload,
});

export const updateCommissionDataAfterDelete = payload => ({
  type: carrierDetailsAction.UPDATE_COMMISSION_TABLE_AFTER_DELETE,
  payload,
});

export const loadCarrierCommissionTable = () => ({
  type: carrierDetailsAction.UPDATE_COMMISSION_TABLE_ON_STATE_UPDATE,
});

export const updateProductAccessibility = payload => ({
  type: carrierDetailsAction.UPDATE_CARRIER_PRODUCT_ACCESSIBILITY,
  payload,
});

export const setProductAccessibility = payload => ({
  type: carrierDetailsAction.SET_CARRIER_PRODUCT_ACCESSIBILITY,
  payload,
});

export const clearUpdatedRowResponse = () => ({
  type: carrierDetailsAction.CLEAR_UPDATED_ROW_REPONSE,
});

export const imageUploadError = payload => ({
  type: carrierDetailsAction.SET_IMAGE_UPLOAD_ERROR,
  payload,
});

export const clearCarrierResponse = () => ({
  type: carrierDetailsAction.CLEAR_CARRIER_RESPONSE,
});

export const setIsFetchCarrierError = payload => ({
  type: carrierDetailsAction.SET_IS_FETCH_CARRIER_ERROR,
  payload,
});

export const setWebinarLoader = payload => ({
  type: carrierDetailsAction.WEBINAR_LINK_LOADER,
  payload,
});

export const addWebinarLink = payload => ({
  type: carrierDetailsAction.ADD_WEBINAR_LINK,
  payload,
});

export const updateWebinarLink = payload => ({
  type: carrierDetailsAction.UPDATE_WEBINAR_LINK,
  payload,
});

export const deleteWebinarLink = payload => ({
  type: carrierDetailsAction.DELETE_WEBINAR_LINK,
  payload,
});

export const setWebinarLinkDetailsInStore = payload => ({
  type: carrierDetailsAction.SET_WEBINAR_LINK_DETAILS_IN_STORE,
  payload,
});

export const updateWebinarLinkDetailsInStore = payload => ({
  type: carrierDetailsAction.UPDATE_WEBINAR_LINK_DETAILS_IN_STORE,
  payload,
});

export const deleteWebinarLinkDetailsFromStore = payload => ({
  type: carrierDetailsAction.DELETE_WEBINAR_LINK_DETAILS_FROM_STORE,
  payload,
});

export const setIsWebinarLinkDetailsEdit = payload => ({
  type: carrierDetailsAction.SET_IS_WEBINAR_LINK_DETAILS_EDIT,
  payload,
});
