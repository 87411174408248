import * as postSignupType from '../../constants/action-types';

// post email verification data fetch success
export const postEmailVerificationSuccess = (payload) => {
  return {
    type: postSignupType.POST_VERIFICATION_SUCCESS,
    payload,
  };
};

// post email verification data fetch success
export const postEmailVerificationFail = () => {
  return {
    type: postSignupType.POST_VERIFICATION_FAIL,
  };
};

// fetch pre-signup details on every step by passing step id
export const stepDataFetch = (details) => {
  return {
    type: postSignupType.STEP_DATA_FETCH,
    payload: details,
  };
};

// on each pre-signup step data fetch sucess set respective object in store
export const stepDataFetchSuccess = (details) => {
  return {
    type: postSignupType.STEP_DATA_FETCH_SUCCESS,
    payload: details,
  };
};

// on each pre-signup step data fetch fail set blank object / do nothing.
export const stepDataFetchFail = (page) => {
  return {
    type: postSignupType.STEP_DATA_FETCH_FAIL,
    payload: page,
  };
};

// on w9 sign status being changed, e.g. it may change to false after address change
export const updateW9FormStatus = ({ shouldBeSigned }) => {
  return {
    type: postSignupType.UPDATE_W9_FORM_STATUS,
    payload: {
      shouldBeSigned,
    },
  };
};

// on post signup step data submit update store.
export const updateStepData = (data, entity) => {
  return {
    type: postSignupType.UPDATE_STEP_DATA,
    payload: {
      entity,
      data,
    },
  };
};

// on post signup step submit data.
export const stepSubmit = (details) => {
  return {
    type: postSignupType.STEP_SUBMIT,
    payload: details,
  };
};

// on nipr update loader. (for dashboard only)
export const setNiprLoader = (flag) => ({
  type: postSignupType.SET_NIPPR_LOADER,
  payload: flag,
});

// on post signup step submit suceess.
export const stepSubmitSuccess = () => {
  return {
    type: postSignupType.STEP_SUBMIT_SUCCESS,
  };
};

// on post signup step submit fail.
export const stepSubmitFail = () => {
  return {
    type: postSignupType.STEP_SUBMIT_FAIL,
  };
};

// For page submit loader
export const submitLoaderShow = () => {
  return {
    type: postSignupType.SUBMIT_LOADER_SHOW,
  };
};

export const submitLoaderHide = () => {
  return {
    type: postSignupType.SUBMIT_LOADER_HIDE,
  };
};

// For page loader
export const stepLoaderShow = () => {
  return {
    type: postSignupType.STEP_LOADER_SHOW,
  };
};

export const stepLoaderHide = () => {
  return {
    type: postSignupType.STEP_LOADER_HIDE,
  };
};

export const filesUpload = (files, entity) => {
  return {
    type: postSignupType.FILES_UPLOAD,
    payload: {
      entity,
      files,
    },
  };
};
export const filesUploadSuccess = (fileurl, fileName, entity) => {
  return {
    type: postSignupType.FILES_UPLOAD_SUCCESS,
    payload: {
      fileurl,
      fileName,
      entity,
    },
  };
};
export const filesUploadFailed = () => {
  return {
    type: postSignupType.FILES_UPLOAD_FAILED,
  };
};

// producer Agreement accept
export const stepSubmitAgreementData = (payload) => ({
  type: postSignupType.UPDATE_AGREEMENT_DATA,
  payload,
});

export const setLicenseUrl = (payload) => {
  return {
    type: postSignupType.SET_LICENSE_URL,
    payload,
  };
};

export const setIndividualNPNVerified = (flag) => ({
  type: postSignupType.SET_INDIVIDUAL_NPN_VERIFIED,
  payload: flag,
});

export const setBusinessNPNVerified = (flag) => ({
  type: postSignupType.SET_BUSINESS_NPN_VERIFIED,
  payload: flag,
});

export const getNPN = (details) => {
  return {
    type: postSignupType.GET_NPN,
    payload: details,
  };
};

export const individualLicenseError = (data) => {
  return {
    type: postSignupType.INDIVIDUAL_LICENSE_ERROR,
    payload: data,
  };
};

export const agencyLicenseError = (data) => {
  return {
    type: postSignupType.AGENCY_LICENSE_ERROR,
    payload: data,
  };
};

export const clearDataOnWriteUnderAgency = (payload) => {
  return {
    type: postSignupType.CLEAR_DATA_ON_WRITE_UNDER_AGENCY,
    payload,
  };
};

export const updateLicenseData = (payload) => {
  return {
    type: postSignupType.UPDATE_LICENSE_DATA,
    payload,
  };
};

export const clearNpnLicenses = () => {
  return {
    type: postSignupType.CLEAR_NPN_LICENSES,
  };
};

export const getAgent = (payload) => {
  return {
    type: postSignupType.FETCH_AGENT,
    payload,
  };
};

export const getAgentSuccess = ({ payload }) => {
  return {
    type: postSignupType.FETCH_AGENT_SUCCESS,
    payload,
  };
};

export const getAgentStarted = () => {
  return {
    type: postSignupType.FETCH_AGENT_STARTED,
  };
};

export const setFileUploadError = (payload) => {
  return {
    type: postSignupType.SET_POST_SIGNUP_FILE_UPLOAD_ERROR,
    payload,
  };
};

export const setCurrentStepIndex = (payload) => {
  return {
    type: postSignupType.SET_CURRENT_STEP_INDEX,
    payload,
  };
};

export const setHasAcceptedAgreement = (payload) => {
  return {
    type: postSignupType.SET_HAS_ACCEPTED_AGREEMENT,
    payload,
  };
};

export const fetchLeadStatus = (payload) => {
  return {
    type: postSignupType.FETCH_LEAD_STATUS,
    payload,
  };
};

export const setDeviceTransitionEmail = (payload) => {
  return {
    type: postSignupType.SET_DEVICE_TRANSITION_EMAIL,
    payload,
  };
};
export const setLeadStatus = (payload) => {
  return {
    type: postSignupType.SET_LEAD_STATUS,
    payload,
  };
};

export const setLeadStatusLoader = (payload) => {
  return {
    type: postSignupType.SET_LEAD_STATUS_LOADER,
    payload,
  };
};

export const setSignupByReferral = (payload) => {
  return {
    type: postSignupType.SET_SIGNUP_BY_REFERRAL,
    payload,
  };
};

export const setIsSignupByReferral = (payload) => {
  return {
    type: postSignupType.SET_IS_SIGN_UP_BY_REFERRAL,
    payload,
  };
};

export const setContinueOnComputer = (payload) => {
  return {
    type: postSignupType.SET_CONTINUE_ON_COMPUTER,
    payload,
  };
};

export const setCancleSwitchDeviceValue = (payload) => {
  return {
    type: postSignupType.SET_CANCLE_SWITCH_VALUE,
    payload,
  };
};

export const setDeviceTransitionEmailLoader = (payload) => {
  return {
    type: postSignupType.SET_DEVICE_TRANSITION_EMAIL_LOADER,
    payload,
  };
};

export const setUpdatedSteps = (payload) => {
  return {
    type: postSignupType.SET_UPDATED_STEP,
    payload,
  };
};

export const setDeviceTransitionViewed = () => {
  return {
    type: postSignupType.SET_DEVICE_TRANSITION_VIEWED,
  };
};

export const fetchLicense = (payload) => {
  return {
    type: postSignupType.FETCH_LICENSE,
    payload,
  };
};

export const setSwitchToComputer = (payload) => {
  return {
    type: postSignupType.SWITCH_TO_COMPUTER,
    payload,
  };
};

export const setIndividualLicenseList = (payload) => {
  return {
    type: postSignupType.SET_INDIVIDUAL_LICENSE_LIST,
    payload,
  };
};

export const setBusinessLicenseList = (payload) => {
  return {
    type: postSignupType.SET_BUSINESS_LICENSE_LIST,
    payload,
  };
};

export const setRefetchLicenseLoader = (payload) => {
  return {
    type: postSignupType.SET_REFETCH_LICENSE_LOADER,
    payload,
  };
};

export const setLicenseList = (payload) => {
  return {
    type: postSignupType.SET_LICENSE_LIST,
    payload,
  };
};

export const setDropboxLoader = (payload) => {
  return {
    type: postSignupType.SET_DROPBOX_LOADER,
    payload,
  };
};
