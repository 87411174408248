import { get } from 'lodash';
import * as types from '../../constants/action-types';

const initState = () => ({
  graphLoader: false,
  graphDetails: null,
  initURL: '',
  renewalLoader: false,
  carrierLoader: false,
  carrierUrlLoader: false,
  renewalDetails: [],
  carrierPortals: [],
  suggestedCarriers: [],
  featuredNewCarriers: [],
  renewalNotificationFlag: false,
  producerDetails: JSON.parse(localStorage.getItem('producerDetails')) || [],
  quotesList: [],
  quoteListLoader: false,
  showQuotes: false,
  quoteOptions: [],
  launchUrl: '',
  updateNotificationLoader: false,
  accessQuotingPageLoader: false,
  commercialLineLaunchUrl: '',
  isSelectingProduct: false,
  selectedProduct: null,
  carriersCredentialList: [],
  credentialLoader: false,
  carrierProducerAppointment: null,
  products: {
    personalProducts: [],
    commercialProducts: [],
  },
  isConsentModalOpen: false,
  isCrossSellExperienceViewed: true,
  carrier_link: '',
  currentCarrier: {},
  carrierType: {
    homeCarrier: {
      carrierName: 'Home',
      active: true,
    },
    suggestedCarriers: {
      carrierName: '',
      active: false,
    },
    isTendVisited: false,
    isBlinkVisited: false,
  },
  endorsementRequestLoader: false,
  endorsementRequestSendSuccess: false,
  quoteOptionsLoader: false,
  wholesaleCarrierData: null,
  isClickOnCrossSell: false,
  isApplicationModal: true,
  shouldShowBanner: localStorage.getItem('shouldShowBanner')
    ? JSON.parse(localStorage.getItem('shouldShowBanner'))
    : false,
  showFastStartModal: localStorage.getItem('showFastStartModal')
    ? JSON.parse(localStorage.getItem('showFastStartModal'))
    : false,
  fastStartPolicyListLoader: false,
  fastStartDetails: null,
  appointedCarriers: [],
  addfastStartLoader: false,
  fastStartEarning: null,
  isFastStartAdded: false,
  isCarrierDropdownOpen: false,
  carrierStoreModal: false,
  consentModal: false,
  bankInfoModal: false,
  fastStartModal: false,
  restrictionModal: false,
  w9InforModal: false,
  isFastStartPolicyAddedd: false,
});
export default (state = initState(), action = {}) => {
  switch (action.type) {
    case types.INIT_URL: {
      return { ...state, initURL: action.payload };
    }
    case types.TOGGLE_CARRIER_DROPDOWN: {
      return {
        ...state,
        isCarrierDropdownOpen: !state.isCarrierDropdownOpen,
      };
    }

    case types.SET_CARRIER_STORE_MODAL: {
      return {
        ...state,
        carrierStoreModal: action.payload,
      };
    }

    case types.SET_W9IINFO_MODAL: {
      return {
        ...state,
        w9InforModal: action.payload,
      };
    }

    case types.SET_RESTRICTION_MODAL: {
      return {
        ...state,
        restrictionModal: action.payload,
      };
    }

    case types.SET_CONSENT_MODAL: {
      return {
        ...state,
        consentModal: action.payload,
      };
    }

    case types.SET_BANK_INFO_MODAL: {
      return {
        ...state,
        bankInfoModal: action.payload,
      };
    }

    case types.SET_FAST_START_MODAL: {
      return {
        ...state,
        fastStartModal: action.payload,
      };
    }

    case types.SHOW_FAST_START_BANNER: {
      return {
        ...state,
        shouldShowBanner: action.payload,
      };
    }
    case types.SHOW_FAST_START_MODAL: {
      return {
        ...state,
        showFastStartModal: action.payload,
      };
    }
    case types.UPDATE_WHOLESALER_CARRIER_DATA: {
      return {
        ...state,
        wholesaleCarrierData: action.payload,
      };
    }
    case types.SET_CARRIER_URL: {
      return {
        ...state,
        carrier_link: action.payload,
      };
    }
    case types.SET_CURRECT_CARRIER_URL: {
      return {
        ...state,
        currentCarrier: action.payload,
      };
    }
    case types.SET_CARRIER_TYPE: {
      return {
        ...state,
        carrierType: action.payload,
      };
    }

    case types.POLICY_PREMIUM_DETAILS_LOADER: {
      return {
        ...state,
        graphLoader: action.payload,
      };
    }

    case types.STORE_POLICY_PREMIUM_DETAILS: {
      return {
        ...state,
        graphDetails: { ...action.payload },
      };
    }

    case types.UPCOMING_RENEWALS_LOADER: {
      return {
        ...state,
        renewalLoader: action.payload,
      };
    }

    case types.STORE_UPCOMING_RENEWALS: {
      return {
        ...state,
        renewalDetails: [...action.payload],
      };
    }

    case types.RENEWAL_NOTIFICATIONS_STATUS: {
      return {
        ...state,
        producerDetails: action.payload,
        renewalNotificationFlag: action.payload.status,
      };
    }

    case types.RENEWAL_NOTIFICATIONS_FLAG_STATUS_CHANGE: {
      return {
        ...state,
        renewalNotificationFlag: false,
      };
    }

    case types.SET_CARRIER_PORTAL_LOADER: {
      return {
        ...state,
        carrierLoader: action.payload,
      };
    }

    case types.SET_CARRIER_PORTALS_DETAILS: {
      return {
        ...state,
        carrierPortals: get(action.payload, 'carriers', []),
        suggestedCarriers: get(action.payload, 'suggestedCarriers', []),
        isCrossSellExperienceViewed: action.payload.isCrossSellExperienceViewed,
        featuredNewCarriers: get(action.payload, 'featuredNewCarriers', []),
      };
    }

    case types.SET_CARRIER_URL_LOADER: {
      return {
        ...state,
        carrierUrlLoader: action.payload,
      };
    }

    case types.SET_QUOTES_LIST_LOADER: {
      return {
        ...state,
        quoteListLoader: action.payload,
      };
    }

    case types.SET_QUOTES_LIST: {
      const {
        availableLOBs,
        quotesList,
        showQuotes,
        availableCarriers,
      } = action.payload;
      return {
        ...state,
        quotesList: quotesList,
        showQuotes: !!showQuotes ? true : false,
        availableLOBs: availableLOBs,
        availableCarriers: availableCarriers,
      };
    }

    case types.UPDATE_AGENT_NAME_IN_SIGNUP_PROCESS: {
      return {
        ...state,
        producerDetails: {
          ...state.producerDetails,
          ...action.payload,
        },
      };
    }

    case types.UPDATE_QUOTE_NOTIFICATION_INDICATOR_LOADER: {
      return {
        ...state,
        updateNotificationLoader: action.payload,
      };
    }

    case types.SET_PRODUCER_QUOTES_OPTIONS_LOADER: {
      return {
        ...state,
        quoteOptionsLoader: action.payload,
      };
    }

    case types.SET_PRODUCER_QUOTES_OPTIONS_LIST: {
      const { appointments, hasAppointment } = action.payload;
      return {
        ...state,
        quoteOptions: appointments,
        hasAppointment,
      };
    }

    case types.SET_CARRIERS_CREDENTIAL_LIST: {
      return {
        ...state,
        carriersCredentialList: action.payload,
      };
    }

    case types.CARRIER_CREDENTIAL_LOADER: {
      return {
        ...state,
        credentialLoader: action.payload,
      };
    }

    case types.SET_CARRIER_PRODUCER_APPOINTMENT_ID: {
      return {
        ...state,
        carrierProducerAppointment: action.payload,
      };
    }

    case types.SET_ACCESS_QUOTING_PAGE_LOADER: {
      return {
        ...state,
        accessQuotingPageLoader: action.payload,
      };
    }

    case types.SET_LAUNCH_URL: {
      const { launchUrl } = action.payload;
      return {
        ...state,
        launchUrl: launchUrl,
      };
    }

    case types.SET_CARRIER_LINK: {
      return {
        ...state,
        ...action.payload,
      };
    }

    case types.SET_CARRIER_PORTAL_ERROR_DIALOG: {
      return {
        ...state,
        errorData: action.payload,
      };
    }

    case types.SET_COMMERCIAL_LINE_LAUNCH_URL: {
      const { launchUrl } = action.payload;
      return {
        ...state,
        commercialLineLaunchUrl: launchUrl,
      };
    }

    case types.SET_IS_SELECTING_PRODUCT: {
      return { ...state, isSelectingProduct: action.payload };
    }

    case types.SET_SELECTED_PRODUCT: {
      return { ...state, selectedProduct: action.payload };
    }

    case types.SET_PRODUCTS: {
      return { ...state, products: action.payload };
    }

    case types.SET_CONSENT_TEXT_DATA: {
      return { ...state, isConsentModalOpen: action.payload };
    }
    case types.SET_CLICK_ON_CROSS_SALE: {
      return { ...state, isClickOnCrossSell: action.payload };
    }
    case types.SET_CARRIER_STORE_APPLICATION_MODAL: {
      return { ...state, isApplicationModal: action.payload };
    }
    case types.SET_FAST_START_POLICY_LIST_LOADER: {
      return {
        ...state,
        fastStartPolicyListLoader: action.payload,
      };
    }
    case types.SET_FAST_START_POLICY_LIST: {
      const { fastStartDetails, appointedCarriers } = action.payload;
      return {
        ...state,
        fastStartDetails: fastStartDetails,
        appointedCarriers: appointedCarriers,
      };
    }

    case types.ADD_FAST_START_POLICIES_LOADER: {
      return {
        ...state,
        addfastStartLoader: action.payload,
      };
    }

    case types.ADDED_FAST_START_DETAILS: {
      const { earning } = action.payload;
      return {
        ...state,
        fastStartEarning: earning,
      };
    }

    case types.SET_IS_FAST_START_ADDED_FLAG: {
      return {
        ...state,
        isFastStartAdded: action.payload,
      };
    }

    case types.SET_IS_FAST_START_POLICY_ADDED: {
      return {
        ...state,
        isFastStartPolicyAddedd: action.payload,
      };
    }
    default:
      return state;
  }
};
