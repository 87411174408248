import React from 'react';
import { styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';

const Android12Switch = styled(Switch)(({ checked, text }) => ({
  padding: 8,
  width: 70,
  color: 'white',
  fontSize: '11px',
  fontWeight: '700',
  marginTop:'10px',
  // position: 'absolute !important',
  '& .MuiButtonBase-root': {
    position: 'absolute !important',
    padding:'inherit',
  },
  '& .MuiSwitch-switchBase.Mui-checked': {
    transform: 'translateX(30px) !important',
  },
  '& .MuiSwitch-track': {
    borderRadius: 20,
    width: 90,
    '&:before, &:after': {
      content: '""',
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      width: 16,
      height: 16,
    },
    '&:before': {
      content: checked ? `"${text}"` : '""',
      left: 17,
      top: 20,
    },
    '&:after': {
      content: checked ? '""' : `"${text}"`,
      right: 22,
      top: 20,
    },
  },
  '& .MuiSwitch-thumb': {
    width: 16,
    height: 16,
    margin: 3,
    color:'#fff',
    boxShadow: '0px 3px 8px rgba(0, 0, 0, 0.15), 0px 3px 1px rgba(0, 0, 0, 0.06)',
  },
}));
export function ToggleSwitch({ checked, onChange, text, disabled }) {
  return (
    <Android12Switch
      checked={checked}
      onChange={onChange}
      text={text}
      disabled={disabled}
    />
  );
}
