import {
  all,
  call,
  delay,
  fork,
  put,
  select,
  takeLeading,
} from 'redux-saga/effects';
import * as actions from 'constants/action-types';
import { errorHandler } from '../actions/error';
import {
  getRedirectUrlFromParams,
  setRedirectUrlCookie,
} from 'util/redirect-url';
import { push } from 'connected-react-router';
import { REFRESHED_AT_SESSION_STORAGE_KEY } from 'constants/constant';
import { saveState } from 'redux/persist';

const STATE_CHECK_TIMOUT = 1000; // 1 second
const STATE_CHECK_MAX_RETRIES = 10;
const STATE_CHECK_INTERVAL = STATE_CHECK_TIMOUT / STATE_CHECK_MAX_RETRIES;

const selectIsStateInitialized = (state) => state.auth.isStateInitialized;

function* redirectToDashboard() {
  yield put(
    push({
      pathname: `/app/producer/dashboard`,
    })
  );
}

function* redirectToSignIn() {
  yield put(
    push({
      pathname: '/signin',
    })
  );
}

function* waitForStateInitialization() {
  let isStateInitialized = yield select(selectIsStateInitialized);
  let retryTimeLeft = 1000;

  while (!isStateInitialized && retryTimeLeft > 0) {
    yield delay(STATE_CHECK_INTERVAL);
    retryTimeLeft -= STATE_CHECK_INTERVAL;

    isStateInitialized = yield select(selectIsStateInitialized);
  }

  return isStateInitialized;
}

function* validateRequiredStateData() {
  try {
    const isStateInitialized = yield call(waitForStateInitialization);
    const redirectUrl = getRedirectUrlFromParams();

    if (isStateInitialized) {
      if (redirectUrl) {
        // Delay the redirect for the race condition where the state is not fully initialized
        // and the user is redirected back to the signin page after successful login trial
        const state = yield select();
        yield saveState(state);
        yield delay(1000);
        window.location.href = redirectUrl;
      } else {
        yield call(redirectToDashboard);
      }
    } else {
      setRedirectUrlCookie(redirectUrl, 'producer');

      yield call(redirectToSignIn);
    }
  } catch (error) {
    yield put(errorHandler(error));
  } finally {
    // Necessary for the scenario of a user logging out,
    // and trying to access new app route within a small amount of time
    sessionStorage.removeItem(REFRESHED_AT_SESSION_STORAGE_KEY);
  }
}

export function* watchValidateRequiredStateData() {
  yield takeLeading(
    actions.VALIDATE_REQUIRED_STATE_DATA,
    validateRequiredStateData
  );
}

export default function* rootSaga() {
  yield all([fork(watchValidateRequiredStateData)]);
}
