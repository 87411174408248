// @ts-check

import React from 'react';
import { isMobileOnly } from 'react-device-detect';
const TrustPilotWidget = ({ trustBoxRef }) => {
  return (
    <div
      ref={trustBoxRef}
      className="trustpilot-widget"
      data-locale="en-US"
      data-template-id={
        isMobileOnly ? '5419b637fa0340045cd0c936' : '5419b6ffb0d04a076446a9af'
      }
      data-businessunit-id="6446763e6fd87d42b8cd2738"
      data-style-height="20px"
      data-style-width="100%"
      data-theme="light"
    >
      <a
        href="https://www.trustpilot.com/review/firstconnectinsurance.com"
        target="_blank"
        rel="noopener noreferrer"
      >
        Trustpilot
      </a>
    </div>
  );
};

export default TrustPilotWidget;
