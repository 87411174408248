// @ts-check

import React from 'react';
import {
  CARRIER_STORE_AGENT_APPLICATION_RESTRICTION_ENABLED,
  PRINCIPAL_ACCESS_ROLE,
  PRODUCER,
  TRAILING_SLASH_URL,
} from 'constants/constant';
import { connect } from 'react-redux';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import { fetchProducerSettings } from 'redux/actions/producer-settings';
import { fetchProducerFlags } from 'redux/actions/producer-flags';
import asyncComponent from 'util/async-component';
import {
  getAuthDetails,
  getDefaultPath,
  getModulePermission,
  isCarrierStore,
  isCarrierStoreSubProducer,
  isUserSubProducer,
} from 'util/extra';
import CarrierStore from './routes/carrier-store';
import CarrierApettite from 'components/workers-comp/apetite';
import { setBankInfoModal } from 'redux/actions/dashboard';
import config from 'config';
import { GlobalAuthLoader } from 'components/global-loader';
import DancingDots from 'components/dancing-dots';
import { START_ROUTE } from 'constants/routes';
import { navigateToNewAppRoute } from 'util/navigate-to-new-app-route';

class Producer extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      closeModal: false,
    };
  }

  componentDidMount() {
    this.props.fetchProducerSettings();
    this.props.fetchProducerFlags();
  }

  render() {
    const { signupFlow } = this.props;
    const isSubProducer = isUserSubProducer(signupFlow);
    const {
      match,
      modulePermissionsData,
      isWholesaler,
      impersonate,
      producer_id,
      producer_code,
      loader,
      role,
      carrierAppointmentRequestCount,
      carrierStoreAgentApplicationRestrictionEnabled,
      enableMenuItem,
      isNewCarrierStoreEnabled,
      location: { pathname },
    } = this.props;

    const shouldShowCarrierStore =
      isCarrierStore(
        carrierStoreAgentApplicationRestrictionEnabled,
        carrierAppointmentRequestCount,
        enableMenuItem,
        isNewCarrierStoreEnabled
      ) && !window.location.href.includes('carrier-store');
    const isPrincipalProducer = role === PRODUCER;
    const isPrincipalAccessProducer = role === PRINCIPAL_ACCESS_ROLE;
    const showCarrierStoreForSubProducer =
      !isCarrierStoreSubProducer(isWholesaler) &&
      window.location.href.includes('carrier-store');
    const getPath =
      !!modulePermissionsData &&
      getDefaultPath({
        module: modulePermissionsData,
        match,
        isWholesaler,
        producerDetails: { producer_id, producer_code },
        carrierStoreAgentApplicationRestrictionEnabled:
          carrierStoreAgentApplicationRestrictionEnabled ||
          JSON.parse(
            localStorage.getItem(
              CARRIER_STORE_AGENT_APPLICATION_RESTRICTION_ENABLED
            )
          ),
        carrierAppointmentRequestCount,
        enableMenuItem,
        availableCarrierCount: JSON.parse(
          localStorage.getItem('availableCarrierCount') || null
        ),
        isNewCarrierStoreEnabled,
      });

    const isAwaitingPermissions = !modulePermissionsData || loader;

    // Global loader for handling application loading state after successful sign in.
    // Toggled on after successful SPA sign in (handleAuth0SignInRedirectAuth0Spa).
    // Toggled off after successful redirect to new app on sign in success OR on app mount.
    if (this.props.isGlobalAuthLoaderShown) {
      return <GlobalAuthLoader />;
    }

    return isAwaitingPermissions ? (
      <DancingDots fullpageWrapper />
    ) : (
      <React.Fragment>
        <Switch>
          <Redirect from={TRAILING_SLASH_URL} to={pathname.slice(0, -1)} />
          <Route
            exact
            path={`${match.url}/start`}
            component={() => {
              navigateToNewAppRoute(START_ROUTE);
              return null;
            }}
          />
          <Redirect
            exact
            from={`${match.url}/`}
            to={!!getPath ? getPath : `${match.url}/error`}
          />
          {(shouldShowCarrierStore ||
            (isSubProducer &&
              !isPrincipalAccessProducer &&
              showCarrierStoreForSubProducer)) && (
            <Route
              component={asyncComponent(() => import('components/error-404'))}
            />
          )}
          <Route
            exact
            path={`${match.url}/dashboard`}
            component={asyncComponent(() => import('./routes/dashboard'))}
          />
          <Route
            exact
            path={`${match.url}/my-commission`}
            component={asyncComponent(() => import('./routes/my-commission'))}
          />
          <Route
            path={`${match.url}/my-account`}
            component={asyncComponent(() => import('./routes/my-account'))}
          />
          {(isPrincipalProducer ||
            isPrincipalAccessProducer ||
            impersonate?.isImpersonate) && (
            <Route
              exact
              path={`${match.url}/my-policies`}
              component={asyncComponent(() => import('./routes/my-policies'))}
            />
          )}
          <Route
            exact
            path={`${match.url}/carrier-store`}
            component={CarrierStore}
          />
          <Route
            exact
            path={`${match.url}/agency-commission`}
            component={asyncComponent(() =>
              import('./routes/agency-commission')
            )}
          />
          {(isPrincipalProducer ||
            isPrincipalAccessProducer ||
            impersonate?.isImpersonate) && (
            <Route
              path={`${match.url}/fast-start`}
              component={asyncComponent(() => import('./routes/fast-start'))}
            />
          )}
          <Route
            path={`${match.url}/agency-profile`}
            component={asyncComponent(() => import('./routes/agency-profile'))}
          />
          <Redirect
            exact
            from={`${match.url}/quote`}
            to={`${match.url}/quote/commercial-line`}
          />
          <Redirect
            exact
            from={`${match.url}/quote`}
            to={`${match.url}/workers-comp`}
          />
          {config.WC_UPDATE_ENABLED && (
            <Route
              exact
              path={`${match.url}/workers-comp`}
              component={CarrierApettite}
            />
          )}
          <Redirect
            exact
            from={`${match.url}/quote`}
            to={`${match.url}/business-owner-policy`}
          />
          {config.NEW_LOB_UPDATE_ENABLED && (
            <Route
              exact
              path={`${match.url}/business-owner-policy`}
              component={CarrierApettite}
            />
          )}
          <Redirect
            exact
            from={`${match.url}/quote`}
            to={`${match.url}/general-liability`}
          />
          {config.NEW_LOB_UPDATE_ENABLED && (
            <Route
              exact
              path={`${match.url}/general-liability`}
              component={CarrierApettite}
            />
          )}
          <Redirect
            exact
            from={`${match.url}/quote`}
            to={`${match.url}/professional-liability`}
          />
          {config.NEW_LOB_UPDATE_ENABLED && (
            <Route
              exact
              path={`${match.url}/professional-liability`}
              component={CarrierApettite}
            />
          )}
          <Route
            exact
            path={`${match.url}/new-quote`}
            component={asyncComponent(() => import('components/new-quote'))}
          />
          <Route
            component={asyncComponent(() => import('components/error-404'))}
          />
        </Switch>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({
  auth,
  impersonate,
  modulePermissions,
  producerSettings,
}) => {
  const { permissions, producer, isImpersonate } = impersonate;
  const {
    authUser,
    producer_code,
    producer_id,
    isWholesaler,
    loader,
    role,
    userRole,
    signupFlow,
  } = getAuthDetails({
    auth,
    producer,
    isImpersonate,
  });
  const { isNewDashboardEnabled, isNewCarrierStoreEnabled } = producerSettings;
  const {
    carrierAppointmentRequestCount,
    carrierStoreAgentApplicationRestrictionEnabled,
    enableMenuItem,
    appointments,
    isGlobalAuthLoaderShown,
  } = auth;
  const modulePermissionsData = getModulePermission(
    modulePermissions.modulePermissionsData,
    permissions,
    isImpersonate
  );
  return {
    authUser,
    modulePermissionsData,
    producer_code,
    producer_id,
    isWholesaler,
    impersonate,
    loader,
    role,
    userRole,
    carrierAppointmentRequestCount,
    carrierStoreAgentApplicationRestrictionEnabled,
    enableMenuItem,
    signupFlow,
    appointments,
    isNewDashboardEnabled,
    isGlobalAuthLoaderShown,
    isNewCarrierStoreEnabled,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    setBankInfoModal,
    fetchProducerSettings,
    fetchProducerFlags,
  })(Producer)
);
