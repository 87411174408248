import * as types from '../../constants/action-types';
import { isEmpty } from 'lodash';

const initState = () => ({
  carrierCredentialListLoader: false,
  carrierCredentialList: [],
  carrierCredentialDetailsLoader: false,
  carrierCredentialDetails: {},
});

export default (state = initState(), action) => {
  switch (action.type) {
    case types.SET_CARRIER_CREDENTIALS: {
      const { list } = action.payload;
      return {
        ...state,
        carrierCredentialList: list,
      };
    }
    case types.FETCH_CARRIER_CREDENTIALS_LOADER: {
      return {
        ...state,
        carrierCredentialListLoader: action.payload,
      };
    }
    case types.FETCH_CARRIER_CREDENTIALS_DETAILS_LOADER: {
      return {
        ...state,
        carrierCredentialDetailsLoader: action.payload,
      };
    }
    case types.SET_CARRIER_CREDENTIALS_DETAILS: {
      const { carrierCredentialDetails } = action.payload;
      return {
        ...state,
        carrierCredentialDetails: carrierCredentialDetails,
      };
    }
    case types.STORE_UPDATED_APPOINTMENT_TYPE: {
      const { appointmentType, carrierId, updatedAt } = action.payload;
      const carrierCredentialListData = [...state.carrierCredentialList];
      const elementsIndex = state.carrierCredentialList.findIndex(
        element => element.carrierId === carrierId
      );
      carrierCredentialListData[elementsIndex] = {
        ...carrierCredentialListData[elementsIndex],
        appointmentType: appointmentType,
        updatedAt,
      };
      return {
        ...state,
        carrierCredentialDetails: {
          ...state.carrierCredentialDetails,
          carrierProducerAppointment: {
            ...state.carrierCredentialDetails.carrierProducerAppointment,
            appointmentType: appointmentType,
          },
        },
        carrierCredentialList: [...carrierCredentialListData],
        isAppointmentTypeUpdated: true,
      };
    }
    case types.CLEAR_IS_UPDATED_APPOINTMENT_DATA: {
      return {
        ...state,
        isAppointmentTypeUpdated: false,
      };
    }
    case types.STORE_UPDATED_CARRIER_PRODUCER_CREDIENTIAL: {
      const {
        carrierProducerAppointmentId,
        states,
        username,
        password,
        producerCode,
        isAdd,
        isDeleted,
        lob,
        updatedAt,
        carrierId,
      } = action.payload;
      const carrierCredential = [...state.carrierCredentialList];
      const elementIndex = state.carrierCredentialList.findIndex(
        element => element.carrierId === carrierId
      );
      carrierCredential[elementIndex] = {
        ...carrierCredential[elementIndex],
        updatedAt,
      };
      let carrierCredentialListData = [];
      if (
        !!state?.carrierCredentialDetails?.carrierProducerAppointment
          ?.carrierProducerCredentials
      ) {
        carrierCredentialListData = [
          ...state.carrierCredentialDetails.carrierProducerAppointment
            .carrierProducerCredentials,
        ];
      }
      const elementsIndex = !isEmpty(
        state?.carrierCredentialDetails?.carrierProducerAppointment
          ?.carrierProducerCredentials
      )
        ? state.carrierCredentialDetails.carrierProducerAppointment.carrierProducerCredentials.findIndex(
            element =>
              !!element?.carrierProducerAppointmentId &&
              element.carrierProducerAppointmentId ===
                carrierProducerAppointmentId
          )
        : 0;
      if (!!isAdd) {
        carrierCredentialListData = [
          {
            carrierProducerAppointmentId: carrierProducerAppointmentId,
            states: states,
            username: username,
            password: password,
            producerCode: producerCode,
            lob: lob,
            updatedAt,
            carrierId,
          },
          ...carrierCredentialListData,
        ];
        const index = carrierCredential.findIndex(
          element => element.carrierId === carrierId
        );
        carrierCredential[index] = {
          ...carrierCredential[index],
          updatedAt,
        };
      } else if (isDeleted) {
        carrierCredentialListData = state.carrierCredentialDetails.carrierProducerAppointment.carrierProducerCredentials.filter(
          item =>
            item.carrierProducerAppointmentId !== carrierProducerAppointmentId
        );
        const index = carrierCredential.findIndex(
          element => element.carrierId === carrierId
        );
        carrierCredential[index] = {
          ...carrierCredential[index],
          updatedAt,
        };
      } else {
        carrierCredentialListData[elementsIndex] = {
          ...carrierCredentialListData[elementsIndex],
          carrierProducerAppointmentId: carrierProducerAppointmentId,
          states: states,
          username: username,
          password: password,
          producerCode: producerCode,
          lob: lob,
          updatedAt,
        };
      }
      return {
        ...state,
        carrierCredentialDetails: {
          ...state.carrierCredentialDetails,
          carrierProducerAppointment: {
            ...state.carrierCredentialDetails.carrierProducerAppointment,
            carrierProducerCredentials: [...carrierCredentialListData],
          },
        },
        carrierCredentialList: [...carrierCredential],
        isCarrierProducerCredentialsUpdated: true,
      };
    }
    case types.CLEAR_IS_PRODUCER_CREDIENTIAL_UPDATED: {
      return {
        ...state,
        isCarrierProducerCredentialsUpdated: false,
      };
    }
    case types.CLEAR_CARRIER_PRODUCER_CREDENTIALS: {
      return {
        ...state,
        carrierCredentialDetails: {
          ...state.carrierCredentialDetails,
          carrierProducerAppointment: {
            ...state.carrierCredentialDetails.carrierProducerAppointment,
            carrierProducerCredentials: [],
          },
        },
      };
    }
    case types.DELETE_CARRIER_CREDENTIAL_STATE_LOADER: {
      return {
        ...state,
        deleteCarrierProducerCredentialLoader: action.payload,
      };
    }
    default:
      return state;
  }
};
