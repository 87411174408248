/* eslint-disable no-unreachable */
import {
  all,
  call,
  delay,
  fork,
  put,
  select,
  takeEvery,
} from 'redux-saga/effects';
import axios from 'util/api';
import * as agentDetailsActions from 'constants/action-types';
import {
  fetchAgentList,
  licenseFileLoader,
  licenseListLoader,
  licenseLOAStateListLoader,
  setAgentComplianceList,
  setAgentComplianceListUpdateLoader,
  setAgentList,
  setAgentListLoader,
  setAgentStatusLoaderFun,
  setCompleteAgentProfile,
  setComplianceFileUploadError,
  setHubspotIdsDetailLoader,
  setHubspotIdsDetailSaved,
  setLOAStateList,
  setNeedsApprovalList,
  setTotalProducerCount,
  storeAgentErrorMessages,
  updateAgentLoader,
  updateFilterCount,
} from '../actions/agent-details';
import { setW9infoModal } from 'redux/actions/dashboard';

import {
  closedModelFun,
  duplicatePhoneNumber,
  fetchAgentDetails,
  invalidFileType,
  storeAgentDetails,
  storeComplianceLicense,
  storeSubAgentStatus,
  updateAgentCompliance,
  updateAgentRole,
  updateAgentStatusReason,
  updateNpnData,
} from '../actions/my-account';

import { setAuthUserName, updateComplianceStatus } from '../actions/auth';
import { errorHandler } from '../actions/error';
import { displayErrorMessage, displaySuccessMessage } from 'util/user-feedback';
import {
  capitalize,
  every,
  filter,
  forEach,
  get,
  isEmpty,
  isNull,
  isUndefined,
} from 'lodash';
import { encryptUrlParams } from 'util/crypto';
import {
  complianceStatus,
  globalMessages,
  updateComplianceFile,
  updateProducer,
  uploadFile,
} from '../../util/extra';
import {
  ACTIVE_STATUS,
  ADMIN,
  AGENCY_PROFILE,
  APPROVE,
  COMPLIANCE_STATUS,
  E_AND_O,
  ENO,
  ENO_LIST,
  INCOMPLETE_SIGNUP,
  LICENSE_AND_ENO,
  NEEDS_APPROVAL,
  NPN,
  NPN_AGENT_LIST,
  PHONE_ALREADY_EXIST,
  PRODUCER,
  REGULATORY_ACTION,
  REGULATORY_ACTION_API_COMPLIANCE_MODULE,
  REGULATORY_ACTION_LIST,
  REJECT,
  TAX_AGENT_LIST,
  TAX_KEY,
  W9,
} from 'constants/constant';
import config from 'config';
import { getImpersonateBFFRequestConfig } from './impersonate';

const PRODUCER_COMPLIANCE_URL_BASE = '/api/v2/producer-compliance/producer';

function resolveComplianceModule(entity) {
  switch (entity) {
    case ENO_LIST:
    case LICENSE_AND_ENO:
      return ENO;
    case TAX_AGENT_LIST:
    case TAX_KEY:
      return W9;
    case NPN_AGENT_LIST:
    case NPN:
      return NPN;
    case REGULATORY_ACTION_LIST:
      return REGULATORY_ACTION_API_COMPLIANCE_MODULE;
  }
}
function resolveCompliancePayload(action) {
  switch (action?.toUpperCase()) {
    case APPROVE:
    case COMPLIANCE_STATUS.IN_COMPLIANCE:
      return COMPLIANCE_STATUS.IN_COMPLIANCE;
    case COMPLIANCE_STATUS.IN_REVIEW:
      return COMPLIANCE_STATUS.IN_REVIEW;
    case REJECT:
      return COMPLIANCE_STATUS.REJECTED;
  }
}
function updateProducerCompliance({
  producerId,
  module,
  status,
  baseUrl = PRODUCER_COMPLIANCE_URL_BASE,
}) {
  return axios.put(`${baseUrl}/${producerId}/${module}/status`, { status });
}

function setProducerAggregateCount(count) {
  let updatedCount = {
    all: count.all,
    active: count.active,
    pending: count.pending,
    restricted: count.restricted,
    suspend: count.suspend,
    dormant: count.dormant,
    terminated: count.terminated,
    errorOmission: count.eno,
    taxAgent: count.w9,
    npn: count.npn,
    regulatoryAction: count.regulatoryAction,
    needsApproval: count.needsApproval,
    incompleteSignup: count.incomplete,
  };
  localStorage.setItem('producerStatusAggregate', JSON.stringify(updatedCount));
}

function* fetchAgentDataList({ payload }) {
  try {
    yield put(setAgentListLoader(true));
    let agentDetails = null;
    if (!!payload.searchList) {
      const { searchList, filterStatus } = payload;
      searchList.forEach((item) => {
        return delete item.error;
      });
      agentDetails = yield axios.get(
        `api/v1/producer/get/agents/1?filter=${JSON.stringify(
          searchList
        )}&current_page=${filterStatus}`
      );
    } else {
      agentDetails = yield axios.get(
        `api/v1/producer/get/agents/1?current_page=${payload}`
      );
    }
    if (agentDetails.data) {
      if (!!agentDetails.data.producers) {
        setProducerAggregateCount(agentDetails.data.producerStatusAggregate);
        if (payload === NEEDS_APPROVAL.toLowerCase()) {
          yield put(
            setNeedsApprovalList({
              data: agentDetails.data.producers,
            })
          );
        } else if (!!!payload.searchList && payload === ENO) {
          yield put(
            setAgentComplianceList({
              entity: ENO_LIST,
              data: agentDetails.data.producers,
            })
          );
        } else if (!!!payload.searchList && payload === NPN) {
          yield put(
            setAgentComplianceList({
              entity: NPN_AGENT_LIST,
              data: agentDetails.data.producers,
            })
          );
        } else if (!!!payload.searchList && payload === REGULATORY_ACTION) {
          yield put(
            setAgentComplianceList({
              entity: REGULATORY_ACTION_LIST,
              data: agentDetails.data.producers,
            })
          );
        } else if (!!!payload.searchList && payload === W9) {
          yield put(
            setAgentComplianceList({
              entity: TAX_AGENT_LIST,
              data: agentDetails.data.producers,
            })
          );
        } else {
          yield put(
            setAgentList({
              data: agentDetails.data.producers,
            })
          );
        }
        yield put(
          updateFilterCount({
            count: agentDetails.data.producerStatusAggregate,
          })
        );
      }
    }
  } catch (error) {
    yield put(errorHandler(error));
  }
  yield put(setAgentListLoader(false));
}

export function* fetchAgentListWatcher() {
  yield takeEvery(agentDetailsActions.FETCH_AGENT_LIST, fetchAgentDataList);
}
function getFileURL(reduxState, name) {
  const folder_name = reduxState.myAccount.details.producers.producer_code;
  const fileURL = `${config.PRODUCER_UPLOAD_FOLDER}/${folder_name}/${name}`;
  return fileURL;
}
function* catchForCompliance(error) {
  yield put(errorHandler(error));
}

function* saveAgentDetails({
  payload: { stepId, entity, agentId, data, updateNIPR },
}) {
  yield put(duplicatePhoneNumber(false));
  try {
    const reduxState = yield select();
    const authProducerId = reduxState.auth.producer_id;
    yield put(updateAgentLoader(true));
    let isErrorPresent = false;
    if (data.file) {
      const file = data.file[0];
      const name = file.name;
      const fileURL = getFileURL(reduxState, name);
      const result = yield call(uploadFile, file, fileURL);
      let updateEntity = '';
      if (result.status === 200) {
        const fileData = {
          producer_id: reduxState.myAccount.details.producers.producer_id,
          file_path: result.data.file,
          file_category: updateEntity,
        };
        const responseFile = yield call(updateComplianceFile, fileData);
        if (responseFile.data) {
          const { file_url, file_name } = responseFile.data;
          const dataFile = {
            file: '',
            fileurl: file_url,
            fileName: file_name,
          };
          Object.assign(data, dataFile);
        }
        yield put(
          setComplianceFileUploadError({ updateEntity, isSuccess: true })
        );
      } else {
        isErrorPresent = true;
        yield put(errorHandler(result));
        yield put(closedModelFun(false));
        yield put(
          setComplianceFileUploadError({ updateEntity, isSuccess: false })
        );
      }
    }
    if (!isErrorPresent) {
      let isSubAgent = false;
      if (entity === AGENCY_PROFILE) {
        isSubAgent = data.isSubProducer;
        delete data.agentRole;
        if (!data.isSubProducer) {
          delete data.isSubProducer;
          delete data.role;
        } else {
          delete data.isSubProducer;
        }
      }

      const responseDetails = yield axios.post(
        '/api/v1/producer/update-producer',
        {
          data: {
            [entity]: { ...data },
            stepId,
            producerId: agentId,
            updateNIPR,
          },
        }
      );
      if (responseDetails.data) {
        const { userRole } = reduxState.auth;
        if (entity === AGENCY_PROFILE) {
          if (isSubAgent) {
            yield put(updateAgentRole(data.role));
          }
          if (!!userRole && userRole === ADMIN) {
            let npnData = {
              individualNPN: data.individualNPN,
              businessNPN: data.businessNPN,
            };
            yield put(updateNpnData(npnData));
          }
          yield put(fetchAgentDetails(agentId));
        }
        if (!!responseDetails?.data?.complianceStatus && userRole !== ADMIN) {
          yield put(
            updateComplianceStatus(responseDetails.data.complianceStatus)
          );
        }
        if (
          !isEmpty(get(responseDetails.data, 'licenseInfo', null)) &&
          entity === AGENCY_PROFILE
        ) {
          yield put(
            storeComplianceLicense({
              data: responseDetails.data.licenseInfo,
              refreshMessages: [],
              show: false,
            })
          );
        }
        yield put(closedModelFun(true));
        yield put(
          storeAgentDetails({
            entity,
            data,
            responseStatus: true,
            commissionPaymentSetting:
              responseDetails?.data?.commission_payment_settings,
          })
        );

        // to change login user name in UserInfo component.
        if (
          entity === AGENCY_PROFILE &&
          !!userRole &&
          userRole.toLowerCase() !== ADMIN &&
          userRole.toLowerCase() === PRODUCER &&
          authProducerId === agentId
        ) {
          const name = data.firstName;
          yield put(setAuthUserName(name));
        }
        if (!!responseDetails?.data?.complianceStatus) {
          yield put(
            updateAgentCompliance({
              complianceStatus: responseDetails.data.complianceStatus,
            })
          );
        }
        yield displaySuccessMessage(responseDetails.data.message);
      }
    }
  } catch (error) {
    if (
      error.response.status === 409 &&
      error.response.data.message === PHONE_ALREADY_EXIST
    ) {
      yield put(duplicatePhoneNumber(true));
    }
    yield put(errorHandler(error));
  }
  yield put(updateAgentLoader(false));
}

export function* saveAgentDetailsWatcher() {
  yield takeEvery(agentDetailsActions.SAVE_AGENT_DETAILS, saveAgentDetails);
}

function* saveW9Details({
  payload: { stepId, entity, agentId, data, updateNIPR },
}) {
  try {
    const reduxState = yield select();
    yield put(updateAgentLoader(true));
    let isErrorPresent = false;
    if (data.file) {
      const file = data.file[0];
      const name = file.name;
      const fileURL = getFileURL(reduxState, name);
      const result = yield call(uploadFile, file, fileURL);
      let updateEntity = W9;
      if (result.status === 200) {
        const fileData = {
          producer_id: reduxState.myAccount.details.producers.producer_id,
          file_path: result.data.file,
          file_category: updateEntity,
        };
        const responseFile = yield call(updateComplianceFile, fileData);
        if (responseFile.data) {
          const { file_url, file_name } = responseFile.data;
          const dataFile = {
            file: '',
            fileurl: file_url,
            fileName: file_name,
          };
          Object.assign(data, dataFile);
          const { enoAndW9VerificationData } = responseFile.data;
          const { userRole } = reduxState.auth;
          if (
            userRole === ADMIN &&
            !!enoAndW9VerificationData?.w9?.w9VerifiedBy &&
            !!enoAndW9VerificationData?.w9?.w9VerifiedOn
          ) {
            data.w9VerifiedByName = enoAndW9VerificationData.w9.w9VerifiedBy;
            data.w9VerifiedOn = enoAndW9VerificationData.w9.w9VerifiedOn;
          }
        }
        yield put(
          setComplianceFileUploadError({ updateEntity, isSuccess: true })
        );
      } else {
        isErrorPresent = true;
        yield put(errorHandler(result));
        yield put(closedModelFun(false));
        yield put(
          setComplianceFileUploadError({ updateEntity, isSuccess: false })
        );
      }
    }
    if (!isErrorPresent) {
      const dataSet = {
        data: {
          [entity]: Object.assign({}, data),
          stepId,
          producerId: agentId,
          updateNIPR,
        },
      };
      const responseDetails = yield call(updateProducer, dataSet);
      if (responseDetails.data) {
        const { userRole } = reduxState.auth;

        if (!!responseDetails?.data?.complianceStatus) {
          const { enoAndW9VerificationData } = responseDetails.data;
          data.w9_compliance_status = responseDetails.data.complianceStatus.w9;
          if (
            userRole === ADMIN &&
            !!enoAndW9VerificationData?.w9?.w9VerifiedBy &&
            !!enoAndW9VerificationData?.w9?.w9VerifiedOn
          ) {
            data.w9VerifiedByName = enoAndW9VerificationData.w9.w9VerifiedBy;
            data.w9VerifiedOn = enoAndW9VerificationData.w9.w9VerifiedOn;
          }
        }
        if (!!responseDetails?.data?.complianceStatus && userRole !== ADMIN) {
          yield put(
            updateComplianceStatus(responseDetails.data.complianceStatus)
          );
        }
        if (
          !isUndefined(updateNIPR) &&
          !isNull(updateNIPR) &&
          !isEmpty(get(responseDetails.data, 'refreshMessages', null))
        ) {
          const { licenseInfo } = responseDetails.data;
          yield put(
            storeComplianceLicense({
              data: licenseInfo,
              refreshMessages: responseDetails.data.refreshMessages,
              show: !every(responseDetails.data.refreshMessages, {
                success: true,
              }),
            })
          );
          yield put(
            storeAgentDetails({
              entity: LICENSE_AND_ENO,
              data: licenseInfo,
              updateNPN: true,
            })
          );
          yield put(
            storeAgentDetails({
              entity,
              data,
            })
          );

          if (every(responseDetails.data.refreshMessages, { success: true })) {
            yield put(closedModelFun(true));
          }
        } else {
          yield put(closedModelFun(true));
          yield put(
            storeAgentDetails({
              entity,
              data,
              responseStatus: true,
              commissionPaymentSetting:
                responseDetails?.data?.commission_payment_settings,
            })
          );
          if (userRole !== ADMIN) {
            const { licenseInfo } = responseDetails.data;
            yield put(
              storeAgentDetails({
                entity: LICENSE_AND_ENO,
                data: licenseInfo,
                updateNPN: false,
              })
            );
          }
        }
        if (!!responseDetails?.data?.complianceStatus) {
          yield put(
            updateAgentCompliance({
              complianceStatus: responseDetails.data.complianceStatus,
            })
          );
        }
        yield displaySuccessMessage(responseDetails.data.message);
        yield put(setW9infoModal(false));
      }
    }
  } catch (error) {
    yield call(catchForCompliance(error));
  }
  yield put(updateAgentLoader(false));
}

export function* saveW9DetailsWatcher() {
  yield takeEvery(agentDetailsActions.SAVE_W9_DETAILS, saveW9Details);
}

function* saveLicenseDetails({
  payload: { stepId, entity, agentId, data, updateNIPR },
}) {
  try {
    const reduxState = yield select();
    yield put(updateAgentLoader(true));
    const dataSet = {
      data: {
        [entity]: Object.assign({}, data),
        stepId,
        producerId: agentId,
        updateNIPR,
      },
    };
    const responseDetails = yield call(updateProducer, dataSet);
    if (responseDetails.data) {
      const { userRole } = reduxState.auth;
      if (responseDetails?.data?.complianceStatus) {
        data.license_compliance_status =
          responseDetails.data.complianceStatus.license;
      }
      if (
        (responseDetails?.data?.licenseInfo && userRole === ADMIN) ||
        responseDetails?.data?.agencyProfile
      ) {
        yield put(
          storeAgentDetails({
            entity: LICENSE_AND_ENO,
            data: { ...responseDetails?.data?.licenseInfo },
          })
        );
        // Re-fetch agent defails to refresh appointed carriers, as carrier appointment depends on licenseInfo.
        yield put(fetchAgentDetails(agentId));
      }
      if (responseDetails?.data?.complianceStatus && userRole !== ADMIN) {
        yield put(
          updateComplianceStatus(responseDetails.data.complianceStatus)
        );
      }
      if (
        !isUndefined(updateNIPR) &&
        !isNull(updateNIPR) &&
        !isEmpty(get(responseDetails.data, 'refreshMessages', null))
      ) {
        const { licenseInfo } = responseDetails.data;
        if (!!responseDetails?.data?.complianceStatus?.license) {
          const { license } = responseDetails.data.complianceStatus;
          licenseInfo.license_compliance_status = license;
        }
        yield put(
          storeComplianceLicense({
            data: licenseInfo,
            refreshMessages: responseDetails.data.refreshMessages,
            show: !every(responseDetails.data.refreshMessages, {
              success: true,
            }),
          })
        );
        yield put(
          storeAgentDetails({
            entity,
            data,
          })
        );
        yield put(
          storeAgentDetails({
            entity: LICENSE_AND_ENO,
            data: licenseInfo,
            updateNPN: true,
          })
        );
        if (every(responseDetails.data.refreshMessages, { success: true })) {
          yield put(closedModelFun(true));
        }
      } else {
        yield put(closedModelFun(true));
        yield put(
          storeAgentDetails({
            entity,
            data,
            responseStatus: true,
            commissionPaymentSetting:
              responseDetails?.data?.commission_payment_settings,
          })
        );
      }
      if (!!responseDetails?.data?.complianceStatus) {
        yield put(
          updateAgentCompliance({
            complianceStatus: responseDetails.data.complianceStatus,
          })
        );
      }
      yield displaySuccessMessage(responseDetails.data.message);
    }
  } catch (error) {
    yield call(catchForCompliance, error);
  }
  yield put(updateAgentLoader(false));
}

export function* saveLicenseDetailsWatcher() {
  yield takeEvery(agentDetailsActions.SAVE_LICENSE_DETAILS, saveLicenseDetails);
}
function* saveBankDetails({ payload: { stepId, entity, agentId, data } }) {
  try {
    const reduxState = yield select();
    yield put(updateAgentLoader(true));
    let isErrorPresent = false;
    if (!isErrorPresent) {
      const responseDetails = yield axios.post(
        '/api/v1/producer/update-producer',
        {
          data: {
            [entity]: { ...data },
            stepId,
            producerId: agentId,
          },
        }
      );
      if (responseDetails.data) {
        const { userRole } = reduxState.auth;

        if (!!responseDetails?.data?.complianceStatus && userRole !== ADMIN) {
          yield put(
            updateComplianceStatus(responseDetails.data.complianceStatus)
          );
        }
        yield put(closedModelFun(true));
        yield put(
          storeAgentDetails({
            entity,
            data,
            responseStatus: true,
            commissionPaymentSetting:
              responseDetails?.data?.commission_payment_settings,
          })
        );
        if (!!responseDetails?.data?.complianceStatus) {
          yield put(
            updateAgentCompliance({
              complianceStatus: responseDetails.data.complianceStatus,
            })
          );
        }
        yield displaySuccessMessage(responseDetails.data.message, '', 200);
      }
    }
  } catch (error) {
    yield put(errorHandler(error));
  }
  yield put(updateAgentLoader(false));
}

export function* saveBankDetailsWatcher() {
  yield takeEvery(agentDetailsActions.SAVE_BANK_DETAILS, saveBankDetails);
}

function* saveEnoDetails({ payload: { stepId, entity, agentId, data } }) {
  try {
    const reduxState = yield select();
    yield put(updateAgentLoader(true));
    let isErrorPresent = false;
    if (data.file) {
      const file = data.file[0];
      const name = file.name;
      const fileURL = getFileURL(reduxState, name);
      const result = yield call(uploadFile, file, fileURL);
      let updateEntity = E_AND_O;
      if (result.status === 200) {
        const fileData = {
          producer_id: reduxState.myAccount.details.producers.producer_id,
          file_path: result.data.file,
          file_category: updateEntity,
        };
        const responseFile = yield call(updateComplianceFile, fileData);
        if (responseFile.data) {
          const { file_url, file_name } = responseFile.data;
          const dataFile = {
            file: '',
            fileurl: file_url,
            fileName: file_name,
          };
          Object.assign(data, dataFile);
          const { enoAndW9VerificationData, licenseInfo } = responseFile.data;
          const { userRole } = reduxState.auth;

          if (
            userRole === ADMIN &&
            !!enoAndW9VerificationData?.eno?.enoVerifiedBy &&
            !!enoAndW9VerificationData?.eno?.enoVerifiedOn
          ) {
            data.enoVerifiedByName = enoAndW9VerificationData.eno.enoVerifiedBy;
            data.enoVerifiedOn = enoAndW9VerificationData.eno.enoVerifiedOn;
            data.enoVerifiedBy = licenseInfo.enoVerifiedBy;
          }
        }
        yield put(
          setComplianceFileUploadError({ updateEntity, isSuccess: true })
        );
      } else {
        isErrorPresent = true;
        yield put(errorHandler(result));
        yield put(closedModelFun(false));
        yield put(
          setComplianceFileUploadError({ updateEntity, isSuccess: false })
        );
      }
    }
    if (!isErrorPresent) {
      const dataSet = {
        data: {
          [entity]: Object.assign({}, data),
          stepId,
          producerId: agentId,
        },
      };
      const responseDetails = yield call(updateProducer, dataSet);
      if (responseDetails.data) {
        const { userRole } = reduxState.auth;
        if (!!responseDetails?.data?.complianceStatus) {
          const { enoAndW9VerificationData, licenseInfo } =
            responseDetails.data;
          data.eno_compliance_status =
            responseDetails.data.complianceStatus.eno;
          if (
            userRole === ADMIN &&
            !!enoAndW9VerificationData?.eno?.enoVerifiedBy &&
            !!enoAndW9VerificationData?.eno?.enoVerifiedOn
          ) {
            data.enoVerifiedByName = enoAndW9VerificationData.eno.enoVerifiedBy;
            data.enoVerifiedOn = enoAndW9VerificationData.eno.enoVerifiedOn;
            data.enoVerifiedBy = licenseInfo.enoVerifiedBy;
          }
        }
        if (!!responseDetails?.data?.complianceStatus && userRole !== ADMIN) {
          yield put(
            updateComplianceStatus(responseDetails.data.complianceStatus)
          );
        }

        yield put(closedModelFun(true));
        yield put(
          storeAgentDetails({
            entity,
            data,
            responseStatus: true,
            commissionPaymentSetting:
              responseDetails?.data?.commission_payment_settings,
          })
        );

        if (!!responseDetails?.data?.complianceStatus) {
          yield put(
            updateAgentCompliance({
              complianceStatus: responseDetails.data.complianceStatus,
            })
          );
        }
        yield displaySuccessMessage(responseDetails.data.message);
      }
    }
  } catch (error) {
    yield call(catchForCompliance(error));
  }
  yield put(updateAgentLoader(false));
}

export function* saveEnoDetailsWatcher() {
  yield takeEvery(agentDetailsActions.SAVE_ENO_DETAILS, saveEnoDetails);
}

export function* deleteIncompleteProfile({ payload }) {
  try {
    yield put(setAgentListLoader(true));
    const { producer_code, producer_id } = payload;
    const responseData = yield axios.post(
      '/api/v1/producer/delete-incomplete-agent',
      {
        producer_id: producer_id,
        producer_code: producer_code,
      }
    );
    if (responseData.data.success) {
      yield displaySuccessMessage(responseData.data.message);
    } else {
      yield displayErrorMessage(responseData.data.message);
      yield put(setAgentListLoader(false));
    }
    yield put(fetchAgentList(INCOMPLETE_SIGNUP));
  } catch (error) {
    yield put(errorHandler(error));
    yield put(setAgentListLoader(false));
  }
}

export function* deleteIncompleteSignupWatcher() {
  yield takeEvery(
    agentDetailsActions.DELETE_INCOMPLETE_SIGNUP,
    deleteIncompleteProfile
  );
}

export function* agentComplianceListUpdate({
  payload: { entity, agentId, action },
}) {
  try {
    yield put(setAgentComplianceListUpdateLoader(true));
    const responseDetails = yield updateProducerCompliance({
      producerId: agentId,
      module: resolveComplianceModule(entity),
      status: resolveCompliancePayload(action),
    });

    if (responseDetails.data) {
      let agentIdData = agentId;
      setProducerAggregateCount(responseDetails.data.producerStatusAggregate);
      let filterCount = responseDetails.data.producerStatusAggregate;
      const reduxState = yield select();
      const {
        errorOmissionList,
        taxAgentList,
        npnAgentList,
        regulatoryActionList,
      } = reduxState.agentDetails;
      if (entity === ENO_LIST) {
        let errorOmissionListData = filter(errorOmissionList, (value) => {
          if (value.producer_id !== agentIdData) {
            return value;
          }
        });
        yield put(
          setAgentComplianceList({
            entity,
            data: errorOmissionListData,
          })
        );
        yield displaySuccessMessage(globalMessages.enoUpdateMsg);
      }
      if (entity === TAX_AGENT_LIST) {
        let taxAgentListData = filter(taxAgentList, (value) => {
          if (value.producer_id !== agentIdData) {
            return value;
          }
        });
        yield put(
          setAgentComplianceList({
            entity,
            data: taxAgentListData,
          })
        );
        yield displaySuccessMessage(globalMessages.taxUpdateMsg);
      }
      if (entity === NPN_AGENT_LIST) {
        let npnAgentListData = filter(npnAgentList, (value) => {
          if (value.producer_id !== agentIdData) {
            return value;
          }
        });
        yield put(
          setAgentComplianceList({
            entity,
            data: npnAgentListData,
          })
        );
        yield displaySuccessMessage(globalMessages.licenseUpdateMsg);
      }
      if (entity === REGULATORY_ACTION_LIST) {
        yield put(
          setAgentComplianceList({
            entity,
            data: filter(
              regulatoryActionList,
              ({ producer_id }) => producer_id !== agentIdData
            ),
          })
        );
        yield put(fetchAgentDetails(agentId));
        yield displaySuccessMessage(globalMessages.regulatoryActionUpdateMsg);
      }
      yield put(updateFilterCount({ count: filterCount }));
    }
  } catch (error) {
    yield put(errorHandler(error));
  }
  yield put(setAgentComplianceListUpdateLoader(false));
}

export function* agentComplianceListUpdateWatcher() {
  yield takeEvery(
    agentDetailsActions.SET_AGENT_COMPLIANCE_LIST_UPDATE,
    agentComplianceListUpdate
  );
}

export function* sentAgentStatusFun({
  payload: {
    content,
    entity,
    producerId,
    actionStatus,
    agentData,
    isReasonUpdate,
  },
}) {
  try {
    yield put(setAgentStatusLoaderFun(true));
    const redux = yield select();
    let existingReason =
      redux.myAccount?.details?.producers?.notes?.content &&
      redux.myAccount.details.producers.notes.content;
    let updateReasonResponse = yield axios.post(
      '/api/v1/producer/update-notes',
      {
        producer_id: producerId,
        notes: {
          content: content,
        },
      }
    );
    if (!isReasonUpdate) {
      const response = yield axios.post(`api/v1/producer/update-status`, {
        producer_id: producerId,
        status: actionStatus,
      });
      if (response.data) {
        const { message, success, status } = response?.data && response.data;
        if (!!success) {
          let updatedContent = updateReasonResponse?.data?.success
            ? content
            : existingReason;
          agentData.status = !!status ? status : agentData.status;
          yield put(
            storeAgentDetails({
              entity,
              data: agentData,
              content: updatedContent,
            })
          );
          yield displaySuccessMessage(message);
        } else {
          const errors = [];
          forEach(response.data.errors, (errorStatus) => {
            errors.push(errorStatus);
          });
          yield put(storeAgentErrorMessages({ status: false, errors }));
          if (!!message) {
            yield displayErrorMessage(message);
          }
        }
      }
    } else {
      if (!!updateReasonResponse?.data && updateReasonResponse.data) {
        const { message, success } = updateReasonResponse.data;
        let updatedContent = success ? content : existingReason;
        yield put(
          updateAgentStatusReason({
            content: updatedContent,
          })
        );
        success
          ? yield displaySuccessMessage(message)
          : yield displayErrorMessage(message);
      }
    }
  } catch (error) {
    yield put(errorHandler(error));
  } finally {
    yield put(setAgentStatusLoaderFun(false));
  }
}

export function* sentAgentStatusWatcher() {
  yield takeEvery(agentDetailsActions.SET_AGENT_STATUS, sentAgentStatusFun);
}

export function* completeAgentProfile({ payload: { agentId, agentCode } }) {
  const reduxState = yield select();
  const {
    router: {
      location: { pathname },
    },
  } = reduxState;
  const pagePathname = pathname.substring(0, pathname.lastIndexOf('/'));
  try {
    let encodedQueryString = null;
    let queryString = null;
    yield put(setAgentStatusLoaderFun(true));
    if (!isEmpty(agentId.toString()) && !isEmpty(agentCode)) {
      yield delay(300);
      yield put(
        setCompleteAgentProfile({
          agentId: agentId,
          agentCode: agentCode,
          redirectMyAccount: true,
        })
      );
      queryString = `id=${agentId.toString()}&code=${agentCode.toString()}`;
      encodedQueryString = encryptUrlParams(queryString);
    } else {
      const responseDetails = yield axios.post(
        '/api/v1/producer/complete-signup',
        {
          producer_id: agentId,
        }
      );

      if (responseDetails.data) {
        yield put(
          setCompleteAgentProfile({
            agentId: responseDetails.data.producer.producer_id,
            agentCode: responseDetails.data.producer.producer_code,
            redirectMyAccount: true,
          })
        );
        const { producer_id, producer_code } = responseDetails.data.producer;
        queryString = `id=${producer_id.toString()}&code=${producer_code.toString()}`;
        encodedQueryString = encryptUrlParams(queryString);
      }
    }
    window.open(`${pagePathname}/${encodedQueryString}`, '_blank');
  } catch (error) {
    const { agentList } = reduxState.agentDetails;
    let incompleteSignupListUpdated = agentList;

    incompleteSignupListUpdated.forEach((data) => {
      if (data.producer_id === agentId) {
        return (data.actionState = true);
      }
    });

    setAgentList({
      data: incompleteSignupListUpdated,
    });
    yield put(errorHandler(error));
  }
  yield put(setAgentStatusLoaderFun(false));
}
export function* completeAgentProfileWatcher() {
  yield takeEvery(
    agentDetailsActions.COMPLETE_AGENT_PROFILE,
    completeAgentProfile
  );
}

export function* fetchENOApproveRejectAction(payload) {
  try {
    yield put(setAgentStatusLoaderFun(true));
    const { entity, agentId, action, details } = payload.payload;
    const { licenseAndEnO, tax } = details.producers;
    const reduxState = yield select();
    const admin_id = reduxState.auth.producer_id;
    const responseDetails = yield updateProducerCompliance({
      producerId: agentId,
      module: resolveComplianceModule(entity),
      status: resolveCompliancePayload(action),
    });

    if (responseDetails.data) {
      let data =
        entity === ENO
          ? licenseAndEnO
          : entity === W9
          ? tax
          : entity === NPN
          ? licenseAndEnO
          : '';
      /**
       *  checking action:
       *  1. if action is approve then status will be approved
       *  2. if action is reject then status will be rejected
       */
      let status;
      responseDetails.data.action?.toUpperCase() === APPROVE &&
        (status = complianceStatus.approved);
      responseDetails.data.action?.toUpperCase() === REJECT &&
        (status = complianceStatus.rejected);
      let verifiedBy = responseDetails.data.verifiedBy; // verified name on real time
      let verifiedOn = responseDetails.data.date; // verified date on real time
      if (entity === LICENSE_AND_ENO) {
        data = {
          ...data,
          eno_compliance_status: status,
          enoVerifiedBy: admin_id,
          enoVerifiedByName: verifiedBy, // E&O verified name on real time
          enoVerifiedOn: verifiedOn, // W9 verified date on real time
        };
      }
      if (entity === TAX_KEY) {
        data = {
          ...data,
          w9_compliance_status: status,
          w9VerifiedBy: admin_id,
          w9VerifiedByName: verifiedBy, // W9 verified name on real time
          w9VerifiedOn: verifiedOn, // W9 verified date on real time
        };
      }
      if (entity === NPN) {
        data = {
          ...data,
          npn_compliance_status: status,
          npnVerifiedBy: admin_id,
          npnVerifiedByName: verifiedBy, // NPN verified name on real time
          npnVerifiedOn: verifiedOn, // NPN verified date on real time
        };
      }
      const { activate } = responseDetails.data;
      let isActivate = !!activate ? activate : false;
      let reduxEntity = entity === NPN ? LICENSE_AND_ENO : entity;
      yield put(storeAgentDetails({ entity: reduxEntity, data, isActivate }));
      yield displaySuccessMessage(
        // If e&o or w9 compliance is approved or reject it will show e&o or w9, approved or rejected message.
        entity === LICENSE_AND_ENO && action === capitalize(APPROVE)
          ? globalMessages.enoApproveMsg
          : entity === LICENSE_AND_ENO && action === capitalize(REJECT)
          ? globalMessages.enoRejectMsg
          : entity === TAX_KEY && action === capitalize(APPROVE)
          ? globalMessages.taxApproveMsg
          : entity === TAX_KEY && action === capitalize(REJECT)
          ? globalMessages.taxtRejectMsg
          : entity === NPN && action === capitalize(APPROVE)
          ? globalMessages.npnApproveMsg
          : entity === NPN && action === capitalize(REJECT)
          ? globalMessages.npnRejectMsg
          : ''
      );
      if (!!activate) {
        yield put(storeSubAgentStatus({ status: ACTIVE_STATUS }));
      }
    }
  } catch (error) {
    yield put(errorHandler(error));
  } finally {
    yield put(setAgentStatusLoaderFun(false));
  }
}
export function* fetchENOApproveRejectActionFunWatcher() {
  yield takeEvery(
    agentDetailsActions.FETCH_ENO_APPROVE_REJECT_ACTION_FUN,
    fetchENOApproveRejectAction
  );
}

export function* fetchStateLOAList() {
  try {
    yield put(licenseLOAStateListLoader(true));
    yield put(licenseFileLoader(false));
    yield put(licenseListLoader(false));
    yield put(invalidFileType(null));
    const stateLOAList = yield axios.get(`/api/v1/producer/states`, {
      params: { withLoa: true },
    });
    if (!!stateLOAList?.data && stateLOAList.status === 200) {
      const { data } = stateLOAList.data;
      yield put(setLOAStateList(data));
    }
  } catch (error) {
    yield put(errorHandler(error));
  }
  yield put(licenseLOAStateListLoader(false));
}

export function* fetchStateLOAListWatcher() {
  yield takeEvery(
    agentDetailsActions.FETCH_LICENSE_LOA_STATE_LIST,
    fetchStateLOAList
  );
}

export function* updateDataOnPromptClose(payload) {
  try {
    const { producerId } = payload;
    yield axios.post(`/api/v1/producer/update-sub-agent-prompt-status`, {
      producer_id: producerId,
    });
  } catch (error) {
    yield put(errorHandler(error));
  }
}

export function* updateDataOnPromptCloseWatcher() {
  yield takeEvery(
    agentDetailsActions.UPDATE_ON_PROMPT_CLOSE,
    updateDataOnPromptClose
  );
}

// pagination in BE
function* fetchAgentListCounters() {
  try {
    yield put(setAgentListLoader(true));
    const requestConfig = yield getImpersonateBFFRequestConfig();
    const agentDetails = yield axios.get(
      `${config.BFF_URL}/api/v1/producer/list/counters`,
      requestConfig
    );
    if (agentDetails.data) {
      setProducerAggregateCount(agentDetails.data.producerStatusAggregate);
      yield put(
        updateFilterCount({
          count: agentDetails.data.producerStatusAggregate,
        })
      );
    }
  } catch (error) {
    yield put(errorHandler(error));
  }
  yield put(setAgentListLoader(false));
}

export function* fetchAgentListCountersWatcher() {
  yield takeEvery(
    agentDetailsActions.FETCH_AGENT_LIST_COUNTERS,
    fetchAgentListCounters
  );
}

function* fetchProducersDataList({ payload }) {
  try {
    const requestConfig = yield getImpersonateBFFRequestConfig();
    let agentDetails = null;
    agentDetails = yield axios.get(
      `${config.BFF_URL}/api/v1/producer/list?status=${payload.status}&limit=${
        payload.limit
      }&page=${payload.page + 1}&query=${encodeURIComponent(payload.query)}`,
      requestConfig
    );
    if (agentDetails.data) {
      if (!!agentDetails.data.producers) {
        yield put(setTotalProducerCount(agentDetails.data.total));
        if (payload.status === NEEDS_APPROVAL) {
          yield put(
            setNeedsApprovalList({
              data: agentDetails.data.producers,
            })
          );
        } else if (!payload.searchList && payload.status === ENO) {
          yield put(
            setAgentComplianceList({
              entity: ENO_LIST,
              data: agentDetails.data.producers,
            })
          );
        } else if (!payload.searchList && payload.status === NPN) {
          yield put(
            setAgentComplianceList({
              entity: NPN_AGENT_LIST,
              data: agentDetails.data.producers,
            })
          );
        } else if (
          !payload.searchList &&
          payload.status === REGULATORY_ACTION
        ) {
          yield put(
            setAgentComplianceList({
              entity: REGULATORY_ACTION_LIST,
              data: agentDetails.data.producers,
            })
          );
        } else if (!payload.searchList && payload.status === W9) {
          yield put(
            setAgentComplianceList({
              entity: TAX_AGENT_LIST,
              data: agentDetails.data.producers,
            })
          );
        } else {
          yield put(
            setAgentList({
              data: agentDetails.data.producers,
            })
          );
        }
      }
    }
  } catch (error) {
    yield put(errorHandler(error));
  }
}

export function* fetchProducersDataListWatcher() {
  yield takeEvery(
    agentDetailsActions.FETCH_PROUDUCER_DATA_LIST,
    fetchProducersDataList
  );
}
// pagination in BE

function* updateHubspotIdsDetail({ payload }) {
  yield put(setHubspotIdsDetailSaved(false));
  try {
    yield put(setHubspotIdsDetailLoader(true));
    let data = isEmpty(payload.companyId)
      ? { contactId: payload.contactId }
      : { companyId: payload.companyId, contactId: payload.contactId };
    yield axios.put(
      `${config.BFF_URL}/api/v1/producer/${payload.producerId}/hubspot-identifiers`,
      data
    );
    yield put(fetchAgentDetails(payload.producerId));
    yield put(setHubspotIdsDetailSaved(true));
    yield displaySuccessMessage('Hubspot IDs updated successfully!');
  } catch (error) {
    yield put(errorHandler(error));
  }
  yield put(setHubspotIdsDetailSaved(false));
  yield put(setHubspotIdsDetailLoader(false));
}

export function* updateHubspotIdsDetailWatcher() {
  yield takeEvery(
    agentDetailsActions.UPDATE_HUBSPOT_IDS_DETAIL,
    updateHubspotIdsDetail
  );
}

// ------ ROOT SAGA -----------------
export default function* rootSaga() {
  yield all([
    fork(fetchAgentListWatcher),
    fork(fetchAgentListCountersWatcher),
    fork(fetchProducersDataListWatcher),
    fork(saveAgentDetailsWatcher),
    fork(saveBankDetailsWatcher),
    fork(saveEnoDetailsWatcher),
    fork(saveLicenseDetailsWatcher),
    fork(saveW9DetailsWatcher),
    fork(agentComplianceListUpdateWatcher),
    fork(sentAgentStatusWatcher),
    fork(completeAgentProfileWatcher),
    fork(fetchENOApproveRejectActionFunWatcher),
    fork(deleteIncompleteSignupWatcher),
    fork(fetchStateLOAListWatcher),
    fork(updateDataOnPromptCloseWatcher),
    fork(updateHubspotIdsDetailWatcher),
  ]);
}
