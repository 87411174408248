import React from 'react';
import { connect } from 'react-redux';
import IntlMessages from 'util/intl-messages';
import './process-footer.scss';
import { SUPPORT_PHONE_NUMBER } from 'constants/constant';

const Footer = (props) => {
  const { footer, privacyPolicy, termsAndConditions } =
    !!props.orgDetails && props.orgDetails;
  return (
    <footer className="process-footer">
      <span className="d-inline-block">
        &copy; {new Date().getFullYear()} {footer}{' '}
        {privacyPolicy && (
          <a target="_blank" rel="noopener noreferrer" href={privacyPolicy}>
            &bull; <IntlMessages id="label.privacy.policy" />
            &nbsp;
          </a>
        )}
        {termsAndConditions && (
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={termsAndConditions}
          >
            &bull; <IntlMessages id="label.terms.and.condition" />
          </a>
        )}
        <a href={`tel:${SUPPORT_PHONE_NUMBER}`}>
          &nbsp; &bull; <IntlMessages id="label.support" />:{' '}
          {SUPPORT_PHONE_NUMBER}
        </a>
      </span>
    </footer>
  );
};

const mapStateToProps = ({ organizationDetails }) => {
  const { orgDetails } = organizationDetails;
  return {
    orgDetails,
  };
};
export default connect(mapStateToProps, null)(Footer);
