// Customizer const
export const TOGGLE_COLLAPSED_NAV = 'toggle_collapse_menu';
export const DRAWER_TYPE = 'drawer_type';
export const FIXED_DRAWER = 'fixed_drawer';
export const COLLAPSED_DRAWER = 'collapsible';
export const MINI_DRAWER = 'mini_drawer';
export const THEME_COLOR = 'theme_color';
export const DARK_THEME = 'dark_theme';
export const WINDOW_WIDTH = 'window-width';
export const SWITCH_LANGUAGE = 'switch-language';
export const CHANGE_DIRECTION = 'change-direction';

export const CHANGE_NAVIGATION_STYLE = 'change-navigation-style';
export const HORIZONTAL_NAVIGATION = 'horizontal_navigation';
export const VERTICAL_NAVIGATION = 'vertical_navigation';

export const HORIZONTAL_MENU_POSITION = 'horizontal_menu_position';
export const ABOVE_THE_HEADER = 'above_the_header';
export const INSIDE_THE_HEADER = 'inside_the_header';
export const BELOW_THE_HEADER = 'below_the_header';
// Contact Module const
export const FETCH_START = 'fetch_start';
export const FETCH_SUCCESS = 'fetch_success';
export const FETCH_ERROR = 'fetch_error';
export const FETCH_ALL_CONTACT = 'fetch_all_contact';
export const FETCH_ALL_CONTACT_SUCCESS = 'fetch_all_contact_success';
export const ADD_FAVOURITE = 'add_favourite';
export const ON_CONTACT_SELECT = 'on_contact_select';
export const ON_ADD_CONTACT = 'on_add_contact';
export const ON_CONTACT_CLOSE = 'on_contact_close';
export const ON_FILTER_OPTION_SELECT = 'on_filter_option_select';
export const ON_SAVE_CONTACT = 'on_save_contact';
export const ON_DELETE_CONTACT = 'on_delete_contact';
export const ON_DELETE_SELECTED_CONTACT = 'on_delete_selected_contact';
export const FILTER_CONTACT = 'filter_contact';
export const GET_ALL_CONTACT = 'get_all_contact';
export const GET_UNSELECTED_ALL_CONTACT = 'get_unselected_all_contact';
export const ON_ALL_CONTACT_SELECT = 'on_all_contact_select';
export const UPDATE_SEARCH_USER = 'update_search_user';
export const ON_TOGGLE_DRAWER = 'on_toggle_drawer';
export const HANDLE_REQUEST_CLOSE = 'handle_request_close';
export const HIDE_CONTACT_LOADER = 'hide_contact_loader';
// Auth const
const userEntity = '[user]';
export const SIGNIN_USER = `${userEntity} SIGNIN_USER`;
export const SIGNIN_AUTH0_HANDLE_REDIRECT = `${userEntity} SIGNIN_AUTH0_HANDLE_REDIRECT`;
export const SIGNIN_AUTH0_FETCH_USER_DATA = `${userEntity} SIGNIN_AUTH0_FETCH_USER_DATA`;

export const FETCH_PERMISSIONS = `${userEntity} FETCH_PERMISSIONS`;

export const SET_DASHBOARD_VIEW = `${userEntity} SET_DASHBOARD_VIEW`;
export const SET_PRODUCER_LOGGEDIN = `${userEntity} SET_PRODUCER_LOGGEDIN`;
export const SET_DONT_UPDATE_ME = `${userEntity} SET_DONT_UPDATE_ME`;
export const SET_WHOLESALER = `${userEntity} SET_WHOLESALER`;
export const SIGNOUT_USER = `${userEntity} SIGNOUT_USER`;
export const SIGNIN_USER_SUCCESS = `${userEntity} SIGNIN_USER_SUCCESS`;
export const SIGNOUT_USER_SUCCESS = `${userEntity} SIGNOUT_USER_SUCCESS`;
export const AUTO_SIGNIN = `${userEntity} AUTO_SIGNIN`;
export const UPDATE_AUTO_LOGIN = `${userEntity} UPDATE_AUTO_LOGIN`;
export const UPDATE_AUTH_NAME = `${userEntity} UPDATE_AUTH_NAME`;
export const UPDATE_AUTH_LAST_NAME = `${userEntity} UPDATE_AUTH_LAST_NAME`;
export const UPDATE_COMPLIANCE_STATUS = `${userEntity} UPDATE_COMPLIANCE_STATUS`;
export const INIT_URL = '[app] INIT_URL';
export const USER_DATA = 'user_data';
export const USER_TOKEN_SET = 'user_token_set';
export const UPDATE_AGREEMENT_DATA = `${userEntity}  UPDATE_AGREEMENT_DATA`;
export const SET_CARRIER_STORE_DETAILS = `${userEntity} SET_CARRIER_STORE_DETAILS`;
export const UPDATE_CARRIER_APPOINTMENT_REQUEST_COUNT = `${userEntity} UPDATE_CARRIER_APPOINTMENT_REQUEST_COUNT`;
export const CARRIER_STORE_THANK_YOU_MODAL_CLOSE = `${userEntity} CARRIER_STORE_THANK_YOU_MODAL_CLOSE`;
export const SET_STATE_INITIALIZED = `${userEntity} SET_STATE_INITIALIZED`;
export const SET_IS_USER_EMAIL_VERIFIED = `${userEntity} SET_IS_USER_EMAIL_VERIFIED`;
// Notifications
export const FETCH_NOTIFICATIONS = `${userEntity} FETCH_NOTIFICATIONS`;
export const SET_UNSEEN_NOTIFICATIONS_COUNT = `${userEntity} SET_UNSEEN_NOTIFICATIONS_COUNT`;

// post sign up
const postSignupEntity = '[post signup]';
export const POST_VERIFICATION_SUCCESS = `${postSignupEntity} POST_VERIFICATION_SUCCESS`;
export const POST_VERIFICATION_FAIL = `${postSignupEntity} POST_VERIFICATION_FAIL`;
export const STEP_LOADER_SHOW = `${postSignupEntity} STEP_LOADER_SHOW`;
export const STEP_LOADER_HIDE = `${postSignupEntity} STEP_LOADER_HIDE`;
export const FILES_UPLOAD = `${postSignupEntity} FILES_UPLOAD`;
export const FILES_UPLOAD_SUCCESS = `${postSignupEntity} FILES_UPLOAD_SUCCESS`;
export const SET_LICENSE_URL = `${postSignupEntity} SET_LICENSE_URL`;
export const FILES_UPLOAD_FAILED = `${postSignupEntity} FILES_UPLOAD_FAILED`;
export const SUBMIT_LOADER_SHOW = `${postSignupEntity} SUBMIT_LOADER_SHOW`;
export const SUBMIT_LOADER_HIDE = `${postSignupEntity} SUBMIT_LOADER_HIDE`;
export const STEP_DATA_FETCH = `${postSignupEntity} STEP_DATA_FETCH`;
export const STEP_DATA_FETCH_SUCCESS = `${postSignupEntity} STEP_DATA_FETCH_SUCCESS`;
export const STEP_DATA_FETCH_FAIL = `${postSignupEntity} STEP_DATA_FETCH_FAIL`;
export const UPDATE_W9_FORM_STATUS = `${postSignupEntity} UPDATE_W9_FORM_STATUS`;
// after each post signup step page submit
export const UPDATE_STEP_DATA = `${postSignupEntity} UPDATE_STEP_DATA`;
export const STEP_SUBMIT = `${postSignupEntity} STEP_SUBMIT`;
export const GET_NPN = `${postSignupEntity} GET_NPN`;
export const INDIVIDUAL_LICENSE_ERROR = `${postSignupEntity} INDIVIDUAL_LICENSE_ERROR`;
export const AGENCY_LICENSE_ERROR = `${postSignupEntity} AGENCY_LICENSE_ERROR`;
export const STEP_SUBMIT_SUCCESS = `${postSignupEntity} STEP_SUBMIT_SUCCESS`;
export const STEP_SUBMIT_FAIL = `${postSignupEntity} STEP_SUBMIT_FAIL`;
export const SET_NIPPR_LOADER = `${postSignupEntity} SET_NIPPR_LOADER`;
export const SET_INDIVIDUAL_NPN_VERIFIED = `${postSignupEntity} SET_INDIVIDUAL_NPN_VERIFIED`;
export const SET_BUSINESS_NPN_VERIFIED = `${postSignupEntity} SET_BUSINESS_NPN_VERIFIED`;
export const UPDATE_LICENSE_DATA = `${postSignupEntity} UPDATE_LICENSE_DATA`;
export const CLEAR_NPN_LICENSES = `${postSignupEntity} CLEAR_NPN_LICENSES`;
export const SET_POST_SIGNUP_FILE_UPLOAD_ERROR = `${postSignupEntity} SET_POST_SIGNUP_FILE_UPLOAD_ERROR`;
export const SET_CURRENT_STEP_INDEX = `${postSignupEntity} SET_CURRENT_STEP_INDEX`;
export const SET_HAS_ACCEPTED_AGREEMENT = `${postSignupEntity} SET_HAS_ACCEPTED_AGREEMENT`;
export const SET_UPDATED_STEP = `${postSignupEntity} SET_UPDATED_STEP`;
export const FETCH_AGENT = 'FETCH_AGENT';
export const FETCH_AGENT_SUCCESS = 'FETCH_AGENT_SUCCESS';
export const FETCH_AGENT_STARTED = 'FETCH_AGENT_STARTED';
export const FETCH_LEAD_STATUS = `${postSignupEntity} FETCH_LEAD_STATUS`;
export const SET_LEAD_STATUS = `${postSignupEntity} SET_LEAD_STATUS`;
export const SET_LEAD_STATUS_LOADER = `${postSignupEntity} SET_LEAD_STATUS_LOADER`;
export const SET_SIGNUP_BY_REFERRAL = `${postSignupEntity} SET_SIGNUP_BY_REFERRAL`;
export const SET_IS_SIGN_UP_BY_REFERRAL = `${postSignupEntity} SET_IS_SIGN_UP_BY_REFERRAL`;
export const SET_CONTINUE_ON_COMPUTER = `${postSignupEntity} SET_CONTINUE_ON_COMPUTER`;
export const SET_DEVICE_TRANSITION_EMAIL = `${postSignupEntity} SET_DEVICE_TRANSITION_EMAIL`; // App Loader
export const SET_DEVICE_TRANSITION_EMAIL_LOADER = `${postSignupEntity} SET_DEVICE_TRANSITION_EMAIL_LOADER`; // App Loader
export const SET_CANCLE_SWITCH_VALUE = `${postSignupEntity} SET_CANCLE_SWITCH_VALUE`;
export const SET_DEVICE_TRANSITION_VIEWED = `${postSignupEntity} SET_DEVICE_TRANSITION_VIEWED`; // App Loader
export const SWITCH_TO_COMPUTER = `${postSignupEntity} SWITCH_TO_COMPUTER`;
export const FETCH_LICENSE = `${postSignupEntity} FETCH_LICENSE`;
export const SET_REFETCH_LICENSE_LOADER = `${postSignupEntity} SET_REFETCH_LICENSE_LOADER`;
export const SET_INDIVIDUAL_LICENSE_LIST = `${postSignupEntity} SET_INDIVIDUAL_LICENSE_LIST`;
export const SET_BUSINESS_LICENSE_LIST = `${postSignupEntity} SET_BUSINESS_LICENSE_LIST`;
export const SET_LICENSE_LIST = `${postSignupEntity} SET_LICENSE_LIST`;
export const SET_DROPBOX_LOADER = `${postSignupEntity} SET_DROPBOX_LOADER`;

const uiEntity = '[ui]';
export const ON_SHOW_LOADER = `${uiEntity} ON_SHOW_LOADER`;
export const ON_HIDE_LOADER = `${uiEntity} ON_HIDE_LOADER`;
export const SHOW_MESSAGE = `${uiEntity} SHOW_MESSAGE`;
export const HIDE_MESSAGE = `${uiEntity} HIDE_MESSAGE`;
// Chat Module const
export const FETCH_ALL_CHAT_USER = 'fetch_all_chat_user';
export const FETCH_ALL_CHAT_USER_CONVERSATION =
  'fetch_all_chat_user_conversation';
export const FETCH_ALL_CHAT_USER_SUCCESS = 'fetch_all_chat_user_success';
export const FETCH_ALL_CHAT_USER_CONVERSATION_SUCCESS =
  'fetch_all_chat_user_conversation_success';
export const FILTER_USERS = 'filter_users';
export const ON_SELECT_USER = 'on_select_user';
export const USER_INFO_STATE = 'user_info_state';
export const SUBMIT_COMMENT = 'submit_comment';
export const UPDATE_MESSAGE_VALUE = 'update_message_value';
export const UPDATE_SEARCH_CHAT_USER = 'update_search_chat_user';
// Mail Module const
export const ADD_LABEL = 'ADD_LABEL';
export const GET_ALL_MAIL = 'get_all_mail';
export const FETCH_ALL_MAIL = 'fetch_all_mail';
export const FETCH_ALL_MAIL_SUCCESS = 'fetch_all_mail_success';
export const GET_IMPORTANT_MAIL = 'get_important_mail';
export const GET_NAV_FILTERS = 'get_nav_filters';
export const GET_NAV_LABELS = 'get_nav_labels';
export const GET_NAV_FOLDER = 'get_nav_folder';
export const GET_READ_MAIL = 'get_read_mail';
export const GET_STARRED_MAIL = 'get_starred_mail';
export const GET_UNIMPORTANT_MAIL = 'get_unimportant_mail';
export const GET_UNREAD_MAIL = 'get_unread_mail';
export const GET_UNSELECTED_ALL_MAIL = 'get_unselected_all_mail';
export const GET_UNSTARRED_MAIL = 'get_unstarred_mail';
export const ON_ALL_MAIL_SELECT = 'on_all_mail_select';
export const ON_FOLDER_MENU_ITEM_SELECT = 'on_folder_menu_item_select';
export const ON_FOLDER_SELECT = 'on_folder_select';
export const ON_IMPORTANT_SELECT = 'on_important_select';
export const ON_LABEL_MENU_ITEM_SELECT = 'on_label_menu_item_select';
export const GET_MARK_AS_START = 'get_mark_as_start';
export const ON_LABEL_SELECT = 'on_label_select';
export const ON_MAIL_CHECKED = 'on_mail_checked';
export const ON_MAIL_SELECT = 'on_mail_select';
export const ON_MAIL_SEND = 'on_mail_send';
export const ON_OPTION_MENU_ITEM_SELECT = 'on_option_menu_item_select';
export const ON_OPTION_MENU_SELECT = 'on_option_menu_select';
export const ON_START_SELECT = 'on_start_select';
export const SEARCH_MAIL = 'search_mail';
export const ON_DELETE_MAIL = 'on_delete_mail';
export const SET_CURRENT_MAIL_NULL = 'set_current_mail_null';
export const ON_COMPOSE_MAIL = 'on_compose_mail';
export const ON_SORTEND = 'on_sortend';
export const FETCH_ALL_TODO = 'fetch_all_todo';
export const FETCH_ALL_TODO_SUCCESS = 'fetch_all_todo-success';
export const FETCH_ALL_TODO_CONVERSATION = 'fetch_all_todo_conversation';
export const FETCH_ALL_TODO_CONVERSATION_SUCCESS =
  'fetch_all_todo_conversation_success';
export const SELECT_ALL_TODO = 'select_all_todo';
export const GET_ALL_TODO = 'get_all_todo';
export const GET_UNSELECTED_ALL_TODO = 'get_unselected_all_todo';
export const GET_STARRED_TODO = 'get_starred_todo';
export const GET_UNSTARRED_TODO = 'get_unstarred_todo';
export const GET_IMPORTANT_TODO = 'get_important_todo';
export const GET_UNIMPORTANT_TODO = 'get_unimportant_todo';
export const ON_LABEL_UPDATE = 'on_label_update';
export const ON_TODO_UPDATE = 'on_todo_update';
export const ON_DELETE_TODO = 'on_delete_todo';
export const SEARCH_TODO = 'search_todo';
export const SHOW_TODOS = 'show_todos';
export const GET_TODO_CONVERSATION = 'get_todo_conversation';
export const ON_TODO_CHECKED = 'on_todo_checked';
export const ON_TODO_ADD = 'on_todo_add';
export const ON_TODO_SELECT = 'on_todo_select';
export const SET_CURRENT_TODO_NULL = 'set_current_todo_null';
export const REMOVE_LABEL = 'remove_label';
export const UPDATE_SEARCH = 'update_search';

// SignUp Process page
const preSignupEntity = '[pre signup]';
export const PRE_SIGNUP_USER = `${preSignupEntity} PRE_SIGNUP_USER`;

export const PRE_SIGNUP_USER_SUCCESS = `${preSignupEntity} PRE_SIGNUP_USER_SUCCESS`;
export const PRE_SIGNUP_USER_EXIST = `${preSignupEntity} PRE_SIGNUP_USER_EXIST`;
export const PRE_SIGNUP_USER_FAIL = `${preSignupEntity} PRE_SIGNUP_USER_FAIL`;

export const PRE_SIGNUP_ON_SHOW_LOADER = `${preSignupEntity} PRE_SIGNUP_ON_SHOW_LOADER`;
export const PRE_SIGNUP_ON_HIDE_LOADER = `${preSignupEntity} PRE_SIGNUP_ON_HIDE_LOADER`;

export const PRE_SIGNUP_SET_RECORDS = `${preSignupEntity} PRE_SIGNUP_SET_RECORDS`;

// DASHBOARD ACTIONS

// Update Password
const passwordEntity = '[Password - Update]';
export const FORGOT_PASSWORD = `${passwordEntity} FORGOT_PASSWORD`; // dispatched form forgot password link on signin page.
export const UPDATE_PASSWORD_LOADER = `${passwordEntity} UPDATE_PASSWORD_LOADER`;
export const UPDATE_TOKEN_LOADER = `${passwordEntity} UPDATE_TOKEN_LOADER`;
// on email link visit
export const SET_UPDATE_PASSWORD_TOKEN = `${passwordEntity} SET_UPDATE_PASSWORD_TOKEN`;
export const CHANGE_PASSWORD = `${passwordEntity} CHANGE_PASSWORD`;
export const SET_IS_TOKEN_VALID = `${passwordEntity} SET_IS_TOKEN_VALID`;
export const VALIDATE_EMAIL_TOKEN = `${passwordEntity} VALIDATE_EMAIL_TOKEN`;
export const UPDATE_PASSWORD_EMAIL_SENT = `${passwordEntity} UPDATE_PASSWORD_EMAIL_SENT`;
export const UPDATE_PASSWORD_COMPLETED = `${passwordEntity} UPDATE_PASSWORD_COMPLETED`;

// Quote Connector
const quoteConnector = '[Quote Connector]';
export const FETCH_QUOTE_CONNECTOR_SETTINGS = `${quoteConnector} FETCH_QUOTE_CONNECTOR_SETTINGS`;
export const FETCH_QUOTE_CONNECTOR_SETTINGS_RESULT = `${quoteConnector} FETCH_QUOTE_CONNECTOR_SETTINGS_RESULT`;
export const SET_IS_NEW_QUOTE_CONNECTOR_ENABLED = `${quoteConnector} SET_IS_NEW_QUOTE_CONNECTOR_ENABLED`;

// Producer - Dashboard (Graphs ONLY)
const agentDashboardEntity = '[Producer - Dashboard]';
export const FETCH_POLICY_PREMIUM_DETAILS = `${agentDashboardEntity} FETCH_POLICY_PREMIUM_DETAILS`;
export const STORE_POLICY_PREMIUM_DETAILS = `${agentDashboardEntity} STORE_POLICY_PREMIUM_DETAILS`;
export const POLICY_PREMIUM_DETAILS_LOADER = `${agentDashboardEntity} POLICY_PREMIUM_DETAILS_LOADER`;

export const FETCH_UPCOMING_RENEWALS = `${agentDashboardEntity} FETCH_UPCOMING_RENEWALS`;
export const SET_CARRIER_PORTAL_LOADER = `${agentDashboardEntity} SET_CARRIER_PORTAL_LOADER`;
export const FETCH_CARRIER_PORTALS_DETAILS = `${agentDashboardEntity} FETCH_CARRIER_PORTALS_DETAILS`;
export const SET_CARRIER_PORTALS_DETAILS = `${agentDashboardEntity} SET_CARRIER_PORTALS_DETAILS`;
export const SET_CARRIER_URL_LOADER = `${agentDashboardEntity} SET_CARRIER_URL_LOADER`;
export const SET_CARRIER_URL = `${agentDashboardEntity} SET_CARRIER_URL`;
export const SET_CURRECT_CARRIER_URL = `${agentDashboardEntity} SET_CURRECT_CARRIER_URL`;
export const SET_CARRIER_TYPE = `${agentDashboardEntity} SET_CARRIER_TYPE`;
export const GET_CARRIER_LINK = `${agentDashboardEntity} GET_CARRIER_LINK`;
export const STORE_UPCOMING_RENEWALS = `${agentDashboardEntity} STORE_UPCOMING_RENEWALS`;
export const UPCOMING_RENEWALS_LOADER = `${agentDashboardEntity} UPCOMING_RENEWALS_LOADER`;
export const RENEWAL_NOTIFICATIONS_STATUS = `${agentDashboardEntity} RENEWAL_NOTIFICATIONS_STATUS`;
export const RENEWAL_NOTIFICATIONS_FLAG_STATUS_CHANGE = `${agentDashboardEntity} RENEWAL_NOTIFICATIONS_FLAG_STATUS_CHANGE`;
export const SET_CARRIER_PORTAL_ERROR_DIALOG = `${agentDashboardEntity} SET_CARRIER_PORTAL_ERROR_DIALOG`;
export const SET_IS_SELECTING_PRODUCT = `${agentDashboardEntity} SET_IS_SELECTING_PRODUCT`;
export const SET_SELECTED_PRODUCT = `${agentDashboardEntity} SET_SELECTED_LOB`;
export const SET_CONSENT_TEXT_DATA = `${agentDashboardEntity} SET_CONSENT_TEXT_DATA`;
export const SET_AUTH_INFO = `${agentDashboardEntity} SET_AUTH_INFO`;
export const SET_PRODUCTS = `${agentDashboardEntity} SET_PRODUCTS`;
export const MARK_APPOINTED_CARRIERS_AS_VIEWED = `${agentDashboardEntity} MARK_APPOINTED_CARRIERS_AS_VIEWED`;
export const SET_CROSS_SELL_DETAILS = `${agentDashboardEntity} SET_CROSS_SELL_DETAILS`;
export const SET_CROSS_SELL_VIEWED = `${agentDashboardEntity} SET_CROSS_SELL_VIEWED`;
export const GET_PROGRESS_BAR_FLAG = `${agentDashboardEntity} GET_PROGRESS_BAR_FLAG`;
export const SET_PROGRESS_BAR_FLAG = `${agentDashboardEntity} SET_PROGRESS_BAR_FLAG`;
export const SET_PROGRESS_BAR_Loader = `${agentDashboardEntity} SET_PROGRESS_BAR_Loader`;
export const UPDATE_WHOLESALER_CARRIER_DATA = `${agentDashboardEntity} UPDATE_WHOLESALER_CARRIER_DATA`;
export const SET_CLICK_ON_CROSS_SALE = `${agentDashboardEntity} SET_CLICK_ON_CROSS_SALE`;
export const SET_CARRIER_STORE_APPLICATION_MODAL = `${agentDashboardEntity} SET_CARRIER_STORE_APPLICATION_MODAL`;
export const SET_CARRIER_FLOW_VIEWED = `${agentDashboardEntity} SET_CARRIER_FLOW_VIEWED`;
export const SHOW_FAST_START_BANNER = `${agentDashboardEntity} SHOW_FAST_START_BANNER`;
export const SET_CARRIER_STORE_MODAL = `${agentDashboardEntity} SET_CARRIER_STORE_MODAL`;
export const SET_W9IINFO_MODAL = `${agentDashboardEntity} SET_W9IINFO_MODAL`;
export const SET_RESTRICTION_MODAL = `${agentDashboardEntity} SET_RESTRICTION_MODAL`;
export const SET_CONSENT_MODAL = `${agentDashboardEntity} SET_CONSENT_MODAL`;
export const SET_BANK_INFO_MODAL = `${agentDashboardEntity} SET_BANK_INFO_MODAL`;
export const SET_FAST_START_MODAL = `${agentDashboardEntity} SET_FAST_START_MODAL`;
export const SET_FAST_START_MODAL_VIEWED = `${agentDashboardEntity} SET_FAST_START_MODAL_VIEWED`;
export const SHOW_FAST_START_MODAL = `${agentDashboardEntity} SHOW_FAST_START_MODAL`;
export const SET_FAST_START_POLICY_LIST_LOADER = `${agentDashboardEntity} SET_FAST_START_POLICY_LIST_LOADER`;
export const SET_FAST_START_POLICY_LIST = `${agentDashboardEntity} SET_FAST_START_POLICY_LIST`;
export const FETCH_FAST_START_POLICIES = `${agentDashboardEntity} FETCH_FAST_START_POLICIES`;
export const TOGGLE_CARRIER_DROPDOWN = `${agentDashboardEntity} TOGGLE_CARRIER_DROPDOWN`;
export const ADD_FAST_START_POLICIES = `${agentDashboardEntity} ADD_FAST_START_POLICIES`;
export const ADD_FAST_START_POLICIES_LOADER = `${agentDashboardEntity} ADD_FAST_START_POLICIES_LOADER`;
export const ADDED_FAST_START_DETAILS = `${agentDashboardEntity} ADDED_FAST_START_DETAILS`;
export const SET_IS_FAST_START_ADDED_FLAG = `${agentDashboardEntity} SET_IS_FAST_START_ADDED_FLAG`;
export const SET_IS_FAST_START_POLICY_ADDED = `${agentDashboardEntity} SET_IS_FAST_START_POLICY_ADDED`;

// Producer - My Account Page
const myAccountEntity = '[Producer - My Account]';
export const FETCH_AGENT_DETAILS = `${myAccountEntity} FETCH_AGENT_DETAILS`;
export const CHECK_STEP_UP_NECESSITY = `${myAccountEntity} CHECK_STEP_UP_NECESSITY`;
export const SHOW_REAUTHENITACATE_MODAL = `${myAccountEntity} SHOW_REAUTHENITACATE_MODAL`;
export const SET_SUB_AGENT_PROMPT_VALUE = `${myAccountEntity}SET_SUB_AGENT_PROMPT_VALUE`;
export const SET_AGENT_DETAILS = `${myAccountEntity} SET_AGENT_DETAILS`;
export const AGENT_DETAILS_LOADER = `${myAccountEntity} AGENT_DETAILS_LOADER`;
export const SET_AGENT_YTD_STATS = `${myAccountEntity} SET_AGENT_YTD_STATS`;
export const UPDATE_LICENSE_LIST = `${myAccountEntity} UPDATE_LICENSE_LIST`;
export const FETCH_AGENT_DOCUMENTS_DETAILS = `${myAccountEntity} FETCH_AGENT_DOCUMENTS_DETAILS`;
export const STORE_DOCUMENT_DETAILS = `${myAccountEntity} STORE_DOCUMENT_DETAILS`;
export const RENAME_DOCUMENT_DETAILS = `${myAccountEntity} RENAME_DOCUMENT_DETAILS`;
export const DELETE_DOCUMENT_DETAILS = `${myAccountEntity} DELETE_DOCUMENT_DETAILS`;
export const STORED_DOCUMENT_LOADER = `${myAccountEntity} STORED_DOCUMENT_LOADER`;
export const DOCUMENT_RENAME = `${myAccountEntity} DOCUMENT_RENAME`;
export const DOCUMENT_DELETE = `${myAccountEntity} DOCUMENT_DELETE`;
export const AGENT_DOCUMENT_FILES_UPLOAD = `${myAccountEntity} AGENT_DOCUMENT_FILES_UPLOAD`;
export const AGENT_DOCUMENT_FILES_UPLOAD_DONE = `${myAccountEntity} AGENT_DOCUMENT_FILES_UPLOAD_DONE`;
export const AGENT_DOCUMENT_FILES_UPLOADING = `${myAccountEntity} AGENT_DOCUMENT_FILES_UPLOADING`;
export const AGENT_DOCUMENT_FILES_TREE_UPDATE = `${myAccountEntity} AGENT_DOCUMENT_FILES_TREE_UPDATE`;
export const DOCUMENT_FOLDER_CREATE = `${myAccountEntity} DOCUMENT_FOLDER_CREATE`;
export const STORE_AGREEMENT_DATA = `${myAccountEntity} STORE_AGREEMENT_DATA`;
export const FETCH_LICENSE_ON_REFRESH = `${myAccountEntity} FETCH_LICENSE_ON_REFRESH`;
export const UPDATE_LICENSE_ON_REFRESH = `${myAccountEntity} UPDATE_LICENSE_ON_REFRESH`;
export const UPDATE_LICENSE_ON_REFRESH_LOADER = `${myAccountEntity} UPDATE_LICENSE_ON_REFRESH_LOADER`;
export const AGENT_DOCUMENT_FILE_UPLOAD_ERROR = `${myAccountEntity} AGENT_DOCUMENT_FILE_UPLOAD_ERROR`;
export const CLEAR_DATA = `${myAccountEntity} CLEAR_DATA`;
export const UPDATE_SHOW_W9_DOWNLOAD_BLOCKED_MODAL = `${myAccountEntity} UPDATE_SHOW_W9_DOWNLOAD_BLOCKED_MODAL`;
// ENO and W9 file upload
export const ENO_AND_W9_FILES_UPLOAD = `${myAccountEntity} ENO_AND_W9_FILES_UPLOAD`;
export const CLEAR_FOLDER_LIST = `${myAccountEntity} CLEAR_FOLDER_LIST`; // on agent details page revisit clear the directory list from dropdown
export const CHECK_BANKACTDETAILS_DUPLICATION = `${myAccountEntity} CHECK_BANKACTDETAILS_DUPLICATION`;
export const CLOSED_MODEL = `${myAccountEntity} CLOSED_MODEL`;
export const EMAIL_DUPLICATE = `${myAccountEntity} EMAIL_DUPLICATE`;
export const RESPONSE_MODEL = `${myAccountEntity} RESPONSE_MODEL`;
export const SET_VALIDATE_NIPR_RESPONSE = `${myAccountEntity} SET_VALIDATE_NIPR_RESPONSE`;
export const CLEAR_REFRESH_MESSAGE = `${myAccountEntity} CLEAR_REFRESH_MESSAGE`;
// Producer - My Commission
const myCommission = '[Producer - My Commission]';
export const FETCH_AGENT_REPORT_LIST = `${myCommission} FETCH_AGENT_REPORT_LIST`;
export const SET_AGENT_REPORT_LIST = `${myCommission} SET_AGENT_REPORT_LIST`;
export const AGENT_REPORT_LIST_LOADER = `${myCommission} AGENT_REPORT_LIST_LOADER`;
export const GET_COMMISSION_RECOMMENDATION_BANNER = `${myCommission} GET_COMMISSION_RECOMMENDATION_BANNER`;
export const SET_COMMISSION_RECOMMENDATION_BANNER = `${myCommission} SET_COMMISSION_RECOMMENDATION_BANNER`;

// Producer - My Policies
export const FETCH_AGENT_POLICIES = `${myCommission} FETCH_AGENT_POLICIES`;
export const SET_AGENT_POLICIES = `${myCommission} SET_AGENT_POLICIES`;
export const AGENT_POLICY_LOADER = `${myCommission} AGENT_POLICY_LOADER`;

// Admin - Agent Directory
const agentDirectoryEntity = '[Admin - Agent Directory]';
export const AGENT_LIST_LOADER = `${agentDirectoryEntity} AGENT_DETAILS_LOADER`;
export const FETCH_AGENT_LIST = `${agentDirectoryEntity} FETCH_AGENT_LIST`;
export const SET_AGENT_LIST = `${agentDirectoryEntity} SET_AGENT_LIST`;
export const AGENT_UPDATE_LOADER = `${agentDirectoryEntity} AGENT_UPDATE_LOADER`;
export const DASHBOARD_AGENT_INFO_UPDATE = `${agentDirectoryEntity} DASHBOARD_AGENT_INFO_UPDATE`;
export const SAVE_AGENT_DETAILS = `${agentDirectoryEntity} SAVE_AGENT_DETAILS`;
export const SAVE_ENO_DETAILS = `${agentDirectoryEntity} SAVE_ENO_DETAILS`;
export const SAVE_LICENSE_DETAILS = `${agentDirectoryEntity} SAVE_LICENSE_DETAILS`;
export const SAVE_BANK_DETAILS = `${agentDirectoryEntity} SAVE_BANK_DETAILS`;
export const SAVE_W9_DETAILS = `${agentDirectoryEntity} SAVE_W9_DETAILS`;
export const STORE_AGENT_DETAILS = `${agentDirectoryEntity} STORE_AGENT_DETAILS`;
export const STORE_COMPIANCE_LICENSE = `${agentDirectoryEntity} STORE_COMPIANCE_LICENSE`;
export const VALIDATE_NPN_BY_TYPE = `${agentDirectoryEntity} VALIDATE_NPN_BY_TYPE`;
export const SET_HUBSPOT_IDS_DETAIL_LOADER = `${agentDirectoryEntity} SET_HUBSPOT_IDS_DETAIL_LOADER`;
export const UPDATE_HUBSPOT_IDS_DETAIL = ` ${agentDirectoryEntity} UPDATE_HUBSPOT_IDS_DETAIL`;
export const SET_HUBSPOT_IDS_DETAIL_SAVED = `${agentDirectoryEntity} SET_HUBSPOT_IDS_DETAIL_SAVED`;

// PAGINATION IN BE

export const FETCH_AGENT_LIST_COUNTERS = `${agentDirectoryEntity} FETCH_AGENT_LIST_COUNTERS`;
export const FETCH_PROUDUCER_DATA_LIST = `${agentDirectoryEntity} FETCH_PROUDUCER_DATA_LIST`;
export const SET_TOTAL_PRODUCER_COUNT = `${agentDirectoryEntity} SET_TOTAL_PRODUCER_COUNT`;

// PAGINATION IN BE

export const DELETE_INCOMPLETE_SIGNUP = `${agentDirectoryEntity} DELETE_INCOMPLETE_SIGNUP`;

export const SET_AGENT_COMPLIANCE_LIST = `${agentDirectoryEntity} SET_AGENT_COMPLIANCE_LIST`;

export const SET_AGENT_COMPLIANCE_LIST_UPDATE = `${agentDirectoryEntity} SET_AGENT_COMPLIANCE_LIST_UPDATE`;
export const AGENT_COMPLIANCE_LIST_UPDATE_LOADER = `${agentDirectoryEntity} AGENT_COMPLIANCE_LIST_UPDATE_LOADER`;
export const SET_AGENT_STATUS = `${agentDirectoryEntity} SET_AGENT_STATUS`;
export const SET_AGENT_STATUS_LOADER = `${agentDirectoryEntity} SET_AGENT_STATUS_LOADER`;
export const COMPLETE_AGENT_PROFILE = `${agentDirectoryEntity} COMPLETE_AGENT_PROFILE`;
export const SET_COMPLETE_AGENT_PROFILE = `${agentDirectoryEntity} SET_COMPLETE_AGENT_PROFILE`;
export const SET_AGENT_ERROR_MESSAGES = `${agentDirectoryEntity} SET_AGENT_ERROR_MESSAGES`;
export const SET_NEEDS_APPROVAL_LIST = `${agentDirectoryEntity} SET_NEEDS_APPROVAL_LIST`;
// Agent directory ENO and W9 Approve and Reject Action
export const FETCH_ENO_APPROVE_REJECT_ACTION_FUN = `${agentDirectoryEntity} FETCH_ENO_APPROVE_REJECT_ACTION_FUN`;
// after settings modal save.
export const SAVE_SETTINGS = `${agentDirectoryEntity} SAVE_SETTINGS`;
export const UPDATE_STORED_SETTINGS = `${agentDirectoryEntity} UPDATE_STORED_SETTINGS`;
export const SET_COMPLIANCE_FILE_UPLOAD_ERROR = `${agentDirectoryEntity} SET_COMPLIANCE_FILE_UPLOAD_ERROR`;
export const SET_OPEN_BANK_INFO = `${agentDirectoryEntity} SET_OPEN_BANK_INFO`;
export const SET_OPEN_CREDENTIAL_TAB = `${agentDirectoryEntity} SET_OPEN_CREDENTIAL_TAB`;

// after License list modal save.
const licenseList = '[License List]';
export const SAVE_LICENSE_LIST = `${licenseList} SAVE_LICENSE_LIST`; // for server to save the new agent details.
export const UPDATE_STORED_LICENSE_LIST = `${licenseList} UPDATE_STORED_LICENSE_LIST`; // used to store new details in state.
export const LICENSE_LIST_LOADER = `${licenseList} LICENSE_LIST_LOADER`;
export const SAVE_UPDATE_LICENSE_LIST = `${licenseList} SAVE_UPDATE_LICENSE_LIST`;
export const LICENSE_FILE_LOADER = `${licenseList} LICENSE_FILE_LOADER`;
// License List : modal open
export const LICENSE_LOA_STATE_LIST_LOADER = `${licenseList} LICENSE_LOA_STATE_LIST_LOADER`;
export const FETCH_LICENSE_LOA_STATE_LIST = `${licenseList} FETCH_LICENSE_LOA_STATE_LIST`;
export const SET_LICENSE_LOA_STATE_LIST = `${licenseList} SET_LICENSE_LOA_STATE_LIST`;

// Admin - Commission Statement
const commissionEntity = '[commission]';
export const FETCH_COMMISSION_STATEMENTS = `${commissionEntity} FETCH_COMMISSION_STATEMENTS`;
export const SET_COMMISSION_DETAILS = `${commissionEntity} SET_COMMISSION_DETAILS`;
export const SET_COMMISSION_LOADER = `${commissionEntity} SET_COMMISSION_LOADER`;
export const COMMISSION_FILES_UPLOAD = `${commissionEntity} COMMISSION_FILES_UPLOAD`;
export const COMMISSION_FILES_UPLOAD_SUCCESS_FLAG = `${commissionEntity} COMMISSION_FILES_UPLOAD_SUCCESS_FLAG`;
export const COMMISSION_FILES_UPLOADING = `${commissionEntity} COMMISSION_FILES_UPLOADING`;
export const COMMISSION_FILES_UPLOAD_SUCCESS = `${commissionEntity} COMMISSION_FILES_UPLOAD_SUCCESS`;
export const COMMISSION_FILES_UPLOAD_FAILED = `${commissionEntity} COMMISSION_FILES_UPLOAD_FAILED`;
export const CARRIER_COMMISSION_UPDATE = `${commissionEntity} CARRIER_COMMISSION_UPDATE`;
export const UPDATE_LICESE_DETAILS_MODAL = `${commissionEntity} UPDATE_LICESE_DETAILS_MODAL`;
export const SENT_LICESE_DETAILS_MODAL_DATA = `${commissionEntity} SENT_LICESE_DETAILS_MODAL_DATA`;

// Admin - Commission View Details (Monthly Stmt.)
const commissionStatement = '[commission stmt.]';
export const FETCH_STATEMENT_DATA = `${commissionStatement} FETCH_STATEMENT_DATA`;
export const ADD_COMMISSION_MONTH = `${commissionStatement} ADD_COMMISSION_MONTH`; // for '+' sign
export const SET_STATEMENT_DATA = `${commissionStatement} SET_STATEMENT_DATA`;
export const UPDATE_MONTH_DETAILS = `${commissionStatement} UPDATE_MONTH_DETAILS`; // on selection of particular carrier in row
export const DELETE_CARRIER_COMMISSION_RECORD = `${commissionStatement} DELETE_CARRIER_COMMISSION_RECORD`;
export const STORE_DELETED_COMMISSION_RECORD = `${commissionStatement} STORE_DELETED_COMMISSION_RECORD`;
export const FETCH_CARRIER_DOCUMENTS = `${commissionStatement} FETCH_CARRIER_DOCUMENTS`; // on 'CONTINUE' with pending carrier list modal
export const REVIEW_DOCUMENT_LOADER = `${commissionStatement} REVIEW_DOCUMENT_LOADER`;
export const SET_REIVEW_DOCUMENTS = `${commissionStatement} SET_REIVEW_DOCUMENTS`;
export const FINAL_PUBLISH = `${commissionStatement} FINAL_PUBLISH`;
export const PUBLISH_LOADER = `${commissionStatement} PUBLISH_LOADER`;
export const MONTH_ALREADY_PROCEED = `${commissionStatement} MONTH_ALREADY_PROCEED`;
export const ANOMALY_CHECK = `${commissionStatement} ANOMALY_CHECK`;
export const ANOMALY_CHECK_LOADER = `${commissionStatement} ANOMALY_CHECK_LOADER`;
export const SET_ANOMALIES_DETECTED = `${commissionStatement}SET_ANOMALIES_DETECTED`;
export const SET_ANOMALIES_DATA = `${commissionStatement}SET_ANOMALIES_DATA`;

// Get Carrier List Details
const carrierList = '[carrier list]';
export const FETCH_CARRIER_LIST = `${carrierList} FETCH_CARRIER_LIST`;
export const SET_CARRIER_LIST = `${carrierList} SET_CARRIER_LIST`;
export const CARRIER_LIST_LOADER = `${carrierList} CARRIER_LIST_LOADER`;

// Get Carrier List for appointments
const carrierAppointmentList = '[carrier appointments list]';
export const FETCH_CARRIER_APPOINTMENT_LIST = `${carrierAppointmentList} FETCH_CARRIER_APPOINTMENT_LIST`;
export const SET_CARRIER_APPOINTMENT_LIST = `${carrierAppointmentList} SET_CARRIER_APPOINTMENT_LIST`;
export const CARRIER_APPOINTMENT_LIST_LOADER = `${carrierAppointmentList} CARRIER_APPOINTMENT_LIST_LOADER`;
export const CARRIER_APPOINTMENT_FILE_LOADER = `${carrierAppointmentList} CARRIER_APPOINTMENT_FILE_LOADER`;

// Get Carrier appointment setting for producer
const carrierAppointmentSetting = '[carrier appointment setting]';
export const FETCH_CARRIER_ALREADY_FEATURED = `${carrierAppointmentSetting} FETCH_CARRIER_ALREADY_FEATURED`;
export const SET_CARRIER_ALREADY_FEATURED_RESPONSE = `${carrierAppointmentSetting} SET_CARRIER_ALREADY_FEATURED_RESPONSE`;
export const SET_CARRIER_APPOINTMENT_SETTING_LOADER = `${carrierAppointmentSetting} SET_CARRIER_APPOINTMENT_SETTING_LOADER`;

// Get Carrier appointment request for producer
const carrierAppointmentRequest = '[carrier appointment request]';
export const SET_CARRIER_APPOINTMENT_REQUEST_SUCCESS = `${carrierAppointmentRequest} SET_CARRIER_APPOINTMENT_REQUEST_SUCCESS`;
export const SET_CARRIER_APPOINTMENT_REQUEST_FAILED = `${carrierAppointmentRequest} SET_CARRIER_APPOINTMENT_REQUEST_FAILED`;

// Sub agent modal updates
const subAgent = '[Sub Agent]';

export const STORE_NEW_SUBAGENT = `${subAgent} STORE_NEW_SUBAGENT`; // used to store new details in state.
export const SUBAGENT_ADDED = `${subAgent} SUBAGENT_ADDED`;
export const SUBAGENT_EMAIL_DUPLICATION = `${subAgent} SUBAGENT_EMAIL_DUPLICATION`;
export const INVALID_FILE_TYPE = `${subAgent} INVALID_FILE_TYPE`;
export const ADD_NEW_TEAM_MEMBERS = `${subAgent} ADD_NEW_TEAM_MEMBERS`;
export const TEAM_MEMBER_LOADER = `${subAgent} TEAM_MEMBER_LOADER`;
export const TEAM_MEMBER_DUPLICATE_EMAIL = `${subAgent} TEAM_MEMBER_DUPLICATE_EMAIL`;
export const DUPLICATE_PHONE_NUMBER = `${subAgent} DUPLICATE_PHONE_NUMBER`; // Error Hnadling
const errorEntity = '[Error]';
export const ERROR_DETAILS = `${errorEntity} ERROR_DETAILS`;
// Document Download
export const DOCUMENT_DOWNLAOD_LOADER = `${subAgent} DOCUMENT_DOWNLAOD_LOADER`;
export const GET_DOCUMENT_PATH = `${myAccountEntity} GET_DOCUMENT_PATH`;

const adhocPaymentEntity = '[Adhoc Payment]';
export const ADHOC_PAYMENT_LOADER = `${adhocPaymentEntity} ADHOC_PAYMENT_LOADER`;
export const FETCH_ADHOC_PAYMENT_LIST = `${adhocPaymentEntity} FETCH_ADHOC_PAYMENT_LIST`;
export const FETCH_ADHOC_PAYMENT_LIST_SUCCESS = `${adhocPaymentEntity} FETCH_ADHOC_PAYMENT_LIST_SUCCESS`;
export const FETCH_ADHOC_PAYMENT_LIST_DELETE = `${adhocPaymentEntity} FETCH_ADHOC_PAYMENT_LIST_DELETE`;
export const ADHOC_PAYMENT_EDIT_TITLE = `${adhocPaymentEntity} ADHOC_PAYMENT_EDIT_TITLE`;
export const ADHOC_PAYMENT_EDIT_TITLE_SUCCESS = `${adhocPaymentEntity} ADHOC_PAYMENT_EDIT_TITLE_SUCCESS`;
export const ADHOC_PAYMENT_ADD_ROW = `${adhocPaymentEntity} ADHOC_PAYMENT_ADD_ROW`;
export const ADHOC_PAYMENT_ADD_ROW_SUCCESS = `${adhocPaymentEntity} ADHOC_PAYMENT_ADD_ROW_SUCCESS`;
export const ADHOC_PAYMENT_FILE_UPLOAD_SUCCESS = `${adhocPaymentEntity} ADHOC_PAYMENT_FILE_UPLOAD_SUCCESS`;
export const ADHOC_PAYMENT_FILE_UPLOAD_FAILURE = `${adhocPaymentEntity} ADHOC_PAYMENT_FILE_UPLOAD_FAILURE`;
export const ADHOC_PAYMENT_LOCK_PAYOUT = `${adhocPaymentEntity} ADHOC_PAYMENT_LOCK_PAYOUT`;
// Get Carrier Onboard List
const carrierOnboardList = '[Carrier Onboard list]';
export const FETCH_CARRIER_ONBOARD_LIST = `${carrierOnboardList} FETCH_CARRIER_ONBOARD_LIST`;
export const SET_CARRIER_ONBOARD_LIST = `${carrierOnboardList} SET_CARRIER_ONBOARD_LIST`;
export const CARRIER_ONBOARD_LIST_LOADER = `${carrierOnboardList} CARRIER_ONBOARD_LIST_LOADER`;
export const CLEAR_CARRIER_ONBOARD_LIST = `${carrierOnboardList} CLEAR_CARRIER_ONBOARD_LIST`;
// Add Carrier Products
export const SET_CARRIER_PRODUCTS_LIST = `${carrierOnboardList} SET_CARRIER_PRODUCTS_LIST`;
export const FETCH_CARRIER_PRODUCTS_LIST = `${carrierOnboardList} FETCH_CARRIER_PRODUCTS_LIST`;
export const ADD_CARRIER_PRODUCT = `${carrierOnboardList} ADD_CARRIER_PRODUCT`;
export const ADD_NEW_PRODUCT_LOADER = `${carrierOnboardList} ADD_NEW_PRODUCT_LOADER`;
export const FETCH_CARRIER_PRODUCT_LOADER = `${carrierOnboardList} FETCH_CARRIER_PRODUCT_LOADER`;
// Add New Carrier to list
const addCarrier = '[Add New Carrier]';
export const ADD_NEW_CARRIER = `${addCarrier} ADD_NEW_CARRIER`;
export const SET_NEW_CARRIER = `${addCarrier} SET_NEW_CARRIER`;
export const ADD_NEW_CARRIER_LOADER = `${addCarrier} ADD_NEW_CARRIER_LOADER`;
// UPDATE Carrier Appointment Settings
const carrierAppointmentSettings = '[Carrier Appointment Settings]';
export const UPDATE_CARRIER_APPOINTMENT_SETTINGS = `${carrierAppointmentSettings} UPDATE_CARRIER_APPOINTMENT_SETTINGS`;
export const TOGGLE_CARRIER_APPOINTMENT_SETTINGS = `${carrierAppointmentSettings} TOGGLE_CARRIER_APPOINTMENT_SETTINGS`;
export const SET_APPOINTMENT_ENABLED = `${carrierAppointmentSettings} SET_APPOINTMENT_ENABLED`;
export const SET_SHOW_IN_STORE_ENABLED = `${carrierAppointmentSettings} SET_SHOW_IN_STORE_ENABLED`;
export const OPEN_APPOINTMENT_SETTING = `${carrierAppointmentSettings} OPEN_APPOINTMENT_SETTING`;
export const INIT_DATA_CARRIER_APPOINTMENTS = `${carrierAppointmentSettings} INIT_DATA_CARRIER_APPOINTMENTS`;
export const SAVE_APPOINTMENT_SETTINGS = `${carrierAppointmentSettings} SAVE_APPOINTMENT_SETTINGS`;
export const SET_UPDATE_APPOINTMENT_SETTING_LOADER = `${carrierAppointmentSettings} SET_UPDATE_APPOINTMENT_SETTING_LOADER`;
export const SET_SELECTED_STATES= `${carrierAppointmentSettings} SET_SELECTED_STATES`;
// Fetch Carrier Onboard List Item Details
const carrierListItemDetail = '[Carrier Onboard List Item Details]';
export const CARRIER_DETAILS_LOADER = `${carrierListItemDetail} CARRIER_DETAILS_LOADER`;
export const FETCH_CARRIER_DETAILS = `${carrierListItemDetail} FETCH_CARRIER_DETAILS`;
export const SET_CARRIER_DETAILS = `${carrierListItemDetail} SET_CARRIER_DETAILS`;
export const SET_NEW_CARRIER_PRODUCT = `${carrierListItemDetail} SET_NEW_CARRIER_PRODUCT`;
export const SET_NEW_CARRIER_PRODUCT_EMPTY = `${carrierListItemDetail} SET_NEW_CARRIER_PRODUCT_EMPTY`;
export const SET_IS_FETCH_CARRIER_ERROR = `${carrierListItemDetail} SET_FETCH_CARRIER_ERROR`;
// Update Carrier Details
export const CARRIER_UPDATE_LOADER = `${carrierListItemDetail} CARRIER_UPDATE_LOADER`;
export const UPDATE_CARRIER_DETAILS = `${carrierListItemDetail} UPDATE_CARRIER_DETAILS`;
export const SET_CARRIER_UPDATE_DETAILS = `${carrierListItemDetail} SET_CARRIER_UPDATE_DETAILS`;
export const CLEAR_CARRIER_RESPONSE = `${carrierListItemDetail} CLEAR_CARRIER_RESPONSE`;

// Add Webinar Link Details
export const WEBINAR_LINK_LOADER = `${carrierListItemDetail} WEBINAR_LINK_LOADER`;
export const ADD_WEBINAR_LINK = `${carrierListItemDetail} ADD_WEBINAR_LINK`;
export const UPDATE_WEBINAR_LINK = `${carrierListItemDetail} UPDATE_WEBINAR_LINK`;
export const DELETE_WEBINAR_LINK = `${carrierListItemDetail} DELETE_WEBINAR_LINK`;
export const SET_WEBINAR_LINK_DETAILS_IN_STORE = `${carrierListItemDetail} SET_WEBINAR_LINK_DETAILS_IN_STORE`;
export const UPDATE_WEBINAR_LINK_DETAILS_IN_STORE = `${carrierListItemDetail} UPDATE_WEBINAR_LINK_DETAILS_IN_STORE`;
export const DELETE_WEBINAR_LINK_DETAILS_FROM_STORE = `${carrierListItemDetail} DELETE_WEBINAR_LINK_DETAILS_FROM_STORE`;
export const SET_IS_WEBINAR_LINK_DETAILS_EDIT = `${carrierListItemDetail} SET_IS_WEBINAR_LINK_DETAILS_EDIT`;

// Update Commission Table Data
export const UPDATE_COMMISSION_TABLE_DATA = `${carrierListItemDetail} UPDATE_COMMISSION_TABLE_DATA`;
export const SET_COMMISSION_TABLE_DATA = `${carrierListItemDetail} SET_COMMISSION_TABLE_DATA`;
export const CLEAR_UPDATED_ROW_REPONSE = `${carrierListItemDetail} CLEAR_UPDATED_ROW_REPONSE`;
export const SET_IMAGE_UPLOAD_ERROR = `${carrierListItemDetail} SET_IMAGE_UPLOAD_ERROR`;
// Update Commission Paid On
const carrierCommissionPaidOn = '[Carrier Commission Paid On]';
export const UPDATE_COMMISSION_PAID_ON = `${carrierCommissionPaidOn} UPDATE_COMMISSION_PAID_ON`;
export const SET_COMMISSION_PAID_ON = `${carrierCommissionPaidOn} SET_COMMISSION_PAID_ON`;
// Update Carrier Product Market States
const carrierProductMarketUpdate = '[Carrier Product Market Update]';
export const UPDATE_CARRIER_PRODUCT_MARKET = `${carrierProductMarketUpdate} UPDATE_CARRIER_PRODUCT_MARKET`;
export const SET_CARRIER_PRODUCT_MARKET = `${carrierProductMarketUpdate} SET_CARRIER_PRODUCT_MARKET`;
export const DELETE_COMMISSION_TABLE_DATA = `${carrierListItemDetail} DELETE_COMMISSION_TABLE_DATA`;
export const UPDATE_COMMISSION_TABLE_AFTER_DELETE = `${carrierListItemDetail} UPDATE_COMMISSION_TABLE_AFTER_DELETE`;
export const UPDATE_COMMISSION_TABLE_ON_STATE_UPDATE = `${carrierProductMarketUpdate} UPDATE_COMMISSION_TABLE_ON_STATE_UPDATE`;
// Update Carrier Product Accessibility
const carrierProductAccessibility = '[Carrier Product Accessibility Update]';
export const UPDATE_CARRIER_PRODUCT_ACCESSIBILITY = `${carrierProductAccessibility} UPDATE_CARRIER_PRODUCT_ACCESSIBILITY`;
export const SET_CARRIER_PRODUCT_ACCESSIBILITY = `${carrierProductAccessibility} SET_CARRIER_PRODUCT_ACCESSIBILITY`;
// Agent adgoc payment report
const agentAdhocReport = '[Agent Adhoc Payment Report]';
export const FETCH_AGENT_ADHOC_REPORT_LIST = `${agentAdhocReport} FETCH_AGENT_ADHOC_REPORT_LIST`;
export const SET_AGENT_ADHOC_REPROT_LIST = `${agentAdhocReport} SET_AGENT_ADHOC_REPROT_LIST`;
export const AGENT_ADHOC_REPORT_LIST_LOADER = `${agentAdhocReport} AGENT_ADHOC_REPORT_LIST_LOADER`;
// Reports
const agentOnBoardReport = '[Agent Onboard Report]';
export const FETCH_AGENT_ONBOARD_REPORT = `${agentOnBoardReport} FETCH_AGENT_ONBOARD_REPORT`;
export const SET_ONBOARD_REPORT = `${agentOnBoardReport} SET_ONBOARD_REPORT`;
export const FETCH_COMMISSION_REPORT = `${agentOnBoardReport} FETCH_COMMISSION_REPORT`;
export const SET_COMMISSION_REPORT = `${agentOnBoardReport} SET_COMMISSION_REPORT`;
// Reports module
const agentSyncReport = '[Agent Sync Reports]';
export const FETCH_MONTHLY_COMMISSION_REPORT = `${agentSyncReport} FETCH_MONTHLY_COMMISSION_REPORT`;
export const SET_MONTHLY_COMMISSION_REPORT = `${agentSyncReport} SET_MONTHLY_COMMISSION_REPORT`;
export const MONTHLY_COMMISSION_DATE_LOADER = `${agentSyncReport} MONTHLY_COMMISSION_DATE_LOADER`;
export const FETCH_MONTHLY_COMMISSION_DATE = `${agentSyncReport} FETCH_MONTHLY_COMMISSION_DATE`;
export const SET_MONTHLY_COMMISSION_DATE = `${agentSyncReport} SET_MONTHLY_COMMISSION_DATE`;
export const FETCH_AGENT_SYNC_REPORT = `${agentSyncReport} FETCH_AGENT_SYNC_REPORT`;
export const SET_AGENT_SYNC_REPORT = `${agentSyncReport} SET_AGENT_SYNC_REPORT`;
export const FETCH_FAST_START_REPORT = `${agentSyncReport} FETCH_FAST_START_REPORT`;
export const SET_FAST_START_REPORT = `${agentSyncReport} SET_FAST_START_REPORT`;
export const FETCH_APPOINTMENT_FAILURE_REPORT = `${agentSyncReport} FETCH_APPOINTMENT_FAILURE_REPORT`;
export const SET_APPOINTMENT_FAILURE_REPORT = `${agentSyncReport} SET_APPOINTMENT_FAILURE_REPORT`;
export const FETCH_QUICKBOOKS_EXTRACT_REPORT = `${agentSyncReport} FETCH_QUICKBOOKS_EXTRACT_REPORT`;
export const SET_QUICKBOOKS_REPORT = `${agentSyncReport} SET_QUICKBOOKS_REPORT`;
export const FETCH_INCENTIVE_REPORT = `${agentSyncReport} FETCH_INCENTIVE_REPORT`;
export const SET_INCENTIVE_REPORT = `${agentSyncReport} SET_INCENTIVE_REPORT`;
export const FETCH_CARRIER_LOB_MARKET_REPORT = `${agentSyncReport} FETCH_CARRIER_LOB_MARKET_REPORT`;
export const CARRIER_LOB_MARKET_REPORT_LOADER = `${agentSyncReport} CARRIER_LOB_MARKET_REPORT_LOADER`;
export const FETCH_REWARDS_PROGRAM_REPORT = `${agentSyncReport} FETCH_REWARDS_PROGRAM_REPORT`;
export const REWARDS_PROGRAM_REPORT_LOADER = `${agentSyncReport} REWARDS_PROGRAM_REPORT_LOADER`;

// Module Permissions
const modulePermissions = '[Module Permissions]';
export const SET_MODULE_PERMISSIONS = `${modulePermissions} SET_MODULE_PERMISSIONS`;
// Role Permission
const rolePermission = '[Role Permission]';
export const FETCH_ROLE_PERMISSION_LIST = `${rolePermission} FETCH_ROLE_PERMISSION_LIST`;
export const SET_ROLE_PERMISSION_LIST = `${rolePermission} SET_ROLE_PERMISSION_LIST`;
export const ROLE_PERMISSION_LIST_LOADER = `${rolePermission} ROLE_PERMISSION_LIST_LOADER`;
export const CLEAR_ROLE_PERMISSION_LIST = `${rolePermission} CLEAR_ROLE_PERMISSION_LIST`;
export const ADD_NEW_ROLE = `${rolePermission} ADD_NEW_ROLE`;
export const SET_NEW_ROLE = `${rolePermission} SET_NEW_ROLE`;
export const CLEAR_RESPONCE_MESSAGE = `${rolePermission} CLEAR_RESPONCE_MESSAGE`;
export const DELETE_ROLE = `${rolePermission} DELETE_ROLE`;
export const SET_IS_FETCH_FAILED = `${rolePermission} SET_IS_FETCH_FAILED`;
/* Edit Role */
export const FETCH_ROLE_EDIT_PERMISSION_LIST = `${rolePermission} FETCH_ROLE_EDIT_PERMISSION_LIST`;
export const ROLE_EDIT_PERMISSION_LIST_LOADER = `${rolePermission} ROLE_EDIT_PERMISSION_LIST_LOADER`;
export const SET_ROLE_EDIT_PERMISSION_LIST = `${rolePermission} SET_ROLE_EDIT_PERMISSION_LIST`;
/* Save Role */
export const UPDATE_ROLE_PERMISSION_LOADER = `${rolePermission} UPDATE_ROLE_PERMISSION_LOADER`;
export const UPDATE_ROLE_PERMISSION = `${rolePermission} UPDATE_ROLE_PERMISSION`;
export const SET_UPDATED_ROLE_PERMISSION = `${rolePermission} SET_UPDATED_ROLE_PERMISSION`;
// User Permission
const userPermission = '[User Permission]';
export const FETCH_USER_PERMISSION_LIST = `${userPermission} FETCH_USER_PERMISSION_LIST`;
export const SET_USER_PERMISSION_LIST = `${userPermission} SET_USER_PERMISSION_LIST`;
export const USER_PERMISSION_LIST_LOADER = `${userPermission} USER_PERMISSION_LIST_LOADER`;
/* Add User */
export const ADD_NEW_USER = `${userPermission} ADD_NEW_USER`;
export const SET_NEW_USER = `${userPermission} SET_NEW_USER`;
export const ADD_NEW_USER_LOADER = `${userPermission} ADD_NEW_USER_LOADER`;
export const CLEAR_USER_DATA = `${userPermission} CLEAR_USER_DATA`;
/* Get Roles */
export const FETCH_ROLES_LIST = `${userPermission} FETCH_ROLES_LIST`;
export const SET_ROLES_LIST = `${userPermission} SET_ROLES_LIST`;
export const FETCH_ROLES_LIST_LOADER = `${userPermission} FETCH_ROLES_LIST_LOADER`;
export const CLEAR_ROLES_DATA = `${userPermission} CLEAR_ROLES_DATA`;
/* Update user */
export const UPDATE_USER_DATA = `${userPermission} UPDATE_USER_DATA`;
export const SET_UPDATED_USER_DATA = `${userPermission} SET_UPDATED_USER_DATA`;
export const FETCH_CATEGORY_LIST = `${userPermission} FETCH_CATEGORY_LIST`;
export const SET_CATEGORY_LIST = `${userPermission} SET_CATEGORY_LIST`;
export const CATEGORY_LIST_LOADER = `${userPermission} CATEGORY_LIST_LOADER`;
/* Advance Search */
const advanceSearchFields = '[Advance Search Field]';
export const FETCH_ADVANCE_SEARCH_FIELDS = `${advanceSearchFields} FETCH_ADVANCE_SEARCH_FIELDS`;
export const ADVANCE_SEARCH_FIELDS_LOADER = `${advanceSearchFields} ADVANCE_SEARCH_FIELDS_LOADER`;
export const SET_ADVANCE_SEARCH_FIELDS = `${advanceSearchFields} SET_ADVANCE_SEARCH_FIELDS`;
/* Update filter Count */
const filterCount = '[Agent Directory Filter Count]';
export const UPDATE_FILTER_COUNT = `${filterCount} UPDATE_FILTER_COUNT`;
/* Update flag of prompt on login for signup */
export const UPDATE_ON_PROMPT_CLOSE = `${agentDirectoryEntity} UPDATE_ON_PROMPT_CLOSE`;
/* Update sub-agent status */
export const SET_SUB_AGENT_STATUS = `${subAgent} SET_SUB_AGENT_STATUS`;
export const STORE_SUB_AGENT_STATUS = `${subAgent} STORE_SUB_AGENT_STATUS`;
// Fetch Quotes List
const quotes = '[Quotes]';
export const FETCH_QUOTES_LIST = `${quotes} FETCH_QUOTES_LIST`;
export const SET_QUOTES_LIST = `${quotes} SET_QUOTES_LIST`;
export const SET_QUOTES_LIST_LOADER = `${quotes} SET_QUOTES_LIST_LOADER`;
export const SET_PRODUCER_QUOTES_OPTIONS_LOADER = `${quotes} SET_PRODUCER_QUOTES_OPTIONS_LOADER`;
export const SET_ACCESS_QUOTING_PAGE_LOADER = `${quotes} SET_ACCESS_QUOTING_PAGE_LOADER`;
export const SET_PRODUCER_QUOTES_OPTIONS_LIST = `${quotes} SET_PRODUCER_QUOTES_OPTIONS_LIST`;
export const SET_LAUNCH_URL = `${quotes} SET_LAUNCH_URL`;
export const FETCH_PRODUCER_QUOTE_OPTIONS = `${quotes} FETCH_PRODUCER_QUOTE_OPTIONS`;
export const ACCESS_QUOTING_PAGE = `${quotes} ACCESS_QUOTING_PAGE`;
export const SET_COMMERCIAL_LINE_LAUNCH_URL = `${quotes} SET_COMMERCIAL_LINE_LAUNCH_URL`;
export const SET_CARRIERS_CREDENTIAL_LIST = `${quotes} SET_CARRIERS_CREDENTIAL_LIST`;
export const FETCH_CARRIER_CREDENTIAL = `${quotes} FETCH_CARRIER_CREDENTIAL`;
export const CARRIER_CREDENTIAL_LOADER = `${quotes} CARRIER_CREDENTIAL_LOADER`;
export const SET_CARRIER_PRODUCER_APPOINTMENT_ID = `${quotes} SET_CARRIER_PRODUCER_APPOINTMENT_ID`;

// Motion auto report
export const motionAutoReport = '[Motion Auto Report]';
export const FETCH_MOTION_AUTO_LIST = `${motionAutoReport} FETCH_MOTION_AUTO_LIST`;
export const SET_MOTION_AUTO_REPORT = `${motionAutoReport} SET_MOTION_AUTO_REPORT`;
export const MOTION_AUTO_LIST_LOADER = `${motionAutoReport} MOTION_AUTO_LIST_LOADER`;
export const UPDATE_DEFAULT_PASSWORD = `${userEntity} UPDATE_DEFAULT_PASSWORD`;
export const UPDATE_AGENT_NAME_IN_SIGNUP_PROCESS = `${userEntity} UPDATE_AGENT_NAME_IN_SIGNUP_PROCESS`;
export const UPDATE_NPN_DATA = `${userEntity} UPDATE_NPN_DATA`;
export const CLEAR_DATA_ON_WRITE_UNDER_AGENCY = `${userEntity} CLEAR_DATA_ON_WRITE_UNDER_AGENCY`;
export const UPDATE_AGENT_ROLE = `${userEntity} UPDATE_AGENT_ROLE`;

// Impersonate Mode
const impersonateMode = '[Impersonate]';
export const GET_IMPERSONATE_MODE = `${impersonateMode} GET_IMPERSONATE_MODE`;
export const SET_IMPERSONATE_MODE = `${impersonateMode} SET_IMPERSONATE_MODE`;
export const SET_IMPERSONATE_LOADER = `${impersonateMode} SET_IMPERSONATE_LOADER`;
export const CLEAR_IMPERSONATE_MODE = `${impersonateMode} CLEAR_IMPERSONATE_MODE`;

// Quote Connector Notification Indicator
const quoteConnectorNotificationIndicator = '[Quote Notification]';
export const UPDATE_QUOTE_NOTIFICATION_INDICATOR_LOADER = `${quoteConnectorNotificationIndicator} UPDATE_QUOTE_NOTIFICATION_INDICATOR_LOADER`;
export const UPDATE_QUOTE_NOTIFICATION_INDICATOR = `${quoteConnectorNotificationIndicator} UPDATE_QUOTE_NOTIFICATION_INDICATOR`;

// My Policies Notification Indicator
const myPoliciesNotificationIndicator = '[My Policy Notification]';
export const UPDATE_MY_POLICY_NOTIFICATION_INDICATOR = `${myPoliciesNotificationIndicator} UPDATE_MY_POLICY_NOTIFICATION_INDICATOR`;
export const UPDATE_MY_POLICY_NOTIFICATION_INDICATOR_LOADER = `${myPoliciesNotificationIndicator} UPDATE_MY_POLICY_NOTIFICATION_INDICATOR_LOADER`;

// Update Status reasons
const updateAgentStatusReason = '[Agent Status Reason]';
export const UPDATE_AGENT_STATUS_REASON = `${updateAgentStatusReason} UPDATE_AGENT_STATUS_REASON`;
export const SET_CARRIER_FLOOD_RATER = 'SET_CARRIER_FLOOD_RATER';
export const SET_CARRIER_TEND = 'SET_CARRIER_TEND';
export const SET_CLOUD_FRONT_URL = 'SET_CLOUD_FRONT_URL';
export const SET_CARRIER_TOOLTIP = 'SET_CARRIER_TOOLTIP';

// Update showSSNPrompt
const updateSSNPromtValue = '[Update SSN Prompt Value]';
export const UPDATE_SSN_EDIT_PROMPT_VALUE = `${updateSSNPromtValue} UPDATE_SSN_EDIT_PROMPT_VALUE`;

// Product
const product = '[Product]';
export const GET_PRODUCT_LIST = `${product} GET_PRODUCT_LIST`;
export const SET_PRODUCT_LIST = `${product} SET_PRODUCT_LIST`;
export const GET_PRODUCT_CATEGORIES = `${product} GET_PRODUCT_CATEGORIES`;
export const SET_PRODUCT_CATEGORIES = `${product} SET_PRODUCT_CATEGORIES`;
export const SET_PRODUCT_LIST_LOADER = `${product} SET_PRODUCT_LIST_LOADER`;

// Add New Product to list
const addProduct = '[Add New Product]';
export const ADD_NEW_PRODUCT = `${addProduct} ADD_NEW_PRODUCT`;
export const SET_NEW_PRODUCT = `${addProduct} SET_NEW_PRODUCT`;
export const NEW_PRODUCT_ADD_LOADER = `${addProduct} NEW_PRODUCT_ADD_LOADER`;
export const UPDATE_PRODUCT = `${addProduct} UPDATE_PRODUCT`;
export const SET_UPDATED_PRODUCT = `${addProduct} SET_UPDATED_PRODUCT`;
export const UPDATE_PRODUCT_LOADER = `${addProduct} UPDATE_PRODUCT_LOADER`;
export const SET_CARRIER_LINK = 'SET_CARRIER_LINK';
export const UPDATE_AGENT_COMPLIANCE = 'UPDATE_AGENT_COMPLIANCE';
export const PRODUCT_FILE_UPLOAD_ERROR = 'PRODUCT_FILE_UPLOAD_ERROR';
export const PRODUCT_SERVER_ERROR = 'PRODUCT_SERVER_ERROR';
export const PRODUCT_SERVER_ERROR_MESSAGE = 'PRODUCT_SERVER_ERROR_MESSAGE';

// Admin user list
const adminUserList = '[Admin User List]';
export const GET_ADMIN_USER_LIST = `${adminUserList} GET_ADMIN_USER_LIST`;
export const SET_ADMIN_USER_LIST = `${adminUserList} SET_ADMIN_USER_LIST`;
export const SET_ADMIN_USER_LIST_LOADER = `${adminUserList} SET_ADMIN_USER_LIST_LOADER`;
export const FETCH_ADMIN_CATEGORY_ROLE_LIST = `${adminUserList} FETCH_ADMIN_CATEGORY_ROLE_LIST`;
export const SET_ADMIN_CATEGORY_ROLE_LIST = `${adminUserList} SET_ADMIN_CATEGORY_ROLE_LIST`;
export const ADMIN_CATEGORY_ROLE_LIST_LOADER = `${adminUserList} ADMIN_CATEGORY_ROLE_LIST_LOADER`;

// Incentive Banner List
const incentiveBannerList = '[Incentive Banner]';
export const GET_INCENTIVE_BANNER_LIST = `${incentiveBannerList} GET_INCENTIVE_BANNER_LIST`;
export const SET_INCENTIVE_BANNER_LIST = `${incentiveBannerList} SET_INCENTIVE_BANNER_LIST`;
export const SET_INCENTIVE_BANNER_LIST_LOADER = `${incentiveBannerList} SET_INCENTIVE_BANNER_LIST_LOADER`;
export const GET_INCENTIVE_DETAILS = `${incentiveBannerList} GET_INCENTIVE_DETAILS`;
export const SET_INCENTIVE_DETAILS = `${incentiveBannerList} SET_INCENTIVE_DETAILS`;
export const SET_INCENTIVE_DETAIL_LOADER = `${incentiveBannerList} SET_INCENTIVE_DETAIL_LOADER`;

// Add-edit New Incentive to list
const addNewIncentiveBanner = '[Add New Incentive Banner]';
export const ADD_NEW_INCENTIVE_BANNER = `${addNewIncentiveBanner} ADD_NEW_INCENTIVE_BANNER`;
export const NEW_INCENTIVE_BANNER_LOADER = `${addNewIncentiveBanner} NEW_INCENTIVE_BANNER_LOADER`;
export const SET_NEW_INCENTIVE_BANNER = `${addNewIncentiveBanner} SET_NEW_INCENTIVE_BANNER`;
export const UPDATE_INCENTIVE_BANNER = `${addNewIncentiveBanner} UPDATE_INCENTIVE_BANNER`;
export const SET_UPDATED_INCENTIVE_BANNER = `${addNewIncentiveBanner} SET_UPDATED_INCENTIVE_BANNER`;
export const SET_INCENTIVE_FILE_UPLOAD_LOADER = `${addNewIncentiveBanner} SET_INCENTIVE_FILE_UPLOAD_LOADER`;
export const SET_INCENTIVE_FILE_UPLOAD_ERROR = `${addNewIncentiveBanner} SET_INCENTIVE_FILE_UPLOAD_ERROR`;
export const SET_SAVE_SUCCESS = `${addNewIncentiveBanner} SET_SAVE_SUCCESS`;
export const CLEAR_INCENTIVE_DETAILS = `${addNewIncentiveBanner} CLEAR_INCENTIVE_DETAILS`;

// Add New Admin to list
const addNewAdminUser = '[Add New Admin]';
export const ADD_NEW_ADMIN_USER = `${addNewAdminUser} ADD_NEW_ADMIN_USER`;
export const NEW_ADMIN_USER_LOADER = `${addNewAdminUser} NEW_ADMIN_USER_LOADER`;
export const SET_NEW_ADMIN_USER = `${addNewAdminUser} SET_NEW_ADMIN_USER`;
export const UPDATE_ADMIN_USER = `${addNewAdminUser} UPDATE_ADMIN_USER`;
export const SET_UPDATED_ADMIN_USER = `${addNewAdminUser} SET_UPDATED_ADMIN_USER`;

// LOA Filters
const loaFilters = '[lOA Filters]';
export const LOA_FILTERS_LIST_LOADER = `${loaFilters} LOA_FILTERS_LIST_LOADER`;
export const FETCH_LOA_FILTERS = `${loaFilters} FETCH_LOA_FILTERS`;
export const STORE_LOA_FILTERS_LIST = `${loaFilters} STORE_LOA_FILTERS_LIST`;
export const LOA_FILTER_DETAILS_LOADER = `${loaFilters} LOA_FILTER_DETAILS_LOADER`;
export const FETCH_LOA_FILTERS_DETAILS = `${loaFilters} FETCH_LOA_FILTERS_DETAILS`;
export const STORE_LOA_FILTERS_DETAILS = `${loaFilters} STORE_LOA_FILTERS_DETAILS`;

// Set empty LOA Filter
export const CLEAR_LOA_FILTER = `${loaFilters} CLEAR_LOA_FILTER`;

// Add New LOA Filter
export const ADD_NEW_LOA_FILTER = `${loaFilters} ADD_NEW_LOA_FILTER`;
export const ADD_NEW_LOA_FILTER_LOADER = `${loaFilters} ADD_NEW_LOA_FILTER_LOADER`;
export const FETCH_ALL_STATES = `${loaFilters} FETCH_ALL_STATES`;
export const SET_ALL_STATES_LOADER = `${loaFilters} SET_ALL_STATES_LOADER`;
export const SET_ALL_LOA_FILTER_STATES = `${loaFilters} SET_ALL_LOA_FILTER_STATES`;
// Update Loa Filters
export const UPDATE_LOA_FILTER = `${loaFilters} UPDATE_LOA_FILTER`;
// Sign In
const getOrganizationDetails = '[Get Organization Details]';
export const SET_SIGNUP_TRANSITION_LOGIN_SUCCESS = `${getOrganizationDetails} SET_SIGNUP_TRANSITION_LOGIN_SUCCESS`; // Organization
export const SET_SIGNUP_TRANSITION_GET_AGENT_SUCCESS = `${getOrganizationDetails} SET_SIGNUP_TRANSITION_GET_AGENT_SUCCESS`;
// Add Producer
const addProducer = '[Add Producer]';
export const ADD_PRODUCER_DATA = `${addProducer} ADD_PRODUCER_DATA`;
export const ADD_PRODUCER_DATA_LOADER = `${addProducer} ADD_PRODUCER_DATA_LOADER`;
// Fetch Carrier Credentials
const carrierCredentials = '[Carrier Credentials]';
export const FETCH_CARRIER_CREDENTIALS = `${carrierCredentials} FETCH_CARRIER_CREDENTIALS`;
export const FETCH_CARRIER_CREDENTIALS_LOADER = `${carrierCredentials} FETCH_CARRIER_CREDENTIALS_LOADER`;
export const SET_CARRIER_CREDENTIALS = `${carrierCredentials} SET_CARRIER_CREDENTIALS`;
export const FETCH_CARRIER_CREDENTIALS_DETAILS = `${carrierCredentials} FETCH_CARRIER_CREDENTIALS_DETAILS`;
export const FETCH_CARRIER_CREDENTIALS_DETAILS_LOADER = `${carrierCredentials} FETCH_CARRIER_CREDENTIALS_DETAILS_LOADER`;
export const SET_CARRIER_CREDENTIALS_DETAILS = `${carrierCredentials} SET_CARRIER_CREDENTIALS_DETAILS`;
export const UPDATE_APPOINTMENT_TYPE = `${carrierCredentials} UPDATE_APPOINTMENT_TYPE`;
export const STORE_UPDATED_APPOINTMENT_TYPE = `${carrierCredentials} STORE_UPDATED_APPOINTMENT_TYPE`;
export const CLEAR_IS_UPDATED_APPOINTMENT_DATA = `${carrierCredentials} CLEAR_IS_UPDATED_APPOINTMENT_DATA`;
export const UPDATE_CARRIER_PRODUCER_CREDIENTIAL = `${carrierCredentials} UPDATE_CARRIER_PRODUCER_CREDIENTIAL`;
export const STORE_UPDATED_CARRIER_PRODUCER_CREDIENTIAL = `${carrierCredentials} STORE_UPDATED_CARRIER_PRODUCER_CREDIENTIAL`;
export const CLEAR_IS_PRODUCER_CREDIENTIAL_UPDATED = `${carrierCredentials} CLEAR_IS_PRODUCER_CREDIENTIAL_UPDATED`;
export const CLEAR_CARRIER_PRODUCER_CREDENTIALS = `${carrierCredentials} CLEAR_CARRIER_PRODUCER_CREDENTIALS`;
export const QUOTE_URL_DATA = `${quoteConnectorNotificationIndicator} QUOTE_URL_DATA`;
export const DELETE_CARRIER_CREDENTIAL_STATE = `${quoteConnectorNotificationIndicator} DELETE_CARRIER_CREDENTIAL_STATE`;
export const DELETE_CARRIER_CREDENTIAL_STATE_LOADER = `${quoteConnectorNotificationIndicator} DELETE_CARRIER_CREDENTIAL_STATE_LOADER`;
// Appointment Requests
const appointmentRequests = '[Appointment Requests]';
export const DOWNLOAD_APPOINTMENT_FILE = `${appointmentRequests} DOWNLOAD_APPOINTMENT_FILE`;

export const FETCH_CARRIERS_CATEGORIES = 'FETCH_CARRIERS_CATEGORIES';
export const SET_CARRIERS_CATEGORIES_LOADER = 'SET_CARRIERS_CATEGORIES_LOADER';
export const SET_CARRIERS_CATEGORIES = 'SET_CARRIERS_CATEGORIES';

// Class Codes for Quotes
const classCodes = '[Class Codes]';
export const FETCH_CLASS_CODES = `${classCodes} FETCH_CLASS_CODES`;
export const SAVE_CLASS_CODES = `${classCodes} SAVE_CLASS_CODES`;
export const SET_CLASS_CODES = `${classCodes} SET_CLASS_CODES`;
export const CLASS_CODES_LOADER = `${classCodes} CLASS_CODES_LOADER`;
export const FETCH_CLASS_CARRIERS = `${classCodes} FETCH_CLASS_CARRIERS`;
export const UPDATE_CLASS_CODE_DETAILS = `${classCodes} UPDATE_CLASS_CODE_DETAILS`;
export const CLEAR_CLASS_CODES_DETAILS = `${classCodes} CLEAR_CLASS_CODES_DETAILS`;
export const SET_SELECTED_LOB_LIST = `${classCodes} SET_SELECTED_LOB_LIST`;
export const FETCH_SELECTED_LOB_LIST = `${classCodes} FETCH_SELECTED_LOB_LIST`;

// Bulk Appointments
const bulkAppointments = '[Bulk Appointments]';
export const FETCH_APPOINTMENTS = `${bulkAppointments} FETCH_APPOINTMENTS`;
export const SET_APPOINTMENTS = `${bulkAppointments} SET_APPOINTMENTS`;
export const APPOINTMENTS_LOADER = `${bulkAppointments} APPOINTMENTS_LOADER`;
export const APPOINTMENTS_SUCCESS = `${bulkAppointments} APPOINTMENTS_SUCCESS`;
export const APPOINTMENTS_PROCESSING = `${bulkAppointments} APPOINTMENTS_PROCESSING`;
export const APPOINTMENTS = `${bulkAppointments} APPOINTMENTS`;
export const APPOINTMENTS_CURRENT_DATA = `${bulkAppointments} APPOINTMENTS_CURRENT_DATA`;
export const FETCH_SAMPLE_FILE = `${bulkAppointments} FETCH_SAMPLE_FILE`;
export const BULK_APPOINTMENT_UPDATE = `${commissionEntity} BULK_APPOINTMENT_UPDATE`;
export const BULK_APPOINTMENT_FILES_UPLOADING = `${bulkAppointments} BULK_APPOINTMENT_FILES_UPLOADING`;
export const BULK_APPOINTMENT_SUCCESS = `${bulkAppointments} BULK_APPOINTMENT_SUCCESS`;
export const BULK_APPOINTMENT_FILES_UPLOAD_FAILED = `${bulkAppointments} BULK_APPOINTMENT_FILES_UPLOAD_FAILED`;

// Agent Messaging
const agentMessaging = '[Agent Messaging]';
export const AGENT_MESSAGING_LOADER = `${agentMessaging} AGENT_MESSAGING_LOADER`;
export const AGENT_MESSAGING_SUCCESS = `${agentMessaging} AGENT_MESSAGING_SUCCESS`;
export const AGENT_MESSAGING_PROCESS_SUCCESS = `${agentMessaging} AGENT_MESSAGING_PROCESS_SUCCESS`;
export const AGENT_MESSAGE_STATE = `${agentMessaging} AGENT_MESSAGE_STATE`;
export const AGENT_MESSAGING_PROCESSING = `${agentMessaging} AGENT_MESSAGING_PROCESSING`;
export const AGENT_MESSAGING_CURRENT_DATA = `${agentMessaging} AGENT_MESSAGING_CURRENT_DATA`;
export const UPLOAD_AGENT_MESSAGING = `${commissionEntity} UPLOAD_AGENT_MESSAGING`;
export const UPLOAD_AGENT_NOTIFICATION = `${commissionEntity} UPLOAD_AGENT_NOTIFICATION`;
export const AGENT_MESSAGING_FILES_UPLOADING = `${agentMessaging} AGENT_MESSAGING_FILES_UPLOADING`;
export const AGENT_MESSAGING_FILE_SUCCESS = `${agentMessaging} AGENT_MESSAGING_FILE_SUCCESS`;
export const AGENT_MESSAGING_FILES_UPLOAD_FAILED = `${agentMessaging} AGENT_MESSAGING_FILES_UPLOAD_FAILED`;
export const FETCH_AGENT_MESSAGING = `${agentMessaging} FETCH_AGENT_MESSAGING`;
export const SET_MESSAGING_LIST = `${agentMessaging} SET_MESSAGING_LIST`;
export const NOTIFICATION_IMAGE_UPLOAD_ERROR = `${agentMessaging} NOTIFICATION_IMAGE_UPLOAD_ERROR`;

// Fast Start
const fastStart = '[Fast Start]';
export const FETCH_FAST_START_DETAIL = `${fastStart} FETCH_FAST_START_DETAIL`;
export const FAST_START_DETAIL_LOADER = `${fastStart} FAST_START_DETAIL_LOADER`;
export const STORE_FAST_START_DETAILS = `${fastStart} STORE_FAST_START_DETAILS`;

// Q2 Incentive
const q2Incentive = '[Q2 Incentive]';
export const FETCH_Q2_INCENTIVE_DETAIL = `${q2Incentive} FETCH_Q2_INCENTIVE_DETAIL`;
export const Q2_INCENTIVE_DETAIL_LOADER = `${q2Incentive} Q2_INCENTIVE_DETAIL_LOADER`;
export const STORE_Q2_INCENTIVE_DETAILS = `${q2Incentive} STORE_Q2_INCENTIVE_DETAILS`;
export const ADD_Q2_INCENTIVE_POLICIES = `${q2Incentive} ADD_Q2_INCENTIVE_POLICIES`;
export const ADD_Q2_INCENTIVE_POLICIES_LOADER = `${q2Incentive} ADD_Q2_INCENTIVE_POLICIES_LOADER`;
export const ADDED_Q2_INCENTIVE_DETAILS = `${q2Incentive} ADDED_Q2_INCENTIVE_DETAILS`;
export const SET_IS_Q2_INCENTIVE_ADDED_FLAG = `${q2Incentive} SET_IS_Q2_INCENTIVE_ADDED_FLAG`;
export const SHOW_Q2_INCENTIVE_MODAL = `${q2Incentive} SHOW_Q2_INCENTIVE_MODAL`;
export const Q2_INCENTIVE_INITIAL_DETAIL = `${q2Incentive} Q2_INCENTIVE_INITIAL_DETAIL`;
export const SET_Q2_INCENTIVE_MODAL = `${q2Incentive} SET_Q2_INCENTIVE_MODAL`;
export const SET_Q2_INCENTIVE_MODAL_VIEWED = `${q2Incentive} SET_Q2_INCENTIVE_MODAL_VIEWED`;
export const SET_Q2_INCENTIVE_POLICY_LIST_LOADER = `${q2Incentive} SET_Q2_INCENTIVE_POLICY_LIST_LOADER`;
export const SET_Q2_INCENTIVE_POLICY_LIST = `${q2Incentive} SET_Q2_INCENTIVE_POLICY_LIST`;
export const FETCH_Q2_INCENTIVE_POLICIES = `${q2Incentive} FETCH_Q2_INCENTIVE_POLICIES`;

// Leaderboard
const leaderboard = '[Leaderboard]';
export const FETCH_LEADER_BOARD_DETAIL = `${leaderboard} FETCH_LEADER_BOARD_DETAIL`;
export const SET_LEADER_BOARD_DETAIL = `${leaderboard} SET_LEADER_BOARD_DETAIL`;
export const SET_SHOULD_SHOW_LEADER_BOARD = `${leaderboard} SET_SHOULD_SHOW_LEADER_BOARD`;
export const SET_LEADER_BOARD_DETAIL_LOADER = `${leaderboard} SET_LEADER_BOARD_DETAIL_LOADER`;
export const FETCH_INCENTIVE_LEADER_BOARD_DETAIL = `${leaderboard} FETCH_INCENTIVE_LEADER_BOARD_DETAIL`;
export const SET_INCENTIVE_LEADER_BOARD_DETAIL = `${leaderboard} SET_INCENTIVE_LEADER_BOARD_DETAIL`;

// Q3incentive
const incentive = '[incentive]';
export const FETCH_Q3_INCENTIVE_DETAIL = `${incentive} FETCH_Q3_INCENTIVE_DETAIL`;
export const SET_Q3_INCENTIVE_DETAIL = `${incentive} SET_Q3_INCENTIVE_DETAIL`;
export const SET_Q3_DAYSREMAINING = `${incentive} SET_Q3_DAYSREMAINING`;
export const SET_Q3_INCENTIVE_DETAIL_LOADER = `${incentive} SET_Q3_INCENTIVE_DETAIL_LOADER`;
export const SET_Q4_DAYSREMAINING = `${incentive} SET_Q4_DAYSREMAINING`;
export const FETCH_INCENTIVE_EARNING = `${incentive} FETCH_INCENTIVE_EARNING`;
export const SET_INCENTIVE_EARNING = `${incentive} SET_INCENTIVE_EARNING`;

// Producer Settings
const producerSettings = '[Producer Settings]';
export const FETCH_PRODUCER_SETTINGS = `${quoteConnector} FETCH_PRODUCER_SETTINGS`;
export const SET_IS_NEW_DASHBOARD_ENABLED = `${producerSettings} SET_IS_NEW_DASHBOARD_ENABLED`;
export const SET_IS_CANOPY_CONNECT_ENABLED = `${producerSettings} SET_IS_CANOPY_CONNECT_ENABLED`;
export const SET_IS_LEAD_FEED_ENABLED = `${producerSettings} SET_IS_LEAD_FEED_ENABLED`;
export const SET_IS_AGENT_TOOLS_ENABLED = `${producerSettings} SET_IS_AGENT_TOOLS_ENABLED`;
export const SET_IS_NEW_CARRIER_STORE_ENABLED = `${producerSettings} SET_IS_NEW_CARRIER_STORE_ENABLED`;
export const SET_IS_NOTIFICATION_CENTER_ENABLED = `${producerSettings} SET_IS_NOTIFICATION_CENTER_ENABLED`;

// Producer Flags
const producerFlags = '[Producer Flags]';
export const FETCH_PRODUCER_FLAGS = `${producerFlags} FETCH_PRODUCER_FLAGS`;
export const SET_IS_REFERRAL_PROGRAM_ENABLED = `${producerFlags} SET_IS_REFERRAL_PROGRAM_ENABLED`;

// Refresh state
const refreshState = '[Refresh State]';
export const VALIDATE_REQUIRED_STATE_DATA = `${refreshState} VALIDATE_REQUIRED_STATE_DATA`;

// State Rehydration
export const SET_PRODUCER = `${userEntity} SET_PRODUCER`;

// Passwordless Sign In
const passwordlessSignIn = '[Passwordless Sign In]';

export const CHECK_IS_PASSWORDLESS = `${passwordlessSignIn} CHECK_IS_PASSWORDLESS`;
export const SET_IS_GLOBAL_AUTH_LOADER_SHOWN = `${passwordlessSignIn} SET_IS_GLOBAL_AUTH_LOADER_SHOWN`;

// Sign in
const signIn = '[Sign In]';
export const SET_SIGN_IN_COHORT_DATA = `${signIn} SET_SIGN_IN_COHORT_DATA`;

// Carrier Incentive
const carrierIncentive = '[Carrier Incentive]';
export const SET_INCENTIVE_LIST = `${carrierIncentive} SET_INCENTIVE_LIST`;
export const CARRIER_INCENTIVE_LOADER = `${carrierIncentive} CARRIER_INCENTIVE_LOADER`;
export const FETCH_INCENTIVE_LIST = `${carrierIncentive} FETCH_INCENTIVE_LIST`;
export const SET_CARRIER_INCENTIVE_DETAILS = `${carrierIncentive} SET_CARRIER_INCENTIVE_DETAILS`;
export const SET_CARRIER_INCENTIVE_DETAIL_LOADER = `${carrierIncentive} SET_CARRIER_INCENTIVE_DETAIL_LOADER`;
export const ADD_CARRIER_INCENTIVE = `${carrierIncentive} ADD_CARRIER_INCENTIVE`;
export const SET_CARRIER_INCENTIVE = `${carrierIncentive} SET_CARRIER_INCENTIVE`;
export const SET_UPDATED_CARRIER_INCENTIVE = `${carrierIncentive} SET_UPDATED_CARRIER_INCENTIVE`;
export const SET_INCENTIVE_SUBMIT_LOADER = `${carrierIncentive} SET_INCENTIVE_SUBMIT_LOADER`;

// Carrier Zip Code
const carrierZipCode = '[Carrier Zipcode]';
export const CARRIER_ZIP_CODE_FILE_LOADER = `${agentSyncReport} CARRIER_ZIP_CODE_LOADER`;
export const CARRIER_ZIP_CODE_FILE_MODAL = `${agentSyncReport} CARRIER_ZIP_CODE_FILE_MODAL`;
export const FETCH_ZIP_CODE_FILE = `${carrierZipCode} FETCH_ZIP_CODE_FILE`
export const ADD_CARRIER_ZIP_CODE_FILE = `${carrierZipCode} ADD_CARRIER_ZIP_CODE_FILE`;
