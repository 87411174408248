import { all, fork, put, takeLeading } from 'redux-saga/effects';
import { getImpersonateBFFRequestConfig } from 'redux/saga/impersonate';
import config from 'config';
import { errorHandler } from 'redux/actions';
import * as types from '../../constants/action-types';
import axios from 'util/api';
import { setIsReferralProgramEnabled } from '../actions/producer-flags';

export function* fetchAndStoreProducerFlagsWatcher() {
  yield takeLeading(types.FETCH_PRODUCER_SETTINGS, fetchAndStoreProducerFlags);
}

export function* fetchAndStoreProducerFlags() {
  try {
    const requestConfig = yield getImpersonateBFFRequestConfig();
    const producerFlagsResponse = yield axios.get(
      `${config.BFF_URL}/api/v1/producer-flags`,
      requestConfig
    );

    const isReferralProgramEnabled = Boolean(
      producerFlagsResponse?.data?.featureFlags?.cashConnect
        ?.referralProgramEnabled
    );

    yield put(setIsReferralProgramEnabled(isReferralProgramEnabled));
  } catch (error) {
    yield put(errorHandler(error));
  }
}

// ------ ROOT SAGA -----------------
export default function* rootSaga() {
  yield all([fork(fetchAndStoreProducerFlagsWatcher)]);
}
