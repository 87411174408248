import { Box, CardActions, Modal, Typography } from '@material-ui/core';
import { SUBMIT } from 'constants/constant';
import React from 'react';
import IntlMessages from 'util/intl-messages';
import HeroImage from './HeroImage.svg';

import CloseIcon from './CloseIcon';
import './modal.scss';

export const W9Modal = ({ open, onClose, onCtaClick, isDropboxLoading }) => {
  return (
    <Modal open={open} className="app-signup-v w9-modal">
      <Box className="wrapper">
        <div className="close-icon" onClick={onClose}>
          <CloseIcon />
        </div>

        <img
          src={HeroImage}
          alt="W9 Form"
          className="hero-image"
          width="568px"
          height="225px"
        />
        <Typography className="heading">
          <IntlMessages id="signup.tax.heading" />
        </Typography>
        <Typography className="text">
          <IntlMessages id="signup.tax.text" />
        </Typography>
        <CardActions className="signup-btn-action-card action-card">
          <button
            type={SUBMIT}
            className="cta orange-next-button"
            onClick={onCtaClick}
            disabled={isDropboxLoading}
          >
            <IntlMessages id="w9Modal.cta" />
          </button>
        </CardActions>
      </Box>
    </Modal>
  );
};
