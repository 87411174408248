// @ts-check

import { useIsSSOBFF } from './bff';
import { useIsSSOLegacy } from './legacy';

/**
 * @param {boolean} bffResult
 * @param {boolean} legacyResult
 * @returns {string}
 */
const getIsSSOTextResult = (bffResult, legacyResult) => {
  const textResult = [];

  if (bffResult && legacyResult) {
    textResult.push("You're connecting through TwinGate, well done!");
  } else {
    textResult.push(
      "You're not connecting through TwinGate, contact the administrator!\n"
    );
  }

  if (!bffResult) {
    textResult.push('BFF connection not going through TwinGate.');
  }
  if (!legacyResult) {
    textResult.push('API connection not going through TwinGate.');
  }

  return textResult.join('\n');
};

/**
 * @returns {{result: boolean, textResult: string, isLoading: boolean, error: unknown}}
 */
export const useIsSSO = () => {
  const bffSSO = useIsSSOBFF();
  const legacySSO = useIsSSOLegacy();

  return {
    result: bffSSO.isSuccess && legacySSO.isSuccess,
    textResult: getIsSSOTextResult(bffSSO.isSuccess, legacySSO.isSuccess),
    isLoading: bffSSO.isLoading || legacySSO.isLoading,
    error: bffSSO.error || legacySSO.error,
  };
};
