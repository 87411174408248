import { all, fork, put, takeLeading } from 'redux-saga/effects';
import { displayErrorMessage } from 'util/user-feedback';
import axios from 'util/api';
import * as carrierIncentiveActions from 'constants/action-types';
import { errorHandler } from '../actions/error';
import {
  fetchCarrierIncentiveList,
  setCarrierIncentiveList,
  setCarrierIncentiveLoader,
  setIncentiveSubmitLoader,
  setNewCarrierIncentive,
} from 'redux/actions/carrier-incentive';
import config from 'config';

function* fetchCarrierIncentiveListDetails() {
  try {
    yield put(setCarrierIncentiveLoader(true));
    const carrierIncentiveData = yield axios.get(
      `${config.BFF_URL}/api/v1/carrier-incentives`
    );
    if (carrierIncentiveData?.data) {
      yield put(
        setCarrierIncentiveList({
          carrierIncentiveList: carrierIncentiveData?.data.carrierIncentives,
        })
      );
    }
  } catch (error) {
    if (!!error.response && error.response.status === 401) {
      yield displayErrorMessage(error.response.data.message);
      yield put(errorHandler(error));
    } else {
      yield put(errorHandler(error));
    }
  } finally {
    yield put(setCarrierIncentiveLoader(false));
  }
}

export function* fetchCarrierIncentiveListDetailsWatcher() {
  yield takeLeading(
    carrierIncentiveActions.FETCH_INCENTIVE_LIST,
    fetchCarrierIncentiveListDetails
  );
}

function* updateCarrierIncentiveDetails({ payload }) {
  const requestPayload = {
    carrierId: payload.carrierId,
    status: payload.status,
    header: payload.header,
    startDate: payload.startDate,
    endDate: payload.endDate,
    body: payload?.body,
    learnMoreUrl: payload?.learnMoreUrl,
  };
  try {
    yield put(setIncentiveSubmitLoader(true));
    const response = yield axios.put(
      `${config.BFF_URL}/api/v1/carrier-incentives/${payload?.id}`,
      requestPayload
    );
    if (response.status === 200 && response.data) {
      yield put(fetchCarrierIncentiveList());
    }
  } catch (error) {
    if (!!error.response && error.response.status === 401) {
      yield displayErrorMessage(error.response.data.message);
      yield put(errorHandler(error));
    } else {
      yield put(errorHandler(error));
    }
  } finally {
    yield put(setIncentiveSubmitLoader(false));
  }
}

export function* updateCarrierIncentiveDetailsWatcher() {
  yield takeLeading(
    carrierIncentiveActions.SET_UPDATED_CARRIER_INCENTIVE,
    updateCarrierIncentiveDetails
  );
}

function* addNewCarrierIncentive({ payload }) {
  try {
    yield put(setIncentiveSubmitLoader(true));
    const response = yield axios.post(
      `${config.BFF_URL}/api/v1/carrier-incentives`,
      payload
    );
    if (!!response.data) {
      yield put(setNewCarrierIncentive(response.data));
    } else {
      yield displayErrorMessage(response.data.message);
    }
  } catch (error) {
    if (!!error.response && error.response.status === 401) {
      yield displayErrorMessage(error.response.data.message);
      yield put(errorHandler(error));
    } else {
      yield put(errorHandler(error));
    }
  } finally {
    yield put(setIncentiveSubmitLoader(false));
  }
}
export function* addCarrierIncentiveWatcher() {
  yield takeLeading(
    carrierIncentiveActions.ADD_CARRIER_INCENTIVE,
    addNewCarrierIncentive
  );
}

// ------ ROOT SAGA -----------------
export default function* rootSaga() {
  yield all([
    fork(fetchCarrierIncentiveListDetailsWatcher),
    fork(updateCarrierIncentiveDetailsWatcher),
    fork(addCarrierIncentiveWatcher),
  ]);
}
