import React from 'react';

const SubmitLoader = ({ className }) => (
  <span
    className={`spinner-border spinner-border-sm mr-1 ${className}`}
    role="status"
    aria-hidden="true"
  ></span>
);

export default SubmitLoader;
