/**
 * Note: this mapping is only valid for production.
 * Expect failures in dev and staging.
 */
export const carrierNameToIdMapping = [
  {
    id: 493,
    display_name: 'Foremost Signature',
    carrier_code: 'foremost-signature',
  },
  {
    id: 492,
    display_name: 'NuSureCo',
    carrier_code: 'nusureco',
  },
  {
    id: 459,
    display_name: 'Orange',
    carrier_code: 'orange',
  },
  {
    id: 426,
    display_name: 'Hagerty',
    carrier_code: 'hagerty',
  },
  {
    id: 393,
    display_name: 'AssurancePlus',
    carrier_code: 'assuranceplus',
  },
  {
    id: 392,
    display_name: 'Foxquilt',
    carrier_code: 'foxquilt',
  },
  {
    id: 391,
    display_name: 'First Insurance',
    carrier_code: 'first-insurance',
  },
  {
    id: 358,
    display_name: 'Unique',
    carrier_code: 'unique',
  },
  {
    id: 357,
    display_name: 'Hiscox',
    carrier_code: 'hiscox',
  },
  {
    id: 356,
    display_name: 'Boundless Rider',
    carrier_code: 'boundless-rider',
  },
  {
    id: 355,
    display_name: 'First Capital',
    carrier_code: 'first-capital',
  },
  {
    id: 354,
    display_name: 'ePremium',
    carrier_code: 'epremium',
  },
  {
    id: 353,
    display_name: 'Stonegate',
    carrier_code: 'stonegate',
  },
  {
    id: 352,
    display_name: 'HoneyQuote',
    carrier_code: 'honeyquote',
  },
  {
    id: 351,
    display_name: 'Obie',
    carrier_code: 'obie',
  },
  {
    id: 350,
    display_name: 'IDIQ',
    carrier_code: 'idiq',
  },
  {
    id: 349,
    display_name: 'Hourly',
    carrier_code: 'hourly',
  },
  {
    id: 348,
    display_name: 'Covertree',
    carrier_code: 'covertree',
  },
  {
    id: 347,
    display_name: 'Fairmatic',
    carrier_code: 'fairmatic',
  },
  {
    id: 346,
    display_name: 'bolt',
    carrier_code: 'bolt',
  },
  {
    id: 345,
    display_name: 'Sola',
    carrier_code: 'sola',
  },
  {
    id: 344,
    display_name: 'Savvi',
    carrier_code: 'savvi',
  },
  {
    id: 343,
    display_name: 'Gainsco',
    carrier_code: 'gainsco',
  },
  {
    id: 342,
    display_name: 'Slice',
    carrier_code: 'slice',
  },
  {
    id: 341,
    display_name: 'Rainbow',
    carrier_code: 'rainbow',
  },
  {
    id: 340,
    display_name: 'LIO',
    carrier_code: 'lio',
  },
  {
    id: 339,
    display_name: 'THREE by Berkshire Hathaway',
    carrier_code: 'three-by-berkshire-hathaway',
  },
  {
    id: 338,
    display_name: 'Arkay Auto Warranty',
    carrier_code: 'arkay-auto-warranty',
  },
  {
    id: 337,
    display_name: 'Pathpoint',
    carrier_code: 'pathpoint',
  },
  {
    id: 336,
    display_name: 'Kemper Auto',
    carrier_code: 'kemper-auto',
  },
  {
    id: 303,
    display_name: 'Jewelers Mutual',
    carrier_code: 'jewelers-mutual',
  },
  {
    id: 302,
    display_name: 'Homeowners of America',
    carrier_code: 'homeowners-of-america',
  },
  {
    id: 301,
    display_name: 'Annex Risk',
    carrier_code: 'annex-coastal',
  },
  {
    id: 300,
    display_name: 'Commonwealth',
    carrier_code: 'commonwealth',
  },
  {
    id: 299,
    display_name: 'Chubb',
    carrier_code: 'chubb',
  },
  {
    id: 266,
    display_name: 'Dairyland',
    carrier_code: 'dairyland',
  },
  {
    id: 265,
    display_name: 'Lamar General Agency',
    carrier_code: 'lamar-general-agency',
  },
  {
    id: 264,
    display_name: 'Cover Whale',
    carrier_code: 'cover-whale',
  },
  {
    id: 263,
    display_name: 'Test Carrier Store',
    carrier_code: 'test-carrier-store',
  },
  {
    id: 262,
    display_name: 'National General Custom 360',
    carrier_code: 'national-general-custom-360',
  },
  {
    id: 261,
    display_name: 'Foremost',
    carrier_code: 'foremost',
  },
  {
    id: 260,
    display_name: 'RLI Surety',
    carrier_code: 'rli-surety',
  },
  {
    id: 259,
    display_name: 'Voom',
    carrier_code: 'voom',
  },
  {
    id: 258,
    display_name: 'Delos',
    carrier_code: 'delos',
  },
  {
    id: 257,
    display_name: 'Bristol West Auto',
    carrier_code: 'bristol-west-auto',
  },
  {
    id: 256,
    display_name: 'SES Risk Solutions',
    carrier_code: 'ses-risk-solutions',
  },
  {
    id: 255,
    display_name: 'Nationwide',
    carrier_code: 'nationwide',
  },
  {
    id: 254,
    display_name: 'Collectibles Insurance Services',
    carrier_code: 'collectibles-insurance-services',
  },
  {
    id: 253,
    display_name: 'biBERK',
    carrier_code: 'biberk',
  },
  {
    id: 220,
    display_name: 'Vacant Express',
    carrier_code: 'vacant-express',
  },
  {
    id: 219,
    display_name: 'Test CArrier 1',
    carrier_code: 'test-carrier-1',
  },
  {
    id: 218,
    display_name: 'Assurity',
    carrier_code: 'assurity',
  },
  {
    id: 217,
    display_name: 'ClickVSC',
    carrier_code: 'clickvsc',
  },
  {
    id: 216,
    display_name: 'Mutual of Omaha',
    carrier_code: 'mutual-of-omaha',
  },
  {
    id: 215,
    display_name: 'First Connect',
    carrier_code: 'first-connect',
  },
  {
    id: 214,
    display_name: 'Root',
    carrier_code: 'root',
  },
  {
    id: 213,
    display_name: 'CNA',
    carrier_code: 'cna',
  },
  {
    id: 212,
    display_name: 'Pacific Specialty Insurance Company',
    carrier_code: 'psic',
  },
  {
    id: 211,
    display_name: 'CSAA',
    carrier_code: 'csaa',
  },
  {
    id: 209,
    display_name: 'Ahoy',
    carrier_code: 'ahoy',
  },
  {
    id: 208,
    display_name: 'Steadily',
    carrier_code: 'steadily',
  },
  {
    id: 207,
    display_name: 'Cowbell',
    carrier_code: 'cowbell',
  },
  {
    id: 204,
    display_name: 'National General',
    carrier_code: 'national-general',
  },
  {
    id: 202,
    display_name: 'Get Covered',
    carrier_code: 'get-covered',
  },
  {
    id: 193,
    display_name: 'Stillwater',
    carrier_code: 'stillwater',
  },
  {
    id: 192,
    display_name: 'Blink',
    carrier_code: 'blink',
  },
  {
    id: 191,
    display_name: 'Sproutt',
    carrier_code: 'sproutt',
  },
  {
    id: 190,
    display_name: 'Sensa',
    carrier_code: 'sensa',
  },
  {
    id: 189,
    display_name: 'plumlife',
    carrier_code: 'plum-life',
  },
  {
    id: 188,
    display_name: 'Tend',
    carrier_code: 'tend',
  },
  {
    id: 154,
    display_name: 'Roamly',
    carrier_code: 'roamly',
  },
  {
    id: 153,
    display_name: 'Hippo HOA Commercial Lines',
    carrier_code: 'hippo-hoa-commercial-lines',
  },
  {
    id: 141,
    display_name: 'Hippo Flood',
    carrier_code: 'hippo-flood',
  },
  {
    id: 138,
    display_name: 'Bamboo',
    carrier_code: 'bamboo',
  },
  {
    id: 136,
    display_name: 'GeoVera',
    carrier_code: 'geovera',
  },
  {
    id: 128,
    display_name: 'Sterling Surplus Underwriters',
    carrier_code: 'sterling',
  },
  {
    id: 126,
    display_name: 'Wright Flood',
    carrier_code: 'wright-flood',
  },
  {
    id: 125,
    display_name: 'Pouch Insurance',
    carrier_code: 'pouch-insurance',
  },
  {
    id: 88,
    display_name: 'Honeycomb',
    carrier_code: 'honeycomb',
  },
  {
    id: 87,
    display_name: 'Metromile',
    carrier_code: 'metromile',
  },
  {
    id: 65,
    display_name: 'Rainwalk',
    carrier_code: 'rainwalk',
  },
  {
    id: 64,
    display_name: 'Annex Risk Solutions',
    carrier_code: 'annex-risk-solutions',
  },
  {
    id: 62,
    display_name: 'Liferaft',
    carrier_code: 'liferaft',
  },
  {
    id: 61,
    display_name: 'Great American',
    carrier_code: 'great-american',
  },
  {
    id: 60,
    display_name: 'Elephant',
    carrier_code: 'elephant',
  },
  {
    id: 58,
    display_name: 'Kemper Personal Lines',
    carrier_code: 'kemper-personal-lines',
  },
  {
    id: 57,
    display_name: 'Kelly Klee',
    carrier_code: 'kelly-klee',
  },
  {
    id: 56,
    display_name: 'Kemper Preferred',
    carrier_code: 'kemper-preferred',
  },
  {
    id: 55,
    display_name: 'Skywatch.ai',
    carrier_code: 'skywatch.ai',
  },
  {
    id: 24,
    display_name: 'Cover Insurance',
    carrier_code: 'cover-insurance',
  },
  {
    id: 23,
    display_name: 'Go Insurance',
    carrier_code: 'go-insurance',
  },
  {
    id: 22,
    display_name: 'Aon Edge',
    carrier_code: 'aon-edge',
  },
  {
    id: 20,
    display_name: 'Openly',
    carrier_code: 'Openly',
  },
  {
    id: 19,
    display_name: 'Motion Auto',
    carrier_code: 'MotionAuto',
  },
  {
    id: 18,
    display_name: 'Thimble',
    carrier_code: 'Thimble',
  },
  {
    id: 17,
    display_name: 'Ethos',
    carrier_code: 'Ethos',
  },
  {
    id: 16,
    display_name: 'Coterie',
    carrier_code: 'Coterie',
  },
  {
    id: 15,
    display_name: 'Attune',
    carrier_code: 'Attune',
  },
  {
    id: 14,
    display_name: 'Palomar Specialty',
    carrier_code: 'PalomarSpecialty',
  },
  {
    id: 13,
    display_name: 'Next',
    carrier_code: 'Next',
  },
  {
    id: 12,
    display_name: 'Safeco',
    carrier_code: 'Safeco',
  },
  {
    id: 11,
    display_name: 'Infinity',
    carrier_code: 'Infinity',
  },
  {
    id: 10,
    display_name: 'Kemper Delete',
    carrier_code: 'Kemper',
  },
  {
    id: 9,
    display_name: 'Neptune',
    carrier_code: 'Neptune',
  },
  {
    id: 8,
    display_name: 'Clearcover',
    carrier_code: 'Clearcover',
  },
  {
    id: 7,
    display_name: 'Hippo',
    carrier_code: 'HIPPO',
  },
  {
    id: 6,
    display_name: 'RLI',
    carrier_code: 'RLI',
  },
  {
    id: 5,
    display_name: 'Mile Auto',
    carrier_code: 'MileAuto',
  },
  {
    id: 4,
    display_name: 'Bankers',
    carrier_code: 'Bankers',
  },
  {
    id: 3,
    display_name: 'Pie',
    carrier_code: 'Pie',
  },
  {
    id: 2,
    display_name: 'Aegis Specialty',
    carrier_code: 'AegisSpecialty',
  },
  {
    id: 1,
    display_name: 'Velocity',
    carrier_code: 'Velocity',
  },
];
