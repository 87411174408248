import {
  all,
  fork,
  put,
  takeLeading,
} from 'redux-saga/effects';
import * as quoteConnectorType from 'constants/action-types';
import { errorHandler } from '../actions/error';
import {
  fetchQuoteConnectorSettingsResult,
  setIsNewQuoteConnectorEnabled,
} from '../actions/quote-connector';
import axios from 'util/api';
import { getImpersonateBFFRequestConfig } from './impersonate';
import config from 'config';

// @TODO: To be removed
export function* fetchAndStoreQuoteConnectorSettingsWatcher() {
  yield takeLeading(
    quoteConnectorType.FETCH_QUOTE_CONNECTOR_SETTINGS,
    fetchAndStoreQuoteConnectorSettings
  );
}

export function* fetchAndStoreQuoteConnectorSettings() {
  try {
    const requestConfig = yield getImpersonateBFFRequestConfig();
    const quoteConnectorSettingsData = yield axios.get(
      `${config.BFF_URL}/api/v1/producer/settings/quote-connector`,
      requestConfig
    );
    const isEnabled = Boolean(quoteConnectorSettingsData?.data?.enabled);
    yield put(setIsNewQuoteConnectorEnabled(isEnabled));
    yield put(fetchQuoteConnectorSettingsResult(isEnabled));
  } catch (error) {
    yield put(errorHandler(error));
    yield put(fetchQuoteConnectorSettingsResult(false));
  }
}

// ------ ROOT SAGA -----------------
export default function* rootSaga() {
  yield all([
    fork(fetchAndStoreQuoteConnectorSettingsWatcher),
  ]);
}
