import React, { Component, Fragment } from 'react';
import clsx from 'clsx';
import {
  Card,
  CardActions,
  Grid,
  Input,
  InputAdornment,
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import FormInput from 'components/form-input';
import HtmlTooltip from 'components/html-tooltip';
import PhoneMask from 'components/phone-mask';
import SubmitLoader from 'components/submit-loader';
import {
  AGENCY_PROFILE,
  ALTERNATE_SUB_PRODUCER,
  EMAIL,
  EMAIL_CONFLICT,
  FINISH,
  FIRST_NAME,
  LAST_NAME,
  MAX_NAME_LENGTH,
  NEXT,
  OFF,
  OWNER_FIRST_NAME,
  OWNER_LAST_NAME,
  PHONE,
  PHONE_ALREADY_EXIST,
  POST_SIGNUP_STEPS_NAME,
  PRINCIPAL_ACCESS_ROLE,
  SMALL,
  SSN_ID,
  SUB_PRODUCER_KEY,
  SUBMIT,
  TAX_INFO_CONTENT,
  TAX_STATUS_INFO_CONTENT,
  TOP,
  TYPE_PASSWORD,
  TYPE_TEXT,
} from 'constants/constant';
import { withGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { connect } from 'react-redux';
import { preSignupUser } from 'redux/actions';
import { getProgressBarFlag, setDashboardView } from 'redux/actions/auth';
import { teamMemberEmailDuplicate as setTeamMemberEmailDuplicate } from 'redux/actions/my-account';
import {
  setCurrentStepIndex,
  stepSubmit,
  stepSubmitSuccess,
  submitLoaderHide,
} from 'redux/actions/post-sign-up';
import { blurHandlers, validators } from 'util/form-handler';
import IntlMessages from 'util/intl-messages';
import './step-1.scss';
import SsnMask from 'components/ssn-mask';
import { InfoOutlined } from '@material-ui/icons';
import { v4 } from 'uuid';
import { executeRecaptchaCheck } from 'util/recaptcha';
import { onSSNChange, onSSNKeyPress } from '../step-3/step-3-helpers';

let originalSSNValue = '';
let hiddenSSNValue = '';
let show = false;
const initState = {
  fieldsModified: false,
  isMount: false,
  showSSN: false,
  formValues: {
    firstName: '',
    email: '',
    lastName: '',
    phone: '',
    password: '',
    SSN: '',
  },
  formErrors: {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    password: '',
    SSN: '',
  },
  showPassword: false,
};
class Step1 extends Component {
  constructor(props) {
    super(props);
    this.isSubProducerWithProductionAccess =
      props.productionAccess && props.signupFlow === SUB_PRODUCER_KEY;
    this.alternateSubWithProdAcc =
      props.productionAccess && props.signupFlow === ALTERNATE_SUB_PRODUCER;
    this.state = initState;
    this.errorField = null;
    this.createRef();
  }

  componentDidMount = () => {
    const {
      agencyProfile: { firstName, email, lastName, password, phone },
      tax: { SSN },
      submitLoader,
      isSignedUpByReferral,
      referralData,
      teamMemberEmailDuplicate,
    } = this.props;

    let newState = {
      isMount: true,
      formValues: {
        ...this.state.formValues,
        email,
        firstName,
        lastName,
        phone,
        password,
        SSN,
      },
    };
    if (isSignedUpByReferral) {
      newState.formValues.firstName = referralData.first_name;
      newState.formValues.lastName = referralData.last_name;
      newState.formValues.agencyLegalName = referralData.agency_name;
      newState.formValues.phone = referralData.phone_number;
      newState.formValues.email = referralData.email;
      newState.formValues.utm_source = referralData.utm_source;
      newState.formValues.referred_code = referralData.referred_code;
    }
    this.setState(newState);
    originalSSNValue = SSN;
    hiddenSSNValue = SSN?.replaceAll(/[0-9]/g, '*');
    show = false;
    submitLoader && this.props.submitLoaderHide();
    if (teamMemberEmailDuplicate?.messageType) {
      this.props.setTeamMemberEmailDuplicate({
        success: false,
        message: null,
        messageType: null,
      });
    }
    if (
      !localStorage.getItem('uuid') &&
      !localStorage.getItem('Authorization')
    ) {
      const uuid = v4();
      localStorage.setItem('uuid', uuid);
      this.props.getProgressBarFlag(uuid);
    }
  };

  UNSAFE_componentWillReceiveProps(newProps) {
    let formErrors = { ...this.state.formErrors };
    formErrors = {
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      password: '',
    };

    if (
      !newProps.teamMemberEmailDuplicate?.success &&
      newProps.teamMemberEmailDuplicate?.messageType === EMAIL_CONFLICT
    ) {
      this.setState((prevState) => ({
        ...prevState,
        formErrors: {
          ...formErrors,
          email: newProps.teamMemberEmailDuplicate?.message,
        },
      }));
    }

    if (newProps.duplicatePhoneNumber) {
      this.setState((prevState) => ({
        ...prevState,
        formErrors: {
          ...formErrors,
          phone: PHONE_ALREADY_EXIST,
        },
      }));
    }
  }

  createRef() {
    this.refList = {
      firstName: React.createRef(),
      lastName: React.createRef(),
      email: React.createRef(),
      phone: React.createRef(),
      password: React.createRef(),
    };
  }

  validators = {
    firstName: [validators.required, validators.nameInvalid],
    lastName: [validators.required, validators.nameInvalid],
    email: [
      (value) => {
        if (!this.props.isSubProducer) {
          return validators.required(value) || validators.emailInvalid(value);
        }
        return '';
      },
    ],
    phone: [validators.required, validators.usPhoneInvalid],
    SSN: [
      // check only if subproducer with production access
      (value) => {
        if (
          this.props.role !== PRINCIPAL_ACCESS_ROLE &&
          this.isSubProducerWithProductionAccess &&
          !this.props.writesUnderAgencyLicense
        ) {
          return validators.required(value) || validators.SSNInvalid(value);
        }
        return '';
      },
    ],
    password: [
      (value) => {
        if (
          this.props.isSubProducer ||
          (this.props.signupStatus && !this.props.isSignedUpByReferral)
        ) {
          return '';
        }
        return validators.required(value) || validators.passwordInvalid(value);
      },
    ],
  };

  validate = () => {
    const { formValues, formErrors } = this.state;
    let isInvalid = false;
    const newErrors = { ...formErrors };

    for (const [key, validatorList] of Object.entries(this.validators)) {
      for (const validator of validatorList) {
        const error = validator(formValues[key]);
        newErrors[key] = error;

        if (error) {
          isInvalid = true;

          if (!this.errorField) {
            this.errorField = key;
          }

          break;
        }
      }
    }

    this.setState({ formErrors: newErrors });

    return !isInvalid;
  };

  submitForm = async (event) => {
    event.preventDefault();

    const {
      formValues: {
        email,
        firstName,
        lastName,
        phone,
        password,
        SSN,
        utm_source,
        referred_code,
      },
    } = this.state;
    const {
      tax,
      signupFlow,
      signupStatus,
      agencyProfile,
      isSignedUpByReferral,
    } = this.props;
    const flagshipIdentifier = localStorage.getItem('uuid');

    if (!this.validate()) {
      this.refList[this.errorField]?.current?.scrollIntoView({
        behavior: 'smooth',
      });
      this.errorField = null;
      return;
    }

    if (
      !isSignedUpByReferral &&
      (Boolean(this.props.isSubProducer) || Boolean(signupStatus))
    ) {
      this.props.stepSubmit({
        stepId: AGENCY_PROFILE,
        entity: AGENCY_PROFILE,
        signupFlow,
        completedSignupStep: POST_SIGNUP_STEPS_NAME.AGENCY_PROFILE,
        data: {
          ...agencyProfile,
          phone: phone.replace(/[^\d]/g, ''),
          firstName: firstName,
          lastName: lastName,
          email,
          password,
        },
        final: this.props.final,
        signUpComplete: this.props.final,
        flagshipIdentifier,
        tax: signupFlow === SUB_PRODUCER_KEY ? { ...tax, SSN } : undefined,
      });
      return;
    }

    try {
      const captchaToken = await executeRecaptchaCheck(
        this.props.googleReCaptchaProps
      );
      this.props.preSignupUser({
        signup_details: {
          phone_number: phone.replace(/[^\d]/g, ''),
          first_name: firstName,
          last_name: lastName,
          email,
          password,
          captchaToken,
          utm_source,
          referred_code,
          flagshipIdentifier,
        },
      });
    } catch (e) {
      console.error(e);
    }
  };

  setValue = (key, value, callback) => {
    this.setState(
      {
        fieldsModified: true,
        formValues: { ...this.state.formValues, [key]: value },
      },
      () => callback?.(key)
    );
  };

  handleClickShowPassword = () => {
    const { formValues } = this.state;

    this.setState({
      formValues: {
        ...formValues,

        showPassword: !formValues.showPassword,
      },
    });
  };
  handleClickShowSSN = () => {
    show = !this.state.showSSN;
    hiddenSSNValue = originalSSNValue.replaceAll(/[0-9]/g, '*');
    this.setState((prevState) => ({
      ...prevState,
      showSSN: !this.state.showSSN,
    }));
  };
  handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  render() {
    const {
      classNames,
      isSubProducer,
      submitLoader,
      signupStatus,
      isSignedUpByReferral,
      loader,
      writesUnderAgencyLicense,
      role,
    } = this.props;
    const { formErrors, formValues } = this.state;
    const isPrincipalAccessProducer = role === PRINCIPAL_ACCESS_ROLE;
    const isEnabledForSSNField =
      !this.isSubProducerWithProductionAccess ||
      writesUnderAgencyLicense ||
      isPrincipalAccessProducer
        ? true
        : formValues?.SSN?.length > 0;
    const isEnabled = !isSubProducer
      ? formValues?.firstName?.length > 0 &&
        formValues?.lastName?.length > 0 &&
        formValues?.phone?.length > 0 &&
        formValues?.email?.length > 0 &&
        formValues?.password?.length > 0
      : formValues?.firstName?.length > 0 &&
        formValues?.lastName?.length > 0 &&
        formValues?.phone?.length > 0 &&
        isEnabledForSSNField;
    return (
      <Fragment>
        <Card className={clsx('first-step', classNames?.card)}>
          <form
            className="post-signup-forms"
            // onSubmit={this.submitForm}
          >
            <Grid
              container
              className={clsx(
                classNames?.cardContent,
                'card-content',
                'details-capacha-s',
                'm-0'
              )}
            >
              {!isSubProducer && (
                <Grid xs={12} item sm={12} className="sub-head-wrap">
                  <h2 className="subhead">Principal Writing Agent</h2>
                </Grid>
              )}
              <Grid xs={12} item sm={6} className="p-10">
                <label
                  ref={this.refList.firstName}
                  className={clsx({
                    'label-error-color': formErrors.firstName,
                  })}
                >
                  <IntlMessages id="label.firstName" />
                  <span className="required">*</span>
                </label>
                <FormInput
                  id={OWNER_FIRST_NAME}
                  type={TYPE_TEXT}
                  className={clsx('fs-exclude', 'validate', {
                    'error-border': formErrors.firstName,
                  })}
                  name={FIRST_NAME}
                  maxLength={MAX_NAME_LENGTH}
                  disabled={submitLoader || loader}
                  value={formValues.firstName}
                  setValue={this.setValue}
                  valueKey="firstName"
                  handleBlur={blurHandlers.startCapital}
                  error={isSubProducer && formErrors.firstName}
                />
                {!isSubProducer && (
                  <div>
                    {formErrors.firstName && (
                      <div className="error-msg height-20">
                        {formErrors.firstName}
                      </div>
                    )}
                    <span
                      className={clsx(
                        'hint-info',
                        formErrors.firstName && 'errorMsg'
                      )}
                    >
                      <IntlMessages id="name.hint" />
                    </span>
                  </div>
                )}
              </Grid>
              <Grid xs={12} item sm={6} className="p-10">
                <label
                  ref={this.refList.lastName}
                  className={clsx({
                    'label-error-color': formErrors.lastName,
                  })}
                >
                  <IntlMessages id="label.lastName" />
                  <span className="required">*</span>
                </label>
                <FormInput
                  id={OWNER_LAST_NAME}
                  type={TYPE_TEXT}
                  className={clsx('fs-exclude', 'validate', {
                    'error-border': formErrors.lastName,
                  })}
                  name={LAST_NAME}
                  maxLength={MAX_NAME_LENGTH}
                  disabled={submitLoader || loader}
                  value={formValues.lastName}
                  setValue={this.setValue}
                  valueKey="lastName"
                  handleBlur={blurHandlers.startCapital}
                  error={isSubProducer && formErrors.lastName}
                />
                {!isSubProducer && (
                  <div>
                    {formErrors.firstName && (
                      <div className="error-msg height-20">
                        {formErrors.lastName}
                      </div>
                    )}
                    <span
                      className={clsx(
                        'hint-info',
                        formErrors.lastName && 'errorMsg'
                      )}
                    >
                      <IntlMessages id="name.hint" />
                    </span>
                  </div>
                )}
              </Grid>
              {!isSubProducer && (
                <>
                  <Grid xs={12} item sm={6} className="p-10">
                    <label
                      ref={this.refList.email}
                      className={clsx({
                        'label-error-color': formErrors.email,
                      })}
                    >
                      <IntlMessages id="appModule.email" />
                      <span className="required">*</span>
                    </label>
                    <FormInput
                      id={EMAIL}
                      type={TYPE_TEXT}
                      className={clsx('fs-exclude', 'validate', {
                        'error-border': formErrors.email,
                      })}
                      name={EMAIL}
                      maxLength={50}
                      disabled={submitLoader || loader}
                      value={formValues.email}
                      setValue={this.setValue}
                      valueKey="email"
                      error={formErrors.email}
                    />
                  </Grid>
                </>
              )}
              <Grid xs={12} item sm={6} className="p-10">
                <label
                  ref={this.refList.phone}
                  className={clsx({ 'label-error-color': formErrors.phone })}
                >
                  <IntlMessages id="appModule.phone" />
                  <span className="required">*</span>
                </label>
                <Input
                  autoComplete={OFF}
                  value={formValues.phone}
                  onChange={(e) => {
                    this.setValue('phone', e.target.value);
                  }}
                  name={PHONE}
                  id={PHONE}
                  className={clsx('phone', {
                    'error-border': formErrors.phone,
                  })}
                  inputProps={{
                    className: 'fs-exclude',
                  }}
                  inputComponent={PhoneMask}
                  disabled={submitLoader || loader}
                />
                <div className="error-msg">{formErrors.phone}</div>
              </Grid>
              {!isSubProducer ? (
                <>
                  <Grid xs={12} item sm={6} className="password-wrap p-10">
                    <label
                      htmlFor="password"
                      className={clsx('active', 'password-field', {
                        'label-error-color': formErrors.password,
                      })}
                      ref={this.refList.password}
                    >
                      <IntlMessages id="label.password" />
                      <span className="required">*</span>
                    </label>
                    <Input
                      id={TYPE_PASSWORD}
                      name={TYPE_PASSWORD}
                      type={formValues.showPassword ? TYPE_TEXT : TYPE_PASSWORD}
                      value={
                        signupStatus && !isSignedUpByReferral
                          ? '********'
                          : formValues.password
                      }
                      className={clsx(
                        'fs-exclude',
                        'validate',
                        'active',
                        'password-field',
                        { 'error-border': formErrors.password }
                      )}
                      setValue={this.setValue}
                      onChange={(event) =>
                        this.setValue('password', event.target.value)
                      }
                      disabled={
                        submitLoader ||
                        loader ||
                        (signupStatus && !isSignedUpByReferral)
                      }
                      maxLength={30}
                      handleBlur={blurHandlers.trim}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={this.handleClickShowPassword}
                            onMouseDown={this.handleMouseDownPassword}
                            edge="end"
                          >
                            {formValues.showPassword ? (
                              <VisibilityOffOutlinedIcon />
                            ) : (
                              <VisibilityOutlinedIcon />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                    {formErrors.password ? (
                      <div className="error-msg height-20">
                        {formErrors.password}
                      </div>
                    ) : (
                      <span className="password-hint">
                        {' '}
                        <IntlMessages id="passwordError.hint" />
                      </span>
                    )}
                  </Grid>
                </>
              ) : (
                <>
                  {!isPrincipalAccessProducer &&
                    this.isSubProducerWithProductionAccess &&
                    !writesUnderAgencyLicense && (
                      <>
                        <Grid xs={12} item sm={6} className="p-10">
                          <label
                            className={clsx({
                              'label-error-color': formErrors.SSN,
                            })}
                          >
                            <IntlMessages id="label.agent.ssn" />
                            <span className="required">*</span>
                            <HtmlTooltip
                              title={
                                <>
                                  <span>{TAX_STATUS_INFO_CONTENT}</span>
                                  <br />
                                  <span>{TAX_INFO_CONTENT}</span>
                                </>
                              }
                              placement={TOP}
                              fontSize={SMALL}
                              disableTouchListener
                            >
                              <InfoOutlined className="ssnInfoMargin orange-text pointer" />
                            </HtmlTooltip>
                          </label>
                          <div className="ssnInfoIcon">
                            <Input
                              id={SSN_ID}
                              name={SSN_ID}
                              className={clsx('fs-exclude', 'validate', {
                                'error-border': formErrors.SSN,
                              })}
                              fullWidth={true}
                              disableUnderline={true}
                              value={show ? originalSSNValue : hiddenSSNValue}
                              inputComponent={SsnMask}
                              inputProps={{
                                autoComplete: 'off',
                              }}
                              onKeyDown={function (evt) {
                                if (!show) {
                                  originalSSNValue = onSSNKeyPress(
                                    evt,
                                    originalSSNValue
                                  );
                                  this.setValue('SSN', originalSSNValue);
                                }
                              }}
                              onChange={(e) => {
                                this.setValue('SSN', e.target.value);
                                hiddenSSNValue = e.target.value;
                                if (show) {
                                  originalSSNValue = e.target.value;
                                } else {
                                  originalSSNValue =
                                    onSSNChange(hiddenSSNValue);
                                  hiddenSSNValue = hiddenSSNValue.replaceAll(
                                    /[0-9]/g,
                                    '*'
                                  );
                                }
                                this.setValue('SSN', originalSSNValue);
                              }}
                              handleBlur={blurHandlers.trim}
                              disabled={submitLoader || loader}
                              error={formErrors.SSN ? true : false}
                            />
                            <div className="error-msg">{formErrors.SSN}</div>
                          </div>
                        </Grid>
                      </>
                    )}
                </>
              )}
            </Grid>
            <CardActions className="signup-btn-action-card">
              <Grid xs={12} item sm={12} className="p-0">
                <div
                  className={clsx(
                    'btn-wrap',
                    'signup-button-wrap',
                    'mt-0',
                    classNames.nextBtn
                  )}
                >
                  <button
                    type={SUBMIT}
                    className={`${
                      !isEnabled ? 'disable-button-grey' : 'orange-next-button'
                    }`}
                    onClick={this.submitForm}
                    id={this.props.final ? FINISH : NEXT}
                    disabled={!isEnabled || submitLoader}
                  >
                    <IntlMessages
                      id={this.props.final ? 'label.finish' : 'appModule.next'}
                    />
                    {(submitLoader || loader) && (
                      <SubmitLoader className={classNames.submitLoader} />
                    )}
                  </button>
                </div>
              </Grid>
            </CardActions>
          </form>
        </Card>
      </Fragment>
    );
  }
}

const mapStateToProps = ({
  postSignup: {
    agencyProfile,
    submitLoader,
    tax,
    currentStepIndex,
    producer_code: producerCode,
    isSignedUpByReferral,
    referralData,
  },
  preSignup: { signupStatus, loader },
  auth: { signupFlow, writesUnderAgencyLicense, role },
  myAccount,
}) => {
  const { teamMemberEmailDuplicate, duplicatePhoneNumber } = myAccount;
  return {
    agencyProfile,
    loader,
    currentStepIndex,
    producerCode,
    signupFlow,
    submitLoader,
    teamMemberEmailDuplicate,
    tax,
    isSignedUpByReferral,
    referralData,
    signupStatus,
    writesUnderAgencyLicense,
    role,
    duplicatePhoneNumber,
  };
};

export default connect(mapStateToProps, {
  stepSubmit,
  stepSubmitSuccess,
  setDashboardView,
  setCurrentStepIndex,
  submitLoaderHide,
  preSignupUser,
  setTeamMemberEmailDuplicate,
  getProgressBarFlag,
})(withGoogleReCaptcha(Step1));
