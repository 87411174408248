import React from 'react';
import { Button, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
const overlay = {
  width: '100%',
  position: 'absolute',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  transition: 'all 0.2s ease-in',
  transform: 'translateY(0)',
};

const useStyles = makeStyles(() => ({
  animWrapper: {
    ...overlay,
    height: 'auto',
    zIndex: 1,
  },
  clickBtn: {
    backgroundColor: props => props.ctaButtonBackgroundColor || '#FF9A14',
    '&:hover': {
      backgroundColor: props => props.ctaButtonBackgroundColor || '#FF9A14',
    },
    color: props => props.ctaButtonLabelColor || '#000',
    fontFamily: props => props.ctaButtonLabelFont,
  },
  clickBtnSpan: {
    color: props => props.ctaButtonLabelColor || '#000',
    fontFamily: props => props.ctaButtonLabelFont,
  },
  btnWrapper: {
    ...overlay,
    zIndex: 1,
    height: 'auto',
  },
  posTop: { top: '50px' },
  posMid: { top: '127px' },
  posBottom: { top: '204px' },
  animatePosTop: { top: '20px' },
  animatePosMid: { top: '90px' },
  animatePosBottom: { top: '160px' },
  incentiveRoot: {
    backgroundImage: props => `url(${props.mainImageURL})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100% 100%',
    backgroundPosition: 'initial',
    backgroundOrigin: 'initial',
    position: 'relative',
    height: '100%',
  },
  animGif: { width: '100px', height: '100px', objectFit: 'contain' },
}));

const posMap = {
  Top: 'posTop',
  Middle: 'posMid',
  Bottom: 'posBottom',
};

const animPosMap = {
  Top: 'animatePosTop',
  Middle: 'animatePosMid',
  Bottom: 'animatePosBottom',
};

const Preview = props => {
  const classes = useStyles({
    ctaButtonLabelFont: props.ctaButtonLabelFont,
    ctaButtonLabelColor: props.ctaButtonLabelColor,
    ctaButtonBackgroundColor: props.ctaButtonBackgroundColor,
    animationURL: props.animationURL,
    mainImageURL: props.mainImageURL,
    ctaButtonURL: props.ctaButtonURL,
    ctaButtonLabel: props.ctaButtonLabel,
    buttonAlignment: props.ctaButtonAlignment,
    animationAlignment: props.animationAlignment,
  });

  const onClick = () => {
    if (props.ctaButtonIncluded) {
      window.open(props.ctaButtonURL, '_blank');
    }
  };

  return (
    <div
      className={clsx(
        classes.incentiveRoot,
        props.ctaButtonIncluded ? 'cursour-pointer' : ''
      )}
      onClick={onClick}
    >
      {props.animationURL && (
        <div className="producer-wrap-preview">
          <div
            className={clsx(
              classes.animWrapper,
              classes[animPosMap[props.animationAlignment]]
            )}
          >
            <img
              src={props.animationURL}
              className={clsx('animation-img', classes.animGif)}
            />
          </div>
        </div>
      )}
      {props.ctaButtonIncluded && (
        <div
          className={clsx(
            classes.btnWrapper,
            classes[posMap[props.ctaButtonAlignment]]
          )}
        >
          <Button className={`btn-click ${classes.clickBtn}`} onClick={onClick}>
            <span className={`${classes.clickBtnSpan}`}>
              {props.ctaButtonLabel}
            </span>
          </Button>
        </div>
      )}
    </div>
  );
};

export default Preview;
