import { push } from 'connected-react-router';
import { all, fork, put, takeEvery } from 'redux-saga/effects';
import * as agetDetailsActions from 'constants/action-types';
import { displayErrorMessage } from 'util/user-feedback';
import { SIGNOUT_ROUTE } from 'constants/routes';
import {
  SIGNOUT_IS_INACTIVITY_REAUTHENTICATE_SEARCH_PARAM,
  SIGNOUT_IS_SSO_ERROR,
} from 'constants/constant';

function* handleError({ payload }) {
  const isUnauthorizedError = payload?.response?.status === 401;
  const errorMessage = payload?.response?.data?.message || payload.message;
  const isFormError = payload?.response?.status === 422;

  // Handle Admin SSO 401 error - sign out, redirect to sign in with banner
  const isAdminSSOError =
    isUnauthorizedError &&
    payload?.response?.data?.message?.toLowerCase() ===
      'you need sso to login as admin';
  if (isAdminSSOError) {
    yield put(push(`${SIGNOUT_ROUTE}?${SIGNOUT_IS_SSO_ERROR}=true`));
    return;
  }

  // Handle 401 Unauthorized error - sign out & render reauthenticate screen
  if (isUnauthorizedError) {
    yield put(
      push(
        `${SIGNOUT_ROUTE}?${SIGNOUT_IS_INACTIVITY_REAUTHENTICATE_SEARCH_PARAM}=true`
      )
    );
    return;
  }

  // Handle 403 Sign Up Process Step Up Forbidden error - sign out & render reauthenticate screen
  const isSignupProcessStepUpError =
    !!payload?.response?.status &&
    payload.response.status === 403 &&
    payload.response?.data?.error === 'StepUpForbiddenError' &&
    window.location.pathname.includes('post-signup');

  if (isSignupProcessStepUpError) {
    yield put(
      push(
        `${SIGNOUT_ROUTE}?${SIGNOUT_IS_INACTIVITY_REAUTHENTICATE_SEARCH_PARAM}=true`
      )
    );
    return;
  }

  // Log other errors
  if (isFormError) {
    if (!errorMessage) {
      yield displayErrorMessage("Oops! Something went wrong");
    }
  } else {
    yield displayErrorMessage(errorMessage || "Oops! Something went wrong");
  }
}

export function* handleErrorWatcher() {
  yield takeEvery(agetDetailsActions.ERROR_DETAILS, handleError);
}

// ------ ROOT SAGA -----------------
export default function* rootSaga() {
  yield all([fork(handleErrorWatcher)]);
}
