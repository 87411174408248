import { all, fork, put, takeLeading } from 'redux-saga/effects';
import { getImpersonateBFFRequestConfig } from 'redux/saga/impersonate';
import {
  setIsCanopyConnectEnabled,
  setIsLeadFeedEnabled,
  setIsNewCarrierStoreEnabled,
  setIsNewDashboardEnabled,
} from 'redux/actions/producer-settings';
import config from 'config';
import { errorHandler, setIsNewQuoteConnectorEnabled } from 'redux/actions';
import * as types from '../../constants/action-types';
import axios from 'util/api';
import * as FullStory from '@fullstory/browser';

export function* fetchAndStoreProducerSettingsWatcher() {
  yield takeLeading(
    types.FETCH_PRODUCER_SETTINGS,
    fetchAndStoreProducerSettings
  );
}

export function* fetchAndStoreProducerSettings() {
  try {
    const requestConfig = yield getImpersonateBFFRequestConfig();
    const producerSettingsResponse = yield axios.get(
      `${config.BFF_URL}/api/v1/producer/settings`,
      requestConfig
    );

    // @TODO - store all the settings at once
    const isNewDashboardEnabled = Boolean(
      producerSettingsResponse?.data?.dashboard?.isNewEnabled
    );
    const isTestUser = Boolean(
      producerSettingsResponse?.data?.general?.isTestUser
    );
    const isNewQuoteConnectorEnabled = Boolean(
      producerSettingsResponse?.data?.['quote_connector']?.enabled
    );
    const isCanopyConnectEnabled = Boolean(
      producerSettingsResponse?.data?.dashboard?.isCanopyConnectEnabled
    );
    const isLeadFeedEnabled = Boolean(
      producerSettingsResponse?.data?.dashboard?.isLeadFeedEnabled
    );
    const isNewCarrierStoreEnabled = Boolean(
      producerSettingsResponse?.data?.carrier_store?.isNewEnabled
    );

    FullStory.setUserVars({
      isTestUser: isTestUser,
    });

    yield put(setIsNewDashboardEnabled(isNewDashboardEnabled));
    yield put(setIsNewQuoteConnectorEnabled(isNewQuoteConnectorEnabled));
    yield put(setIsCanopyConnectEnabled(isCanopyConnectEnabled));
    yield put(setIsLeadFeedEnabled(isLeadFeedEnabled));
    yield put(setIsNewCarrierStoreEnabled(isNewCarrierStoreEnabled));
  } catch (error) {
    yield put(errorHandler(error));
  }
}

// ------ ROOT SAGA -----------------
export default function* rootSaga() {
  yield all([fork(fetchAndStoreProducerSettingsWatcher)]);
}
