import * as carrierDetailsActions from 'constants/action-types';
import {
  ERROR_MESSAGE,
  FILE,
  LOGO,
  STATIC_ASSETS,
  SUCCESS,
} from 'constants/constant';
import { isEmpty, omit } from 'lodash';
import { displayErrorMessage, displaySuccessMessage } from 'util/user-feedback';
import { all, call, fork, put, select, takeEvery } from 'redux-saga/effects';
import {
  setAppointmentEnabled,
  setshowInStore,
} from 'redux/actions/carrier-appointment-settings-admin';
import axios from 'util/api';
import { uploadMultipleFile } from '../../util/extra';
import {
  carrierDetailsLoader,
  carrierUpdateLoader,
  clearCarrierResponse,
  deleteWebinarLinkDetailsFromStore,
  imageUploadError,
  setCarrierDetails,
  setCarrierProductMarket,
  setCarrierUpdateDetails,
  setCommissionPaidOn,
  setIsFetchCarrierError,
  setProductAccessibility,
  setRowData,
  setWebinarLinkDetailsInStore,
  setWebinarLoader,
  updateCommissionDataAfterDelete,
  updateWebinarLinkDetailsInStore,
} from '../actions/carrier-details';
import { errorHandler } from '../actions/error';

function* fetchCarrierData({ payload }) {
  const { carrierCode } = payload;
  try {
    yield put(carrierDetailsLoader(true));
    const carrierDetails = yield axios.get(
      `/api/v1/carrier/get/carrier/${carrierCode}`
    );
    if (carrierDetails.status === 200 && carrierDetails.data) {
      const { carrier, commissions } = carrierDetails.data;
      const { webinarLinks } = carrier;
      if (!isEmpty(webinarLinks)) {
        yield put(setWebinarLinkDetailsInStore([...webinarLinks]));
      } else {
        yield put(setWebinarLinkDetailsInStore([]));
      }
      yield put(
        setCarrierDetails({
          carrier,
          commissions,
          carrierMarkets: carrier.market,
        })
      );
    }
  } catch (error) {
    if (!!error.response && error.response.status === 401) {
      yield displayErrorMessage(error.response.data.message);
      yield put(errorHandler(error));
    } else {
      yield put(setIsFetchCarrierError(true));
      yield put(errorHandler(error));
    }
  }
}

export function* fetchCarrierDetailsWatcher() {
  yield takeEvery(
    carrierDetailsActions.FETCH_CARRIER_DETAILS,
    fetchCarrierData
  );
}

const updateCarrierApiCall = async (carrierData) =>
  await axios.post('/api/v1/carrier/update-carrier', {
    carrier: carrierData,
  });

function* updateCarrierDetails({ payload }) {
  const reduxState = yield select();
  let producerId = reduxState.auth.producer_id;
  const { carrierMarkets, carrierDetailsData, commissionsData } =
    reduxState.carrierDetails;
  try {
    yield put(carrierUpdateLoader(true));
    yield put(clearCarrierResponse());
    const { logoData, logoUrl, credential_preferences, carrier_id } = payload;
    const { file, fileName } =
      credential_preferences?.helpUrl && credential_preferences.helpUrl;
    let carrierLogoUrl = logoUrl;
    let helpFileUrl = file;
    let uploadedFileName = fileName;
    let carrierDetails = null;
    let isFileUploadErrorPresent = false;
    if (!isEmpty(logoData) || Array.isArray(file)) {
      let multipleUrl = [];
      let files = [];
      let filePath = [];
      if (!isEmpty(logoData)) {
        let fileURL = `${STATIC_ASSETS}/carrier-logos/${logoData[0].name}`;
        filePath.push(fileURL);
        files.push(logoData);
        multipleUrl.push({
          type: LOGO,
        });
      } else {
        carrierLogoUrl = carrierDetailsData.logo_url;
      }
      if (Array.isArray(file)) {
        let fileURL = `carriers/${carrier_id}/credential-help-documents/${file[0].name}`;
        filePath.push(fileURL);
        files.push(file);
        multipleUrl.push({
          type: FILE,
        });
      } else {
        helpFileUrl = carrierDetailsData.credential_preferences?.helpUrl?.file
          ? carrierDetailsData.credential_preferences.helpUrl.file
          : carrierDetailsData.credential_preferences?.helpUrl?.url;
        uploadedFileName =
          carrierDetailsData.credential_preferences?.helpUrl?.fileName;
      }
      const result = yield call(
        uploadMultipleFile,
        files,
        JSON.stringify(filePath)
      );
      if (result && result.status === 200) {
        const { files: file_url } = result.data;
        for (let item of multipleUrl) {
          if (item.type === LOGO) {
            carrierLogoUrl = file_url[0];
          }
          if (item.type === FILE) {
            helpFileUrl =
              !isEmpty(multipleUrl) && multipleUrl?.length === 1
                ? file_url[0]
                : file_url[1];
            uploadedFileName = file[0]?.name ?? '';
          }
        }
        yield put(imageUploadError(false));
      } else {
        isFileUploadErrorPresent = true;
        yield put(errorHandler(result));
        yield put(imageUploadError(true));
      }
    } else {
      carrierLogoUrl = carrierDetailsData.logo_url;
      helpFileUrl = carrierDetailsData.credential_preferences?.helpUrl?.url;
      uploadedFileName =
        carrierDetailsData.credential_preferences?.helpUrl?.fileName;
    }
    if (!isFileUploadErrorPresent) {
      let data = Object.assign({}, payload);
      delete data.logoData;
      delete data.logoUrl;
      delete data.helpFileUrl;
      delete data.uploadedFileName;

      let carrierData = Object.assign({}, data);
      carrierData.credential_preferences.helpUrl = {
        isEnabled: data?.credential_preferences?.helpUrl?.isEnabled,
        url: helpFileUrl,
        fileName: uploadedFileName,
      };
      carrierData.logo_url = carrierLogoUrl;
      carrierData.updated_by = producerId;
      carrierData.updated_at = new Date();
      carrierDetails = yield call(updateCarrierApiCall, carrierData);
      if (!!carrierDetails && !!carrierDetails?.data?.success) {
        const { carrier, message } = carrierDetails.data;
        const { is_tiers_enabled } = carrier;
        let updateCommission;
        if (!is_tiers_enabled) {
          updateCommission = commissionsData?.map((element) => ({
            ...element,
            commission_tiers_enabled: false,
          }));
        }
        carrier.responseMessage = message;
        yield put(
          setCarrierUpdateDetails({
            carrier,
            carrierMarkets: carrierMarkets,
            commissions: updateCommission ? updateCommission : commissionsData,
          })
        );
        yield displaySuccessMessage(carrierDetails.data.message);
      } else {
        yield displayErrorMessage(carrierDetails.data.message);
        yield put(carrierUpdateLoader(false));
      }
    } else {
      let carrierData = Object.assign({}, carrierDetailsData);
      carrierData.credential_preferences.helpUrl = {
        isEnabled:
          carrierDetailsData?.credential_preferences?.helpUrl?.isEnabled,
        url: '',
        fileName: '',
      };
      yield put(
        setCarrierUpdateDetails({
          carrier: carrierData,
          carrierMarkets: carrierMarkets,
        })
      );
      yield put(imageUploadError(true));
    }
  } catch (error) {
    if (!!error?.response && error?.response?.status === 401) {
      yield displayErrorMessage(error?.response?.data?.message);
      yield put(errorHandler(error));
    } else {
      yield put(errorHandler(error));
    }
  }
  yield put(carrierUpdateLoader(false));
}

export function* updateCarrierDetailsWatcher() {
  yield takeEvery(
    carrierDetailsActions.UPDATE_CARRIER_DETAILS,
    updateCarrierDetails
  );
}

function* updateCommissionTableData({ payload }) {
  const reduxState = yield select();
  const { carrierMarkets, commissionsData } = reduxState.carrierDetails;
  try {
    const { commission } = payload;
    let productIndex = commissionsData.findIndex((item) => {
      return item.product_id === commission.FK_product_id;
    });
    let commissionToUpdate;
    if (productIndex !== -1) {
      commissionToUpdate = commissionsData[productIndex].commission.filter(
        (data) => {
          return (
            data.FK_carrier_id === commission.FK_carrier_id &&
            data.id === commission.id
          );
        }
      );
    }
    commission.payment_type = commissionsData[productIndex].commission_paid_on;
    commission.commission_type = commissionsData[productIndex].commission_type;
    commission.commission_tiers_enabled =
      commissionsData[productIndex].commission_tiers_enabled;

    const updateData = yield axios.post(
      '/api/v1/carrier/add-carrier-product-commission',
      payload
    );
    const { message, commission: apiCommission } = updateData.data;
    let updatedCommissionData = reduxState?.carrierDetails?.commissionsData.map(
      (item) => {
        if (commission.FK_product_id === item.product_id) {
          let commissionIndex = item.commission.findIndex((data) => {
            return data.id === commission.id || data.uuid === commission.uuid;
          });
          if (commissionIndex === -1) {
            item.commission = [apiCommission, ...item.commission];
          } else {
            item.commission[commissionIndex] = apiCommission;
          }
          const {
            is_active,
            product,
            product_id,
            commission_paid_on,
            market,
            commission_type,
            commission_tiers_enabled,
          } = item;
          let commissionObject = {
            is_active,
            product,
            product_id,
            commission_paid_on,
            commission_type,
            commission_tiers_enabled,
            market,
            commission: item.commission,
            carrier_product_code: !!item?.carrier_product_code
              ? item.carrier_product_code
              : '',
          };
          return commissionObject;
        } else {
          return item;
        }
      }
    );
    if (updateData.status === 200) {
      yield put(
        setRowData({
          message,
          commission: { ...commission, ...updateData.data.commission },
          market: carrierMarkets,
          updatedCommissionData,
        })
      );
      yield displaySuccessMessage(message);
    } else {
      yield put(
        setRowData({
          message,
          commission: commissionToUpdate[0],
          market: carrierMarkets,
          updatedCommissionData: reduxState.carrierDetails.commissionsData,
        })
      );
      yield displayErrorMessage(message);
    }
  } catch (error) {
    if (!!error.response && error.response.status === 401) {
      yield displayErrorMessage(error.response.data.message);
      yield put(errorHandler(error));
    } else {
      yield put(errorHandler(error));
    }
  }
}

export function* updateCommissionTableItemWatcher() {
  yield takeEvery(
    carrierDetailsActions.UPDATE_COMMISSION_TABLE_DATA,
    updateCommissionTableData
  );
}

function* updateCommissionPaidOnData({ payload }) {
  const reduxState = yield select();
  const { carrierMarkets, commissionsData } = reduxState.carrierDetails;
  let commissionPaidOnValue = payload.commission_paid_on;
  let commissionType = payload.commission_type;
  let commissionTiersEnabled = payload.commission_tiers_enabled;
  let isAvailableForQuoting = payload.is_available_for_quoting;
  try {
    const commissionPaidOnResponse = yield axios.post(
      '/api/v1/carrier/update-commission-paid-on',
      payload
    );
    if (!!commissionPaidOnResponse) {
      const { status, data } = commissionPaidOnResponse;
      let updatedCommissionProduct = commissionsData.map((item) => {
        if (item.product_id === payload.FK_product_id && status === 200) {
          return {
            ...item,
            commission_paid_on: payload.commission_paid_on,
            commission_type: payload.commission_type,
            carrier_product_code: payload.carrier_product_code,
            commission_tiers_enabled: payload.commission_tiers_enabled,
            is_available_for_quoting: payload.is_available_for_quoting,
          };
        } else {
          commissionPaidOnValue = item.commission_paid_on;
          commissionType = item.commission_type;
          commissionTiersEnabled = item.commission_tiers_enabled;
          return item;
        }
      });
      yield put(
        setCommissionPaidOn({
          updatedCommissionProduct,
          productID: payload.FK_product_id,
          commissionPaidOnValue,
          markets: carrierMarkets,
          commissionType,
          commissionTiersEnabled,
          isAvailableForQuoting,
        })
      );
      if (status === 200) {
        yield displaySuccessMessage(data.message);
      } else {
        yield displayErrorMessage(data.message);
      }
    }
  } catch (error) {
    if (!!error.response && error.response.status === 401) {
      yield displayErrorMessage(error.response.data.message);
      yield put(errorHandler(error));
    } else {
      yield put(
        setCommissionPaidOn({
          updatedCommissionProduct: commissionsData,
          productID: payload.FK_product_id,
          commissionPaidOnValue,
        })
      );
      yield put(errorHandler(error));
    }
  }
}

export function* updateCommissionPaidOnWatcher() {
  yield takeEvery(
    carrierDetailsActions.UPDATE_COMMISSION_PAID_ON,
    updateCommissionPaidOnData
  );
}

function* updateCarrierProductsMarket({ payload }) {
  const { markets } = payload;
  try {
    const updateMarketsResponse = yield axios.post(
      '/api/v1/carrier/update-carrier-product-markets',
      payload
    );
    if (!!updateMarketsResponse) {
      const { status, data } = updateMarketsResponse;
      if (!!data) {
        const reduxState = yield select();
        let updatedCommissionData =
          reduxState?.carrierDetails?.commissionsData.map((item) => {
            if (markets.FK_product_id === item.product_id) {
              const {
                commission,
                is_active,
                product,
                product_id,
                commission_paid_on,
                commission_tiers_enabled,
                commission_type,
              } = item;
              let filteredState =
                !!commission &&
                commission.filter((commData) => {
                  return !markets.removed_markets.includes(
                    commData.state_abbrevation
                  );
                });
              let commissionObject = {
                is_active,
                product,
                product_id,
                commission_paid_on,
                commission_tiers_enabled,
                commission_type,
                market: markets.markets,
                commission: filteredState,
                carrier_product_code: !!item?.carrier_product_code
                  ? item.carrier_product_code
                  : '',
              };
              return commissionObject;
            } else {
              return item;
            }
          });
        const { commissionsData } = reduxState.carrierDetails;
        let carrierMarkets = reduxState.carrierDetails.carrierMarkets; // New. Old: fetching from carrierDetailsData.market
        // Checks whether markets are available in other products
        let isMarketPresent = [];
        commissionsData.filter((product) => {
          if (product.product_id !== markets.FK_product_id) {
            markets.removed_markets.filter((item) => {
              return (
                !!product.market &&
                product.market.includes(item) &&
                isMarketPresent.push(item)
              );
            });
          }
          return product;
        });
        // Add new market if not present in existing MarketList
        let updateMarketList = [];
        markets.markets.filter((item) => {
          return !carrierMarkets.includes(item) && updateMarketList.push(item);
        });
        const { message } = data;
        if (!!status && status === 200) {
          if (updateMarketList.length > 0) {
            carrierMarkets = carrierMarkets.concat(updateMarketList);
          }
          carrierMarkets = carrierMarkets.filter((value) => {
            return (
              !markets.removed_markets.includes(value) ||
              isMarketPresent.includes(value)
            );
          });
          yield put(
            setCarrierProductMarket({
              message,
              markets,
              updateMarketStatus: SUCCESS,
              carrierMarkets,
              updatedCommissionData,
            })
          );
          yield displaySuccessMessage(message);
        } else {
          yield put(
            setCarrierProductMarket({
              message,
              updateMarketStatus: 'error',
              markets,
              success: false,
            })
          );
          yield displayErrorMessage(message);
        }
      } else yield displayErrorMessage(ERROR_MESSAGE);
    } else yield displayErrorMessage(ERROR_MESSAGE);
  } catch (error) {
    if (!!error.response && error.response.status === 401) {
      yield put(
        setCarrierProductMarket({
          message: error.response.data.message,
          updateMarketStatus: 'error',
          markets,
          success: false,
        })
      );
      yield displayErrorMessage(error.response.data.message);
      yield put(errorHandler(error));
    } else yield put(errorHandler(error));
  }
}

export function* updateCarrierProductsMarketWatcher() {
  yield takeEvery(
    carrierDetailsActions.UPDATE_CARRIER_PRODUCT_MARKET,
    updateCarrierProductsMarket
  );
}

function* deleteCommissionTableData({ payload }) {
  const reduxState = yield select();
  const { carrierMarkets } = reduxState.carrierDetails;
  try {
    const {
      FK_carrier_id,
      FK_product_id,
      agent_new_commission,
      agent_renewal_commission,
      broker_new_commission,
      broker_renewal_commission,
      state_abbrevation,
      FK_carrier_tiers_mapping_id,
      id,
    } = payload.commission;
    const productId = FK_product_id;
    const responseData = yield axios.post(
      '/api/v1/carrier/delete-carrier-product-commission',
      {
        commission: {
          FK_carrier_id: FK_carrier_id,
          FK_schedule_id: 1,
          FK_membership_id: 1, // Todo: change this data
          FK_product_id: FK_product_id,
          state_abbrevation: state_abbrevation,
          broker_new_commission: broker_new_commission,
          broker_renewal_commission: broker_renewal_commission,
          agent_new_commission: agent_new_commission,
          agent_renewal_commission: agent_renewal_commission,
          FK_carrier_tiers_mapping_id: FK_carrier_tiers_mapping_id,
          id: id,
        },
      }
    );
    let deleteCommissionData = {};
    let updatedCommissionData = reduxState?.carrierDetails?.commissionsData.map(
      (item) => {
        if (payload.commission.FK_product_id === item.product_id) {
          item.commission = item.commission.filter((data) => {
            return data.id !== payload.commission.id;
          });
          const {
            is_active,
            product,
            product_id,
            commission_paid_on,
            commission_tiers_enabled,
            market,
            commission_type,
          } = item;
          let commissionObject = {
            is_active,
            product,
            product_id,
            commission_paid_on,
            commission_tiers_enabled,
            commission_type,
            market,
            commission: item.commission,
            carrier_product_code: !!item?.carrier_product_code
              ? item.carrier_product_code
              : '',
          };
          return commissionObject;
        } else {
          return item;
        }
      }
    );
    if (responseData.status === 200) {
      deleteCommissionData.deleteCommissionStatus = 'success';
      deleteCommissionData.productId = productId;
      deleteCommissionData.deletedState = id;
      yield put(
        updateCommissionDataAfterDelete({
          deleteCommissionData,
          markets: carrierMarkets,
          updatedCommissionData,
        })
      );
      yield displaySuccessMessage(responseData.data.message);
    } else {
      deleteCommissionData.deleteCommissionStatus = 'error';
      deleteCommissionData.productId = productId;
      yield put(
        updateCommissionDataAfterDelete({
          deleteCommissionData,
          markets: carrierMarkets,
          updatedCommissionData: reduxState.carrierDetails.commissionsData,
        })
      );
      yield displayErrorMessage(responseData.data.message);
    }
  } catch (error) {
    if (!!error.response && error.response.status === 401) {
      yield displayErrorMessage(error.response.data.message);
      yield put(errorHandler(error));
    } else {
      yield put(errorHandler(error));
    }
  }
}

export function* deleteCommissionDataWatcher() {
  yield takeEvery(
    carrierDetailsActions.DELETE_COMMISSION_TABLE_DATA,
    deleteCommissionTableData
  );
}

function* updateProductAccess(param) {
  const { payload } = param;
  try {
    const updateProductAccessResponse = yield axios.post(
      '/api/v1/carrier/update-carrier-product',
      payload
    );
    if (!!updateProductAccessResponse.data) {
      const { success, message, appointmentsDisabled } =
        updateProductAccessResponse.data;
      const reduxState = yield select();
      const { carrierMarkets } = reduxState.carrierDetails;
      const commissionData = reduxState.carrierDetails.commissionsData;
      let updatedCommissionData = commissionData.map((item) => {
        if (payload.FK_product_id === item.product_id) {
          let updatedCommisionObject = {
            commission: item.commission,
            is_active: !item.is_active,
            market: item.market,
            product: item.product,
            product_id: item.product_id,
            commission_paid_on: item.commission_paid_on,
            commission_tiers_enabled: item.commission_tiers_enabled,
            commission_type: item.commission_type,
            carrier_product_code: !!item?.carrier_product_code
              ? item.carrier_product_code
              : '',
          };
          return updatedCommisionObject;
        } else return item;
      });
      if (updateProductAccessResponse.status === 200) {
        yield put(
          setProductAccessibility({
            updateAccessResponse: success,
            isProductEnable: payload.is_active,
            commissionData: updatedCommissionData,
            disableProductId: payload.FK_product_id,
            markets: carrierMarkets,
          })
        );
        yield displaySuccessMessage(message);
        if (appointmentsDisabled) {
          yield put(
            setAppointmentEnabled({
              acceptingAppointments: false,
            })
          );
          yield put(
            setshowInStore({
              showInStore: false,
            })
          );
        }
      } else {
        yield put(
          setProductAccessibility({
            updateAccessResponse: success,
            isProductEnable: !payload.is_active,
            commissionData,
            disableProductId: payload.FK_product_id,
            markets: carrierMarkets,
          })
        );
        yield displayErrorMessage(message);
      }
    }
  } catch (error) {
    if (!!error.response && error.response.status === 401) {
      yield displayErrorMessage(error.response.data.message);
      yield put(errorHandler(error));
    } else {
      yield put(errorHandler(error));
    }
    yield displayErrorMessage(error);
  }
}

export function* updateProductAccessibilityWatcher() {
  yield takeEvery(
    carrierDetailsActions.UPDATE_CARRIER_PRODUCT_ACCESSIBILITY,
    updateProductAccess
  );
}

function* addWebinarlinkWorker({ payload }) {
  try {
    yield put(setWebinarLoader(true));
    const response = yield axios.post('api/v1/carrier/add-webinar-link', {
      ...payload,
    });
    if (!isEmpty(response?.data)) {
      const {
        carrierDetails: { webinarDetails },
      } = yield select();
      const { id } = response.data;
      if (!isEmpty(webinarDetails)) {
        yield put(
          setWebinarLinkDetailsInStore([
            ...webinarDetails,
            { id, ...omit(payload, ['carrierId']) },
          ])
        );
      } else {
        yield put(setWebinarLinkDetailsInStore([{ id, ...payload }]));
      }
    }
  } catch (error) {
    if (!!error.response && error.response.status === 401) {
      yield displayErrorMessage(error.response.data.message);
      yield put(errorHandler(error));
    } else {
      yield put(errorHandler(error));
    }
  }
  yield put(setWebinarLoader(false));
}

export function* addWebinarlinksWatcher() {
  yield takeEvery(carrierDetailsActions.ADD_WEBINAR_LINK, addWebinarlinkWorker);
}

function* updateWebinarlinkWorker({ payload }) {
  const { id, linkName, URL } = payload;
  try {
    yield put(setWebinarLoader(true));
    const response = yield axios.put(
      `api/v1/carrier/update-webinar-link/${id}`,
      { linkName, URL }
    );
    if (!isEmpty(response?.data)) {
      yield put(updateWebinarLinkDetailsInStore(payload));
    }
  } catch (error) {
    if (!!error.response && error.response.status === 401) {
      yield displayErrorMessage(error.response.data.message);
      yield put(errorHandler(error));
    } else {
      yield put(errorHandler(error));
    }
  }
  yield put(setWebinarLoader(false));
}

export function* updateWebinarlinksWatcher() {
  yield takeEvery(
    carrierDetailsActions.UPDATE_WEBINAR_LINK,
    updateWebinarlinkWorker
  );
}

function* deleteWebinarLinkWorker({ payload }) {
  const { id } = payload;
  try {
    const response = yield axios.delete(
      `api/v1/carrier/delete-webinar-link/${id}`
    );
    if (!isEmpty(response?.data)) {
      yield put(deleteWebinarLinkDetailsFromStore({ id }));
    }
  } catch (error) {
    if (!!error.response && error.response.status === 401) {
      yield displayErrorMessage(error.response.data.message);
      yield put(errorHandler(error));
    } else {
      yield put(errorHandler(error));
    }
  }
}

export function* deleteWebinarlinksWatcher() {
  yield takeEvery(
    carrierDetailsActions.DELETE_WEBINAR_LINK,
    deleteWebinarLinkWorker
  );
}

// ------ ROOT SAGA -----------------
export default function* rootSaga() {
  yield all([
    fork(fetchCarrierDetailsWatcher),
    fork(updateCarrierDetailsWatcher),
    fork(updateCommissionTableItemWatcher),
    fork(updateCommissionPaidOnWatcher),
    fork(updateCarrierProductsMarketWatcher),
    fork(deleteCommissionDataWatcher),
    fork(updateProductAccessibilityWatcher),
    fork(addWebinarlinksWatcher),
    fork(updateWebinarlinksWatcher),
    fork(deleteWebinarlinksWatcher),
  ]);
}
