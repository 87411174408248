import { Box, Button, Typography, withStyles } from '@material-ui/core';
import React from 'react';
import ErrorBoundaryIcon from './ErrorBoundaryIcon';
import { Stack, styles } from './ErrorBoundary.styles';

class ErrorBoundary extends React.Component {
  static getDerivedStateFromError() {
    return { hasError: true };
  }

  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, errorInfo) {
    // eslint-disable-next-line no-console
    console.log(error, errorInfo);
  }

  handleRefresh() {
    location.reload();
  }

  render() {
    const { classes } = this.props;
    if (this.state.hasError) {
      return (
        <Box className={classes.background}>
          <Stack className={classes.wrapper} spacing={8}>
            <ErrorBoundaryIcon />
            <Stack spacing={6}>
              <Stack spacing={4}>
                <Typography className={classes.title}>Something went wrong!</Typography>
                <Typography className={classes.message}>
                  An unexpected error has occurred. Please try again later.
                </Typography>
              </Stack>
            </Stack>
            <Button className={classes.button} onClick={this.handleRefresh}>Refresh</Button>
          </Stack>
        </Box>
      );
    }

    return this.props.children;
  }
}

export default withStyles(styles)(ErrorBoundary);